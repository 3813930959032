import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  FindToFollowResponse,
  ToFollowCandidate,
} from 'generated/src/main/proto/api/follow-service.pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { FollowService } from '../services/follow/follow.service';

export class PeopleDataSource extends DataSource<ToFollowCandidate> {
  private peopleSubject = new BehaviorSubject<readonly ToFollowCandidate[]>([]);
  private query = '';
  private readTime?: Timestamp;
  private paginator!: MatPaginator;

  constructor(private followService: FollowService) {
    super();
  }

  connect(): Observable<readonly ToFollowCandidate[]> {
    return this.peopleSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  setPaginator(paginator: MatPaginator): void {
    this.paginator = paginator;

    // Subscribe to requests for subsequent pages.
    paginator.page.subscribe((pageEvent) =>
      this.loadPage(
        pageEvent.pageIndex * pageEvent.pageSize,
        pageEvent.pageSize,
      ),
    );
  }

  /** Initiates search. */
  search(query: string): void {
    if (this.query != query) {
      this.paginator.pageIndex = 0;
      this.readTime = undefined;

      // Expect more than one page.
      this.paginator.length = 2 * this.paginator.pageSize;
    }
    this.query = query;
    this.loadPage(
      this.paginator.pageIndex * this.paginator.pageSize,
      this.paginator.pageSize,
    );
  }

  private loadPage(startIndex: number, count: number): void {
    this.followService
      .findToFollow(this.query, startIndex, count, this.readTime)
      .then((r: FindToFollowResponse) => {
        this.peopleSubject.next(r.candidates!);

        this.readTime = r.readTime;

        if (r.candidates!.length == this.paginator.pageSize) {
          // Expect more.
          this.paginator.length = startIndex + 2 * this.paginator.pageSize;
        } else {
          this.paginator.length = startIndex + r.candidates!.length;
        }
      });
  }
}
