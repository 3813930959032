import { Component, OnInit } from '@angular/core';
import { Config } from 'generated/src/main/proto/api/config-service.pb';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrl: './config.component.scss',
})
export class ConfigComponent implements OnInit {
  private originalConfig?: Config;
  config?: Config;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.configService.getConfig().then((config) => {
      this.originalConfig = config;
      this.config = new Config(config);
    });
  }

  get configChanged(): boolean {
    return (
      this.config != undefined &&
      this.originalConfig != undefined &&
      JSON.stringify(this.config) != JSON.stringify(this.originalConfig)
    );
  }

  updateConfig(): void {
    const config = new Config(this.config!);
    this.configService.updateConfig(config).then((r) => {
      if (r) {
        this.originalConfig = new Config(config);
      }
    });
  }
}
