<div class="container">
  <button *ngIf="showIconButton" mat-icon-button class="button" matTooltip="Click to react"
    (click)="onAddReactionClick($event)">
    <div class="icon-container">
      <mat-icon class="material-symbols-outlined foreground-icon" [ngStyle]="{ 'z-index': 2 }">favorite</mat-icon>
      <!-- Background color -->
      <mat-icon class="background-icon" [ngStyle]="{ color: iconColor, 'z-index': 1 }">favorite</mat-icon>
    </div>
  </button>
  <span class="text" *ngIf="likeText">{{ likeText }}</span>
</div>
