<div class="policy-text" [ngStyle]="{ 'max-height': maxHeight }">
  <h1>Privacy Policy</h1>
  <p>
    This Privacy Policy explains how we collect, use, and disclose
    your personal information when you use our website. By using our
    website, you consent to the practices described in this policy.
  </p>

  <h2>Information We Collect</h2>
  <p>
    When you use our website, we may collect the following types of
    personal information:
  </p>
  <ul>
    <li>
      <strong>Your Data:</strong> This includes tracks, location,
      and media that you choose to share with other users. You have
      full control over the visibility of your data and can delete
      it at any time.
    </li>
    <li>
      <strong>Location:</strong> The location you define on this
      website is used solely for finding and displaying followers
      who are in your vicinity. We do not share your exact location
      with anyone.
    </li>
  </ul>

  <h2>How We Use Your Information</h2>
  <p>
    We use your personal information only for the following
    purposes:
  </p>
  <ul>
    <li>
      <strong>Data Visibility:</strong> We use your preferences to
      determine what data is visible to other users. Your data is
      shared only with your approved followers.
    </li>
    <li>
      <strong>Improving User Experience:</strong> We may use
      aggregated and anonymized data to analyze user behavior and
      improve our website's features and functionality.
    </li>
  </ul>

  <h2>Data Security</h2>
  <p>
    We take data security seriously and implement measures to
    protect your personal information from unauthorized access,
    alteration, disclosure, or destruction. Your data is stored
    securely on our back-end systems.
  </p>

  <h2>Employee Access</h2>
  <p>
    Our employees are strictly prohibited from accessing your
    personal data. Only certain authorized personnel, on a
    need-to-know basis, may access your data for the sole purpose of
    providing support or technical assistance.
  </p>

  <h2>Deleting Your Data</h2>
  <p>
    You have the right to delete your data, including tracks,
    location, and media, at any time. Deleting your data will remove
    it from our systems permanently.
  </p>

  <h2>Disclosure to Third Parties</h2>
  <p>
    We do not disclose your personal information to third parties
    unless required by law or with your explicit consent.
  </p>

  <h2>Changes to Privacy Policy</h2>
  <p>
    We may update this Privacy Policy from time to time. Any changes
    will be posted on this page, and the date of the last update
    will be indicated at the end of this policy.
  </p>

  <p>This Privacy Policy was last updated on {{ lastUpdateDate }}.</p>
</div>
