import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { PubsubNotification } from 'generated/src/main/proto/pubsub/notification.pb';
import { ActivityType } from 'generated/src/main/proto/shared/activity-shared.pb';
import { NotificationContainer } from 'generated/src/main/proto/shared/notification-shared.pb';
import { FollowService } from '../services/follow/follow.service';
import { FormatService } from '../services/format.service';

/** Dialog input. */
export type NotificationsInput = {
  notifications: NotificationContainer[];
};

/** Dialog result. */
export type NotificationsResult = {
  seenIds: string[];
  reloadNotifications: boolean;
};

/** Shows notificaitons. */
@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications.dialog.component.html',
  styleUrls: ['./notifications.dialog.component.scss'],
})
export class NotificationsDialogComponent implements AfterViewInit {
  readonly columns = ['date', 'text'];
  notifications: NotificationContainer[] = [];

  recentNotifications: NotificationContainer[] = [];
  olderNotifications: NotificationContainer[] = [];
  followRequests: PubsubNotification.NewFollowRequest[] = [];

  @ViewChild('contents')
  contentsDivElement!: ElementRef<HTMLDivElement>;

  private visibilityTimerId = 0;
  private seenIds: string[] = [];
  private reloadNotifications = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: NotificationsInput,
    public dialogRef: MatDialogRef<
      NotificationsDialogComponent,
      NotificationsResult
    >,
    private formatService: FormatService,
    private followService: FollowService,
    private router: Router,
  ) {
    this.notifications = inputData.notifications.sort((a, b) => {
      const time1 = a.time!.toDate();
      const time2 = b.time!.toDate();
      return time2.getTime() - time1.getTime();
    });

    // Split notifications into lists per section.
    const now = new Date();
    this.notifications.forEach((n) => {
      const then = n.time!.toDate();
      const days = Math.floor(
        (now.getTime() - then.getTime()) / (24 * 3600 * 1000),
      );
      if (n.notification?.newFollowRequest) {
        this.followRequests.push(n.notification.newFollowRequest);
      } else if (days < 7) {
        this.recentNotifications.push(n);
      } else {
        this.olderNotifications.push(n);
      }
    });

    dialogRef.keydownEvents().subscribe((e) => this.onDialogKeyDown(e));
    dialogRef.backdropClick().subscribe(() => this.onDialogBackdropClick());
  }

  ngAfterViewInit(): void {
    // All not previously seen ids are marked as seen after the timeout
    // unless the dialog is closed before that.
    this.visibilityTimerId = window.setTimeout(() => {
      this.seenIds = this.notifications
        .filter((n) => !n.seenByUser)
        .map((n) => n.notificationId);
    }, 700);
  }

  formatDate(date: Timestamp): string {
    return this.formatService.formatDate(date);
  }

  formatDateSinceToday(date: Timestamp): string {
    return this.formatService.formatDateSinceToday(date);
  }

  formatUploadedActivity(
    uploadedActivity: PubsubNotification.UploadedActivity,
  ) {
    let type = 'Activity';
    if (
      uploadedActivity.activityType &&
      uploadedActivity.activityType.length > 0
    ) {
      const index = Object.keys(ActivityType).indexOf(
        uploadedActivity.activityType,
      );
      if (index >= 0) {
        type = FormatService.toDisplay(
          Object.values(ActivityType)[index] as ActivityType,
        );
        type = `'${type}'`;
      }
    }

    return `${type} from ${uploadedActivity.source}`;
  }

  formatFailedActivity(
    failedActivity: PubsubNotification.FailedActivityUpload,
  ) {
    return (
      `Failed activity upload from '${failedActivity.source}': ` +
      failedActivity.reason
    );
  }

  formatNewFollowRequest(
    newFollowRequest: PubsubNotification.NewFollowRequest,
  ): string {
    return `Request from ${
      newFollowRequest.firstName + ' ' + newFollowRequest.lastName
    } (@${newFollowRequest.alias})`;
  }

  formatNewFollowing(newFollowing: PubsubNotification.NewFollowing): string {
    return `You started following ${
      newFollowing.firstName + ' ' + newFollowing.lastName
    } (@${newFollowing.alias})`;
  }

  formatNewReaction(newReaction: PubsubNotification.NewReaction): string {
    return (
      `Comment from ${newReaction.firstName + ' ' + newReaction.lastName} (@${
        newReaction.alias
      }): ` + newReaction.comment
    );
  }

  getAlias(n: NotificationContainer): string {
    let c:
      | PubsubNotification.NewReaction
      | PubsubNotification.NewFollowing
      | PubsubNotification.UploadedActivity;
    if (n.notification?.newReaction) {
      c = n.notification.newReaction;
    } else if (n.notification?.newFollowing) {
      c = n.notification.newFollowing;
    } else if (n.notification?.uploadedActivity) {
      c = n.notification.uploadedActivity;
    } else {
      return '';
    }
    return c.alias;
  }

  getRequestAlias(r: PubsubNotification.NewFollowRequest): string {
    return r.alias;
  }

  onLinkClick(link: string): void {
    this.closeDialog();
    this.router.navigate([link]);
  }

  onLinkKeydown(e: KeyboardEvent, link: string): void {
    if (e.key == ' ') {
      this.onLinkClick(link);
    }
  }

  onResolveRequest(alias: string, approved: boolean): void {
    this.followService.resolveFollowRequest(alias, approved).then(() => {
      this.followRequests = this.followRequests.filter((r) => r.alias != alias);
      this.reloadNotifications = true;
    });
  }

  onDialogKeyDown(e: KeyboardEvent) {
    if (e.key == 'Escape') {
      this.closeDialog();
      e.stopPropagation();
    }
  }

  onDialogBackdropClick() {
    this.closeDialog();
  }

  private closeDialog(): void {
    if (this.visibilityTimerId) {
      window.clearTimeout(this.visibilityTimerId);
    }
    this.dialogRef.close({
      seenIds: this.seenIds,
      reloadNotifications: this.reloadNotifications,
    });
  }
}
