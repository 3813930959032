import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import {
  Activity,
  Summary,
} from 'generated/src/main/proto/api/activity-service.pb';
import {
  IntervalStats,
  TrackPoint,
} from 'generated/src/main/proto/shared/activity-shared.pb';
import {
  Media,
  MediaContentType,
} from 'generated/src/main/proto/shared/media-shared.pb';
import { timer } from 'rxjs';
import { Roles } from '../admin/roles';
import { ChartType, SeriesType } from '../common/charts/types';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import { ItemData } from '../common/gallery/gallery.component';
import { ActivityReactionUpdater } from '../common/reactions/activity-reaction-updater';
import { ReactionDetailsDialogComponent } from '../reaction-details/reaction-details.dialog';
import { ActivitiesService } from '../services/activities/activities.service';
import {
  BannerMessage,
  BannerService,
} from '../services/banner/banner.service';
import { DeviceDetectorService } from '../services/device-detector.service';
import {
  FormatService,
  Measurement,
  OptionalNumber,
  Unit,
} from '../services/format.service';
import { MapRendererService } from '../services/map-renderer/map-renderer.service';
import { MediaService } from '../services/media-service/media.service';
import {
  LoggedInUser,
  UnitPreference,
  UserService,
} from '../services/user/user.service';

/** Shows details of an activity in edit or read-only mode. */
@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss'],
})
export class ActivityDetailComponent implements OnInit {
  galleryMargin: number;
  galleryWidth: number;
  galleryHeight: number;
  galleryInnerWidth: number;
  galleryInnerHeight: number;

  activity?: Activity;
  readOnly = false;
  statMeasurements: Measurement[][] = [];

  @ViewChild('imageInput') imageInput!: ElementRef<HTMLInputElement>;
  @ViewChild('map') mapDivRef!: ElementRef<HTMLDivElement>;
  uploadingImages = false;
  uploadProgressPercent = 0;
  galleryItems: ItemData[] = [];

  // Map.
  showMapUI = false;
  private map: google.maps.Map | undefined;
  private readonly mapDivStyleDefault: {
    [prop: string]: string;
  };
  private readonly mapDivStyleFullScreen: {
    [prop: string]: string;
  } = { width: '100%', height: '100%' };
  isFullScreen = false;

  // Editable properties.
  title = '';
  activityType!: string;
  noteHtml = '';

  // HR Zones chart.
  showHrZones = false;

  // First chart.
  showFirstChart = false;
  chartTypes = [ChartType.Kilometers, ChartType.Miles];
  firstChartType = ChartType.Miles;
  firstChartSeriesTypes: SeriesType[] = [];
  firstChartSeriesTypeLeft = SeriesType.AverageHeartRate;
  firstChartSeriesTypeRight = SeriesType.ElevationGain;
  firstChartOptions: EChartsOption = {};
  // eslint-disable-next-line
  firstChart: any;

  // Elevation chart.
  showElevationChart = false;
  elevationChartSeriesTypes: SeriesType[] = [];
  elevationChartSeriesType = SeriesType.Speed;
  elevationChartOptions: EChartsOption = {};
  // eslint-disable-next-line
  elevationChart: any;

  // Tracking point on the map.
  trackingPointFeature?: google.maps.Data.Feature;

  readonly statColumns = ['name', 'value'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private activitiesService: ActivitiesService,
    private mapRendererService: MapRendererService,
    private mediaService: MediaService,
    private bannerService: BannerService,
    private deviceDetectorService: DeviceDetectorService,
    private userService: UserService,
    private formatService: FormatService,
    private dialog: MatDialog,
  ) {
    this.galleryMargin = 7;
    this.galleryWidth = this.formatService.viewWidth;
    this.galleryHeight = Math.floor(0.7 * this.galleryWidth);
    this.galleryInnerWidth = this.galleryWidth - 2 * this.galleryMargin;
    this.galleryInnerHeight = this.galleryHeight - 2 * this.galleryMargin;

    // Map dimensions.
    this.mapDivStyleDefault = {
      width: this.galleryInnerWidth + 'px',
      height: this.galleryInnerHeight + 'px',
    };
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const activityId = params.get('id') ?? '';
      this.activitiesService
        .get(activityId)
        .then((r) => {
          this.activity = r.activity;
          this.showMapUI =
            this.activity!.track != undefined &&
            !this.activity!.track.noLocation;
          this.initProperties();
          this.updateGalleryItems();
          this.showMap();
        })
        .catch(); // handled in the service
    });
  }

  getMapDivStyle(index: number): {
    [prop: string]: string;
  } {
    let style = this.isFullScreen
      ? this.mapDivStyleFullScreen
      : this.mapDivStyleDefault;
    if (index != 0 || !this.showMapUI) {
      style = Object.create(style);
      style['display'] = 'none';
    }
    return style;
  }

  onGalleryFullScreenChange(isFullScreen: boolean): void {
    this.isFullScreen = isFullScreen;
  }

  private updateGalleryItems() {
    const items: ItemData[] = [];
    if (this.activity?.track && !this.activity?.track.noLocation) {
      items.push({});
    }
    if (this.activity) {
      const medias = this.activity.summary?.medias;
      if (medias) {
        for (let i = 0; i < medias.length; i++) {
          const media = medias[i];
          const smallSizeUrl = MediaService.getImageSrc(media.smallSizeId);
          const fullSizeUrl = MediaService.getImageSrc(media.fullSizeId);
          const item: ItemData = {};
          if (media.contentType == MediaContentType.MEDIA_CONTENT_TYPE_VIDEO) {
            item.videoSrc = smallSizeUrl;
            if (media.fullSizeId != media.smallSizeId) {
              item.videoFullSizeSrc = fullSizeUrl;
            }
          } else {
            item.imageSrc = smallSizeUrl;
            if (media.fullSizeId != media.smallSizeId) {
              item.imageFullSizeSrc = fullSizeUrl;
            }
          }
          items.push(item);
        }
      }
    }
    this.galleryItems = items;
  }

  getShowDeleteButton(index: number): boolean {
    if (this.readOnly) {
      return false;
    }
    const item = this.galleryItems[index];
    if (
      item.imageSrc ||
      item.videoSrc ||
      item.imageFullSizeSrc ||
      item.videoFullSizeSrc
    ) {
      return true;
    }
    return false;
  }

  deleteGalleryItem(index: number) {
    const medias = this.activity!.summary!.medias!;
    if (0 <= index && index < medias.length) {
      medias.splice(index, 1);
      this.updateGalleryItems();
      this.save(false);
    }
  }

  createReactionUpdater(summary: Summary): ActivityReactionUpdater {
    return new ActivityReactionUpdater(
      this.activitiesService,
      this.bannerService,
      summary,
      this.userService.snapshotUser!.alias!,
    );
  }
  onClickReactionsSummary(activityId: string, left: number) {
    const top = Math.round((1 * window.innerHeight) / 3);
    const height = window.innerHeight - top;

    ReactionDetailsDialogComponent.openDialog(
      this.dialog,
      activityId,
      top,
      left + this.galleryMargin,
      this.galleryWidth,
      height,
    );
  }

  private showMap() {
    timer(1).subscribe(() => {
      if (this.showMapUI) {
        this.mapRendererService
          .renderActivity(this.mapDivRef.nativeElement, this.activity!)
          .then((map) => (this.map = map));
      }
    });
  }

  private initProperties(): void {
    this.title = this.activity!.summary!.title;
    this.activityType = FormatService.toDisplay(
      this.activity!.summary!.activityType,
    );
    if (this.activity!.summary!.noteHtml) {
      this.noteHtml = this.activity!.summary!.noteHtml;
    }

    this.userService.user.subscribe((user) => {
      if (user) {
        this.readOnly = this.isReadonly(user);
        this.firstChartType =
          user.unitPreference == UnitPreference.Imperial
            ? ChartType.Miles
            : ChartType.Kilometers;
      }
    });
    this.initTotalStats();
    this.initGraphs();
  }

  private isReadonly(user: LoggedInUser): boolean {
    let readOnly = this.activity!.summary?.alias != user.alias;
    if (
      user.roles != undefined &&
      user.roles.indexOf(Roles.IssueReviewer) >= 0
    ) {
      readOnly = false;
    }
    return readOnly;
  }

  private initTotalStats(): void {
    const totals = this.activity!.stats?.totalStats;

    const measurements: Measurement[][] = [];
    let currentRow: Measurement[] = [];

    const startTime = this.activity!.summary!.totalStats!.startTime!;
    const endTime = this.activity!.summary!.totalStats!.endTime!;

    // Start/end times.
    let s = this.formatService.formatDateToMeasurement(startTime.toDate());
    s.title = 'Start time';
    currentRow.push(s);
    s = this.formatService.formatDateToMeasurement(endTime.toDate());
    s.title = 'End time';
    currentRow.push(s);
    measurements.push(currentRow);
    currentRow = [];

    // Duration.
    const duration = FormatService.formatDuration(startTime, endTime);
    currentRow.push(duration);

    // Moving time.
    const movingTime = totals?.movingTimeSeconds;
    if (movingTime) {
      s = FormatService.formatDurationSeconds(movingTime);
      s.title = 'Moving time';
      if (s.text != duration.text) {
        currentRow.push(s);
      }
    }
    measurements.push(currentRow);
    currentRow = [];

    // Distance.
    const distance = totals?.distance;
    if (distance) {
      currentRow.push(
        this.formatService.formatDistanceByActivityType(
          distance,
          this.activity!.summary!.activityType,
        ),
      );
    }

    // Speed.
    if (totals?.speedStats?.mean) {
      const s = this.formatService.formatSpeed(
        this.activity?.summary?.activityType,
        totals!.speedStats.mean,
      );
      s.description = 'Average speed.';
      s.title = 'Average speed';
      currentRow.push(s);
    }
    if (totals?.speedStats?.max) {
      const s = this.formatService.formatSpeed(
        this.activity?.summary?.activityType,
        totals!.speedStats.max,
      );
      s.description = 'Maximum speed.';
      s.title = 'Max speed';
      currentRow.push(s);
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }

    // Altitude.
    if (totals?.altitudeStats) {
      let s = this.formatService.formatAltitude(totals!.altitudeStats.min);
      s.description = 'Minimum altitude above sea level.';
      s.title = 'Min altitude';

      currentRow.push(s);
      s = this.formatService.formatAltitude(totals!.altitudeStats.max);
      s.description = 'Maximum altitude above sea level.';
      s.title = 'Max altitude';
      currentRow.push(s);

      measurements.push(currentRow);
      currentRow = [];
    }

    // Elevation change.
    if (totals?.elevationGain) {
      const s = this.formatService.formatElevation(totals!.elevationGain);
      currentRow.push(s);
    }
    if (totals?.elevationDrop) {
      const s = this.formatService.formatElevation(-totals!.elevationDrop);
      currentRow.push(s);
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }

    // Pace.
    if (totals?.kmPace) {
      currentRow.push(this.formatService.formatPace(totals.kmPace, false));
    }
    if (totals?.milePace) {
      currentRow.push(this.formatService.formatPace(totals.milePace, true));
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }

    // Heart rate.
    if (totals?.heartRateStats?.mean) {
      const s = this.formatService.formatHeartRate(totals!.heartRateStats.mean);
      s.description = 'Average heart rate.';
      s.title = 'Average HR';
      currentRow.push(s);
    }
    if (totals?.heartRateStats?.min) {
      const s = this.formatService.formatHeartRate(totals!.heartRateStats.min);
      s.description = 'Minimum heart rate.';
      s.title = 'Min HR';
      currentRow.push(s);
    }
    if (totals?.heartRateStats?.max) {
      const s = this.formatService.formatHeartRate(totals!.heartRateStats.max);
      s.description = 'Maximum heart rate.';
      s.title = 'Max HR';
      currentRow.push(s);
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }
    if (totals?.heartRateStats?.percentiles) {
      totals?.heartRateStats?.percentiles.forEach((value) => {
        const s = this.formatService.formatHeartRate(value.value);
        s.description = `${value.percent}th % heart rate.`;
        s.title = `${value.percent}th % HR`;
        currentRow.push(s);
      });
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }

    // Grade.
    if (totals?.gradeStats?.min) {
      const s = this.formatService.formatGrade(totals!.gradeStats.min);
      s.description = 'Minimum Grade.';
      s.title = 'Min grade';
      currentRow.push(s);
    }
    if (totals?.gradeStats?.max) {
      const s = this.formatService.formatGrade(totals!.gradeStats.max);
      s.description = 'Maximum grade.';
      s.title = 'Max grade';
      currentRow.push(s);
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }

    // Calories.
    if (totals?.calories) {
      const s = this.formatService.formatCalories(totals?.calories);
      currentRow.push(s);
    }
    if (currentRow.length > 0) {
      measurements.push(currentRow);
      currentRow = [];
    }

    this.statMeasurements = measurements;

    // HR Zones chart.
    if (
      totals?.heartRateZoneStats &&
      Object.keys(totals?.heartRateZoneStats).length > 0
    ) {
      this.showHrZones = true;
    } else {
      this.showHrZones = false;
    }
  }

  get isChanged(): boolean {
    if (this.activity?.summary?.title != this.title) {
      return true;
    }
    if (
      FormatService.toDisplay(this.activity?.summary?.activityType) !=
      this.activityType
    ) {
      return true;
    }
    if (this.noteHtml != this.activity.summary.noteHtml) {
      return true;
    }
    return false;
  }

  get activityTypes(): string[] {
    return FormatService.activityTypes().map((v) => FormatService.toDisplay(v));
  }

  activityTypeDisplay(value: number): string {
    return FormatService.toDisplay(value);
  }

  addImages(event: Event) {
    const target = event.target as HTMLInputElement;
    // Check file sizes.
    let tooLarge = false;
    let totalBytes = 0;
    const doneBytesByFile: number[] = [];
    for (let i = 0; i < target.files!.length; i++) {
      const file: File = target.files![i];
      if (file) {
        if (file.size > MediaService.MAX_FILE_SIZE) {
          const fileMbs = file.size >> 20;
          const maxMbs = MediaService.MAX_FILE_SIZE >> 20;
          this.bannerService.add(
            new BannerMessage(
              `File ${file.name} is too large: ${fileMbs}MB > ${maxMbs}MB`,
            ),
          );
          tooLarge = true;
        }
        totalBytes += file.size;
        doneBytesByFile.push(0);
      }
    }
    if (tooLarge) {
      // Skip upload.
      this.imageInput.nativeElement.value = '';
      return;
    }

    // Start upload.
    const uploadPromises = [];
    let doneBytes = 0;
    this.uploadProgressPercent = 0;
    this.uploadingImages = true;
    for (let i = 0; i < target.files!.length; i++) {
      const file: File = target.files![i];
      if (file) {
        uploadPromises.push(
          this.mediaService.postMedia(file, (done) => {
            doneBytes -= doneBytesByFile[i];
            doneBytes += done;
            doneBytesByFile[i] = done;
            this.uploadProgressPercent = Math.round(
              (100 * doneBytes) / totalBytes,
            );
          }),
        );
      }
    }
    this.imageInput.nativeElement.value = '';

    // Wait for all uploads.
    Promise.all(uploadPromises)
      .then((results) => {
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const media = new Media({
            smallSizeId: result.smallSizeId,
            fullSizeId: result.fullSizeId,
            contentType: result.contentType,
          });
          const id = Number(media.smallSizeId);
          if (!isNaN(id) && id > 0) {
            this.activity!.summary!.medias!.push(media);
          } else {
            console.log('Unexpected 0 media id');
          }
        }

        this.save(false);
        this.updateGalleryItems();
        this.uploadingImages = false;
      })
      .catch((reason) => {
        this.bannerService.add(
          new BannerMessage('Upload failed with: ' + reason),
        );
        this.uploadingImages = false;
      });
  }

  onDeleteActivityClick() {
    ConfirmationComponent.openDialog(
      this.dialog,
      'Delete activity?',
      (result) => {
        if (result) {
          this.activitiesService
            .delete(this.activity?.summary?.activityId)
            .then(() => {
              this.router.navigate(['/activities']);
            });
        }
      },
    );
  }

  save(check = true) {
    if (check && !this.isChanged) {
      return;
    }

    const newSummary = new Summary(this.activity!.summary);
    newSummary.title = this.title;
    newSummary.activityType = FormatService.toApi(this.activityType);
    newSummary.noteHtml = this.noteHtml ?? '';

    this.activitiesService.saveSummary(newSummary).then(() => {
      // Update display summary.
      this.activity!.summary = newSummary;
    });
  }

  onAccordionItemKeyDown(e: KeyboardEvent, accordionItem: CdkAccordionItem) {
    if (e.key == 'Enter') {
      accordionItem.toggle();
    }
  }

  // eslint-disable-next-line
  onFirstChartInit(e: any) {
    this.firstChart = e;
  }

  onMouseMoveFirstChart(e: MouseEvent) {
    const point = [e.offsetX, e.offsetY];
    const xIndex = this.firstChart.convertFromPixel('grid', point)[0];

    const chartType = this.firstChartType;
    let trackPoint: TrackPoint;
    if (chartType == ChartType.Miles || chartType == ChartType.Kilometers) {
      // Use distance to find low point of the distance interval.
      const distance = this.formatService.convertDistanceToMeters(
        xIndex,
        chartType == ChartType.Miles,
      );
      trackPoint = this.findNearestTrackPoint(distance, (p) => p.distance!);
    } else if (chartType == ChartType.Hours || chartType == ChartType.Days) {
      // Use time to find low point of the time interval.
      const secondsPerIndexIncrement =
        chartType == ChartType.Hours ? 3600 : 3600 * 24;
      const secondsFromEpoch =
        parseFloat(this.activity?.track?.points![0].time?.seconds ?? '0') +
        xIndex * secondsPerIndexIncrement;
      trackPoint = this.findNearestTrackPoint(secondsFromEpoch, (p) =>
        parseFloat(p.time?.seconds ?? '0'),
      );
    } else {
      return;
    }

    // Add tracking point to the map.
    if (this.map) {
      const coord: google.maps.LatLngLiteral = {
        lat: trackPoint.latLongAlt!.latitude,
        lng: trackPoint.latLongAlt!.longitude,
      };
      if (!this.trackingPointFeature) {
        this.trackingPointFeature = new google.maps.Data.Feature({
          geometry: new google.maps.Data.Point(coord),
        });
        this.map.data.add(this.trackingPointFeature);
      } else {
        this.trackingPointFeature.setGeometry(coord);
      }
    }
  }

  // eslint-disable-next-line
  onElevationChartInit(e: any) {
    this.elevationChart = e;
  }

  onMouseMoveElevation(e: MouseEvent) {
    const point = [e.offsetX, e.offsetY];
    const secondsFromEpoch =
      this.elevationChart.convertFromPixel('grid', point)[0] / 1000;

    // Add tracking point to the map.
    if (this.map) {
      const trackPoint = this.findNearestTrackPoint(secondsFromEpoch, (p) =>
        parseFloat(p.time?.seconds ?? '0'),
      );
      const coord: google.maps.LatLngLiteral = {
        lat: trackPoint.latLongAlt!.latitude,
        lng: trackPoint.latLongAlt!.longitude,
      };
      if (!this.trackingPointFeature) {
        this.trackingPointFeature = new google.maps.Data.Feature({
          geometry: new google.maps.Data.Point(coord),
        });
        this.map.data.add(this.trackingPointFeature);
      } else {
        this.trackingPointFeature.setGeometry(coord);
      }
    }
  }

  onGraphsClosed() {
    this.removeTrackingPoint();
  }

  private removeTrackingPoint(): void {
    if (this.trackingPointFeature && this.map) {
      this.map.data.remove(this.trackingPointFeature);
      this.trackingPointFeature = undefined;
    }
  }

  private findNearestTrackPoint(
    value: number,
    pointToValue: (point: TrackPoint) => number,
  ): TrackPoint {
    const points = this.activity?.track?.points ?? [];
    let high = points.length - 1;
    let low = 0;
    let mid;
    while (low <= high) {
      mid = Math.floor((high + low) / 2);
      const pointValue = pointToValue(points[mid]);
      if (pointValue === value) {
        return points[mid];
      } else if (value > pointValue) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    low = Math.max(0, Math.min(low, points.length - 1));
    return points[low];
  }

  private initGraphs(): void {
    if (
      this.activity?.stats?.hourStats &&
      this.activity?.stats?.hourStats.length > 0
    ) {
      this.chartTypes.push(ChartType.Hours);
    }
    if (
      this.activity?.stats?.dayStats &&
      this.activity?.stats?.dayStats.length > 0
    ) {
      this.chartTypes.push(ChartType.Days);
    }

    const hasHeartRates = this.activity?.stats?.totalStats?.heartRateStats
      ? true
      : false;

    // First chart.
    this.showFirstChart = this.showMapUI ? true : false;
    this.firstChartSeriesTypes = this.getSeriesTypes(this.firstChartType);
    this.firstChartSeriesTypeLeft = hasHeartRates
      ? SeriesType.AverageHeartRate
      : SeriesType.MilePace;
    this.firstChartSeriesTypeRight = SeriesType.ElevationGain;
    this.buildFirstChartOptions();

    // Elevation chart.
    this.showElevationChart = hasHeartRates || this.showFirstChart;
    this.elevationChartSeriesTypes = [];
    if (hasHeartRates) {
      this.elevationChartSeriesTypes.push(SeriesType.HeartRate);
    }
    if (
      this.activity?.stats?.totalStats?.speedStats &&
      this.activity?.stats?.totalStats?.speedStats.max > 0
    ) {
      this.elevationChartSeriesTypes.push(SeriesType.Speed);
    }
    if (this.activity?.stats?.totalStats?.gradeStats) {
      this.elevationChartSeriesTypes.push(SeriesType.Grade);
    }
    this.elevationChartSeriesType = this.elevationChartSeriesTypes[0];
    this.buildElevationChartOptions();
  }

  private getSeriesTypes(chartType: ChartType): SeriesType[] {
    const seriesTypes: SeriesType[] = [];
    let intervalStats: IntervalStats[] | undefined;
    switch (chartType) {
      case ChartType.Kilometers:
        intervalStats = this.activity?.stats?.kmStats;
        break;
      case ChartType.Miles:
        intervalStats = this.activity?.stats?.mileStats;
        break;
      case ChartType.Hours:
        intervalStats = this.activity?.stats?.hourStats;
        break;
      case ChartType.Days:
        intervalStats = this.activity?.stats?.dayStats;
        break;
    }
    if (intervalStats && intervalStats.length > 0) {
      seriesTypes.push(SeriesType.Distance);
      seriesTypes.push(SeriesType.ElevationGain);
      seriesTypes.push(SeriesType.ElevationDrop);

      const first = intervalStats[0];
      if (first.speedStats) {
        seriesTypes.push(SeriesType.AverageSpeed);
        seriesTypes.push(SeriesType.MedianSpeed);
      }
      if (first.kmPaceStats) {
        seriesTypes.push(SeriesType.KilometerPace);
      }
      if (first.milePaceStats) {
        seriesTypes.push(SeriesType.MilePace);
      }
      if (first.heartRateStats) {
        seriesTypes.push(SeriesType.Calories);
        seriesTypes.push(SeriesType.AverageHeartRate);
        seriesTypes.push(SeriesType.MedianHeartRate);
      }
      if (first.heartRateZoneStats) {
        seriesTypes.push(SeriesType.TimeInZone1);
        seriesTypes.push(SeriesType.TimeInZone2);
        seriesTypes.push(SeriesType.TimeInZone3);
        seriesTypes.push(SeriesType.TimeInZone4);
        seriesTypes.push(SeriesType.TimeInZone5);
        seriesTypes.push(SeriesType.PercentTimeInZone1);
        seriesTypes.push(SeriesType.PercentTimeInZone2);
        seriesTypes.push(SeriesType.PercentTimeInZone3);
        seriesTypes.push(SeriesType.PercentTimeInZone4);
        seriesTypes.push(SeriesType.PercentTimeInZone5);
      }
      if (first.gradeStats) {
        seriesTypes.push(SeriesType.Grade);
      }
    }
    return seriesTypes;
  }

  firstChartTypeSelected() {
    this.firstChartSeriesTypes = this.getSeriesTypes(this.firstChartType);
    this.buildFirstChartOptions();
  }

  firstChartSeriesLeftSelected() {
    this.buildFirstChartOptions();
  }

  firstChartSeriesRightSelected() {
    this.buildFirstChartOptions();
  }

  elevationChartSeriesSelected() {
    this.buildElevationChartOptions();
  }

  private buildFirstChartOptions(): void {
    this.firstChartOptions = this.buildChartOptions(this.firstChartType, [
      this.firstChartSeriesTypeLeft,
      this.firstChartSeriesTypeRight,
    ]);
  }

  private buildChartOptions(
    chartType: ChartType,
    seriesTypes: SeriesType[],
  ): EChartsOption {
    if (seriesTypes.length == 0 || seriesTypes.length > 2) {
      throw 'Unexpected series types length: ' + seriesTypes.length.toString();
    }

    let intervalStats: IntervalStats[] | undefined;
    switch (chartType) {
      case ChartType.Kilometers:
        intervalStats = this.activity?.stats?.kmStats;
        break;
      case ChartType.Miles:
        intervalStats = this.activity?.stats?.mileStats;
        break;
      case ChartType.Hours:
        intervalStats = this.activity?.stats?.hourStats;
        break;
      case ChartType.Days:
        intervalStats = this.activity?.stats?.dayStats;
        break;
    }

    let chartOption: EChartsOption = {};
    if (intervalStats) {
      chartOption = {
        tooltip: {
          trigger: 'axis',
          // eslint-disable-next-line
          formatter: (params: any) => {
            const xLabel = params[0].name;
            // eslint-disable-next-line
            const yValues = params.map((p: any) => p.data);

            return (
              `<span>${xLabel}</span> <br/>` +
              seriesTypes
                .map((type, index) => {
                  const unit = this.formatService.getUnit(type);
                  if (unit == Unit.Hours) {
                    return this.formatValue(type, yValues[index]) + `<br />`;
                  } else {
                    return (
                      this.formatValue(type, yValues[index]) + `, ${unit}<br />`
                    );
                  }
                })
                .join('')
            );
          },
          axisPointer: {
            type: 'cross',
            label: {
              // eslint-disable-next-line
              formatter: (params: any) => {
                const yAxisIndex = params.axisIndex;
                const isY = params.axisDimension == 'y';
                if (isY) {
                  const type = seriesTypes[yAxisIndex];
                  const unit = this.formatService.getUnit(type);
                  const value = Math.round(params.value * 10) / 10;
                  if (unit == Unit.Hours) {
                    return this.formatValue(type, value);
                  } else {
                    return this.formatValue(type, value) + `, ${unit}`;
                  }
                } else {
                  return params.value;
                }
              },
            },
          },
        },
        grid: {
          containLabel: true,
          left: '2%',
          right: '2%',
          top: '5%',
          bottom: '5%',
        },
        xAxis: {
          type: 'category',
          data: intervalStats.map((_value, index) => (index + 1).toString()),
        },
        yAxis: seriesTypes.map((type, index) => {
          return {
            type: 'value',
            name:
              type.toString() +
              ', ' +
              this.formatService.getUnit(type).toString(),
            nameLocation: 'end',
            nameTextStyle: {
              align: index == 0 ? 'left' : 'right',
            },
            position: index == 0 ? 'left' : 'right',
            axisLabel: {
              inside: false,
              show: true,
              // eslint-disable-next-line
              formatter: (dataValue: any) =>
                this.formatValue(seriesTypes[index], dataValue as number),
            },
          };
        }),
        series: seriesTypes.map((type, index) => {
          return {
            data: this.getValues(type, intervalStats!),
            type: 'bar',
            yAxisIndex: index,
          };
        }),
      };
    }
    return chartOption;
  }

  private buildElevationChartOptions(): void {
    const elevations: [string, number][] = [];
    const values: [string, OptionalNumber][] = [];
    const points = this.activity?.track?.points ?? [];
    let sampling = 1;
    if (points.length > this.galleryWidth) {
      sampling = Math.round(points.length / this.galleryWidth);
    }
    let nonEmptyElevation = false;
    points.forEach((point, index) => {
      if (sampling == 1 || index % sampling == 0) {
        const time = point.time!.toISOString();
        const altitude = this.formatService.convertElevations([
          point.latLongAlt?.altitude ?? 0,
        ])[0];
        elevations.push([time, altitude]);
        if (altitude != 0) {
          nonEmptyElevation = true;
        }
        switch (this.elevationChartSeriesType) {
          case SeriesType.HeartRate:
            {
              const v = point.heartRate ?? 0;
              values.push([
                time,
                this.formatService.convertHeartRates([v > 0 ? v : '-'])[0],
              ]);
            }
            break;
          case SeriesType.Speed:
            values.push([
              time,
              this.formatService.convertSpeeds([point.speed ?? 0])[0],
            ]);
            break;
          case SeriesType.Grade:
            values.push([time, Math.round((point.grade ?? 0) * 10) / 10]);
            break;
        }
      }
    });

    // Y-axis options.
    // eslint-disable-next-line
    const yAxisOptions: any[] = [];
    if (nonEmptyElevation) {
      yAxisOptions.push({
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        name:
          SeriesType.Elevation +
          ', ' +
          this.formatService.getUnit(SeriesType.Elevation).toString(),
        nameLocation: 'end',
        nameTextStyle: {
          align: 'left',
        },
        position: 'left',
      });
    }
    yAxisOptions.push({
      type: 'value',
      min: 'dataMin',
      max: 'dataMax',
      name:
        this.elevationChartSeriesType.toString() +
        ', ' +
        this.formatService.getUnit(this.elevationChartSeriesType).toString(),
      nameLocation: 'end',
      nameTextStyle: {
        align: 'right',
      },
      position: 'right',
    });

    // Series.
    // eslint-disable-next-line
    const series: any[] = [];
    if (nonEmptyElevation) {
      series.push({
        data: elevations,
        type: 'line',
        yAxisIndex: 0,
        areaStyle: {},
      });
    }
    series.push({
      data: values,
      type: 'line',
      yAxisIndex: nonEmptyElevation ? 1 : 0,
    });

    // Chart options.
    const chartOption: EChartsOption = {
      axisPointer: {
        show: true,
      },
      grid: {
        containLabel: true,
        left: '2%',
        right: '2%',
        top: '5%',
        bottom: '5%',
      },
      xAxis: {
        type: 'time',
        splitNumber: this.deviceDetectorService.isMobile() ? 3 : 5,
      },
      yAxis: yAxisOptions,
      series: series,
    };

    this.elevationChartOptions = chartOption;
  }

  private formatValue(seriesType: SeriesType, dataValue: number): string {
    switch (seriesType) {
      case SeriesType.Distance:
      case SeriesType.ElevationGain:
      case SeriesType.ElevationDrop:
      case SeriesType.Calories:
      case SeriesType.AverageSpeed:
      case SeriesType.MedianSpeed:
      case SeriesType.MilePace:
      case SeriesType.KilometerPace:
      case SeriesType.AverageHeartRate:
      case SeriesType.Grade:
      case SeriesType.MedianHeartRate:
        return `${dataValue}`;
      case SeriesType.TimeInZone1:
      case SeriesType.TimeInZone2:
      case SeriesType.TimeInZone3:
      case SeriesType.TimeInZone4:
      case SeriesType.TimeInZone5:
        return FormatService.formatDurationSeconds(dataValue).text;
      case SeriesType.PercentTimeInZone1:
      case SeriesType.PercentTimeInZone2:
      case SeriesType.PercentTimeInZone3:
      case SeriesType.PercentTimeInZone4:
      case SeriesType.PercentTimeInZone5:
        return `${dataValue}`;
    }
    throw 'Unexpected series type: ' + seriesType;
  }

  private getValues(
    seriesType: SeriesType,
    stats: IntervalStats[],
  ): OptionalNumber[] {
    switch (seriesType) {
      case SeriesType.Distance:
        return this.formatService.convertDistances(
          stats.map((s) => s.distance),
        );
      case SeriesType.ElevationGain:
        return this.formatService.convertElevations(
          stats.map((s) => s.elevationGain),
        );
      case SeriesType.ElevationDrop:
        return this.formatService.convertElevations(
          stats.map((s) => s.elevationDrop),
        );
      case SeriesType.Calories:
        return this.formatService.convertCalories(stats.map((s) => s.calories));
      case SeriesType.AverageSpeed:
        return this.formatService.convertSpeeds(
          stats.map((s) => s.speedStats?.mean ?? 0),
        );
      case SeriesType.MedianSpeed:
        return this.formatService.convertSpeeds(
          stats.map((s) => {
            if (!s.speedStats || !s.speedStats.percentiles) {
              return '-';
            }
            return (
              s.speedStats.percentiles.find((v) => v.percent == 50)?.value ?? 0
            );
          }),
        );
      case SeriesType.MilePace:
        return this.formatService.convertPaces(
          stats.map((s) => {
            if (!s.milePaceStats) {
              return '-';
            }
            return s.milePaceStats.mean;
          }),
        );
      case SeriesType.KilometerPace:
        return this.formatService.convertPaces(
          stats.map((s) => {
            if (!s.kmPaceStats) {
              return '-';
            }
            return s.kmPaceStats.mean;
          }),
        );
      case SeriesType.AverageHeartRate:
        return this.formatService.convertHeartRates(
          stats.map((s) => {
            if (!s.heartRateStats) {
              return '-';
            }
            const v = s.heartRateStats.mean;
            // Ignore heart rate of 0;
            return v > 0 ? v : '-';
          }),
        );
      case SeriesType.Grade:
        return stats.map((s) => {
          if (!s.gradeStats) {
            return 0;
          }
          return s.gradeStats.mean;
        });
      case SeriesType.MedianHeartRate:
        return this.formatService.convertHeartRates(
          stats.map((s) => {
            if (!s.heartRateStats || !s.heartRateStats.percentiles) {
              return '-';
            }
            const v =
              s.heartRateStats.percentiles.find((v) => v.percent == 50)
                ?.value ?? 0;
            return v > 0 ? v : '-';
          }),
        );
      case SeriesType.TimeInZone1:
      case SeriesType.TimeInZone2:
      case SeriesType.TimeInZone3:
      case SeriesType.TimeInZone4:
      case SeriesType.TimeInZone5: {
        const zoneIndex = parseInt(seriesType.charAt(seriesType.length - 1));
        return stats.map((s) => {
          if (!s.heartRateZoneStats) {
            return '-';
          }
          const hrZone = s.heartRateZoneStats[zoneIndex];
          if (!hrZone) {
            return 0;
          }
          return parseInt(hrZone.secondsInZone);
        });
      }
      case SeriesType.PercentTimeInZone1:
      case SeriesType.PercentTimeInZone2:
      case SeriesType.PercentTimeInZone3:
      case SeriesType.PercentTimeInZone4:
      case SeriesType.PercentTimeInZone5: {
        const zoneIndex = parseInt(seriesType.charAt(seriesType.length - 1));
        return stats.map((s) => {
          if (!s.heartRateZoneStats) {
            return '-';
          }
          const hrZone = s.heartRateZoneStats[zoneIndex];
          if (!hrZone) {
            return 0;
          }
          let total = 0;
          Object.values(s.heartRateZoneStats).forEach((hrZoneStats) => {
            total += parseInt(hrZoneStats.secondsInZone);
          });
          let percent =
            total > 0 ? (100 * parseInt(hrZone.secondsInZone)) / total : 0;
          percent = Math.round(percent * 10) / 10;
          return percent;
        });
      }
    }
    throw 'Unexpected series type: ' + seriesType;
  }
}
