import { Component, Input } from '@angular/core';
import { PolicyVersions } from 'src/app/common/policy-versions';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent {
  @Input()
  maxHeight = 'inherit';

  lastUpdateDate = PolicyVersions.cookiesPolicyLastUpdateDate;
}
