<!-- Filtering menu -->
<button
  mat-icon-button
  class="filter"
  [matMenuTriggerFor]="filterMenu"
  matTooltip="Filter activities"
  aria-label="Filter menu"
>
  <mat-icon>filter_list</mat-icon>
</button>
<!-- TODO: figure out how to make menu width fit the contents, below doesn't work
::ng-deep {
  .mat-mdc-menu-panel {
    max-width: unset !important;
  }
} -->
<mat-menu class="menu" #filterMenu="matMenu">
  <button *ngIf="!disableAll" mat-menu-item (click)="onFilterAll()">
    <span
      >All
      <mat-pseudo-checkbox
        appearance="minimal"
        class="check"
        [state]="userFilter && userFilter.all ? 'checked' : 'unchecked'"
      ></mat-pseudo-checkbox>
    </span>
  </button>
  <button mat-menu-item (click)="onFilterLoggedInUser()">
    <span
      >Mine
      <mat-pseudo-checkbox
        appearance="minimal"
        class="check"
        [state]="userFilter && userFilter.loggedInUser ? 'checked' : 'unchecked'"
      ></mat-pseudo-checkbox>
    </span>
  </button>
  <div class="user-query-input-container">
    <input
      class="mat-mdc-menu-item"
      matInput
      #userQueryInput
      [(ngModel)]="userQueryPrefix"
      (ngModelChange)="onQueryPrefixChanged()"
      placeholder="type alias or name"
      (focus)="onUserQueryFocus($event)"
      (click)="onUserQueryClick($event)"
      (keydown)="onUserQueryKeyDown($event)"
    />
    <mat-pseudo-checkbox
      appearance="minimal"
      class="check"
      [state]="userFilter && userFilter.followingAlias ? 'checked' : 'unchecked'"
    ></mat-pseudo-checkbox>
  </div>
  <mat-option
    #userAutoSuggest
    class="user-query-options"
    *ngFor="let user of autoSuggestedUsers"
    (onSelectionChange)="onUserSelected()"
    [value]="user"
    >{{ formatUserForOption(user) }}</mat-option
  >
</mat-menu>
