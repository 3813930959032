import { GrpcMetadata } from '@ngx-grpc/common';

const _SESSION = 'Session';

export function authToken(token: string | null): GrpcMetadata {
  const metadata = new GrpcMetadata();
  if (token) {
    metadata.set('Authorization', 'Bearer ' + token);
  } else {
    const sessionId = getSessionId();
    if (sessionId) {
      metadata.set(_SESSION, sessionId);
    }
  }
  return metadata;
}

export function getSessionId(): string | null {
  return localStorage.getItem(_SESSION);
}

export function deleteSessionId() {
  const expiration = new Date();
  expiration.setTime(expiration.getTime() - 24 * 3600 * 1000);
  setCookie(_SESSION, '', expiration);
  localStorage.removeItem(_SESSION);
}

export function setSessionId(sessionId: string, expiration: Date) {
  localStorage.setItem(_SESSION, sessionId);
  setCookie(_SESSION, sessionId, expiration);
}

export function sessionIdExists(): boolean {
  return localStorage.getItem(_SESSION) ? true : false;
}

/** The cookie is sent on image/video requests made by the browser. If cookies are not enabled,
 * the requests will fail with 401.
 *
 * The alternatives to using cookies for auth are:
 *   1) Intercept requests in a service worker:
 *       navigator.serviceWorker.register('/sw.js');
 *       self.addEventListener('fetch', function(event) {
 *          const newRequest = new Request(event.request, {
 *            headers: {"Session": "SessionId"},
 *            mode: "cors"
 *       });
 *       return fetch(newRequest);
 *    2) Replace src attribute in <img>, <video> with "url | pipeToFetch", where
 *       Angular pipe will transform src to blob url (URL.createObjectUrl API) after fetching
 *       the image.
 *  Service workers may make the site less maintainable as they are long-lived, it's unclear how
 *  to support multiple users in the same browser. The pipe approach would work for images but not
 *  videos as videos are loaded with multiple GET calls. The pipe approach would effectively pre-load
 *  images during Angular template rendering, which may not be desired in all cases.
}
 */
function setCookie(name: string, value: string, expiration: Date) {
  const domain = window.location.hostname;
  const cookie =
    name +
    '=' +
    value +
    '; expires=' +
    expiration.toUTCString() +
    '; path=/; SameSite=Strict; domain=' +
    domain;
  document.cookie = cookie;
}
