import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationContainer } from 'generated/src/main/proto/shared/notification-shared.pb';
import { DeviceDetectorService } from '../services/device-detector.service';
import { FormatService } from '../services/format.service';
import { NotificationsService } from '../services/notifications/notifications.service';
import {
  NotificationsDialogComponent,
  NotificationsInput,
  NotificationsResult,
} from './notifications.dialog.component';

/** Shows notificaiton icon and dialog with notifications. */
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  private isMobile = false;
  notifications: NotificationContainer[] = [];
  newNotificationCount = 0;

  @ViewChild('notificationsButton', { read: ElementRef })
  notificationsButton!: ElementRef;

  constructor(
    private notificationsService: NotificationsService,
    private formatService: FormatService,
    private dialog: MatDialog,
    deviceDetectorService: DeviceDetectorService,
  ) {
    this.isMobile = deviceDetectorService.isMobile();
    notificationsService.responseObservable.subscribe((r) => {
      if (r.notification) {
        this.onNewNotification();
      }
    });
  }

  ngOnInit(): void {
    this.loadNotifications();
  }

  showNotifications(): void {
    this.loadNotifications().then(() => this.openNotificationsDialog());
  }

  private openNotificationsDialog() {
    // Align to center for mobile.
    const margin = 7;
    const width = this.formatService.viewWidth - 2 * margin;
    let left = (window.innerWidth - width) / 2;
    let top = Math.round((1 * window.innerHeight) / 3);
    const maxHeight = window.innerHeight - top;

    if (!this.isMobile) {
      const buttonRect =
        this.notificationsButton.nativeElement.getBoundingClientRect();
      const right = Math.round((buttonRect.left + buttonRect.right) / 2);
      left = right - width;
      top = buttonRect.bottom + 10;
    }

    // Open dialog.
    const dialogRef = this.dialog.open<
      NotificationsDialogComponent,
      NotificationsInput,
      NotificationsResult
    >(NotificationsDialogComponent, {
      data: { notifications: this.notifications },
      width: width + 'px',
      minWidth: width,
      maxWidth: width,
      maxHeight: maxHeight,
      position: {
        left: left + 'px',
        top: top + 'px',
      },
    });
    dialogRef.afterClosed().subscribe((r) => {
      if (r) {
        if (r.seenIds) {
          this.notificationsService
            .setSeen(r.seenIds)
            .then(() => this.markSeen(r.seenIds));
        }
        if (r.reloadNotifications) {
          this.loadNotifications();
        }
      }
    });
  }

  private markSeen(seenIds: string[]): void {
    const ids = new Set(seenIds);
    this.notifications.forEach((n) => {
      if (ids.has(n.notificationId)) {
        n.seenByUser = true;
      }
    });
    this.updateNewNotificationsCount();
  }

  private onNewNotification(): void {
    this.loadNotifications();
  }

  private async loadNotifications(): Promise<boolean> {
    return this.notificationsService.get().then((r) => {
      this.notifications = r.notifications!;
      this.updateNewNotificationsCount();
      return true;
    });
  }

  private updateNewNotificationsCount(): void {
    this.newNotificationCount = this.notifications.filter(
      (n) => !n.seenByUser,
    ).length;
  }
}
