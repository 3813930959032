import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IssueType } from 'generated/src/main/proto/shared/issue-shared.pb';
import { ImageService } from 'src/app/services/image.service';

export type IssueReport = {
  issueType: IssueType;
  consentToContact: boolean;
  screenshot?: Uint8Array;
  title: string;
  description: string;
};

@Component({
  selector: 'app-issue-report-dialog',
  templateUrl: './issue-report.dialog.html',
  styleUrls: ['./issue-report.dialog.scss'],
})
export class IssueReportDialogComponent {
  private static issueTypes = IssueReportDialogComponent.initIssueTypes();

  issueType?: string;
  issueTypeKeys = Array.from(IssueReportDialogComponent.issueTypes.keys());
  consentToContact = false;
  submitWithScreenshot = true;
  title = '';
  description = '';

  imageWidth: number;
  imageHeight: number;
  imageSrc?: string;
  screenshotBuffer?: Uint8Array;

  constructor(
    public dialogRef: MatDialogRef<IssueReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IssueReport,
    imageService: ImageService,
  ) {
    // Compute screenshot snippet dimensions.
    const maxImageSize = 150;
    this.imageWidth = window.innerWidth;
    this.imageHeight = window.innerHeight;
    if (Math.max(this.imageHeight, this.imageWidth) > maxImageSize) {
      if (this.imageHeight > this.imageWidth) {
        this.imageWidth = Math.floor(
          (this.imageWidth * maxImageSize) / this.imageHeight,
        );
        this.imageHeight = maxImageSize;
      } else {
        this.imageHeight = Math.floor(
          (this.imageHeight * maxImageSize) / this.imageWidth,
        );
        this.imageWidth = maxImageSize;
      }
    }

    // Take screenshot.
    imageService
      .getScreenshot()
      .then((buffer) => {
        this.screenshotBuffer = new Uint8Array(buffer);
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = () => reject(reader.error);
          reader.readAsDataURL(
            new File([buffer], '', { type: 'application/octet-stream' }),
          );
        });
      })
      .then((imageSrc) => (this.imageSrc = imageSrc));
  }

  onCancel() {
    this.dialogRef.close();
  }

  get isSubmitDisabled(): boolean {
    return (
      this.issueType == undefined ||
      this.description.length < 5 ||
      !this.title ||
      (this.submitWithScreenshot && !this.screenshotBuffer)
    );
  }

  onSubmit() {
    const data: IssueReport = {
      issueType:
        IssueReportDialogComponent.issueTypes.get(this.issueType!) ??
        IssueType.ISSUE_TYPE_UNSPECIFIED,
      consentToContact: this.consentToContact,
      screenshot: this.submitWithScreenshot ? this.screenshotBuffer : undefined,
      title: this.title,
      description: this.description,
    };
    this.dialogRef.close(data);
  }

  private static initIssueTypes(): Map<string, IssueType> {
    const values = new Map<string, IssueType>();
    for (const type in IssueType) {
      if (isNaN(Number(type))) {
        const key = type as keyof typeof IssueType;
        if (IssueType[key] != IssueType.ISSUE_TYPE_UNSPECIFIED) {
          let value = type.substring('ISSUE_TYPE_'.length);
          value = value
            .split('_')
            .map((s) => s[0] + s.substring(1).toLowerCase())
            .join(' ');
          values.set(value, IssueType[key]);
        }
      }
    }
    return values;
  }
}
