<div class="banner" *ngFor="let message of messageQueue; index as index">
  <div class="message">{{ message.message }}</div>
  <button
    mat-raised-button
    color="accent"
    class="dismiss"
    (click)="removeItem(index)"
  >
    Dismiss
  </button>
</div>
