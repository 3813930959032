import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationModule } from '../common/confirmation/confirmation.module';
import { GalleryModule } from '../common/gallery/gallery.module';
import { GarminBackfillModule } from '../common/garmin-backfill/garmin-backfill.module';
import { MeasurementWidgetModule } from '../common/measurement-widget/measurement-widget.module';
import { ProfileImageModule } from '../common/profile-image/profile-image.module';
import { ReactionsModule } from '../common/reactions/reactions.modue';
import { ScrollModule } from '../common/scroll/scroll.module';
import { UserFilterModule } from '../common/user-filter/user-filter.module';
import { ActivitiesComponent } from './activities.component';

@NgModule({
  declarations: [ActivitiesComponent],
  exports: [ActivitiesComponent],
  imports: [
    CommonModule,
    ConfirmationModule,
    GalleryModule,
    GarminBackfillModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MeasurementWidgetModule,
    ProfileImageModule,
    ReactionsModule,
    ScrollModule,
    UserFilterModule,
  ],
})
export class ActivitiesModule {}
