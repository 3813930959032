import { Injectable } from '@angular/core';
import {
  CompleteLogInRequest,
  CompleteLogInResponse,
  GetLoginStatusRequest,
  GetLoginStatusResponse,
  LogOutRequest,
  LogOutResponse,
  StartLogInRequest,
  StartLogInResponse,
} from 'generated/src/main/proto/api/fitbit-service.pb';
import { FitbitServiceClient } from 'generated/src/main/proto/api/fitbit-service.pbsc';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

/** Fitbit-related methods for user login to Fitbit. */
@Injectable({
  providedIn: 'root',
})
export class FitbitService {
  constructor(
    private fitbitServiceClient: FitbitServiceClient,
    private userService: UserService,
    private bannerService: BannerService,
  ) {}

  /** Starts login by generating code verifier on the backend. */
  async startLogin(): Promise<StartLogInResponse> {
    return lastValueFrom(
      this.fitbitServiceClient
        .startLogin(new StartLogInRequest(), this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Completes login by exchanging the auth code for access and refresh tokens on the backend. */
  async completeLogin(
    code: string,
    redirectUri: string,
  ): Promise<CompleteLogInResponse> {
    return lastValueFrom(
      this.fitbitServiceClient
        .completeLogin(
          new CompleteLogInRequest({ code: code, redirectUri: redirectUri }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Gets Fitbit login status. */
  async getLoginStatus(): Promise<GetLoginStatusResponse> {
    return lastValueFrom(
      this.fitbitServiceClient
        .getLoginStatus(
          new GetLoginStatusRequest(),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Deletes the tokens. */
  async logOut(): Promise<LogOutResponse> {
    return lastValueFrom(
      this.fitbitServiceClient
        .logOut(
          new LogOutRequest(),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
