import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EditRtcModule } from 'src/app/common/edit-rtc/edit-rtc.module';
import { EndTimeValidatorDirective } from './end-time-validator.directive';
import { EventEditorComponent } from './event-editor.component';

@NgModule({
  declarations: [EventEditorComponent, EndTimeValidatorDirective],
  exports: [EventEditorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    EditRtcModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
  ],
})
export class EventEditorModule {}
