import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

import { MatButtonModule } from '@angular/material/button';
import { EditRtcComponent } from './edit-rtc.component';

@NgModule({
  declarations: [EditRtcComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    RichTextEditorAllModule,
  ],
  exports: [EditRtcComponent],
})
export class EditRtcModule {}
