import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgxEchartsModule } from 'ngx-echarts';
import { EditOptionModule } from '../common/edit-option/edit-option.module';
import { EditTextModule } from '../common/edit-text/edit-text.module';
import { HrZonesChartModule } from '../common/hr-zones-chart/hr-zones-chart.module';
import { UserFilterModule } from '../common/user-filter/user-filter.module';
import { StatsComponent } from './stats.component';

@NgModule({
  declarations: [StatsComponent],
  imports: [
    CdkAccordionModule,
    CdkTableModule,
    CommonModule,
    EditOptionModule,
    EditTextModule,
    FormsModule,
    HrZonesChartModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTableModule,
    NgxEchartsModule,
    UserFilterModule,
  ],
  exports: [StatsComponent],
})
export class StatsModule {}
