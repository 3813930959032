import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Summary } from 'generated/src/main/proto/api/activity-service.pb';
import { ActivityType } from 'generated/src/main/proto/shared/activity-shared.pb';
import { MediaContentType } from 'generated/src/main/proto/shared/media-shared.pb';
import { ItemData } from 'src/app/common/gallery/gallery.component';
import { FormatService, Measurement } from '../format.service';
import { MediaService } from '../media-service/media.service';
import { UnitPreference } from '../user/user.service';

export class ActivitySummary {
  private static readonly MIN_ELEVATION_GAIN = 50; // meters
  private static readonly MIN_ELEVATION_DROP = 50; // meters

  public summary: Summary;

  activityType: string;
  measurements: Measurement[];
  startTime: Measurement;
  duration: Measurement;

  galleryItems: ItemData[] = [];

  descriptionHtml!: SafeHtml;

  constructor(
    summary: Summary,
    private formatService: FormatService,
    domSanitizer: DomSanitizer,
  ) {
    this.summary = summary;
    this.activityType = FormatService.toDisplay(summary.activityType);
    this.measurements = this.getDisplayMeasurements();

    // Start time.
    const startTime = this.formatService.formatDateToMeasurement(
      this.summary.totalStats!.startTime!.toDate(),
    );
    startTime.title = 'Start';
    startTime.shortTitle = 'Start';
    this.startTime = startTime;

    // Duration.
    this.duration = FormatService.formatDuration(
      this.summary.totalStats!.startTime!,
      this.summary.totalStats!.endTime!,
    );

    // Description.
    if (this.summary.noteHtml && this.summary.noteHtml.length > 0) {
      this.descriptionHtml = domSanitizer.bypassSecurityTrustHtml(
        this.summary.noteHtml,
      );
    }

    this.updateGalleryItems();
  }

  private updateGalleryItems() {
    const items: ItemData[] = [];

    // Put user images first.
    const medias = this.summary?.medias;
    if (medias) {
      for (let i = 0; i < medias.length; i++) {
        const media = medias[i];
        const smallSizeUrl = MediaService.getImageSrc(media.smallSizeId);
        const fullSizeUrl = MediaService.getImageSrc(media.fullSizeId);
        const item: ItemData = {};
        if (media.contentType == MediaContentType.MEDIA_CONTENT_TYPE_VIDEO) {
          item.videoSrc = smallSizeUrl;
          if (media.fullSizeId != media.smallSizeId) {
            item.videoFullSizeSrc = fullSizeUrl;
          }
        } else {
          item.imageSrc = smallSizeUrl;
          if (media.fullSizeId != media.smallSizeId) {
            item.imageFullSizeSrc = fullSizeUrl;
          }
        }
        items.push(item);
      }
    }

    // Put map second.
    if (this.summary.mapMedia) {
      items.push({
        imageSrc: MediaService.getImageSrc(this.summary.mapMedia.mediaId),
      });
    }

    this.galleryItems = items;
  }

  private getDisplayMeasurements(): Measurement[] {
    const measurements: Measurement[] = [];

    // More than 3 items don't fit on mobile.
    let measurementNames: string[];
    switch (this.summary.activityType) {
      case ActivityType.ACTIVITY_TYPE_WALK:
        measurementNames = ['elevationGain', 'distance', 'calories'];
        break;
      case ActivityType.ACTIVITY_TYPE_RUN:
        measurementNames = ['elevationGain', 'distance', 'pace'];
        break;
      case ActivityType.ACTIVITY_TYPE_ALPINE_SKI:
        measurementNames = ['elevationDrop', 'distance'];
        break;
      case ActivityType.ACTIVITY_TYPE_CROSS_COUNTRY_SKI:
      case ActivityType.ACTIVITY_TYPE_ROAD_BIKE:
      case ActivityType.ACTIVITY_TYPE_MOUNTAIN_BIKE:
        measurementNames = ['elevationGain', 'distance'];
        break;
      case ActivityType.ACTIVITY_TYPE_POOL_SWIM:
      case ActivityType.ACTIVITY_TYPE_OPEN_SWIM:
        measurementNames = ['distance', 'heartRate', 'calories'];
        break;
      case ActivityType.ACTIVITY_TYPE_YOGA:
        measurementNames = ['heartRate', 'calories'];
        break;
      case ActivityType.ACTIVITY_TYPE_WORKOUT:
      default:
        measurementNames = ['distance', 'heartRate', 'calories'];
        break;
    }

    measurementNames.forEach((name) => {
      switch (name) {
        case 'distance':
          {
            const distance = this.summary.totalStats?.distance;
            if (distance) {
              measurements.push(
                this.formatService.formatDistanceByActivityType(
                  distance,
                  this.summary!.activityType,
                ),
              );
            }
          }
          break;
        case 'elevationGain':
          {
            const elevationGain = this.summary.totalStats?.elevationGain;
            if (
              elevationGain &&
              elevationGain > ActivitySummary.MIN_ELEVATION_GAIN
            ) {
              measurements.push(
                this.formatService.formatElevation(elevationGain),
              );
            }
          }
          break;
        case 'elevationDrop':
          {
            const elevationDrop = this.summary.totalStats?.elevationDrop;
            if (
              elevationDrop &&
              -elevationDrop > ActivitySummary.MIN_ELEVATION_DROP
            ) {
              measurements.push(
                this.formatService.formatElevation(elevationDrop),
              );
            }
          }
          break;
        case 'heartRate':
          {
            const hr = this.summary.totalStats?.heartRateStats?.mean;
            if (hr) {
              measurements.push(this.formatService.formatHeartRate(hr));
            }
          }
          break;
        case 'calories':
          {
            const calories = this.summary.totalStats?.calories;
            if (calories) {
              measurements.push(this.formatService.formatCalories(calories));
            }
          }
          break;
        case 'pace':
          {
            if (
              this.formatService.getUnitPreference() == UnitPreference.Imperial
            ) {
              let pace = this.summary.totalStats!.milePace!;
              if (!pace) {
                pace = this.summary.totalStats!.milePaceStats!.mean!;
              }
              if (pace) {
                measurements.push(this.formatService.formatPace(pace, true));
              }
            } else {
              let pace = this.summary.totalStats!.kmPace!;
              if (!pace) {
                pace = this.summary.totalStats!.kmPaceStats!.mean!;
              }
              if (pace) {
                measurements.push(this.formatService.formatPace(pace, false));
              }
            }
          }
          break;
      }
    });

    return measurements;
  }
}
