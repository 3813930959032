import { CommonModule, formatDate } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CalendarDateFormatter,
  CalendarModule,
  DateAdapter,
  DateFormatterParams,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UserFilterModule } from '../common/user-filter/user-filter.module';
import { CalComponent } from './cal.component';
import { EventEditorModule } from './event-editor/event-editor.module';

/** Customizes formatting of dates. */
@Injectable()
class CalDateFormatter extends CalendarDateFormatter {
  public override monthViewColumnHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    // use short week days
    return formatDate(date, 'EEE', locale ?? 'en');
  }

  public override weekViewColumnHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    // use short week days
    return formatDate(date, 'EEE', locale ?? 'en');
  }
}

@NgModule({
  declarations: [CalComponent],
  exports: [CalComponent],
  imports: [
    CommonModule,
    EventEditorModule,
    FormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    UserFilterModule,
  ],
  providers: [{ provide: CalendarDateFormatter, useClass: CalDateFormatter }],
})
export class CalModule {}
