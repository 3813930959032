import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FitbitLoginStatus,
  GetLoginStatusResponse,
} from 'generated/src/main/proto/api/fitbit-service.pb';
import {
  BannerMessage,
  BannerService,
} from 'src/app/services/banner/banner.service';
import { FitbitService } from 'src/app/services/fitbit/fitbit.service';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'app-fitibit-login',
  templateUrl: './fitibit-login.component.html',
  styleUrls: ['./fitibit-login.component.scss'],
})
export class FitibitLoginComponent implements OnInit {
  // Whether the component is part of redirect or not.
  @Input()
  redirect = false;

  loginStatusDisplay = '';
  fitbitUserId = '';
  private loginStatus = FitbitLoginStatus.FITBIT_LOGIN_STATUS_UNSPECIFIED;

  constructor(
    private fitbitService: FitbitService,
    private activatedRoute: ActivatedRoute,
    private bannerService: BannerService,
    private router: Router,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.redirect) {
      const authCode = this.activatedRoute.snapshot.queryParamMap.get('code');
      const redirectUri =
        window.location.protocol +
        '//' +
        window.location.hostname +
        window.location.pathname;
      this.fitbitService.completeLogin(authCode!, redirectUri).then(() => {
        this.bannerService.add(new BannerMessage('Logged-in to Fitbit'));
        this.router.navigate(['/profile']);
      });
    } else {
      this.fitbitService
        .getLoginStatus()
        .then((r) => this.processLoginStatusResponse(r));
    }
  }

  onFitbitLogin() {
    this.fitbitService.startLogin().then((r) => {
      const url = new URL(environment.fitbit.authorizeUrl);
      url.searchParams.append('client_id', r.applicationClientId);
      url.searchParams.append('scope', r.scope);
      url.searchParams.append('code_challenge', r.codeChallenge);
      url.searchParams.append('code_challenge_method', r.codeChallengeMethod);
      url.searchParams.append('response_type', r.responseType);
      window.location.href = url.href;
    });
  }

  get logInDisabled(): boolean {
    return this.loginStatus == FitbitLoginStatus.FITBIT_LOGIN_STATUS_LOGGED_IN;
  }

  get logOutDisabled(): boolean {
    return (
      this.loginStatus == FitbitLoginStatus.FITBIT_LOGIN_STATUS_NO_RECORD ||
      this.loginStatus == FitbitLoginStatus.FITBIT_LOGIN_STATUS_UNSPECIFIED
    );
  }

  onFitbitLogout() {
    ConfirmationComponent.openDialog(
      this.matDialog,
      'Log out will remove connection to Fitbit. Confirm?',
      (result) => {
        if (result) {
          this.fitbitService.logOut().then((r) => {
            this.fitbitUserId = '';
            this.loginStatusDisplay = 'Not logged in';
            this.loginStatus = r.loginStatus;
          });
        }
      },
    );
  }

  private processLoginStatusResponse(r: GetLoginStatusResponse) {
    this.fitbitUserId = r.fitbitUserId;
    switch (r.loginStatus) {
      case FitbitLoginStatus.FITBIT_LOGIN_STATUS_LOGGED_IN:
        this.loginStatusDisplay = 'Logged In';
        break;
      case FitbitLoginStatus.FITBIT_LOGIN_STATUS_NO_TOKEN:
        this.loginStatusDisplay = 'Invalid token';
        break;
      default:
      case FitbitLoginStatus.FITBIT_LOGIN_STATUS_NO_RECORD:
        this.loginStatusDisplay = 'Not logged in';
        break;
    }
    this.loginStatus = r.loginStatus;
  }
}
