import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { UserPublicDetails } from 'generated/src/main/proto/api/user-service.pb';
import { StateSaverService } from 'src/app/services/state-saver/state-saver.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserFilter } from './user-filter';

/** Provides a filter for users. */
@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent implements OnInit, OnDestroy {
  @Input()
  stateName = 'userFilter';

  @Input()
  disableAll = false;

  @Output()
  userFilterChange = new EventEmitter<UserFilter>();

  @Input()
  userFilter: UserFilter = new UserFilter();

  userQueryPrefix = '';
  autoSuggestedUsers: UserPublicDetails[] = [];
  @ViewChild('userAutoSuggest')
  userAutoSuggest!: MatOption;
  @ViewChild('userQueryInput')
  userQueryInput!: ElementRef<HTMLInputElement>;

  constructor(
    private stateSaverService: StateSaverService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    const state = this.stateSaverService.get(this.stateName);
    if (state.state && state.state.has('userFilter')) {
      this.userFilter = state.state.get('userFilter') as UserFilter;
    }
    if (this.userFilter && this.userFilter.followingAlias) {
      this.userService
        .getUserDetails([this.userFilter.followingAlias])
        .then((r) => {
          this.userQueryInput.nativeElement.value = this.formatUserForOption(
            r[0],
          );
        });
    }
    this.userFilterChange.emit(this.userFilter);
  }

  ngOnDestroy(): void {
    const position = {
      x: 0,
      y: 0,
    };

    this.stateSaverService.set(this.stateName, {
      scrollPosition: position,
      state: new Map<string, object>([['userFilter', this.userFilter]]),
    });
  }

  onFilterAll() {
    if (!this.userFilter.all) {
      const userFilter = new UserFilter();
      userFilter.setAll();
      this.userFilterChange.emit(userFilter);
      this.userQueryInput.nativeElement.value = '';
    }
  }

  onFilterLoggedInUser() {
    if (!this.userFilter.loggedInUser) {
      const userFilter = new UserFilter();
      userFilter.setLoggedInUser();
      this.userFilterChange.emit(userFilter);
      this.userQueryInput.nativeElement.value = '';
    }
  }

  formatUserForOption(user: UserPublicDetails): string {
    return `${user.firstName} ${user.lastName} (@${user.alias})`;
  }

  onUserQueryClick(e: MouseEvent) {
    e.stopPropagation();
  }

  onUserQueryKeyDown(e: KeyboardEvent) {
    e.stopPropagation();
  }

  onUserQueryFocus(e: FocusEvent) {
    this.userQueryInput.nativeElement.value = '';
    this.autoSuggestedUsers = [];
    e.stopPropagation();
  }

  onQueryPrefixChanged() {
    this.userService
      .autoSuggestUsers(this.userQueryPrefix)
      .then((r) => (this.autoSuggestedUsers = r));
  }

  onUserSelected() {
    this.autoSuggestedUsers = [];

    const userFilter = new UserFilter();
    const user: UserPublicDetails = this.userAutoSuggest.value;
    userFilter.setFollowingAlias(user.alias);
    this.userQueryInput.nativeElement.value = this.formatUserForOption(user);
    this.userFilterChange.next(userFilter);
  }
}
