import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { Summary } from 'generated/src/main/proto/api/activity-service.pb';
import { UserPublicDetails } from 'generated/src/main/proto/api/user-service.pb';
import { ReactionType } from 'generated/src/main/proto/shared/reaction.pb';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ReactionNameAndColor,
  ReactionsShared,
} from '../common/reactions/reactions.shared';
import { UserService } from '../services/user/user.service';

export type Reaction = {
  reactionType: ReactionType;
  timestamp: Timestamp;
  nameAndColor: ReactionNameAndColor;
  userLoaded: boolean;
  name: string;
  alias: string;
};

export class ReactionSource extends DataSource<Reaction> {
  private reactionSubject = new BehaviorSubject<readonly Reaction[]>([]);
  private allReactions: Reaction[] = [];

  private static reactionTypesMap: Map<ReactionType, ReactionNameAndColor> =
    ReactionSource.initReactionMap();

  constructor(
    private userService: UserService,
    private paginator: MatPaginator,
    summary: Summary,
  ) {
    super();

    // Copy reactions, sort by time in descending order.
    const reactions = summary.reactions!.reactionsByAlias;
    Object.keys(reactions).forEach((alias) => {
      const reaction = reactions[alias];
      this.allReactions.push({
        reactionType: reaction.reactionType,
        timestamp: reaction.createdTime!,
        nameAndColor: ReactionSource.reactionTypesMap.get(
          reaction.reactionType,
        )!,
        alias: alias,
        userLoaded: false,
        name: '',
      });
    });
    this.allReactions.sort(
      (a, b) => parseInt(b.timestamp.seconds) - parseInt(a.timestamp.seconds),
    );

    // Load initial page.
    this.loadPage(paginator.pageIndex * paginator.pageSize, paginator.pageSize);

    // Subscribe to page events.
    this.paginator.page.subscribe((pageEvent) =>
      this.loadPage(
        pageEvent.pageIndex * pageEvent.pageSize,
        pageEvent.pageSize,
      ),
    );

    this.paginator.length = this.allReactions.length;
  }

  private static initReactionMap(): Map<ReactionType, ReactionNameAndColor> {
    const map = new Map<ReactionType, ReactionNameAndColor>();
    ReactionsShared.allReactionIconNameAndColors.forEach((descritor) =>
      map.set(descritor.reactionType, descritor),
    );
    return map;
  }

  override connect(): Observable<readonly Reaction[]> {
    return this.reactionSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  override disconnect(): void {}

  private loadPage(startIndex: number, count: number): void {
    const reactions = this.allReactions.slice(startIndex, startIndex + count);

    // Get user details.
    const aliases: string[] = [];
    reactions.forEach((reaction) => {
      if (!reaction.userLoaded) {
        aliases.push(reaction.alias);
      }
    });
    const usersPromise =
      aliases.length > 0
        ? this.userService.getUserDetails(aliases)
        : Promise.resolve([]);

    // Update reactions and notify view.
    usersPromise.then((users) => {
      const usersByAlias = new Map<string, UserPublicDetails>();
      users.forEach((user) => usersByAlias.set(user.alias, user));
      reactions.forEach((reaction) => {
        if (!reaction.userLoaded) {
          const user = usersByAlias.get(reaction.alias)!;
          reaction.name = user.firstName + ' ' + user.lastName;
          reaction.userLoaded = true;
        }
      });

      this.reactionSubject.next(reactions);
    });
  }
}
