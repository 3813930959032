import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';
import { AppConfigProvider } from './environments/app-config';

AppConfigProvider.initialize((config) => {
  registerLicense(config.syncFusionlicenseKey);

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
