<div class="contents">
  <!-- Authenticated -->
  <div *ngIf="socialUser || updateMode && !loggingIn">
    <div [ngStyle]="{ width: width + 'px', margin: margin + 'px' }">
      <div>
        <!-- Email -->
        <mat-form-field [floatLabel]="'always'" class="field email">
          <mat-label>Email</mat-label>
          <input matInput readonly [(ngModel)]="email" required [ngModelOptions]="{ standalone: true }"
            matTooltip="Email from login" />
        </mat-form-field>

        <!-- Profile picture upload -->
        <input #profileImageInput type="file" style="display: none" (change)="updateProfileImage($event)" />
        <button *ngIf="updateMode" class="profile-image-button" mat-raised-button (click)="profileImageInput.click()"
          matTooltip="Click to upload profile image.">
          <img *ngIf="profileImageUrl" width="60" height="60" class="profile-image" src="{{ profileImageUrl }}"
            alt="Profile image" />
          <div *ngIf="!profileImageUrl" class="upload-div">Image</div>
        </button>
      </div>

      <br />

      <span class="required-fields">Required fields:</span>

      <br />

      <!-- First Name -->
      <mat-form-field [floatLabel]="'always'" class="field">
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="firstName" required [ngModelOptions]="{ standalone: true }"
          matTooltip="First name to show" />
      </mat-form-field>
      <!-- Last Name -->
      <mat-form-field [floatLabel]="'always'" class="field last-name">
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="lastName" required [ngModelOptions]="{ standalone: true }"
          matTooltip="Last name to show" />
      </mat-form-field>

      <br />

      <!-- Alias -->
      <mat-form-field [floatLabel]="'always'" class="field alias">
        <mat-label>Alias</mat-label>
        <input matInput readonly="{{ updateMode }}" [(ngModel)]="alias"
          matTooltip="Unique alias to use as an @ handle, lower case and digits" required appAliasValidator
          [currentAlias]="loggedInUser.alias" autocomplete="off" maxlength="30" pattern="[a-z,0-9]{2,30}"
          [ngModelOptions]="{ standalone: true }" />
      </mat-form-field>

      <!-- Unit preference -->
      <mat-form-field [floatLabel]="'always'" class="field unit-preference">
        <mat-label>Unit Preference</mat-label>
        <mat-select [(ngModel)]="unitPreference" (change)="onUnitPreferenceChanged()"
          matTooltip="Units to use for metrics" required>
          <mat-option *ngFor="let unit of unitPreferenceValues" [value]="unit">{{ unit }}</mat-option>
        </mat-select>
      </mat-form-field>

      <br />

      <!-- Time zone -->
      <mat-form-field [floatLabel]="'always'" class="field time-zone">
        <mat-label>Time zone</mat-label>
        <mat-select [(ngModel)]="timeZone" matTooltip="Time zone (used in stats)" required>
          <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone">{{ timeZone }}</mat-option>
        </mat-select>
      </mat-form-field>

      <hr />

      <!-- Fields for computing metrics: calories -->
      <div role="region">
        <!-- Date of birth -->
        <mat-form-field [floatLabel]="'always'" class="field date">
          <mat-label>Date of Birth</mat-label>
          <input matInput type="date" [(ngModel)]="dateOfBirth" (ngModelChange)="onDateOfBirthChanged()"
            matTooltip="Age is used for calories." [ngModelOptions]="{ standalone: true }" />
          <mat-hint>{{ dateFormat }}</mat-hint>
        </mat-form-field>

        <!-- Sex -->
        <mat-form-field [floatLabel]="'always'" class="field sex">
          <mat-label>Sex</mat-label>
          <mat-select [(ngModel)]="sex" matTooltip="Sex is used for calories.">
            <mat-option *ngFor="let value of sexValues" [value]="value">{{
              value
              }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Weight -->
        <mat-form-field [floatLabel]="'always'" class="field weight">
          <mat-label>{{ weightLabel }}</mat-label>
          <input matInput [(ngModel)]="weight" autocomplete="off" appNumberValidator [minValue]="10"
            matTooltip="Weight is used for calories." [maxValue]="1000" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>

        <hr />

        <!-- Heart rate limits for computing zones. -->
        <mat-checkbox class="field" [(ngModel)]="useAutomaticHeartRateLimits"
          (ngModelChange)="onUseAutomaticHeartRateLimitsChanged()"
          matTooltip="{{ useAutomaticHeartRateLimits ? 'Click to use manually set limits' : 'Click to use automatic limits' }}">Use
          auto HR limits</mat-checkbox>
        <br />

        <!-- Rest heart rate -->
        <mat-form-field [floatLabel]="'always'" class="field heart-rate">
          <mat-label>Rest HR</mat-label>
          <input matInput [readonly]="useAutomaticHeartRateLimits" [(ngModel)]="restHeartRate" autocomplete="off"
            appNumberValidator [minValue]="40" [maxValue]="150"
            matTooltip="Rest heart rate is your average lowest heart rate." [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>

        <!-- Max heart rate -->
        <mat-form-field [floatLabel]="'always'" class="field heart-rate">
          <mat-label>Max HR</mat-label>
          <input matInput [readonly]="useAutomaticHeartRateLimits" [(ngModel)]="maxHeartRate" autocomplete="off"
            appNumberValidator [minValue]="restHeartRate" [maxValue]="220"
            matTooltip="Max heart rate is your maximum heart rate during very hard exercise."
            [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>

      <br />

      <cdk-accordion class="accordion" [ngStyle]="{ 'max-width': width + 'px' }">
        <!-- Locations for use in people search and recommendations. -->
        <cdk-accordion-item class="accordion-item" #accordionLocationsItem="cdkAccordionItem" role="button"
          (expandedChange)="onLocationsExpanded($event)">
          <div class="accordion-item-header" tabindex="0" (click)="accordionLocationsItem.toggle()"
            (keydown)="onAccordionItemKeyDown(accordionLocationsItem)"
            matTooltip="If not set, people search and recommendations will be lower quality.">
            Locations
            <span class="material-symbols-outlined">{{ accordionLocationsItem.expanded ? 'expand_less' : 'expand_more'
              }}</span>
          </div>
          <div role="region" [ngStyle]="{
              display: accordionLocationsItem.expanded ? '' : 'none'
            }">
            <div class="location-search-container">
              <!-- Table with current locations -->
              <div *ngIf="locations.length === 0" class="locations-intro-text">
                Pick up to 5 location(s) by searching below, press 'Save' button
                to store. The locations are used for location-based search of
                people and other recommendations. Adjust who can see your
                locations in Privacy section.
              </div>
              <mat-table *ngIf="locations.length > 0" class="summaries-table" [ngStyle]="{ width: width + 'px' }"
                [dataSource]="locations">
                <!-- Place Name -->
                <ng-container cdkColumnDef="name">
                  <th cdk-header-cell class="locations-header-cell" *cdkHeaderCellDef>
                    Name
                  </th>
                  <td cdk-cell *cdkCellDef="let location" class="locations-cell locations-title-cell">
                    {{ location.placeName }}
                  </td>
                </ng-container>

                <!-- Location -->
                <ng-container cdkColumnDef="location">
                  <th cdk-header-cell class="locations-header-cell" *cdkHeaderCellDef>
                    Location
                  </th>
                  <td cdk-cell *cdkCellDef="let location" class="locations-cell locations-coordinates"
                    (click)="onLocationClick(location)">
                    {{ formatLocationCoordinates(location) }}
                  </td>
                </ng-container>

                <!-- Delete location -->
                <ng-container cdkColumnDef="delete">
                  <th cdk-header-cell class="locations-header-cell" *cdkHeaderCellDef></th>
                  <td cdk-cell *cdkCellDef="let location; let dataIndex = dataIndex">
                    <div class="locations-cell">
                      <button mat-mini-fab color="white" class="location-delete-button"
                        matTooltip="Click to delete this location" (click)="onLocationDelete(dataIndex)">
                        <mat-icon class="material-symbols-outlined location-delete-icon">delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr cdk-header-row *cdkHeaderRowDef="locationsColumns"></tr>
                <tr cdk-row *cdkRowDef="
                    let row;
                    columns: locationsColumns;
                    let locationIndex = dataIndex
                  "></tr>
              </mat-table>

              <!-- Map -->
              <div #locationMap class="location-map" [ngStyle]="{ width: width + 'px', height: mapHeight + 'px' }">
                <div class="location-find-card">
                  <div class="location-find-card-content">
                    <mat-icon class="location-find-card-icon">search</mat-icon>
                    <input #locationFindInput class="location-find-input" matInput (focus)="onLocationFindInputFocus()"
                      (click)="onLocationFindInputClick()" />
                  </div>
                  <div #locationAutocompleteList></div>
                </div>
              </div>
            </div>
          </div>
        </cdk-accordion-item>

        <!-- Integrations with platforms. -->
        <cdk-accordion-item *ngIf="updateMode" class="accordion-item" #accordionIntegrationsItem="cdkAccordionItem"
          role="button">
          <div class="accordion-item-header" tabindex="0" (click)="accordionIntegrationsItem.toggle()"
            (keydown)="onAccordionItemKeyDown(accordionIntegrationsItem)"
            matTooltip="Integrations with other platforms.">
            Integrations
            <span class="material-symbols-outlined">{{ accordionIntegrationsItem.expanded ? 'expand_less' :
              'expand_more' }}</span>
          </div>
          <div role="region" class="integrations" [ngStyle]="{
            display: accordionIntegrationsItem.expanded ? '' : 'none'
          }">
            <mat-form-field [floatLabel]="'always'" class="field">
              <mat-label>External Integration</mat-label>
              <mat-select [(ngModel)]="integrationPlatform"
                matTooltip="Click to enable/disable sync with other platforms" required>
                <mat-option *ngFor="let platform of integrationPlatforms" [value]="platform">{{ platform }}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="field" [ngSwitch]="integrationPlatform">
              <!-- Fitbit -->
              <app-fitibit-login *ngSwitchCase="'Fitbit'"></app-fitibit-login>
              <!-- Garmin -->
              <app-garmin-login *ngSwitchCase="'Garmin'"></app-garmin-login>
            </div>
          </div>
        </cdk-accordion-item>

        <!-- Privacy controls -->
        <cdk-accordion-item class="accordion-item" #accordionPrivacyControlsItem="cdkAccordionItem" role="button">
          <div class="accordion-item-header" tabindex="0" (click)="accordionPrivacyControlsItem.toggle()"
            (keydown)="onAccordionItemKeyDown(accordionPrivacyControlsItem)"
            matTooltip="Privacy controls - who can see what.">
            Privacy
            <span class="material-symbols-outlined">{{ accordionPrivacyControlsItem.expanded ? 'expand_less' :
              'expand_more' }}</span>
          </div>
          <div role="region" class="privacy-controls" [ngStyle]="{
              display: accordionPrivacyControlsItem.expanded ? '' : 'none'
            }">
            <div class="privacy-control">
              <span><b>Location</b> is used in user search. Recommended: Public.</span>
              <app-privacy-control [(userDataAcl)]="privacyControls.locationAcl"></app-privacy-control>
            </div>
            <div class="privacy-control">
              <span><b>Health metrics</b>, for example heart rate, can be shown in graphs/stats.</span>
              <app-privacy-control [disablePublic]="true"
                [(userDataAcl)]="privacyControls.healthMetricsAcl"></app-privacy-control>
            </div>
            <div class="privacy-control">
              <span>Who can see your <b>calendar</b>. Recommended: Followers.</span>
              <app-privacy-control [(userDataAcl)]="privacyControls.calendarAcl"></app-privacy-control>
            </div>
            <div class="privacy-control">
              <span>Who can see your <b>activities</b>. Recommended: Followers.</span>
              <!-- Public is disabled to comply with FitBit Platform Terms Of Service -->
              <app-privacy-control [disablePublic]="true"
                [(userDataAcl)]="privacyControls.activitiesAcl"></app-privacy-control>
            </div>
            <div class="privacy-control">
              <mat-checkbox class="field" [(ngModel)]="privacyControls.autoApproveFollowRequests"
                matTooltip="If checked, anybody can start following you">Auto-approve follow
                requests</mat-checkbox>
            </div>
            <div class="privacy-controls-explained">
              <ul>
                <li>Start/end of activities is automatically obfuscated.</li>
                <li>Private - only logged in user can see/use the data.</li>
                <li>Followers - logged in user and followers can see/use the data.</li>
                <li>Public - any logged-in user can see/use the data.</li>
              </ul>
            </div>
          </div>
        </cdk-accordion-item>

        <!-- Notiication controls -->
        <cdk-accordion-item class="accordion-item" #accordionNotificationControlsItem="cdkAccordionItem" role="button">
          <div class="accordion-item-header" tabindex="0" (click)="accordionNotificationControlsItem.toggle()"
            (keydown)="onAccordionItemKeyDown(accordionNotificationControlsItem)"
            matTooltip="Notification controls - when to notify.">
            Notifications
            <span class="material-symbols-outlined">{{ accordionNotificationControlsItem.expanded ? 'expand_less' :
              'expand_more' }}</span>
          </div>
          <div role="region" class="notification-controls" [ngStyle]="{
              display: accordionNotificationControlsItem.expanded ? '' : 'none'
            }">
            <div class="notification-control">
              <span><b>Activities</b> New activities uploaded or synced.</span>
              <app-notification-control [(control)]="notificationControls.activities"></app-notification-control>
            </div>
            <div class="notification-control">
              <span><b>New requests</b> New follower requests.</span>
              <app-notification-control
                [(control)]="notificationControls.newFollowerRequests"></app-notification-control>
            </div>
            <div class="notification-control">
              <span><b>Following</b> Your requests to follow were approved.</span>
              <app-notification-control [(control)]="notificationControls.newFollowing"></app-notification-control>
            </div>
            <div class="notification-controls-explained">
              <ul>
                <li>Disabled - disables all notifications.</li>
                <li>Enabled For Web - enables notifications for Web app.</li>
              </ul>
            </div>
          </div>
        </cdk-accordion-item>

        <!-- Cookies policy -->
        <cdk-accordion-item class="accordion-item" #accordionCookiesItem="cdkAccordionItem" role="button">
          <div class="accordion-item-header" tabindex="0" (click)="accordionCookiesItem.toggle()"
            (keydown)="onAccordionItemKeyDown(accordionCookiesItem)" matTooltip="Cookies policy to accept." [ngStyle]="{
              'background-color': !acceptCookies ? 'orange' : 'inherit'
            }">
            Cookies Policy
            <span class="material-symbols-outlined">{{ accordionCookiesItem.expanded ? 'expand_less' : 'expand_more'
              }}</span>
          </div>
          <div role="region" class="policy" [ngStyle]="{ display: accordionCookiesItem.expanded ? '' : 'none' }">
            <app-cookies [maxHeight]="'300px'"></app-cookies>
            <br />
          </div>
        </cdk-accordion-item>

        <!-- Privacy policy -->
        <cdk-accordion-item class="accordion-item" #accordionPrivacyItem="cdkAccordionItem" role="button">
          <div class="accordion-item-header" tabindex="0" (click)="accordionPrivacyItem.toggle()"
            (keydown)="onAccordionItemKeyDown(accordionPrivacyItem)" matTooltip="Privacy policy to accept." [ngStyle]="{
              'background-color': !acceptPrivacy ? 'orange' : 'inherit'
            }">
            Privacy Policy
            <span class="material-symbols-outlined">{{ accordionPrivacyItem.expanded ? 'expand_less' : 'expand_more'
              }}</span>
          </div>
          <div role="region" class="policy" [ngStyle]="{ display: accordionPrivacyItem.expanded ? '' : 'none' }">
            <app-privacy [maxHeight]="'300px'"></app-privacy>
            <mat-checkbox [(ngModel)]="acceptPrivacy" matTooltip="Click to accept privac policy." required
              [ngModelOptions]="{ standalone: true }">Accept Privacy Policy</mat-checkbox>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>

      <div class="buttons-container">
        <button *ngIf="updateMode" mat-raised-button class="delete-button" color="warn"
          matTooltip="Delete logged-in user along with all activities." (click)="delete()">
          Delete Profile
        </button>

        <button mat-raised-button class="save-button" [disabled]="!fieldsChanged()" matTooltip="Save the changes."
          (click)="save()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
