import { Injectable } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  AddRequest,
  AddResponse,
  DeleteRequest,
  DeleteResponse,
  Event,
  GetRequest,
  GetResponse,
  UpdateRequest,
  UpdateResponse,
} from 'generated/src/main/proto/api/event-service.pb';
import { EventServiceClient } from 'generated/src/main/proto/api/event-service.pbsc';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    private eventServiceClient: EventServiceClient,
    private bannerService: BannerService,
    private userService: UserService,
  ) {}

  /** Reads events for the logged-in user or all events available to the user. */
  async get(
    loggedInOrAll: boolean,
    startTime: Date,
    endTime: Date,
    alias?: string,
    readTime?: Timestamp,
  ): Promise<GetResponse> {
    const request = new GetRequest({
      startTime: Timestamp.fromDate(startTime),
      endTime: Timestamp.fromDate(endTime),
    });
    if (alias) {
      request.alias = alias;
    } else if (loggedInOrAll) {
      request.loggedInUser = true;
    } else {
      request.followingAndSelf = true;
    }
    if (readTime) {
      request.readTime = readTime;
    }
    return lastValueFrom(
      this.eventServiceClient
        .get(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Adds an event for the logged-in user. */
  async add(event: Event): Promise<AddResponse> {
    const request = new AddRequest({
      event: event,
    });
    return lastValueFrom(
      this.eventServiceClient
        .add(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Updates an event for the logged-in user. */
  async update(event: Event): Promise<UpdateResponse> {
    const request = new UpdateRequest({
      event: event,
    });
    return lastValueFrom(
      this.eventServiceClient
        .update(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Deletes an event. */
  async delete(eventId: string): Promise<DeleteResponse> {
    return lastValueFrom(
      this.eventServiceClient
        .delete(
          new DeleteRequest({
            eventId: eventId,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
