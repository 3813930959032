export class DocsTableOfContents {
  title: string = '';
  path: string = '';
  file?: string;
  level = 0;
  items?: DocsTableOfContents[];

  initialize(): void {
    this.setPrototype();
    this.path = '/';
    if (this.items) {
      this.items.forEach((item) => item.setPath(0, ''));
    }
  }

  getFilePath(basePath: string): string {
    if (this.file) {
      const index = this.path.lastIndexOf('/');
      return basePath + this.path.substring(0, index + 1) + this.file;
    } else {
      return basePath + this.path + '.md';
    }
  }

  find(path: string): DocsTableOfContents | undefined {
    if (this.path == path) {
      return this;
    }
    if (this.items) {
      for (const item of this.items) {
        const found = item.find(path);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  private setPath(level: number, parentPath: string): void {
    this.path = parentPath + '/' + this.path;
    this.level = level;
    if (this.items) {
      this.items.forEach((item) => item.setPath(level + 1, this.path));
    }
  }

  private setPrototype(): void {
    if (this.items) {
      this.items.forEach((item) => {
        Object.setPrototypeOf(item, DocsTableOfContents.prototype);
        item.setPrototype();
      });
    }
  }
}
