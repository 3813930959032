<div class="container" [ngStyle]="{ width: width + 'px' }">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.route"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </a>
  </nav>

  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
