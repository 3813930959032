<div class="container" [ngStyle]="{ width: viewWidth + 'px' }">
  <mat-tab-group #matTabGroup [ngStyle]="{ 'max-height': '80%' }">
    <mat-tab label="Comments" [ngStyle]="{ 'max-height': '80%' }">
      <!-- Existing comments -->
      <div *ngIf="commentsSource">
        <div *ngFor="
            let comment of commentsSource.allComments;
            let commentIndex = index
          " class="comment">
          <app-profile-image [alias]="comment.alias"></app-profile-image>
          <div class="comment-contents">
            <div class="comment-header">
              <span class="comment-name">{{ comment.name }}</span>
              <div class="comment-time" matTooltip="{{ comment.timeTooltip }}">
                {{ comment.timeText }}
              </div>
              <div class="comment-name-container">
                <button class="comment-delete-button" mat-icon-button matTooltip="Delete comment"
                  (click)="onDeleteComment(comment.id)">
                  <mat-icon class="material-symbols-outlined comment-delete-icon">delete</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <div class="comment-text">
                {{ comment.text }}
              </div>
            </div>
            <!-- Comment reaction -->
            <app-comment-reaction [reactionsByAlias]="comment.reactionsByAlias" [commentCreatorAlias]="comment.alias"
              (reactionChanged)="onCommentReactionChanged(comment.id, $event)"></app-comment-reaction>
          </div>
        </div>

        <!-- New comment -->
        <div class="comment">
          <app-profile-image [alias]="user!.alias!"></app-profile-image>
          <div class="comment-contents">
            <div class="comment-header">
              <div class="comment-name">
                {{ user?.details?.firstName + " " + user?.details?.lastName }}
              </div>
              <div class="comment-time" matTooltip="Remaining chars">
                {{ currentCommentRemainingChars + " chars" }}
              </div>
            </div>
            <textarea class="comment-text" placeholder="...reply" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5" [(ngModel)]="currentComment" maxlength="256"
              [ngModelOptions]="{ standalone: true }" (keydown)="onCommentKeyDown($event)"></textarea>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Reactions">
      <mat-table [dataSource]="reactionsSource">
        <!-- Reaction -->
        <<ng-container matColumnDef="reaction">
          <th mat-header-cell *matHeaderCellDef>Reaction</th>
          <td mat-cell *matCellDef="let reaction">
            <div class="icon-container">
              <mat-icon class="material-symbols-outlined foreground-icon">{{
                reaction.nameAndColor.name
                }}</mat-icon>
              <!-- Background color -->
              <mat-icon class="background-icon" [ngStyle]="{ color: reaction.nameAndColor.color }">{{
                reaction.nameAndColor.backgroundName }}</mat-icon>
            </div>
          </td>
          </ng-container>

          <!-- Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let reaction">{{ reaction.name }}</td>
          </ng-container>

          <!-- Alias -->
          <ng-container matColumnDef="alias">
            <th mat-header-cell *matHeaderCellDef>Alias</th>
            <td mat-cell *matCellDef="let reaction">{{ reaction.alias }}</td>
          </ng-container>

          <!-- Date -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let reaction">
              <div matTooltip="{{ formatDate(reaction.timestamp) }}">
                {{ formatDateSinceToday(reaction.timestamp) }}
              </div>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: reactionsDisplayColumns"></tr>
      </mat-table>

      <!-- Paginator -->
      <mat-paginator #reactionsPaginator [ngStyle]="{
          visibility: reactionsPaginator.length > 10 ? 'visible' : 'hidden'
        }" [pageSizeOptions]="[10, 20]" aria-label="Select page" matTooltip="Select page">
      </mat-paginator>
    </mat-tab>
  </mat-tab-group>
</div>
