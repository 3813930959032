import { catchError, first, lastValueFrom, map, tap, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  CommentToAdd,
  DeleteRequest,
  DeleteResponse,
  GetRequest,
  GetResponse,
  GetStatsRequest,
  GetStatsResponse,
  GetSummariesRequest,
  ReactionToAdd,
  SetReactionRequest,
  SetReactionResponse,
  Summary,
  UpdateCommentRequest,
  UpdateCommentResponse,
  UpdateRequest,
  UpdateResponse,
  UploadRequest,
  UploadResponse,
} from 'generated/src/main/proto/api/activity-service.pb';
import { ActivityServiceClient } from 'generated/src/main/proto/api/activity-service.pbsc';
import { StatsRequestTimeInterval } from 'generated/src/main/proto/shared/activity-shared.pb';
import { ReactionType } from 'generated/src/main/proto/shared/reaction.pb';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';
import { ActivitiesSource } from './activities-source';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(
    private activityServiceClient: ActivityServiceClient,
    private bannerService: BannerService,
    private userService: UserService,
    public allActivitiesSource: ActivitiesSource,
  ) {}

  /** Uploads activity file. */
  uploadActivity(
    data: Uint8Array,
    fileExtension: string | undefined,
  ): Promise<UploadResponse> {
    let fileType;
    if (fileExtension == 'fit') {
      fileType = UploadRequest.FileType.FILE_TYPE_FIT;
    } else if (fileExtension == 'gpx') {
      fileType = UploadRequest.FileType.FILE_TYPE_GPX;
    } else if (fileExtension == 'tcx') {
      fileType = UploadRequest.FileType.FILE_TYPE_TCX;
    } else {
      this.bannerService.add(
        new BannerMessage(
          'Invalid file extension, add ".fit" or ".gpx" to indicate its type.',
        ),
      );
      return Promise.reject('Invalid file extension');
    }
    const request = new UploadRequest({
      fileContents: data,
      fileType: fileType,
    });
    return lastValueFrom(
      this.activityServiceClient
        .upload(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        )
        .pipe(
          tap(() => {
            // TODO: remove this method after notifications are implemented.
            // this.allActivitiesSource.insertNewActivity();
          }),
          first(),
        ),
    );
  }

  async get(activityId: string): Promise<GetResponse> {
    const request = new GetRequest({ activityId: activityId });
    return lastValueFrom(
      this.activityServiceClient
        .get(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async getStats(
    timeIntervals: StatsRequestTimeInterval[],
    alias: string | undefined,
  ): Promise<GetStatsResponse> {
    const request = new GetStatsRequest({
      alias: alias,
      timeIntervals: timeIntervals,
    });
    return lastValueFrom(
      this.activityServiceClient
        .getStats(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async getSummary(activityId: string): Promise<Summary> {
    const request = new GetSummariesRequest({
      activityIds: { activityIds: [activityId] },
      startIndex: 0,
      count: 1,
    });
    return lastValueFrom(
      this.activityServiceClient
        .getSummaries(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => {
      const summary = r.summaries![0];
      this.allActivitiesSource.updateSummary(summary);
      return summary;
    });
  }

  async getSummaries(activityIds: string[]): Promise<Summary[]> {
    const request = new GetSummariesRequest({
      activityIds: { activityIds: activityIds },
      startIndex: 0,
      count: activityIds.length,
    });
    return lastValueFrom(
      this.activityServiceClient
        .getSummaries(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => r.summaries!);
  }

  async saveSummary(summary: Summary): Promise<UpdateResponse> {
    const request = new UpdateRequest({
      activityId: summary.activityId,
      summary: summary,
    });
    return lastValueFrom(
      this.activityServiceClient
        .update(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        )
        .pipe(
          map((r) => {
            if (r instanceof UpdateResponse) {
              this.allActivitiesSource.updateSummary(summary);
            }
            return r;
          }),
        ),
    );
  }

  async delete(activityId: string | undefined): Promise<DeleteResponse> {
    if (!activityId) {
      throw 'Activity id must be defined.';
    }
    return lastValueFrom(
      this.activityServiceClient
        .delete(
          new DeleteRequest({ activityId: activityId }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => {
      this.allActivitiesSource.deleteActivity(activityId);
      return r;
    });
  }

  async setReaction(
    activityId: string,
    reactionType: ReactionType,
  ): Promise<SetReactionResponse> {
    const request = new SetReactionRequest({
      activityId: activityId,
      reactionType: reactionType,
    });
    return lastValueFrom(
      this.activityServiceClient
        .setReaction(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => {
      this.allActivitiesSource.updateReactions(activityId, r.reactions!);
      return r;
    });
  }

  async updateComment(
    activityId: string,
    text: string,
    replytoId: number | undefined,
    deleteId: number | undefined,
  ): Promise<UpdateCommentResponse> {
    const request = new UpdateCommentRequest({
      activityId: activityId,
    });
    if (deleteId != undefined) {
      // Workaround for proto oneof not distinguishing 0 from absent value.
      request.deleteIdPlusOne = deleteId + 1;
    } else {
      request.commentToAdd = new CommentToAdd({
        text: text,
        replyToId: replytoId,
      });
    }
    return lastValueFrom(
      this.activityServiceClient
        .updateComment(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async updateCommentReaction(
    activityId: string,
    commentId: number,
    reactionType: ReactionType,
  ): Promise<UpdateCommentResponse> {
    const request = new UpdateCommentRequest({
      activityId: activityId,
      reactionToAdd: new ReactionToAdd({
        commentId: commentId,
        reactionType: reactionType,
      }),
    });
    return lastValueFrom(
      this.activityServiceClient
        .updateComment(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
