import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Reaction,
  ReactionType,
} from 'generated/src/main/proto/shared/reaction.pb';
import { UserService } from 'src/app/services/user/user.service';

/** Shows reaction to a comment. */
@Component({
  selector: 'app-comment-reaction',
  templateUrl: './comment-reaction.component.html',
  styleUrl: './comment-reaction.component.scss',
})
export class CommentReactionComponent implements OnInit, OnChanges {
  private userAlias: string;

  @Input()
  reactionsByAlias!: { [prop: string]: Reaction };

  @Input()
  commentCreatorAlias!: string;

  @Output()
  reactionChanged = new EventEmitter<ReactionType>();

  showIconButton = false;
  likeText: string | undefined;
  iconColor = '';

  constructor(userService: UserService) {
    this.userAlias = userService.snapshotUser?.alias ?? '';
  }

  ngOnInit(): void {
    this.updateProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reactionsByAlias'] || changes['commentCreatorAlias']) {
      this.updateProperties();
    }
  }

  onAddReactionClick(e: MouseEvent): void {
    this.reactionChanged.emit(ReactionType.REACTION_TYPE_HEART);
    e.stopPropagation();
  }

  private updateProperties(): void {
    this.showIconButton = this.userAlias != this.commentCreatorAlias;
    if (!this.reactionsByAlias) {
      this.iconColor = '';
      this.likeText = undefined;
    } else {
      // Icon color.
      this.iconColor =
        this.reactionsByAlias[this.userAlias] != undefined
          ? 'red'
          : 'transparent';

      // Like text.
      const keys = Object.keys(this.reactionsByAlias);

      keys.sort((a, b) => {
        const first = this.reactionsByAlias[a];
        const second = this.reactionsByAlias[b];
        const timeDiff =
          first.createdTime!.toDate().getTime() -
          second.createdTime!.toDate().getTime();
        return -timeDiff;
      });

      if (keys.length > 0) {
        const lastLikeAlias = keys[0];
        let text =
          lastLikeAlias == this.userAlias
            ? 'Liked by you'
            : 'Liked by @' + lastLikeAlias;
        if (keys.length > 1) {
          text += ` and ${keys.length - 1} others`;
        }
        this.likeText = text;
      } else {
        this.likeText = undefined;
      }
    }
  }
}
