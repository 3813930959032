import {
  HttpClient,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UploadRequest,
  UploadResponse,
} from 'generated/src/main/proto/api/media-service.pb';
import { MediaServiceClient } from 'generated/src/main/proto/api/media-service.pbsc';
import { MediaContentType } from 'generated/src/main/proto/shared/media-shared.pb';
import { catchError, lastValueFrom, of, tap } from 'rxjs';
import { AppConfigProvider } from 'src/environments/app-config';
import { getSessionId } from '../api.auth';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public static readonly MAX_FILE_SIZE = 100 << 20;

  constructor(
    private mediaServiceClient: MediaServiceClient,
    private bannerService: BannerService,
    private userService: UserService,
    private httpClient: HttpClient,
  ) {}

  async uploadMedia(data: Uint8Array): Promise<UploadResponse> {
    if (data.length > MediaService.MAX_FILE_SIZE) {
      return Promise.reject('Data is too big.');
    }
    return lastValueFrom(
      this.mediaServiceClient
        .upload(
          new UploadRequest({ mediaBytes: data }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of(new UploadResponse());
          }),
        ),
    );
  }

  async postMedia(
    data: Blob,
    progressListener: (doneBytes: number, totalBytes: number) => void,
  ): Promise<UploadResponse> {
    const url = MediaService.getMediaUrl();
    return lastValueFrom(
      this.httpClient
        .post(url.toString(), data, {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
          withCredentials: true,
        })
        .pipe(
          catchError((e) => {
            console.error('Post error', e);
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of(new UploadResponse());
          }),
        )
        .pipe(
          tap((e) => {
            // Report progress to the listener.
            try {
              const pe = e as HttpProgressEvent;
              if (pe.type == HttpEventType.UploadProgress) {
                progressListener(pe.loaded, pe.total!);
              }
            } catch (e) {
              console.error('Error in progress', e);
            }
          }),
        ),
    ).then((e) => {
      if (e instanceof UploadResponse) {
        return e;
      } else {
        const response = e as HttpResponse<Blob>;
        return response.body!.text().then((text) => {
          const media = JSON.parse(text);
          const contentType =
            media.contentType as keyof typeof MediaContentType;
          return new UploadResponse({
            smallSizeId: media.smallSizeId,
            fullSizeId: media.fullSizeId,
            contentType: MediaContentType[contentType],
          });
        });
      }
    });
  }

  static getImageSrc(id: string): string {
    const url = MediaService.getMediaUrl();
    url.searchParams.set('id', id);
    return url.toString();
  }

  static getProfileImageSrc(alias: string): string {
    const url = MediaService.getMediaUrl();
    url.searchParams.set('profile', alias);
    return url.toString();
  }

  private static getMediaUrl(): URL {
    const url = new URL(AppConfigProvider.config.apiServerHost + '/rest/media');
    if (window.location.hostname.startsWith('localhost')) {
      const sessionId = getSessionId();
      if (sessionId) {
        url.searchParams.set('session', sessionId);
      }
    }
    return url;
  }
}
