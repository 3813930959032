import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MeasurementWidgetModule } from '../measurement-widget/measurement-widget.module';
import { GarminBackfillComponent } from './garmin-backfill.component';

@NgModule({
  declarations: [GarminBackfillComponent],
  exports: [GarminBackfillComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatNativeDateModule,
    MeasurementWidgetModule,
  ],
})
export class GarminBackfillModule {}
