import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrl: './progress-spinner.component.scss',
})
export class ProgressSpinnerComponent {
  @Input()
  progressPercent = 0;

  get progressText(): string {
    return `${this.progressPercent} %`;
  }
}
