import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { FormatService } from 'src/app/services/format.service';

export type EventEditorData = {
  // input-only data
  readOnly?: boolean;
  eventUserAlias?: string;
  eventUserFirstName?: string;
  eventUserLastName?: string;
  ownedEvent?: boolean;

  // input/output data
  title: string;
  descriptionHtml: string;
  start: Date;
  end: Date;
  allDay: boolean;
};

/** Dialog for editing events. */
@Component({
  selector: 'app-event-editor',
  templateUrl: './event-editor.component.html',
  styleUrls: ['./event-editor.component.scss'],
})
export class EventEditorComponent {
  private static readonly TIME_FORMAT = 'h:mm a';

  readOnly = false;

  dialogWidth: number;
  titleWidth: number;
  descriptionWidth: number;

  startDate = new Date();
  startTime = '';
  endDate = new Date(this.startDate);
  endTime = '';
  allDay = false;

  title = '';
  descriptionHtml = '';

  constructor(
    public dialogRef: MatDialogRef<EventEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EventEditorData | undefined,
    formatService: FormatService,
  ) {
    const margin = 7;
    this.dialogWidth = formatService.viewWidth - 2 * margin;
    this.titleWidth = Math.round(0.9 * this.dialogWidth);
    this.descriptionWidth = Math.round(0.9 * this.dialogWidth);

    if (data) {
      this.readOnly = data.readOnly ?? false;
      this.title = data.title;
      this.descriptionHtml = data.descriptionHtml;
      this.allDay = data.allDay;
      if (data.allDay) {
        this.startDate = data.start;
        this.startTime = '';
        this.endDate = data.end;
        this.endTime = '';
      } else {
        const s = EventEditorComponent.toDateTime(data.start);
        this.startDate = s.date;
        this.startTime = s.time;
        const e = EventEditorComponent.toDateTime(data.end);
        this.endDate = e.date;
        this.endTime = e.time;
      }
    }
  }

  onStartDateChange() {
    this.endDate = this.startDate;
  }

  onStartTimeChange() {
    this.endTime = this.startTime;
  }

  get isSaveDisabled(): boolean {
    if (!this.allDay && (!this.startTime || !this.endTime)) {
      return true;
    }

    if (!this.title) {
      return true;
    }

    const startDate = EventEditorComponent.toDate(
      this.startDate,
      this.startTime,
      this.allDay,
    );
    const newEndDate = EventEditorComponent.toDate(
      this.endDate,
      this.endTime,
      this.allDay,
    );

    if (startDate > newEndDate) {
      return true;
    }

    const newData = this.getDialogData();
    return JSON.stringify(newData) == JSON.stringify(this.data);
  }

  validateEndTime(newEndTime: string): boolean {
    if (!newEndTime) {
      return false;
    }

    const startDate = EventEditorComponent.toDate(
      this.startDate,
      this.startTime,
      this.allDay,
    );
    const newEndDate = EventEditorComponent.toDate(
      this.endDate,
      newEndTime,
      this.allDay,
    );

    return startDate <= newEndDate;
  }

  save() {
    this.dialogRef.close(this.getDialogData());
  }

  private getDialogData(): EventEditorData {
    return {
      title: this.title,
      descriptionHtml: this.descriptionHtml,
      start: EventEditorComponent.toDate(
        this.startDate,
        this.startTime,
        this.allDay,
      ),
      end: EventEditorComponent.toDate(this.endDate, this.endTime, this.allDay),
      allDay: this.allDay,
    };
  }

  private static toDate(date: Date, timeStr: string, allDay: boolean): Date {
    if (!timeStr || allDay) {
      return date;
    }
    const time = DateTime.fromFormat(timeStr, EventEditorComponent.TIME_FORMAT);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.hour,
      time.minute,
    );
  }

  private static toDateTime(date: Date): { date: Date; time: string } {
    const datePart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
    const timePart = DateTime.fromJSDate(date).toFormat(
      EventEditorComponent.TIME_FORMAT,
    );
    return { date: datePart, time: timePart };
  }
}
