<!-- Main menu -->
<mat-menu #menu="matMenu">
  <!-- Public components -->
  <ng-container *ngTemplateOutlet="
  menuItem;
  context: { path: '/home', title: 'Home' }
">
  </ng-container>
  <!-- Non public components -->
  <div *ngIf="loggedIn">
    <hr />
    <ng-container *ngTemplateOutlet="
        menuItem;
        context: { path: '/activities', title: 'Activities' }
      ">
    </ng-container>
    <ng-container *ngTemplateOutlet="
        menuItem;
        context: { path: '/calendar/' + user!.alias, title: 'Calendar' }
      ">
    </ng-container>
    <ng-container *ngTemplateOutlet="
        menuItem;
        context: { path: '/people', title: 'People' }
      ">
    </ng-container>
    <ng-container *ngTemplateOutlet="
        menuItem;
        context: { path: '/profile', title: 'Profile', imageUrl: user!.profileImageUrl }
      ">
    </ng-container>
    <ng-container *ngTemplateOutlet="
        menuItem;
        context: { path: '/stats/' + user!.alias, title: 'Stats' }
      ">
    </ng-container>
    <ng-container *ngTemplateOutlet="
        menuItem;
        context: { path: '/admin', title: 'Admin', checkInternal: true }
      ">
    </ng-container>
    <hr />
  </div>
  <!-- More public components -->
  <!-- Docs -->
  <ng-container *ngTemplateOutlet="
  menuItem;
  context: { path: '/docs', title: 'Docs' }
">
  </ng-container>
  <!-- Legal -->
  <ng-container *ngTemplateOutlet="
  menuItem;
  context: { path: '/legal/privacy-policy', title: 'Legal' }
">
  </ng-container>
  <!-- Toggle cookies choices dialog -->
  <button mat-menu-item (click)="toggleCookieBanner()">
    <div class="menu-item-container">
      <span>Cookies</span>
      <mat-pseudo-checkbox appearance="minimal" class="menu-check"
        [state]="isCookieBannerDisplayed() ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
    </div>
  </button>
</mat-menu>

<ng-template #menuItem let-path="path" let-title="title" let-imageUrl="imageUrl" let-checkInternal="checkInternal">
  <button *ngIf="!checkInternal || isInternalUser()" mat-menu-item routerLink="{{ path }}">
    <div class="menu-item-container">
      <span>{{ title }}
      </span>
      <div *ngIf="imageUrl" class="profile-image">
        <app-profile-image [alias]="''" [imageUrl]="imageUrl"></app-profile-image>
      </div>
      <mat-pseudo-checkbox appearance="minimal" class="menu-check"
        [state]="locationStartsWith(path) ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
    </div>
  </button>
</ng-template>

<div class="container">
  <!-- Header -->
  <mat-toolbar color="primary" class="header">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Main menu button">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="title">Jestle (Beta!)</span>

    <span class="flex-expand"></span>

    <div *ngIf="loggedIn">
      <!-- Notifications button -->
      <app-notifications></app-notifications>

      <!-- Log out button -->
      <button mat-raised-button (click)="logOut()" color="accent" class="logout" matTooltip="Click to logout">
        <mat-icon class="logout">logout</mat-icon>
      </button>

      <!-- Report issue component -->
      <app-issue-report></app-issue-report>
    </div>
    <div *ngIf="!loggedIn">
      <!-- Log in -->
      <asl-google-signin-button type="standard" size="large" logo_alignment="left"
        text="signin_with"></asl-google-signin-button>
    </div>
  </mat-toolbar>

  <!-- Contents of the children components -->
  <div class="contents" *ngIf="!loggingIn && (!loggedIn || policiesAccepted)">
    <div class="flex-expand"></div>
    <router-outlet></router-outlet>
    <div class="flex-expand"></div>
  </div>
  <div class="contents" *ngIf="loggedIn && !policiesAccepted">
    <div class="flex-expand"></div>
    <app-profile></app-profile>
    <div class="flex-expand"></div>
  </div>

  <!-- Banner for error messages. -->
  <app-banner></app-banner>

  <!-- Bottom ads -->
  <div id="adsense-bottom" class="adsense-unit"></div>
</div>
