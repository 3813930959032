import { Component, Inject } from '@angular/core';
import {
  DialogPosition,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

type ReactionsDialogData = {
  activityId: string;
};

@Component({
  selector: 'app-reactions-dialog',
  templateUrl: './reaction-details.dialog.html',
  styleUrls: ['./reaction-details.dialog.scss'],
})
export class ReactionDetailsDialogComponent {
  activityId: string;

  constructor(
    public dialogRef: MatDialogRef<ReactionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReactionsDialogData,
  ) {
    this.activityId = data.activityId;
  }

  public static openDialog(
    dialog: MatDialog,
    activityId: string,
    top: number,
    left: number,
    width: number,
    maxHeight: number,
  ): void {
    const position: DialogPosition = {
      left: left + 'px',
      top: top + 'px',
    };
    dialog.open(ReactionDetailsDialogComponent, {
      data: { activityId: activityId },
      width: width + 'px',
      minWidth: width,
      maxWidth: width,
      maxHeight: maxHeight,
      position: position,
    });
  }
}
