import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ReactionType,
  Reactions,
} from 'generated/src/main/proto/shared/reaction.pb';
import { Subscription, timer } from 'rxjs';
import {
  CommentConstants,
  ReactionNameAndColor,
  ReactionsShared,
} from './reactions.shared';

/**
 * Interface that is implemented by the host of this component.
 */
export interface ReactionUpdater {
  getUserAlias(): string;

  isUserAllowedToReact(): boolean;

  /** Sets reaction by the logged-in user. */
  setReaction(reactionType: ReactionType): Promise<Reactions>;

  /** Gets current reactions. */
  getReactions(): Reactions | undefined;

  /** Adds a comment. */
  addComment(text: string): Promise<Reactions>;
}

/** Displays like and comment buttons, when hovering over like it shows other choices. */
@Component({
  selector: 'app-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss'],
})
export class ReactionsComponent implements OnInit, OnChanges {
  @Input()
  updater?: ReactionUpdater;

  @Input()
  readOnly = false;

  @Input()
  hideComment = false;

  @Output()
  clickReactionsSummary = new EventEmitter();

  @ViewChild('reactionButton', { read: ElementRef })
  reactionButton!: ElementRef<HTMLButtonElement>;

  @ViewChild('reactionButtonsContainer', { read: ElementRef })
  reactionButtonsContainer!: ElementRef<HTMLDivElement>;

  reactions: Reactions | undefined;
  userAllowedToReact!: boolean;
  userAlias!: string;

  pointerCapturedElement?: HTMLElement;

  showReactionButtons = false;
  showReactionButtonsTimerSubscription?: Subscription;
  hideReactionButtonsTimerSubscription?: Subscription;

  allReactionIconNameAndColors: readonly ReactionNameAndColor[] =
    ReactionsShared.allReactionIconNameAndColors;

  currentCommentRemainingChars = CommentConstants.MAX_COMMENT_CHARS;
  private currentCommentValue = '';

  ngOnInit(): void {
    this.initReactions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).indexOf('updater') >= 0) {
      this.initReactions();
    }
  }

  private initReactions() {
    if (this.updater) {
      this.reactions = this.updater.getReactions();
      this.userAlias = this.updater.getUserAlias();
      this.userAllowedToReact = this.updater.isUserAllowedToReact();
    }
  }

  get hasUserReaction(): boolean {
    const reaction = this.reactions?.reactionsByAlias[this.userAlias];
    return (
      reaction?.reactionType != undefined &&
      reaction?.reactionType != ReactionType.REACTION_TYPE_UNSPECIFIED
    );
  }

  getReactionIconName(isBackground: boolean): string | undefined {
    const reaction = this.reactions?.reactionsByAlias[this.userAlias];
    const icon = ReactionsShared.getReactionIconNameAndColor(
      reaction ? reaction.reactionType : ReactionType.REACTION_TYPE_UNSPECIFIED,
    );
    const name = icon ? icon.name : 'thumb_up';
    if (isBackground && icon) {
      return this.getBackgroundReactionIconName(icon.reactionType, icon.name);
    }
    return name;
  }

  getBackgroundReactionIconName(
    reactionType: ReactionType,
    name: string,
  ): string {
    if (
      reactionType == ReactionType.REACTION_TYPE_SATISFIED ||
      reactionType == ReactionType.REACTION_TYPE_DISSATISFIED
    ) {
      // empty circle
      return 'circle';
    }
    return name;
  }

  get reactionColorName(): string {
    const reaction = this.reactions?.reactionsByAlias[this.userAlias];
    return ReactionsShared.getReactionIconNameAndColor(reaction!.reactionType)!
      .color;
  }

  get reactionButtonTooltip(): string {
    if (!this.userAllowedToReact) {
      return 'Reaction is disabled for self activities.';
    }
    return this.hasUserReaction
      ? 'Click to remove reaction.'
      : 'Click to add reaction';
  }

  get hasReactions(): boolean {
    if (!this.reactions) {
      return false;
    }
    return Object.keys(this.reactions!.reactionsByAlias).length > 0;
  }

  get reactionIconNameAndColors(): ReactionNameAndColor[] {
    if (!this.reactions) {
      return [];
    }

    const counts = new Map<ReactionType, number>();
    Object.keys(this.reactions!.reactionsByAlias).forEach((alias) => {
      const reaction = this.reactions!.reactionsByAlias[alias];
      let count = counts.get(reaction.reactionType);
      if (!count) {
        count = 0;
      }
      counts.set(reaction.reactionType, count + 1);
    });

    const result: ReactionNameAndColor[] = [];
    counts.forEach((count, reactionType) => {
      const r = ReactionsShared.getReactionIconNameAndColor(reactionType);
      r!.count = count;
      result.push(r!);
    });

    result.sort((a, b) => a.order - b.order);

    return result;
  }

  onAddLikeClick(e: MouseEvent) {
    const reaction = this.reactions?.reactionsByAlias[this.userAlias];
    const reactionType = reaction
      ? reaction.reactionType
      : ReactionType.REACTION_TYPE_LIKE;
    this.onAddReactionClick(reactionType, e);
  }

  onAddReactionClick(reactionType: ReactionType, e: MouseEvent) {
    // User cannot like their activities.
    if (this.userAllowedToReact) {
      if (this.updater) {
        this.updater.setReaction(reactionType).then((reactions) => {
          this.reactions = reactions;
        });
      }
    }
    e.stopPropagation();
    this.hideReactionButtons();
  }

  // TODO: mouseover/mouseout or mouseenter/mouseleave don't seem to work on iPhone.
  onMouseOverReactionButtons(e: MouseEvent) {
    this.mouseOverReactionButtons(e.target as HTMLElement);
  }

  onReactionButtonsFocus(e: FocusEvent) {
    this.mouseOverReactionButtons(e.target as HTMLElement);
  }

  private mouseOverReactionButtons(element: HTMLElement) {
    const reactionButtonsContainer =
      this.reactionButtonsContainer.nativeElement;
    if (
      this.isDescendant(element, reactionButtonsContainer) &&
      this.pointerCapturedElement != reactionButtonsContainer
    ) {
      this.pointerCapturedElement = reactionButtonsContainer;
      if (this.hideReactionButtonsTimerSubscription) {
        this.hideReactionButtonsTimerSubscription.unsubscribe();
        this.hideReactionButtonsTimerSubscription = undefined;
      }
    }
  }

  onMouseOutReactionButtons(e: MouseEvent) {
    this.mouseOutReactionButtons(e.relatedTarget as HTMLElement);
  }

  onBlurReactionButtons(e: FocusEvent) {
    this.mouseOutReactionButtons(e.relatedTarget as HTMLElement);
  }

  private mouseOutReactionButtons(element: HTMLElement): void {
    const reactionButtonsContainer =
      this.reactionButtonsContainer.nativeElement;
    if (
      reactionButtonsContainer === this.pointerCapturedElement &&
      !this.isDescendant(element, reactionButtonsContainer)
    ) {
      this.hideReactionButtons();
    }
  }

  onMouseOverLike(e: MouseEvent) {
    this.mouseOverLike(e.target as HTMLElement);
  }

  onFocusLike(e: FocusEvent) {
    this.mouseOverLike(e.target as HTMLElement);
  }

  private mouseOverLike(element: HTMLElement): void {
    const reactionButton = this.reactionButton.nativeElement;
    if (
      this.isDescendant(element, reactionButton) &&
      this.pointerCapturedElement != reactionButton
    ) {
      this.pointerCapturedElement = reactionButton;

      this.showReactionButtonsTimerSubscription = timer(1000).subscribe(() => {
        if (this.pointerCapturedElement === reactionButton) {
          this.showReactionButtons = this.userAllowedToReact;
          if (this.showReactionButtons) {
            // Start timer to hide the reaction buttons if the user never
            // hovers the mouse over them.
            this.hideReactionButtonsTimerSubscription = timer(5000).subscribe(
              () => {
                this.hideReactionButtons();
              },
            );
          }
        }
      });
    }
  }

  onMouseOutLike(e: MouseEvent) {
    this.mouseOutLike(e.relatedTarget as HTMLElement);
  }

  onBlurLike(e: FocusEvent) {
    this.mouseOutLike(e.relatedTarget as HTMLElement);
  }

  private mouseOutLike(element: HTMLElement): void {
    const reactionButton = this.reactionButton.nativeElement;
    if (
      reactionButton === this.pointerCapturedElement &&
      !this.isDescendant(element, reactionButton)
    ) {
      this.pointerCapturedElement = undefined;
    }
  }

  set currentComment(value: string) {
    this.currentCommentRemainingChars =
      CommentConstants.MAX_COMMENT_CHARS - value.length;
    this.currentCommentValue = value;
  }

  get currentComment(): string {
    return this.currentCommentValue;
  }

  onCommentKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      if (this.updater) {
        this.updater
          .addComment(this.currentCommentValue)
          .then((r) => (this.reactions = r));
      }
      this.currentComment = '';

      e.preventDefault();
      e.stopPropagation();
    }
  }

  onClickReactionSummary(e: MouseEvent) {
    this.clickReactionsSummary.emit();
    e.stopPropagation();
  }

  onReactionSummaryKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.clickReactionsSummary.emit();
      e.stopPropagation();
    }
  }

  private isDescendant(
    child: HTMLElement | null,
    parent: HTMLElement,
  ): boolean {
    while (child) {
      if (child === parent) {
        return true;
      }
      child = child.parentElement;
    }
    return false;
  }

  private hideReactionButtons(): void {
    if (this.showReactionButtonsTimerSubscription) {
      this.showReactionButtonsTimerSubscription.unsubscribe();
      this.showReactionButtonsTimerSubscription = undefined;
    }
    if (this.hideReactionButtonsTimerSubscription) {
      this.hideReactionButtonsTimerSubscription.unsubscribe();
      this.hideReactionButtonsTimerSubscription = undefined;
    }
    this.showReactionButtons = false;
  }
}
