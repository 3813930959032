import { CdkAccordionItem } from '@angular/cdk/accordion';
import { DataSource } from '@angular/cdk/collections';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  GetSummariesResponse,
  Issue,
  IssueSummary,
} from 'generated/src/main/proto/api/issue-service.pb';
import {
  IssueResolution,
  IssueState,
  IssueType,
  RemovedContent,
} from 'generated/src/main/proto/shared/issue-shared.pb';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { ConfirmationComponent } from 'src/app/common/confirmation/confirmation.component';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';
import { FormatService } from 'src/app/services/format.service';
import { IssueService } from 'src/app/services/issues/issue.service';
import { UserService } from 'src/app/services/user/user.service';
import BiDirectionalMap from 'ts-bidirectional-map';

/** Loads issues. */
class IssuesDataSource implements DataSource<IssueSummary> {
  private readonly issuesSubject = new BehaviorSubject<readonly IssueSummary[]>(
    [],
  );
  paginator!: MatPaginator;
  readTime?: Timestamp;

  constructor(private issueService: IssueService) {}

  setPaginator(paginator: MatPaginator): void {
    this.paginator = paginator;
    this.paginator.page.subscribe((pageEvent) =>
      this.loadPage(
        pageEvent.pageIndex * pageEvent.pageSize,
        pageEvent.pageSize,
      ),
    );
    this.loadPage(paginator.pageIndex * paginator.pageSize, paginator.pageSize);
  }

  get issues(): Observable<readonly IssueSummary[]> {
    return this.issuesSubject.asObservable();
  }

  connect(): Observable<readonly IssueSummary[]> {
    return this.issuesSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  updateSummary(summary: IssueSummary): void {
    const issues = Array.from(this.issuesSubject.getValue());
    const index = issues.findIndex((s) => s.issueId == summary.issueId);
    if (index >= 0) {
      issues[index] = summary;
      this.issuesSubject.next(issues);
    }
  }

  private loadPage(startIndex: number, count: number): void {
    timer(1).subscribe(() =>
      this.issueService
        .getSummaries(startIndex, count, this.readTime)
        .then((r: GetSummariesResponse) => {
          this.issuesSubject.next(r.issues!);

          this.readTime = r.readTime;

          if (r.issues!.length == this.paginator.pageSize) {
            // Expect more.
            this.paginator.length = startIndex + 2 * this.paginator.pageSize;
          } else {
            this.paginator.length = startIndex + r.issues!.length;
          }
        }),
    );
  }
}

/** Shows a table with issues and actions for them. */
@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss'],
})
export class IssuesComponent implements AfterViewInit {
  private static issueTypesMap = IssuesComponent.initIssueTypes();
  private static issueStatesMap = IssuesComponent.initIssueStates();
  private static resolutionTypesMap = IssuesComponent.initResolutionType();

  readonly flexImageRowClass: string;
  readonly issueDescriptionClass: string;
  readonly innerWidth: number;
  readonly screenshotImageWidth: number;

  readonly issueTypes: string[];
  readonly issueStates: string[];
  readonly resolutionTypes: string[];

  // Summaries.
  @ViewChild('issuesPaginator') issuesPaginator!: MatPaginator;
  private dataSource: IssuesDataSource;
  issuesObservable!: Observable<readonly IssueSummary[]>;
  issueIdFromParams?: string;

  // Details.
  selectedIssue?: Issue;
  issueType?: string;
  issueState?: string;
  consentToContactUser = false;
  comment = '';
  resolutionType?: string;
  removedActivityIds?: string;
  userAliasToDelete?: string;

  constructor(
    private issueService: IssueService,
    private formatService: FormatService,
    deviceDectorService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
  ) {
    // Styling.
    this.innerWidth = formatService.viewWidth - 2 * 7;
    this.screenshotImageWidth = deviceDectorService.isMobile()
      ? this.innerWidth
      : 300;
    this.flexImageRowClass = deviceDectorService.isMobile()
      ? 'flex-column'
      : 'flex-row';
    this.issueDescriptionClass = deviceDectorService.isMobile()
      ? 'issue-description-mobile'
      : 'issue-description';

    // Summaries.
    this.dataSource = new IssuesDataSource(issueService);
    this.issuesObservable = this.dataSource.issues;
    this.activatedRoute.paramMap.subscribe((params) => {
      this.issueIdFromParams = params.get('id')!;
    });

    // Details.
    this.issueTypes = Array.from(
      IssuesComponent.issueTypesMap.ForwardMap.keys(),
    ).sort();
    this.issueStates = Array.from(
      IssuesComponent.issueStatesMap.ForwardMap.keys(),
    ).sort();
    this.resolutionTypes = Array.from(
      IssuesComponent.resolutionTypesMap.ForwardMap.keys(),
    ).sort();
  }

  ngAfterViewInit(): void {
    // TODO: handle issue id from URL, this requires finding correct page to load.
    this.dataSource.setPaginator(this.issuesPaginator);
  }

  formatState(state: IssueState): string {
    return IssuesComponent.issueStatesMap.ReverseMap.get(state)!;
  }

  formatTime(time: Timestamp): string {
    return this.formatService.formatDate(time);
  }

  get formatContactConsent(): string {
    if (!this.selectedIssue) {
      return '';
    }

    const email = this.selectedIssue!.reporterEmail;
    const alias = this.selectedIssue!.reporterAlias;
    return !this.selectedIssue.summary!.consentToContactUser
      ? `'${alias}' didn't consent for contact`
      : `Ok to contact ${email} (${alias})`;
  }

  onAccordionItemKeyDown(e: KeyboardEvent, item: CdkAccordionItem) {
    if (e.key == 'Enter') {
      item.toggle();
      e.stopPropagation();
    }
  }

  onExpandedChanged(expanded: boolean, issueSummary: IssueSummary) {
    if (expanded) {
      this.issueService
        .get(issueSummary.issueId)
        .then((r) => this.selectIssue(r.issue!, false));
    } else {
      this.selectedIssue = undefined;
      this.issueType = undefined;
      this.issueState = undefined;
      this.consentToContactUser = false;
      this.comment = '';
      this.resolutionType = undefined;
      this.removedActivityIds = undefined;
      this.userAliasToDelete = undefined;
    }
  }

  private selectIssue(issue: Issue, updateSummary: boolean): void {
    this.selectedIssue = issue;
    const summary = this.selectedIssue.summary!;

    const imageElement: HTMLImageElement = document.getElementById(
      'screenshot' + summary.issueId,
    )! as HTMLImageElement;

    // Copy fields for editing.
    this.issueType = IssuesComponent.issueTypesMap.ReverseMap.get(
      summary!.type,
    );
    this.issueState = IssuesComponent.issueStatesMap.ReverseMap.get(
      summary!.state,
    );
    this.resolutionType = IssuesComponent.resolutionTypesMap.ReverseMap.get(
      summary.resolution?.type ??
        IssueResolution.IssueResolutionType.ISSUE_RESOLUTION_TYPE_UNSPECIFIED,
    );
    this.removedActivityIds = summary.resolution?.removedContents
      ?.removedActivityIds
      ? summary.resolution?.removedContents?.removedActivityIds.join(',')
      : '';
    this.consentToContactUser = summary!.consentToContactUser;

    // Show screenshot.
    if (this.selectedIssue?.screenshotBlobId) {
      imageElement.src = IssueService.getScreenshotImageSrc(
        this.selectedIssue?.screenshotBlobId,
      );
    }
    this.comment = '';

    // Update summary.
    if (updateSummary) {
      this.dataSource.updateSummary(summary);
    }
  }

  onDeleteUser() {
    const alias = this.userAliasToDelete!;
    ConfirmationComponent.openDialog(
      this.dialog,
      `Delete the user ('${alias}')?`,
      (result) => {
        if (result) {
          this.userService.delete(alias);
        }
      },
    );
  }

  get isSaveDisabled(): boolean {
    if (!this.selectedIssue) {
      return true;
    }
    const summary = this.selectedIssue.summary!;

    // State.
    const newIssueState = IssuesComponent.issueStatesMap.ForwardMap.get(
      this.issueState!,
    );
    const issueState = summary.state!;
    const requireComment = newIssueState == IssueState.ISSUE_STATE_RESOLVED;

    // Type.
    const newIssueType = IssuesComponent.issueTypesMap.ForwardMap.get(
      this.issueType!,
    );
    const issueType = summary.type!;

    // Resolution type.
    const newResolutionType = IssuesComponent.resolutionTypesMap.ForwardMap.get(
      this.resolutionType!,
    );
    const resolutionType =
      summary.resolution?.type ??
      IssueResolution.IssueResolutionType.ISSUE_RESOLUTION_TYPE_UNSPECIFIED;

    // Removed activity ids.
    const requireActivityIds =
      newResolutionType ==
      IssueResolution.IssueResolutionType
        .ISSUE_RESOLUTION_TYPE_ACTIVITY_REMOVED;
    let removedActivityIds: string[] = this.removedActivityIds
      ? this.removedActivityIds.split(',')
      : [];
    if (removedActivityIds.length > 0) {
      for (let i = 0; i < removedActivityIds.length; i++) {
        if (Number.isNaN(parseInt(removedActivityIds[i]))) {
          // Invalid number.
          removedActivityIds = [];
          break;
        }
      }
    }

    const resolving =
      newResolutionType !=
      IssueResolution.IssueResolutionType.ISSUE_RESOLUTION_TYPE_UNSPECIFIED;
    const isChanged =
      newIssueState != issueState ||
      newIssueType != issueType ||
      newResolutionType != resolutionType ||
      removedActivityIds.length > 0 ||
      this.comment.length > 0;
    const isAllowedToSave =
      (!requireComment || this.comment.length > 0) &&
      (!requireActivityIds || removedActivityIds.length > 0) &&
      ((!resolving && newIssueState != IssueState.ISSUE_STATE_RESOLVED) ||
        (resolving && newIssueState == IssueState.ISSUE_STATE_RESOLVED));

    return !isChanged || !isAllowedToSave;
  }

  onSave() {
    // State and type.
    const newIssueState = IssuesComponent.issueStatesMap.ForwardMap.get(
      this.issueState!,
    );
    const newIssueType = IssuesComponent.issueTypesMap.ForwardMap.get(
      this.issueType!,
    );

    // Resolution.
    let resolution: IssueResolution | undefined = undefined;
    const newResolutionType = IssuesComponent.resolutionTypesMap.ForwardMap.get(
      this.resolutionType!,
    );
    if (
      newResolutionType !=
      IssueResolution.IssueResolutionType.ISSUE_RESOLUTION_TYPE_UNSPECIFIED
    ) {
      const removedActivityIds: string[] = this.removedActivityIds
        ? this.removedActivityIds.split(',')
        : [];
      resolution = new IssueResolution({
        type: newResolutionType,
        comment: this.comment,
      });
      if (removedActivityIds.length > 0) {
        resolution.removedContents = new RemovedContent({
          removedActivityIds: removedActivityIds,
        });
      }
    }

    const issueId = this.selectedIssue?.summary?.issueId ?? '';
    this.issueService
      .update(issueId, newIssueState!, newIssueType!, this.comment, resolution)
      .then(() => this.issueService.get(issueId))
      .then((r) => this.selectIssue(r.issue!, true));
  }

  private static initIssueTypes(): BiDirectionalMap<string, IssueType> {
    const values = new BiDirectionalMap<string, IssueType>();
    for (const type in IssueType) {
      if (isNaN(Number(type))) {
        const key = type as keyof typeof IssueType;
        if (IssueType[key] != IssueType.ISSUE_TYPE_UNSPECIFIED) {
          let value = type.substring('ISSUE_TYPE_'.length);
          value = value
            .split('_')
            .map((s) => s[0] + s.substring(1).toLowerCase())
            .join(' ');
          values.set(value, IssueType[key]);
        }
      }
    }
    return values;
  }

  private static initIssueStates(): BiDirectionalMap<string, IssueState> {
    const values = new BiDirectionalMap<string, IssueState>();
    for (const state in IssueState) {
      if (isNaN(Number(state))) {
        const key = state as keyof typeof IssueState;
        if (IssueState[key] != IssueState.ISSUE_STATE_UNSPECIFIED) {
          let value = state.substring('ISSUE_STATE_'.length);
          value = value
            .split('_')
            .map((s) => s[0] + s.substring(1).toLowerCase())
            .join(' ');
          values.set(value, IssueState[key]);
        }
      }
    }
    return values;
  }

  private static initResolutionType(): BiDirectionalMap<
    string,
    IssueResolution.IssueResolutionType
  > {
    const values = new BiDirectionalMap<
      string,
      IssueResolution.IssueResolutionType
    >();
    for (const type in IssueResolution.IssueResolutionType) {
      if (isNaN(Number(type))) {
        const key = type as keyof typeof IssueResolution.IssueResolutionType;
        const unspecified =
          IssueResolution.IssueResolutionType[key] ==
          IssueResolution.IssueResolutionType.ISSUE_RESOLUTION_TYPE_UNSPECIFIED;
        let value = '';
        if (!unspecified) {
          value = type.substring('ISSUE_RESOLUTION_TYPE_'.length);
          value = value
            .split('_')
            .map((s) => s[0] + s.substring(1).toLowerCase())
            .join(' ');
        }
        values.set(value, IssueResolution.IssueResolutionType[key]);
      }
    }
    return values;
  }
}
