import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationControl } from 'generated/src/main/proto/shared/notification-shared.pb';

@Component({
  selector: 'app-notification-control',
  templateUrl: './notification-control.component.html',
  styleUrls: ['./notification-control.component.scss'],
})
export class NotificationControlComponent {
  @Input()
  control: NotificationControl | undefined = new NotificationControl({ webEnabled: true });
  @Output()
  controlChange = new EventEmitter<NotificationControl>();

  get isDisabled(): boolean {
    return this.control!.disabled;
  }

  setDisabled(value: boolean): void {
    this.control!.disabled = value;
    this.onChanged();
  }

  setWebEnabled(value: boolean): void {
    this.control!.webEnabled = value;
    this.onChanged();
  }

  private onChanged(): void {
    this.controlChange.next(this.control!);
  }
}
