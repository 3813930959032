import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { timer } from 'rxjs';

@Component({
  selector: 'app-edit-option',
  templateUrl: './edit-option.component.html',
  styleUrls: ['./edit-option.component.scss'],
})
export class EditOptionComponent {
  editMode = false;

  @Input()
  readOnly = false;

  @Input()
  tooltipName = '';

  @Input()
  optionValues: string[] = [];

  @Input()
  width: string | undefined;

  @Input()
  selectedOptionValue = '';
  @Output()
  selectedOptionValueChange = new EventEmitter();

  @Output()
  editDone = new EventEmitter();

  @ViewChild('editSelect')
  editSelect!: MatSelect;
  openSelect = false;

  toolTip(): string {
    return this.readOnly
      ? this.tooltipName
      : 'Click to edit ' + this.tooltipName;
  }

  onClick() {
    if (!this.readOnly) {
      this.flipEditMode();
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.flipEditMode();
    }
  }

  onSelectionChanged() {
    this.selectedOptionValueChange.emit(this.selectedOptionValue);
  }

  onSelectClosed() {
    this.editDone.emit();
    this.flipEditMode();
  }

  private flipEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.openSelect = true;
      timer(1).subscribe(() => {
        if (!this.editSelect.panelOpen) {
          this.editSelect.open();
        }
        this.openSelect = false;
      });
    }
  }
}
