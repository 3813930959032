import { environment } from './environment';

export type AppConfig = {
  // Firebase config.
  apiKey: string;
  appId: string;
  authDomain: string;
  databaseURL: string;
  mapsApiKey: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;

  // Rest of app settings.
  syncFusionlicenseKey: string;
  googleAuth2ClientId: string;
  apiServerHost: string;
  contentBucketUrl: string;
  messagingVapid: string;
};

/** Loads app config before Angular app is bootstrapped. */
export class AppConfigProvider {
  public static config: AppConfig;

  static initialize(callback: (config: AppConfig) => void): void {
    fetch(environment.config.configUrl, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        response.text().then((v) => {
          const config = JSON.parse(v) as AppConfig;
          AppConfigProvider.config = config;
          callback(config);
        });
      } else {
        console.log('Failed to get app config.');
      }
    });
  }
}
