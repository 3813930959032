import { Injectable } from '@angular/core';
import { BannerMessage, BannerService } from '../banner/banner.service';

@Injectable({
  providedIn: 'root',
})
export class ReadFileService {
  constructor(private bannerService: BannerService) {}

  readFile(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.loaded == event.total) {
          resolve(
            reader.result instanceof ArrayBuffer
              ? reader.result
              : new ArrayBuffer(0),
          );
        }
      };
      reader.onerror = () => {
        this.bannerService.add(
          new BannerMessage(
            'Failed to load file ' +
              file.name +
              ' error: ' +
              JSON.stringify(reader.error),
          ),
        );
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
