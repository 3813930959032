import { Component, Inject } from '@angular/core';
import {
  DialogPosition,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { BackfillState } from 'generated/src/main/proto/api/garmin-service.pb';
import { FormatService, Measurement } from 'src/app/services/format.service';

export type GarminBackfillResult = {
  startDate?: Date;
  endDate?: Date;
  submit: boolean;
};

/** Shows a dialog with current state of backfill and options for selecting backfill time range. */
@Component({
  selector: 'app-garmin-backfill',
  templateUrl: './garmin-backfill.component.html',
  styleUrl: './garmin-backfill.component.scss',
})
export class GarminBackfillComponent {
  disableSubmit: boolean;
  canSubmit: boolean;

  endDate: Date = new Date();
  startDate: Date = new Date(
    this.endDate.getFullYear() - 1,
    this.endDate.getMonth(),
    this.endDate.getDate(),
  );

  constructor(
    public dialogRef: MatDialogRef<GarminBackfillComponent>,
    @Inject(MAT_DIALOG_DATA) public backfillStates: BackfillState[],
    private formatService: FormatService,
  ) {
    this.canSubmit = backfillStates.filter((s) => !s.completedTime).length == 0;
    this.disableSubmit = !this.canSubmit;

    this.validateDates();
  }

  timestampMeasurement(title: string, timestamp: Timestamp): Measurement {
    const measurement = this.formatService.formatDateOnlyToMeasurement(
      timestamp.toDate(),
    );
    measurement.title = title;
    return measurement;
  }

  completedPercentageMeasurement(
    title: string,
    state: BackfillState,
  ): Measurement {
    let percentDone = 0;
    if (state.completedTime) {
      percentDone = 100;
    } else if (state.lastEndTime) {
      const start = state.startTime!.toDate().getTime();
      const end = state.endTime!.toDate().getTime();
      const complete = state.lastEndTime.toDate().getTime();
      percentDone = (100 * (complete - start)) / (end - start);
    }
    return this.formatService.formatPercent(title, percentDone);
  }

  onStartDateChange(): void {
    this.validateDates();
  }

  onEndDateChange(): void {
    this.validateDates();
  }

  validateDates(): void {
    const now = new Date().getTime();
    const seconds = (this.endDate.getTime() - this.startDate.getTime()) / 1000;
    const totalYears =
      (now - this.startDate.getTime()) / (1000 * 24 * 3600 * 365);
    if (
      seconds < 0 ||
      this.endDate.getTime() > now ||
      totalYears > 5 ||
      this.backfillStates.filter(s => this.doDatesOverlap(s)).length > 0
    ) {
      // Invalid.
      this.disableSubmit = true;
    } else {
      // Valid.
      this.disableSubmit = !this.canSubmit;
    }
  }

  private doDatesOverlap(state: BackfillState): boolean {
    const start = state.startTime!.toDate().getTime();
    const end = state.endTime!.toDate().getTime();
    return (
      Math.max(start, this.startDate.getTime()) <
      Math.min(end, this.endDate.getTime())
    );
  }

  static show(
    dialog: MatDialog,
    top: number,
    left: number,
    width: number,
    maxHeight: number,
    backfillStates: BackfillState[],
    onComplete: (result: GarminBackfillResult) => void,
  ): MatDialogRef<GarminBackfillComponent> {
    const position: DialogPosition = {
      left: left + 'px',
      top: top + 'px',
    };

    const dialogRef = dialog.open(GarminBackfillComponent, {
      data: backfillStates,
      width: width + 'px',
      minWidth: width,
      maxWidth: width,
      maxHeight: maxHeight,
      position: position,
    });
    dialogRef.afterClosed().subscribe((result) => onComplete(result));

    return dialogRef;
  }
}
