import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { UserPublicDetails } from 'generated/src/main/proto/api/user-service.pb';
import {
  CommentsContainer,
  Reaction,
} from 'generated/src/main/proto/shared/reaction.pb';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ItemLoadResult,
  LoadResult,
  ScrollDataSource,
} from 'src/app/common/scroll/scroll.component';
import { FormatService } from 'src/app/services/format.service';
import { UserService } from 'src/app/services/user/user.service';

export type Comment = {
  id: number;
  replyToId: number | undefined;
  text: string;
  timestamp: Timestamp;
  userLoaded: boolean;
  name: string;
  alias: string;
  reactionsByAlias: { [prop: string]: Reaction };

  // Display fields.
  timeText?: string;
  timeTooltip?: string;
};

export class CommentSource implements ScrollDataSource {
  private readonly commentsSubject = new BehaviorSubject<LoadResult>({
    resetView: false,
    items: new Map<number, ItemLoadResult>(),
  });
  allComments: Comment[] = [];
  private readonly usersByAlias = new Map<string, UserPublicDetails>();

  constructor(
    private userService: UserService,
    private fomatService: FormatService,
    commentsContainer: CommentsContainer | undefined,
  ) {
    this.updateComments(commentsContainer);
  }

  updateComments(commentsContainer: CommentsContainer | undefined): void {
    // Copy comments, sort by id except when replied to an earlier id.
    const newComments: Comment[] = [];
    const comments = commentsContainer?.comments;
    if (comments) {
      comments.forEach((c) => {
        newComments.push({
          id: c.id,
          replyToId: c.replyToId,
          text: c.text,
          reactionsByAlias: c.reactionsByAlias,
          timestamp: c.createdTime!,
          userLoaded: false,
          name: '',
          alias: c.alias,
        });
      });
    }
    newComments.sort((a, b) => {
      const aId = a.replyToId ?? a.id;
      const bId = b.replyToId ?? b.id;
      let ret = aId - bId;
      if (ret == 0) {
        ret = a.id - b.id;
      }
      return ret;
    });

    // Get user details.
    const aliases = new Set<string>();
    newComments.forEach((comment) => {
      if (!comment.userLoaded && !this.usersByAlias.has(comment.alias)) {
        aliases.add(comment.alias);
      }
    });
    // TODO: query in chunks of 20 or so.
    const aliasesList = Array.from(aliases);
    const usersPromise =
      aliases.size > 0
        ? this.userService.getUserDetails(aliasesList)
        : Promise.resolve([]);

    // Set default display fields while users are being loaded.
    newComments.forEach((comment) => this.setDisplayFields(comment));

    // Update comments.
    usersPromise.then((users) => {
      users.forEach((user) => this.usersByAlias.set(user.alias, user));
      newComments.forEach((comment) => {
        if (!comment.userLoaded) {
          const user = this.usersByAlias.get(comment.alias)!;
          comment.name = user.firstName + ' ' + user.lastName;
          comment.userLoaded = true;
        }
      });
      newComments.forEach((comment) => this.setDisplayFields(comment));
    });
    this.allComments = newComments;
  }

  connect(collectionViewer: CollectionViewer): Observable<LoadResult> {
    collectionViewer.viewChange.subscribe((range) => this.load(range));
    return this.commentsSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  private setDisplayFields(comment: Comment): void {
    comment.timeText = this.fomatService.formatDateSinceToday(
      comment.timestamp,
    );
    comment.timeTooltip = this.fomatService.formatDate(comment.timestamp);
  }

  private load(range: ListRange): void {
    const comments = this.allComments.slice(range.start, range.end);

    // Get user details.
    const aliases: string[] = [];
    comments.forEach((comment) => {
      if (!comment.userLoaded && !this.usersByAlias.has(comment.alias)) {
        aliases.push(comment.alias);
      }
    });
    const usersPromise =
      aliases.length > 0
        ? this.userService.getUserDetails(aliases)
        : Promise.resolve([]);

    // Update comments and notify view.
    usersPromise.then((users) => {
      users.forEach((user) => this.usersByAlias.set(user.alias, user));
      comments.forEach((comment) => {
        if (!comment.userLoaded) {
          const user = this.usersByAlias.get(comment.alias)!;
          comment.name = user.firstName + ' ' + user.lastName;
          comment.userLoaded = true;
        }
      });

      const items = new Map<number, ItemLoadResult>();
      let index = range.start;
      for (let i = 0; i < comments.length; i++) {
        items.set(index, {
          error: undefined,
          data: comments[i],
        });
        index++;
      }
      while (index < range.end) {
        items.set(index++, { error: undefined, data: undefined });
      }

      // Notify the view.
      this.commentsSubject.next({
        resetView: false,
        items: items,
      });
    });
  }
}
