import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxEchartsModule } from 'ngx-echarts';
import { HrZonesChartComponent } from './hr-zones-chart.component';

@NgModule({
  declarations: [HrZonesChartComponent],
  exports: [HrZonesChartComponent],
  imports: [CommonModule, NgxEchartsModule, MatIconModule, MatTooltipModule],
})
export class HrZonesChartModule {}
