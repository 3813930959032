import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

/** Video player component. */
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss',
})
export class VideoComponent implements OnInit {
  @Input()
  sourceUrl = '';

  @Input()
  width = 0;

  @Input()
  height = 0;

  @Input()
  isFullScreen = false;

  @ViewChild('video')
  videoRef!: ElementRef<HTMLVideoElement>;

  @ViewChild('progress')
  progressRef!: ElementRef<HTMLProgressElement>;
  currentTime: number = 0;
  isPlaying = false;

  private checkVisibilityTimerId = 0;

  private videoStyleDefault?: {
    [prop: string]: string;
  };
  private videoStyleFullScreen: {
    [prop: string]: string;
  } = { width: '100%', height: '100%', 'object-fit': 'contain' };

  ngOnInit(): void {
    this.videoStyleDefault = {
      width: this.width + 'px',
      height: this.height + 'px',
      'object-fit': 'cover',
    };
  }

  get videoStyle(): object {
    return this.isFullScreen
      ? this.videoStyleFullScreen
      : this.videoStyleDefault!;
  }

  onLoadedMetadata(): void {
    const video = this.videoRef.nativeElement;
    this.progressRef.nativeElement.max = video.duration;
    video.muted = true;
    if (this.isVideoVisible()) {
      this.startPlay();
    }
  }

  onTimeUpdated(): void {
    const video = this.videoRef.nativeElement;
    this.currentTime = this.videoRef.nativeElement.currentTime;
    this.isPlaying = !video.paused && !video.ended;
  }

  onPreventMouseDown(e: MouseEvent): void {
    e.stopPropagation();
  }

  onPlayPauseClick(e: MouseEvent): void {
    if (this.isPlaying) {
      this.pausePlay();
    } else {
      this.startPlay();
    }
    e.stopPropagation();
  }

  private startPlay(): void {
    const video = this.videoRef.nativeElement;
    video.play();
    this.checkVisibilityTimerId = window.setInterval(
      () => this.checkVisibility(),
      500,
    );
  }

  private checkVisibility(): void {
    if (!this.isVideoVisible()) {
      this.pausePlay();
    }
  }

  private pausePlay(): void {
    const video = this.videoRef.nativeElement;
    video.pause();
    window.clearInterval(this.checkVisibilityTimerId);
    this.checkVisibilityTimerId = 0;
}

  private isVideoVisible(): boolean {
    const video = this.videoRef.nativeElement;
    const r = video.getBoundingClientRect();
    const width = window.innerWidth;
    const height = window.innerHeight;
    return (
      Math.max(0, r.left) < Math.min(width, r.right) &&
      Math.max(0, r.top) < Math.min(height, r.bottom)
    );
  }

  onProgressKeyDown(e: KeyboardEvent): void {
    switch (e.key) {
      case 'b':
        this.setVideoTime(0);
        break;
      case 'm':
        this.setVideoTime(0.5);
        break;
      case 'e':
        this.setVideoTime(1);
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        this.setVideoTime((e.key.charCodeAt(0) - '0'.charCodeAt(0)) / 10);
        break;
    }

    e.stopPropagation();
  }

  onProgressClick(e: MouseEvent): void {
    const rect = this.progressRef.nativeElement.getBoundingClientRect();
    const left = rect.left;
    const right = left + rect.width;
    const x = Math.max(left, Math.min(right, e.clientX));

    this.setVideoTime((x - left) / (right - left));

    e.stopPropagation();
  }

  private setVideoTime(fraction: number): void {
    const video = this.videoRef.nativeElement;
    video.currentTime = video.duration * fraction;
  }

  onMuteClick(e: MouseEvent): void {
    const video = this.videoRef.nativeElement;
    video.muted = !video.muted;
    e.stopPropagation();
  }

  onVolumeClick(inc: number): void {
    const video = this.videoRef.nativeElement;
    const volume = Math.min(1, Math.max(0, video.volume + inc));
    video.volume = volume;
  }

  /** Sums up all buffered times. TODO: show individual ranges instead. */
  private getBufferedTime(): number {
    const video = this.videoRef.nativeElement;
    let totalBuffered = 0;
    for (let i = 0; i < video.buffered.length; i++) {
      totalBuffered += video.buffered.end(i) - video.buffered.start(i);
    }
    return totalBuffered;
  }
}
