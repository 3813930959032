/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum UnitType {
  UNIT_TYPE_UNSPECIFIED = 0,
  UNIT_TYPE_METRIC = 1,
  UNIT_TYPE_IMPERIAL = 2
}
export enum Sex {
  SEX_UNSPECIFIED = 0,
  SEX_MALE = 1,
  SEX_FEMALE = 2
}
export enum UserDataAccessLevel {
  USER_DATA_ACCESS_LEVEL_UNSPECIFIED = 0,
  USER_DATA_ACCESS_LEVEL_PRIVATE = 1,
  USER_DATA_ACCESS_LEVEL_FOLLOWERS = 2,
  USER_DATA_ACCESS_LEVEL_PUBLIC = 3
}
/**
 * Message implementation for shared.UserHeartRateLimits
 */
export class UserHeartRateLimits implements GrpcMessage {
  static id = 'shared.UserHeartRateLimits';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserHeartRateLimits();
    UserHeartRateLimits.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserHeartRateLimits) {
    _instance.automatic = _instance.automatic || undefined;
    _instance.manualOverride = _instance.manualOverride || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserHeartRateLimits,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.automatic = new HeartRateLimits();
          _reader.readMessage(
            _instance.automatic,
            HeartRateLimits.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.manualOverride = new HeartRateLimits();
          _reader.readMessage(
            _instance.manualOverride,
            HeartRateLimits.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserHeartRateLimits.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserHeartRateLimits,
    _writer: BinaryWriter
  ) {
    if (_instance.automatic) {
      _writer.writeMessage(
        1,
        _instance.automatic as any,
        HeartRateLimits.serializeBinaryToWriter
      );
    }
    if (_instance.manualOverride) {
      _writer.writeMessage(
        2,
        _instance.manualOverride as any,
        HeartRateLimits.serializeBinaryToWriter
      );
    }
  }

  private _automatic?: HeartRateLimits;
  private _manualOverride?: HeartRateLimits;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserHeartRateLimits to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserHeartRateLimits.AsObject>) {
    _value = _value || {};
    this.automatic = _value.automatic
      ? new HeartRateLimits(_value.automatic)
      : undefined;
    this.manualOverride = _value.manualOverride
      ? new HeartRateLimits(_value.manualOverride)
      : undefined;
    UserHeartRateLimits.refineValues(this);
  }
  get automatic(): HeartRateLimits | undefined {
    return this._automatic;
  }
  set automatic(value: HeartRateLimits | undefined) {
    this._automatic = value;
  }
  get manualOverride(): HeartRateLimits | undefined {
    return this._manualOverride;
  }
  set manualOverride(value: HeartRateLimits | undefined) {
    this._manualOverride = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserHeartRateLimits.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserHeartRateLimits.AsObject {
    return {
      automatic: this.automatic ? this.automatic.toObject() : undefined,
      manualOverride: this.manualOverride
        ? this.manualOverride.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserHeartRateLimits.AsProtobufJSON {
    return {
      automatic: this.automatic ? this.automatic.toProtobufJSON(options) : null,
      manualOverride: this.manualOverride
        ? this.manualOverride.toProtobufJSON(options)
        : null
    };
  }
}
export module UserHeartRateLimits {
  /**
   * Standard JavaScript object representation for UserHeartRateLimits
   */
  export interface AsObject {
    automatic?: HeartRateLimits.AsObject;
    manualOverride?: HeartRateLimits.AsObject;
  }

  /**
   * Protobuf JSON representation for UserHeartRateLimits
   */
  export interface AsProtobufJSON {
    automatic: HeartRateLimits.AsProtobufJSON | null;
    manualOverride: HeartRateLimits.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.HeartRateLimits
 */
export class HeartRateLimits implements GrpcMessage {
  static id = 'shared.HeartRateLimits';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new HeartRateLimits();
    HeartRateLimits.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: HeartRateLimits) {
    _instance.restHeartRate = _instance.restHeartRate || 0;
    _instance.maxHeartRate = _instance.maxHeartRate || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: HeartRateLimits,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.restHeartRate = _reader.readInt32();
          break;
        case 2:
          _instance.maxHeartRate = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    HeartRateLimits.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: HeartRateLimits,
    _writer: BinaryWriter
  ) {
    if (_instance.restHeartRate) {
      _writer.writeInt32(1, _instance.restHeartRate);
    }
    if (_instance.maxHeartRate) {
      _writer.writeInt32(2, _instance.maxHeartRate);
    }
  }

  private _restHeartRate: number;
  private _maxHeartRate: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of HeartRateLimits to deeply clone from
   */
  constructor(_value?: RecursivePartial<HeartRateLimits.AsObject>) {
    _value = _value || {};
    this.restHeartRate = _value.restHeartRate;
    this.maxHeartRate = _value.maxHeartRate;
    HeartRateLimits.refineValues(this);
  }
  get restHeartRate(): number {
    return this._restHeartRate;
  }
  set restHeartRate(value: number) {
    this._restHeartRate = value;
  }
  get maxHeartRate(): number {
    return this._maxHeartRate;
  }
  set maxHeartRate(value: number) {
    this._maxHeartRate = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    HeartRateLimits.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): HeartRateLimits.AsObject {
    return {
      restHeartRate: this.restHeartRate,
      maxHeartRate: this.maxHeartRate
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): HeartRateLimits.AsProtobufJSON {
    return {
      restHeartRate: this.restHeartRate,
      maxHeartRate: this.maxHeartRate
    };
  }
}
export module HeartRateLimits {
  /**
   * Standard JavaScript object representation for HeartRateLimits
   */
  export interface AsObject {
    restHeartRate: number;
    maxHeartRate: number;
  }

  /**
   * Protobuf JSON representation for HeartRateLimits
   */
  export interface AsProtobufJSON {
    restHeartRate: number;
    maxHeartRate: number;
  }
}

/**
 * Message implementation for shared.UserLocation
 */
export class UserLocation implements GrpcMessage {
  static id = 'shared.UserLocation';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserLocation();
    UserLocation.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserLocation) {
    _instance.placeName = _instance.placeName || '';
    _instance.placeId = _instance.placeId || '';
    _instance.center = _instance.center || undefined;
    _instance.viewport = _instance.viewport || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserLocation,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.placeName = _reader.readString();
          break;
        case 2:
          _instance.placeId = _reader.readString();
          break;
        case 3:
          _instance.center = new LatLong();
          _reader.readMessage(
            _instance.center,
            LatLong.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.viewport = new LatLongRectangle();
          _reader.readMessage(
            _instance.viewport,
            LatLongRectangle.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserLocation.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserLocation,
    _writer: BinaryWriter
  ) {
    if (_instance.placeName) {
      _writer.writeString(1, _instance.placeName);
    }
    if (_instance.placeId) {
      _writer.writeString(2, _instance.placeId);
    }
    if (_instance.center) {
      _writer.writeMessage(
        3,
        _instance.center as any,
        LatLong.serializeBinaryToWriter
      );
    }
    if (_instance.viewport) {
      _writer.writeMessage(
        4,
        _instance.viewport as any,
        LatLongRectangle.serializeBinaryToWriter
      );
    }
  }

  private _placeName: string;
  private _placeId: string;
  private _center?: LatLong;
  private _viewport?: LatLongRectangle;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserLocation to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserLocation.AsObject>) {
    _value = _value || {};
    this.placeName = _value.placeName;
    this.placeId = _value.placeId;
    this.center = _value.center ? new LatLong(_value.center) : undefined;
    this.viewport = _value.viewport
      ? new LatLongRectangle(_value.viewport)
      : undefined;
    UserLocation.refineValues(this);
  }
  get placeName(): string {
    return this._placeName;
  }
  set placeName(value: string) {
    this._placeName = value;
  }
  get placeId(): string {
    return this._placeId;
  }
  set placeId(value: string) {
    this._placeId = value;
  }
  get center(): LatLong | undefined {
    return this._center;
  }
  set center(value: LatLong | undefined) {
    this._center = value;
  }
  get viewport(): LatLongRectangle | undefined {
    return this._viewport;
  }
  set viewport(value: LatLongRectangle | undefined) {
    this._viewport = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserLocation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserLocation.AsObject {
    return {
      placeName: this.placeName,
      placeId: this.placeId,
      center: this.center ? this.center.toObject() : undefined,
      viewport: this.viewport ? this.viewport.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserLocation.AsProtobufJSON {
    return {
      placeName: this.placeName,
      placeId: this.placeId,
      center: this.center ? this.center.toProtobufJSON(options) : null,
      viewport: this.viewport ? this.viewport.toProtobufJSON(options) : null
    };
  }
}
export module UserLocation {
  /**
   * Standard JavaScript object representation for UserLocation
   */
  export interface AsObject {
    placeName: string;
    placeId: string;
    center?: LatLong.AsObject;
    viewport?: LatLongRectangle.AsObject;
  }

  /**
   * Protobuf JSON representation for UserLocation
   */
  export interface AsProtobufJSON {
    placeName: string;
    placeId: string;
    center: LatLong.AsProtobufJSON | null;
    viewport: LatLongRectangle.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.LatLongRectangle
 */
export class LatLongRectangle implements GrpcMessage {
  static id = 'shared.LatLongRectangle';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LatLongRectangle();
    LatLongRectangle.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LatLongRectangle) {
    _instance.southWest = _instance.southWest || undefined;
    _instance.northEast = _instance.northEast || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LatLongRectangle,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.southWest = new LatLong();
          _reader.readMessage(
            _instance.southWest,
            LatLong.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.northEast = new LatLong();
          _reader.readMessage(
            _instance.northEast,
            LatLong.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LatLongRectangle.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LatLongRectangle,
    _writer: BinaryWriter
  ) {
    if (_instance.southWest) {
      _writer.writeMessage(
        1,
        _instance.southWest as any,
        LatLong.serializeBinaryToWriter
      );
    }
    if (_instance.northEast) {
      _writer.writeMessage(
        2,
        _instance.northEast as any,
        LatLong.serializeBinaryToWriter
      );
    }
  }

  private _southWest?: LatLong;
  private _northEast?: LatLong;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LatLongRectangle to deeply clone from
   */
  constructor(_value?: RecursivePartial<LatLongRectangle.AsObject>) {
    _value = _value || {};
    this.southWest = _value.southWest
      ? new LatLong(_value.southWest)
      : undefined;
    this.northEast = _value.northEast
      ? new LatLong(_value.northEast)
      : undefined;
    LatLongRectangle.refineValues(this);
  }
  get southWest(): LatLong | undefined {
    return this._southWest;
  }
  set southWest(value: LatLong | undefined) {
    this._southWest = value;
  }
  get northEast(): LatLong | undefined {
    return this._northEast;
  }
  set northEast(value: LatLong | undefined) {
    this._northEast = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LatLongRectangle.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LatLongRectangle.AsObject {
    return {
      southWest: this.southWest ? this.southWest.toObject() : undefined,
      northEast: this.northEast ? this.northEast.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LatLongRectangle.AsProtobufJSON {
    return {
      southWest: this.southWest ? this.southWest.toProtobufJSON(options) : null,
      northEast: this.northEast ? this.northEast.toProtobufJSON(options) : null
    };
  }
}
export module LatLongRectangle {
  /**
   * Standard JavaScript object representation for LatLongRectangle
   */
  export interface AsObject {
    southWest?: LatLong.AsObject;
    northEast?: LatLong.AsObject;
  }

  /**
   * Protobuf JSON representation for LatLongRectangle
   */
  export interface AsProtobufJSON {
    southWest: LatLong.AsProtobufJSON | null;
    northEast: LatLong.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.LatLong
 */
export class LatLong implements GrpcMessage {
  static id = 'shared.LatLong';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LatLong();
    LatLong.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LatLong) {
    _instance.latitude = _instance.latitude || 0;
    _instance.longitude = _instance.longitude || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LatLong,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.latitude = _reader.readDouble();
          break;
        case 2:
          _instance.longitude = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    LatLong.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: LatLong, _writer: BinaryWriter) {
    if (_instance.latitude) {
      _writer.writeDouble(1, _instance.latitude);
    }
    if (_instance.longitude) {
      _writer.writeDouble(2, _instance.longitude);
    }
  }

  private _latitude: number;
  private _longitude: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LatLong to deeply clone from
   */
  constructor(_value?: RecursivePartial<LatLong.AsObject>) {
    _value = _value || {};
    this.latitude = _value.latitude;
    this.longitude = _value.longitude;
    LatLong.refineValues(this);
  }
  get latitude(): number {
    return this._latitude;
  }
  set latitude(value: number) {
    this._latitude = value;
  }
  get longitude(): number {
    return this._longitude;
  }
  set longitude(value: number) {
    this._longitude = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LatLong.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LatLong.AsObject {
    return {
      latitude: this.latitude,
      longitude: this.longitude
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LatLong.AsProtobufJSON {
    return {
      latitude: this.latitude,
      longitude: this.longitude
    };
  }
}
export module LatLong {
  /**
   * Standard JavaScript object representation for LatLong
   */
  export interface AsObject {
    latitude: number;
    longitude: number;
  }

  /**
   * Protobuf JSON representation for LatLong
   */
  export interface AsProtobufJSON {
    latitude: number;
    longitude: number;
  }
}

/**
 * Message implementation for shared.UserPrivateDataAccessList
 */
export class UserPrivateDataAccessList implements GrpcMessage {
  static id = 'shared.UserPrivateDataAccessList';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserPrivateDataAccessList();
    UserPrivateDataAccessList.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserPrivateDataAccessList) {
    _instance.accessLevel = _instance.accessLevel || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserPrivateDataAccessList,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.accessLevel = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    UserPrivateDataAccessList.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserPrivateDataAccessList,
    _writer: BinaryWriter
  ) {
    if (_instance.accessLevel) {
      _writer.writeEnum(1, _instance.accessLevel);
    }
  }

  private _accessLevel: UserDataAccessLevel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserPrivateDataAccessList to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserPrivateDataAccessList.AsObject>) {
    _value = _value || {};
    this.accessLevel = _value.accessLevel;
    UserPrivateDataAccessList.refineValues(this);
  }
  get accessLevel(): UserDataAccessLevel {
    return this._accessLevel;
  }
  set accessLevel(value: UserDataAccessLevel) {
    this._accessLevel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserPrivateDataAccessList.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserPrivateDataAccessList.AsObject {
    return {
      accessLevel: this.accessLevel
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserPrivateDataAccessList.AsProtobufJSON {
    return {
      accessLevel:
        UserDataAccessLevel[
          this.accessLevel === null || this.accessLevel === undefined
            ? 0
            : this.accessLevel
        ]
    };
  }
}
export module UserPrivateDataAccessList {
  /**
   * Standard JavaScript object representation for UserPrivateDataAccessList
   */
  export interface AsObject {
    accessLevel: UserDataAccessLevel;
  }

  /**
   * Protobuf JSON representation for UserPrivateDataAccessList
   */
  export interface AsProtobufJSON {
    accessLevel: string;
  }
}
