import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  ImageSettingsModel,
  RichTextEditor,
  ToolbarSettingsModel,
} from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-edit-rtc',
  templateUrl: './edit-rtc.component.html',
  styleUrls: ['./edit-rtc.component.scss'],
})
export class EditRtcComponent implements OnInit {
  editMode = false;

  @Input()
  public textTooltip = '';

  @Input()
  public textHtml = '';
  @Output()
  public textHtmlChange = new EventEmitter();
  safeTextHtml!: SafeHtml;
  private savedTextHtml = '';

  @Input()
  public width!: number;

  @Input()
  public readOnly = false;

  @Output()
  public editDone = new EventEmitter();

  @ViewChild('textView')
  textView!: ElementRef<HTMLDivElement>;

  toolbarSettings: ToolbarSettingsModel = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Indent',
      'Outdent',
      '|',
      'CreateTable',
      '|',
      'Createlink',
      'FontColor',
      'BackgroundColor',
      'ClearFormat',
      '|',
      'Undo',
      'Redo',
      '|',
      'FontName',
      'FontSize',
    ],
  };

  fontSize = {
    default: '14pt',
  };

  imageSettings: ImageSettingsModel = {
    // TODO: consider adding images ('Image' in toolbar), saveUrl/path is where the image is POST'ed,
    //   not clear how to return resulting media id to the RTC component.
    saveUrl: 'not-existent',
    path: 'not-existent',
    maxWidth: 128,
    maxHeight: 128,
  };

  @ViewChild('textEditor')
  textEditor!: RichTextEditor;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.updateSafeTextHtml();
  }

  onViewClick(e: MouseEvent) {
    if (!this.readOnly) {
      this.savedTextHtml = this.textHtml;
      this.flipEditMode();
      e.stopPropagation();
    }
  }

  onViewKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.savedTextHtml = this.textHtml;
      this.flipEditMode();
      e.stopPropagation();
    }
  }

  onRtcCreated() {
    setTimeout(() => {
      this.textEditor.focusIn();
    }, 100);
  }

  onEditKeyDown(e: KeyboardEvent) {
    if (
      e.key == '?' &&
      e.getModifierState('Control') &&
      e.getModifierState('Shift')
    ) {
      // TODO: show shortcuts.
      console.log('TODO: help');
    }
  }

  commitEdit(): void {
    this.updateSafeTextHtml();
    this.textHtmlChange.emit(this.textHtml);
    this.editDone.emit();
    this.flipEditMode();
  }

  cancelEdit(): void {
    this.textHtml = this.savedTextHtml;
    this.editDone.emit();
    this.flipEditMode();
  }

  private flipEditMode() {
    this.editMode = !this.editMode;
  }

  private updateSafeTextHtml(): void {
    if (this.textHtml) {
      this.safeTextHtml = this.domSanitizer.bypassSecurityTrustHtml(
        this.textHtml,
      );
    } else {
      this.safeTextHtml = this.domSanitizer.bypassSecurityTrustHtml(
        '<span style="color:lightgray">click to enter description</span>',
      );
    }
  }
}
