<div
  *ngIf="!editMode && (!readOnly || textHtml)"
  tabindex="0"
  #textView
  class="text-view"
  [innerHTML]="safeTextHtml"
  [matTooltip]="textTooltip"
  [ngStyle]="{ width: width + 'px' }"
  (click)="onViewClick($event)"
  (keydown)="onViewKeyDown($event)"
></div>
<div
  *ngIf="editMode"
  class="editor-container"
  [ngStyle]="{ width: width + 'px' }"
>
  <ejs-richtexteditor
    #textEditor
    class="text-editor"
    [(value)]="textHtml"
    [toolbarSettings]="toolbarSettings"
    [fontSize]="fontSize"
    [insertImageSettings]="imageSettings"
    (created)="onRtcCreated()"
    (keydown)="onEditKeyDown($event)"
    [showCharCount]="true"
    [maxLength]="1000"
  >
  </ejs-richtexteditor>
  <div class="button-container">
    <button mat-raised-button class="cancel-button" (click)="cancelEdit()">
      Cancel
    </button>
    <button mat-raised-button class="save-button" (click)="commitEdit()">
      Save
    </button>
  </div>
</div>
