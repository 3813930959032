import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { Roles } from './admin/roles';
import { ConfirmationComponent } from './common/confirmation/confirmation.component';
import { PolicyVersions } from './common/policy-versions';
import { isCookieBannerDisplayed, toggleCookieBanner } from './cookies';
import { NotificationsService } from './services/notifications/notifications.service';
import { LoggedInUser, UserService } from './services/user/user.service';

function isPublicPath(path: string): boolean {
  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }
  return path.startsWith('/docs') || path.startsWith('/legal');
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'jestle';

  user?: LoggedInUser;
  loggedIn = false;
  loggingIn = true;
  policiesAccepted = false;

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // If path is root, default to activities, otherwise navigate to where the user
    // asked including parameters (important for redirects).
    let routerParams: Params = {};
    new URL(window.location.href).searchParams.forEach(
      (v, k) => (routerParams[k] = v),
    );
    let path = window.location.pathname;
    if (path == '/') {
      path = '/activities';
      routerParams = {};
    }
    const notLoggedInPath = isPublicPath(path) ? path : '/home';
    this.userService.user.subscribe((user) => {
      if (!user) {
        this.user = user;
        this.loggedIn = false;
        this.policiesAccepted = false;
        this.router.navigate([notLoggedInPath]);
        return;
      } else {
        this.user = user;
        this.loggedIn = user.email != null;
        this.policiesAccepted =
          user != null &&
          user.details.consentedCookiesVersion ==
            PolicyVersions.cookiesPolicyLastUpdateDate &&
          user.details.consentedPrivacyVersion ==
            PolicyVersions.privacyPolicyLastUpdateDate;

        if (this.loggedIn) {
          // Do not redirect after profile updates.
          if (!user.wasUpdated) {
            this.router.navigate([path], { queryParams: routerParams });
            this.notificationsService.startListening();
          }
        } else {
          // Do not redirect while logging-in.
          if (!this.loggingIn) {
            this.router.navigate([notLoggedInPath]);
          }
        }
      }
    });

    // Attempt to login with session.
    this.userService.logInWithSession().then((result) => {
      this.loggingIn = false;
      if (!result) {
        this.router.navigate([notLoggedInPath]);
      } else {
        this.router.navigate([path], { queryParams: routerParams });
        this.notificationsService.startListening();
      }
    });
  }

  locationStartsWith(prefix: string): boolean {
    const index = prefix.indexOf('/', 1);
    if (index > 0) {
      prefix = prefix.substring(0, index);
    }
    return this.location.path().startsWith(prefix);
  }

  logOut(): void {
    ConfirmationComponent.openDialog(
      this.dialog,
      'Log out from all devices? This will remove all session ids, you can always re-login.',
      (result) => {
        if (result) {
          this.userService.logOut();
        }
      },
    );
  }

  isInternalUser(): boolean {
    return (
      this.user != null &&
      this.user.roles != undefined &&
      this.user.roles.indexOf(Roles.Internal) >= 0
    );
  }

  toggleCookieBanner(): void {
    toggleCookieBanner();
  }

  isCookieBannerDisplayed(): boolean {
    return isCookieBannerDisplayed();
  }
}
