import { Directive, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Directive({
  selector: '[appAliasValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => AliasValidatorDirective),
      multi: true,
    },
  ],
})
export class AliasValidatorDirective implements AsyncValidator {
  @Input()
  currentAlias: string | undefined;

  constructor(private userService: UserService) {}
  validate(
    control: AbstractControl<string, string>,
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (this.currentAlias && this.currentAlias == control.value) {
      return Promise.resolve(null);
    }
    return this.userService.aliasExists(control.value).then((v) => {
      if (v == undefined) {
        return { value: 'Unknown error' };
      }
      return v ? { value: 'Alias exists' } : null;
    });
  }
}
