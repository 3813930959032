import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MeasurementWidgetComponent } from './measurement-widget.component';

@NgModule({
  declarations: [MeasurementWidgetComponent],
  exports: [MeasurementWidgetComponent],
  imports: [CommonModule],
})
export class MeasurementWidgetModule {}
