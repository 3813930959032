import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

export interface ItemData {
  imageSrc?: string;
  imageFullSizeSrc?: string;
  videoSrc?: string;
  videoFullSizeSrc?: string;
}

export type CurrentIndexChange = {
  index: number;
  data: object;
};

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  private readonly itemMargin = 8;

  @Input()
  data!: object;

  @Input()
  scrollable = false;

  itemsInternal?: ItemData[];

  @Input()
  width!: number;

  @Input()
  height!: number;

  @Input()
  id!: string;

  @Input()
  itemTemplateData!: object;

  @Input()
  itemTemplate?: TemplateRef<object>;

  @Output()
  currentIndex = 0;

  @Output()
  currentIndexChange = new EventEmitter<CurrentIndexChange>();

  @ViewChild('scrollDiv')
  scrollDiv!: ElementRef<HTMLDivElement>;

  @ViewChild('contents')
  contentsDiv!: ElementRef<HTMLDivElement>;

  private mouseDownX: number | undefined;
  private mouseDownScrollLeft: number | undefined;

  isFullScreenEnabled = document.fullscreenEnabled;
  isFullScreen = false;
  @Output()
  isFullScreenChange = new EventEmitter<boolean>();

  private imageStyleDefault?: {
    [prop: string]: string;
  };
  private imageVideoStyleFullScreen: {
    [prop: string]: string;
  } = { width: '100%', height: '100%', 'object-fit': 'contain' };

  ngOnInit(): void {
    this.imageStyleDefault = {
      width: this.width + 'px',
      height: this.height + 'px',
      'object-fit': 'cover',
    };
  }

  @Input()
  set items(value: ItemData[]) {
    if (value) {
      if (this.currentIndex >= value.length) {
        this.currentIndex = value.length - 1;
      }
    }
    this.itemsInternal = value;
  }

  get imageSrc(): string | undefined {
    if (!this.itemsInternal) {
      return '';
    }
    if (
      this.currentIndex >= 0 &&
      this.currentIndex < this.itemsInternal.length
    ) {
      return this.getItemImageSrc(this.itemsInternal[this.currentIndex]);
    }
    return '';
  }

  getItemImageSrc(item: ItemData): string | undefined {
    let src: string | undefined;
    if (this.isFullScreen && item.imageFullSizeSrc) {
      src = item.imageFullSizeSrc;
    } else {
      if (item.imageSrc) {
        src = item.imageSrc;
      }
    }
    return src;
  }

  get videoSrc(): string | undefined {
    if (!this.itemsInternal) {
      return '';
    }
    if (
      this.currentIndex >= 0 &&
      this.currentIndex < this.itemsInternal.length
    ) {
      return this.getItemVideoSrc(this.itemsInternal[this.currentIndex]);
    }
    return '';
  }

  getItemVideoSrc(item: ItemData): string | undefined {
    let src: string | undefined;
    if (item.videoFullSizeSrc) {
      src = item.videoFullSizeSrc;
    } else {
      if (item.videoSrc) {
        src = item.videoSrc;
      }
    }
    return src ? src + '#t=0.001' : undefined;
  }

  @HostListener('fullscreenchange')
  onFullScreenChange(): void {
    this.isFullScreen = document.fullscreenElement !== null;
    this.isFullScreenChange.emit(this.isFullScreen);
  }

  get imageStyle(): object {
    return this.isFullScreen
      ? this.imageVideoStyleFullScreen
      : this.imageStyleDefault!;
  }

  onFullScreen() {
    if (document.fullscreenElement != null) {
      document.exitFullscreen();
    } else {
      this.contentsDiv.nativeElement.requestFullscreen();
    }
  }

  onNext(e: MouseEvent) {
    if (this.itemsInternal) {
      const prevIndex = this.currentIndex;
      this.currentIndex = (this.currentIndex + 1) % this.itemsInternal.length;
      e.stopPropagation();
      if (prevIndex != this.currentIndex) {
        this.currentIndexChange.emit({
          index: this.currentIndex,
          data: this.data,
        });
      }
    }
  }

  onPrevious(e: MouseEvent) {
    if (this.itemsInternal) {
      const prevIndex = this.currentIndex;
      this.currentIndex =
        (this.currentIndex - 1 + this.itemsInternal.length) %
        this.itemsInternal.length;
      e.stopPropagation();
      if (prevIndex != this.currentIndex) {
        this.currentIndexChange.emit({
          index: this.currentIndex,
          data: this.data,
        });
      }
    }
  }

  onError() {
    console.log('Failed to load image');
  }

  onMouseDown(e: MouseEvent) {
    if (this.itemsInternal && this.itemsInternal.length > 1) {
      this.mouseDownX = e.clientX;
      this.mouseDownScrollLeft = this.scrollDiv.nativeElement.scrollLeft;
    }
  }

  onMouseMove(e: MouseEvent) {
    if (this.mouseDownX) {
      const offsetX = this.mouseDownX - e.clientX;
      this.scrollDiv.nativeElement.scrollLeft =
        this.mouseDownScrollLeft! + offsetX;
    }
  }

  onMouseUp(e: MouseEvent) {
    try {
      if (this.mouseDownX) {
        let newIndex: number;
        const len = this.itemsInternal!.length;
        if (e.clientX - this.mouseDownX < 0) {
          // Scroll left.
          newIndex = (this.currentIndex + len - 1) % len;
        } else {
          // Scroll right.
          newIndex = (this.currentIndex + len + 1) % len;
        }
        this.scrollDiv.nativeElement.scrollLeft =
          newIndex * (this.width + this.itemMargin);
      }
    } finally {
      this.mouseDownX = undefined;
    }
  }

  /** When the view is detached from DOM, it doesn't get scroll event, but
   * the scrollLeft is reset to 0.
   * */
  get computedCurrentIndex(): number {
    if (!this.scrollDiv) {
      return this.currentIndex;
    }
    const width = this.width + this.itemMargin;
    return Math.trunc(
      (this.scrollDiv.nativeElement.scrollLeft + this.width / 2) / width,
    );
  }

  onScroll() {
    const width = this.width + this.itemMargin;
    const prevIndex = this.currentIndex;
    this.currentIndex = Math.trunc(
      (this.scrollDiv.nativeElement.scrollLeft + this.width / 2) / width,
    );
    if (prevIndex != this.currentIndex) {
      this.currentIndexChange.emit({
        index: this.currentIndex,
        data: this.data,
      });
    }
  }
}
