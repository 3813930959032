import { ReactionType } from 'generated/src/main/proto/shared/reaction.pb';

export class CommentConstants {
  public static readonly MAX_COMMENT_CHARS = 256;
}

/** Reaction type, color and icon names. */
export type ReactionNameAndColor = {
  name: string;
  backgroundName: string;
  color: string;
  order: number;
  count: number;
  reactionType: ReactionType;
  toolTip: string;
};

export class ReactionsShared {
  public static allReactionIconNameAndColors: readonly ReactionNameAndColor[] =
    ReactionsShared.initAllReactionIconNameAndColors();

  private static initAllReactionIconNameAndColors(): ReactionNameAndColor[] {
    const result: ReactionNameAndColor[] = [];
    for (const reactionType in ReactionType) {
      if (isNaN(Number(reactionType))) {
        const key = reactionType as keyof typeof ReactionType;
        const icon = ReactionsShared.getReactionIconNameAndColor(
          ReactionType[key],
        );
        if (icon) {
          result.push(icon);
        }
      }
    }

    result.sort((a, b) => a.order - b.order);

    return result;
  }
  public static getReactionIconNameAndColor(
    reactionType: ReactionType,
  ): ReactionNameAndColor | undefined {
    switch (reactionType) {
      case ReactionType.REACTION_TYPE_LIKE:
        return {
          name: 'thumb_up',
          backgroundName: 'thumb_up',
          color: 'yellow',
          count: 0,
          order: 0,
          reactionType: reactionType,
          toolTip: 'Like it',
        };
      case ReactionType.REACTION_TYPE_HEART:
        return {
          name: 'favorite',
          backgroundName: 'favorite',
          color: 'red',
          count: 0,
          order: 1,
          reactionType: reactionType,
          toolTip: 'Love it!',
        };
      case ReactionType.REACTION_TYPE_SATISFIED:
        return {
          name: 'sentiment_very_satisfied',
          backgroundName: 'circle',
          color: 'lightgreen',
          count: 0,
          order: 2,
          reactionType: reactionType,
          toolTip: 'Funny, satisfied',
        };
      case ReactionType.REACTION_TYPE_DISSATISFIED:
        return {
          name: 'sentiment_dissatisfied',
          backgroundName: 'circle',
          color: 'orange',
          count: 0,
          order: 3,
          reactionType: reactionType,
          toolTip: 'This is bad',
        };
      case ReactionType.REACTION_TYPE_UNSPECIFIED:
      default:
        return undefined;
    }
  }
}
