import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NotificationsComponent } from './notifications.component';
import { NotificationsDialogComponent } from './notifications.dialog.component';
import { ProfileImageModule } from '../common/profile-image/profile-image.module';

@NgModule({
  declarations: [NotificationsComponent, NotificationsDialogComponent],
  exports: [NotificationsComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CdkTableModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    ProfileImageModule,
    RouterModule,
  ],
})
export class NotificationsModule {}
