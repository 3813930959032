import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Renderer } from 'marked';
import { createDirectives } from 'marked-directive';
import markedKatex from 'marked-katex-extension';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { CommentReactionModule } from '../common/comment-reaction/comment-reaction.module';
import { ProfileImageModule } from '../common/profile-image/profile-image.module';
import { ReactionsModule } from '../common/reactions/reactions.modue';
import { DocsTocComponent } from './docs-toc.component';
import { DocsComponent } from './docs.component';

/**
 * The 'marked' renderer adds 'mermaid' style class for code blocks that are annotated with 'mermaid',
 * other code blocks are considered code. Use prism to render the code snippets.
 * */
class CustomRenderer extends Renderer {
  override code(code: string, language: string | undefined): string {
    if ((language ?? '') == 'mermaid') {
      return `<pre class="mermaid">${code}</pre>`;
    } else {
      // The style of the copy button is customized in the root styles.css (toolbar, copy-button).
      //   prism toolbar and copy-to-clipboard plugins
      //   font awesome stylesheets
      return `<pre><code class="language-${language}">${code}</code></pre>`;
    }
  }
}

@NgModule({
  declarations: [DocsComponent, DocsTocComponent],
  exports: [DocsComponent],
  imports: [
    CommentReactionModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedExtensions: [createDirectives(), markedKatex()],
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          renderer: new CustomRenderer(),
        },
      },
    }),
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatTooltipModule,
    ProfileImageModule,
    ReactionsModule,
  ],
})
export class DocsModule {}
