import { NgModule } from '@angular/core';
import { GRPC_CLIENT_FACTORY, GrpcCoreModule } from '@ngx-grpc/core';
import {
  GRPC_WEB_CLIENT_DEFAULT_SETTINGS,
  GrpcWebClientFactory,
  GrpcWebClientModule,
} from '@ngx-grpc/grpc-web-client';
import { AppConfigProvider } from 'src/environments/app-config';

@NgModule({
  imports: [
    GrpcCoreModule.forRoot(),
    {
      ngModule: GrpcWebClientModule,
      // See: https://github.com/smnbbrv/ngx-grpc/blob/2e1a1c6383e5f146c0e54dfd999f2b930b2aac2a/packages/grpc-web-client/src/lib/grpc-web-client.module.ts#L15
      providers: [
        {
          provide: GRPC_CLIENT_FACTORY,
          useClass: GrpcWebClientFactory,
        },
        {
          provide: GRPC_WEB_CLIENT_DEFAULT_SETTINGS,
          useFactory: () => {
            return {
              host: AppConfigProvider.config.apiServerHost,
              format: 'binary',
            };
          },
        },
      ],
    },
  ],
})
export class ApiModule {}
