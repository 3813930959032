<div class="activity-contents" #contentsElement *ngIf="activity" [ngStyle]="{ width: galleryWidth }">
  <!-- header -->
  <div class="activity-header">
    <div class="activity-header">
      <!-- Title -->
      <app-edit-text [readOnly]="readOnly" [(value)]="title" tooltipName="title" (editDone)="save()"></app-edit-text>

      <!-- Activity type -->
      <app-edit-option tabindex="0" [readOnly]="readOnly" [(selectedOptionValue)]="activityType"
        tooltipName="Activty type" [optionValues]="activityTypes" (editDone)="save()"></app-edit-option>

      <!-- Delete activity -->
      <div class="delete-activity-div">
        <button tabindex="0" *ngIf="!readOnly" mat-icon-button (click)="onDeleteActivityClick()">
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- Map and images -->
  <div #mapAndImages>
    <app-gallery *ngIf="galleryItems.length > 0" (isFullScreenChange)="onGalleryFullScreenChange($event)"
      [items]="galleryItems" [itemTemplate]="galleryItemTemplate" [width]="galleryInnerWidth"
      [height]="galleryInnerHeight"></app-gallery>

    <input #imageInput type="file" multiple style="display: none" (change)="addImages($event)" />

    <ng-template #galleryItemTemplate let-index="index">
      <div #map class="map" [ngStyle]="getMapDivStyle(index)"></div>

      <button *ngIf="getShowDeleteButton(index)" class="delete button" matTooltip="Remove image/video"
        (click)="deleteGalleryItem(index)">
        <mat-icon class="button-icon">delete</mat-icon>
      </button>
    </ng-template>
  </div>

  <!-- Image/video upload. -->
  <div *ngIf="!readOnly">
    <button *ngIf="!uploadingImages" class="add button" matTooltip="Add image/video" (click)="imageInput.click()">
      <mat-icon class="button-icon">add_a_photo</mat-icon>
    </button>
    <app-progress-spinner *ngIf="uploadingImages" class="add"
      [progressPercent]="uploadProgressPercent"></app-progress-spinner>
  </div>

  <!-- Description -->
  <div [ngStyle]="{ width: galleryInnerWidth + 'px' }">
    <app-edit-rtc [readOnly]="readOnly" [(textHtml)]="noteHtml" [width]="galleryInnerWidth"
      (editDone)="save()"></app-edit-rtc>
  </div>

  <!-- Stats and graphs -->
  <cdk-accordion class="accordion" [ngStyle]="{
      'max-width': galleryInnerWidth + 'px',
      'margin-left': galleryMargin + 'px',
      'margin-right': galleryMargin + 'px'
    }">
    <!-- stats -->
    <cdk-accordion-item class="accordion-item" #accordionStatsItem="cdkAccordionItem" [expanded]="true" role="button">
      <div class="accordion-item-header" tabindex="0" (click)="accordionStatsItem.toggle()"
        (keydown)="onAccordionItemKeyDown($event, accordionStatsItem)">
        Stats
        <span class="material-symbols-outlined">{{ accordionStatsItem.expanded ? 'expand_less' : 'expand_more' }}</span>
      </div>
      <div>
        <div role="region" [ngStyle]="{ display: accordionStatsItem.expanded ? '' : 'none' }">
          <!-- Stats rows -->
          <div class="stats-row" *ngFor="let measurementsRow of statMeasurements">
            <div class="stats-cell" *ngFor="let measurement of measurementsRow">
              <app-measurement-widget [measurement]="measurement" [useTitle]="true"></app-measurement-widget>
            </div>
          </div>

          <!-- HR zones -->
          <app-hr-zones-chart *ngIf="showHrZones" [hrZones]="activity!.stats!.totalStats!.heartRateZoneStats">
          </app-hr-zones-chart>
        </div>
      </div>
    </cdk-accordion-item>

    <!-- graphs -->
    <cdk-accordion-item *ngIf="showFirstChart || showElevationChart" class="accordion-item"
      #accordionGraphsItem="cdkAccordionItem" role="button" (closed)="onGraphsClosed()">
      <div class="accordion-item-header" tabindex="0" (click)="accordionGraphsItem.toggle()"
        (keydown)="onAccordionItemKeyDown($event, accordionGraphsItem)">
        Graphs
        <span class="material-symbols-outlined">{{ accordionGraphsItem.expanded ? 'expand_less' : 'expand_more'
          }}</span>
      </div>
      <div>
        <div role="region" [ngStyle]="{ display: accordionGraphsItem.expanded ? '' : 'none' }">
          <!-- First chart (only show if there is location data = map). -->
          <div *ngIf="showFirstChart">
            <div class="chart-options">
              <div class="chart-x-options">
                <span class="chart-axis">X:</span>
                <app-edit-option [width]="'130px'" [(selectedOptionValue)]="firstChartType" tooltipName="Chart type"
                  [optionValues]="chartTypes" (editDone)="firstChartTypeSelected()"></app-edit-option>
              </div>
              <div class="chart-y-options">
                <span class="chart-axis">Y:</span>
                <app-edit-option [width]="'140px'" [(selectedOptionValue)]="firstChartSeriesTypeLeft"
                  tooltipName="Left series type" [optionValues]="firstChartSeriesTypes"
                  (editDone)="firstChartSeriesLeftSelected()"></app-edit-option>
                <app-edit-option [width]="'140px'" [(selectedOptionValue)]="firstChartSeriesTypeRight"
                  tooltipName="Right series type" [optionValues]="firstChartSeriesTypes"
                  (editDone)="firstChartSeriesRightSelected()"></app-edit-option>
              </div>
            </div>
            <div echarts class="chart" [options]="firstChartOptions" [loading]="firstChartOptions.xAxis === undefined"
              (mousemove)="onMouseMoveFirstChart($event)" (chartInit)="onFirstChartInit($event)"></div>
          </div>

          <!-- Elevation chart -->
          <div *ngIf="showElevationChart">
            <div class="chart-options">
              <div class="chart-y-options">
                <span class="chart-axis">Y:</span>
                <app-edit-option [width]="'130px'" [(selectedOptionValue)]="elevationChartSeriesType"
                  tooltipName="Series type" [optionValues]="elevationChartSeriesTypes"
                  (editDone)="elevationChartSeriesSelected()"></app-edit-option>
              </div>
            </div>
            <div echarts class="chart" [options]="elevationChartOptions"
              [loading]="elevationChartOptions.xAxis === undefined" (mousemove)="onMouseMoveElevation($event)"
              (chartInit)="onElevationChartInit($event)"></div>
          </div>
        </div>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>

  <!-- Reactions -->
  <app-reactions [updater]="createReactionUpdater(activity.summary!)"
    (clickReactionsSummary)="
          onClickReactionsSummary(
            activity.summary!.activityId,
            contentsElement.offsetLeft
          )
        "></app-reactions>
</div>
