import { Directive, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';

@Directive({
  selector: '[appNumberValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => NumberValidatorDirective),
      multi: true,
    },
  ],
})
export class NumberValidatorDirective implements AsyncValidator {
  @Input()
  minValue!: number;

  @Input()
  maxValue!: number;

  validate(
    control: AbstractControl<string, string>,
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (control.value == undefined) {
      return Promise.resolve(null);
    }

    const n = parseFloat(control.value);
    if (Number.isNaN(n)) {
      return Promise.resolve({ value: `${control.value} is not a number` });
    }
    if (n < this.minValue) {
      return Promise.resolve({ value: `${control.value} < ${this.minValue}` });
    }
    if (n > this.maxValue) {
      return Promise.resolve({ value: `${control.value} > ${this.maxValue}` });
    }

    return Promise.resolve(null);
  }
}
