import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DocumentMetadata } from 'generated/src/main/proto/shared/document-shared.pb';
import {
  ReactionType,
  Reactions,
} from 'generated/src/main/proto/shared/reaction.pb';
import { parse } from 'yaml';
import { Roles } from '../admin/roles';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import {
  Comment,
  CommentSource,
} from '../common/reactions/reactions.comment.source';
import { ReactionUpdater } from '../common/reactions/reactions.component';
import { CommentConstants } from '../common/reactions/reactions.shared';
import { DocumentService } from '../services/document/document.service';
import { FormatService } from '../services/format.service';
import { LoggedInUser, UserService } from '../services/user/user.service';
import { DocsTableOfContents } from './docs-table-of-contents';
import { ActivePath } from './docs-toc.component';

/**
 * The instance maintains active path to style table of contents and change
 * active contents.
 * */
class ActivePathImpl implements ActivePath {
  private value?: DocsTableOfContents;

  constructor(private changed: (value: DocsTableOfContents) => void) {}

  get(): DocsTableOfContents | undefined {
    return this.value;
  }

  set(value: DocsTableOfContents): void {
    this.value = value;
    this.changed(value);
  }
}

/** Reads/writes reactions for the document on the path. */
class DocReactionUpdater implements ReactionUpdater {
  constructor(
    private userAlias: string,
    private path: string,
    private metadata: DocumentMetadata,
    private documentService: DocumentService,
  ) {}

  getUserAlias(): string {
    return this.userAlias;
  }

  isUserAllowedToReact(): boolean {
    return true;
  }

  async setReaction(reactionType: ReactionType): Promise<Reactions> {
    return this.documentService
      .setReaction(this.path, reactionType)
      .then((r) => {
        this.metadata.reactions = r.reactions;
        return r.reactions!;
      });
  }

  getReactions(): Reactions | undefined {
    return this.metadata.reactions;
  }

  async addComment(text: string): Promise<Reactions> {
    return this.documentService
      .updateComment(this.path, text, undefined, undefined)
      .then((r) => {
        this.metadata.reactions = r.reactions;
        return r.reactions!;
      });
  }
}

/**
 * The Docs component loads table of contents from a toc.yaml in assets/docs
 * and renders its contents in the side bar. The contents pages referenced by
 * the yaml are rendered by 'marked' component.
 */

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrl: './docs.component.scss',
})
export class DocsComponent implements OnInit, AfterViewInit {
  readonly BASE_URL = '/assets/docs';
  readonly BASE_PATH = '/docs';

  tableOfContents!: DocsTableOfContents;
  activePath = new ActivePathImpl(this.onPathChanged.bind(this));
  contentsSrc = '';
  lastUpdated = '';

  user?: LoggedInUser;
  reactionUpdater?: DocReactionUpdater;

  metadata?: DocumentMetadata;
  commentsSource?: CommentSource;
  currentCommentRemainingChars = CommentConstants.MAX_COMMENT_CHARS;
  private currentCommentValue = '';

  constructor(
    private location: Location,
    private router: Router,
    private formatService: FormatService,
    private documentService: DocumentService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {
    location.onUrlChange(this.onUrlChanged.bind(this));
    userService.user.subscribe((user) => (this.user = user));
  }

  ngOnInit(): void {
    this.loadTableOfContents().then((toc) => {
      this.tableOfContents = toc;
      this.onUrlChanged();
    });
  }

  ngAfterViewInit(): void {
    this.loadMetadata();
  }

  /** Loads table of contents. */
  private async loadTableOfContents(): Promise<DocsTableOfContents> {
    const response = await fetch(this.BASE_URL + '/toc.yaml');
    const text = await response.text();
    const toc = parse(text)[0] as DocsTableOfContents;
    Object.setPrototypeOf(toc, DocsTableOfContents.prototype);
    toc.initialize();
    return toc;
  }

  /** Reaction to browser back/forward actions. */
  private onUrlChanged(): void {
    if (!this.tableOfContents) {
      return;
    }
    const path = this.location.path();
    if (path && path.startsWith(this.BASE_PATH)) {
      const item = this.tableOfContents.find(
        path.substring(this.BASE_PATH.length),
      );
      if (item) {
        this.activePath.set(item);
      }
    }
  }

  /** Reaction to path changes, sets active contents and url path. */
  private onPathChanged(item: DocsTableOfContents): void {
    this.contentsSrc = item.getFilePath(this.BASE_URL);
    fetch(this.contentsSrc, { method: 'HEAD' }).then((response) => {
      if (response.ok) {
        const lastModifiedString = response.headers.get('Last-Modified');
        this.lastUpdated = lastModifiedString
          ? this.formatService.formatJsDate(new Date(lastModifiedString))
          : '';
      } else {
        throw new Error(`fetcherror: ${response.status}`);
      }
    });

    const newPath = this.BASE_PATH + item.path;
    if (newPath != this.location.path()) {
      this.location.go(newPath);

      // Load document metadata.
      this.loadMetadata();
    }
  }

  private loadMetadata(): void {
    const path = this.location.path();
    this.reactionUpdater = undefined;
    this.commentsSource = undefined;
    this.documentService.getMetadata(path).then((r) => {
      this.metadata = r.metadata;

      // Reaction updater for the top view.
      this.reactionUpdater = new DocReactionUpdater(
        this.user?.alias ?? '',
        path,
        this.metadata!,
        this.documentService,
      );

      // Comments source for the scroll view.
      this.commentsSource = new CommentSource(
        this.userService,
        this.formatService,
        r.metadata?.reactions?.commentsContainer,
      );
    });
  }

  set currentComment(value: string) {
    this.currentCommentRemainingChars =
      CommentConstants.MAX_COMMENT_CHARS - value.length;
    this.currentCommentValue = value;
  }

  get currentComment(): string {
    return this.currentCommentValue;
  }

  onCommentKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.documentService
        .updateComment(
          this.location.path(),
          this.currentCommentValue,
          undefined,
          undefined,
        )
        .then((r) => {
          this.metadata!.reactions = r.reactions;
          this.commentsSource!.updateComments(
            this.metadata?.reactions?.commentsContainer,
          );
        });
      this.currentComment = '';

      e.preventDefault();
      e.stopPropagation();
    }
  }

  onCommentReactionChanged(
    commentId: number,
    reactiontype: ReactionType,
  ): void {
    this.documentService
      .updateCommentReaction(this.location.path(), commentId, reactiontype)
      .then((r) => {
        this.metadata!.reactions = r.reactions;
        this.commentsSource!.updateComments(
          this.metadata?.reactions?.commentsContainer,
        );
      });
  }

  isDeleteAllowed(comment: Comment): boolean {
    return (
      (this.user !== undefined && this.user.alias == comment.alias) ||
      (this.user !== undefined &&
        this.user.roles!.indexOf(Roles.IssueReviewer) >= 0)
    );
  }

  onDeleteComment(commentId: number) {
    ConfirmationComponent.openDialog(
      this.dialog,
      'Delete comment?',
      (result) => {
        if (result) {
          this.documentService
            .updateComment(this.location.path(), '', undefined, commentId)
            .then((r) => {
              this.metadata!.reactions = r.reactions;
              this.commentsSource!.updateComments(
                this.metadata?.reactions?.commentsContainer,
              );
            });
        }
      },
    );
  }

  @HostListener('document:click', ['$event'])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      if (element.className === 'markdown-link') {
        event.preventDefault();
        const route = element?.getAttribute('href');
        if (route) {
          this.router.navigate([`/${route}`]);
        }
      }
    }
  }
}
