/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum GarminLoginStatus {
  GARMIN_LOGIN_STATUS_UNSPECIFIED = 0,
  GARMIN_LOGIN_STATUS_LOGGED_IN = 1,
  GARMIN_LOGIN_STATUS_NO_RECORD = 2,
  GARMIN_LOGIN_STATUS_NO_TOKEN = 3
}
/**
 * Message implementation for api.garmin.StartLogInRequest
 */
export class StartLogInRequest implements GrpcMessage {
  static id = 'api.garmin.StartLogInRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StartLogInRequest();
    StartLogInRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StartLogInRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StartLogInRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    StartLogInRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StartLogInRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StartLogInRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<StartLogInRequest.AsObject>) {
    _value = _value || {};
    StartLogInRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StartLogInRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StartLogInRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StartLogInRequest.AsProtobufJSON {
    return {};
  }
}
export module StartLogInRequest {
  /**
   * Standard JavaScript object representation for StartLogInRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for StartLogInRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.garmin.StartLogInResponse
 */
export class StartLogInResponse implements GrpcMessage {
  static id = 'api.garmin.StartLogInResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StartLogInResponse();
    StartLogInResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StartLogInResponse) {
    _instance.oauthToken = _instance.oauthToken || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StartLogInResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.oauthToken = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    StartLogInResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StartLogInResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.oauthToken) {
      _writer.writeString(1, _instance.oauthToken);
    }
  }

  private _oauthToken: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StartLogInResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<StartLogInResponse.AsObject>) {
    _value = _value || {};
    this.oauthToken = _value.oauthToken;
    StartLogInResponse.refineValues(this);
  }
  get oauthToken(): string {
    return this._oauthToken;
  }
  set oauthToken(value: string) {
    this._oauthToken = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StartLogInResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StartLogInResponse.AsObject {
    return {
      oauthToken: this.oauthToken
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StartLogInResponse.AsProtobufJSON {
    return {
      oauthToken: this.oauthToken
    };
  }
}
export module StartLogInResponse {
  /**
   * Standard JavaScript object representation for StartLogInResponse
   */
  export interface AsObject {
    oauthToken: string;
  }

  /**
   * Protobuf JSON representation for StartLogInResponse
   */
  export interface AsProtobufJSON {
    oauthToken: string;
  }
}

/**
 * Message implementation for api.garmin.CompleteLogInRequest
 */
export class CompleteLogInRequest implements GrpcMessage {
  static id = 'api.garmin.CompleteLogInRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompleteLogInRequest();
    CompleteLogInRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompleteLogInRequest) {
    _instance.oauthToken = _instance.oauthToken || '';
    _instance.oauthVerifier = _instance.oauthVerifier || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompleteLogInRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.oauthToken = _reader.readString();
          break;
        case 2:
          _instance.oauthVerifier = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CompleteLogInRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompleteLogInRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.oauthToken) {
      _writer.writeString(1, _instance.oauthToken);
    }
    if (_instance.oauthVerifier) {
      _writer.writeString(2, _instance.oauthVerifier);
    }
  }

  private _oauthToken: string;
  private _oauthVerifier: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompleteLogInRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompleteLogInRequest.AsObject>) {
    _value = _value || {};
    this.oauthToken = _value.oauthToken;
    this.oauthVerifier = _value.oauthVerifier;
    CompleteLogInRequest.refineValues(this);
  }
  get oauthToken(): string {
    return this._oauthToken;
  }
  set oauthToken(value: string) {
    this._oauthToken = value;
  }
  get oauthVerifier(): string {
    return this._oauthVerifier;
  }
  set oauthVerifier(value: string) {
    this._oauthVerifier = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompleteLogInRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompleteLogInRequest.AsObject {
    return {
      oauthToken: this.oauthToken,
      oauthVerifier: this.oauthVerifier
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompleteLogInRequest.AsProtobufJSON {
    return {
      oauthToken: this.oauthToken,
      oauthVerifier: this.oauthVerifier
    };
  }
}
export module CompleteLogInRequest {
  /**
   * Standard JavaScript object representation for CompleteLogInRequest
   */
  export interface AsObject {
    oauthToken: string;
    oauthVerifier: string;
  }

  /**
   * Protobuf JSON representation for CompleteLogInRequest
   */
  export interface AsProtobufJSON {
    oauthToken: string;
    oauthVerifier: string;
  }
}

/**
 * Message implementation for api.garmin.CompleteLogInResponse
 */
export class CompleteLogInResponse implements GrpcMessage {
  static id = 'api.garmin.CompleteLogInResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompleteLogInResponse();
    CompleteLogInResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompleteLogInResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompleteLogInResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    CompleteLogInResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompleteLogInResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompleteLogInResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompleteLogInResponse.AsObject>) {
    _value = _value || {};
    CompleteLogInResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompleteLogInResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompleteLogInResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompleteLogInResponse.AsProtobufJSON {
    return {};
  }
}
export module CompleteLogInResponse {
  /**
   * Standard JavaScript object representation for CompleteLogInResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for CompleteLogInResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.garmin.GetLoginStatusRequest
 */
export class GetLoginStatusRequest implements GrpcMessage {
  static id = 'api.garmin.GetLoginStatusRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLoginStatusRequest();
    GetLoginStatusRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLoginStatusRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLoginStatusRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetLoginStatusRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLoginStatusRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLoginStatusRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLoginStatusRequest.AsObject>) {
    _value = _value || {};
    GetLoginStatusRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLoginStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLoginStatusRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLoginStatusRequest.AsProtobufJSON {
    return {};
  }
}
export module GetLoginStatusRequest {
  /**
   * Standard JavaScript object representation for GetLoginStatusRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetLoginStatusRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.garmin.GetLoginStatusResponse
 */
export class GetLoginStatusResponse implements GrpcMessage {
  static id = 'api.garmin.GetLoginStatusResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLoginStatusResponse();
    GetLoginStatusResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLoginStatusResponse) {
    _instance.loginStatus = _instance.loginStatus || 0;
    _instance.garminUserId = _instance.garminUserId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLoginStatusResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.loginStatus = _reader.readEnum();
          break;
        case 2:
          _instance.garminUserId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLoginStatusResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLoginStatusResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.loginStatus) {
      _writer.writeEnum(1, _instance.loginStatus);
    }
    if (_instance.garminUserId) {
      _writer.writeString(2, _instance.garminUserId);
    }
  }

  private _loginStatus: GarminLoginStatus;
  private _garminUserId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLoginStatusResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLoginStatusResponse.AsObject>) {
    _value = _value || {};
    this.loginStatus = _value.loginStatus;
    this.garminUserId = _value.garminUserId;
    GetLoginStatusResponse.refineValues(this);
  }
  get loginStatus(): GarminLoginStatus {
    return this._loginStatus;
  }
  set loginStatus(value: GarminLoginStatus) {
    this._loginStatus = value;
  }
  get garminUserId(): string {
    return this._garminUserId;
  }
  set garminUserId(value: string) {
    this._garminUserId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLoginStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLoginStatusResponse.AsObject {
    return {
      loginStatus: this.loginStatus,
      garminUserId: this.garminUserId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLoginStatusResponse.AsProtobufJSON {
    return {
      loginStatus:
        GarminLoginStatus[
          this.loginStatus === null || this.loginStatus === undefined
            ? 0
            : this.loginStatus
        ],
      garminUserId: this.garminUserId
    };
  }
}
export module GetLoginStatusResponse {
  /**
   * Standard JavaScript object representation for GetLoginStatusResponse
   */
  export interface AsObject {
    loginStatus: GarminLoginStatus;
    garminUserId: string;
  }

  /**
   * Protobuf JSON representation for GetLoginStatusResponse
   */
  export interface AsProtobufJSON {
    loginStatus: string;
    garminUserId: string;
  }
}

/**
 * Message implementation for api.garmin.LogOutRequest
 */
export class LogOutRequest implements GrpcMessage {
  static id = 'api.garmin.LogOutRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogOutRequest();
    LogOutRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogOutRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogOutRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LogOutRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogOutRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogOutRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogOutRequest.AsObject>) {
    _value = _value || {};
    LogOutRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogOutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogOutRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogOutRequest.AsProtobufJSON {
    return {};
  }
}
export module LogOutRequest {
  /**
   * Standard JavaScript object representation for LogOutRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LogOutRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.garmin.LogOutResponse
 */
export class LogOutResponse implements GrpcMessage {
  static id = 'api.garmin.LogOutResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogOutResponse();
    LogOutResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogOutResponse) {
    _instance.loginStatus = _instance.loginStatus || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogOutResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.loginStatus = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    LogOutResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogOutResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.loginStatus) {
      _writer.writeEnum(1, _instance.loginStatus);
    }
  }

  private _loginStatus: GarminLoginStatus;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogOutResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogOutResponse.AsObject>) {
    _value = _value || {};
    this.loginStatus = _value.loginStatus;
    LogOutResponse.refineValues(this);
  }
  get loginStatus(): GarminLoginStatus {
    return this._loginStatus;
  }
  set loginStatus(value: GarminLoginStatus) {
    this._loginStatus = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogOutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogOutResponse.AsObject {
    return {
      loginStatus: this.loginStatus
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogOutResponse.AsProtobufJSON {
    return {
      loginStatus:
        GarminLoginStatus[
          this.loginStatus === null || this.loginStatus === undefined
            ? 0
            : this.loginStatus
        ]
    };
  }
}
export module LogOutResponse {
  /**
   * Standard JavaScript object representation for LogOutResponse
   */
  export interface AsObject {
    loginStatus: GarminLoginStatus;
  }

  /**
   * Protobuf JSON representation for LogOutResponse
   */
  export interface AsProtobufJSON {
    loginStatus: string;
  }
}

/**
 * Message implementation for api.garmin.GetBackfillStatusRequest
 */
export class GetBackfillStatusRequest implements GrpcMessage {
  static id = 'api.garmin.GetBackfillStatusRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBackfillStatusRequest();
    GetBackfillStatusRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBackfillStatusRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBackfillStatusRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetBackfillStatusRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBackfillStatusRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBackfillStatusRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBackfillStatusRequest.AsObject>) {
    _value = _value || {};
    GetBackfillStatusRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBackfillStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBackfillStatusRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBackfillStatusRequest.AsProtobufJSON {
    return {};
  }
}
export module GetBackfillStatusRequest {
  /**
   * Standard JavaScript object representation for GetBackfillStatusRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetBackfillStatusRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.garmin.GetBackfillStatusResponse
 */
export class GetBackfillStatusResponse implements GrpcMessage {
  static id = 'api.garmin.GetBackfillStatusResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBackfillStatusResponse();
    GetBackfillStatusResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBackfillStatusResponse) {
    _instance.loggedIn = _instance.loggedIn || false;
    _instance.backfillStates = _instance.backfillStates || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBackfillStatusResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.loggedIn = _reader.readBool();
          break;
        case 2:
          const messageInitializer2 = new BackfillState();
          _reader.readMessage(
            messageInitializer2,
            BackfillState.deserializeBinaryFromReader
          );
          (_instance.backfillStates = _instance.backfillStates || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetBackfillStatusResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBackfillStatusResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.loggedIn) {
      _writer.writeBool(1, _instance.loggedIn);
    }
    if (_instance.backfillStates && _instance.backfillStates.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.backfillStates as any,
        BackfillState.serializeBinaryToWriter
      );
    }
  }

  private _loggedIn: boolean;
  private _backfillStates?: BackfillState[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBackfillStatusResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBackfillStatusResponse.AsObject>) {
    _value = _value || {};
    this.loggedIn = _value.loggedIn;
    this.backfillStates = (_value.backfillStates || []).map(
      m => new BackfillState(m)
    );
    GetBackfillStatusResponse.refineValues(this);
  }
  get loggedIn(): boolean {
    return this._loggedIn;
  }
  set loggedIn(value: boolean) {
    this._loggedIn = value;
  }
  get backfillStates(): BackfillState[] | undefined {
    return this._backfillStates;
  }
  set backfillStates(value: BackfillState[] | undefined) {
    this._backfillStates = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBackfillStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBackfillStatusResponse.AsObject {
    return {
      loggedIn: this.loggedIn,
      backfillStates: (this.backfillStates || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBackfillStatusResponse.AsProtobufJSON {
    return {
      loggedIn: this.loggedIn,
      backfillStates: (this.backfillStates || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetBackfillStatusResponse {
  /**
   * Standard JavaScript object representation for GetBackfillStatusResponse
   */
  export interface AsObject {
    loggedIn: boolean;
    backfillStates?: BackfillState.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetBackfillStatusResponse
   */
  export interface AsProtobufJSON {
    loggedIn: boolean;
    backfillStates: BackfillState.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.garmin.RequestBackfillRequest
 */
export class RequestBackfillRequest implements GrpcMessage {
  static id = 'api.garmin.RequestBackfillRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestBackfillRequest();
    RequestBackfillRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestBackfillRequest) {
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestBackfillRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RequestBackfillRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestBackfillRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.startTime) {
      _writer.writeMessage(
        1,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        2,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestBackfillRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestBackfillRequest.AsObject>) {
    _value = _value || {};
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    RequestBackfillRequest.refineValues(this);
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestBackfillRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestBackfillRequest.AsObject {
    return {
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestBackfillRequest.AsProtobufJSON {
    return {
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null
    };
  }
}
export module RequestBackfillRequest {
  /**
   * Standard JavaScript object representation for RequestBackfillRequest
   */
  export interface AsObject {
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for RequestBackfillRequest
   */
  export interface AsProtobufJSON {
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.garmin.RequestBackfillResponse
 */
export class RequestBackfillResponse implements GrpcMessage {
  static id = 'api.garmin.RequestBackfillResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestBackfillResponse();
    RequestBackfillResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestBackfillResponse) {
    _instance.backfillStates = _instance.backfillStates || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestBackfillResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new BackfillState();
          _reader.readMessage(
            messageInitializer1,
            BackfillState.deserializeBinaryFromReader
          );
          (_instance.backfillStates = _instance.backfillStates || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RequestBackfillResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestBackfillResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.backfillStates && _instance.backfillStates.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.backfillStates as any,
        BackfillState.serializeBinaryToWriter
      );
    }
  }

  private _backfillStates?: BackfillState[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestBackfillResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestBackfillResponse.AsObject>) {
    _value = _value || {};
    this.backfillStates = (_value.backfillStates || []).map(
      m => new BackfillState(m)
    );
    RequestBackfillResponse.refineValues(this);
  }
  get backfillStates(): BackfillState[] | undefined {
    return this._backfillStates;
  }
  set backfillStates(value: BackfillState[] | undefined) {
    this._backfillStates = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestBackfillResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestBackfillResponse.AsObject {
    return {
      backfillStates: (this.backfillStates || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestBackfillResponse.AsProtobufJSON {
    return {
      backfillStates: (this.backfillStates || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module RequestBackfillResponse {
  /**
   * Standard JavaScript object representation for RequestBackfillResponse
   */
  export interface AsObject {
    backfillStates?: BackfillState.AsObject[];
  }

  /**
   * Protobuf JSON representation for RequestBackfillResponse
   */
  export interface AsProtobufJSON {
    backfillStates: BackfillState.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.garmin.BackfillState
 */
export class BackfillState implements GrpcMessage {
  static id = 'api.garmin.BackfillState';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BackfillState();
    BackfillState.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BackfillState) {
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
    _instance.lastEndTime = _instance.lastEndTime || undefined;
    _instance.requestTime = _instance.requestTime || undefined;
    _instance.completedTime = _instance.completedTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BackfillState,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.lastEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.requestTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.requestTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.completedTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.completedTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BackfillState.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BackfillState,
    _writer: BinaryWriter
  ) {
    if (_instance.startTime) {
      _writer.writeMessage(
        1,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        2,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastEndTime) {
      _writer.writeMessage(
        3,
        _instance.lastEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.requestTime) {
      _writer.writeMessage(
        4,
        _instance.requestTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.completedTime) {
      _writer.writeMessage(
        5,
        _instance.completedTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;
  private _lastEndTime?: googleProtobuf000.Timestamp;
  private _requestTime?: googleProtobuf000.Timestamp;
  private _completedTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BackfillState to deeply clone from
   */
  constructor(_value?: RecursivePartial<BackfillState.AsObject>) {
    _value = _value || {};
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    this.lastEndTime = _value.lastEndTime
      ? new googleProtobuf000.Timestamp(_value.lastEndTime)
      : undefined;
    this.requestTime = _value.requestTime
      ? new googleProtobuf000.Timestamp(_value.requestTime)
      : undefined;
    this.completedTime = _value.completedTime
      ? new googleProtobuf000.Timestamp(_value.completedTime)
      : undefined;
    BackfillState.refineValues(this);
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }
  get lastEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._lastEndTime;
  }
  set lastEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._lastEndTime = value;
  }
  get requestTime(): googleProtobuf000.Timestamp | undefined {
    return this._requestTime;
  }
  set requestTime(value: googleProtobuf000.Timestamp | undefined) {
    this._requestTime = value;
  }
  get completedTime(): googleProtobuf000.Timestamp | undefined {
    return this._completedTime;
  }
  set completedTime(value: googleProtobuf000.Timestamp | undefined) {
    this._completedTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BackfillState.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BackfillState.AsObject {
    return {
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
      lastEndTime: this.lastEndTime ? this.lastEndTime.toObject() : undefined,
      requestTime: this.requestTime ? this.requestTime.toObject() : undefined,
      completedTime: this.completedTime
        ? this.completedTime.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BackfillState.AsProtobufJSON {
    return {
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
      lastEndTime: this.lastEndTime
        ? this.lastEndTime.toProtobufJSON(options)
        : null,
      requestTime: this.requestTime
        ? this.requestTime.toProtobufJSON(options)
        : null,
      completedTime: this.completedTime
        ? this.completedTime.toProtobufJSON(options)
        : null
    };
  }
}
export module BackfillState {
  /**
   * Standard JavaScript object representation for BackfillState
   */
  export interface AsObject {
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
    lastEndTime?: googleProtobuf000.Timestamp.AsObject;
    requestTime?: googleProtobuf000.Timestamp.AsObject;
    completedTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for BackfillState
   */
  export interface AsProtobufJSON {
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    lastEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    requestTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    completedTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
