<div [ngStyle]="videoStyle">
  <video #video [ngStyle]="videoStyle" alt="Gallery video" preload="metadata" playsinline (loadedmetadata)="onLoadedMetadata()"
    (timeupdate)="onTimeUpdated()">
    <source src="{{ sourceUrl }}" type="video/mp4" />
  </video>
  <div class="controls">
    <div class="flex-expand"></div>
    <button class="button" (mousedown)="onPreventMouseDown($event)" (click)="onPlayPauseClick($event)"><mat-icon
        class="button-icon">{{ isPlaying ?
        'pause' : 'play_arrow' }}</mat-icon></button>
    <progress #progress class="progress" min="0" [value]="currentTime" (mousedown)="onPreventMouseDown($event)"
      tabindex="0" (keydown)="onProgressKeyDown($event)" (click)="onProgressClick($event)"></progress>
    <button class="button" (mousedown)="onPreventMouseDown($event)" (click)="onMuteClick($event)"><mat-icon
        class="button-icon">{{ video.muted ?
        'volume_off' : 'volume_mute' }}</mat-icon></button>
    <div class="flex-expand"></div>
  </div>
</div>
