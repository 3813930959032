<div class="contents">
  <div mat-dialog-title>{{ title }}</div>
  <div mat-dialog-content>{{ message }}</div>
  <div mat-dialog-actions>
    <button *ngIf="showCancel" mat-raised-button class="cancel" (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button
      mat-raised-button
      color="accent"
      class="confirm"
      (click)="dialogRef.close(true)"
    >
      {{ confirmText }}
    </button>
  </div>
</div>
