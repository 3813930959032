/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for api.event.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.event.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.loggedInUser = _reader.readBool();
          break;
        case 5:
          _instance.alias = _reader.readString();
          break;
        case 6:
          _instance.followingAndSelf = _reader.readBool();
          break;
        case 7:
          _instance.eventIds = new EventIds();
          _reader.readMessage(
            _instance.eventIds,
            EventIds.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        2,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        3,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.loggedInUser || _instance.loggedInUser === false) {
      _writer.writeBool(4, _instance.loggedInUser);
    }
    if (_instance.alias || _instance.alias === '') {
      _writer.writeString(5, _instance.alias);
    }
    if (_instance.followingAndSelf || _instance.followingAndSelf === false) {
      _writer.writeBool(6, _instance.followingAndSelf);
    }
    if (_instance.eventIds) {
      _writer.writeMessage(
        7,
        _instance.eventIds as any,
        EventIds.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;
  private _loggedInUser: boolean;
  private _alias: string;
  private _followingAndSelf: boolean;
  private _eventIds?: EventIds;

  private _filter: GetRequest.FilterCase = GetRequest.FilterCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    this.loggedInUser = _value.loggedInUser;
    this.alias = _value.alias;
    this.followingAndSelf = _value.followingAndSelf;
    this.eventIds = _value.eventIds ? new EventIds(_value.eventIds) : undefined;
    GetRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }
  get loggedInUser(): boolean {
    return this._loggedInUser;
  }
  set loggedInUser(value: boolean) {
    if (value !== undefined && value !== null) {
      this._alias = this._followingAndSelf = this._eventIds = undefined;
      this._filter = GetRequest.FilterCase.loggedInUser;
    }
    this._loggedInUser = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    if (value !== undefined && value !== null) {
      this._loggedInUser = this._followingAndSelf = this._eventIds = undefined;
      this._filter = GetRequest.FilterCase.alias;
    }
    this._alias = value;
  }
  get followingAndSelf(): boolean {
    return this._followingAndSelf;
  }
  set followingAndSelf(value: boolean) {
    if (value !== undefined && value !== null) {
      this._loggedInUser = this._alias = this._eventIds = undefined;
      this._filter = GetRequest.FilterCase.followingAndSelf;
    }
    this._followingAndSelf = value;
  }
  get eventIds(): EventIds | undefined {
    return this._eventIds;
  }
  set eventIds(value: EventIds | undefined) {
    if (value !== undefined && value !== null) {
      this._loggedInUser = this._alias = this._followingAndSelf = undefined;
      this._filter = GetRequest.FilterCase.eventIds;
    }
    this._eventIds = value;
  }
  get filter() {
    return this._filter;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
      loggedInUser: this.loggedInUser,
      alias: this.alias,
      followingAndSelf: this.followingAndSelf,
      eventIds: this.eventIds ? this.eventIds.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
      loggedInUser: this.loggedInUser,
      alias:
        this.alias === null || this.alias === undefined ? null : this.alias,
      followingAndSelf: this.followingAndSelf,
      eventIds: this.eventIds ? this.eventIds.toProtobufJSON(options) : null
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
    loggedInUser: boolean;
    alias: string;
    followingAndSelf: boolean;
    eventIds?: EventIds.AsObject;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    loggedInUser: boolean;
    alias: string | null;
    followingAndSelf: boolean;
    eventIds: EventIds.AsProtobufJSON | null;
  }
  export enum FilterCase {
    none = 0,
    loggedInUser = 1,
    alias = 2,
    followingAndSelf = 3,
    eventIds = 4
  }
}

/**
 * Message implementation for api.event.EventIds
 */
export class EventIds implements GrpcMessage {
  static id = 'api.event.EventIds';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EventIds();
    EventIds.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EventIds) {
    _instance.eventIds = _instance.eventIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EventIds,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.eventIds = _instance.eventIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    EventIds.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: EventIds, _writer: BinaryWriter) {
    if (_instance.eventIds && _instance.eventIds.length) {
      _writer.writePackedInt64String(1, _instance.eventIds);
    }
  }

  private _eventIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EventIds to deeply clone from
   */
  constructor(_value?: RecursivePartial<EventIds.AsObject>) {
    _value = _value || {};
    this.eventIds = (_value.eventIds || []).slice();
    EventIds.refineValues(this);
  }
  get eventIds(): string[] {
    return this._eventIds;
  }
  set eventIds(value: string[]) {
    this._eventIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EventIds.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EventIds.AsObject {
    return {
      eventIds: (this.eventIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EventIds.AsProtobufJSON {
    return {
      eventIds: (this.eventIds || []).slice()
    };
  }
}
export module EventIds {
  /**
   * Standard JavaScript object representation for EventIds
   */
  export interface AsObject {
    eventIds: string[];
  }

  /**
   * Protobuf JSON representation for EventIds
   */
  export interface AsProtobufJSON {
    eventIds: string[];
  }
}

/**
 * Message implementation for api.event.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.event.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.events = _instance.events || [];
    _instance.readTime = _instance.readTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new Event();
          _reader.readMessage(
            messageInitializer1,
            Event.deserializeBinaryFromReader
          );
          (_instance.events = _instance.events || []).push(messageInitializer1);
          break;
        case 2:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.events && _instance.events.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.events as any,
        Event.serializeBinaryToWriter
      );
    }
    if (_instance.readTime) {
      _writer.writeMessage(
        2,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _events?: Event[];
  private _readTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.events = (_value.events || []).map(m => new Event(m));
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    GetResponse.refineValues(this);
  }
  get events(): Event[] | undefined {
    return this._events;
  }
  set events(value: Event[] | undefined) {
    this._events = value;
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      events: (this.events || []).map(m => m.toObject()),
      readTime: this.readTime ? this.readTime.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      events: (this.events || []).map(m => m.toProtobufJSON(options)),
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    events?: Event.AsObject[];
    readTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    events: Event.AsProtobufJSON[] | null;
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.event.AddRequest
 */
export class AddRequest implements GrpcMessage {
  static id = 'api.event.AddRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddRequest();
    AddRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddRequest) {
    _instance.event = _instance.event || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.event = new Event();
          _reader.readMessage(
            _instance.event,
            Event.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: AddRequest, _writer: BinaryWriter) {
    if (_instance.event) {
      _writer.writeMessage(
        1,
        _instance.event as any,
        Event.serializeBinaryToWriter
      );
    }
  }

  private _event?: Event;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddRequest.AsObject>) {
    _value = _value || {};
    this.event = _value.event ? new Event(_value.event) : undefined;
    AddRequest.refineValues(this);
  }
  get event(): Event | undefined {
    return this._event;
  }
  set event(value: Event | undefined) {
    this._event = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddRequest.AsObject {
    return {
      event: this.event ? this.event.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddRequest.AsProtobufJSON {
    return {
      event: this.event ? this.event.toProtobufJSON(options) : null
    };
  }
}
export module AddRequest {
  /**
   * Standard JavaScript object representation for AddRequest
   */
  export interface AsObject {
    event?: Event.AsObject;
  }

  /**
   * Protobuf JSON representation for AddRequest
   */
  export interface AsProtobufJSON {
    event: Event.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.event.AddResponse
 */
export class AddResponse implements GrpcMessage {
  static id = 'api.event.AddResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddResponse();
    AddResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddResponse) {
    _instance.eventId = _instance.eventId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.eventId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    AddResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.eventId) {
      _writer.writeInt64String(1, _instance.eventId);
    }
  }

  private _eventId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddResponse.AsObject>) {
    _value = _value || {};
    this.eventId = _value.eventId;
    AddResponse.refineValues(this);
  }
  get eventId(): string {
    return this._eventId;
  }
  set eventId(value: string) {
    this._eventId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddResponse.AsObject {
    return {
      eventId: this.eventId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddResponse.AsProtobufJSON {
    return {
      eventId: this.eventId
    };
  }
}
export module AddResponse {
  /**
   * Standard JavaScript object representation for AddResponse
   */
  export interface AsObject {
    eventId: string;
  }

  /**
   * Protobuf JSON representation for AddResponse
   */
  export interface AsProtobufJSON {
    eventId: string;
  }
}

/**
 * Message implementation for api.event.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.event.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.event = _instance.event || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.event = new Event();
          _reader.readMessage(
            _instance.event,
            Event.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.event) {
      _writer.writeMessage(
        1,
        _instance.event as any,
        Event.serializeBinaryToWriter
      );
    }
  }

  private _event?: Event;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.event = _value.event ? new Event(_value.event) : undefined;
    UpdateRequest.refineValues(this);
  }
  get event(): Event | undefined {
    return this._event;
  }
  set event(value: Event | undefined) {
    this._event = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      event: this.event ? this.event.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      event: this.event ? this.event.toProtobufJSON(options) : null
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    event?: Event.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    event: Event.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.event.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.event.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    UpdateResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.event.Event
 */
export class Event implements GrpcMessage {
  static id = 'api.event.Event';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Event();
    Event.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Event) {
    _instance.alias = _instance.alias || '';
    _instance.eventId = _instance.eventId || '0';
    _instance.title = _instance.title || '';

    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
    _instance.allDay = _instance.allDay || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Event, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.eventId = _reader.readInt64String();
          break;
        case 3:
          _instance.title = _reader.readString();
          break;
        case 4:
          _instance.descriptionHtml = _reader.readString();
          break;
        case 5:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.allDay = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    Event.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Event, _writer: BinaryWriter) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.eventId) {
      _writer.writeInt64String(2, _instance.eventId);
    }
    if (_instance.title) {
      _writer.writeString(3, _instance.title);
    }
    if (
      _instance.descriptionHtml !== undefined &&
      _instance.descriptionHtml !== null
    ) {
      _writer.writeString(4, _instance.descriptionHtml);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        5,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        6,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.allDay) {
      _writer.writeBool(7, _instance.allDay);
    }
  }

  private _alias: string;
  private _eventId: string;
  private _title: string;
  private _descriptionHtml?: string;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;
  private _allDay: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Event to deeply clone from
   */
  constructor(_value?: RecursivePartial<Event.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.eventId = _value.eventId;
    this.title = _value.title;
    this.descriptionHtml = _value.descriptionHtml;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    this.allDay = _value.allDay;
    Event.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get eventId(): string {
    return this._eventId;
  }
  set eventId(value: string) {
    this._eventId = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get descriptionHtml(): string | undefined {
    return this._descriptionHtml;
  }
  set descriptionHtml(value?: string) {
    this._descriptionHtml = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }
  get allDay(): boolean {
    return this._allDay;
  }
  set allDay(value: boolean) {
    this._allDay = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Event.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Event.AsObject {
    return {
      alias: this.alias,
      eventId: this.eventId,
      title: this.title,
      descriptionHtml: this.descriptionHtml,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
      allDay: this.allDay
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Event.AsProtobufJSON {
    return {
      alias: this.alias,
      eventId: this.eventId,
      title: this.title,
      descriptionHtml:
        this.descriptionHtml === null || this.descriptionHtml === undefined
          ? null
          : this.descriptionHtml,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
      allDay: this.allDay
    };
  }
}
export module Event {
  /**
   * Standard JavaScript object representation for Event
   */
  export interface AsObject {
    alias: string;
    eventId: string;
    title: string;
    descriptionHtml?: string;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
    allDay: boolean;
  }

  /**
   * Protobuf JSON representation for Event
   */
  export interface AsProtobufJSON {
    alias: string;
    eventId: string;
    title: string;
    descriptionHtml: string | null;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    allDay: boolean;
  }
}

/**
 * Message implementation for api.event.DeleteRequest
 */
export class DeleteRequest implements GrpcMessage {
  static id = 'api.event.DeleteRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteRequest();
    DeleteRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteRequest) {
    _instance.eventId = _instance.eventId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.eventId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.eventId) {
      _writer.writeInt64String(1, _instance.eventId);
    }
  }

  private _eventId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteRequest.AsObject>) {
    _value = _value || {};
    this.eventId = _value.eventId;
    DeleteRequest.refineValues(this);
  }
  get eventId(): string {
    return this._eventId;
  }
  set eventId(value: string) {
    this._eventId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteRequest.AsObject {
    return {
      eventId: this.eventId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteRequest.AsProtobufJSON {
    return {
      eventId: this.eventId
    };
  }
}
export module DeleteRequest {
  /**
   * Standard JavaScript object representation for DeleteRequest
   */
  export interface AsObject {
    eventId: string;
  }

  /**
   * Protobuf JSON representation for DeleteRequest
   */
  export interface AsProtobufJSON {
    eventId: string;
  }
}

/**
 * Message implementation for api.event.DeleteResponse
 */
export class DeleteResponse implements GrpcMessage {
  static id = 'api.event.DeleteResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteResponse();
    DeleteResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    DeleteResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteResponse.AsObject>) {
    _value = _value || {};
    DeleteResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteResponse.AsProtobufJSON {
    return {};
  }
}
export module DeleteResponse {
  /**
   * Standard JavaScript object representation for DeleteResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for DeleteResponse
   */
  export interface AsProtobufJSON {}
}
