/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as shared000 from '../../../../src/main/proto/shared/config-shared.pb';
/**
 * Message implementation for api.config.GetConfigRequest
 */
export class GetConfigRequest implements GrpcMessage {
  static id = 'api.config.GetConfigRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfigRequest();
    GetConfigRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfigRequest) {
    _instance.configId = _instance.configId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfigRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.configId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfigRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfigRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.configId) {
      _writer.writeInt64String(1, _instance.configId);
    }
  }

  private _configId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfigRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetConfigRequest.AsObject>) {
    _value = _value || {};
    this.configId = _value.configId;
    GetConfigRequest.refineValues(this);
  }
  get configId(): string {
    return this._configId;
  }
  set configId(value: string) {
    this._configId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfigRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfigRequest.AsObject {
    return {
      configId: this.configId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfigRequest.AsProtobufJSON {
    return {
      configId: this.configId
    };
  }
}
export module GetConfigRequest {
  /**
   * Standard JavaScript object representation for GetConfigRequest
   */
  export interface AsObject {
    configId: string;
  }

  /**
   * Protobuf JSON representation for GetConfigRequest
   */
  export interface AsProtobufJSON {
    configId: string;
  }
}

/**
 * Message implementation for api.config.GetConfigResponse
 */
export class GetConfigResponse implements GrpcMessage {
  static id = 'api.config.GetConfigResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfigResponse();
    GetConfigResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfigResponse) {
    _instance.config = _instance.config || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfigResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.config = new Config();
          _reader.readMessage(
            _instance.config,
            Config.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfigResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfigResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.config) {
      _writer.writeMessage(
        1,
        _instance.config as any,
        Config.serializeBinaryToWriter
      );
    }
  }

  private _config?: Config;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfigResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetConfigResponse.AsObject>) {
    _value = _value || {};
    this.config = _value.config ? new Config(_value.config) : undefined;
    GetConfigResponse.refineValues(this);
  }
  get config(): Config | undefined {
    return this._config;
  }
  set config(value: Config | undefined) {
    this._config = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfigResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfigResponse.AsObject {
    return {
      config: this.config ? this.config.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfigResponse.AsProtobufJSON {
    return {
      config: this.config ? this.config.toProtobufJSON(options) : null
    };
  }
}
export module GetConfigResponse {
  /**
   * Standard JavaScript object representation for GetConfigResponse
   */
  export interface AsObject {
    config?: Config.AsObject;
  }

  /**
   * Protobuf JSON representation for GetConfigResponse
   */
  export interface AsProtobufJSON {
    config: Config.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.config.UpdateConfigRequest
 */
export class UpdateConfigRequest implements GrpcMessage {
  static id = 'api.config.UpdateConfigRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateConfigRequest();
    UpdateConfigRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateConfigRequest) {
    _instance.config = _instance.config || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateConfigRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.config = new Config();
          _reader.readMessage(
            _instance.config,
            Config.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateConfigRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateConfigRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.config) {
      _writer.writeMessage(
        1,
        _instance.config as any,
        Config.serializeBinaryToWriter
      );
    }
  }

  private _config?: Config;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateConfigRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateConfigRequest.AsObject>) {
    _value = _value || {};
    this.config = _value.config ? new Config(_value.config) : undefined;
    UpdateConfigRequest.refineValues(this);
  }
  get config(): Config | undefined {
    return this._config;
  }
  set config(value: Config | undefined) {
    this._config = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateConfigRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateConfigRequest.AsObject {
    return {
      config: this.config ? this.config.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateConfigRequest.AsProtobufJSON {
    return {
      config: this.config ? this.config.toProtobufJSON(options) : null
    };
  }
}
export module UpdateConfigRequest {
  /**
   * Standard JavaScript object representation for UpdateConfigRequest
   */
  export interface AsObject {
    config?: Config.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateConfigRequest
   */
  export interface AsProtobufJSON {
    config: Config.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.config.UpdateConfigResponse
 */
export class UpdateConfigResponse implements GrpcMessage {
  static id = 'api.config.UpdateConfigResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateConfigResponse();
    UpdateConfigResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateConfigResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateConfigResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateConfigResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateConfigResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateConfigResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateConfigResponse.AsObject>) {
    _value = _value || {};
    UpdateConfigResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateConfigResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateConfigResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateConfigResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateConfigResponse {
  /**
   * Standard JavaScript object representation for UpdateConfigResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateConfigResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.config.Config
 */
export class Config implements GrpcMessage {
  static id = 'api.config.Config';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Config();
    Config.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Config) {
    _instance.configId = _instance.configId || '0';
    _instance.apiConfig = _instance.apiConfig || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Config, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.configId = _reader.readInt64String();
          break;
        case 2:
          _instance.apiConfig = new shared000.ApiConfig();
          _reader.readMessage(
            _instance.apiConfig,
            shared000.ApiConfig.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Config.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Config, _writer: BinaryWriter) {
    if (_instance.configId) {
      _writer.writeInt64String(1, _instance.configId);
    }
    if (_instance.apiConfig) {
      _writer.writeMessage(
        2,
        _instance.apiConfig as any,
        shared000.ApiConfig.serializeBinaryToWriter
      );
    }
  }

  private _configId: string;
  private _apiConfig?: shared000.ApiConfig;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Config to deeply clone from
   */
  constructor(_value?: RecursivePartial<Config.AsObject>) {
    _value = _value || {};
    this.configId = _value.configId;
    this.apiConfig = _value.apiConfig
      ? new shared000.ApiConfig(_value.apiConfig)
      : undefined;
    Config.refineValues(this);
  }
  get configId(): string {
    return this._configId;
  }
  set configId(value: string) {
    this._configId = value;
  }
  get apiConfig(): shared000.ApiConfig | undefined {
    return this._apiConfig;
  }
  set apiConfig(value: shared000.ApiConfig | undefined) {
    this._apiConfig = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Config.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Config.AsObject {
    return {
      configId: this.configId,
      apiConfig: this.apiConfig ? this.apiConfig.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Config.AsProtobufJSON {
    return {
      configId: this.configId,
      apiConfig: this.apiConfig ? this.apiConfig.toProtobufJSON(options) : null
    };
  }
}
export module Config {
  /**
   * Standard JavaScript object representation for Config
   */
  export interface AsObject {
    configId: string;
    apiConfig?: shared000.ApiConfig.AsObject;
  }

  /**
   * Protobuf JSON representation for Config
   */
  export interface AsProtobufJSON {
    configId: string;
    apiConfig: shared000.ApiConfig.AsProtobufJSON | null;
  }
}
