/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum FitbitLoginStatus {
  FITBIT_LOGIN_STATUS_UNSPECIFIED = 0,
  FITBIT_LOGIN_STATUS_LOGGED_IN = 1,
  FITBIT_LOGIN_STATUS_NO_RECORD = 2,
  FITBIT_LOGIN_STATUS_NO_TOKEN = 3
}
/**
 * Message implementation for api.fitbit.StartLogInRequest
 */
export class StartLogInRequest implements GrpcMessage {
  static id = 'api.fitbit.StartLogInRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StartLogInRequest();
    StartLogInRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StartLogInRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StartLogInRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    StartLogInRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StartLogInRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StartLogInRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<StartLogInRequest.AsObject>) {
    _value = _value || {};
    StartLogInRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StartLogInRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StartLogInRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StartLogInRequest.AsProtobufJSON {
    return {};
  }
}
export module StartLogInRequest {
  /**
   * Standard JavaScript object representation for StartLogInRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for StartLogInRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.fitbit.StartLogInResponse
 */
export class StartLogInResponse implements GrpcMessage {
  static id = 'api.fitbit.StartLogInResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StartLogInResponse();
    StartLogInResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StartLogInResponse) {
    _instance.applicationClientId = _instance.applicationClientId || '';
    _instance.scope = _instance.scope || '';
    _instance.codeChallenge = _instance.codeChallenge || '';
    _instance.codeChallengeMethod = _instance.codeChallengeMethod || '';
    _instance.responseType = _instance.responseType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StartLogInResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.applicationClientId = _reader.readString();
          break;
        case 2:
          _instance.scope = _reader.readString();
          break;
        case 3:
          _instance.codeChallenge = _reader.readString();
          break;
        case 4:
          _instance.codeChallengeMethod = _reader.readString();
          break;
        case 5:
          _instance.responseType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    StartLogInResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StartLogInResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.applicationClientId) {
      _writer.writeString(1, _instance.applicationClientId);
    }
    if (_instance.scope) {
      _writer.writeString(2, _instance.scope);
    }
    if (_instance.codeChallenge) {
      _writer.writeString(3, _instance.codeChallenge);
    }
    if (_instance.codeChallengeMethod) {
      _writer.writeString(4, _instance.codeChallengeMethod);
    }
    if (_instance.responseType) {
      _writer.writeString(5, _instance.responseType);
    }
  }

  private _applicationClientId: string;
  private _scope: string;
  private _codeChallenge: string;
  private _codeChallengeMethod: string;
  private _responseType: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StartLogInResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<StartLogInResponse.AsObject>) {
    _value = _value || {};
    this.applicationClientId = _value.applicationClientId;
    this.scope = _value.scope;
    this.codeChallenge = _value.codeChallenge;
    this.codeChallengeMethod = _value.codeChallengeMethod;
    this.responseType = _value.responseType;
    StartLogInResponse.refineValues(this);
  }
  get applicationClientId(): string {
    return this._applicationClientId;
  }
  set applicationClientId(value: string) {
    this._applicationClientId = value;
  }
  get scope(): string {
    return this._scope;
  }
  set scope(value: string) {
    this._scope = value;
  }
  get codeChallenge(): string {
    return this._codeChallenge;
  }
  set codeChallenge(value: string) {
    this._codeChallenge = value;
  }
  get codeChallengeMethod(): string {
    return this._codeChallengeMethod;
  }
  set codeChallengeMethod(value: string) {
    this._codeChallengeMethod = value;
  }
  get responseType(): string {
    return this._responseType;
  }
  set responseType(value: string) {
    this._responseType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StartLogInResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StartLogInResponse.AsObject {
    return {
      applicationClientId: this.applicationClientId,
      scope: this.scope,
      codeChallenge: this.codeChallenge,
      codeChallengeMethod: this.codeChallengeMethod,
      responseType: this.responseType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StartLogInResponse.AsProtobufJSON {
    return {
      applicationClientId: this.applicationClientId,
      scope: this.scope,
      codeChallenge: this.codeChallenge,
      codeChallengeMethod: this.codeChallengeMethod,
      responseType: this.responseType
    };
  }
}
export module StartLogInResponse {
  /**
   * Standard JavaScript object representation for StartLogInResponse
   */
  export interface AsObject {
    applicationClientId: string;
    scope: string;
    codeChallenge: string;
    codeChallengeMethod: string;
    responseType: string;
  }

  /**
   * Protobuf JSON representation for StartLogInResponse
   */
  export interface AsProtobufJSON {
    applicationClientId: string;
    scope: string;
    codeChallenge: string;
    codeChallengeMethod: string;
    responseType: string;
  }
}

/**
 * Message implementation for api.fitbit.CompleteLogInRequest
 */
export class CompleteLogInRequest implements GrpcMessage {
  static id = 'api.fitbit.CompleteLogInRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompleteLogInRequest();
    CompleteLogInRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompleteLogInRequest) {
    _instance.code = _instance.code || '';
    _instance.redirectUri = _instance.redirectUri || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompleteLogInRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.code = _reader.readString();
          break;
        case 2:
          _instance.redirectUri = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CompleteLogInRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompleteLogInRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.code) {
      _writer.writeString(1, _instance.code);
    }
    if (_instance.redirectUri) {
      _writer.writeString(2, _instance.redirectUri);
    }
  }

  private _code: string;
  private _redirectUri: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompleteLogInRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompleteLogInRequest.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.redirectUri = _value.redirectUri;
    CompleteLogInRequest.refineValues(this);
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get redirectUri(): string {
    return this._redirectUri;
  }
  set redirectUri(value: string) {
    this._redirectUri = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompleteLogInRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompleteLogInRequest.AsObject {
    return {
      code: this.code,
      redirectUri: this.redirectUri
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompleteLogInRequest.AsProtobufJSON {
    return {
      code: this.code,
      redirectUri: this.redirectUri
    };
  }
}
export module CompleteLogInRequest {
  /**
   * Standard JavaScript object representation for CompleteLogInRequest
   */
  export interface AsObject {
    code: string;
    redirectUri: string;
  }

  /**
   * Protobuf JSON representation for CompleteLogInRequest
   */
  export interface AsProtobufJSON {
    code: string;
    redirectUri: string;
  }
}

/**
 * Message implementation for api.fitbit.CompleteLogInResponse
 */
export class CompleteLogInResponse implements GrpcMessage {
  static id = 'api.fitbit.CompleteLogInResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompleteLogInResponse();
    CompleteLogInResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompleteLogInResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompleteLogInResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    CompleteLogInResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompleteLogInResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompleteLogInResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompleteLogInResponse.AsObject>) {
    _value = _value || {};
    CompleteLogInResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompleteLogInResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompleteLogInResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompleteLogInResponse.AsProtobufJSON {
    return {};
  }
}
export module CompleteLogInResponse {
  /**
   * Standard JavaScript object representation for CompleteLogInResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for CompleteLogInResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.fitbit.GetLoginStatusRequest
 */
export class GetLoginStatusRequest implements GrpcMessage {
  static id = 'api.fitbit.GetLoginStatusRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLoginStatusRequest();
    GetLoginStatusRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLoginStatusRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLoginStatusRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetLoginStatusRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLoginStatusRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLoginStatusRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLoginStatusRequest.AsObject>) {
    _value = _value || {};
    GetLoginStatusRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLoginStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLoginStatusRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLoginStatusRequest.AsProtobufJSON {
    return {};
  }
}
export module GetLoginStatusRequest {
  /**
   * Standard JavaScript object representation for GetLoginStatusRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetLoginStatusRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.fitbit.GetLoginStatusResponse
 */
export class GetLoginStatusResponse implements GrpcMessage {
  static id = 'api.fitbit.GetLoginStatusResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLoginStatusResponse();
    GetLoginStatusResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLoginStatusResponse) {
    _instance.loginStatus = _instance.loginStatus || 0;
    _instance.fitbitUserId = _instance.fitbitUserId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLoginStatusResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.loginStatus = _reader.readEnum();
          break;
        case 2:
          _instance.fitbitUserId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLoginStatusResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLoginStatusResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.loginStatus) {
      _writer.writeEnum(1, _instance.loginStatus);
    }
    if (_instance.fitbitUserId) {
      _writer.writeString(2, _instance.fitbitUserId);
    }
  }

  private _loginStatus: FitbitLoginStatus;
  private _fitbitUserId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLoginStatusResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLoginStatusResponse.AsObject>) {
    _value = _value || {};
    this.loginStatus = _value.loginStatus;
    this.fitbitUserId = _value.fitbitUserId;
    GetLoginStatusResponse.refineValues(this);
  }
  get loginStatus(): FitbitLoginStatus {
    return this._loginStatus;
  }
  set loginStatus(value: FitbitLoginStatus) {
    this._loginStatus = value;
  }
  get fitbitUserId(): string {
    return this._fitbitUserId;
  }
  set fitbitUserId(value: string) {
    this._fitbitUserId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLoginStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLoginStatusResponse.AsObject {
    return {
      loginStatus: this.loginStatus,
      fitbitUserId: this.fitbitUserId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLoginStatusResponse.AsProtobufJSON {
    return {
      loginStatus:
        FitbitLoginStatus[
          this.loginStatus === null || this.loginStatus === undefined
            ? 0
            : this.loginStatus
        ],
      fitbitUserId: this.fitbitUserId
    };
  }
}
export module GetLoginStatusResponse {
  /**
   * Standard JavaScript object representation for GetLoginStatusResponse
   */
  export interface AsObject {
    loginStatus: FitbitLoginStatus;
    fitbitUserId: string;
  }

  /**
   * Protobuf JSON representation for GetLoginStatusResponse
   */
  export interface AsProtobufJSON {
    loginStatus: string;
    fitbitUserId: string;
  }
}

/**
 * Message implementation for api.fitbit.LogOutRequest
 */
export class LogOutRequest implements GrpcMessage {
  static id = 'api.fitbit.LogOutRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogOutRequest();
    LogOutRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogOutRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogOutRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LogOutRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogOutRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogOutRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogOutRequest.AsObject>) {
    _value = _value || {};
    LogOutRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogOutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogOutRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogOutRequest.AsProtobufJSON {
    return {};
  }
}
export module LogOutRequest {
  /**
   * Standard JavaScript object representation for LogOutRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LogOutRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.fitbit.LogOutResponse
 */
export class LogOutResponse implements GrpcMessage {
  static id = 'api.fitbit.LogOutResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogOutResponse();
    LogOutResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogOutResponse) {
    _instance.loginStatus = _instance.loginStatus || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogOutResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.loginStatus = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    LogOutResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogOutResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.loginStatus) {
      _writer.writeEnum(1, _instance.loginStatus);
    }
  }

  private _loginStatus: FitbitLoginStatus;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogOutResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogOutResponse.AsObject>) {
    _value = _value || {};
    this.loginStatus = _value.loginStatus;
    LogOutResponse.refineValues(this);
  }
  get loginStatus(): FitbitLoginStatus {
    return this._loginStatus;
  }
  set loginStatus(value: FitbitLoginStatus) {
    this._loginStatus = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogOutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogOutResponse.AsObject {
    return {
      loginStatus: this.loginStatus
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogOutResponse.AsProtobufJSON {
    return {
      loginStatus:
        FitbitLoginStatus[
          this.loginStatus === null || this.loginStatus === undefined
            ? 0
            : this.loginStatus
        ]
    };
  }
}
export module LogOutResponse {
  /**
   * Standard JavaScript object representation for LogOutResponse
   */
  export interface AsObject {
    loginStatus: FitbitLoginStatus;
  }

  /**
   * Protobuf JSON representation for LogOutResponse
   */
  export interface AsProtobufJSON {
    loginStatus: string;
  }
}
