/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './follow-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import { GRPC_FOLLOW_SERVICE_CLIENT_SETTINGS } from './follow-service.pbconf';
/**
 * Service client implementation for api.follow.FollowService
 */
@Injectable({ providedIn: 'any' })
export class FollowServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.follow.FollowService/FindToFollow
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.FindToFollowResponse>>
     */
    findToFollow: (
      requestData: thisProto.FindToFollowRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.FindToFollowResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/FindToFollow',
        requestData,
        requestMetadata,
        requestClass: thisProto.FindToFollowRequest,
        responseClass: thisProto.FindToFollowResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/RequestToFollow
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RequestToFollowResponse>>
     */
    requestToFollow: (
      requestData: thisProto.RequestToFollowRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RequestToFollowResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/RequestToFollow',
        requestData,
        requestMetadata,
        requestClass: thisProto.RequestToFollowRequest,
        responseClass: thisProto.RequestToFollowResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/GetFollowRequests
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetFollowRequestsResponse>>
     */
    getFollowRequests: (
      requestData: thisProto.GetFollowRequestsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetFollowRequestsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/GetFollowRequests',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFollowRequestsRequest,
        responseClass: thisProto.GetFollowRequestsResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/GetFollowers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetFollowersResponse>>
     */
    getFollowers: (
      requestData: thisProto.GetFollowersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetFollowersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/GetFollowers',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFollowersRequest,
        responseClass: thisProto.GetFollowersResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/GetFollowing
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetFollowingResponse>>
     */
    getFollowing: (
      requestData: thisProto.GetFollowingRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetFollowingResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/GetFollowing',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFollowingRequest,
        responseClass: thisProto.GetFollowingResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/RemoveFollower
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RemoveFollowerResponse>>
     */
    removeFollower: (
      requestData: thisProto.RemoveFollowerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RemoveFollowerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/RemoveFollower',
        requestData,
        requestMetadata,
        requestClass: thisProto.RemoveFollowerRequest,
        responseClass: thisProto.RemoveFollowerResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/Unfollow
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UnfollowResponse>>
     */
    unfollow: (
      requestData: thisProto.UnfollowRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UnfollowResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/Unfollow',
        requestData,
        requestMetadata,
        requestClass: thisProto.UnfollowRequest,
        responseClass: thisProto.UnfollowResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/ResolveFollowRequest
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ResolveFollowRequestResponse>>
     */
    resolveFollowRequest: (
      requestData: thisProto.ResolveFollowRequestRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ResolveFollowRequestResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/ResolveFollowRequest',
        requestData,
        requestMetadata,
        requestClass: thisProto.ResolveFollowRequestRequest,
        responseClass: thisProto.ResolveFollowRequestResponse
      });
    },
    /**
     * Unary call: /api.follow.FollowService/GetStats
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetStatsResponse>>
     */
    getStats: (
      requestData: thisProto.GetStatsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetStatsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.follow.FollowService/GetStats',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetStatsRequest,
        responseClass: thisProto.GetStatsResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_FOLLOW_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.follow.FollowService',
      settings
    );
  }

  /**
   * Unary call @/api.follow.FollowService/FindToFollow
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.FindToFollowResponse>
   */
  findToFollow(
    requestData: thisProto.FindToFollowRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.FindToFollowResponse> {
    return this.$raw
      .findToFollow(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/RequestToFollow
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RequestToFollowResponse>
   */
  requestToFollow(
    requestData: thisProto.RequestToFollowRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RequestToFollowResponse> {
    return this.$raw
      .requestToFollow(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/GetFollowRequests
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetFollowRequestsResponse>
   */
  getFollowRequests(
    requestData: thisProto.GetFollowRequestsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetFollowRequestsResponse> {
    return this.$raw
      .getFollowRequests(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/GetFollowers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetFollowersResponse>
   */
  getFollowers(
    requestData: thisProto.GetFollowersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetFollowersResponse> {
    return this.$raw
      .getFollowers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/GetFollowing
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetFollowingResponse>
   */
  getFollowing(
    requestData: thisProto.GetFollowingRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetFollowingResponse> {
    return this.$raw
      .getFollowing(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/RemoveFollower
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RemoveFollowerResponse>
   */
  removeFollower(
    requestData: thisProto.RemoveFollowerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RemoveFollowerResponse> {
    return this.$raw
      .removeFollower(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/Unfollow
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UnfollowResponse>
   */
  unfollow(
    requestData: thisProto.UnfollowRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UnfollowResponse> {
    return this.$raw
      .unfollow(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/ResolveFollowRequest
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ResolveFollowRequestResponse>
   */
  resolveFollowRequest(
    requestData: thisProto.ResolveFollowRequestRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ResolveFollowRequestResponse> {
    return this.$raw
      .resolveFollowRequest(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.follow.FollowService/GetStats
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetStatsResponse>
   */
  getStats(
    requestData: thisProto.GetStatsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetStatsResponse> {
    return this.$raw
      .getStats(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
