import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  Follower,
  GetFollowersResponse,
  GetFollowingResponse,
} from 'generated/src/main/proto/api/follow-service.pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { FollowService } from '../services/follow/follow.service';

export class FollowDataSource extends DataSource<Follower> {
  private followerSubject = new BehaviorSubject<readonly Follower[]>([]);
  private paginator: MatPaginator | undefined;
  private readTime: Timestamp | undefined;

  constructor(
    private following: boolean,
    private followService: FollowService,
  ) {
    super();
  }

  setPaginator(paginator: MatPaginator) {
    this.paginator = paginator;

    // Load initial page.
    this.loadPage(paginator.pageIndex * paginator.pageSize, paginator.pageSize);

    // Subscribe to requests for subsequent pages.
    paginator.page.subscribe((pageEvent) =>
      this.loadPage(
        pageEvent.pageIndex * pageEvent.pageSize,
        pageEvent.pageSize,
      ),
    );
  }

  connect(): Observable<readonly Follower[]> {
    return this.followerSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  reload() {
    this.readTime = undefined;
    if (this.paginator) {
      this.loadPage(
        this.paginator.pageIndex * this.paginator.pageSize,
        this.paginator.pageSize,
      );
    }
  }

  private loadPage(index: number, count: number): void {
    if (this.following) {
      this.followService
        .getFollowing(index, count, this.readTime)
        .then((r: GetFollowingResponse) => {
          this.readTime = r.readTime;
          return this.followerSubject.next(r.followers!);
        });
    } else {
      this.followService
        .getFollowers(index, count, this.readTime)
        .then((r: GetFollowersResponse) => {
          this.readTime = r.readTime;
          return this.followerSubject.next(r.followers!);
        });
    }
  }
}
