import { Component, Input } from '@angular/core';
import { DocsTableOfContents } from './docs-table-of-contents';

export interface ActivePath {
  get(): DocsTableOfContents | undefined;

  set(value: DocsTableOfContents): void;
}

@Component({
  selector: 'app-docs-toc',
  templateUrl: './docs-toc.component.html',
  styleUrl: './docs-toc.component.scss',
})
export class DocsTocComponent {
  @Input() item!: DocsTableOfContents;

  @Input() activePath!: ActivePath;

  onClick(): void {
    this.clicked();
  }

  onKeyDown(e: KeyboardEvent): void {
    if (e.key == ' ') {
      this.clicked();
    }
  }

  get isVisible(): boolean {
    const activeDoc = this.activePath.get();
    const activePath = activeDoc?.path ?? '';
    const activeLevel = activeDoc?.level ?? 0;
    const itemPath = this.item.path;

    // Ancestors are always visible.
    if (activePath.startsWith(itemPath)) {
      return true;
    }

    // Non siblings of the active path are not visible.
    const parentPath = activePath.substring(0, activePath.lastIndexOf('/'));
    if (!itemPath.startsWith(parentPath)) {
      return this.item.level == 0;
    }

    // Children of the active path are visible.
    return (
      this.item.level == activeLevel ||
      (itemPath.startsWith(activePath) &&
        itemPath.substring(activePath.length + 1).indexOf('/') < 0)
    );
  }

  get isActive(): boolean {
    const activeDoc = this.activePath.get();
    const activePath = activeDoc?.path ?? '';
    return activePath == this.item.path;
  }

  private clicked(): void {
    this.activePath.set(this.item);
  }
}
