/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/shared/reaction.pb';
/**
 * Message implementation for shared.DocumentMetadata
 */
export class DocumentMetadata implements GrpcMessage {
  static id = 'shared.DocumentMetadata';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DocumentMetadata();
    DocumentMetadata.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DocumentMetadata) {
    _instance.reactions = _instance.reactions || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DocumentMetadata,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.reactions = new shared001.Reactions();
          _reader.readMessage(
            _instance.reactions,
            shared001.Reactions.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DocumentMetadata.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DocumentMetadata,
    _writer: BinaryWriter
  ) {
    if (_instance.reactions) {
      _writer.writeMessage(
        1,
        _instance.reactions as any,
        shared001.Reactions.serializeBinaryToWriter
      );
    }
  }

  private _reactions?: shared001.Reactions;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DocumentMetadata to deeply clone from
   */
  constructor(_value?: RecursivePartial<DocumentMetadata.AsObject>) {
    _value = _value || {};
    this.reactions = _value.reactions
      ? new shared001.Reactions(_value.reactions)
      : undefined;
    DocumentMetadata.refineValues(this);
  }
  get reactions(): shared001.Reactions | undefined {
    return this._reactions;
  }
  set reactions(value: shared001.Reactions | undefined) {
    this._reactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DocumentMetadata.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DocumentMetadata.AsObject {
    return {
      reactions: this.reactions ? this.reactions.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DocumentMetadata.AsProtobufJSON {
    return {
      reactions: this.reactions ? this.reactions.toProtobufJSON(options) : null
    };
  }
}
export module DocumentMetadata {
  /**
   * Standard JavaScript object representation for DocumentMetadata
   */
  export interface AsObject {
    reactions?: shared001.Reactions.AsObject;
  }

  /**
   * Protobuf JSON representation for DocumentMetadata
   */
  export interface AsProtobufJSON {
    reactions: shared001.Reactions.AsProtobufJSON | null;
  }
}
