import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivacyControlComponent } from './privacy-control.component';

@NgModule({
  declarations: [PrivacyControlComponent],
  exports: [PrivacyControlComponent],
  imports: [CommonModule, MatRadioModule, MatTooltipModule],
})
export class PrivacyControlModule {}
