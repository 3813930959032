<div class="form">
  <!-- Issue type -->
  <mat-form-field class="issue-type-field" [floatLabel]="'always'">
    <mat-label>Issue Type</mat-label>
    <mat-select required [(value)]="issueType">
      <mat-option *ngFor="let v of issueTypeKeys" [value]="v">{{v}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Consent to contact the user -->
  <mat-checkbox
    required
    [(ngModel)]="consentToContact"
    matTooltip="Consent to contact"
    [ngModelOptions]="{ standalone: true }"
    >Is it ok to contact you if we need more info?</mat-checkbox
  >

  <!-- Screenshot sharing -->
  <div class="screenshot-container">
    <div
      *ngIf="!imageSrc"
      [ngStyle]="{ 'width': imageWidth + 'px', 'height': imageHeight + 'px' }"
    >
      Generating screenshot...
    </div>
    <img
      *ngIf="imageSrc"
      width="{{ imageWidth }}"
      height="{{ imageHeight }}"
      src="{{ imageSrc }}"
      alt="Screenshot"
    />
    <mat-checkbox
      required
      [(ngModel)]="submitWithScreenshot"
      matTooltip="Consent to share screenshot"
      [ngModelOptions]="{ standalone: true }"
      >Submit with screenshot</mat-checkbox
    >
  </div>

  <!-- Title -->
  <mat-form-field [floatLabel]="'always'">
    <mat-label>Issue title</mat-label>
    <input
      matInput
      [(ngModel)]="title"
      placeholder="Enter title"
      maxlength="200"
    />
  </mat-form-field>

  <!-- Description -->
  <mat-form-field [floatLabel]="'always'">
    <mat-label>Issue description</mat-label>
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      [(ngModel)]="description"
      placeholder="Please describe the issue"
      maxlength="2000"
    ></textarea>
  </mat-form-field>

  <div mat-dialog-actions class="buttons">
    <button mat-raised-button (click)="onCancel()">Cancel</button>
    <button
      mat-raised-button
      [disabled]="isSubmitDisabled"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</div>
