import { SocialUser } from '@abacritt/angularx-social-login';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { UserPrivacyControls } from 'generated/src/main/proto/api/user-service.pb';
import { Media } from 'generated/src/main/proto/shared/media-shared.pb';
import { NotificationControls } from 'generated/src/main/proto/shared/notification-shared.pb';
import {
  HeartRateLimits,
  UserDataAccessLevel,
  UserHeartRateLimits,
  UserLocation,
  UserPrivateDataAccessList,
} from 'generated/src/main/proto/shared/user-shared.pb';
import { Subscription } from 'rxjs';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import { PolicyVersions } from '../common/policy-versions';
import { cookiePolicyAcceptObservable, showCookieBanner } from '../cookies';
import {
  BannerMessage,
  BannerService,
} from '../services/banner/banner.service';
import { FormatService } from '../services/format.service';
import { MapRendererService } from '../services/map-renderer/map-renderer.service';
import { MediaService } from '../services/media-service/media.service';
import { NotificationsService } from '../services/notifications/notifications.service';
import { ReadFileService } from '../services/read-file/read-file.service';
import {
  LoggedInUser,
  UnitPreference,
  UserService,
} from '../services/user/user.service';

enum IntegrationPlatform {
  Fitbit = 'Fitbit',
  Garmin = 'Garmin',
}

/**
 * Profile and signup component.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit {
  private static readonly MAX_LOCATIONS = 5;
  private static readonly DEFAULT_TIME_ZONE =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  private static readonly TIME_ZONES = Intl.supportedValuesOf('timeZone');

  readonly unitPreferenceValues = this.initUnitPreferenceValues();
  readonly timeZones = LoginComponent.TIME_ZONES;
  readonly sexValues = FormatService.initSexValues();
  readonly dateFormat: string;

  width: number;
  margin: number;
  mapHeight: number;

  @Input()
  updateMode = false;

  @ViewChild('profileImageInput')
  profileImageInput!: ElementRef<HTMLInputElement>;

  @ViewChild('locationMap')
  locationMap!: ElementRef<HTMLDivElement>;
  @ViewChild('locationFindInput')
  locationFindInput!: ElementRef<HTMLInputElement>;
  @ViewChild('locationAutocompleteList')
  locationAutocompleteList!: ElementRef<HTMLInputElement>;
  readonly locationsColumns = ['name', 'location', 'delete'];
  private autocomplete?: google.maps.places.Autocomplete;

  loggingIn = true;
  socialUser: SocialUser | undefined;
  socialUserSubscription?: Subscription;

  loggedInUser: LoggedInUser = new LoggedInUser();
  firstName = '';
  lastName = '';
  email = '';
  unitPreference = UnitPreference.Imperial;
  timeZone = '';
  alias = '';
  dateOfBirth: string | undefined;
  sex: string | undefined;
  // in user preferred units
  weight: number | undefined;
  useAutomaticHeartRateLimits = true;
  restHeartRate = 0;
  maxHeartRate = 0;
  autoHeartRateLimits = new HeartRateLimits();
  profileImageMedia: Media | undefined;
  profileImageUrl = '';
  acceptCookies = false;
  acceptedCookiesCategories: string[] = [];
  acceptPrivacy = false;
  locations: UserLocation[] = [];
  // Privacy controls.
  privacyControls: UserPrivacyControls =
    LoginComponent.defaultPrivacyControls();
  // Notification controls.
  private savedNotificationControls =
    LoginComponent.defaultNotificationControls();
  notificationControls = LoginComponent.defaultNotificationControls();

  integrationPlatforms = [
    IntegrationPlatform.Fitbit,
    IntegrationPlatform.Garmin,
  ];
  integrationPlatform = IntegrationPlatform.Fitbit;

  constructor(
    private userService: UserService,
    private readFileService: ReadFileService,
    private mediaService: MediaService,
    private bannerService: BannerService,
    private mapRenderService: MapRendererService,
    private formatService: FormatService,
    private notificationService: NotificationsService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.margin = 7;
    this.width = formatService.viewWidth - 2 * this.margin;
    this.mapHeight = Math.round(0.6 * this.width);
    this.dateFormat = formatService.getDateDisplayFormat();

    // For sign-up, subscribe to first authentication event.
    this.socialUser = userService.socialUser;
    this.email = this.socialUser?.email ?? '';

    cookiePolicyAcceptObservable.subscribe((categories) =>
      this.onCookieAcceptedCategoriesChange(categories),
    );
  }

  ngOnInit(): void {
    if (!this.updateMode) {
      this.socialUserSubscription =
        this.userService.socialUserObservable.subscribe((user) =>
          this.onSocialUserChanged(user),
        );
    }

    // Update from logged-in user.
    this.userService.user.subscribe((user) => this.onLoggedInUserChanged(user));
  }

  private onSocialUserChanged(user?: SocialUser): void {
    this.socialUser = user;
    if (user) {
      if (!this.updateMode) {
        this.email = user.email;
        if (!this.userService.loggedIn) {
          // Attempt to log-in.
          this.userService.logIn(user).then((result) => {
            if (result) {
              this.router.navigate(['/home']);
            }
          });
        } else {
          this.router.navigate(['/home']);
        }
      }
    }
  }

  private onLoggedInUserChanged(user?: LoggedInUser) {
    if (!user || !user.email) {
      this.loggedInUser = new LoggedInUser();
      this.email = this.socialUser?.email ?? '';
      this.firstName = '';
      this.lastName = '';
      this.alias = '';
      this.unitPreference = UnitPreference.Imperial;
      this.timeZone = LoginComponent.DEFAULT_TIME_ZONE;
      this.dateOfBirth = undefined;
      this.sex = undefined;
      this.weight = undefined;
      this.useAutomaticHeartRateLimits = true;
      this.setAutoHeartRateLimits();
      this.profileImageMedia = undefined;
      this.acceptCookies = false;
      this.acceptedCookiesCategories = [];
      this.acceptPrivacy = false;
      this.locations = [];
      // Privacy controls.
      this.privacyControls = LoginComponent.defaultPrivacyControls();
      this.savedNotificationControls =
        LoginComponent.defaultNotificationControls();
      this.notificationControls = LoginComponent.defaultNotificationControls();
      this.loggingIn = true;
    } else {
      // Not in update mode - navigate away from login page.
      if (!this.updateMode) {
        this.router.navigate(['/home']);
        return;
      }
      this.loggedInUser = user;
      this.email = user.email!;
      this.firstName = user.details.firstName;
      this.lastName = user.details.lastName;
      this.alias = user.alias!;
      this.unitPreference = user.unitPreference!;
      this.timeZone =
        user.details.timeZone.length > 0
          ? user.details.timeZone
          : LoginComponent.DEFAULT_TIME_ZONE;
      this.dateOfBirth = undefined;
      if (user.details.dateOfBirth) {
        this.dateOfBirth = LoginComponent.formatDate(
          user.details.dateOfBirth.toDate(),
        );
      }
      this.sex = undefined;
      if (user.details.sex) {
        this.sex = FormatService.sexToDisplay(user.details.sex);
      }
      this.weight = undefined;
      if (user.details.weightInKilograms != undefined) {
        if (this.unitPreference == UnitPreference.Metric) {
          this.weight = user.details.weightInKilograms;
        } else {
          this.weight = FormatService.convertWeightToPounds(
            user.details.weightInKilograms,
          );
        }
      }
      this.useAutomaticHeartRateLimits =
        !user.details.heartRateLimits ||
        !user.details.heartRateLimits.manualOverride?.maxHeartRate;
      this.autoHeartRateLimits = new HeartRateLimits(
        user.details.heartRateLimits!.automatic,
      );
      if (this.useAutomaticHeartRateLimits) {
        this.restHeartRate =
          user.details.heartRateLimits!.automatic!.restHeartRate;
        this.maxHeartRate =
          user.details.heartRateLimits!.automatic!.maxHeartRate;
      } else {
        this.restHeartRate =
          user.details.heartRateLimits!.manualOverride!.restHeartRate;
        this.maxHeartRate =
          user.details.heartRateLimits!.manualOverride!.maxHeartRate;
      }
      this.profileImageMedia = user.details.photoMedia;
      if (this.profileImageMedia) {
        this.profileImageUrl = MediaService.getImageSrc(
          this.profileImageMedia.smallSizeId,
        );
      }
      this.acceptCookies =
        user.details.consentedCookiesVersion ==
        PolicyVersions.cookiesPolicyLastUpdateDate;
      this.acceptedCookiesCategories = user.details.acceptedCookiesCategories;
      this.acceptPrivacy =
        user.details.consentedPrivacyVersion ==
        PolicyVersions.privacyPolicyLastUpdateDate;
      this.locations = user.details.locations ?? [];
      // Privacy controls.
      this.privacyControls = new UserPrivacyControls(
        user.details.privacyControls,
      );
      // Notification controls.
      this.notificationService.getControls().then((r) => {
        this.savedNotificationControls = new NotificationControls(r.controls!);
        this.notificationControls = r.controls!;
      });
      this.loggingIn = false;
    }
    if (!this.acceptCookies) {
      showCookieBanner();
    }
  }

  private setAutoHeartRateLimits() {
    let dateOfBirth: Timestamp | undefined;
    if (this.dateOfBirth) {
      dateOfBirth = Timestamp.fromISOString(this.dateOfBirth);
    }
    this.userService.getDefaultHeartRateLimits(dateOfBirth).then((r) => {
      this.autoHeartRateLimits = new HeartRateLimits(r.heartRateLimits);
      if (this.useAutomaticHeartRateLimits) {
        this.restHeartRate = r.heartRateLimits!.restHeartRate;
        this.maxHeartRate = r.heartRateLimits!.maxHeartRate;
      }
    });
  }

  private static defaultPrivacyControls(): UserPrivacyControls {
    return new UserPrivacyControls({
      locationAcl: new UserPrivateDataAccessList({
        accessLevel: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_PUBLIC,
      }),
      healthMetricsAcl: new UserPrivateDataAccessList({
        accessLevel: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_PRIVATE,
      }),
      calendarAcl: new UserPrivateDataAccessList({
        accessLevel: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_FOLLOWERS,
      }),
      activitiesAcl: new UserPrivateDataAccessList({
        accessLevel: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_FOLLOWERS,
      }),
    });
  }

  private static defaultNotificationControls(): NotificationControls {
    return new NotificationControls({
      activities: { disabled: false, webEnabled: true },
      newFollowerRequests: { disabled: false, webEnabled: true },
      newFollowing: { disabled: false, webEnabled: true },
      newReaction: { disabled: false, webEnabled: true },
    });
  }

  ngOnDestroy(): void {
    if (this.socialUserSubscription) {
      this.socialUserSubscription.unsubscribe();
    }
    this.cleanUpLocationsMap();
  }

  get weightLabel(): string {
    return this.unitPreference == UnitPreference.Imperial
      ? 'Weight, lbs'
      : 'Weight, kg';
  }

  private initUnitPreferenceValues(): Array<string> {
    const result: Array<string> = [];
    for (const unit in UnitPreference) {
      result.push(unit);
    }
    return result;
  }

  onDateOfBirthChanged(): void {
    this.setAutoHeartRateLimits();
  }

  onUseAutomaticHeartRateLimitsChanged(): void {
    this.setAutoHeartRateLimits();
  }

  fieldsChanged(): boolean {
    if (!this.firstName || !this.lastName || !this.alias) {
      return false;
    }

    // Must accept/acknowledge policies.
    if (!this.acceptCookies || !this.acceptPrivacy) {
      return false;
    }

    // Validate alias regex, let existing users have theirs.
    if (
      this.alias != this.loggedInUser.alias &&
      !this.alias.match('^[a-z,0-9]{2,30}$')
    ) {
      return false;
    }

    return (
      this.firstName != this.loggedInUser.details.firstName ||
      this.lastName != this.loggedInUser.details.lastName ||
      this.alias != this.loggedInUser.alias ||
      this.unitPreference != this.loggedInUser.unitPreference ||
      this.timeZone != this.loggedInUser.details.timeZone ||
      this.dateOfBirth !=
        LoginComponent.formatDate(
          this.loggedInUser.details.dateOfBirth?.toDate(),
        ) ||
      this.sex != FormatService.sexToDisplay(this.loggedInUser.details.sex) ||
      this.getWeightInKilograms()?.toFixed(1) !=
        this.loggedInUser.details.weightInKilograms?.toFixed(1) ||
      !this.compareInJson(
        this.buildHeartRateLimits(),
        this.loggedInUser.details.heartRateLimits!,
      ) ||
      this.profileImageMedia?.smallSizeId !=
        this.loggedInUser.details.photoMedia?.smallSizeId ||
      PolicyVersions.cookiesPolicyLastUpdateDate !=
        this.loggedInUser.details.consentedCookiesVersion ||
      !this.compareInJson(
        this.acceptedCookiesCategories,
        this.loggedInUser.details.acceptedCookiesCategories,
      ) ||
      PolicyVersions.privacyPolicyLastUpdateDate !=
        this.loggedInUser.details.consentedPrivacyVersion ||
      !this.compareInJson(
        this.locations,
        this.loggedInUser.details.locations ?? [],
      ) ||
      !this.compareInJson(
        this.privacyControls,
        this.loggedInUser.details.privacyControls!,
      ) ||
      !this.compareInJson(
        this.notificationControls,
        this.savedNotificationControls,
      )
    );
  }

  private buildHeartRateLimits(): UserHeartRateLimits {
    const userHeartRateLimits = new UserHeartRateLimits(
      this.loggedInUser.details.heartRateLimits,
    );
    userHeartRateLimits.automatic = this.autoHeartRateLimits;
    if (!this.useAutomaticHeartRateLimits) {
      userHeartRateLimits.manualOverride = new HeartRateLimits({
        restHeartRate: this.restHeartRate,
        maxHeartRate: this.maxHeartRate,
      });
    } else {
      userHeartRateLimits.manualOverride = undefined;
    }
    return userHeartRateLimits;
  }

  private compareInJson(v1: object, v2: object): boolean {
    return JSON.stringify(v1) == JSON.stringify(v2);
  }

  onUnitPreferenceChanged() {
    if (this.weight) {
      if (this.unitPreference == UnitPreference.Imperial) {
        this.weight = FormatService.convertWeightToPounds(this.weight);
      } else {
        this.weight = FormatService.convertWeightToKilograms(this.weight);
      }
    }
  }

  onAccordionItemKeyDown(item: CdkAccordionItem) {
    item.toggle();
  }

  onLocationsExpanded(expanded: boolean) {
    if (expanded) {
      // Get coarse user location and show the map.
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.mapRenderService
            .renderMap(this.locationMap.nativeElement, {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            .then((map) => this.initLocationsMap(map, true));
        },
        (error) => {
          console.error('getCurrentPosition error', error);
          this.mapRenderService
            .renderMap(this.locationMap.nativeElement)
            .then((map) => this.initLocationsMap(map, false));
        },
        { enableHighAccuracy: false },
      );
    } else {
      this.cleanUpLocationsMap();
    }
  }

  private initLocationsMap(
    map: google.maps.Map,
    withUserLocation: boolean,
  ): void {
    this.mapRenderService.setOptions({
      mapTypeControl: false,
    });
    this.autocomplete = new google.maps.places.Autocomplete(
      this.locationFindInput.nativeElement,
      {
        strictBounds: false,
        types: ['(regions)'],
      },
    );
    if (withUserLocation) {
      this.autocomplete.bindTo('bounds', map);
    }
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete!.getPlace();
      if (this.locations.length < LoginComponent.MAX_LOCATIONS) {
        const newLocations = Array.from(this.locations);
        const location = place.geometry!.location;
        const viewport = place.geometry!.viewport;
        newLocations.push(
          new UserLocation({
            placeName: place.formatted_address,
            placeId: place.place_id,
            center: {
              latitude: location!.lat(),
              longitude: location!.lng(),
            },
            viewport: {
              southWest: {
                latitude: viewport!.getSouthWest().lat(),
                longitude: viewport!.getSouthWest().lng(),
              },
              northEast: {
                latitude: viewport!.getNorthEast().lat(),
                longitude: viewport!.getNorthEast().lng(),
              },
            },
          }),
        );
        this.locations = newLocations;
      }
    });
  }

  private cleanUpLocationsMap(): void {
    if (this.autocomplete) {
      this.autocomplete.unbindAll();
      this.autocomplete = undefined;
    }
    this.mapRenderService.setOptions(null);
    const autoCompleteListDivs =
      document.getElementsByClassName('pac-container');
    for (let i = 0; i < autoCompleteListDivs.length; i++) {
      autoCompleteListDivs[i].remove();
    }
  }

  onLocationClick(location: UserLocation) {
    this.mapRenderService.centerMap({
      lat: location.center!.latitude,
      lng: location.center!.longitude,
    });
  }

  onLocationDelete(index: number) {
    const newLocations = Array.from(this.locations);
    newLocations.splice(index, 1);
    this.locations = newLocations;
  }

  /** Google Maps autocomplete list has absolute position,
   * attach the list to the card and override its style.
   * Otherwise the list doesn't show up by the input when the view is scrolled. */
  onLocationFindInputFocus() {
    const autoCompleteListDivs =
      document.getElementsByClassName('pac-container');
    if (autoCompleteListDivs.length == 1) {
      const autoCompleteListDiv = autoCompleteListDivs[0];
      this.locationAutocompleteList.nativeElement.appendChild(
        autoCompleteListDiv,
      );
    }
  }

  onLocationFindInputClick() {
    // Clear input value on clik.
    this.locationFindInput.nativeElement.value = '';
  }

  updateProfileImage(e: Event) {
    const target = e.target as HTMLInputElement;
    const file: File = target.files![0];
    if (file.size > MediaService.MAX_FILE_SIZE) {
      const fileMbs = file.size >> 20;
      const maxMbs = MediaService.MAX_FILE_SIZE >> 20;
      this.bannerService.add(
        new BannerMessage(
          `File ${file.name} is too large: ${fileMbs}MB > ${maxMbs}MB`,
        ),
      );
    } else {
      // Show the image from blob.
      this.profileImageUrl = URL.createObjectURL(file);

      // Upload media.
      this.readFileService
        .readFile(file)
        .then((data) => {
          return this.mediaService.uploadMedia(new Uint8Array(data));
        })
        .catch()
        .then((response) => {
          this.profileImageMedia = new Media({
            smallSizeId: response.smallSizeId,
            fullSizeId: response.fullSizeId,
            contentType: response.contentType,
          });
        });
    }

    // Reset the value.
    this.profileImageInput.nativeElement.value = '';
  }

  formatLocationCoordinates(location: UserLocation): string {
    return (
      this.formatService.formatNumber(location.center!.latitude) +
      ' ' +
      this.formatService.formatNumber(location.center!.longitude)
    );
  }

  private onCookieAcceptedCategoriesChange(
    acceptedCategories: readonly string[],
  ): void {
    this.acceptedCookiesCategories = [...acceptedCategories];

    if (this.updateMode) {
      if (
        PolicyVersions.cookiesPolicyLastUpdateDate !=
          this.loggedInUser.details.consentedCookiesVersion ||
        !this.compareInJson(
          this.acceptedCookiesCategories,
          this.loggedInUser.details.acceptedCookiesCategories,
        )
      ) {
        this.userService
          .updateCookiesConsent(this.acceptedCookiesCategories)
          .then((r) => {
            this.acceptCookies = r;
          });
      }
    } else {
      this.acceptCookies = true;
    }
  }

  save() {
    // The date of birth is stored in UTC.
    const dateOfBirth = this.dateOfBirth
      ? Timestamp.fromISOString(this.dateOfBirth)
      : undefined;
    const userHeartRateLimits = this.buildHeartRateLimits();
    if (this.updateMode) {
      this.userService.update(
        this.firstName,
        this.lastName,
        this.unitPreference,
        this.timeZone,
        dateOfBirth,
        this.sex ? FormatService.sexToApi(this.sex) : undefined,
        this.getWeightInKilograms(),
        userHeartRateLimits,
        this.profileImageMedia,
        this.acceptedCookiesCategories,
        PolicyVersions.privacyPolicyLastUpdateDate,
        this.locations,
        this.privacyControls,
      );

      const newNotificationControls = new NotificationControls(
        this.notificationControls,
      );
      this.notificationService
        .updateControls(newNotificationControls)
        .then(() => (this.savedNotificationControls = newNotificationControls));
    } else {
      this.userService.signUp(
        this.socialUser!,
        this.firstName,
        this.lastName,
        this.alias,
        this.unitPreference,
        this.timeZone,
        dateOfBirth,
        this.sex ? FormatService.sexToApi(this.sex) : undefined,
        this.getWeightInKilograms(),
        userHeartRateLimits,
        this.acceptedCookiesCategories,
        PolicyVersions.privacyPolicyLastUpdateDate,
        this.locations,
        this.privacyControls,
      );
    }
  }

  delete(): void {
    ConfirmationComponent.openDialog(
      this.dialog,
      'Confirm DELETE of all user data?',
      (result) => {
        if (result) {
          this.userService.signOut();
        }
      },
    );
  }

  private getWeightInKilograms(): number | undefined {
    let weightInKilograms: number | undefined;
    if (this.weight) {
      if (this.unitPreference == UnitPreference.Imperial) {
        weightInKilograms = FormatService.convertWeightToKilograms(this.weight);
      } else {
        weightInKilograms = this.weight;
      }
    }
    return weightInKilograms;
  }

  private static formatDate(date: Date | undefined): string | undefined {
    let s: string | undefined;
    if (date) {
      s = date.toISOString();
      s = s.substring(0, s.indexOf('T'));
    }
    return s;
  }
}
