<div *ngIf="item">
  <div class="item"
    [ngStyle]="{ 'padding-left': (item.level * 8) + 'px', 'display': isVisible ? 'block' : 'none', 'background-color': isActive ? 'lightgray' : 'white', 'font-size': item.level === 0 ? 'larger' : 'medium'}"
    tabIndex="0" role="none" (click)="onClick()" (keydown)="onKeyDown($event)">
    {{ item.title }}
  </div>
  <div *ngFor=" let child of item.items">
    <app-docs-toc [item]="child" [activePath]="activePath"></app-docs-toc>
  </div>
</div>
