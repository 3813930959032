import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Activity } from 'generated/src/main/proto/api/activity-service.pb';
import { Track } from 'generated/src/main/proto/shared/activity-shared.pb';
import { AppConfigProvider } from 'src/environments/app-config';
import { DeviceDetectorService } from '../device-detector.service';

export type LatLng = {
  lat: number;
  lng: number;
};

/** Wrapper for Google Maps APIs. */
@Injectable({
  providedIn: 'root',
})
export class MapRendererService {
  private readonly defaultMapOptions: google.maps.MapOptions;
  private loader: Loader = new Loader({
    apiKey: AppConfigProvider.config!.mapsApiKey,
    version: 'weekly',
    libraries: ['maps', 'places'],
  });
  private mapElement: HTMLDivElement;
  private map?: google.maps.Map;

  constructor(deviceDetectorService: DeviceDetectorService) {
    this.mapElement = document.createElement('div');
    this.mapElement.setAttribute('style', 'width: 100%; height: 100%');

    this.defaultMapOptions = {
      streetViewControl: false,
      fullscreenControl: false,
      controlSize: deviceDetectorService.isMobile() ? 20 : 30,
    };
  }

  async renderActivity(
    parentElement: HTMLElement,
    activity: Activity,
  ): Promise<google.maps.Map> {
    // Remove any previous data.
    if (this.map) {
      this.clearMap();
    }

    // Add activity data.
    return this.loadMap().then((map) => {
      this.addActivityData(parentElement, activity);
      map.setOptions(this.defaultMapOptions);
      return map;
    });
  }

  async renderMap(parentElement: HTMLElement, center?: LatLng, zoomLevel = 5) {
    // Remove any previous data.
    if (this.map) {
      this.clearMap();
    }

    return this.loadMap().then((map) => {
      parentElement.appendChild(this.mapElement);
      if (center) {
        map.setCenter(
          new google.maps.LatLng({
            lat: center.lat,
            lng: center.lng,
          }),
        );
        map.setZoom(zoomLevel);
      }
      map.setOptions(this.defaultMapOptions);
      return map;
    });
  }

  centerMap(center: LatLng, zoomLevel = 7): void {
    this.map!.setCenter(
      new google.maps.LatLng({
        lat: center.lat,
        lng: center.lng,
      }),
    );
    this.map!.setZoom(zoomLevel);
  }

  setOptions(options: google.maps.MapOptions | null): void {
    if (this.map) {
      if (options) {
        options.streetViewControl = this.defaultMapOptions.streetViewControl;
        options.fullscreenControl = this.defaultMapOptions.fullscreenControl;
        options.controlSize = this.defaultMapOptions.controlSize;
      } else {
        options = this.defaultMapOptions;
      }
      this.map.setOptions(options);
    }
  }

  private async loadMap(): Promise<google.maps.Map> {
    if (this.map) {
      return this.map;
    }
    return this.loader.importLibrary('maps').then(() => {
      this.map = new google.maps.Map(this.mapElement);
      return this.map;
    });
  }

  private addActivityData(
    parentElement: HTMLElement,
    activity: Activity,
  ): void {
    if (this.map) {
      parentElement.appendChild(this.mapElement);

      this.map.data.setStyle({
        strokeColor: 'blue',
        strokeWeight: 5,
        strokeOpacity: 0.7,
      });
      const bounds = MapRendererService.getTrackBounds(activity.track!);
      this.map.fitBounds(bounds);
      this.map.data.add({
        geometry: MapRendererService.getTrackPath(activity.track!),
      });
    }
  }

  private clearMap(): void {
    if (this.map) {
      const features: google.maps.Data.Feature[] = [];
      this.map.data.forEach((feature) => features.push(feature));
      features.forEach((f) => this.map!.data.remove(f));

      // Set default options.
      this.map.setOptions(this.defaultMapOptions);
    }

    if (this.mapElement.parentElement) {
      this.mapElement.parentElement.removeChild(this.mapElement);
    }
  }

  private static getTrackPath(track: Track): google.maps.Data.LineString {
    const coordinates = track.points!.map((p) => {
      return { lat: p.latLongAlt!.latitude, lng: p.latLongAlt!.longitude };
    });
    return new google.maps.Data.LineString(coordinates);
  }

  private static getTrackBounds(track: Track): google.maps.LatLngBounds {
    // Ignore possible crossing of 180 line.
    let minLat = 90.0;
    let maxLat = -90.0;
    let minLng = 180.0;
    let maxLng = -180.0;
    track.points!.forEach((p) => {
      const lat = p.latLongAlt!.latitude;
      const lng = p.latLongAlt!.longitude;
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    });
    return new google.maps.LatLngBounds(
      { lat: minLat, lng: minLng },
      { lat: maxLat, lng: maxLng },
    );
  }
}
