<button
  mat-icon-button
  class="report-button"
  matTooltip="Report an issue"
  (click)="onReportProblemClick()"
>
  <mat-icon class="report-button-icon material-symbols-outlined"
    >bug_report</mat-icon
  >
</button>
