import { Injectable } from '@angular/core';
import { NgxCaptureService } from 'ngx-capture';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from './banner/banner.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private static readonly MAX_IMAGE_SIZE = 1000;

  constructor(
    private captureService: NgxCaptureService,
    private bannerService: BannerService,
  ) {}

  /** Takes a screenshot of the app. */
  async getScreenshot(): Promise<ArrayBuffer> {
    return lastValueFrom(
      this.captureService.getImage(document.body, true).pipe(
        catchError((e) => {
          this.bannerService.add(new BannerMessage(e.statusMessage));
          return throwError(() => e);
        }),
      ),
    )
      .then((imageString) => {
        const image = new Image();
        image.src = imageString;
        return new Promise<HTMLImageElement>((resolve, reject) => {
          image.onload = () => resolve(image);
          image.onerror = reject;
        });
      })
      .then((image) => {
        const width = image.width;
        const height = image.height;

        // Resize image to a maximum of 1000px on either side.
        let newWidth = width;
        let newHeight = height;
        if (Math.max(width, height) > ImageService.MAX_IMAGE_SIZE) {
          if (height > width) {
            newHeight = ImageService.MAX_IMAGE_SIZE;
            newWidth = Math.max(1, Math.round((width * newHeight) / height));
          } else {
            newWidth = ImageService.MAX_IMAGE_SIZE;
            newHeight = Math.max(1, Math.round((height * newWidth) / width));
          }
        }
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const context2d = canvas.getContext('2d');
        context2d!.drawImage(image, 0, 0, newWidth, newHeight);
        return new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'jpeg'),
        );
      })
      .then((blob) => blob!.arrayBuffer());
  }
}
