import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import {
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  afterRender,
} from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';

// Result of item loading, data is passed to item template.
// If data is undefined and error is not set for item
// after last, it marks the end of the collection.
export type ItemLoadResult = {
  // Error message.
  error: string | undefined;

  // Item data passed to item template for rendering.
  // Undefined if item does not exist or there was an error.
  data: object | undefined;
};

export type LoadResult = {
  // If set the component will clear caches and starts loading new content.
  resetView: boolean | undefined;

  // Loaded items.
  items: Map<number, ItemLoadResult>;
};

// Provides items keyed by their index.
export interface ScrollDataSource {
  // Registers observable for data ranges and returns observable for data.
  connect(collectionViewer: CollectionViewer): Observable<LoadResult>;

  // Removes collection viewer from the data source.
  disconnect(collectionViewer: CollectionViewer): void;
}

// Allows for saving and restoring of scroll position.
export interface Scrollable {
  scrollTo(position: number): void;

  scrollTop(): number;
}

// Context set on individual items.
declare type ItemContext = {
  data: object | undefined;
};

// Context for padding divs before and after the items.
class PaddingDivContext {
  constructor(
    private component: ScrollComponent,
    private top: boolean,
  ) {}

  // Height of the div element with 'px' suffix.
  get height(): string {
    return this.top
      ? this.component.topPaddingPixels
      : this.component.bottomPaddingPixels;
  }
}

/** View state of an item. */
class ItemViewState {
  top = 0;
  bottom = 0;

  constructor(
    element: HTMLElement,
    parentTop: number,
    previousBottom: number | undefined,
  ) {
    this.updateState(element, parentTop, previousBottom);
  }

  updateState(
    element: HTMLElement,
    parentTop: number,
    previousBottom: number | undefined,
  ): void {
    const rect = element.getBoundingClientRect();
    this.top = rect.top - parentTop;
    this.bottom = rect.bottom - parentTop;
    // Make sure margin is included to avoid gaps between items.
    // For the first item, the top matches the actual margin as top padding div
    // doesn't have any margin. For the rest of the items, the top is above
    // the actual top as items have top and bottom margins.
    if (previousBottom != undefined) {
      this.top = previousBottom;
    }
  }
}

// Describes state of items in view.
class ViewState {
  // First item's index.
  private startItemIndex = 0;
  // Scroll top in pixels.
  private scrollTop = 0;
  // State of the items in view.
  private readonly itemStates: ItemViewState[] = [];
  // Cached item tops for adjusting top padding when scrolling back.
  private readonly cachedTops = new Map<number, number>();

  /** Updates the state. */
  update(
    startItemIndex: number,
    scrollTop: number,
    itemContainer: ViewContainerRef,
  ): void {
    this.startItemIndex = startItemIndex;
    this.scrollTop = scrollTop;
    this.updateItemStates(itemContainer);
  }

  /** Resets the state. */
  reset() {
    this.startItemIndex = 0;
    this.scrollTop = 0;
    this.cachedTops.clear();
    this.itemStates.splice(0, this.itemStates.length);
  }

  findNewStartEndIndices(
    newClientHeight: number,
    newScrollTop: number,
    minItemHeight: number,
  ): { startIndex: number; endIndex: number } {
    // New scroll position relative to parent element top.
    const relativeScrollTop = newScrollTop - this.scrollTop;
    let startIndex = 0;
    let endIndex = Math.floor(
      (newClientHeight + minItemHeight - 1) / minItemHeight,
    );

    // Empty state?
    if (this.itemStates.length == 0) {
      return { startIndex: startIndex, endIndex: endIndex };
    }

    const topViewState = this.itemStates[0];
    const bottomViewState = this.itemStates[this.itemStates.length - 1];

    // New scroll position is inside the top padding.
    let startItemTop: number;
    if (relativeScrollTop < topViewState.bottom) {
      const itemOffset = Math.floor(
        (topViewState.bottom - relativeScrollTop + minItemHeight - 1) /
          minItemHeight,
      );
      startIndex = this.startItemIndex - itemOffset;
      startItemTop = topViewState.bottom - itemOffset * minItemHeight;
    }
    // New scroll position is inside the bottom padding.
    else if (relativeScrollTop > bottomViewState.top) {
      const itemOffset = Math.floor(
        (relativeScrollTop - bottomViewState.top) / minItemHeight,
      );
      startIndex =
        this.startItemIndex + this.itemStates.length - 2 + itemOffset;
      startItemTop = bottomViewState.top + itemOffset * minItemHeight;
    }
    // New scroll position is inside the visible items.
    else {
      // Find what item contains the new position.
      startIndex = this.startItemIndex; // this should not be needed, safety net
      startItemTop = 0;
      for (let index = 1; index + 1 < this.itemStates.length; index++) {
        const itemState = this.itemStates[index];
        if (
          itemState.top <= relativeScrollTop &&
          relativeScrollTop < itemState.bottom
        ) {
          startIndex = this.startItemIndex + index - 1;
          startItemTop = itemState.top;
          break;
        }
      }
    }
    if (startIndex < 0) {
      startIndex = 0;
    }

    // Find end index by assuming minimum height before and after the visible items.
    let y = startItemTop;
    const bottom = relativeScrollTop + newClientHeight;
    endIndex = startIndex;
    do {
      if (
        this.startItemIndex <= endIndex &&
        endIndex - this.startItemIndex + 2 < this.itemStates.length
      ) {
        const item = this.itemStates[endIndex - this.startItemIndex + 1];
        y += Math.max(minItemHeight, item.bottom - item.top);
      } else {
        y += minItemHeight;
      }
      endIndex++;
    } while (y < bottom);

    return { startIndex: startIndex, endIndex: endIndex };
  }

  computeTopPadding(newStartIndex: number, minItemHeight: number): number {
    if (this.itemStates.length == 0) {
      // No items are yet loaded, use estimate of the padding height.
      return minItemHeight * newStartIndex;
    }
    const topViewState = this.itemStates[0];
    if (this.startItemIndex == newStartIndex) {
      // Start item has not changed (most common for scrolling):
      //   use current padding's height.
      return topViewState.bottom - topViewState.top;
    }
    const itemCount = this.itemStates.length - 2;
    let topPadding: number;
    if (newStartIndex > this.startItemIndex) {
      // New start index is after current start index:
      //   1. If new start is within loaded range, then use the top
      //      of the item before new start.
      //   2. If new start is after loaded range, then use last item's bottom plus estimate
      //      of the height of items after that.
      const relativeItemIndex = newStartIndex - 1 - this.startItemIndex;
      if (relativeItemIndex >= itemCount) {
        const lastItem = this.itemStates[itemCount];
        topPadding =
          lastItem.bottom -
          topViewState.top +
          (relativeItemIndex - itemCount + 1) * minItemHeight;
      } else {
        const newStartItem = this.itemStates[relativeItemIndex + 1];
        topPadding = newStartItem.bottom - topViewState.top;
      }
    } else {
      const newStartTop = this.cachedTops.get(newStartIndex);
      if (newStartTop != undefined) {
        // The new start index was seen before, use the cached value.
        topPadding = newStartTop;
      } else {
        // New start is before the loaded range, use an estimate that does not exceed
        // current padding height.
        topPadding = Math.max(
          0,
          Math.min(
            newStartIndex * minItemHeight,
            topViewState.bottom - topViewState.top - minItemHeight,
          ),
        );
      }
    }
    return topPadding;
  }

  private updateItemStates(itemContainer: ViewContainerRef) {
    if (itemContainer.length < 2) {
      throw new Error('Views length must be 2 or greater');
    }

    // Item container anchor element is a comment after all the items.
    // Get item elements (including padding ones) before it.
    let siblings: HTMLElement[] = [];
    let sibling = itemContainer.element.nativeElement.previousElementSibling;
    while (sibling) {
      siblings.push(sibling);
      sibling = sibling.previousElementSibling;
    }
    siblings = siblings.reverse();

    // Update state of the items.
    const parentRect =
      itemContainer.element.nativeElement.parentElement.getBoundingClientRect();
    let previousBottom: number | undefined;
    for (let index = 0; index < siblings.length; index++) {
      const itemElement = siblings[index];
      if (index < this.itemStates.length) {
        this.itemStates[index].updateState(
          itemElement,
          parentRect.top,
          previousBottom,
        );
      } else {
        this.itemStates.push(
          new ItemViewState(itemElement, parentRect.top, previousBottom),
        );
      }
      const item = this.itemStates[index];
      previousBottom = item.bottom;
      if (1 <= index && index + 1 < siblings.length) {
        // Cache top of the items relative to the top of the scroll.
        // The map can grow unbounded, it is unlikely to grow to millions
        // w/o view reload that triggers reset of the map.
        this.cachedTops.set(
          this.startItemIndex + index - 1,
          item.top - this.itemStates[0].top,
        );
      }
    }
    if (itemContainer.length < this.itemStates.length) {
      this.itemStates.splice(
        itemContainer.length,
        this.itemStates.length - itemContainer.length,
      );
    }
  }
}

// Implements infinite vertical scroll.
// Angular virtual scroll requires to know size of the collection
// and doesn't work correctly with gallery items.
@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.scss'],
})
export class ScrollComponent
  implements OnInit, OnDestroy, CollectionViewer, Scrollable
{
  // Item height in pixels.
  @Input()
  itemHeight!: number;

  // Item template.
  @Input() itemTemplate!: TemplateRef<ItemContext>;

  // Data source to load data.
  dataSourceInternal?: ScrollDataSource;
  private startedLoading = false;
  private initialized = false;

  private viewChangeSource = new BehaviorSubject<ListRange>({
    start: 0,
    end: 0,
  });
  viewChange: Observable<ListRange> = this.viewChangeSource.asObservable();

  @Output()
  dataInViewChange = new EventEmitter<readonly object[]>();

  // Number of items to preload ahead of scrolling.
  @Input()
  preloadItems = 3;

  // Number of view items to keep in cache on each side of the [start - preload, end + preload].
  @Input()
  cachedItems = 5;

  // Scrollable view.
  @ViewChild('viewport')
  viewport!: ElementRef;

  // Container with visible items.
  @ViewChild('itemContainer', { read: ViewContainerRef })
  itemContainer!: ViewContainerRef;

  // Div element height before the visible contents.
  topPaddingPixels = '0px';

  // Div element height after the visible contents.
  bottomPaddingPixels = '0px';

  // Item views.
  private itemViewsLength = 0;
  private totalItems: number | undefined = undefined;
  private readonly itemViewsCache = new Map<
    number,
    EmbeddedViewRef<ItemContext>
  >();
  private lastStartIndex = 0;
  private stateNeedsUpdate = true;
  private readonly viewState = new ViewState();

  // Padding div views.
  @ViewChild('divPadding', { read: TemplateRef })
  divPaddingTemplate!: TemplateRef<PaddingDivContext>;
  private topPaddingView!: EmbeddedViewRef<PaddingDivContext>;
  private bottomPaddingView!: EmbeddedViewRef<PaddingDivContext>;

  constructor() {
    afterRender(() => this.onAfterRender());
  }

  ngOnInit(): void {
    this.initialized = true;
    timer(1).subscribe(() => this.startLoading());
  }

  ngOnDestroy(): void {
    if (this.dataSourceInternal) {
      // Disconnect from the data source.
      this.dataSourceInternal.disconnect(this);
    }
  }

  @Input() set dataSource(dataSource: ScrollDataSource) {
    this.dataSourceInternal = dataSource;
    this.startLoading();
  }

  scrollTo(position: number): void {
    this.viewport.nativeElement.scrollTop = position;
  }

  scrollTop(): number {
    return this.viewport.nativeElement.scrollTop;
  }

  onScroll() {
    this.updateView();
  }

  private getPaddingView(top: boolean): EmbeddedViewRef<PaddingDivContext> {
    if (top) {
      if (!this.topPaddingView || this.topPaddingView.destroyed) {
        this.topPaddingView = this.divPaddingTemplate.createEmbeddedView(
          new PaddingDivContext(this, true),
        );
      }
    } else {
      if (!this.bottomPaddingView || this.bottomPaddingView.destroyed) {
        this.bottomPaddingView = this.divPaddingTemplate.createEmbeddedView(
          new PaddingDivContext(this, false),
        );
      }
    }
    return top ? this.topPaddingView : this.bottomPaddingView;
  }

  private startLoading() {
    if (!this.dataSourceInternal || !this.initialized || this.startedLoading) {
      return;
    }

    // Create padding views.
    this.topPaddingView = this.getPaddingView(true);
    this.bottomPaddingView = this.getPaddingView(false);

    // Connect to the data source.
    this.dataSourceInternal.connect(this).subscribe((m) => this.itemsLoaded(m));

    // Ask for initial data.
    const elementsNeeded =
      Math.floor(
        (this.viewport.nativeElement.clientHeight + this.itemHeight - 1) /
          this.itemHeight,
      ) + this.preloadItems;

    this.viewChangeSource.next({ start: 0, end: elementsNeeded });
    this.startedLoading = true;
  }

  private itemsLoaded(loadResult: LoadResult): void {
    // Reset view?
    if (loadResult.resetView) {
      this.itemViewsLength = 0;
      this.totalItems = undefined;
      this.itemViewsCache.clear();
      while (this.itemContainer.length > 0) {
        this.itemContainer.detach(this.itemContainer.length - 1);
      }
      this.viewState.reset();
    } else {
      const newItems = loadResult.items;
      const indices: number[] = [];
      newItems.forEach((_, index) => indices.push(index));

      newItems.forEach((result, index) => {
        if (index >= this.itemViewsLength && result.data != undefined) {
          this.itemViewsLength = index + 1;
        }

        // Set total number of items not to query the data source beyond it.
        if (
          this.itemViewsLength + 1 == index &&
          result.data == undefined &&
          !result.error
        ) {
          this.totalItems = this.itemViewsLength;
        }

        if (!result.error && index < this.itemViewsLength) {
          if (result.data != undefined) {
            const view = this.itemTemplate.createEmbeddedView({
              data: result.data,
            });

            this.itemViewsCache.set(index, view);
          } else {
            // Invalidate view.
            this.itemViewsCache.delete(index);
          }
        }
      });
    }
    this.updateView();
  }

  private updateView(): void {
    const scrollTop = this.viewport.nativeElement.scrollTop;
    const viewHeight = this.viewport.nativeElement.clientHeight;

    // Compute item start and end indices including a number of items
    // before and after the immediate view.
    let { startIndex, endIndex } = this.viewState.findNewStartEndIndices(
      viewHeight,
      scrollTop,
      this.itemHeight,
    );
    startIndex -= this.preloadItems;
    if (startIndex < 0) {
      startIndex = 0;
    }
    endIndex += this.preloadItems;
    if (endIndex > this.itemViewsLength) {
      endIndex = this.itemViewsLength;
    }
    this.lastStartIndex = startIndex;

    // Set sizes of the padding div elements. The top padding needs to be
    // equal to the size of the items removed from DOM as they get removed for
    // the scroll not to jump. The bottom needs to be non zero if there are items
    // after the immediate view.
    const topPadding = this.viewState.computeTopPadding(
      startIndex,
      this.itemHeight,
    );
    this.topPaddingPixels = topPadding + 'px';
    const bottomPadding = (this.itemViewsLength - endIndex) * this.itemHeight;
    this.bottomPaddingPixels = bottomPadding + 'px';

    // Collect view items.
    const viewsToInsert: EmbeddedViewRef<ItemContext | PaddingDivContext>[] = [
      this.getPaddingView(true),
    ];
    let minIndexToLoad = endIndex;
    let maxIndexToLoad = startIndex - 1;
    for (let index = startIndex; index < endIndex; index++) {
      let view = this.itemViewsCache.get(index);
      if (view == undefined) {
        view = this.itemTemplate.createEmbeddedView({
          // Undefined data indicates to the template that a "loading" placeholder
          // needs to be rendered.
          data: undefined,
        });
        if (minIndexToLoad > index) {
          minIndexToLoad = index;
        }
        if (maxIndexToLoad < index) {
          maxIndexToLoad = index;
        }
      }
      viewsToInsert.push(view!);
    }
    viewsToInsert.push(this.getPaddingView(false));

    this.updateViewItems(viewsToInsert);

    // Evict item views not in view from cache.
    this.evictViews(startIndex, endIndex);

    // Not all items in view are loaded, trigger loading them.
    if (minIndexToLoad <= maxIndexToLoad) {
      this.viewChangeSource.next({
        start: minIndexToLoad,
        end: maxIndexToLoad + 1,
      });
    }

    // Preload.
    if (this.itemViewsLength - endIndex < this.preloadItems) {
      let nextEndIndex = endIndex + this.preloadItems;
      if (this.totalItems != undefined && this.totalItems < nextEndIndex) {
        nextEndIndex = this.totalItems;
      }

      if (this.itemViewsLength < nextEndIndex) {
        this.viewChangeSource.next({
          start: this.itemViewsLength,
          end: nextEndIndex,
        });
      }
    }

    // Trigger state update after the current view is rendered.
    this.stateNeedsUpdate = true;
  }

  private updateViewItems(
    viewsToInsert: EmbeddedViewRef<ItemContext | PaddingDivContext>[],
  ): void {
    let updateView = viewsToInsert.length != this.itemContainer.length;
    if (!updateView) {
      for (let index = 0; index < viewsToInsert.length; index++) {
        if (viewsToInsert[index] != this.itemContainer.get(index)) {
          updateView = true;
          break;
        }
      }
    }

    // Update actual visual elements if they changed. This
    // conditional logic prevents jittering.
    if (updateView) {
      for (let index = 0; index < viewsToInsert.length; index++) {
        const viewToInsert = viewsToInsert[index];
        const viewIndex = this.itemContainer.indexOf(viewToInsert);
        if (viewIndex < 0) {
          this.itemContainer.insert(viewToInsert, index);
        } else {
          if (index <= viewIndex) {
            let count = viewIndex - index;
            while (count > 0) {
              this.itemContainer.detach(index);
              count--;
            }
          } else {
            this.itemContainer.move(viewToInsert, index);
          }
        }
      }
      while (this.itemContainer.length > viewsToInsert.length) {
        this.itemContainer.detach();
      }

      // Notify subscribers about the change of items in view.
      this.notifyDataInViewSubscribers();
    }
  }

  private onAfterRender(): void {
    if (this.itemContainer.length >= 2 && this.stateNeedsUpdate) {
      const scrollTop = this.viewport.nativeElement.scrollTop;
      this.viewState.update(this.lastStartIndex, scrollTop, this.itemContainer);
      this.stateNeedsUpdate = false;
    }
  }

  private notifyDataInViewSubscribers(): void {
    const datas = [];
    for (let i = 0; i < this.itemContainer.length; i++) {
      const view = this.itemContainer.get(i) as EmbeddedViewRef<
        ItemContext | PaddingDivContext
      >;
      if (!(view.context instanceof PaddingDivContext)) {
        if (view?.context?.data) {
          datas.push(view.context.data);
        }
      }
    }
    this.dataInViewChange.emit(datas);
  }

  private evictViews(startIndex: number, endIndex: number): void {
    const minCached = Math.max(0, startIndex - this.cachedItems);
    const maxCached = Math.min(
      this.itemViewsLength,
      endIndex + this.cachedItems,
    );
    const keysToRemove: number[] = [];
    this.itemViewsCache.forEach((_, key) => {
      if (key < minCached || key >= maxCached) {
        keysToRemove.push(key);
      }
    });
    if (keysToRemove.length > 0) {
      keysToRemove.forEach((key) => {
        this.itemViewsCache.delete(key);
      });
    }
  }
}
