<div class="contents" [ngStyle]="{ width: width + 'px' }">
  <!-- Toolbar -->
  <div class="tool-bar">
    <!-- User filtering menu -->
    <app-user-filter [disableAll]="true" [(userFilter)]="userFilter" (userFilterChange)="onUserFilterChange($event)"
      matTooltip="User filter" [stateName]="'statsUserFilter'"></app-user-filter>
    <!-- End date -->
    <mat-form-field [floatLabel]="'always'" class="date">
      <mat-label>End date</mat-label>
      <input matInput readonly [matDatepicker]="endDatePicker" [(ngModel)]="endDate" (ngModelChange)="onEndDateChange()"
        matTooltip="End date of the stats time interval." [ngModelOptions]="{ standalone: true }" />
      <!-- <mat-hint>{{ dateFormat }}</mat-hint> -->
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker [disabled]="false"></mat-datepicker>
    </mat-form-field>
    <!-- Yearly/Monthly/Weekly option -->
    <app-edit-option [(selectedOptionValue)]="granularity" tooltipName="Select time interval granularity"
      [optionValues]="granularities" (editDone)="onGranularitySelected()"></app-edit-option>
  </div>

  <!-- Stats -->
  <div [ngStyle]="{
      'max-width': innerWidth + 'px',
      'margin-left': margin + 'px',
      'margin-right': margin + 'px'
    }">
    <app-edit-option [(selectedOptionValue)]="activityType" tooltipName="Select activity type"
      [optionValues]="activityTypes" (editDone)="onChartYAxisSelected()"></app-edit-option>
    <div class="chart-y-options">
      <span class="chart-axis">Y:</span>
      <app-edit-option [width]="'140px'" [(selectedOptionValue)]="seriesTypeLeft" tooltipName="Left series type"
        [optionValues]="seriesTypes" (editDone)="onChartYAxisSelected()"></app-edit-option>
      <app-edit-option [width]="'140px'" [(selectedOptionValue)]="seriesTypeRight" tooltipName="Right series type"
        [optionValues]="seriesTypes" (editDone)="onChartYAxisSelected()"></app-edit-option>
    </div>
    <div echarts class="chart" tabindex="0" [options]="chartOptions" [loading]="chartOptions.xAxis === undefined"
      (chartInit)="onChartInit($event)" (click)="onClick($event)" (keydown)="onChartKeyDownInaccessible()"></div>
  </div>

  <!-- HR Zones chart -->
  <app-hr-zones-chart *ngIf="hrZones" [hrZones]="hrZones"></app-hr-zones-chart>

  <!-- Table with activities for selected time interval -->
  <mat-table class="summaries-table" [ngStyle]="{ width: innerWidth + 'px' }" [dataSource]="summariesDataSource">
    <!-- Title Column -->
    <ng-container cdkColumnDef="title">
      <th cdk-header-cell class="summaries-header-cell" *cdkHeaderCellDef>
        Title
      </th>
      <td cdk-cell *cdkCellDef="let summary">
        <div class="summaries-cell summaries-title-cell" tabindex="0" (click)="onSummaryTitleClick(summary)"
          (keydown)="onSummaryTitleKeyDown($event, summary)">
          {{ summary.title }}
        </div>
      </td>
    </ng-container>

    <!-- Start Time Column -->
    <ng-container cdkColumnDef="start">
      <th cdk-header-cell class="summaries-header-cell" *cdkHeaderCellDef>
        Start
      </th>
      <td cdk-cell *cdkCellDef="let summary">
        <div class="summaries-cell">{{ formatDate(summary.totalStats.startTime) }}</div>
      </td>
    </ng-container>

    <!-- End Time Column -->
    <ng-container cdkColumnDef="end">
      <th cdk-header-cell class="summaries-header-cell" *cdkHeaderCellDef>
        End
      </th>
      <td cdk-cell *cdkCellDef="let summary">
        <div class="summaries-cell">{{ formatDate(summary.totalStats.endTime) }}</div>
      </td>
    </ng-container>

    <!-- Activity Type Column -->
    <ng-container cdkColumnDef="type">
      <th cdk-header-cell class="summaries-header-cell" *cdkHeaderCellDef>
        Type
      </th>
      <td cdk-cell *cdkCellDef="let summary">
        <div class="summaries-cell">{{ formatType(summary.activityType) }}</div>
      </td>
    </ng-container>

    <!-- Distance Column -->
    <ng-container cdkColumnDef="distance">
      <th cdk-header-cell class="summaries-header-cell" *cdkHeaderCellDef>
        {{ formatDistanceTitle() }}
      </th>
      <td cdk-cell *cdkCellDef="let summary">
        <div class="summaries-cell">{{ formatDistance(summary.totalStats.distance) }}</div>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="summariesColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: summariesColumns"></tr>
  </mat-table>
  <!-- Paginator -->
  <mat-paginator #summariesPaginator [pageSizeOptions]="[10, 20, 50]" aria-label="Select page" matTooltip="Select page"
    [ngStyle]="{ 'max-width': innerWidth + 'px' }">
  </mat-paginator>
</div>
