/** Types of charts. */
export enum ChartType {
  // Horizontal axis is kilometers traveled.
  Kilometers = 'Kilometers',
  // Horizontal axis is miles traveled.
  Miles = 'Miles',
  // Horizontal axis is hours.
  Hours = 'Hours',
  // Horizontal axis is days.
  Days = 'Days',
}

/** Value on the vertical axis. */
export enum SeriesType {
  NumActivities = 'Num Activities',
  Distance = 'Distance',
  ElevationGain = 'Elevation Gain',
  ElevationDrop = 'Elevation Drop',
  Calories = 'Calories',
  AverageSpeed = 'Average Speed',
  MedianSpeed = 'Median Speed',
  MilePace = 'Mile Pace',
  KilometerPace = 'Kilometer Pace',
  AverageHeartRate = 'Average HR',
  MedianHeartRate = 'Median HR',
  MovingTime = 'Moving Time',
  TimeInZone1 = 'Time In Zone 1',
  TimeInZone2 = 'Time In Zone 2',
  TimeInZone3 = 'Time In Zone 3',
  TimeInZone4 = 'Time In Zone 4',
  TimeInZone5 = 'Time In Zone 5',
  PercentTimeInZone1 = '% Time In Zone 1',
  PercentTimeInZone2 = '% Time In Zone 2',
  PercentTimeInZone3 = '% Time In Zone 3',
  PercentTimeInZone4 = '% Time In Zone 4',
  PercentTimeInZone5 = '% Time In Zone 5',

  // Per point types.
  Elevation = 'Elevation',
  Speed = 'Speed',
  HeartRate = 'Heart Rate',
  Grade = 'Grade',
}
