import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { Summary } from 'generated/src/main/proto/api/activity-service.pb';
import { ReactionType } from 'generated/src/main/proto/shared/reaction.pb';
import { Roles } from '../admin/roles';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import {
  Comment,
  CommentSource,
} from '../common/reactions/reactions.comment.source';
import { CommentConstants } from '../common/reactions/reactions.shared';
import { ActivitiesService } from '../services/activities/activities.service';
import { FormatService } from '../services/format.service';
import { LoggedInUser, UserService } from '../services/user/user.service';
import { ReactionSource } from './reaction-details.reaction.source';

/**
 * Shows details of reactions and comments for an activity.
 */
@Component({
  selector: 'app-reaction-details',
  templateUrl: './reaction-details.component.html',
  styleUrls: ['./reaction-details.component.scss'],
})
export class ReactionDetailsComponent implements OnInit, AfterViewInit {
  private tabIndex: number | undefined;

  @Input()
  activityId: string | undefined;

  user?: LoggedInUser;

  viewWidth: number;

  summary: Summary | undefined;

  @ViewChild('matTabGroup') tabGroup!: MatTabGroup;

  @ViewChild('reactionsPaginator') reactionsPaginator!: MatPaginator;
  reactionsSource!: ReactionSource;

  commentsSource!: CommentSource;
  currentCommentRemainingChars = CommentConstants.MAX_COMMENT_CHARS;
  private currentCommentValue = '';

  reactionsDisplayColumns = ['reaction', 'name', 'alias', 'date'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private activitiesService: ActivitiesService,
    private formatService: FormatService,
    private dialog: MatDialog,
  ) {
    const margin = 7;
    this.viewWidth =
      this.formatService.viewWidth - 2 * margin - formatService.scrollBarWidth;
    userService.user.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (!this.activityId) {
        this.activityId = params.get('id') ?? undefined;
      }
      this.tabIndex = params.get('tab')
        ? parseInt(params.get('tab')!)
        : undefined;
    });
  }

  ngAfterViewInit(): void {
    if (this.activityId) {
      this.activitiesService.getSummary(this.activityId).then((summary) => {
        this.summary = summary;
        this.reactionsSource = new ReactionSource(
          this.userService,
          this.reactionsPaginator,
          summary,
        );

        this.commentsSource = new CommentSource(
          this.userService,
          this.formatService,
          summary?.reactions?.commentsContainer,
        );

        if (this.tabIndex != undefined) {
          this.tabGroup.selectedIndex = this.tabIndex;
        }
      });
    }
  }

  formatDateSinceToday(timestamp: Timestamp): string {
    return this.formatService.formatDateSinceToday(timestamp);
  }

  formatDate(timestamp: Timestamp): string {
    return this.formatService.formatDate(timestamp);
  }

  set currentComment(value: string) {
    this.currentCommentRemainingChars =
      CommentConstants.MAX_COMMENT_CHARS - value.length;
    this.currentCommentValue = value;
  }

  get currentComment(): string {
    return this.currentCommentValue;
  }

  onCommentKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.activitiesService
        .updateComment(
          this.activityId!,
          this.currentCommentValue,
          undefined,
          undefined,
        )
        .then((r) => {
          this.summary!.reactions = r.reactions;
          this.commentsSource.updateComments(
            this.summary?.reactions?.commentsContainer,
          );
        });
      this.currentComment = '';

      e.preventDefault();
      e.stopPropagation();
    }
  }

  onCommentReactionChanged(
    commentId: number,
    reactiontype: ReactionType,
  ): void {
    this.activitiesService
      .updateCommentReaction(this.activityId!, commentId, reactiontype)
      .then((r) => {
        this.summary!.reactions = r.reactions;
        this.commentsSource.updateComments(
          this.summary?.reactions?.commentsContainer,
        );
      });
  }

  isDeleteAllowed(comment: Comment): boolean {
    return (
      (this.user !== undefined && this.user.alias == comment.alias) ||
      (this.user !== undefined &&
        this.user.roles!.indexOf(Roles.IssueReviewer) >= 0)
    );
  }

  onDeleteComment(commentId: number) {
    ConfirmationComponent.openDialog(
      this.dialog,
      'Delete comment?',
      (result) => {
        if (result) {
          this.activitiesService
            .updateComment(this.activityId!, '', undefined, commentId)
            .then((r) => {
              this.summary!.reactions = r.reactions;
              this.commentsSource.updateComments(
                this.summary?.reactions?.commentsContainer,
              );
            });
        }
      },
    );
  }
}
