import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  message = '';
  showCancel = true;
  confirmText = '';
  title = '';

  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>) {}

  /** Shows dialog with Cancel/OK buttons. */
  public static openDialog(
    dialog: MatDialog,
    message: string,
    onComplete: (result: boolean) => void,
  ): void {
    const dialogRef = dialog.open(ConfirmationComponent);
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.showCancel = true;
    dialogRef.componentInstance.confirmText = 'OK';
    dialogRef.componentInstance.title = 'Confirm';
    dialogRef.afterClosed().subscribe((result) => onComplete(result));
  }

  /** Shows dialog with OK button. */
  public static openAlert(
    dialog: MatDialog,
    message: string,
    onComplete: () => void,
  ): void {
    const dialogRef = dialog.open(ConfirmationComponent);
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.showCancel = false;
    dialogRef.componentInstance.confirmText = 'OK';
    dialogRef.componentInstance.title = 'Alert';
    dialogRef.afterClosed().subscribe(() => onComplete());
  }
}
