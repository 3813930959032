export type HomeContents = {
  header: string;
  textItems: {
    title: string;
    text: string;
  }[];
  imageName: string;
  imageAlt: string;
}[];

export const HOME_CONTENTS: HomeContents = [
  {
    header: 'Activities',
    textItems: [
      {
        title: 'Integrate with your wearable',
        text: 'Seamlessly post your activities from FitBit or Garmin watch.',
      },
      {
        title: 'Connect with friends',
        text: 'Follow others, see their activities, search them by user name and geospatially.',
      },
      {
        title: 'Add photos and videos',
        text: 'Interesting photos and statistics are automatically highlighted by AI.',
      },
    ],
    imageName: 'home_activities.jpg',
    imageAlt: 'Image with activities',
  },
  {
    header: 'Stats',
    textItems: [
      {
        title: 'By year, month, week',
        text: `Cumulative statistics are shown by year, month or week. The activities are easily accessible from any
        time period. Check your totals for distance, calories, elevation and others.`,
      },
      {
        title: 'Other users\' stats',
        text: 'Easily filter by user with helpful auto-suggest.',
      },
      {
        title: 'Side-by-side',
        text: 'Show different stats side by side on the graph.',
      },
    ],
    imageName: 'home_stats.jpg',
    imageAlt: 'Image with stats',
  },
  {
    header: 'Calendar',
    textItems: [
      {
        title: 'Add events',
        text: 'Add events with rich descriptions. Share them with others.',
      },
      {
        title: 'See others\' events',
        text: 'Users can allow others to see their calendars.',
      },
    ],
    imageName: 'home_calendar.jpg',
    imageAlt: 'Image with calendar',
  },
];
