import { Injectable } from '@angular/core';

import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  first,
  lastValueFrom,
  of,
  tap,
  throwError,
} from 'rxjs';

import { Router } from '@angular/router';
import { GrpcMetadata } from '@ngx-grpc/common';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  AliasExistsRequest,
  AutoSuggestUsersRequest,
  CheckUserRestrictionsRequest,
  CheckUserRestrictionsResponse,
  DeleteRequest,
  DeleteResponse,
  GetAvailableRolesRequest,
  GetDefaultHeartRateLimitsRequest,
  GetDefaultHeartRateLimitsResponse,
  GetUserDetailsRequest,
  GetUserRolesRequest,
  LogInRequest,
  LogInResponse,
  LogOutRequest,
  LogOutResponse,
  SignOutRequest,
  SignOutResponse,
  SignUpRequest,
  SignUpResponse,
  UpdateCookiesConsentRequest,
  UpdateCookiesConsentResponse,
  UpdateMessagingTokenRequest,
  UpdateMessagingTokenResponse,
  UpdateRequest,
  UpdateResponse,
  UpdateUserRolesRequest,
  UpdateUserRolesResponse,
  UserPrivacyControls,
  UserPrivateDetails,
  UserPublicDetails,
  UserRole,
} from 'generated/src/main/proto/api/user-service.pb';
import { UserServiceClient } from 'generated/src/main/proto/api/user-service.pbsc';
import { Media } from 'generated/src/main/proto/shared/media-shared.pb';
import {
  Sex,
  UnitType,
  UserHeartRateLimits,
  UserLocation,
} from 'generated/src/main/proto/shared/user-shared.pb';
import { PolicyVersions } from 'src/app/common/policy-versions';
import {
  authToken,
  deleteSessionId,
  sessionIdExists,
  setSessionId,
} from '../api.auth';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { MediaService } from '../media-service/media.service';

export enum UnitPreference {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export class LoggedInUser {
  nameInitials!: string;
  profileImageUrl!: string;
  details = new UserPrivateDetails();
  wasUpdated = false;

  constructor(
    public email?: string,
    public alias?: string,
    public roles?: string[],
    details?: UserPrivateDetails,
  ) {
    if (details) {
      this.details = new UserPrivateDetails(details);
    }
    this.setProfileImageUrl();
  }

  get unitPreference(): UnitPreference {
    return UserService.toDisplay(this.details.unitType);
  }

  updateRoles(roles: string[]) {
    this.roles = roles;
    this.wasUpdated = true;
  }

  updateCookiesConsent(
    consentedCookiesVersion: string,
    acceptedCookiesCategories: string[],
  ): void {
    this.details.acceptedCookiesCategories = [...acceptedCookiesCategories];
    this.details.consentedCookiesVersion = consentedCookiesVersion;
  }

  private setProfileImageUrl(): void {
    this.profileImageUrl = '';
    if (this.details.photoMedia) {
      this.profileImageUrl = MediaService.getImageSrc(
        this.details.photoMedia.smallSizeId,
      );
    }
  }
}

/** User service handles log-in, log-out, sign-up plus other operations on user. */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSource: BehaviorSubject<LoggedInUser | undefined> =
    new BehaviorSubject<LoggedInUser | undefined>(new LoggedInUser());
  public user: Observable<LoggedInUser | undefined> =
    this.userSource.asObservable();
  public snapshotUser?: LoggedInUser;
  loggedIn = false;

  public socialUser?: SocialUser;
  private socialUserSubject = new Subject<SocialUser>();
  public readonly socialUserObservable = this.socialUserSubject.asObservable();

  constructor(
    private authService: SocialAuthService,
    private userServiceClient: UserServiceClient,
    private bannerService: BannerService,
    router: Router,
  ) {
    this.authService.authState.subscribe((user) => {
      this.socialUser = user;
      this.socialUserSubject.next(user);

      // Attempt to log-in with the new user.
      if (user) {
        this.logIn(user).then((result) => {
          if (!result) {
            // Check if number of users is not exceeded.
            this.checkUserRestrictions(user.email).then((r) => {
              if (r.isDenied) {
                this.bannerService.add(new BannerMessage(r.reason?.text));
              } else {
                router.navigate(['login']);
              }
            });
          }
        });
      }
    });
  }

  get userTokenMetadata(): GrpcMetadata {
    return authToken(null);
  }

  async checkUserRestrictions(
    email: string,
  ): Promise<CheckUserRestrictionsResponse> {
    return lastValueFrom(
      this.userServiceClient
        .checkUserRestrictions(
          new CheckUserRestrictionsRequest({ email: email }),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async signUp(
    user: SocialUser,
    firstName: string,
    lastName: string,
    alias: string,
    unitPreference: UnitPreference,
    timeZone: string,
    dateOfBirth: Timestamp | undefined,
    sex: Sex | undefined,
    weightInKilograms: number | undefined,
    userHeartRateLimits: UserHeartRateLimits,
    accpetedCookiesCategories: string[],
    privacyPolicyVersion: string,
    locations: UserLocation[],
    privacyControls: UserPrivacyControls,
  ): Promise<boolean> {
    const details = new UserPrivateDetails({
      firstName: firstName,
      lastName: lastName,
      consentedCookiesVersion: PolicyVersions.cookiesPolicyLastUpdateDate,
      acceptedCookiesCategories: accpetedCookiesCategories,
      consentedPrivacyVersion: privacyPolicyVersion,
      unitType: UserService.toApi(unitPreference),
      timeZone: timeZone,
      locations: locations,
    });
    if (dateOfBirth) {
      details.dateOfBirth = dateOfBirth;
    }
    if (sex) {
      details.sex = sex;
    }
    if (weightInKilograms) {
      details.weightInKilograms = weightInKilograms;
    }
    details.heartRateLimits = userHeartRateLimits;
    details.privacyControls = privacyControls;
    return lastValueFrom(
      this.userServiceClient
        .signUp(
          new SignUpRequest({
            alias: alias,
            details: details,
          }),
          authToken(user.idToken),
        )
        .pipe(
          catchError((e) => {
            this.logOutLocally();
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of(false);
          }),
        )
        .pipe(
          tap((r) => {
            if (r instanceof SignUpResponse) {
              const loggedInUser = new LoggedInUser(
                user.email,
                alias,
                r.roles,
                details,
              );
              setSessionId(
                r.session!.sessionId,
                r.session!.expirationTime!.toDate(),
              );
              this.loggedIn = true;
              this.snapshotUser = loggedInUser;
              this.userSource.next(loggedInUser);
            }
          }),
          first(),
        ),
    ).then((r) => r instanceof SignUpResponse);
  }

  async getDefaultHeartRateLimits(
    dateOfBirth: Timestamp | undefined,
  ): Promise<GetDefaultHeartRateLimitsResponse> {
    const request = new GetDefaultHeartRateLimitsRequest();
    if (dateOfBirth) {
      request.dateOfBirth = dateOfBirth;
    }
    return lastValueFrom(
      this.userServiceClient
        .getDefaultHeartRateLimits(request, authToken(null))
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async logInWithSession(): Promise<boolean> {
    if (!sessionIdExists()) {
      return Promise.resolve(false);
    }

    return lastValueFrom(
      this.userServiceClient
        .logIn(new LogInRequest(), authToken(null))
        .pipe(
          catchError((e) => {
            this.logOutLocally();
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of(false);
          }),
        )
        .pipe(
          tap((r) => {
            if (r instanceof LogInResponse) {
              this.processLogInResponse(r);
            }
          }),
          first(),
        ),
    ).then((r) => r instanceof LogInResponse);
  }

  async logIn(user: SocialUser): Promise<boolean> {
    if (!user.idToken) {
      return Promise.resolve(false);
    }
    return lastValueFrom(
      this.userServiceClient
        .logIn(new LogInRequest(), authToken(user.idToken))
        .pipe(
          catchError((e) => {
            this.logOutLocally();
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of(false);
          }),
        )
        .pipe(
          tap((r) => {
            if (r instanceof LogInResponse) {
              this.processLogInResponse(r);
            }
          }),
          first(),
        ),
    ).then((r) => r instanceof LogInResponse);
  }

  /** Upserts Firebase device token for push notifications. */
  async updateMessagingToken(token: string): Promise<boolean> {
    return lastValueFrom(
      this.userServiceClient
        .updateMessagingToken(
          new UpdateMessagingTokenRequest({ token: token }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of(false);
          }),
        ),
    ).then((r) => r instanceof UpdateMessagingTokenResponse);
  }

  private processLogInResponse(r: LogInResponse) {
    // Set session id first as session is used in profile url.
    setSessionId(r.session!.sessionId, r.session!.expirationTime!.toDate());

    const details = r.details!;
    const loggedInUser = new LoggedInUser(r.email, r.alias, r.roles, details);
    this.snapshotUser = loggedInUser;
    this.userSource.next(loggedInUser);
  }

  async logOut(): Promise<boolean> {
    return lastValueFrom(
      this.userServiceClient
        .logOut(new LogOutRequest(), authToken(null))
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of('');
          }),
        )
        .pipe(
          tap((r) => {
            this.logOutLocally();
            return r;
          }),
          first(),
        ),
    ).then((r) => r instanceof LogOutResponse);
  }

  async update(
    firstName: string,
    lastName: string,
    unitPreference: UnitPreference,
    timeZone: string,
    dateOfBirth: Timestamp | undefined,
    sex: Sex | undefined,
    weightInKilograms: number | undefined,
    userHeartRateLimits: UserHeartRateLimits,
    profileImageMedia: Media | undefined,
    accpetedCookiesCategories: string[],
    privacyPolicyVersion: string,
    locations: UserLocation[],
    privacyControls: UserPrivacyControls,
  ): Promise<boolean> {
    const details = new UserPrivateDetails({
      firstName: firstName,
      lastName: lastName,
      consentedCookiesVersion: PolicyVersions.cookiesPolicyLastUpdateDate,
      acceptedCookiesCategories: accpetedCookiesCategories,
      consentedPrivacyVersion: privacyPolicyVersion,
      unitType: UserService.toApi(unitPreference),
      timeZone: timeZone,
      locations: locations,
    });
    if (dateOfBirth) {
      details.dateOfBirth = dateOfBirth;
    }
    if (sex) {
      details.sex = sex;
    }
    if (weightInKilograms) {
      details.weightInKilograms = weightInKilograms;
    }
    details.heartRateLimits = userHeartRateLimits;
    if (profileImageMedia) {
      details.photoMedia = profileImageMedia;
    }
    details.privacyControls = privacyControls;
    return lastValueFrom(
      this.userServiceClient
        .update(
          new UpdateRequest({
            details: details,
          }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of('');
          }),
        )
        .pipe(
          tap((r) => {
            if (r instanceof UpdateResponse) {
              let user = this.userSource.getValue();
              if (!user) {
                throw 'User is undefined';
              }
              user = new LoggedInUser(
                user.email,
                user.alias,
                user.roles,
                details,
              );
              user.wasUpdated = true;
              this.snapshotUser = user;
              this.userSource.next(user);
            }
          }),
          first(),
        ),
    ).then((r) => r instanceof UpdateResponse);
  }

  async updateCookiesConsent(
    acceptedCookiesCategories: string[],
  ): Promise<boolean> {
    return lastValueFrom(
      this.userServiceClient
        .updateCookiesConsent(
          new UpdateCookiesConsentRequest({
            acceptedCookiesCategories: acceptedCookiesCategories,
            consentedCookiesVersion: PolicyVersions.cookiesPolicyLastUpdateDate,
          }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of('');
          }),
        )
        .pipe(
          tap((r) => {
            if (r instanceof UpdateCookiesConsentResponse) {
              const user = this.userSource.getValue();
              if (!user) {
                throw 'User is undefined';
              }

              user.updateCookiesConsent(
                PolicyVersions.cookiesPolicyLastUpdateDate,
                acceptedCookiesCategories,
              );
              user.wasUpdated = true;
              this.snapshotUser = user;
              this.userSource.next(user);
            }
          }),
          first(),
        ),
    ).then((r) => r instanceof UpdateResponse);
  }
  /** This method removes all user data. */
  async signOut(): Promise<boolean> {
    return lastValueFrom(
      this.userServiceClient
        .signOut(new SignOutRequest(), authToken(null))
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of('');
          }),
        )
        .pipe(
          tap(() => this.logOutLocally()),
          first(),
        ),
    ).then((r) => r instanceof SignOutResponse);
  }

  async delete(alias: string): Promise<DeleteResponse> {
    return lastValueFrom(
      this.userServiceClient
        .delete(new DeleteRequest({ alias: alias }), authToken(null))
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async aliasExists(alias: string): Promise<boolean | undefined> {
    const request = new AliasExistsRequest();
    request.alias = alias;
    return lastValueFrom(
      this.userServiceClient.aliasExists(request, authToken(null)).pipe(
        catchError((e) => {
          this.bannerService.add(new BannerMessage(e.statusMessage));
          return throwError(() => e);
        }),
      ),
    ).then((r) => r.exists);
  }

  async getUserDetails(aliases: string[]): Promise<UserPublicDetails[]> {
    return lastValueFrom(
      this.userServiceClient
        .getUserDetails(
          new GetUserDetailsRequest({ aliases: aliases }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => r.users!);
  }

  async getAvailableRoles(): Promise<UserRole[]> {
    return lastValueFrom(
      this.userServiceClient
        .getAvailableRoles(new GetAvailableRolesRequest(), authToken(null))
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => r.roles!);
  }

  async getUserRoles(alias: string): Promise<UserRole[]> {
    return lastValueFrom(
      this.userServiceClient
        .getUserRoles(
          new GetUserRolesRequest({ alias: alias }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => r.roles!);
  }

  async updateUserRoles(
    alias: string,
    roles: string[],
  ): Promise<UpdateUserRolesResponse> {
    return lastValueFrom(
      this.userServiceClient
        .updateUserRoles(
          new UpdateUserRolesRequest({ alias: alias, roles: roles }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => {
      const user = this.userSource.getValue();
      if (!user) {
        throw 'User is undefined';
      }
      user.updateRoles(roles);
      this.userSource.next(user);
      return r;
    });
  }

  async autoSuggestUsers(queryPrefix: string): Promise<UserPublicDetails[]> {
    if (queryPrefix.length < 2) {
      return Promise.resolve([]);
    }
    return lastValueFrom(
      this.userServiceClient
        .autoSuggestUsers(
          new AutoSuggestUsersRequest({
            queryPrefix: queryPrefix,
            maxResults: 10,
          }),
          authToken(null),
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => r.userDetails!);
  }

  private logOutLocally(): void {
    deleteSessionId();
    this.loggedIn = false;
    if (this.snapshotUser) {
      // Sign-out in the auth service if login was done with it.
      if (this.socialUser) {
        this.authService.signOut(true);
      }
      this.snapshotUser = undefined;
      this.userSource.next(undefined);
    }
  }

  private static toApi(unitPreference: UnitPreference): UnitType {
    switch (unitPreference) {
      case UnitPreference.Imperial:
        return UnitType.UNIT_TYPE_IMPERIAL;
      case UnitPreference.Metric:
        return UnitType.UNIT_TYPE_METRIC;
      default:
        return UnitType.UNIT_TYPE_IMPERIAL;
    }
  }

  static toDisplay(unitType: UnitType): UnitPreference {
    switch (unitType) {
      case UnitType.UNIT_TYPE_IMPERIAL:
        return UnitPreference.Imperial;
      case UnitType.UNIT_TYPE_METRIC:
        return UnitPreference.Metric;
      default:
        return UnitPreference.Imperial;
    }
  }
}
