/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/pubsub/notification.pb';
import * as shared002 from '../../../../src/main/proto/shared/notification-shared.pb';
/**
 * Message implementation for api.notification.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.notification.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    GetRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {};
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.notification.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.notification.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.notifications = _instance.notifications || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new shared002.NotificationContainer();
          _reader.readMessage(
            messageInitializer1,
            shared002.NotificationContainer.deserializeBinaryFromReader
          );
          (_instance.notifications = _instance.notifications || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.notifications && _instance.notifications.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.notifications as any,
        shared002.NotificationContainer.serializeBinaryToWriter
      );
    }
  }

  private _notifications?: shared002.NotificationContainer[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.notifications = (_value.notifications || []).map(
      m => new shared002.NotificationContainer(m)
    );
    GetResponse.refineValues(this);
  }
  get notifications(): shared002.NotificationContainer[] | undefined {
    return this._notifications;
  }
  set notifications(value: shared002.NotificationContainer[] | undefined) {
    this._notifications = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      notifications: (this.notifications || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      notifications: (this.notifications || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    notifications?: shared002.NotificationContainer.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    notifications: shared002.NotificationContainer.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.notification.SetSeenRequest
 */
export class SetSeenRequest implements GrpcMessage {
  static id = 'api.notification.SetSeenRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetSeenRequest();
    SetSeenRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetSeenRequest) {
    _instance.notificationIds = _instance.notificationIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetSeenRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.notificationIds = _instance.notificationIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SetSeenRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetSeenRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.notificationIds && _instance.notificationIds.length) {
      _writer.writePackedInt64String(1, _instance.notificationIds);
    }
  }

  private _notificationIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetSeenRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetSeenRequest.AsObject>) {
    _value = _value || {};
    this.notificationIds = (_value.notificationIds || []).slice();
    SetSeenRequest.refineValues(this);
  }
  get notificationIds(): string[] {
    return this._notificationIds;
  }
  set notificationIds(value: string[]) {
    this._notificationIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetSeenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetSeenRequest.AsObject {
    return {
      notificationIds: (this.notificationIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetSeenRequest.AsProtobufJSON {
    return {
      notificationIds: (this.notificationIds || []).slice()
    };
  }
}
export module SetSeenRequest {
  /**
   * Standard JavaScript object representation for SetSeenRequest
   */
  export interface AsObject {
    notificationIds: string[];
  }

  /**
   * Protobuf JSON representation for SetSeenRequest
   */
  export interface AsProtobufJSON {
    notificationIds: string[];
  }
}

/**
 * Message implementation for api.notification.SetSeenResponse
 */
export class SetSeenResponse implements GrpcMessage {
  static id = 'api.notification.SetSeenResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetSeenResponse();
    SetSeenResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetSeenResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetSeenResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    SetSeenResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetSeenResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetSeenResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetSeenResponse.AsObject>) {
    _value = _value || {};
    SetSeenResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetSeenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetSeenResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetSeenResponse.AsProtobufJSON {
    return {};
  }
}
export module SetSeenResponse {
  /**
   * Standard JavaScript object representation for SetSeenResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for SetSeenResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.notification.GetControlsRequest
 */
export class GetControlsRequest implements GrpcMessage {
  static id = 'api.notification.GetControlsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetControlsRequest();
    GetControlsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetControlsRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetControlsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetControlsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetControlsRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetControlsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetControlsRequest.AsObject>) {
    _value = _value || {};
    GetControlsRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetControlsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetControlsRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetControlsRequest.AsProtobufJSON {
    return {};
  }
}
export module GetControlsRequest {
  /**
   * Standard JavaScript object representation for GetControlsRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetControlsRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.notification.GetControlsResponse
 */
export class GetControlsResponse implements GrpcMessage {
  static id = 'api.notification.GetControlsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetControlsResponse();
    GetControlsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetControlsResponse) {
    _instance.controls = _instance.controls || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetControlsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.controls = new shared002.NotificationControls();
          _reader.readMessage(
            _instance.controls,
            shared002.NotificationControls.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetControlsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetControlsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.controls) {
      _writer.writeMessage(
        1,
        _instance.controls as any,
        shared002.NotificationControls.serializeBinaryToWriter
      );
    }
  }

  private _controls?: shared002.NotificationControls;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetControlsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetControlsResponse.AsObject>) {
    _value = _value || {};
    this.controls = _value.controls
      ? new shared002.NotificationControls(_value.controls)
      : undefined;
    GetControlsResponse.refineValues(this);
  }
  get controls(): shared002.NotificationControls | undefined {
    return this._controls;
  }
  set controls(value: shared002.NotificationControls | undefined) {
    this._controls = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetControlsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetControlsResponse.AsObject {
    return {
      controls: this.controls ? this.controls.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetControlsResponse.AsProtobufJSON {
    return {
      controls: this.controls ? this.controls.toProtobufJSON(options) : null
    };
  }
}
export module GetControlsResponse {
  /**
   * Standard JavaScript object representation for GetControlsResponse
   */
  export interface AsObject {
    controls?: shared002.NotificationControls.AsObject;
  }

  /**
   * Protobuf JSON representation for GetControlsResponse
   */
  export interface AsProtobufJSON {
    controls: shared002.NotificationControls.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.notification.UpdateControlsRequest
 */
export class UpdateControlsRequest implements GrpcMessage {
  static id = 'api.notification.UpdateControlsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateControlsRequest();
    UpdateControlsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateControlsRequest) {
    _instance.controls = _instance.controls || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateControlsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.controls = new shared002.NotificationControls();
          _reader.readMessage(
            _instance.controls,
            shared002.NotificationControls.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateControlsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateControlsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.controls) {
      _writer.writeMessage(
        1,
        _instance.controls as any,
        shared002.NotificationControls.serializeBinaryToWriter
      );
    }
  }

  private _controls?: shared002.NotificationControls;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateControlsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateControlsRequest.AsObject>) {
    _value = _value || {};
    this.controls = _value.controls
      ? new shared002.NotificationControls(_value.controls)
      : undefined;
    UpdateControlsRequest.refineValues(this);
  }
  get controls(): shared002.NotificationControls | undefined {
    return this._controls;
  }
  set controls(value: shared002.NotificationControls | undefined) {
    this._controls = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateControlsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateControlsRequest.AsObject {
    return {
      controls: this.controls ? this.controls.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateControlsRequest.AsProtobufJSON {
    return {
      controls: this.controls ? this.controls.toProtobufJSON(options) : null
    };
  }
}
export module UpdateControlsRequest {
  /**
   * Standard JavaScript object representation for UpdateControlsRequest
   */
  export interface AsObject {
    controls?: shared002.NotificationControls.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateControlsRequest
   */
  export interface AsProtobufJSON {
    controls: shared002.NotificationControls.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.notification.UpdateControlsResponse
 */
export class UpdateControlsResponse implements GrpcMessage {
  static id = 'api.notification.UpdateControlsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateControlsResponse();
    UpdateControlsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateControlsResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateControlsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateControlsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateControlsResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateControlsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateControlsResponse.AsObject>) {
    _value = _value || {};
    UpdateControlsResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateControlsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateControlsResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateControlsResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateControlsResponse {
  /**
   * Standard JavaScript object representation for UpdateControlsResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateControlsResponse
   */
  export interface AsProtobufJSON {}
}
