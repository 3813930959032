<div #contents class="contents">
  <div class="dialog-content" mat-dialog-content>
    <!-- Follow requests -->
    <div class="item">
      <div class="item-start">
        <mat-icon class="icon-start material-symbols-outlined">person_add</mat-icon>
      </div>

      <div class="follow-request-container">
        <span class="section-title">Follow requests</span>
        <div class="follow-request-div" *ngFor="let r of followRequests">
          <div class="item-start">
            <app-profile-image [alias]="getRequestAlias(r)"></app-profile-image>
          </div>

          <span class="follow-request-span">{{ r.firstName + ' ' + r.lastName + '(@' + r.alias + ')' }}</span>

          <span class="flex-expand"></span>

          <div class="follow-request-buttons">
            <button mat-raised-button color="accent" class="reject-request-button" matTooltip="Reject request"
              (click)="onResolveRequest(r.alias, false)">Reject</button>
            <button mat-raised-button class="approve-request-button" matTooltip="Accept request"
              (click)="onResolveRequest(r.alias, true)">Approve</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Last 7 days -->
    <span class="section-title">Last 7 days</span>
    <div class="item" *ngFor="let notification of recentNotifications">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { notification: notification}"></ng-container>
    </div>


    <!-- Older -->
    <span class="section-title">Older</span>
    <div class="item" *ngFor="let notification of olderNotifications">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { notification: notification}"></ng-container>
    </div>

    <!-- Item template -->
    <ng-template #itemTemplate let-notification="notification">
      <div class="item-start">
        <app-profile-image [alias]="getAlias(notification)"></app-profile-image>
      </div>

      <div class="item">
        <!-- Uploaded activity -->
        <div *ngIf="notification.notification.uploadedActivity" class="uploaded-activity">
          <span class="text">{{ formatUploadedActivity(notification.notification.uploadedActivity) }}</span>
          <span class="link" tabindex="0"
            (click)="onLinkClick('/activity/' + notification.notification.uploadedActivity.activityId)"
            (keydown)="onLinkKeydown($event, '/activity/' + notification.notification.uploadedActivity.activityId)">Go
            to
            activity</span>
        </div>

        <!-- Failed activity -->
        <div *ngIf="notification.notification.failedActivityUpload" class="failed-activity">
          <span class="text">{{ formatFailedActivity(notification.notification.failedActivityUpload) }}</span>
        </div>

        <!-- New Following -->
        <div *ngIf="notification.notification.newFollowing" class="new-following">
          <span class="text">{{ formatNewFollowing(notification.notification.newFollowing) }}</span>
        </div>

        <!-- New Reaction -->
        <div *ngIf="notification.notification.newReaction" class="new-reaction">
          <span class="text">{{ formatNewReaction(notification.notification.newReaction) }}</span>
          <span class="link" tabindex="0"
            (click)="onLinkClick('/activity/' + notification.notification.newReaction.activityId)"
            (keydown)="onLinkKeydown($event, '/activity/' + notification.notification.newReaction.activityId)">Go
            to
            activity</span>
        </div>

        <!-- Date -->
        <div class="text" matTooltip="{{formatDate(notification.time) }}">{{ formatDateSinceToday(notification.time)
          }}</div>
      </div>
    </ng-template>

  </div>
</div>
