<div class="contents">
  <div mat-dialog-content>
    <!-- Backfill states -->
    <div *ngIf="backfillStates.length > 0" class="states">
      <div class="states-header"><span>Previous requests to backfill:</span></div>
      <div class="state-row" *ngFor="let backfillState of backfillStates">
        <app-measurement-widget [measurement]="timestampMeasurement('Requested', backfillState.requestTime!)"
          [useTitle]="true"></app-measurement-widget>
        <app-measurement-widget [measurement]="timestampMeasurement('Start', backfillState.startTime!)"
          [useTitle]="true"></app-measurement-widget>
        <app-measurement-widget [measurement]="timestampMeasurement('End', backfillState.endTime!)"
          [useTitle]="true"></app-measurement-widget>
        <app-measurement-widget [measurement]="completedPercentageMeasurement('Completed', backfillState)"
          [useTitle]="true"></app-measurement-widget>
      </div>
    </div>

    <!-- Date pickers -->
    <div class="instructions">
      <span>Select dates to backfill (import):</span>
      <li>The dates cannot overlap with the previously backfilled dates.</li>
      <li>No older than 5 * 365 days.</li>
    </div>
    <div class="date-pickers">
      <!-- Start date -->
      <mat-form-field class="date">
        <mat-label>Start Date</mat-label>
        <input matTooltip="Start date" matInput [matDatepicker]="startDatePicker" readonly [(ngModel)]="startDate"
          (ngModelChange)="onStartDateChange()" />
        <mat-datepicker-toggle matSuffix [for]="startDatePicker" [disabled]="false"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker [disabled]="false"></mat-datepicker>
      </mat-form-field>
      <!-- End date -->
      <mat-form-field class="date">
        <mat-label>End Date</mat-label>
        <input matTooltip="End date" matInput [matDatepicker]="endDatePicker" readonly [(ngModel)]="endDate"
          (ngModelChange)="onEndDateChange()" />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker" [disabled]="false"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker [disabled]="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div mat-dialog-actions class="actions">
      <button mat-raised-button class="cancel" (click)="dialogRef.close({ submit: false})">
        Cancel
      </button>
      <div class="flex-expand"></div>
      <button mat-raised-button color="accent" class="confirm" [disabled]="disableSubmit"
        (click)="dialogRef.close({ submit: true, startDate: startDate, endDate: endDate})">
        Submit
      </button>
    </div>
  </div>
