import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export class BannerMessage {
  constructor(
    // Message to display.
    public message: string | null = null,
    // Time in milliseconds for how long to display the message.
    public timeout: number = 5000,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private messageSource: BehaviorSubject<BannerMessage> =
    new BehaviorSubject<BannerMessage>(new BannerMessage());
  public message: Observable<BannerMessage> = this.messageSource.asObservable();

  public add(message: BannerMessage): void {
    this.messageSource.next(message);
  }
}
