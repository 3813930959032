<div class="contents" [ngStyle]="{ 'width': viewWidth + 'px' }">
  <div>
    <h2>Connect everyone to the outdoors</h2>
    <mat-list>
      <mat-list-item>
        Discover and follow others
      </mat-list-item>
      <mat-list-item>
        Discover and register for events
      </mat-list-item>
      <mat-list-item>
        Make it fun
      </mat-list-item>
    </mat-list>
  </div>
  <hr />

  <!-- Desktop -->
  <mat-grid-list *ngIf="!isMobile" cols="2" rows="3" rowHeight="400">
    <div *ngFor="let tile of contents; let even = even">
      <div *ngIf="even">
        <mat-grid-tile>
          <img class="image" src="{{ baseImageUrl + tile.imageName }}" alt="{{ tile.imageAlt }}" />
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-grid-tile-header>{{ tile.header }}</mat-grid-tile-header>
          <mat-list>
            <mat-list-item *ngFor="let item of tile.textItems" lines="3">
              <span matListItemTitle>{{ item.title }}</span>
              <span>{{ item.text }}</span>
            </mat-list-item>
          </mat-list>
        </mat-grid-tile>
      </div>
      <div *ngIf="!even">
        <mat-grid-tile>
          <mat-grid-tile-header>{{ tile.header }}</mat-grid-tile-header>
          <mat-list>
            <mat-list-item *ngFor="let item of tile.textItems" lines="3">
              <span matListItemTitle>{{ item.title }}</span>
              <span>{{ item.text }}</span>
            </mat-list-item>
          </mat-list>
        </mat-grid-tile>
        <mat-grid-tile>
          <img class="image" src="{{ baseImageUrl + tile.imageName }}" alt="{{ tile.imageAlt }}" />
        </mat-grid-tile>
      </div>
    </div>
  </mat-grid-list>

  <!-- Mobile -->
  <mat-grid-list *ngIf="isMobile" cols="1" rows="6" rowHeight="400">
    <div *ngFor="let tile of contents">
      <mat-grid-tile>
        <img class="image" src="{{ baseImageUrl + tile.imageName }}" alt="{{ tile.imageAlt }}" />
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-grid-tile-header>{{ tile.header }}</mat-grid-tile-header>
        <mat-list>
          <mat-list-item *ngFor="let item of tile.textItems" lines="3">
            <span matListItemTitle>{{ item.title }}</span>
            <span>{{ item.text }}</span>
          </mat-list-item>
        </mat-list>
      </mat-grid-tile>
    </div>
  </mat-grid-list>

  <!-- Privacy -->
  <mat-grid-list cols="1" rows="1" rowHeight="400">
    <mat-grid-tile colspan="1">
      <mat-grid-tile-header>Privacy</mat-grid-tile-header>
      <mat-list>
        <mat-list-item lines="3">
          <span matListItemTitle>Your data is secure</span>
          <span>The data you share with us is not accessible by employees. You have control over who can see it in the
            app.</span>
        </mat-list-item>
        <mat-list-item lines="3">
          <span matListItemTitle>Privacy controls</span>
          <span>By default we enable sharing of your activities and calendar with your followers. To change the settings
            visit profile page.</span>
        </mat-list-item>
      </mat-list>
    </mat-grid-tile>
  </mat-grid-list>
</div>
