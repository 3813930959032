/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/pubsub/notification.pb';
/**
 * Message implementation for shared.SocketRequest
 */
export class SocketRequest implements GrpcMessage {
  static id = 'shared.SocketRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SocketRequest();
    SocketRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SocketRequest) {
    _instance.sessionId = _instance.sessionId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SocketRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.sessionId = _reader.readString();
          break;
        case 2:
          _instance.connect = new SocketConnectRequest();
          _reader.readMessage(
            _instance.connect,
            SocketConnectRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SocketRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SocketRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.sessionId) {
      _writer.writeString(1, _instance.sessionId);
    }
    if (_instance.connect) {
      _writer.writeMessage(
        2,
        _instance.connect as any,
        SocketConnectRequest.serializeBinaryToWriter
      );
    }
  }

  private _sessionId: string;
  private _connect?: SocketConnectRequest;

  private _request: SocketRequest.RequestCase = SocketRequest.RequestCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SocketRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SocketRequest.AsObject>) {
    _value = _value || {};
    this.sessionId = _value.sessionId;
    this.connect = _value.connect
      ? new SocketConnectRequest(_value.connect)
      : undefined;
    SocketRequest.refineValues(this);
  }
  get sessionId(): string {
    return this._sessionId;
  }
  set sessionId(value: string) {
    this._sessionId = value;
  }
  get connect(): SocketConnectRequest | undefined {
    return this._connect;
  }
  set connect(value: SocketConnectRequest | undefined) {
    if (value !== undefined && value !== null) {
      this._request = SocketRequest.RequestCase.connect;
    }
    this._connect = value;
  }
  get request() {
    return this._request;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SocketRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SocketRequest.AsObject {
    return {
      sessionId: this.sessionId,
      connect: this.connect ? this.connect.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SocketRequest.AsProtobufJSON {
    return {
      sessionId: this.sessionId,
      connect: this.connect ? this.connect.toProtobufJSON(options) : null
    };
  }
}
export module SocketRequest {
  /**
   * Standard JavaScript object representation for SocketRequest
   */
  export interface AsObject {
    sessionId: string;
    connect?: SocketConnectRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for SocketRequest
   */
  export interface AsProtobufJSON {
    sessionId: string;
    connect: SocketConnectRequest.AsProtobufJSON | null;
  }
  export enum RequestCase {
    none = 0,
    connect = 1
  }
}

/**
 * Message implementation for shared.SocketConnectRequest
 */
export class SocketConnectRequest implements GrpcMessage {
  static id = 'shared.SocketConnectRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SocketConnectRequest();
    SocketConnectRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SocketConnectRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SocketConnectRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    SocketConnectRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SocketConnectRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SocketConnectRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SocketConnectRequest.AsObject>) {
    _value = _value || {};
    SocketConnectRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SocketConnectRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SocketConnectRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SocketConnectRequest.AsProtobufJSON {
    return {};
  }
}
export module SocketConnectRequest {
  /**
   * Standard JavaScript object representation for SocketConnectRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for SocketConnectRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for shared.SocketResponse
 */
export class SocketResponse implements GrpcMessage {
  static id = 'shared.SocketResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SocketResponse();
    SocketResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SocketResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SocketResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.connect = new SocketConnectResponse();
          _reader.readMessage(
            _instance.connect,
            SocketConnectResponse.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.notification = new SocketNotificationResponse();
          _reader.readMessage(
            _instance.notification,
            SocketNotificationResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SocketResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SocketResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.connect) {
      _writer.writeMessage(
        1,
        _instance.connect as any,
        SocketConnectResponse.serializeBinaryToWriter
      );
    }
    if (_instance.notification) {
      _writer.writeMessage(
        2,
        _instance.notification as any,
        SocketNotificationResponse.serializeBinaryToWriter
      );
    }
  }

  private _connect?: SocketConnectResponse;
  private _notification?: SocketNotificationResponse;

  private _response: SocketResponse.ResponseCase =
    SocketResponse.ResponseCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SocketResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SocketResponse.AsObject>) {
    _value = _value || {};
    this.connect = _value.connect
      ? new SocketConnectResponse(_value.connect)
      : undefined;
    this.notification = _value.notification
      ? new SocketNotificationResponse(_value.notification)
      : undefined;
    SocketResponse.refineValues(this);
  }
  get connect(): SocketConnectResponse | undefined {
    return this._connect;
  }
  set connect(value: SocketConnectResponse | undefined) {
    if (value !== undefined && value !== null) {
      this._notification = undefined;
      this._response = SocketResponse.ResponseCase.connect;
    }
    this._connect = value;
  }
  get notification(): SocketNotificationResponse | undefined {
    return this._notification;
  }
  set notification(value: SocketNotificationResponse | undefined) {
    if (value !== undefined && value !== null) {
      this._connect = undefined;
      this._response = SocketResponse.ResponseCase.notification;
    }
    this._notification = value;
  }
  get response() {
    return this._response;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SocketResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SocketResponse.AsObject {
    return {
      connect: this.connect ? this.connect.toObject() : undefined,
      notification: this.notification ? this.notification.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SocketResponse.AsProtobufJSON {
    return {
      connect: this.connect ? this.connect.toProtobufJSON(options) : null,
      notification: this.notification
        ? this.notification.toProtobufJSON(options)
        : null
    };
  }
}
export module SocketResponse {
  /**
   * Standard JavaScript object representation for SocketResponse
   */
  export interface AsObject {
    connect?: SocketConnectResponse.AsObject;
    notification?: SocketNotificationResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for SocketResponse
   */
  export interface AsProtobufJSON {
    connect: SocketConnectResponse.AsProtobufJSON | null;
    notification: SocketNotificationResponse.AsProtobufJSON | null;
  }
  export enum ResponseCase {
    none = 0,
    connect = 1,
    notification = 2
  }
}

/**
 * Message implementation for shared.SocketConnectResponse
 */
export class SocketConnectResponse implements GrpcMessage {
  static id = 'shared.SocketConnectResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SocketConnectResponse();
    SocketConnectResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SocketConnectResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SocketConnectResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    SocketConnectResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SocketConnectResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SocketConnectResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SocketConnectResponse.AsObject>) {
    _value = _value || {};
    SocketConnectResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SocketConnectResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SocketConnectResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SocketConnectResponse.AsProtobufJSON {
    return {};
  }
}
export module SocketConnectResponse {
  /**
   * Standard JavaScript object representation for SocketConnectResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for SocketConnectResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for shared.SocketNotificationResponse
 */
export class SocketNotificationResponse implements GrpcMessage {
  static id = 'shared.SocketNotificationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SocketNotificationResponse();
    SocketNotificationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SocketNotificationResponse) {
    _instance.notification = _instance.notification || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SocketNotificationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.notification = new shared001.PubsubNotification.Notification();
          _reader.readMessage(
            _instance.notification,
            shared001.PubsubNotification.Notification
              .deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SocketNotificationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SocketNotificationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.notification) {
      _writer.writeMessage(
        1,
        _instance.notification as any,
        shared001.PubsubNotification.Notification.serializeBinaryToWriter
      );
    }
  }

  private _notification?: shared001.PubsubNotification.Notification;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SocketNotificationResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SocketNotificationResponse.AsObject>) {
    _value = _value || {};
    this.notification = _value.notification
      ? new shared001.PubsubNotification.Notification(_value.notification)
      : undefined;
    SocketNotificationResponse.refineValues(this);
  }
  get notification(): shared001.PubsubNotification.Notification | undefined {
    return this._notification;
  }
  set notification(
    value: shared001.PubsubNotification.Notification | undefined
  ) {
    this._notification = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SocketNotificationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SocketNotificationResponse.AsObject {
    return {
      notification: this.notification ? this.notification.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SocketNotificationResponse.AsProtobufJSON {
    return {
      notification: this.notification
        ? this.notification.toProtobufJSON(options)
        : null
    };
  }
}
export module SocketNotificationResponse {
  /**
   * Standard JavaScript object representation for SocketNotificationResponse
   */
  export interface AsObject {
    notification?: shared001.PubsubNotification.Notification.AsObject;
  }

  /**
   * Protobuf JSON representation for SocketNotificationResponse
   */
  export interface AsProtobufJSON {
    notification: shared001.PubsubNotification.Notification.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.NotificationContainer
 */
export class NotificationContainer implements GrpcMessage {
  static id = 'shared.NotificationContainer';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new NotificationContainer();
    NotificationContainer.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: NotificationContainer) {
    _instance.notificationId = _instance.notificationId || '0';
    _instance.time = _instance.time || undefined;
    _instance.seenByUser = _instance.seenByUser || false;
    _instance.notification = _instance.notification || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: NotificationContainer,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.notificationId = _reader.readInt64String();
          break;
        case 2:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.seenByUser = _reader.readBool();
          break;
        case 4:
          _instance.notification = new shared001.PubsubNotification.Notification();
          _reader.readMessage(
            _instance.notification,
            shared001.PubsubNotification.Notification
              .deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    NotificationContainer.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: NotificationContainer,
    _writer: BinaryWriter
  ) {
    if (_instance.notificationId) {
      _writer.writeInt64String(1, _instance.notificationId);
    }
    if (_instance.time) {
      _writer.writeMessage(
        2,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.seenByUser) {
      _writer.writeBool(3, _instance.seenByUser);
    }
    if (_instance.notification) {
      _writer.writeMessage(
        4,
        _instance.notification as any,
        shared001.PubsubNotification.Notification.serializeBinaryToWriter
      );
    }
  }

  private _notificationId: string;
  private _time?: googleProtobuf000.Timestamp;
  private _seenByUser: boolean;
  private _notification?: shared001.PubsubNotification.Notification;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of NotificationContainer to deeply clone from
   */
  constructor(_value?: RecursivePartial<NotificationContainer.AsObject>) {
    _value = _value || {};
    this.notificationId = _value.notificationId;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    this.seenByUser = _value.seenByUser;
    this.notification = _value.notification
      ? new shared001.PubsubNotification.Notification(_value.notification)
      : undefined;
    NotificationContainer.refineValues(this);
  }
  get notificationId(): string {
    return this._notificationId;
  }
  set notificationId(value: string) {
    this._notificationId = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }
  get seenByUser(): boolean {
    return this._seenByUser;
  }
  set seenByUser(value: boolean) {
    this._seenByUser = value;
  }
  get notification(): shared001.PubsubNotification.Notification | undefined {
    return this._notification;
  }
  set notification(
    value: shared001.PubsubNotification.Notification | undefined
  ) {
    this._notification = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    NotificationContainer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): NotificationContainer.AsObject {
    return {
      notificationId: this.notificationId,
      time: this.time ? this.time.toObject() : undefined,
      seenByUser: this.seenByUser,
      notification: this.notification ? this.notification.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): NotificationContainer.AsProtobufJSON {
    return {
      notificationId: this.notificationId,
      time: this.time ? this.time.toProtobufJSON(options) : null,
      seenByUser: this.seenByUser,
      notification: this.notification
        ? this.notification.toProtobufJSON(options)
        : null
    };
  }
}
export module NotificationContainer {
  /**
   * Standard JavaScript object representation for NotificationContainer
   */
  export interface AsObject {
    notificationId: string;
    time?: googleProtobuf000.Timestamp.AsObject;
    seenByUser: boolean;
    notification?: shared001.PubsubNotification.Notification.AsObject;
  }

  /**
   * Protobuf JSON representation for NotificationContainer
   */
  export interface AsProtobufJSON {
    notificationId: string;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    seenByUser: boolean;
    notification: shared001.PubsubNotification.Notification.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.NotificationControls
 */
export class NotificationControls implements GrpcMessage {
  static id = 'shared.NotificationControls';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new NotificationControls();
    NotificationControls.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: NotificationControls) {
    _instance.wasSet = _instance.wasSet || false;
    _instance.activities = _instance.activities || undefined;
    _instance.newFollowerRequests = _instance.newFollowerRequests || undefined;
    _instance.newFollowing = _instance.newFollowing || undefined;
    _instance.newReaction = _instance.newReaction || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: NotificationControls,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.wasSet = _reader.readBool();
          break;
        case 2:
          _instance.activities = new NotificationControl();
          _reader.readMessage(
            _instance.activities,
            NotificationControl.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.newFollowerRequests = new NotificationControl();
          _reader.readMessage(
            _instance.newFollowerRequests,
            NotificationControl.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.newFollowing = new NotificationControl();
          _reader.readMessage(
            _instance.newFollowing,
            NotificationControl.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.newReaction = new NotificationControl();
          _reader.readMessage(
            _instance.newReaction,
            NotificationControl.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    NotificationControls.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: NotificationControls,
    _writer: BinaryWriter
  ) {
    if (_instance.wasSet) {
      _writer.writeBool(1, _instance.wasSet);
    }
    if (_instance.activities) {
      _writer.writeMessage(
        2,
        _instance.activities as any,
        NotificationControl.serializeBinaryToWriter
      );
    }
    if (_instance.newFollowerRequests) {
      _writer.writeMessage(
        3,
        _instance.newFollowerRequests as any,
        NotificationControl.serializeBinaryToWriter
      );
    }
    if (_instance.newFollowing) {
      _writer.writeMessage(
        5,
        _instance.newFollowing as any,
        NotificationControl.serializeBinaryToWriter
      );
    }
    if (_instance.newReaction) {
      _writer.writeMessage(
        6,
        _instance.newReaction as any,
        NotificationControl.serializeBinaryToWriter
      );
    }
  }

  private _wasSet: boolean;
  private _activities?: NotificationControl;
  private _newFollowerRequests?: NotificationControl;
  private _newFollowing?: NotificationControl;
  private _newReaction?: NotificationControl;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of NotificationControls to deeply clone from
   */
  constructor(_value?: RecursivePartial<NotificationControls.AsObject>) {
    _value = _value || {};
    this.wasSet = _value.wasSet;
    this.activities = _value.activities
      ? new NotificationControl(_value.activities)
      : undefined;
    this.newFollowerRequests = _value.newFollowerRequests
      ? new NotificationControl(_value.newFollowerRequests)
      : undefined;
    this.newFollowing = _value.newFollowing
      ? new NotificationControl(_value.newFollowing)
      : undefined;
    this.newReaction = _value.newReaction
      ? new NotificationControl(_value.newReaction)
      : undefined;
    NotificationControls.refineValues(this);
  }
  get wasSet(): boolean {
    return this._wasSet;
  }
  set wasSet(value: boolean) {
    this._wasSet = value;
  }
  get activities(): NotificationControl | undefined {
    return this._activities;
  }
  set activities(value: NotificationControl | undefined) {
    this._activities = value;
  }
  get newFollowerRequests(): NotificationControl | undefined {
    return this._newFollowerRequests;
  }
  set newFollowerRequests(value: NotificationControl | undefined) {
    this._newFollowerRequests = value;
  }
  get newFollowing(): NotificationControl | undefined {
    return this._newFollowing;
  }
  set newFollowing(value: NotificationControl | undefined) {
    this._newFollowing = value;
  }
  get newReaction(): NotificationControl | undefined {
    return this._newReaction;
  }
  set newReaction(value: NotificationControl | undefined) {
    this._newReaction = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    NotificationControls.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): NotificationControls.AsObject {
    return {
      wasSet: this.wasSet,
      activities: this.activities ? this.activities.toObject() : undefined,
      newFollowerRequests: this.newFollowerRequests
        ? this.newFollowerRequests.toObject()
        : undefined,
      newFollowing: this.newFollowing
        ? this.newFollowing.toObject()
        : undefined,
      newReaction: this.newReaction ? this.newReaction.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): NotificationControls.AsProtobufJSON {
    return {
      wasSet: this.wasSet,
      activities: this.activities
        ? this.activities.toProtobufJSON(options)
        : null,
      newFollowerRequests: this.newFollowerRequests
        ? this.newFollowerRequests.toProtobufJSON(options)
        : null,
      newFollowing: this.newFollowing
        ? this.newFollowing.toProtobufJSON(options)
        : null,
      newReaction: this.newReaction
        ? this.newReaction.toProtobufJSON(options)
        : null
    };
  }
}
export module NotificationControls {
  /**
   * Standard JavaScript object representation for NotificationControls
   */
  export interface AsObject {
    wasSet: boolean;
    activities?: NotificationControl.AsObject;
    newFollowerRequests?: NotificationControl.AsObject;
    newFollowing?: NotificationControl.AsObject;
    newReaction?: NotificationControl.AsObject;
  }

  /**
   * Protobuf JSON representation for NotificationControls
   */
  export interface AsProtobufJSON {
    wasSet: boolean;
    activities: NotificationControl.AsProtobufJSON | null;
    newFollowerRequests: NotificationControl.AsProtobufJSON | null;
    newFollowing: NotificationControl.AsProtobufJSON | null;
    newReaction: NotificationControl.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.NotificationControl
 */
export class NotificationControl implements GrpcMessage {
  static id = 'shared.NotificationControl';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new NotificationControl();
    NotificationControl.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: NotificationControl) {
    _instance.disabled = _instance.disabled || false;
    _instance.webEnabled = _instance.webEnabled || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: NotificationControl,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.disabled = _reader.readBool();
          break;
        case 2:
          _instance.webEnabled = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    NotificationControl.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: NotificationControl,
    _writer: BinaryWriter
  ) {
    if (_instance.disabled) {
      _writer.writeBool(1, _instance.disabled);
    }
    if (_instance.webEnabled) {
      _writer.writeBool(2, _instance.webEnabled);
    }
  }

  private _disabled: boolean;
  private _webEnabled: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of NotificationControl to deeply clone from
   */
  constructor(_value?: RecursivePartial<NotificationControl.AsObject>) {
    _value = _value || {};
    this.disabled = _value.disabled;
    this.webEnabled = _value.webEnabled;
    NotificationControl.refineValues(this);
  }
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  get webEnabled(): boolean {
    return this._webEnabled;
  }
  set webEnabled(value: boolean) {
    this._webEnabled = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    NotificationControl.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): NotificationControl.AsObject {
    return {
      disabled: this.disabled,
      webEnabled: this.webEnabled
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): NotificationControl.AsProtobufJSON {
    return {
      disabled: this.disabled,
      webEnabled: this.webEnabled
    };
  }
}
export module NotificationControl {
  /**
   * Standard JavaScript object representation for NotificationControl
   */
  export interface AsObject {
    disabled: boolean;
    webEnabled: boolean;
  }

  /**
   * Protobuf JSON representation for NotificationControl
   */
  export interface AsProtobufJSON {
    disabled: boolean;
    webEnabled: boolean;
  }
}
