import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  FollowRequest,
  FollowStatus,
  Follower,
} from 'generated/src/main/proto/api/follow-service.pb';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import { FollowService } from '../services/follow/follow.service';
import { FormatService } from '../services/format.service';
import { FollowRequestDataSource } from './follow-request.datasource';
import { FollowDataSource } from './follow.datasource';
import { PeopleDataSource } from './people.datasource';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements AfterViewInit {
  readonly followerDisplayColumns = ['name', 'alias', 'remove'];
  readonly followingDisplayColumns = ['name', 'alias', 'unfollow'];
  readonly followRequestDisplayColumns = [
    'name',
    'alias',
    'message',
    'date',
    'decision',
  ];
  readonly searchDisplayColumns = ['name', 'alias', 'location', 'follow'];
  readonly margin: number = 7;
  readonly innerWidth: number;

  @ViewChild('followerPaginator') followerPaginator!: MatPaginator;
  @ViewChild('followingPaginator') followingPaginator!: MatPaginator;
  @ViewChild('requestsPaginator') followRequestPaginator!: MatPaginator;
  @ViewChild('peoplePaginator') peoplePaginator!: MatPaginator;

  followerDataSource: FollowDataSource;
  followingDataSource: FollowDataSource;
  followRequestDataSource: FollowRequestDataSource;
  peopleDataSource: PeopleDataSource;

  followersHeader = 'Followers';
  followingHeader = 'Following';
  requestsHeader = 'Requests';

  searchQuery = '';
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  private viewInitPromise = new Promise<boolean>((resolve) => {
    const timetId = setInterval(() => {
      if (
        this.followerPaginator &&
        this.followingPaginator &&
        this.followRequestPaginator &&
        this.peoplePaginator
      ) {
        resolve(true);
        clearInterval(timetId);
      }
    }, 10);
  });

  constructor(
    private followService: FollowService,
    private formatService: FormatService,
    private dialog: MatDialog,
  ) {
    this.innerWidth = formatService.viewWidth - 2 * this.margin;

    this.followerDataSource = new FollowDataSource(false, followService);
    this.followingDataSource = new FollowDataSource(true, followService);
    this.followRequestDataSource = new FollowRequestDataSource(followService);
    this.peopleDataSource = new PeopleDataSource(followService);
    this.updateStats();
  }

  ngAfterViewInit(): void {
    this.followerDataSource.setPaginator(this.followerPaginator);
    this.followingDataSource.setPaginator(this.followingPaginator);
    this.followRequestDataSource.setPaginator(this.followRequestPaginator);
    this.peopleDataSource.setPaginator(this.peoplePaginator);
  }

  updateStats(): void {
    const statsPromise = this.followService.getStats();
    Promise.all([statsPromise, this.viewInitPromise]).then((pair) => {
      const r = pair[0];

      this.followersHeader = `Followers - ${r.followersCount}`;
      this.followingHeader = `Following - ${r.followingCount}`;
      this.requestsHeader = `Requests - ${r.followRequestCount}`;

      this.followerPaginator.length = r.followersCount;
      this.followingPaginator.length = r.followingCount;
      this.followRequestPaginator.length = r.followRequestCount;
    });
  }

  removeFollower(alias: string) {
    ConfirmationComponent.openDialog(
      this.dialog,
      `Remove follower '${alias}?'`,
      (result) => {
        if (result) {
          this.followService.removeFollower(alias).then(() => {
            this.updateStats();
            this.followerDataSource.reload();
          });
        }
      },
    );
  }

  unfollow(alias: string) {
    ConfirmationComponent.openDialog(
      this.dialog,
      `Unfollow '${alias}?'`,
      (result) => {
        if (result) {
          this.followService.unfollow(alias).then(() => {
            this.updateStats();
            this.followingDataSource.reload();
          });
        }
      },
    );
  }

  formatName(value: Follower | FollowRequest): string {
    return value.firstName + ' ' + value.lastName;
  }

  formatDate(timestamp: Timestamp): string {
    return this.formatService.formatDate(timestamp);
  }

  acceptRequest(alias: string) {
    this.followService.resolveFollowRequest(alias, true).then(() => {
      this.updateStats();
      this.followRequestDataSource.reload();
      this.followerDataSource.reload();
    });
  }

  rejectRequest(alias: string) {
    ConfirmationComponent.openDialog(
      this.dialog,
      `Reject '${alias}?'`,
      (result) => {
        if (result) {
          this.followService.resolveFollowRequest(alias, false).then(() => {
            this.updateStats();
            this.followRequestDataSource.reload();
          });
        }
      },
    );
  }

  search(): void {
    this.peopleDataSource.search(this.searchQuery);
  }

  follow(alias: string) {
    this.followService
      .requestToFollow(alias)
      .then(() => this.peopleDataSource.search(this.searchQuery));
  }

  wasRejected(followStatus: FollowStatus): boolean {
    return followStatus == FollowStatus.FOLLOW_STATUS_REQUEST_REJECTED;
  }

  followButtonText(followStatus: FollowStatus): string {
    if (followStatus == FollowStatus.FOLLOW_STATUS_ALREADY_FOLLOWING) {
      return 'Following';
    }
    if (followStatus == FollowStatus.FOLLOW_STATUS_REQUESTED) {
      return 'Requested';
    }
    return 'Follow';
  }

  followButtonDisabled(followStatus: FollowStatus): boolean {
    return (
      followStatus == FollowStatus.FOLLOW_STATUS_ALREADY_FOLLOWING ||
      followStatus == FollowStatus.FOLLOW_STATUS_REQUESTED
    );
  }

  onSearchInputKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.search();
    } else if (e.key == 'Escape') {
      this.searchQuery = '';
    }
  }

  onSearchInputClearClick() {
    this.searchQuery = '';
    this.searchInput.nativeElement.focus();
  }
}
