import { Injectable } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  FindToFollowRequest,
  FindToFollowResponse,
  GetFollowRequestsRequest,
  GetFollowRequestsResponse,
  GetFollowersRequest,
  GetFollowersResponse,
  GetFollowingRequest,
  GetFollowingResponse,
  GetStatsRequest,
  GetStatsResponse,
  RemoveFollowerRequest,
  RequestToFollowRequest,
  ResolveFollowRequestRequest,
  UnfollowRequest,
} from 'generated/src/main/proto/api/follow-service.pb';
import { FollowServiceClient } from 'generated/src/main/proto/api/follow-service.pbsc';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

/** Discovers users to follow, requests to follow, following stats. */
@Injectable({
  providedIn: 'root',
})
export class FollowService {
  constructor(
    private userService: UserService,
    private bannerService: BannerService,
    private followServiceClient: FollowServiceClient,
  ) {}

  /** Finds candidates to follow. */
  async findToFollow(
    query: string,
    start: number,
    count: number,
    readTime: Timestamp | undefined,
  ): Promise<FindToFollowResponse> {
    const request = new FindToFollowRequest({
      queryText: query,
      startIndex: start,
      count: count,
    });
    if (readTime) {
      request.readTime = readTime;
    }
    return lastValueFrom(
      this.followServiceClient
        .findToFollow(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Posts request to follow. */
  async requestToFollow(alias: string): Promise<boolean> {
    return lastValueFrom(
      this.followServiceClient
        .requestToFollow(
          new RequestToFollowRequest({ alias: alias }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then(() => true);
  }

  /** Get follow requests. */
  async getFollowRequests(
    startIndex: number,
    count: number,
    readTime: Timestamp | undefined,
  ): Promise<GetFollowRequestsResponse> {
    return lastValueFrom(
      this.followServiceClient
        .getFollowRequests(
          new GetFollowRequestsRequest({
            startIndex: startIndex,
            count: count,
            readTime: readTime,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Get followers. */
  async getFollowers(
    startIndex: number,
    count: number,
    readTime: Timestamp | undefined,
  ): Promise<GetFollowersResponse> {
    return lastValueFrom(
      this.followServiceClient
        .getFollowers(
          new GetFollowersRequest({
            startIndex: startIndex,
            count: count,
            readTime: readTime,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Get following. */
  async getFollowing(
    startIndex: number,
    count: number,
    readTime: Timestamp | undefined,
  ): Promise<GetFollowingResponse> {
    return lastValueFrom(
      this.followServiceClient
        .getFollowing(
          new GetFollowingRequest({
            startIndex: startIndex,
            count: count,
            readTime: readTime,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Remove follower. */
  async removeFollower(alias: string): Promise<boolean> {
    return lastValueFrom(
      this.followServiceClient
        .removeFollower(
          new RemoveFollowerRequest({
            alias: alias,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then(() => true);
  }

  /** Unfollow. */
  async unfollow(alias: string): Promise<boolean> {
    return lastValueFrom(
      this.followServiceClient
        .unfollow(
          new UnfollowRequest({
            alias: alias,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then(() => true);
  }

  /** Resolve follow request. */
  async resolveFollowRequest(
    followerAlias: string,
    approved: boolean,
  ): Promise<boolean> {
    return lastValueFrom(
      this.followServiceClient
        .resolveFollowRequest(
          new ResolveFollowRequestRequest({
            followerAlias: followerAlias,
            approved: approved,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then(() => true);
  }

  /** Returns follow stats. */
  async getStats(): Promise<GetStatsResponse> {
    return lastValueFrom(
      this.followServiceClient
        .getStats(new GetStatsRequest(), this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
