import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DirectivesModule } from '../../directives/directives.module';
import { EditOptionComponent } from './edit-option.component';

@NgModule({
  declarations: [EditOptionComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  exports: [EditOptionComponent],
})
export class EditOptionModule {}
