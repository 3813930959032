import { DataSource } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { EChartsOption } from 'echarts';
import {
  GetStatsResponse,
  StatsResponse,
  Summary,
} from 'generated/src/main/proto/api/activity-service.pb';
import {
  ActivityType,
  HeartRateZoneStats,
  IntervalStats,
  StatsGranularity,
  StatsRequestTimeInterval,
} from 'generated/src/main/proto/shared/activity-shared.pb';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { SeriesType } from '../common/charts/types';
import { UserFilter } from '../common/user-filter/user-filter';
import { ActivitiesService } from '../services/activities/activities.service';
import {
  FormatService,
  OptionalNumber,
  Unit,
} from '../services/format.service';
import { StateSaverService } from '../services/state-saver/state-saver.service';
import { UserService } from '../services/user/user.service';
import { WaitPromise } from './wait-promise';

/** DataSource for loading activity summaries. */
class SummariesDataSource implements DataSource<Summary> {
  private summariesSubject = new BehaviorSubject<readonly Summary[]>([]);
  private activityIds?: string[];
  private paginator?: MatPaginator;

  constructor(private activitiesService: ActivitiesService) {}

  setActivityIds(activityIds: string[], pageIndex?: number): Promise<boolean> {
    activityIds = Array.from(activityIds);
    this.activityIds = activityIds.sort((a, b) => parseInt(a) - parseInt(b));
    return this.startLoading(pageIndex);
  }

  setPaginator(paginator: MatPaginator): void {
    this.paginator = paginator;

    // Subscribe to requests for subsequent pages.
    paginator.page.subscribe((pageEvent) =>
      this.load(pageEvent.pageIndex * pageEvent.pageSize, pageEvent.pageSize),
    );
  }

  connect(): Observable<readonly Summary[]> {
    return this.summariesSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  private startLoading(pageIndex?: number): Promise<boolean> {
    if (this.paginator) {
      this.paginator.length = this.activityIds?.length ?? 0;
      this.paginator.pageIndex = pageIndex ?? 0;
    }

    // Load initial page.
    if (this.activityIds && this.paginator) {
      const index = this.paginator.pageIndex * this.paginator.pageSize;
      return this.load(
        this.paginator.pageIndex * this.paginator.pageSize,
        Math.min(this.activityIds.length - index, this.paginator.pageSize),
      );
    } else {
      return Promise.resolve(false);
    }
  }

  private load(index: number, count: number): Promise<boolean> {
    let result: Promise<boolean> = Promise.resolve(false);
    if (this.activityIds) {
      const end = Math.min(this.activityIds.length, index + count);
      if (index < end) {
        result = this.activitiesService
          .getSummaries(this.activityIds.slice(index, end))
          .catch()
          .then((s) => {
            this.summariesSubject.next(s);
            return true;
          });
      }
    }
    return result;
  }
}

/** Shows weekly, monthly, yearly user stats.  */
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements AfterViewInit, OnDestroy, OnInit {
  private static readonly ALL_ACTIVITY_TYPES = 'All Activities';
  private static readonly DEFAULT_INTERVAL_STATS = new IntervalStats();

  granularity = 'Year';
  readonly granularities = ['Year', 'Month', 'Week'];
  private currentGranularity = StatsGranularity.STATS_GRANULARITY_YEAR;

  readonly dateFormat: string;
  endDate = new Date();

  width: number;
  margin: number;
  innerWidth: number;

  userFilter!: UserFilter;
  firstUserLoggedIn = false;

  private loggedInAlias!: string;
  private urlAlias!: string;
  statsResponse?: GetStatsResponse;
  initialLoadDone = false;
  initialLoadDonePromise = WaitPromise.create(15000, () => {
    return this.initialLoadDone;
  });

  seriesTypes = [
    SeriesType.NumActivities,
    SeriesType.Distance,
    SeriesType.ElevationGain,
    SeriesType.ElevationDrop,
    SeriesType.Calories,
    SeriesType.AverageSpeed,
    SeriesType.MilePace,
    SeriesType.KilometerPace,
    SeriesType.AverageHeartRate,
    SeriesType.MovingTime,
  ];

  activityTypes: string[] = [StatsComponent.ALL_ACTIVITY_TYPES];
  activityType = StatsComponent.ALL_ACTIVITY_TYPES;
  seriesTypeLeft = SeriesType.Distance;
  seriesTypeRight = SeriesType.NumActivities;
  chartOptions: EChartsOption = {};
  // eslint-disable-next-line
  chart: any;
  statsResponses: StatsResponse[] = [];
  lastClickedIndex?: number;

  hrZones?: { [prop: number]: HeartRateZoneStats };

  summariesDataSource: SummariesDataSource;
  @ViewChild('summariesPaginator', { read: MatPaginator })
  summariesPaginator!: MatPaginator;
  readonly summariesColumns = ['title', 'start', 'type', 'distance'];

  constructor(
    private componentElementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private activitiesService: ActivitiesService,
    private formatService: FormatService,
    private stateSaverService: StateSaverService,
    private location: Location,
    private userService: UserService,
  ) {
    this.width = this.formatService.viewWidth;
    this.margin = 7;
    this.innerWidth = this.width - 2 * this.margin;
    this.summariesDataSource = new SummariesDataSource(activitiesService);
    this.dateFormat = formatService.getDateDisplayFormat();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.urlAlias = params.get('alias')!;

      // Wait for first user.
      this.userService.user.subscribe((user) => {
        if (user?.alias && !this.firstUserLoggedIn) {
          this.firstUserLoggedIn = true;

          this.loggedInAlias = user.alias;

          this.userFilter = UserFilter.fromAlias(
            this.loggedInAlias,
            this.urlAlias,
          );

          // Restore query state.
          this.restoreQueryState();

          // Start loading stats.
          this.loadStats();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.saveState();
  }

  ngAfterViewInit(): void {
    // Wait for initial load to complete.
    this.initialLoadDonePromise.then((r: boolean) => {
      if (r) {
        // Set paginator.
        this.summariesDataSource.setPaginator(this.summariesPaginator);

        // Restore UI state.
        this.restoreUIState();
      }
    });
  }

  onUserFilterChange(userFilter: UserFilter) {
    if (!userFilter) {
      return;
    }
    userFilter.updateLocation(
      this.location,
      '/stats',
      userFilter.loggedInUser ? this.loggedInAlias : userFilter.followingAlias,
    );
    this.loadStats();
  }

  private saveState(): void {
    const state = new Map<string, string | number | Date>();

    // Alias.
    state.set('urlAlias', this.urlAlias);

    // Query state.
    state.set('granularity', this.granularity);
    state.set('endDate', this.endDate);

    // UI state.
    state.set('activityType', this.activityType);
    if (this.lastClickedIndex != undefined) {
      state.set('clickedIndex', this.lastClickedIndex);
    }

    // Table paginator.
    state.set('pageSize', this.summariesPaginator?.pageSize);
    state.set('pageIndex', this.summariesPaginator?.pageIndex);

    // TODO: consider using scroll position after table is populated.
    const scroll = {
      x: this.componentElementRef.nativeElement.scrollLeft,
      y: this.componentElementRef.nativeElement.scrollTop,
    };
    this.stateSaverService.set('stats', {
      scrollPosition: scroll,
      state: state,
    });
  }

  /** Restores members that are used by loadStats. */
  private restoreQueryState(): void {
    const savedState = this.stateSaverService.get('stats');
    const state = savedState.state;
    if (state && this.urlAlias == state.get('urlAlias')) {
      this.granularity = state.get('granularity') as string;
      this.currentGranularity = StatsComponent.granularityStringToEnum(
        this.granularity,
      );
      this.endDate = state.get('endDate') as Date;
    }
  }

  /** Restores UI state. */
  private restoreUIState(): void {
    const savedState = this.stateSaverService.get('stats');
    const state = savedState.state;
    if (state && this.urlAlias == state.get('urlAlias')) {
      this.activityType = state.get('activityType') as string;
      this.lastClickedIndex = state.get('clickedIndex') as number;

      this.initHrZonesChart();

      // Load summaries.
      const pageIndex = state.get('pageIndex') as number;
      this.summariesPaginator.pageSize = state.get('pageSize') as number;
      const summariesLoadPromise = this.setActivityIds(pageIndex);

      // Restore scroll position.
      if (savedState.scrollPosition) {
        // Wait for summaries to be loaded.
        summariesLoadPromise.then(() => {
          // Wait one cycle for the summaries to be rendered.
          timer(1).subscribe(() => {
            this.componentElementRef.nativeElement.scrollLeft =
              savedState.scrollPosition!.x;
            this.componentElementRef.nativeElement.scrollTop =
              savedState.scrollPosition!.y;
          });
        });
      }
    }
  }

  onGranularitySelected(): void {
    const newGranularity = StatsComponent.granularityStringToEnum(
      this.granularity,
    );
    if (newGranularity != this.currentGranularity) {
      this.currentGranularity = newGranularity;
      this.loadStats();
    }
  }

  onEndDateChange(): void {
    this.loadStats();
  }

  formatDate(timestamp: Timestamp): string {
    return this.formatService.formatDate(timestamp);
  }

  formatType(activtyType: ActivityType): string {
    return FormatService.toDisplay(activtyType);
  }

  formatDistance(distance: number): string {
    const withUnit = this.formatService.formatDistance(distance).text;
    return withUnit.split(' ')[0];
  }

  formatDistanceTitle(): string {
    return 'Distance, ' + this.formatService.distanceUnit;
  }

  onSummaryTitleClick(summary: Summary) {
    this.navigateTo('/activity/' + summary.activityId);
  }

  onSummaryTitleKeyDown(e: KeyboardEvent, summary: Summary) {
    if (e.key == 'Enter') {
      this.navigateTo('/activity/' + summary.activityId);
    }
  }

  private navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  // eslint-disable-next-line
  onChartInit(e: any) {
    this.chart = e;
  }

  onChartYAxisSelected() {
    this.initChart();
  }

  onChartKeyDownInaccessible() {
    console.log('Mouse click not accessible for charts.');
  }

  onClick(e: MouseEvent) {
    this.lastClickedIndex = this.chart.convertFromPixel('grid', [
      e.offsetX,
      e.offsetY,
    ])[0];
    this.initHrZonesChart();
    this.setActivityIds();
  }

  private initHrZonesChart(): void {
    this.hrZones = undefined;

    const xIndex = this.lastClickedIndex;
    if (
      xIndex != undefined &&
      0 <= xIndex &&
      xIndex < this.statsResponses.length
    ) {
      const interValStats = StatsComponent.selectIntervalStats(
        this.statsResponses[xIndex],
        StatsComponent.convertActivtyType(this.activityType),
      );
      const hrZoneStats = interValStats.heartRateZoneStats;
      if (hrZoneStats && Object.keys(hrZoneStats).length > 0) {
        this.hrZones = hrZoneStats;
      }
    }
  }

  private setActivityIds(pageIndex?: number): Promise<boolean> {
    let result = Promise.resolve(false);
    const xIndex = this.lastClickedIndex;
    if (
      xIndex != undefined &&
      0 <= xIndex &&
      xIndex < this.statsResponses.length
    ) {
      const interValStats = StatsComponent.selectIntervalStats(
        this.statsResponses[xIndex],
        StatsComponent.convertActivtyType(this.activityType),
      );
      result = this.summariesDataSource.setActivityIds(
        interValStats.activityIds,
        pageIndex,
      );
    }
    return result;
  }

  private loadStats(): void {
    const alias = this.userFilter.followingAlias
      ? this.userFilter.followingAlias
      : this.loggedInAlias;
    const timeIntervals: StatsRequestTimeInterval[] = [];
    const endDate = this.endDate;

    switch (this.currentGranularity) {
      case StatsGranularity.STATS_GRANULARITY_YEAR:
        {
          // Current year + 4 previous years.
          const yearStart = new Date(endDate.getFullYear() - 4, 0, 1);
          timeIntervals.push(
            new StatsRequestTimeInterval({
              startTime: Timestamp.fromDate(yearStart),
              endTime: Timestamp.fromDate(endDate),
              granularity: StatsGranularity.STATS_GRANULARITY_YEAR,
            }),
          );
        }
        break;
      case StatsGranularity.STATS_GRANULARITY_MONTH:
        {
          // Last 12 months.
          const monthStart = new Date(
            endDate.getFullYear(),
            endDate.getMonth() - 12,
            1,
          );
          timeIntervals.push(
            new StatsRequestTimeInterval({
              startTime: Timestamp.fromDate(monthStart),
              endTime: Timestamp.fromDate(endDate),
              granularity: StatsGranularity.STATS_GRANULARITY_MONTH,
            }),
          );
        }
        break;
      case StatsGranularity.STATS_GRANULARITY_WEEK:
        {
          // Last 6 months.
          const weekStart = new Date(
            endDate.getFullYear(),
            endDate.getMonth() - 6,
            1,
          );
          timeIntervals.push(
            new StatsRequestTimeInterval({
              startTime: Timestamp.fromDate(weekStart),
              endTime: Timestamp.fromDate(endDate),
              granularity: StatsGranularity.STATS_GRANULARITY_WEEK,
            }),
          );
        }
        break;
    }

    this.activitiesService
      .getStats(timeIntervals, alias)
      .catch()
      .then((r) => {
        this.statsResponse = r;
        this.seriesTypes = this.initSeriesTypes();
        this.initActivityTypes();
        this.initChart();
        this.initialLoadDone = true;
      });
  }

  private initSeriesTypes(): SeriesType[] {
    const statsList = this.statsResponse!.stats;

    if (!statsList) {
      return [];
    }

    const heartRates = statsList.filter(
      (s) => s.totalStats?.heartRateStats?.mean,
    );
    const calories = statsList.filter((s) => s.totalStats?.calories);

    const seriesTypes = [
      SeriesType.NumActivities,
      SeriesType.Distance,
      SeriesType.ElevationGain,
      SeriesType.ElevationDrop,
      SeriesType.AverageSpeed,
      SeriesType.MilePace,
      SeriesType.KilometerPace,
      SeriesType.MovingTime,
    ];
    if (calories.length > 0) {
      seriesTypes.push(SeriesType.Calories);
    }
    if (heartRates.length > 0) {
      seriesTypes.push(SeriesType.AverageHeartRate);
    }
    const hrZones = statsList.filter((s) => s.totalStats?.heartRateZoneStats);
    if (hrZones.length > 0) {
      seriesTypes.push(SeriesType.TimeInZone1);
      seriesTypes.push(SeriesType.TimeInZone2);
      seriesTypes.push(SeriesType.TimeInZone3);
      seriesTypes.push(SeriesType.TimeInZone4);
      seriesTypes.push(SeriesType.TimeInZone5);
      seriesTypes.push(SeriesType.PercentTimeInZone1);
      seriesTypes.push(SeriesType.PercentTimeInZone2);
      seriesTypes.push(SeriesType.PercentTimeInZone3);
      seriesTypes.push(SeriesType.PercentTimeInZone4);
      seriesTypes.push(SeriesType.PercentTimeInZone5);
    }
    return seriesTypes;
  }

  private initActivityTypes(): void {
    this.activityTypes = StatsComponent.getDisplayActivityTypes(
      this.statsResponse!.stats!,
      this.currentGranularity,
    );
  }

  private static getDisplayActivityTypes(
    stats: StatsResponse[],
    granularity: StatsGranularity,
  ): string[] {
    return [StatsComponent.ALL_ACTIVITY_TYPES].concat(
      Array.from(
        new Set(
          stats
            ?.filter((s) => s.granularity == granularity)
            .flatMap((s) => s.statsByActivityType?.map((sa) => sa.activityType))
            .map((type) => FormatService.toDisplay(type!)),
        ),
      ).sort(),
    );
  }

  private initChart(): void {
    let chartOption: EChartsOption = {};
    this.statsResponses = [];
    if (this.statsResponse) {
      this.statsResponses = StatsComponent.selectStats(
        this.currentGranularity,
        this.statsResponse,
      );
      if (this.statsResponses) {
        let xLabelFormatter: (start: Timestamp, end: Timestamp) => string;
        switch (this.currentGranularity) {
          case StatsGranularity.STATS_GRANULARITY_YEAR:
            xLabelFormatter = StatsComponent.timeIntervalToYearString;
            break;
          case StatsGranularity.STATS_GRANULARITY_MONTH:
            xLabelFormatter = (s, e) => this.timeIntervalToMonthString(s, e);
            break;
          case StatsGranularity.STATS_GRANULARITY_WEEK:
            xLabelFormatter = (s, e) => this.timeIntervalToWeekString(s, e);
            break;
          default:
            throw 'Unexpected granularity: ' + this.currentGranularity;
        }
        chartOption = this.buildChartOptions(
          this.statsResponses,
          StatsComponent.convertActivtyType(this.activityType),
          [this.seriesTypeLeft, this.seriesTypeRight],
          xLabelFormatter,
        );
      }
    }
    this.chartOptions = chartOption;
    this.initHrZonesChart();
    this.setActivityIds();
  }

  private static selectStats(
    granularity: StatsGranularity,
    response: GetStatsResponse,
  ): StatsResponse[] {
    return response
      .stats!.filter((s) => s.granularity == granularity)
      .sort(
        (x, y) =>
          parseInt(x.startTime!.seconds) - parseInt(y.startTime!.seconds),
      );
  }

  private static convertActivtyType(
    displayActivityType: string,
  ): ActivityType | undefined {
    let activtyType: ActivityType | undefined;
    if (displayActivityType != StatsComponent.ALL_ACTIVITY_TYPES) {
      activtyType = FormatService.toApi(displayActivityType);
    }
    return activtyType;
  }

  private buildChartOptions(
    stats: StatsResponse[],
    activtyType: ActivityType | undefined,
    seriesTypes: SeriesType[],
    xLabelFormatter: (start: Timestamp, end: Timestamp) => string,
  ): EChartsOption {
    let chartOption: EChartsOption = {};
    if (stats && seriesTypes) {
      chartOption = {
        tooltip: {
          trigger: 'axis',
          // eslint-disable-next-line
          formatter: (params: any) => {
            const xLabel = params[0].name;
            // eslint-disable-next-line
            const yValues = params.map((p: any) => p.data);

            return (
              `<span>${xLabel}</span> <br/>` +
              seriesTypes
                .map(
                  (type, index) =>
                    `${type}: ${yValues[index]} ` +
                    this.getUnitName(type) +
                    `<br/>`,
                )
                .join('')
            );
          },
          axisPointer: {
            type: 'cross',
          },
        },
        grid: {
          containLabel: true,
          left: '2%',
          right: '2%',
          top: '5%',
          bottom: '5%',
        },
        xAxis: {
          type: 'category',
          data: stats.map((value) =>
            xLabelFormatter(value.startTime!, value.endTime!),
          ),
        },
        yAxis: seriesTypes.map((type, index) => {
          return {
            type: 'value',
            name: this.formatYName(type),
            nameLocation: 'end',
            nameTextStyle: {
              align: index == 0 ? 'left' : 'right',
            },
            position: index == 0 ? 'left' : 'right',
          };
        }),
        series: seriesTypes.map((type, index) => {
          return {
            data: this.getValues(type, stats!, activtyType),
            type: 'bar',
            yAxisIndex: index,
          };
        }),
      };
    }
    return chartOption;
  }

  private static timeIntervalToYearString(
    start: Timestamp,
    end: Timestamp,
  ): string {
    const seconds = 0.5 * (parseInt(start.seconds) + parseInt(end.seconds));
    return new Date(seconds * 1000).getUTCFullYear().toString();
  }

  private timeIntervalToMonthString(start: Timestamp, end: Timestamp): string {
    const seconds = 0.5 * (parseInt(start.seconds) + parseInt(end.seconds));
    return this.formatService.formatMonth(new Date(seconds * 1000));
  }

  private timeIntervalToWeekString(start: Timestamp, end: Timestamp): string {
    const seconds = 0.5 * (parseInt(start.seconds) + parseInt(end.seconds));
    return this.formatService.formatWeek(new Date(seconds * 1000));
  }

  private formatYName(seriesType: SeriesType): string {
    const unit = this.formatService.getUnit(seriesType);
    return unit == Unit.Count
      ? seriesType.toString()
      : seriesType.toString() + ', ' + unit.toString();
  }

  private getUnitName(seriesType: SeriesType): string {
    const unit = this.formatService.getUnit(seriesType);
    return unit == Unit.Count ? '' : unit.toString();
  }

  private getValues(
    seriesType: SeriesType,
    stats: StatsResponse[],
    activtyType: ActivityType | undefined,
  ): OptionalNumber[] {
    switch (seriesType) {
      case SeriesType.NumActivities:
        return stats.map(
          (s) =>
            StatsComponent.selectIntervalStats(s, activtyType).activityIds
              .length,
        );
      case SeriesType.Distance:
        return this.formatService.convertDistances(
          stats.map(
            (s) => StatsComponent.selectIntervalStats(s, activtyType).distance,
          ),
        );
      case SeriesType.ElevationGain:
        return this.formatService.convertElevations(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType).elevationGain,
          ),
        );
      case SeriesType.ElevationDrop:
        return this.formatService.convertElevations(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType).elevationDrop,
          ),
        );
      case SeriesType.Calories:
        return this.formatService.convertCalories(
          stats.map(
            (s) => StatsComponent.selectIntervalStats(s, activtyType).calories,
          ),
        );
      case SeriesType.AverageSpeed:
        return this.formatService.convertSpeeds(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType).speedStats
                ?.mean ?? 0,
          ),
        );
      case SeriesType.MilePace:
        return this.formatService.convertPaces(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType).milePaceStats
                ?.mean ?? 0,
          ),
        );
      case SeriesType.KilometerPace:
        return this.formatService.convertPaces(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType).kmPaceStats
                ?.mean ?? 0,
          ),
        );
      case SeriesType.AverageHeartRate:
        return this.formatService.convertHeartRates(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType).heartRateStats
                ?.mean ?? 0,
          ),
        );
      case SeriesType.MovingTime:
        return this.formatService.convertMovingTime(
          stats.map(
            (s) =>
              StatsComponent.selectIntervalStats(s, activtyType)
                .movingTimeSeconds,
          ),
        );
      case SeriesType.TimeInZone1:
      case SeriesType.TimeInZone2:
      case SeriesType.TimeInZone3:
      case SeriesType.TimeInZone4:
      case SeriesType.TimeInZone5: {
        const zoneIndex = parseInt(seriesType.charAt(seriesType.length - 1));
        return this.formatService.convertMovingTime(
          stats.map((s) => {
            const hrZones = StatsComponent.selectIntervalStats(
              s,
              activtyType,
            ).heartRateZoneStats;
            if (!hrZones) {
              return 0;
            }
            const hrZoneStats = hrZones[zoneIndex];
            if (!hrZoneStats) {
              return 0;
            }
            return parseInt(hrZoneStats.secondsInZone);
          }),
        );
      }
      case SeriesType.PercentTimeInZone1:
      case SeriesType.PercentTimeInZone2:
      case SeriesType.PercentTimeInZone3:
      case SeriesType.PercentTimeInZone4:
      case SeriesType.PercentTimeInZone5: {
        const zoneIndex = parseInt(seriesType.charAt(seriesType.length - 1));
        return stats.map((s) => {
          const hrZones = StatsComponent.selectIntervalStats(
            s,
            activtyType,
          ).heartRateZoneStats;
          if (!hrZones) {
            return '-';
          }
          const hrZoneStats = hrZones[zoneIndex];
          if (!hrZoneStats) {
            return 0;
          }
          let total = 0;
          Object.values(hrZones).forEach((hrz) => {
            total += parseInt(hrz.secondsInZone!);
          });
          let percent =
            total > 0 ? (100 * parseInt(hrZoneStats.secondsInZone)) / total : 0;
          percent = Math.round(percent * 10) / 10;
          return percent;
        });
      }
    }
    throw 'Unexpected series type: ' + seriesType;
  }

  private static selectIntervalStats(
    stats: StatsResponse,
    activtyType: ActivityType | undefined,
  ): IntervalStats {
    if (!activtyType) {
      return stats.totalStats!;
    }
    const selected = stats.statsByActivityType?.filter(
      (s) => s.activityType == activtyType,
    );
    if (selected && selected.length > 0 && selected[0].stats) {
      return selected[0].stats;
    }
    return StatsComponent.DEFAULT_INTERVAL_STATS;
  }

  private static granularityStringToEnum(
    granularity: string,
  ): StatsGranularity {
    switch (granularity) {
      case 'Year':
        return StatsGranularity.STATS_GRANULARITY_YEAR;
      case 'Month':
        return StatsGranularity.STATS_GRANULARITY_MONTH;
      case 'Week':
        return StatsGranularity.STATS_GRANULARITY_WEEK;
      default:
        return StatsGranularity.STATS_GRANULARITY_UNSPECIFIED;
    }
  }
}
