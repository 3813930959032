import { Component, Input } from '@angular/core';
import { Measurement } from 'src/app/services/format.service';

@Component({
  selector: 'app-measurement-widget',
  templateUrl: './measurement-widget.component.html',
  styleUrls: ['./measurement-widget.component.scss'],
})
export class MeasurementWidgetComponent {
  @Input()
  measurement!: Measurement;

  @Input()
  useTitle = false;
}
