/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for shared.PubsubNotification
 */
export class PubsubNotification implements GrpcMessage {
  static id = 'shared.PubsubNotification';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PubsubNotification();
    PubsubNotification.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PubsubNotification) {
    _instance.userIds = _instance.userIds || [];
    _instance.notification = _instance.notification || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PubsubNotification,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.userIds = _instance.userIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        case 2:
          _instance.notification = new PubsubNotification.Notification();
          _reader.readMessage(
            _instance.notification,
            PubsubNotification.Notification.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PubsubNotification.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PubsubNotification,
    _writer: BinaryWriter
  ) {
    if (_instance.userIds && _instance.userIds.length) {
      _writer.writePackedInt64String(1, _instance.userIds);
    }
    if (_instance.notification) {
      _writer.writeMessage(
        2,
        _instance.notification as any,
        PubsubNotification.Notification.serializeBinaryToWriter
      );
    }
  }

  private _userIds: string[];
  private _notification?: PubsubNotification.Notification;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PubsubNotification to deeply clone from
   */
  constructor(_value?: RecursivePartial<PubsubNotification.AsObject>) {
    _value = _value || {};
    this.userIds = (_value.userIds || []).slice();
    this.notification = _value.notification
      ? new PubsubNotification.Notification(_value.notification)
      : undefined;
    PubsubNotification.refineValues(this);
  }
  get userIds(): string[] {
    return this._userIds;
  }
  set userIds(value: string[]) {
    this._userIds = value;
  }
  get notification(): PubsubNotification.Notification | undefined {
    return this._notification;
  }
  set notification(value: PubsubNotification.Notification | undefined) {
    this._notification = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PubsubNotification.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PubsubNotification.AsObject {
    return {
      userIds: (this.userIds || []).slice(),
      notification: this.notification ? this.notification.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PubsubNotification.AsProtobufJSON {
    return {
      userIds: (this.userIds || []).slice(),
      notification: this.notification
        ? this.notification.toProtobufJSON(options)
        : null
    };
  }
}
export module PubsubNotification {
  /**
   * Standard JavaScript object representation for PubsubNotification
   */
  export interface AsObject {
    userIds: string[];
    notification?: PubsubNotification.Notification.AsObject;
  }

  /**
   * Protobuf JSON representation for PubsubNotification
   */
  export interface AsProtobufJSON {
    userIds: string[];
    notification: PubsubNotification.Notification.AsProtobufJSON | null;
  }

  /**
   * Message implementation for shared.PubsubNotification.UploadedActivity
   */
  export class UploadedActivity implements GrpcMessage {
    static id = 'shared.PubsubNotification.UploadedActivity';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new UploadedActivity();
      UploadedActivity.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: UploadedActivity) {
      _instance.alias = _instance.alias || '';
      _instance.activityId = _instance.activityId || '0';
      _instance.source = _instance.source || '';
      _instance.startTimeSeconds = _instance.startTimeSeconds || '0';
      _instance.endTimeSeconds = _instance.endTimeSeconds || '0';
      _instance.activityType = _instance.activityType || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: UploadedActivity,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.alias = _reader.readString();
            break;
          case 2:
            _instance.activityId = _reader.readInt64String();
            break;
          case 3:
            _instance.source = _reader.readString();
            break;
          case 4:
            _instance.startTimeSeconds = _reader.readInt64String();
            break;
          case 5:
            _instance.endTimeSeconds = _reader.readInt64String();
            break;
          case 6:
            _instance.activityType = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      UploadedActivity.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: UploadedActivity,
      _writer: BinaryWriter
    ) {
      if (_instance.alias) {
        _writer.writeString(1, _instance.alias);
      }
      if (_instance.activityId) {
        _writer.writeInt64String(2, _instance.activityId);
      }
      if (_instance.source) {
        _writer.writeString(3, _instance.source);
      }
      if (_instance.startTimeSeconds) {
        _writer.writeInt64String(4, _instance.startTimeSeconds);
      }
      if (_instance.endTimeSeconds) {
        _writer.writeInt64String(5, _instance.endTimeSeconds);
      }
      if (_instance.activityType) {
        _writer.writeString(6, _instance.activityType);
      }
    }

    private _alias: string;
    private _activityId: string;
    private _source: string;
    private _startTimeSeconds: string;
    private _endTimeSeconds: string;
    private _activityType: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of UploadedActivity to deeply clone from
     */
    constructor(_value?: RecursivePartial<UploadedActivity.AsObject>) {
      _value = _value || {};
      this.alias = _value.alias;
      this.activityId = _value.activityId;
      this.source = _value.source;
      this.startTimeSeconds = _value.startTimeSeconds;
      this.endTimeSeconds = _value.endTimeSeconds;
      this.activityType = _value.activityType;
      UploadedActivity.refineValues(this);
    }
    get alias(): string {
      return this._alias;
    }
    set alias(value: string) {
      this._alias = value;
    }
    get activityId(): string {
      return this._activityId;
    }
    set activityId(value: string) {
      this._activityId = value;
    }
    get source(): string {
      return this._source;
    }
    set source(value: string) {
      this._source = value;
    }
    get startTimeSeconds(): string {
      return this._startTimeSeconds;
    }
    set startTimeSeconds(value: string) {
      this._startTimeSeconds = value;
    }
    get endTimeSeconds(): string {
      return this._endTimeSeconds;
    }
    set endTimeSeconds(value: string) {
      this._endTimeSeconds = value;
    }
    get activityType(): string {
      return this._activityType;
    }
    set activityType(value: string) {
      this._activityType = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      UploadedActivity.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): UploadedActivity.AsObject {
      return {
        alias: this.alias,
        activityId: this.activityId,
        source: this.source,
        startTimeSeconds: this.startTimeSeconds,
        endTimeSeconds: this.endTimeSeconds,
        activityType: this.activityType
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): UploadedActivity.AsProtobufJSON {
      return {
        alias: this.alias,
        activityId: this.activityId,
        source: this.source,
        startTimeSeconds: this.startTimeSeconds,
        endTimeSeconds: this.endTimeSeconds,
        activityType: this.activityType
      };
    }
  }
  export module UploadedActivity {
    /**
     * Standard JavaScript object representation for UploadedActivity
     */
    export interface AsObject {
      alias: string;
      activityId: string;
      source: string;
      startTimeSeconds: string;
      endTimeSeconds: string;
      activityType: string;
    }

    /**
     * Protobuf JSON representation for UploadedActivity
     */
    export interface AsProtobufJSON {
      alias: string;
      activityId: string;
      source: string;
      startTimeSeconds: string;
      endTimeSeconds: string;
      activityType: string;
    }
  }

  /**
   * Message implementation for shared.PubsubNotification.FailedActivityUpload
   */
  export class FailedActivityUpload implements GrpcMessage {
    static id = 'shared.PubsubNotification.FailedActivityUpload';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new FailedActivityUpload();
      FailedActivityUpload.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: FailedActivityUpload) {
      _instance.reason = _instance.reason || '';
      _instance.source = _instance.source || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: FailedActivityUpload,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.reason = _reader.readString();
            break;
          case 2:
            _instance.source = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      FailedActivityUpload.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: FailedActivityUpload,
      _writer: BinaryWriter
    ) {
      if (_instance.reason) {
        _writer.writeString(1, _instance.reason);
      }
      if (_instance.source) {
        _writer.writeString(2, _instance.source);
      }
    }

    private _reason: string;
    private _source: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of FailedActivityUpload to deeply clone from
     */
    constructor(_value?: RecursivePartial<FailedActivityUpload.AsObject>) {
      _value = _value || {};
      this.reason = _value.reason;
      this.source = _value.source;
      FailedActivityUpload.refineValues(this);
    }
    get reason(): string {
      return this._reason;
    }
    set reason(value: string) {
      this._reason = value;
    }
    get source(): string {
      return this._source;
    }
    set source(value: string) {
      this._source = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      FailedActivityUpload.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): FailedActivityUpload.AsObject {
      return {
        reason: this.reason,
        source: this.source
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): FailedActivityUpload.AsProtobufJSON {
      return {
        reason: this.reason,
        source: this.source
      };
    }
  }
  export module FailedActivityUpload {
    /**
     * Standard JavaScript object representation for FailedActivityUpload
     */
    export interface AsObject {
      reason: string;
      source: string;
    }

    /**
     * Protobuf JSON representation for FailedActivityUpload
     */
    export interface AsProtobufJSON {
      reason: string;
      source: string;
    }
  }

  /**
   * Message implementation for shared.PubsubNotification.NewFollowRequest
   */
  export class NewFollowRequest implements GrpcMessage {
    static id = 'shared.PubsubNotification.NewFollowRequest';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new NewFollowRequest();
      NewFollowRequest.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: NewFollowRequest) {
      _instance.alias = _instance.alias || '';
      _instance.firstName = _instance.firstName || '';
      _instance.lastName = _instance.lastName || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: NewFollowRequest,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.alias = _reader.readString();
            break;
          case 2:
            _instance.firstName = _reader.readString();
            break;
          case 3:
            _instance.lastName = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      NewFollowRequest.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: NewFollowRequest,
      _writer: BinaryWriter
    ) {
      if (_instance.alias) {
        _writer.writeString(1, _instance.alias);
      }
      if (_instance.firstName) {
        _writer.writeString(2, _instance.firstName);
      }
      if (_instance.lastName) {
        _writer.writeString(3, _instance.lastName);
      }
    }

    private _alias: string;
    private _firstName: string;
    private _lastName: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of NewFollowRequest to deeply clone from
     */
    constructor(_value?: RecursivePartial<NewFollowRequest.AsObject>) {
      _value = _value || {};
      this.alias = _value.alias;
      this.firstName = _value.firstName;
      this.lastName = _value.lastName;
      NewFollowRequest.refineValues(this);
    }
    get alias(): string {
      return this._alias;
    }
    set alias(value: string) {
      this._alias = value;
    }
    get firstName(): string {
      return this._firstName;
    }
    set firstName(value: string) {
      this._firstName = value;
    }
    get lastName(): string {
      return this._lastName;
    }
    set lastName(value: string) {
      this._lastName = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      NewFollowRequest.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): NewFollowRequest.AsObject {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): NewFollowRequest.AsProtobufJSON {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName
      };
    }
  }
  export module NewFollowRequest {
    /**
     * Standard JavaScript object representation for NewFollowRequest
     */
    export interface AsObject {
      alias: string;
      firstName: string;
      lastName: string;
    }

    /**
     * Protobuf JSON representation for NewFollowRequest
     */
    export interface AsProtobufJSON {
      alias: string;
      firstName: string;
      lastName: string;
    }
  }

  /**
   * Message implementation for shared.PubsubNotification.NewFollower
   */
  export class NewFollower implements GrpcMessage {
    static id = 'shared.PubsubNotification.NewFollower';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new NewFollower();
      NewFollower.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: NewFollower) {
      _instance.alias = _instance.alias || '';
      _instance.firstName = _instance.firstName || '';
      _instance.lastName = _instance.lastName || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: NewFollower,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.alias = _reader.readString();
            break;
          case 2:
            _instance.firstName = _reader.readString();
            break;
          case 3:
            _instance.lastName = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      NewFollower.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: NewFollower,
      _writer: BinaryWriter
    ) {
      if (_instance.alias) {
        _writer.writeString(1, _instance.alias);
      }
      if (_instance.firstName) {
        _writer.writeString(2, _instance.firstName);
      }
      if (_instance.lastName) {
        _writer.writeString(3, _instance.lastName);
      }
    }

    private _alias: string;
    private _firstName: string;
    private _lastName: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of NewFollower to deeply clone from
     */
    constructor(_value?: RecursivePartial<NewFollower.AsObject>) {
      _value = _value || {};
      this.alias = _value.alias;
      this.firstName = _value.firstName;
      this.lastName = _value.lastName;
      NewFollower.refineValues(this);
    }
    get alias(): string {
      return this._alias;
    }
    set alias(value: string) {
      this._alias = value;
    }
    get firstName(): string {
      return this._firstName;
    }
    set firstName(value: string) {
      this._firstName = value;
    }
    get lastName(): string {
      return this._lastName;
    }
    set lastName(value: string) {
      this._lastName = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      NewFollower.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): NewFollower.AsObject {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): NewFollower.AsProtobufJSON {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName
      };
    }
  }
  export module NewFollower {
    /**
     * Standard JavaScript object representation for NewFollower
     */
    export interface AsObject {
      alias: string;
      firstName: string;
      lastName: string;
    }

    /**
     * Protobuf JSON representation for NewFollower
     */
    export interface AsProtobufJSON {
      alias: string;
      firstName: string;
      lastName: string;
    }
  }

  /**
   * Message implementation for shared.PubsubNotification.NewFollowing
   */
  export class NewFollowing implements GrpcMessage {
    static id = 'shared.PubsubNotification.NewFollowing';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new NewFollowing();
      NewFollowing.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: NewFollowing) {
      _instance.alias = _instance.alias || '';
      _instance.firstName = _instance.firstName || '';
      _instance.lastName = _instance.lastName || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: NewFollowing,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.alias = _reader.readString();
            break;
          case 2:
            _instance.firstName = _reader.readString();
            break;
          case 3:
            _instance.lastName = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      NewFollowing.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: NewFollowing,
      _writer: BinaryWriter
    ) {
      if (_instance.alias) {
        _writer.writeString(1, _instance.alias);
      }
      if (_instance.firstName) {
        _writer.writeString(2, _instance.firstName);
      }
      if (_instance.lastName) {
        _writer.writeString(3, _instance.lastName);
      }
    }

    private _alias: string;
    private _firstName: string;
    private _lastName: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of NewFollowing to deeply clone from
     */
    constructor(_value?: RecursivePartial<NewFollowing.AsObject>) {
      _value = _value || {};
      this.alias = _value.alias;
      this.firstName = _value.firstName;
      this.lastName = _value.lastName;
      NewFollowing.refineValues(this);
    }
    get alias(): string {
      return this._alias;
    }
    set alias(value: string) {
      this._alias = value;
    }
    get firstName(): string {
      return this._firstName;
    }
    set firstName(value: string) {
      this._firstName = value;
    }
    get lastName(): string {
      return this._lastName;
    }
    set lastName(value: string) {
      this._lastName = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      NewFollowing.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): NewFollowing.AsObject {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): NewFollowing.AsProtobufJSON {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName
      };
    }
  }
  export module NewFollowing {
    /**
     * Standard JavaScript object representation for NewFollowing
     */
    export interface AsObject {
      alias: string;
      firstName: string;
      lastName: string;
    }

    /**
     * Protobuf JSON representation for NewFollowing
     */
    export interface AsProtobufJSON {
      alias: string;
      firstName: string;
      lastName: string;
    }
  }

  /**
   * Message implementation for shared.PubsubNotification.NewReaction
   */
  export class NewReaction implements GrpcMessage {
    static id = 'shared.PubsubNotification.NewReaction';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new NewReaction();
      NewReaction.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: NewReaction) {
      _instance.alias = _instance.alias || '';
      _instance.firstName = _instance.firstName || '';
      _instance.lastName = _instance.lastName || '';
      _instance.activityId = _instance.activityId || '0';
      _instance.comment = _instance.comment || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: NewReaction,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.alias = _reader.readString();
            break;
          case 2:
            _instance.firstName = _reader.readString();
            break;
          case 3:
            _instance.lastName = _reader.readString();
            break;
          case 4:
            _instance.activityId = _reader.readInt64String();
            break;
          case 5:
            _instance.comment = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      NewReaction.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: NewReaction,
      _writer: BinaryWriter
    ) {
      if (_instance.alias) {
        _writer.writeString(1, _instance.alias);
      }
      if (_instance.firstName) {
        _writer.writeString(2, _instance.firstName);
      }
      if (_instance.lastName) {
        _writer.writeString(3, _instance.lastName);
      }
      if (_instance.activityId) {
        _writer.writeInt64String(4, _instance.activityId);
      }
      if (_instance.comment) {
        _writer.writeString(5, _instance.comment);
      }
    }

    private _alias: string;
    private _firstName: string;
    private _lastName: string;
    private _activityId: string;
    private _comment: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of NewReaction to deeply clone from
     */
    constructor(_value?: RecursivePartial<NewReaction.AsObject>) {
      _value = _value || {};
      this.alias = _value.alias;
      this.firstName = _value.firstName;
      this.lastName = _value.lastName;
      this.activityId = _value.activityId;
      this.comment = _value.comment;
      NewReaction.refineValues(this);
    }
    get alias(): string {
      return this._alias;
    }
    set alias(value: string) {
      this._alias = value;
    }
    get firstName(): string {
      return this._firstName;
    }
    set firstName(value: string) {
      this._firstName = value;
    }
    get lastName(): string {
      return this._lastName;
    }
    set lastName(value: string) {
      this._lastName = value;
    }
    get activityId(): string {
      return this._activityId;
    }
    set activityId(value: string) {
      this._activityId = value;
    }
    get comment(): string {
      return this._comment;
    }
    set comment(value: string) {
      this._comment = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      NewReaction.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): NewReaction.AsObject {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName,
        activityId: this.activityId,
        comment: this.comment
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): NewReaction.AsProtobufJSON {
      return {
        alias: this.alias,
        firstName: this.firstName,
        lastName: this.lastName,
        activityId: this.activityId,
        comment: this.comment
      };
    }
  }
  export module NewReaction {
    /**
     * Standard JavaScript object representation for NewReaction
     */
    export interface AsObject {
      alias: string;
      firstName: string;
      lastName: string;
      activityId: string;
      comment: string;
    }

    /**
     * Protobuf JSON representation for NewReaction
     */
    export interface AsProtobufJSON {
      alias: string;
      firstName: string;
      lastName: string;
      activityId: string;
      comment: string;
    }
  }

  /**
   * Message implementation for shared.PubsubNotification.Notification
   */
  export class Notification implements GrpcMessage {
    static id = 'shared.PubsubNotification.Notification';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Notification();
      Notification.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Notification) {}

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Notification,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.uploadedActivity = new PubsubNotification.UploadedActivity();
            _reader.readMessage(
              _instance.uploadedActivity,
              PubsubNotification.UploadedActivity.deserializeBinaryFromReader
            );
            break;
          case 2:
            _instance.failedActivityUpload = new PubsubNotification.FailedActivityUpload();
            _reader.readMessage(
              _instance.failedActivityUpload,
              PubsubNotification.FailedActivityUpload
                .deserializeBinaryFromReader
            );
            break;
          case 3:
            _instance.newFollowRequest = new PubsubNotification.NewFollowRequest();
            _reader.readMessage(
              _instance.newFollowRequest,
              PubsubNotification.NewFollowRequest.deserializeBinaryFromReader
            );
            break;
          case 5:
            _instance.newFollowing = new PubsubNotification.NewFollowing();
            _reader.readMessage(
              _instance.newFollowing,
              PubsubNotification.NewFollowing.deserializeBinaryFromReader
            );
            break;
          case 6:
            _instance.newReaction = new PubsubNotification.NewReaction();
            _reader.readMessage(
              _instance.newReaction,
              PubsubNotification.NewReaction.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      Notification.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: Notification,
      _writer: BinaryWriter
    ) {
      if (_instance.uploadedActivity) {
        _writer.writeMessage(
          1,
          _instance.uploadedActivity as any,
          PubsubNotification.UploadedActivity.serializeBinaryToWriter
        );
      }
      if (_instance.failedActivityUpload) {
        _writer.writeMessage(
          2,
          _instance.failedActivityUpload as any,
          PubsubNotification.FailedActivityUpload.serializeBinaryToWriter
        );
      }
      if (_instance.newFollowRequest) {
        _writer.writeMessage(
          3,
          _instance.newFollowRequest as any,
          PubsubNotification.NewFollowRequest.serializeBinaryToWriter
        );
      }
      if (_instance.newFollowing) {
        _writer.writeMessage(
          5,
          _instance.newFollowing as any,
          PubsubNotification.NewFollowing.serializeBinaryToWriter
        );
      }
      if (_instance.newReaction) {
        _writer.writeMessage(
          6,
          _instance.newReaction as any,
          PubsubNotification.NewReaction.serializeBinaryToWriter
        );
      }
    }

    private _uploadedActivity?: PubsubNotification.UploadedActivity;
    private _failedActivityUpload?: PubsubNotification.FailedActivityUpload;
    private _newFollowRequest?: PubsubNotification.NewFollowRequest;
    private _newFollowing?: PubsubNotification.NewFollowing;
    private _newReaction?: PubsubNotification.NewReaction;

    private _notification: Notification.NotificationCase =
      Notification.NotificationCase.none;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Notification to deeply clone from
     */
    constructor(_value?: RecursivePartial<Notification.AsObject>) {
      _value = _value || {};
      this.uploadedActivity = _value.uploadedActivity
        ? new PubsubNotification.UploadedActivity(_value.uploadedActivity)
        : undefined;
      this.failedActivityUpload = _value.failedActivityUpload
        ? new PubsubNotification.FailedActivityUpload(
            _value.failedActivityUpload
          )
        : undefined;
      this.newFollowRequest = _value.newFollowRequest
        ? new PubsubNotification.NewFollowRequest(_value.newFollowRequest)
        : undefined;
      this.newFollowing = _value.newFollowing
        ? new PubsubNotification.NewFollowing(_value.newFollowing)
        : undefined;
      this.newReaction = _value.newReaction
        ? new PubsubNotification.NewReaction(_value.newReaction)
        : undefined;
      Notification.refineValues(this);
    }
    get uploadedActivity(): PubsubNotification.UploadedActivity | undefined {
      return this._uploadedActivity;
    }
    set uploadedActivity(
      value: PubsubNotification.UploadedActivity | undefined
    ) {
      if (value !== undefined && value !== null) {
        this._failedActivityUpload = this._newFollowRequest = this._newFollowing = this._newReaction = undefined;
        this._notification = Notification.NotificationCase.uploadedActivity;
      }
      this._uploadedActivity = value;
    }
    get failedActivityUpload():
      | PubsubNotification.FailedActivityUpload
      | undefined {
      return this._failedActivityUpload;
    }
    set failedActivityUpload(
      value: PubsubNotification.FailedActivityUpload | undefined
    ) {
      if (value !== undefined && value !== null) {
        this._uploadedActivity = this._newFollowRequest = this._newFollowing = this._newReaction = undefined;
        this._notification = Notification.NotificationCase.failedActivityUpload;
      }
      this._failedActivityUpload = value;
    }
    get newFollowRequest(): PubsubNotification.NewFollowRequest | undefined {
      return this._newFollowRequest;
    }
    set newFollowRequest(
      value: PubsubNotification.NewFollowRequest | undefined
    ) {
      if (value !== undefined && value !== null) {
        this._uploadedActivity = this._failedActivityUpload = this._newFollowing = this._newReaction = undefined;
        this._notification = Notification.NotificationCase.newFollowRequest;
      }
      this._newFollowRequest = value;
    }
    get newFollowing(): PubsubNotification.NewFollowing | undefined {
      return this._newFollowing;
    }
    set newFollowing(value: PubsubNotification.NewFollowing | undefined) {
      if (value !== undefined && value !== null) {
        this._uploadedActivity = this._failedActivityUpload = this._newFollowRequest = this._newReaction = undefined;
        this._notification = Notification.NotificationCase.newFollowing;
      }
      this._newFollowing = value;
    }
    get newReaction(): PubsubNotification.NewReaction | undefined {
      return this._newReaction;
    }
    set newReaction(value: PubsubNotification.NewReaction | undefined) {
      if (value !== undefined && value !== null) {
        this._uploadedActivity = this._failedActivityUpload = this._newFollowRequest = this._newFollowing = undefined;
        this._notification = Notification.NotificationCase.newReaction;
      }
      this._newReaction = value;
    }
    get notification() {
      return this._notification;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Notification.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Notification.AsObject {
      return {
        uploadedActivity: this.uploadedActivity
          ? this.uploadedActivity.toObject()
          : undefined,
        failedActivityUpload: this.failedActivityUpload
          ? this.failedActivityUpload.toObject()
          : undefined,
        newFollowRequest: this.newFollowRequest
          ? this.newFollowRequest.toObject()
          : undefined,
        newFollowing: this.newFollowing
          ? this.newFollowing.toObject()
          : undefined,
        newReaction: this.newReaction ? this.newReaction.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Notification.AsProtobufJSON {
      return {
        uploadedActivity: this.uploadedActivity
          ? this.uploadedActivity.toProtobufJSON(options)
          : null,
        failedActivityUpload: this.failedActivityUpload
          ? this.failedActivityUpload.toProtobufJSON(options)
          : null,
        newFollowRequest: this.newFollowRequest
          ? this.newFollowRequest.toProtobufJSON(options)
          : null,
        newFollowing: this.newFollowing
          ? this.newFollowing.toProtobufJSON(options)
          : null,
        newReaction: this.newReaction
          ? this.newReaction.toProtobufJSON(options)
          : null
      };
    }
  }
  export module Notification {
    /**
     * Standard JavaScript object representation for Notification
     */
    export interface AsObject {
      uploadedActivity?: PubsubNotification.UploadedActivity.AsObject;
      failedActivityUpload?: PubsubNotification.FailedActivityUpload.AsObject;
      newFollowRequest?: PubsubNotification.NewFollowRequest.AsObject;
      newFollowing?: PubsubNotification.NewFollowing.AsObject;
      newReaction?: PubsubNotification.NewReaction.AsObject;
    }

    /**
     * Protobuf JSON representation for Notification
     */
    export interface AsProtobufJSON {
      uploadedActivity: PubsubNotification.UploadedActivity.AsProtobufJSON | null;
      failedActivityUpload: PubsubNotification.FailedActivityUpload.AsProtobufJSON | null;
      newFollowRequest: PubsubNotification.NewFollowRequest.AsProtobufJSON | null;
      newFollowing: PubsubNotification.NewFollowing.AsProtobufJSON | null;
      newReaction: PubsubNotification.NewReaction.AsProtobufJSON | null;
    }
    export enum NotificationCase {
      none = 0,
      uploadedActivity = 1,
      failedActivityUpload = 2,
      newFollowRequest = 3,
      newFollowing = 4,
      newReaction = 5
    }
  }
}
