/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './user-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/shared/media-shared.pb';
import * as shared002 from '../../../../src/main/proto/shared/user-shared.pb';
import { GRPC_USER_SERVICE_CLIENT_SETTINGS } from './user-service.pbconf';
/**
 * Service client implementation for api.user.UserService
 */
@Injectable({ providedIn: 'any' })
export class UserServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.user.UserService/CheckUserRestrictions
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CheckUserRestrictionsResponse>>
     */
    checkUserRestrictions: (
      requestData: thisProto.CheckUserRestrictionsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CheckUserRestrictionsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/CheckUserRestrictions',
        requestData,
        requestMetadata,
        requestClass: thisProto.CheckUserRestrictionsRequest,
        responseClass: thisProto.CheckUserRestrictionsResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/LogIn
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.LogInResponse>>
     */
    logIn: (
      requestData: thisProto.LogInRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.LogInResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/LogIn',
        requestData,
        requestMetadata,
        requestClass: thisProto.LogInRequest,
        responseClass: thisProto.LogInResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/LogOut
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.LogOutResponse>>
     */
    logOut: (
      requestData: thisProto.LogOutRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.LogOutResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/LogOut',
        requestData,
        requestMetadata,
        requestClass: thisProto.LogOutRequest,
        responseClass: thisProto.LogOutResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/UpdateMessagingToken
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateMessagingTokenResponse>>
     */
    updateMessagingToken: (
      requestData: thisProto.UpdateMessagingTokenRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateMessagingTokenResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/UpdateMessagingToken',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateMessagingTokenRequest,
        responseClass: thisProto.UpdateMessagingTokenResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/AliasExists
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AliasExistsResponse>>
     */
    aliasExists: (
      requestData: thisProto.AliasExistsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AliasExistsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/AliasExists',
        requestData,
        requestMetadata,
        requestClass: thisProto.AliasExistsRequest,
        responseClass: thisProto.AliasExistsResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/SignUp
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SignUpResponse>>
     */
    signUp: (
      requestData: thisProto.SignUpRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SignUpResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/SignUp',
        requestData,
        requestMetadata,
        requestClass: thisProto.SignUpRequest,
        responseClass: thisProto.SignUpResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/GetDefaultHeartRateLimits
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDefaultHeartRateLimitsResponse>>
     */
    getDefaultHeartRateLimits: (
      requestData: thisProto.GetDefaultHeartRateLimitsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDefaultHeartRateLimitsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/GetDefaultHeartRateLimits',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDefaultHeartRateLimitsRequest,
        responseClass: thisProto.GetDefaultHeartRateLimitsResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/UpdateCookiesConsent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateCookiesConsentResponse>>
     */
    updateCookiesConsent: (
      requestData: thisProto.UpdateCookiesConsentRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateCookiesConsentResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/UpdateCookiesConsent',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateCookiesConsentRequest,
        responseClass: thisProto.UpdateCookiesConsentResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/SignOut
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SignOutResponse>>
     */
    signOut: (
      requestData: thisProto.SignOutRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SignOutResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/SignOut',
        requestData,
        requestMetadata,
        requestClass: thisProto.SignOutRequest,
        responseClass: thisProto.SignOutResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/Delete
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteResponse>>
     */
    delete: (
      requestData: thisProto.DeleteRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DeleteResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/Delete',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteRequest,
        responseClass: thisProto.DeleteResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/GetUserDetails
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetUserDetailsResponse>>
     */
    getUserDetails: (
      requestData: thisProto.GetUserDetailsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetUserDetailsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/GetUserDetails',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetUserDetailsRequest,
        responseClass: thisProto.GetUserDetailsResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/GetAvailableRoles
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetAvailableRolesResponse>>
     */
    getAvailableRoles: (
      requestData: thisProto.GetAvailableRolesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetAvailableRolesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/GetAvailableRoles',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetAvailableRolesRequest,
        responseClass: thisProto.GetAvailableRolesResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/GetUserRoles
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetUserRolesResponse>>
     */
    getUserRoles: (
      requestData: thisProto.GetUserRolesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetUserRolesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/GetUserRoles',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetUserRolesRequest,
        responseClass: thisProto.GetUserRolesResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/UpdateUserRoles
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateUserRolesResponse>>
     */
    updateUserRoles: (
      requestData: thisProto.UpdateUserRolesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateUserRolesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/UpdateUserRoles',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateUserRolesRequest,
        responseClass: thisProto.UpdateUserRolesResponse
      });
    },
    /**
     * Unary call: /api.user.UserService/AutoSuggestUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AutoSuggestUsersResponse>>
     */
    autoSuggestUsers: (
      requestData: thisProto.AutoSuggestUsersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AutoSuggestUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.user.UserService/AutoSuggestUsers',
        requestData,
        requestMetadata,
        requestClass: thisProto.AutoSuggestUsersRequest,
        responseClass: thisProto.AutoSuggestUsersResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_USER_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('api.user.UserService', settings);
  }

  /**
   * Unary call @/api.user.UserService/CheckUserRestrictions
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CheckUserRestrictionsResponse>
   */
  checkUserRestrictions(
    requestData: thisProto.CheckUserRestrictionsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CheckUserRestrictionsResponse> {
    return this.$raw
      .checkUserRestrictions(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/LogIn
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.LogInResponse>
   */
  logIn(
    requestData: thisProto.LogInRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.LogInResponse> {
    return this.$raw
      .logIn(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/LogOut
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.LogOutResponse>
   */
  logOut(
    requestData: thisProto.LogOutRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.LogOutResponse> {
    return this.$raw
      .logOut(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/UpdateMessagingToken
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateMessagingTokenResponse>
   */
  updateMessagingToken(
    requestData: thisProto.UpdateMessagingTokenRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateMessagingTokenResponse> {
    return this.$raw
      .updateMessagingToken(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/AliasExists
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AliasExistsResponse>
   */
  aliasExists(
    requestData: thisProto.AliasExistsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AliasExistsResponse> {
    return this.$raw
      .aliasExists(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/SignUp
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SignUpResponse>
   */
  signUp(
    requestData: thisProto.SignUpRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SignUpResponse> {
    return this.$raw
      .signUp(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/GetDefaultHeartRateLimits
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDefaultHeartRateLimitsResponse>
   */
  getDefaultHeartRateLimits(
    requestData: thisProto.GetDefaultHeartRateLimitsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDefaultHeartRateLimitsResponse> {
    return this.$raw
      .getDefaultHeartRateLimits(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/UpdateCookiesConsent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateCookiesConsentResponse>
   */
  updateCookiesConsent(
    requestData: thisProto.UpdateCookiesConsentRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateCookiesConsentResponse> {
    return this.$raw
      .updateCookiesConsent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/SignOut
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SignOutResponse>
   */
  signOut(
    requestData: thisProto.SignOutRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SignOutResponse> {
    return this.$raw
      .signOut(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/Delete
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteResponse>
   */
  delete(
    requestData: thisProto.DeleteRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteResponse> {
    return this.$raw
      .delete(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/GetUserDetails
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetUserDetailsResponse>
   */
  getUserDetails(
    requestData: thisProto.GetUserDetailsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetUserDetailsResponse> {
    return this.$raw
      .getUserDetails(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/GetAvailableRoles
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetAvailableRolesResponse>
   */
  getAvailableRoles(
    requestData: thisProto.GetAvailableRolesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetAvailableRolesResponse> {
    return this.$raw
      .getAvailableRoles(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/GetUserRoles
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetUserRolesResponse>
   */
  getUserRoles(
    requestData: thisProto.GetUserRolesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetUserRolesResponse> {
    return this.$raw
      .getUserRoles(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/UpdateUserRoles
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateUserRolesResponse>
   */
  updateUserRoles(
    requestData: thisProto.UpdateUserRolesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateUserRolesResponse> {
    return this.$raw
      .updateUserRoles(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.user.UserService/AutoSuggestUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AutoSuggestUsersResponse>
   */
  autoSuggestUsers(
    requestData: thisProto.AutoSuggestUsersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AutoSuggestUsersResponse> {
    return this.$raw
      .autoSuggestUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
