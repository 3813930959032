<div class="policy-text">
  <h1>Terms of Service</h1>

  <p>Welcome to Jestle! These terms and conditions govern your use of our website and services.</p>

  <h2>1. Acceptance of Terms</h2>
  <p>By using our website and services, you agree to comply with and be bound by these terms of service.</p>

  <h2>2. User Registration</h2>
  <p>In order to access features of our website, you are required to register. You agree to provide accurate and
    complete
    information during the registration process.</p>

  <h2>3. User Activities</h2>
  <p>Our website allows/will allow users to:</p>
  <ul>
    <li>View and manage their physical activity data.</li>
    <li>Access to their calendar.</li>
    <li>Create events.</li>
    <li>Register for events.</li>
    <li>Follow other users.</li>
    <li>Upload photos and videos.</li>
  </ul>

  <h2>4. Privacy</h2>
  <p>Your use of our website is also governed by our <a routerLink="/legal/privacy-policy">Privacy Policy</a>, which outlines how we
    collect, use, and protect your personal information.</p>

  <h2>5. Content Upload</h2>
  <p>When uploading photos and videos to your feed, you agree to abide by our content guidelines. You are solely
    responsible for the content you upload, and it must not violate any laws or infringe on the rights of others.</p>

  <h2>6. Termination of Services</h2>
  <p>We reserve the right to terminate or suspend your access to our website and services at our discretion, without
    notice, for any reason, including if you violate these terms of service.</p>

  <h2>7. Changes to Terms</h2>
  <p>We may update these terms of service from time to time. It is your responsibility to review these terms
    periodically
    for any changes. Your continued use of our website after any modifications constitutes acceptance of those changes.
  </p>

  <h2>8. Contact Us</h2>
  <p>If you have any questions or concerns about these terms of service, please contact us at support&#64;jestle.com.</p>

  <p>Effective Date: 10/5/23</p>
</div>
