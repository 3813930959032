import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UserDataAccessLevel,
  UserPrivateDataAccessList,
} from 'generated/src/main/proto/shared/user-shared.pb';

type AccessLevel = {
  level: UserDataAccessLevel;
  label: string;
  tooltip: string;
};

@Component({
  selector: 'app-privacy-control',
  templateUrl: './privacy-control.component.html',
  styleUrls: ['./privacy-control.component.scss'],
})
export class PrivacyControlComponent implements OnInit {
  @Input()
  userDataAcl: UserPrivateDataAccessList | undefined =
    new UserPrivateDataAccessList({
      accessLevel: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_PRIVATE,
    });

  @Input()
  disablePublic = false;

  @Output()
  userDataAclChange = new EventEmitter<UserPrivateDataAccessList>();

  accessLevels: AccessLevel[] = [];

  ngOnInit(): void {
    this.accessLevels = [
      {
        level: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_PRIVATE,
        label: 'Private',
        tooltip: 'Only the logged-in can see the data',
      },
      {
        level: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_FOLLOWERS,
        label: 'Followers',
        tooltip: 'Logged-in user and followers can see the data',
      },
    ];
    if (!this.disablePublic) {
      this.accessLevels.push({
        level: UserDataAccessLevel.USER_DATA_ACCESS_LEVEL_PUBLIC,
        label: 'Public',
        tooltip: 'Anybody can see the data',
      });
    }
  }

  onAccessLevelChange(accessLevel: UserDataAccessLevel) {
    this.userDataAcl!.accessLevel = accessLevel;
    this.userDataAclChange.next(this.userDataAcl!);
  }
}
