import { Injectable } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  CompleteLogInRequest,
  CompleteLogInResponse,
  GetBackfillStatusRequest,
  GetBackfillStatusResponse,
  GetLoginStatusRequest,
  GetLoginStatusResponse,
  LogOutRequest,
  LogOutResponse,
  RequestBackfillRequest,
  RequestBackfillResponse,
  StartLogInRequest,
  StartLogInResponse,
} from 'generated/src/main/proto/api/garmin-service.pb';
import { GarminServiceClient } from 'generated/src/main/proto/api/garmin-service.pbsc';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

/** Garmin-related methods for user login to Garmin. */
@Injectable({
  providedIn: 'root',
})
export class GarminService {
  constructor(
    private garminServiceClient: GarminServiceClient,
    private userService: UserService,
    private bannerService: BannerService,
  ) {}

  /** Starts login by generating oath_token & secret on the backend. */
  async startLogin(): Promise<StartLogInResponse> {
    return lastValueFrom(
      this.garminServiceClient
        .startLogin(new StartLogInRequest(), this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Completes login by exchanging the verifier code for access token on the backend. */
  async completeLogin(
    oathToken: string,
    oathVerifier: string,
  ): Promise<CompleteLogInResponse> {
    return lastValueFrom(
      this.garminServiceClient
        .completeLogin(
          new CompleteLogInRequest({
            oauthToken: oathToken,
            oauthVerifier: oathVerifier,
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Gets Garmin login status. */
  async getLoginStatus(): Promise<GetLoginStatusResponse> {
    return lastValueFrom(
      this.garminServiceClient
        .getLoginStatus(
          new GetLoginStatusRequest(),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Deletes the token. */
  async logOut(): Promise<LogOutResponse> {
    return lastValueFrom(
      this.garminServiceClient
        .logOut(new LogOutRequest(), this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Gets status of the backfill. */
  async getBackfillStatus(): Promise<GetBackfillStatusResponse> {
    return lastValueFrom(
      this.garminServiceClient
        .getBackfillStatus(
          new GetBackfillStatusRequest(),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Requests backfill. */
  async requestBackfill(
    startTime: Date,
    endTime: Date,
  ): Promise<RequestBackfillResponse> {
    return lastValueFrom(
      this.garminServiceClient
        .requestBackfill(
          new RequestBackfillRequest({
            startTime: Timestamp.fromDate(startTime),
            endTime: Timestamp.fromDate(endTime),
          }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
