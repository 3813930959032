/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum MediaContentType {
  MEDIA_CONTENT_TYPE_UNSPECIFIED = 0,
  MEDIA_CONTENT_TYPE_MAP_PREVIEW = 1,
  MEDIA_CONTENT_TYPE_IMAGE = 2,
  MEDIA_CONTENT_TYPE_VIDEO = 3
}
/**
 * Message implementation for shared.Media
 */
export class Media implements GrpcMessage {
  static id = 'shared.Media';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Media();
    Media.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Media) {
    _instance.smallSizeId = _instance.smallSizeId || '0';
    _instance.fullSizeId = _instance.fullSizeId || '0';
    _instance.contentType = _instance.contentType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Media, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.smallSizeId = _reader.readInt64String();
          break;
        case 2:
          _instance.fullSizeId = _reader.readInt64String();
          break;
        case 3:
          _instance.contentType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    Media.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Media, _writer: BinaryWriter) {
    if (_instance.smallSizeId) {
      _writer.writeInt64String(1, _instance.smallSizeId);
    }
    if (_instance.fullSizeId) {
      _writer.writeInt64String(2, _instance.fullSizeId);
    }
    if (_instance.contentType) {
      _writer.writeEnum(3, _instance.contentType);
    }
  }

  private _smallSizeId: string;
  private _fullSizeId: string;
  private _contentType: MediaContentType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Media to deeply clone from
   */
  constructor(_value?: RecursivePartial<Media.AsObject>) {
    _value = _value || {};
    this.smallSizeId = _value.smallSizeId;
    this.fullSizeId = _value.fullSizeId;
    this.contentType = _value.contentType;
    Media.refineValues(this);
  }
  get smallSizeId(): string {
    return this._smallSizeId;
  }
  set smallSizeId(value: string) {
    this._smallSizeId = value;
  }
  get fullSizeId(): string {
    return this._fullSizeId;
  }
  set fullSizeId(value: string) {
    this._fullSizeId = value;
  }
  get contentType(): MediaContentType {
    return this._contentType;
  }
  set contentType(value: MediaContentType) {
    this._contentType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Media.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Media.AsObject {
    return {
      smallSizeId: this.smallSizeId,
      fullSizeId: this.fullSizeId,
      contentType: this.contentType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Media.AsProtobufJSON {
    return {
      smallSizeId: this.smallSizeId,
      fullSizeId: this.fullSizeId,
      contentType:
        MediaContentType[
          this.contentType === null || this.contentType === undefined
            ? 0
            : this.contentType
        ]
    };
  }
}
export module Media {
  /**
   * Standard JavaScript object representation for Media
   */
  export interface AsObject {
    smallSizeId: string;
    fullSizeId: string;
    contentType: MediaContentType;
  }

  /**
   * Protobuf JSON representation for Media
   */
  export interface AsProtobufJSON {
    smallSizeId: string;
    fullSizeId: string;
    contentType: string;
  }
}
