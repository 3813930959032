<cdk-accordion class="accordion" [ngStyle]="{ width: innerWidth + 'px' }">
  <cdk-accordion-item
    class="accordion-item"
    *ngFor="let issue of issuesObservable | async"
    #accordionItem="cdkAccordionItem"
    role="button"
    (expandedChange)="onExpandedChanged($event, issue)"
  >
    <!-- eslint-disable-next-line -->
    <div
      class="accordion-item-header"
      tabIndex="0"
      (click)="accordionItem.toggle()"
      (keydown)="onAccordionItemKeyDown($event, accordionItem)"
    >
      <table>
        <tr>
          <td class="id">{{ issue.issueId }}</td>
          <td class="title">{{ issue.title }}</td>
          <td class="state">{{ formatState(issue.state) }}</td>
          <td class="lastUpdated" matTooltip="Last updated time">
            {{ formatTime(issue.lastUpdateTime!) }}
          </td>
          <td class="created" matTooltip="Created time">
            {{ formatTime(issue.createdTime!) }}
          </td>
        </tr>
      </table>
    </div>
    <div
      role="region"
      [ngStyle]="{ display: accordionItem.expanded ? '' : 'none' }"
      class="flex-column"
    >
      <div class="{{ flexImageRowClass }}">
        <img
          id="{{ 'screenshot' + issue.issueId }}"
          width="{{ screenshotImageWidth }}"
          alt="Issue screenshot"
          height="auto"
        />
        <div class="flex-column">
          <!-- Title -->
          <div class="field issue-title" matTooltip="Issue title">
            {{ selectedIssue?.summary?.title }}
          </div>

          <!-- Description -->
          <div
            class="{{ 'field ' + issueDescriptionClass }}"
            matTooltip="Issue description"
          >
            {{ selectedIssue?.summary?.description }}
          </div>

          <!-- URL -->
          <div class="field issue-url" matTooltip="Relative URL">
            {{ selectedIssue?.context?.url }}
          </div>

          <!-- User agent -->
          <div
            class="field issue-user-agent"
            *ngIf="selectedIssue?.context?.userAgent"
            matTooltip="User agent"
          >
            {{ selectedIssue?.context?.userAgent }}
          </div>

          <!-- Consent to contact the user -->
          <div
            class="field issue-contact-consent"
            matTooltip="Consent to contact the user"
          >
            {{ formatContactConsent }}
          </div>
        </div>
      </div>
      <div class="flex-column" *ngIf="selectedIssue">
        <!-- List of links to activities -->
        <h4>Activities In View</h4>
        <table>
          <tr *ngFor="let activityIdContext of selectedIssue.activityContexts">
            <td class="links">
              <a
                class="link"
                href="{{ 'activity/' + activityIdContext.activityId }}"
                >{{ activityIdContext.activityId }}</a
              >
            </td>
            <td class="links">
              <a
                class="link"
                href="{{ 'reactions/' + activityIdContext.activityId }}"
                >Reactions</a
              >
            </td>
            <td class="alias">
              <div class="alias">
                {{
                  activityIdContext.alias ? activityIdContext.alias : "no-alias"
                }}
              </div>
            </td>
          </tr>
        </table>

        <!-- List of comments -->
        <h4>Comments</h4>
        <table>
          <tr *ngFor="let comment of selectedIssue.summary?.comments">
            <td class="comments">
              {{ comment.userAlias }}
            </td>
            <td class="comments">
              {{ comment.comment }}
            </td>
          </tr>
        </table>

        <!-- Add comment -->
        <mat-form-field class="field issue-comment" [floatLabel]="'always'">
          <mat-label>New comment</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            [(ngModel)]="comment"
            placeholder="...add comment"
            maxlength="2000"
          ></textarea>
        </mat-form-field>

        <div class="flex-column">
          <!-- Type & State -->
          <div class="flex-row">
            <!-- Type -->
            <mat-form-field [floatLabel]="'always'" class="field issue-type">
              <mat-label>Type</mat-label>
              <mat-select [(ngModel)]="issueType" matTooltip="Issue type.">
                <mat-option *ngFor="let type of issueTypes" [value]="type">{{
                  type
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- State -->
            <mat-form-field [floatLabel]="'always'" class="field issue-state">
              <mat-label>State</mat-label>
              <mat-select
                [(ngModel)]="issueState"
                matTooltip="Issue state."
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option *ngFor="let state of issueStates" [value]="state">{{
                  state
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex-row">
            <!-- Resolution type -->
            <mat-form-field
              [floatLabel]="'always'"
              class="field issue-resolution-type"
            >
              <mat-label>Resolution Type</mat-label>
              <mat-select
                [(ngModel)]="resolutionType"
                matTooltip="Resolution type."
              >
                <mat-option
                  *ngFor="let type of resolutionTypes"
                  [value]="type"
                  >{{ type }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <!-- Removed activity ids -->
            <mat-form-field
              class="field issue-removed-activity-ids"
              [floatLabel]="'always'"
              *ngIf="resolutionType === 'Activity Removed'"
            >
              <mat-label>Removed activity ids</mat-label>
              <input
                matInput
                autocomplete="off"
                [(ngModel)]="removedActivityIds"
                placeholder="id1,id2"
                maxlength="500"
              />
            </mat-form-field>

            <!-- Delete user -->
            <div
              class="delete-user-div"
              *ngIf="resolutionType === 'User Removed'"
            >
              <!-- User alias -->
              <mat-form-field
                class="field issue-alias-to-remove"
                [floatLabel]="'always'"
              >
                <mat-label>User alias</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  [(ngModel)]="userAliasToDelete"
                  placeholder="alias"
                  maxlength="30"
                />
              </mat-form-field>

              <!-- User delete button -->
              <button
                [disabled]="!userAliasToDelete"
                mat-raised-button
                color="warn"
                class="delete-user-button"
                (click)="onDeleteUser()"
              >
                Delete User
              </button>
            </div>
          </div>

          <!-- Save button -->
          <div>
            <button
              mat-icon-button
              class="save-button"
              [disabled]="isSaveDisabled"
              (click)="onSave()"
              matTooltip="Save the issue"
            >
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<mat-paginator
  #issuesPaginator
  [pageSizeOptions]="[10, 20, 50]"
  aria-label="Select page"
  matTooltip="Select page"
></mat-paginator>
