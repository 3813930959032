import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  of,
} from 'rxjs';

import {
  GetSummariesRequest,
  GetSummariesResponse,
  Summary,
} from 'generated/src/main/proto/api/activity-service.pb';
import { ActivityServiceClient } from 'generated/src/main/proto/api/activity-service.pbsc';

import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  ItemLoadResult,
  LoadResult,
  ScrollDataSource,
} from 'src/app/common/scroll/scroll.component';
import { UserFilter } from 'src/app/common/user-filter/user-filter';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { FormatService } from '../format.service';
import { UserService } from '../user/user.service';
import { ActivitySummary } from './activity-summary';
import { Reactions } from 'generated/src/main/proto/shared/reaction.pb';

/* Queries and caches activity summaries. */
@Injectable({
  providedIn: 'root',
})
export class ActivitiesSource implements ScrollDataSource {
  private static PAGE_SIZE = 5;
  private static MAX_PAGES_IN_MEMORY = 10;

  private filter = new UserFilter();

  private cachedSummariesByIndex = new Map<number, ActivitySummary>();
  private cachedSummaryIndexById = new Map<string, number>();
  private cachedRanges: Array<ListRange> = [];
  private cachedPages = new Set<number>();
  private readTime: Timestamp | undefined;

  private readonly summariesSource = new BehaviorSubject<LoadResult>({
    resetView: false,
    items: new Map<number, ItemLoadResult>(),
  });

  private readonly viewerSubcriptions = new Map<
    CollectionViewer,
    Subscription
  >();

  constructor(
    private activityServiceClient: ActivityServiceClient,
    private userService: UserService,
    private bannerService: BannerService,
    private formatService: FormatService,
    private domSanitizer: DomSanitizer,
  ) {
    userService.user.subscribe((user) => {
      if (!user) {
        // Clear cache when the user logs out.
        this.clear();
      }
    });
  }

  reset(filter: UserFilter, force = false): void {
    if (!force && JSON.stringify(this.filter) == JSON.stringify(filter)) {
      return;
    }
    this.filter = filter;
    this.clear();
  }

  private clear(): void {
    this.cachedSummariesByIndex.clear();
    this.cachedSummaryIndexById.clear();
    this.cachedRanges.length = 0;
    this.cachedPages.clear();
    this.readTime = undefined;

    // Notify the view.
    this.summariesSource.next({
      resetView: true,
      items: new Map<number, ItemLoadResult>(),
    });
  }

  updateInMemory(): void {
    const updatedSummaries = new Map<number, ItemLoadResult>();
    this.cachedRanges.forEach((r) => {
      for (let index = r.start; index < r.end; index++) {
        const cachedSummary = this.cachedSummariesByIndex.get(index);
        if (cachedSummary != undefined) {
          const s = new ActivitySummary(
            cachedSummary.summary,
            this.formatService,
            this.domSanitizer,
          );
          this.cachedSummariesByIndex.set(index, s);
          this.cachedSummaryIndexById.set(s.summary.activityId, index);
          updatedSummaries.set(index, { error: undefined, data: s });
        }
      }
    });
    this.summariesSource.next({ resetView: false, items: updatedSummaries });
  }

  connect(collectionViewer: CollectionViewer): Observable<LoadResult> {
    const viewerSubscription = collectionViewer.viewChange.subscribe(
      (range) => {
        this.load(range);
      },
    );
    this.viewerSubcriptions.set(collectionViewer, viewerSubscription);
    return this.summariesSource;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    const viewerSubscription = this.viewerSubcriptions.get(collectionViewer);
    if (viewerSubscription) {
      this.viewerSubcriptions.delete(collectionViewer);
      viewerSubscription.unsubscribe();
    }
  }

  // TODO: remove this method after notifications are implemented.
  insertNewActivity(): void {
    if (this.filter.followingAlias) {
      // Skip adding new activity to the source if it won't show
      // because of the filter.
      return;
    }

    // Update map by id.
    const newMapIdToIndex = new Map<string, number>();
    this.cachedSummaryIndexById.forEach((value, key) => {
      newMapIdToIndex.set(key, value + 1);
    });
    this.cachedSummaryIndexById = newMapIdToIndex;

    // Update map by index and create updateMap to notify the view.
    const updateMap = new Map<number, ItemLoadResult>();
    const newMapByIndex = new Map<number, ActivitySummary>();
    this.cachedSummariesByIndex.forEach((value, key) => {
      newMapByIndex.set(key + 1, value);
      updateMap.set(key + 1, { error: undefined, data: value });
    });
    this.cachedSummariesByIndex.forEach((_, key) => {
      if (!updateMap.has(key)) {
        // Invalidate old index.
        updateMap.set(key, { error: undefined, data: undefined });
      }
    });
    this.cachedSummariesByIndex = newMapByIndex;

    // clear cache.
    this.cachedPages.clear();
    this.cachedRanges.length = 0;
    this.readTime = undefined;

    // Notify the view.
    this.summariesSource.next({ resetView: true, items: updateMap });
  }

  deleteActivity(activityId: string): void {
    const index = this.cachedSummaryIndexById.get(activityId);
    if (index == undefined) {
      // Not in cache - nothing to do.
      return;
    }

    // Update map by id.
    const newMapIdToIndex = new Map<string, number>();
    this.cachedSummaryIndexById.forEach((value, key) => {
      if (value != index) {
        newMapIdToIndex.set(key, value > index ? value - 1 : value);
      }
    });
    this.cachedSummaryIndexById = newMapIdToIndex;

    // Update map by index and create updateMap to notify the view.
    const updateMap = new Map<number, ItemLoadResult>();
    const newMapByIndex = new Map<number, ActivitySummary>();
    this.cachedSummariesByIndex.forEach((value, key) => {
      if (key != index) {
        const newKey = key > index ? key - 1 : key;
        newMapByIndex.set(newKey, value);
        if (newKey >= index) {
          updateMap.set(newKey, { error: undefined, data: value });
        }
      }
    });
    this.cachedSummariesByIndex.forEach((_, key) => {
      if (!updateMap.has(key) && key >= index) {
        // Invalidate old index.
        updateMap.set(key, { error: undefined, data: undefined });
      }
    });
    this.cachedSummariesByIndex = newMapByIndex;

    // clear cache.
    this.cachedPages.clear();
    this.cachedRanges.length = 0;
    this.readTime = undefined;

    // Notify the view.
    this.summariesSource.next({ resetView: true, items: updateMap });
  }

  updateSummary(summary: Summary) {
    const index = this.cachedSummaryIndexById.get(summary.activityId);
    if (index != undefined) {
      const s = new ActivitySummary(
        summary,
        this.formatService,
        this.domSanitizer,
      );
      this.cachedSummariesByIndex.set(index, s);

      const updateMap = new Map<number, ItemLoadResult>();
      updateMap.set(index, { error: undefined, data: s });
      this.summariesSource.next({ resetView: false, items: updateMap });
    }
  }

  updateReactions(activityId: string, reactions: Reactions): void {
    const index = this.cachedSummaryIndexById.get(activityId);
    if (index != undefined) {
      const previous = this.cachedSummariesByIndex.get(index);
      const summary = new Summary(previous?.summary);
      summary.reactions = reactions;
      const s = new ActivitySummary(
        summary,
        this.formatService,
        this.domSanitizer,
      );
      this.cachedSummariesByIndex.set(index, s);

      const updateMap = new Map<number, ItemLoadResult>();
      updateMap.set(index, { error: undefined, data: s });
      this.summariesSource.next({ resetView: false, items: updateMap });
    }
  }

  private load(range: ListRange): void {
    if (!this.userService.snapshotUser) {
      // The user is not logged in.
      return;
    }
    const startPageIndex = ActivitiesSource.indexToPageIndex(range.start);
    const endPageIndex = ActivitiesSource.indexToPageIndex(range.end - 1);
    for (
      let pageIndex = startPageIndex;
      pageIndex <= endPageIndex;
      pageIndex++
    ) {
      const request = new GetSummariesRequest();
      request.startIndex = pageIndex * ActivitiesSource.PAGE_SIZE;
      if (this.cachedPages.has(request.startIndex)) {
        this.summariesSource.next({
          resetView: false,
          items: this.getPageUpdateMap(pageIndex),
        });
        continue;
      }
      request.count = ActivitiesSource.PAGE_SIZE;
      if (this.readTime) {
        request.readTime = this.readTime;
      }
      // Customize depending on view.
      if (this.filter.loggedInUser) {
        request.loggedInUser = true;
      } else if (this.filter.followingAlias) {
        request.alias = this.filter.followingAlias;
      } else {
        request.followingAndSelf = true;
      }
      this.activityServiceClient
        .getSummaries(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return of('');
          }),
        )
        .subscribe((r) => {
          if (r instanceof GetSummariesResponse) {
            this.readTime = r.readTime;
            if (r.summaries != undefined) {
              const displaySummaries = r.summaries.map((s) => {
                return new ActivitySummary(
                  s,
                  this.formatService,
                  this.domSanitizer,
                );
              });
              if (!this.cachedPages.has(request.startIndex)) {
                const updateMap = new Map<number, ItemLoadResult>();
                displaySummaries.forEach((s, index) => {
                  const newIndex = request.startIndex + index;
                  updateMap.set(newIndex, { error: undefined, data: s });
                  this.cachedSummariesByIndex.set(newIndex, s);
                  this.cachedSummaryIndexById.set(
                    s.summary.activityId,
                    newIndex,
                  );
                });
                this.cachedRanges.push({
                  start: request.startIndex,
                  end: request.startIndex + r.summaries.length,
                });
                this.cachedPages.add(request.startIndex);

                this.evictOlderRanges();

                // If the page is incomplete, there are no more items, notify the view
                // by adding undefined for not loaded items.
                for (
                  let index = displaySummaries.length;
                  index < ActivitiesSource.PAGE_SIZE;
                  index++
                ) {
                  updateMap.set(request.startIndex + index, {
                    error: undefined,
                    data: undefined,
                  });
                }

                this.summariesSource.next({
                  resetView: false,
                  items: updateMap,
                });
              }
            }
          }
        });
    }
  }

  private getPageUpdateMap(pageIndex: number): Map<number, ItemLoadResult> {
    const updateMap = new Map<number, ItemLoadResult>();
    const startIndex = pageIndex * ActivitiesSource.PAGE_SIZE;
    const endIndex = startIndex + ActivitiesSource.PAGE_SIZE;
    for (let index = startIndex; index < endIndex; index++) {
      updateMap.set(index, {
        error: undefined,
        data: this.cachedSummariesByIndex.get(index),
      });
    }
    return updateMap;
  }

  private evictOlderRanges() {
    if (this.cachedRanges.length > ActivitiesSource.MAX_PAGES_IN_MEMORY) {
      // Remove last used ranges.
      const removedRanges = this.cachedRanges.splice(
        0,
        this.cachedRanges.length - ActivitiesSource.MAX_PAGES_IN_MEMORY,
      );

      // Mark objects as undefined and delete from the lookup by id.
      removedRanges.forEach((r) => {
        for (let index = r.start; index < r.end; index++) {
          const cachedSummary = this.cachedSummariesByIndex.get(index);
          if (cachedSummary != undefined) {
            this.cachedSummaryIndexById.delete(
              cachedSummary.summary.activityId,
            );
            this.cachedSummariesByIndex.delete(index);
          }
        }

        // Remove the page from cache.
        this.cachedPages.delete(r.start);
      });
    }
  }

  private static indexToPageIndex(index: number): number {
    return Math.floor(index / ActivitiesSource.PAGE_SIZE);
  }
}
