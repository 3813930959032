import { Component } from '@angular/core';
import { UserRole } from 'generated/src/main/proto/api/user-service.pb';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent {
  private currentUserRoles: UserRole[] = [];

  alias = '';
  roles: UserRole[] = [];
  availableRoles: UserRole[] = [];

  roleToAdd = '';
  availableToAdd: string[] = [];

  readonly roleColumns = ['name', 'description', 'delete'];

  constructor(private userService: UserService) {
    this.userService.getAvailableRoles().then((roles) => {
      this.availableRoles = roles.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  onAliasKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.getUserRoles();
    }
  }

  onRoleToAddChanged() {
    if (this.roles.findIndex((r) => r.name == this.roleToAdd) < 0) {
      const index = this.availableRoles.findIndex(
        (r) => r.name == this.roleToAdd,
      );
      this.roles.push(this.availableRoles[index]);
      this.roles.sort((a, b) => a.name.localeCompare(b.name));
      this.roles = Array.from(this.roles);
      this.initAvailableToAdd();
    }
  }

  deleteRole(name: string) {
    this.roles = this.roles.filter((r) => r.name != name);
    this.initAvailableToAdd();
  }

  getUserRoles(): void {
    this.userService.getUserRoles(this.alias).then((roles) => {
      roles = roles.sort((a, b) => a.name.localeCompare(b.name));
      this.currentUserRoles = Array.from(roles);
      this.roles = roles;
      this.initAvailableToAdd();
    });
  }

  isSaveDisabled(): boolean {
    if (this.roles.length != this.currentUserRoles.length) {
      return false;
    }

    return (
      this.roles
        .map((r) => r.name)
        .sort()
        .join() ==
      this.currentUserRoles
        .map((r) => r.name)
        .sort()
        .join()
    );
  }

  save(): void {
    this.userService
      .updateUserRoles(
        this.alias,
        this.roles.map((r) => r.name),
      )
      .then(() => {
        this.currentUserRoles = Array.from(this.roles);
      });
  }

  private initAvailableToAdd() {
    this.availableToAdd = this.availableRoles
      .filter(
        (r) => this.roles.findIndex((userRole) => userRole.name == r.name) < 0,
      )
      .map((role) => role.name);
  }
}
