/** Creates a promise to wait on a condition. */
export class WaitPromise {
  private timerIntervalMs = 100;
  private readonly startTime = new Date();

  private constructor(
    private conditionFunc: () => boolean,
    private timeoutMs: number,
    timerIntervalMs?: number,
  ) {
    if (timerIntervalMs) {
      this.timerIntervalMs = timerIntervalMs;
    }
  }

  public static create(
    timeoutMs: number,
    conditionFunc: () => boolean,
    timerIntervalMs?: number,
  ): Promise<boolean> {
    const waitPromise = new WaitPromise(
      conditionFunc,
      timeoutMs,
      timerIntervalMs,
    );
    return new Promise<boolean>((resolve, reject) =>
      waitPromise.checkCondition(resolve, reject),
    );
  }

  private checkCondition(
    resolve: (value: boolean | PromiseLike<boolean>) => void,
    // eslint-disable-next-line
    reject: (reason?: any) => void,
  ): void {
    if (this.conditionFunc()) {
      resolve(true);
    } else {
      const currentTime = new Date();
      if (currentTime.getTime() - this.startTime.getTime() > this.timeoutMs) {
        reject('Timed out');
      } else {
        setTimeout(
          () => this.checkCondition(resolve, reject),
          this.timerIntervalMs,
        );
      }
    }
  }
}
