import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Summary } from 'generated/src/main/proto/api/activity-service.pb';
import { Subscription, timer } from 'rxjs';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import { GarminBackfillComponent } from '../common/garmin-backfill/garmin-backfill.component';
import { ActivityReactionUpdater } from '../common/reactions/activity-reaction-updater';
import { Scrollable } from '../common/scroll/scroll.component';
import { UserFilter } from '../common/user-filter/user-filter';
import { ReactionDetailsDialogComponent } from '../reaction-details/reaction-details.dialog';
import { ActivitiesSource } from '../services/activities/activities-source';
import { ActivitiesService } from '../services/activities/activities.service';
import { ActivitySummary } from '../services/activities/activity-summary';
import { BannerService } from '../services/banner/banner.service';
import { DeviceDetectorService } from '../services/device-detector.service';
import { FormatService } from '../services/format.service';
import { GarminService } from '../services/garmin/garmin.service';
import { ReadFileService } from '../services/read-file/read-file.service';
import { StateSaverService } from '../services/state-saver/state-saver.service';
import {
  LoggedInUser,
  UnitPreference,
  UserService,
} from '../services/user/user.service';

/** Shows a feed of activities. */
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements AfterViewInit, OnInit, OnDestroy {
  private galleryMargin: number;
  private activityItemMargin: number;
  galleryWidth: number;
  galleryHeight: number;
  activityItemHeight: number;

  itemStyle: object;
  titleStyle: object;
  nameStyle: object;

  @ViewChild('fileInput') fileInput!: ElementRef;
  fileName = '';

  unitPreference: UnitPreference = UnitPreference.Imperial;
  activitiesSource: ActivitiesSource;

  @ViewChild('viewPort') viewPort!: Scrollable;
  userFilter!: UserFilter;

  private userAlias!: string;
  private userSubscription: Subscription;

  constructor(
    private activitiesService: ActivitiesService,
    private bannerService: BannerService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router,
    private dialog: MatDialog,
    private stateSaverService: StateSaverService,
    private readFileService: ReadFileService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    formatService: FormatService,
    userService: UserService,
    private garminService: GarminService,
  ) {
    this.activitiesSource = activitiesService.allActivitiesSource;

    // Initialize dimensions:
    //   1. Gallery width/height define image dimensions.
    //   2. Activity item height is an estimate used in scroll.
    //   3. Max title width for text ellipses.
    const activityItemHeaderHeight = 140;
    const activityItemReactionsHeight = 72;
    this.galleryMargin = 7;
    this.activityItemMargin = 5;
    this.galleryWidth = formatService.viewWidth;
    const galleryWidthWithMargin = this.galleryWidth;
    this.galleryHeight = Math.floor(0.7 * this.galleryWidth);
    this.galleryWidth -= 2 * this.galleryMargin;
    this.galleryHeight -= 2 * this.galleryMargin;
    this.activityItemHeight =
      activityItemHeaderHeight +
      this.galleryHeight +
      2 * this.galleryMargin +
      activityItemReactionsHeight +
      2 * this.activityItemMargin;
    const maxTitleWidth = this.galleryWidth;
    const maxNameWidth = 0.7 * this.galleryWidth;

    this.itemStyle = { 'min-width': galleryWidthWithMargin + 'px' };
    this.titleStyle = { 'max-width': maxTitleWidth + 'px' };
    this.nameStyle = { 'max-width': maxNameWidth + 'px' };

    // Restore some state.
    const state = this.stateSaverService.get('activities');
    if (state) {
      this.userAlias = state.state?.get('alias') as string;
    }

    // Subscribe to user changes.
    this.userSubscription = userService.user.subscribe((user) => {
      if (user) {
        this.onUserChanged(user);
      }
    });
  }

  ngAfterViewInit(): void {
    const state = this.stateSaverService.get('activities');
    if (state) {
      timer(1).subscribe(() => this.viewPort.scrollTo(state.scrollPosition!.y));
    }
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.userFilter = UserFilter.fromAlias(
        this.userAlias,
        params.get('alias') as string,
      );
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();

    const position = {
      x: 0,
      y: this.viewPort.scrollTop(),
    };
    this.stateSaverService.set('activities', {
      scrollPosition: position,
      state: new Map<string, string>([['alias', this.userAlias]]),
    });
  }

  itemClass(): string {
    return this.deviceDetectorService.isMobile()
      ? 'activityItemMobile'
      : 'activityItem';
  }

  createReactionUpdater(summary: Summary): ActivityReactionUpdater {
    return new ActivityReactionUpdater(
      this.activitiesService,
      this.bannerService,
      summary,
      this.userAlias,
    );
  }

  onRefresh(): void {
    this.activitiesSource.reset(this.userFilter, true);
  }

  onFileSelected(event: Event): void {
    const file: File = (event.target as HTMLInputElement).files![0];

    if (file) {
      this.fileName = file.name;
      this.readFileService.readFile(file).then((data) => {
        const fileExtension = file.name.split('.').pop();
        this.activitiesService.uploadActivity(
          new Uint8Array(data),
          fileExtension,
        );
      });
      this.fileInput.nativeElement.value = '';
    }
  }

  onItemClick(e: MouseEvent, activity: ActivitySummary) {
    if (
      e.target instanceof SVGPathElement ||
      e.target instanceof SVGSVGElement
    ) {
      // Ignore click on SVG as these are navigation buttons in the gallery.
      return;
    }
    e.stopPropagation();
    this.router.navigate(['activity', activity.summary.activityId]);
  }

  onItemKeyDown(e: KeyboardEvent, activity: ActivitySummary) {
    if (e.key == 'Enter') {
      e.stopPropagation();
      this.router.navigate(['activity', activity.summary.activityId]);
    }
  }

  onUserFilterChange(userFilter: UserFilter) {
    userFilter.updateLocation(this.location, '/activities', this.userAlias);
    this.activitiesSource.reset(userFilter);
  }

  onClickReactionsSummary(activityId: string, left: number) {
    const top = Math.round((1 * window.innerHeight) / 3);
    const height = window.innerHeight - top;

    ReactionDetailsDialogComponent.openDialog(
      this.dialog,
      activityId,
      top,
      left + this.galleryMargin,
      this.galleryWidth,
      height,
    );
  }

  onDataInViewChange(datas: readonly object[]) {
    const summaries = datas as ActivitySummary[];
    this.stateSaverService.activityIdsInView = summaries.map(
      (v) => v.summary.activityId,
    );
  }

  private onUserChanged(user: LoggedInUser) {
    // Activities source may not be reset initially.
    if (user.alias && this.userAlias != user.alias) {
      this.activitiesSource.reset(this.userFilter, true);
    }
    this.userAlias = user.alias!;
    if (user.unitPreference != this.unitPreference) {
      this.unitPreference = user.unitPreference;
      this.activitiesSource.updateInMemory();
    }
  }

  backfillFromGarmin(): void {
    this.garminService.getBackfillStatus().then((r) => {
      if (!r.loggedIn) {
        // TODO: maintain user state in-memory,
        //   don't show backfill menu item if the user is not logged-in to Garmin.
        //   And, remove this.
        ConfirmationComponent.openAlert(
          this.dialog,
          'First, please log-in to Garmin under Profile/Integrations.',
          () => {},
        );
      } else {
        const left = this.getActivitiesLeft();
        const top = Math.round((1 * window.innerHeight) / 3);
        const height = window.innerHeight - top;
        GarminBackfillComponent.show(
          this.dialog,
          top,
          left + this.galleryMargin,
          this.galleryWidth,
          height,
          r.backfillStates!,
          (result) => {
            if (result.submit) {
              this.garminService.requestBackfill(
                result.startDate!,
                result.endDate!,
              );
            }
          },
        );
      }
    });
  }

  private getActivitiesLeft(): number {
    const viewWidth = window.innerWidth;
    return Math.round((viewWidth - this.galleryWidth) / 2) - this.galleryMargin;
  }
}
