<!-- Toolbar -->
<input #fileInput type="file" class="file-input" (change)="onFileSelected($event)" />
<div class="tool-bar">
  <!-- User filtering menu -->
  <app-user-filter [(userFilter)]="userFilter" (userFilterChange)="onUserFilterChange($event)" matTooltip="User filter"
    [stateName]="'activitiesUserFilter'"></app-user-filter>

  <!-- Refresh button -->
  <button mat-icon-button class="refresh-button" aria-label="Refresh button" matTooltip="Refresh feed"
    (click)="onRefresh()">
    <mat-icon>refresh</mat-icon>
  </button>

  <!-- Three dots menu -->
  <button mat-icon-button class="three-dots-button" [matMenuTriggerFor]="threeDotsMenu"
    aria-label="Three dots menu button" matTooltip="More actions">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #threeDotsMenu="matMenu">
    <button mat-menu-item (click)="fileInput.click()" aria-label="Button to select activity file">
      <span>Import Activity</span>
    </button>
    <button mat-menu-item (click)="backfillFromGarmin()" aria-label="Button to initiate data backfill from Garmin">
      <span>Garmin Backfill...</span>
    </button>
  </mat-menu>
</div>

<app-scroll #viewPort class="activities-viewport" [itemHeight]="activityItemHeight" [itemTemplate]="activityTemplate"
  [dataSource]="activitiesSource" (dataInViewChange)="onDataInViewChange($event)"></app-scroll>

<ng-template #activityTemplate let-activity="data">
  <div *ngIf="activity !== undefined" #activityElement class="activity-item" id="{{ activity.summary.activityId }}"
    [ngStyle]="itemStyle">
    <div class="activity-item-clickable" tabindex="0" (click)="onItemClick($event, activity)"
      (keydown)="onItemKeyDown($event, activity)">
      <span *ngIf="activity.summary.title && activity.summary.title !== 'untitled'" class="title mat-headline-5"
        [ngStyle]="titleStyle">{{ activity.summary.title
        }}</span>
      <div class="card-content">
        <div class="top-line">
          <app-profile-image [alias]="activity.summary.alias"></app-profile-image>
          <div class="name top-line-item" [ngStyle]="nameStyle">
            {{
            activity.summary.firstName + " " + activity.summary.lastName
            }}
          </div>
          <div class="activity-type top-line-item">{{ activity.activityType }}</div>
        </div>
        <div class="measurements">
          <app-measurement-widget [measurement]="activity.startTime"></app-measurement-widget>
          <app-measurement-widget [measurement]="activity.duration"></app-measurement-widget>
        </div>
        <div class="measurements">
          <app-measurement-widget *ngFor="let measurement of activity.measurements"
            [measurement]="measurement"></app-measurement-widget>
        </div>
      </div>
    </div>
    <!-- Gallery -->
    <app-gallery *ngIf="activity.galleryItems.length > 0" [id]="activity.summary.activityId"
      [items]="activity.galleryItems" [width]="galleryWidth" [height]="galleryHeight" [scrollable]="true"></app-gallery>
    <!-- Description -->
    <div class="description" *ngIf="activity.descriptionHtml" [ngStyle]="{ 'width': galleryWidth + 'px' }"
      [innerHTML]="activity.descriptionHtml"></div>
    <!-- Reactions -->
    <app-reactions [updater]="createReactionUpdater(activity.summary)" (clickReactionsSummary)="
          onClickReactionsSummary(
            activity.summary.activityId,
            activityElement.offsetLeft
          )
        "></app-reactions>
  </div>
  <div *ngIf="activity === undefined" class="activity-item" [ngStyle]="{
      width: galleryWidth + 'px',
      height: activityItemHeight + 'px'
    }">
    Loading...
  </div>
</ng-template>
