<mat-form-field
  *ngIf="editMode"
  class="input-field"
  [appearance]="'outline'"
  appSpy
  (spyAfterContentInit)="onInputAfterContentInit()"
>
  <input
    #edit
    matInput
    [(ngModel)]="value"
    required
    [ngModelOptions]="{ standalone: true }"
    (blur)="onEditComplete()"
    (keydown)="onInputKeyDown($event)"
  />
</mat-form-field>
<div
  tabindex="0"
  *ngIf="!editMode"
  class="view-field"
  (click)="onClick()"
  (keydown)="onViewKeyDown($event)"
  matTooltip="{{ toolTip() }}"
>
  <h3 class="view-text mat-typography">{{ value }}</h3>
</div>
