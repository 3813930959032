import { Location } from '@angular/common';

/** Describes how to filter activities/calendar/etc. */
export class UserFilter {
  all = false;

  loggedInUser = true;

  followingAlias = '';

  setAll(): void {
    this.clear();
    this.all = true;
  }

  setLoggedInUser(): void {
    this.clear();
    this.loggedInUser = true;
  }

  setFollowingAlias(value: string): void {
    this.clear();
    this.followingAlias = value;
  }

  static fromAlias(userAlias: string, alias?: string): UserFilter {
    const userFilter = new UserFilter();
    if (alias) {
      if (alias == userAlias) {
        userFilter.setLoggedInUser();
      } else {
        userFilter.setFollowingAlias(alias);
      }
    } else {
      userFilter.setAll();
    }
    return userFilter;
  }

  updateLocation(
    location: Location,
    basePath: string,
    loggedInAlias: string,
  ): void {
    let newPath: string;
    if (this.followingAlias) {
      newPath = `${basePath}/${this.followingAlias}`;
    } else if (this.loggedInUser) {
      newPath = `${basePath}/${loggedInAlias}`;
    } else {
      newPath = basePath;
    }
    if (newPath != location.path()) {
      location.go(newPath);
    }
  }

  private clear(): void {
    this.all = false;
    this.loggedInUser = false;
    this.followingAlias = '';
  }
}
