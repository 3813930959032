import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationControlComponent } from './notification-control.component';

@NgModule({
  declarations: [NotificationControlComponent],
  exports: [NotificationControlComponent],
  imports: [CommonModule, MatCheckboxModule, MatTooltipModule],
})
export class NotificationControlModule {}
