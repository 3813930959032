/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/shared/activity-shared.pb';
import * as shared002 from '../../../../src/main/proto/shared/media-shared.pb';
import * as shared003 from '../../../../src/main/proto/shared/reaction.pb';
/**
 * Message implementation for api.activity.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.activity.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.activityId = _instance.activityId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
  }

  private _activityId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    GetRequest.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      activityId: this.activityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      activityId: this.activityId
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    activityId: string;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    activityId: string;
  }
}

/**
 * Message implementation for api.activity.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.activity.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.activity = _instance.activity || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activity = new Activity();
          _reader.readMessage(
            _instance.activity,
            Activity.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.activity) {
      _writer.writeMessage(
        1,
        _instance.activity as any,
        Activity.serializeBinaryToWriter
      );
    }
  }

  private _activity?: Activity;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.activity = _value.activity ? new Activity(_value.activity) : undefined;
    GetResponse.refineValues(this);
  }
  get activity(): Activity | undefined {
    return this._activity;
  }
  set activity(value: Activity | undefined) {
    this._activity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      activity: this.activity ? this.activity.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      activity: this.activity ? this.activity.toProtobufJSON(options) : null
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    activity?: Activity.AsObject;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    activity: Activity.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.activity.GetSummariesRequest
 */
export class GetSummariesRequest implements GrpcMessage {
  static id = 'api.activity.GetSummariesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSummariesRequest();
    GetSummariesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSummariesRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startIndex = _instance.startIndex || 0;
    _instance.count = _instance.count || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSummariesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startIndex = _reader.readInt32();
          break;
        case 3:
          _instance.count = _reader.readInt32();
          break;
        case 4:
          _instance.loggedInUser = _reader.readBool();
          break;
        case 5:
          _instance.alias = _reader.readString();
          break;
        case 6:
          _instance.followingAndSelf = _reader.readBool();
          break;
        case 7:
          _instance.activityIds = new ActivityIds();
          _reader.readMessage(
            _instance.activityIds,
            ActivityIds.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetSummariesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSummariesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startIndex) {
      _writer.writeInt32(2, _instance.startIndex);
    }
    if (_instance.count) {
      _writer.writeInt32(3, _instance.count);
    }
    if (_instance.loggedInUser || _instance.loggedInUser === false) {
      _writer.writeBool(4, _instance.loggedInUser);
    }
    if (_instance.alias || _instance.alias === '') {
      _writer.writeString(5, _instance.alias);
    }
    if (_instance.followingAndSelf || _instance.followingAndSelf === false) {
      _writer.writeBool(6, _instance.followingAndSelf);
    }
    if (_instance.activityIds) {
      _writer.writeMessage(
        7,
        _instance.activityIds as any,
        ActivityIds.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startIndex: number;
  private _count: number;
  private _loggedInUser: boolean;
  private _alias: string;
  private _followingAndSelf: boolean;
  private _activityIds?: ActivityIds;

  private _filter: GetSummariesRequest.FilterCase =
    GetSummariesRequest.FilterCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSummariesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSummariesRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startIndex = _value.startIndex;
    this.count = _value.count;
    this.loggedInUser = _value.loggedInUser;
    this.alias = _value.alias;
    this.followingAndSelf = _value.followingAndSelf;
    this.activityIds = _value.activityIds
      ? new ActivityIds(_value.activityIds)
      : undefined;
    GetSummariesRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startIndex(): number {
    return this._startIndex;
  }
  set startIndex(value: number) {
    this._startIndex = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }
  get loggedInUser(): boolean {
    return this._loggedInUser;
  }
  set loggedInUser(value: boolean) {
    if (value !== undefined && value !== null) {
      this._alias = this._followingAndSelf = this._activityIds = undefined;
      this._filter = GetSummariesRequest.FilterCase.loggedInUser;
    }
    this._loggedInUser = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    if (value !== undefined && value !== null) {
      this._loggedInUser = this._followingAndSelf = this._activityIds = undefined;
      this._filter = GetSummariesRequest.FilterCase.alias;
    }
    this._alias = value;
  }
  get followingAndSelf(): boolean {
    return this._followingAndSelf;
  }
  set followingAndSelf(value: boolean) {
    if (value !== undefined && value !== null) {
      this._loggedInUser = this._alias = this._activityIds = undefined;
      this._filter = GetSummariesRequest.FilterCase.followingAndSelf;
    }
    this._followingAndSelf = value;
  }
  get activityIds(): ActivityIds | undefined {
    return this._activityIds;
  }
  set activityIds(value: ActivityIds | undefined) {
    if (value !== undefined && value !== null) {
      this._loggedInUser = this._alias = this._followingAndSelf = undefined;
      this._filter = GetSummariesRequest.FilterCase.activityIds;
    }
    this._activityIds = value;
  }
  get filter() {
    return this._filter;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSummariesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSummariesRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startIndex: this.startIndex,
      count: this.count,
      loggedInUser: this.loggedInUser,
      alias: this.alias,
      followingAndSelf: this.followingAndSelf,
      activityIds: this.activityIds ? this.activityIds.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSummariesRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startIndex: this.startIndex,
      count: this.count,
      loggedInUser: this.loggedInUser,
      alias:
        this.alias === null || this.alias === undefined ? null : this.alias,
      followingAndSelf: this.followingAndSelf,
      activityIds: this.activityIds
        ? this.activityIds.toProtobufJSON(options)
        : null
    };
  }
}
export module GetSummariesRequest {
  /**
   * Standard JavaScript object representation for GetSummariesRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startIndex: number;
    count: number;
    loggedInUser: boolean;
    alias: string;
    followingAndSelf: boolean;
    activityIds?: ActivityIds.AsObject;
  }

  /**
   * Protobuf JSON representation for GetSummariesRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startIndex: number;
    count: number;
    loggedInUser: boolean;
    alias: string | null;
    followingAndSelf: boolean;
    activityIds: ActivityIds.AsProtobufJSON | null;
  }
  export enum FilterCase {
    none = 0,
    loggedInUser = 1,
    alias = 2,
    followingAndSelf = 3,
    activityIds = 4
  }
}

/**
 * Message implementation for api.activity.ActivityIds
 */
export class ActivityIds implements GrpcMessage {
  static id = 'api.activity.ActivityIds';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityIds();
    ActivityIds.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityIds) {
    _instance.activityIds = _instance.activityIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityIds,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.activityIds = _instance.activityIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityIds.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityIds,
    _writer: BinaryWriter
  ) {
    if (_instance.activityIds && _instance.activityIds.length) {
      _writer.writePackedInt64String(1, _instance.activityIds);
    }
  }

  private _activityIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityIds to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityIds.AsObject>) {
    _value = _value || {};
    this.activityIds = (_value.activityIds || []).slice();
    ActivityIds.refineValues(this);
  }
  get activityIds(): string[] {
    return this._activityIds;
  }
  set activityIds(value: string[]) {
    this._activityIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityIds.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityIds.AsObject {
    return {
      activityIds: (this.activityIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityIds.AsProtobufJSON {
    return {
      activityIds: (this.activityIds || []).slice()
    };
  }
}
export module ActivityIds {
  /**
   * Standard JavaScript object representation for ActivityIds
   */
  export interface AsObject {
    activityIds: string[];
  }

  /**
   * Protobuf JSON representation for ActivityIds
   */
  export interface AsProtobufJSON {
    activityIds: string[];
  }
}

/**
 * Message implementation for api.activity.GetSummariesResponse
 */
export class GetSummariesResponse implements GrpcMessage {
  static id = 'api.activity.GetSummariesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSummariesResponse();
    GetSummariesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSummariesResponse) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.summaries = _instance.summaries || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSummariesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new Summary();
          _reader.readMessage(
            messageInitializer2,
            Summary.deserializeBinaryFromReader
          );
          (_instance.summaries = _instance.summaries || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetSummariesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSummariesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.summaries && _instance.summaries.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.summaries as any,
        Summary.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _summaries?: Summary[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSummariesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSummariesResponse.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.summaries = (_value.summaries || []).map(m => new Summary(m));
    GetSummariesResponse.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get summaries(): Summary[] | undefined {
    return this._summaries;
  }
  set summaries(value: Summary[] | undefined) {
    this._summaries = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSummariesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSummariesResponse.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      summaries: (this.summaries || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSummariesResponse.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      summaries: (this.summaries || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetSummariesResponse {
  /**
   * Standard JavaScript object representation for GetSummariesResponse
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    summaries?: Summary.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetSummariesResponse
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    summaries: Summary.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.activity.GetStatsRequest
 */
export class GetStatsRequest implements GrpcMessage {
  static id = 'api.activity.GetStatsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetStatsRequest();
    GetStatsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetStatsRequest) {
    _instance.timeIntervals = _instance.timeIntervals || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetStatsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new shared001.StatsRequestTimeInterval();
          _reader.readMessage(
            messageInitializer2,
            shared001.StatsRequestTimeInterval.deserializeBinaryFromReader
          );
          (_instance.timeIntervals = _instance.timeIntervals || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetStatsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetStatsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias !== undefined && _instance.alias !== null) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.timeIntervals && _instance.timeIntervals.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.timeIntervals as any,
        shared001.StatsRequestTimeInterval.serializeBinaryToWriter
      );
    }
  }

  private _alias?: string;
  private _timeIntervals?: shared001.StatsRequestTimeInterval[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetStatsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetStatsRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.timeIntervals = (_value.timeIntervals || []).map(
      m => new shared001.StatsRequestTimeInterval(m)
    );
    GetStatsRequest.refineValues(this);
  }
  get alias(): string | undefined {
    return this._alias;
  }
  set alias(value?: string) {
    this._alias = value;
  }
  get timeIntervals(): shared001.StatsRequestTimeInterval[] | undefined {
    return this._timeIntervals;
  }
  set timeIntervals(value: shared001.StatsRequestTimeInterval[] | undefined) {
    this._timeIntervals = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetStatsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetStatsRequest.AsObject {
    return {
      alias: this.alias,
      timeIntervals: (this.timeIntervals || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetStatsRequest.AsProtobufJSON {
    return {
      alias:
        this.alias === null || this.alias === undefined ? null : this.alias,
      timeIntervals: (this.timeIntervals || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetStatsRequest {
  /**
   * Standard JavaScript object representation for GetStatsRequest
   */
  export interface AsObject {
    alias?: string;
    timeIntervals?: shared001.StatsRequestTimeInterval.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetStatsRequest
   */
  export interface AsProtobufJSON {
    alias: string | null;
    timeIntervals: shared001.StatsRequestTimeInterval.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.activity.GetStatsResponse
 */
export class GetStatsResponse implements GrpcMessage {
  static id = 'api.activity.GetStatsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetStatsResponse();
    GetStatsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetStatsResponse) {
    _instance.stats = _instance.stats || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetStatsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new StatsResponse();
          _reader.readMessage(
            messageInitializer1,
            StatsResponse.deserializeBinaryFromReader
          );
          (_instance.stats = _instance.stats || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetStatsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetStatsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.stats && _instance.stats.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.stats as any,
        StatsResponse.serializeBinaryToWriter
      );
    }
  }

  private _stats?: StatsResponse[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetStatsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetStatsResponse.AsObject>) {
    _value = _value || {};
    this.stats = (_value.stats || []).map(m => new StatsResponse(m));
    GetStatsResponse.refineValues(this);
  }
  get stats(): StatsResponse[] | undefined {
    return this._stats;
  }
  set stats(value: StatsResponse[] | undefined) {
    this._stats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetStatsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetStatsResponse.AsObject {
    return {
      stats: (this.stats || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetStatsResponse.AsProtobufJSON {
    return {
      stats: (this.stats || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetStatsResponse {
  /**
   * Standard JavaScript object representation for GetStatsResponse
   */
  export interface AsObject {
    stats?: StatsResponse.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetStatsResponse
   */
  export interface AsProtobufJSON {
    stats: StatsResponse.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.activity.StatsResponse
 */
export class StatsResponse implements GrpcMessage {
  static id = 'api.activity.StatsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StatsResponse();
    StatsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StatsResponse) {
    _instance.granularity = _instance.granularity || 0;
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
    _instance.totalStats = _instance.totalStats || undefined;
    _instance.statsByActivityType = _instance.statsByActivityType || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StatsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.granularity = _reader.readEnum();
          break;
        case 2:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.totalStats = new shared001.IntervalStats();
          _reader.readMessage(
            _instance.totalStats,
            shared001.IntervalStats.deserializeBinaryFromReader
          );
          break;
        case 5:
          const messageInitializer5 = new shared001.StatsByActivityType();
          _reader.readMessage(
            messageInitializer5,
            shared001.StatsByActivityType.deserializeBinaryFromReader
          );
          (_instance.statsByActivityType =
            _instance.statsByActivityType || []).push(messageInitializer5);
          break;
        default:
          _reader.skipField();
      }
    }

    StatsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StatsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.granularity) {
      _writer.writeEnum(1, _instance.granularity);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        2,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        3,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.totalStats) {
      _writer.writeMessage(
        4,
        _instance.totalStats as any,
        shared001.IntervalStats.serializeBinaryToWriter
      );
    }
    if (_instance.statsByActivityType && _instance.statsByActivityType.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.statsByActivityType as any,
        shared001.StatsByActivityType.serializeBinaryToWriter
      );
    }
  }

  private _granularity: shared001.StatsGranularity;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;
  private _totalStats?: shared001.IntervalStats;
  private _statsByActivityType?: shared001.StatsByActivityType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StatsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<StatsResponse.AsObject>) {
    _value = _value || {};
    this.granularity = _value.granularity;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    this.totalStats = _value.totalStats
      ? new shared001.IntervalStats(_value.totalStats)
      : undefined;
    this.statsByActivityType = (_value.statsByActivityType || []).map(
      m => new shared001.StatsByActivityType(m)
    );
    StatsResponse.refineValues(this);
  }
  get granularity(): shared001.StatsGranularity {
    return this._granularity;
  }
  set granularity(value: shared001.StatsGranularity) {
    this._granularity = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }
  get totalStats(): shared001.IntervalStats | undefined {
    return this._totalStats;
  }
  set totalStats(value: shared001.IntervalStats | undefined) {
    this._totalStats = value;
  }
  get statsByActivityType(): shared001.StatsByActivityType[] | undefined {
    return this._statsByActivityType;
  }
  set statsByActivityType(value: shared001.StatsByActivityType[] | undefined) {
    this._statsByActivityType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StatsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StatsResponse.AsObject {
    return {
      granularity: this.granularity,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
      totalStats: this.totalStats ? this.totalStats.toObject() : undefined,
      statsByActivityType: (this.statsByActivityType || []).map(m =>
        m.toObject()
      )
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StatsResponse.AsProtobufJSON {
    return {
      granularity:
        shared001.StatsGranularity[
          this.granularity === null || this.granularity === undefined
            ? 0
            : this.granularity
        ],
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
      totalStats: this.totalStats
        ? this.totalStats.toProtobufJSON(options)
        : null,
      statsByActivityType: (this.statsByActivityType || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module StatsResponse {
  /**
   * Standard JavaScript object representation for StatsResponse
   */
  export interface AsObject {
    granularity: shared001.StatsGranularity;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
    totalStats?: shared001.IntervalStats.AsObject;
    statsByActivityType?: shared001.StatsByActivityType.AsObject[];
  }

  /**
   * Protobuf JSON representation for StatsResponse
   */
  export interface AsProtobufJSON {
    granularity: string;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    totalStats: shared001.IntervalStats.AsProtobufJSON | null;
    statsByActivityType: shared001.StatsByActivityType.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.activity.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.activity.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.activityId = _instance.activityId || '0';
    _instance.summary = _instance.summary || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        case 2:
          _instance.summary = new Summary();
          _reader.readMessage(
            _instance.summary,
            Summary.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
    if (_instance.summary) {
      _writer.writeMessage(
        2,
        _instance.summary as any,
        Summary.serializeBinaryToWriter
      );
    }
  }

  private _activityId: string;
  private _summary?: Summary;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    this.summary = _value.summary ? new Summary(_value.summary) : undefined;
    UpdateRequest.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }
  get summary(): Summary | undefined {
    return this._summary;
  }
  set summary(value: Summary | undefined) {
    this._summary = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      activityId: this.activityId,
      summary: this.summary ? this.summary.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      activityId: this.activityId,
      summary: this.summary ? this.summary.toProtobufJSON(options) : null
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    activityId: string;
    summary?: Summary.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    activityId: string;
    summary: Summary.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.activity.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.activity.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.changed = _instance.changed || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.changed = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.changed) {
      _writer.writeBool(1, _instance.changed);
    }
  }

  private _changed: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.changed = _value.changed;
    UpdateResponse.refineValues(this);
  }
  get changed(): boolean {
    return this._changed;
  }
  set changed(value: boolean) {
    this._changed = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      changed: this.changed
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      changed: this.changed
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    changed: boolean;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    changed: boolean;
  }
}

/**
 * Message implementation for api.activity.DeleteRequest
 */
export class DeleteRequest implements GrpcMessage {
  static id = 'api.activity.DeleteRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteRequest();
    DeleteRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteRequest) {
    _instance.activityId = _instance.activityId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
  }

  private _activityId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteRequest.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    DeleteRequest.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteRequest.AsObject {
    return {
      activityId: this.activityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteRequest.AsProtobufJSON {
    return {
      activityId: this.activityId
    };
  }
}
export module DeleteRequest {
  /**
   * Standard JavaScript object representation for DeleteRequest
   */
  export interface AsObject {
    activityId: string;
  }

  /**
   * Protobuf JSON representation for DeleteRequest
   */
  export interface AsProtobufJSON {
    activityId: string;
  }
}

/**
 * Message implementation for api.activity.DeleteResponse
 */
export class DeleteResponse implements GrpcMessage {
  static id = 'api.activity.DeleteResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteResponse();
    DeleteResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    DeleteResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteResponse.AsObject>) {
    _value = _value || {};
    DeleteResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteResponse.AsProtobufJSON {
    return {};
  }
}
export module DeleteResponse {
  /**
   * Standard JavaScript object representation for DeleteResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for DeleteResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.activity.UploadRequest
 */
export class UploadRequest implements GrpcMessage {
  static id = 'api.activity.UploadRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadRequest();
    UploadRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadRequest) {
    _instance.fileContents = _instance.fileContents || new Uint8Array();
    _instance.fileType = _instance.fileType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fileContents = _reader.readBytes();
          break;
        case 2:
          _instance.fileType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.fileContents && _instance.fileContents.length) {
      _writer.writeBytes(1, _instance.fileContents);
    }
    if (_instance.fileType) {
      _writer.writeEnum(2, _instance.fileType);
    }
  }

  private _fileContents: Uint8Array;
  private _fileType: UploadRequest.FileType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadRequest.AsObject>) {
    _value = _value || {};
    this.fileContents = _value.fileContents;
    this.fileType = _value.fileType;
    UploadRequest.refineValues(this);
  }
  get fileContents(): Uint8Array {
    return this._fileContents;
  }
  set fileContents(value: Uint8Array) {
    this._fileContents = value;
  }
  get fileType(): UploadRequest.FileType {
    return this._fileType;
  }
  set fileType(value: UploadRequest.FileType) {
    this._fileType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadRequest.AsObject {
    return {
      fileContents: this.fileContents
        ? this.fileContents.subarray(0)
        : new Uint8Array(),
      fileType: this.fileType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadRequest.AsProtobufJSON {
    return {
      fileContents: this.fileContents
        ? uint8ArrayToBase64(this.fileContents)
        : '',
      fileType:
        UploadRequest.FileType[
          this.fileType === null || this.fileType === undefined
            ? 0
            : this.fileType
        ]
    };
  }
}
export module UploadRequest {
  /**
   * Standard JavaScript object representation for UploadRequest
   */
  export interface AsObject {
    fileContents: Uint8Array;
    fileType: UploadRequest.FileType;
  }

  /**
   * Protobuf JSON representation for UploadRequest
   */
  export interface AsProtobufJSON {
    fileContents: string;
    fileType: string;
  }
  export enum FileType {
    FILE_TYPE_UNSPECIFIED = 0,
    FILE_TYPE_FIT = 1,
    FILE_TYPE_GPX = 2,
    FILE_TYPE_TCX = 3
  }
}

/**
 * Message implementation for api.activity.UploadResponse
 */
export class UploadResponse implements GrpcMessage {
  static id = 'api.activity.UploadResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadResponse();
    UploadResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UploadResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadResponse.AsObject>) {
    _value = _value || {};
    UploadResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadResponse.AsProtobufJSON {
    return {};
  }
}
export module UploadResponse {
  /**
   * Standard JavaScript object representation for UploadResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UploadResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.activity.SetReactionRequest
 */
export class SetReactionRequest implements GrpcMessage {
  static id = 'api.activity.SetReactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetReactionRequest();
    SetReactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetReactionRequest) {
    _instance.activityId = _instance.activityId || '0';
    _instance.reactionType = _instance.reactionType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetReactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        case 2:
          _instance.reactionType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    SetReactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetReactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
    if (_instance.reactionType) {
      _writer.writeEnum(2, _instance.reactionType);
    }
  }

  private _activityId: string;
  private _reactionType: shared003.ReactionType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetReactionRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetReactionRequest.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    this.reactionType = _value.reactionType;
    SetReactionRequest.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }
  get reactionType(): shared003.ReactionType {
    return this._reactionType;
  }
  set reactionType(value: shared003.ReactionType) {
    this._reactionType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetReactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetReactionRequest.AsObject {
    return {
      activityId: this.activityId,
      reactionType: this.reactionType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetReactionRequest.AsProtobufJSON {
    return {
      activityId: this.activityId,
      reactionType:
        shared003.ReactionType[
          this.reactionType === null || this.reactionType === undefined
            ? 0
            : this.reactionType
        ]
    };
  }
}
export module SetReactionRequest {
  /**
   * Standard JavaScript object representation for SetReactionRequest
   */
  export interface AsObject {
    activityId: string;
    reactionType: shared003.ReactionType;
  }

  /**
   * Protobuf JSON representation for SetReactionRequest
   */
  export interface AsProtobufJSON {
    activityId: string;
    reactionType: string;
  }
}

/**
 * Message implementation for api.activity.SetReactionResponse
 */
export class SetReactionResponse implements GrpcMessage {
  static id = 'api.activity.SetReactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetReactionResponse();
    SetReactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetReactionResponse) {
    _instance.reactions = _instance.reactions || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetReactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.reactions = new shared003.Reactions();
          _reader.readMessage(
            _instance.reactions,
            shared003.Reactions.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SetReactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetReactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.reactions) {
      _writer.writeMessage(
        1,
        _instance.reactions as any,
        shared003.Reactions.serializeBinaryToWriter
      );
    }
  }

  private _reactions?: shared003.Reactions;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetReactionResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetReactionResponse.AsObject>) {
    _value = _value || {};
    this.reactions = _value.reactions
      ? new shared003.Reactions(_value.reactions)
      : undefined;
    SetReactionResponse.refineValues(this);
  }
  get reactions(): shared003.Reactions | undefined {
    return this._reactions;
  }
  set reactions(value: shared003.Reactions | undefined) {
    this._reactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetReactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetReactionResponse.AsObject {
    return {
      reactions: this.reactions ? this.reactions.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetReactionResponse.AsProtobufJSON {
    return {
      reactions: this.reactions ? this.reactions.toProtobufJSON(options) : null
    };
  }
}
export module SetReactionResponse {
  /**
   * Standard JavaScript object representation for SetReactionResponse
   */
  export interface AsObject {
    reactions?: shared003.Reactions.AsObject;
  }

  /**
   * Protobuf JSON representation for SetReactionResponse
   */
  export interface AsProtobufJSON {
    reactions: shared003.Reactions.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.activity.UpdateCommentRequest
 */
export class UpdateCommentRequest implements GrpcMessage {
  static id = 'api.activity.UpdateCommentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCommentRequest();
    UpdateCommentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCommentRequest) {
    _instance.activityId = _instance.activityId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCommentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        case 2:
          _instance.deleteIdPlusOne = _reader.readInt32();
          break;
        case 3:
          _instance.commentToAdd = new CommentToAdd();
          _reader.readMessage(
            _instance.commentToAdd,
            CommentToAdd.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.reactionToAdd = new ReactionToAdd();
          _reader.readMessage(
            _instance.reactionToAdd,
            ReactionToAdd.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCommentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCommentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
    if (_instance.deleteIdPlusOne || _instance.deleteIdPlusOne === 0) {
      _writer.writeInt32(2, _instance.deleteIdPlusOne);
    }
    if (_instance.commentToAdd) {
      _writer.writeMessage(
        3,
        _instance.commentToAdd as any,
        CommentToAdd.serializeBinaryToWriter
      );
    }
    if (_instance.reactionToAdd) {
      _writer.writeMessage(
        4,
        _instance.reactionToAdd as any,
        ReactionToAdd.serializeBinaryToWriter
      );
    }
  }

  private _activityId: string;
  private _deleteIdPlusOne: number;
  private _commentToAdd?: CommentToAdd;
  private _reactionToAdd?: ReactionToAdd;

  private _change: UpdateCommentRequest.ChangeCase =
    UpdateCommentRequest.ChangeCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCommentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCommentRequest.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    this.deleteIdPlusOne = _value.deleteIdPlusOne;
    this.commentToAdd = _value.commentToAdd
      ? new CommentToAdd(_value.commentToAdd)
      : undefined;
    this.reactionToAdd = _value.reactionToAdd
      ? new ReactionToAdd(_value.reactionToAdd)
      : undefined;
    UpdateCommentRequest.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }
  get deleteIdPlusOne(): number {
    return this._deleteIdPlusOne;
  }
  set deleteIdPlusOne(value: number) {
    if (value !== undefined && value !== null) {
      this._commentToAdd = this._reactionToAdd = undefined;
      this._change = UpdateCommentRequest.ChangeCase.deleteIdPlusOne;
    }
    this._deleteIdPlusOne = value;
  }
  get commentToAdd(): CommentToAdd | undefined {
    return this._commentToAdd;
  }
  set commentToAdd(value: CommentToAdd | undefined) {
    if (value !== undefined && value !== null) {
      this._deleteIdPlusOne = this._reactionToAdd = undefined;
      this._change = UpdateCommentRequest.ChangeCase.commentToAdd;
    }
    this._commentToAdd = value;
  }
  get reactionToAdd(): ReactionToAdd | undefined {
    return this._reactionToAdd;
  }
  set reactionToAdd(value: ReactionToAdd | undefined) {
    if (value !== undefined && value !== null) {
      this._deleteIdPlusOne = this._commentToAdd = undefined;
      this._change = UpdateCommentRequest.ChangeCase.reactionToAdd;
    }
    this._reactionToAdd = value;
  }
  get change() {
    return this._change;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCommentRequest.AsObject {
    return {
      activityId: this.activityId,
      deleteIdPlusOne: this.deleteIdPlusOne,
      commentToAdd: this.commentToAdd
        ? this.commentToAdd.toObject()
        : undefined,
      reactionToAdd: this.reactionToAdd
        ? this.reactionToAdd.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCommentRequest.AsProtobufJSON {
    return {
      activityId: this.activityId,
      deleteIdPlusOne:
        this.deleteIdPlusOne === null || this.deleteIdPlusOne === undefined
          ? null
          : this.deleteIdPlusOne,
      commentToAdd: this.commentToAdd
        ? this.commentToAdd.toProtobufJSON(options)
        : null,
      reactionToAdd: this.reactionToAdd
        ? this.reactionToAdd.toProtobufJSON(options)
        : null
    };
  }
}
export module UpdateCommentRequest {
  /**
   * Standard JavaScript object representation for UpdateCommentRequest
   */
  export interface AsObject {
    activityId: string;
    deleteIdPlusOne: number;
    commentToAdd?: CommentToAdd.AsObject;
    reactionToAdd?: ReactionToAdd.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateCommentRequest
   */
  export interface AsProtobufJSON {
    activityId: string;
    deleteIdPlusOne: number | null;
    commentToAdd: CommentToAdd.AsProtobufJSON | null;
    reactionToAdd: ReactionToAdd.AsProtobufJSON | null;
  }
  export enum ChangeCase {
    none = 0,
    deleteIdPlusOne = 1,
    commentToAdd = 2,
    reactionToAdd = 3
  }
}

/**
 * Message implementation for api.activity.CommentToAdd
 */
export class CommentToAdd implements GrpcMessage {
  static id = 'api.activity.CommentToAdd';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommentToAdd();
    CommentToAdd.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommentToAdd) {
    _instance.text = _instance.text || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommentToAdd,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.replyToId = _reader.readInt32();
          break;
        case 2:
          _instance.text = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CommentToAdd.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommentToAdd,
    _writer: BinaryWriter
  ) {
    if (_instance.replyToId !== undefined && _instance.replyToId !== null) {
      _writer.writeInt32(1, _instance.replyToId);
    }
    if (_instance.text) {
      _writer.writeString(2, _instance.text);
    }
  }

  private _replyToId?: number;
  private _text: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommentToAdd to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommentToAdd.AsObject>) {
    _value = _value || {};
    this.replyToId = _value.replyToId;
    this.text = _value.text;
    CommentToAdd.refineValues(this);
  }
  get replyToId(): number | undefined {
    return this._replyToId;
  }
  set replyToId(value?: number) {
    this._replyToId = value;
  }
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommentToAdd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommentToAdd.AsObject {
    return {
      replyToId: this.replyToId,
      text: this.text
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommentToAdd.AsProtobufJSON {
    return {
      replyToId:
        this.replyToId === null || this.replyToId === undefined
          ? null
          : this.replyToId,
      text: this.text
    };
  }
}
export module CommentToAdd {
  /**
   * Standard JavaScript object representation for CommentToAdd
   */
  export interface AsObject {
    replyToId?: number;
    text: string;
  }

  /**
   * Protobuf JSON representation for CommentToAdd
   */
  export interface AsProtobufJSON {
    replyToId: number | null;
    text: string;
  }
}

/**
 * Message implementation for api.activity.ReactionToAdd
 */
export class ReactionToAdd implements GrpcMessage {
  static id = 'api.activity.ReactionToAdd';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReactionToAdd();
    ReactionToAdd.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReactionToAdd) {
    _instance.commentId = _instance.commentId || 0;
    _instance.reactionType = _instance.reactionType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReactionToAdd,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.commentId = _reader.readInt32();
          break;
        case 2:
          _instance.reactionType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ReactionToAdd.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReactionToAdd,
    _writer: BinaryWriter
  ) {
    if (_instance.commentId) {
      _writer.writeInt32(1, _instance.commentId);
    }
    if (_instance.reactionType) {
      _writer.writeEnum(2, _instance.reactionType);
    }
  }

  private _commentId: number;
  private _reactionType: shared003.ReactionType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReactionToAdd to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReactionToAdd.AsObject>) {
    _value = _value || {};
    this.commentId = _value.commentId;
    this.reactionType = _value.reactionType;
    ReactionToAdd.refineValues(this);
  }
  get commentId(): number {
    return this._commentId;
  }
  set commentId(value: number) {
    this._commentId = value;
  }
  get reactionType(): shared003.ReactionType {
    return this._reactionType;
  }
  set reactionType(value: shared003.ReactionType) {
    this._reactionType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReactionToAdd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReactionToAdd.AsObject {
    return {
      commentId: this.commentId,
      reactionType: this.reactionType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReactionToAdd.AsProtobufJSON {
    return {
      commentId: this.commentId,
      reactionType:
        shared003.ReactionType[
          this.reactionType === null || this.reactionType === undefined
            ? 0
            : this.reactionType
        ]
    };
  }
}
export module ReactionToAdd {
  /**
   * Standard JavaScript object representation for ReactionToAdd
   */
  export interface AsObject {
    commentId: number;
    reactionType: shared003.ReactionType;
  }

  /**
   * Protobuf JSON representation for ReactionToAdd
   */
  export interface AsProtobufJSON {
    commentId: number;
    reactionType: string;
  }
}

/**
 * Message implementation for api.activity.UpdateCommentResponse
 */
export class UpdateCommentResponse implements GrpcMessage {
  static id = 'api.activity.UpdateCommentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCommentResponse();
    UpdateCommentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCommentResponse) {
    _instance.reactions = _instance.reactions || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCommentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.reactions = new shared003.Reactions();
          _reader.readMessage(
            _instance.reactions,
            shared003.Reactions.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCommentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCommentResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.reactions) {
      _writer.writeMessage(
        1,
        _instance.reactions as any,
        shared003.Reactions.serializeBinaryToWriter
      );
    }
  }

  private _reactions?: shared003.Reactions;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCommentResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCommentResponse.AsObject>) {
    _value = _value || {};
    this.reactions = _value.reactions
      ? new shared003.Reactions(_value.reactions)
      : undefined;
    UpdateCommentResponse.refineValues(this);
  }
  get reactions(): shared003.Reactions | undefined {
    return this._reactions;
  }
  set reactions(value: shared003.Reactions | undefined) {
    this._reactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCommentResponse.AsObject {
    return {
      reactions: this.reactions ? this.reactions.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCommentResponse.AsProtobufJSON {
    return {
      reactions: this.reactions ? this.reactions.toProtobufJSON(options) : null
    };
  }
}
export module UpdateCommentResponse {
  /**
   * Standard JavaScript object representation for UpdateCommentResponse
   */
  export interface AsObject {
    reactions?: shared003.Reactions.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateCommentResponse
   */
  export interface AsProtobufJSON {
    reactions: shared003.Reactions.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.activity.Activity
 */
export class Activity implements GrpcMessage {
  static id = 'api.activity.Activity';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Activity();
    Activity.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Activity) {
    _instance.summary = _instance.summary || undefined;
    _instance.gear = _instance.gear || undefined;
    _instance.track = _instance.track || undefined;
    _instance.stats = _instance.stats || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Activity,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.summary = new Summary();
          _reader.readMessage(
            _instance.summary,
            Summary.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.gear = new shared001.Gear();
          _reader.readMessage(
            _instance.gear,
            shared001.Gear.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.track = new shared001.Track();
          _reader.readMessage(
            _instance.track,
            shared001.Track.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.stats = new shared001.ActivityStats();
          _reader.readMessage(
            _instance.stats,
            shared001.ActivityStats.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Activity.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Activity, _writer: BinaryWriter) {
    if (_instance.summary) {
      _writer.writeMessage(
        1,
        _instance.summary as any,
        Summary.serializeBinaryToWriter
      );
    }
    if (_instance.gear) {
      _writer.writeMessage(
        2,
        _instance.gear as any,
        shared001.Gear.serializeBinaryToWriter
      );
    }
    if (_instance.track) {
      _writer.writeMessage(
        3,
        _instance.track as any,
        shared001.Track.serializeBinaryToWriter
      );
    }
    if (_instance.stats) {
      _writer.writeMessage(
        4,
        _instance.stats as any,
        shared001.ActivityStats.serializeBinaryToWriter
      );
    }
  }

  private _summary?: Summary;
  private _gear?: shared001.Gear;
  private _track?: shared001.Track;
  private _stats?: shared001.ActivityStats;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Activity to deeply clone from
   */
  constructor(_value?: RecursivePartial<Activity.AsObject>) {
    _value = _value || {};
    this.summary = _value.summary ? new Summary(_value.summary) : undefined;
    this.gear = _value.gear ? new shared001.Gear(_value.gear) : undefined;
    this.track = _value.track ? new shared001.Track(_value.track) : undefined;
    this.stats = _value.stats
      ? new shared001.ActivityStats(_value.stats)
      : undefined;
    Activity.refineValues(this);
  }
  get summary(): Summary | undefined {
    return this._summary;
  }
  set summary(value: Summary | undefined) {
    this._summary = value;
  }
  get gear(): shared001.Gear | undefined {
    return this._gear;
  }
  set gear(value: shared001.Gear | undefined) {
    this._gear = value;
  }
  get track(): shared001.Track | undefined {
    return this._track;
  }
  set track(value: shared001.Track | undefined) {
    this._track = value;
  }
  get stats(): shared001.ActivityStats | undefined {
    return this._stats;
  }
  set stats(value: shared001.ActivityStats | undefined) {
    this._stats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Activity.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Activity.AsObject {
    return {
      summary: this.summary ? this.summary.toObject() : undefined,
      gear: this.gear ? this.gear.toObject() : undefined,
      track: this.track ? this.track.toObject() : undefined,
      stats: this.stats ? this.stats.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Activity.AsProtobufJSON {
    return {
      summary: this.summary ? this.summary.toProtobufJSON(options) : null,
      gear: this.gear ? this.gear.toProtobufJSON(options) : null,
      track: this.track ? this.track.toProtobufJSON(options) : null,
      stats: this.stats ? this.stats.toProtobufJSON(options) : null
    };
  }
}
export module Activity {
  /**
   * Standard JavaScript object representation for Activity
   */
  export interface AsObject {
    summary?: Summary.AsObject;
    gear?: shared001.Gear.AsObject;
    track?: shared001.Track.AsObject;
    stats?: shared001.ActivityStats.AsObject;
  }

  /**
   * Protobuf JSON representation for Activity
   */
  export interface AsProtobufJSON {
    summary: Summary.AsProtobufJSON | null;
    gear: shared001.Gear.AsProtobufJSON | null;
    track: shared001.Track.AsProtobufJSON | null;
    stats: shared001.ActivityStats.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.activity.Summary
 */
export class Summary implements GrpcMessage {
  static id = 'api.activity.Summary';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Summary();
    Summary.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Summary) {
    _instance.activityId = _instance.activityId || '0';
    _instance.alias = _instance.alias || '';
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
    _instance.title = _instance.title || '';

    _instance.activityType = _instance.activityType || 0;
    _instance.mapMedia = _instance.mapMedia || undefined;
    _instance.medias = _instance.medias || [];
    _instance.reactions = _instance.reactions || undefined;

    _instance.totalStats = _instance.totalStats || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Summary,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        case 2:
          _instance.alias = _reader.readString();
          break;
        case 3:
          _instance.firstName = _reader.readString();
          break;
        case 4:
          _instance.lastName = _reader.readString();
          break;
        case 5:
          _instance.title = _reader.readString();
          break;
        case 6:
          _instance.noteHtml = _reader.readString();
          break;
        case 7:
          _instance.activityType = _reader.readEnum();
          break;
        case 10:
          _instance.mapMedia = new MapMedia();
          _reader.readMessage(
            _instance.mapMedia,
            MapMedia.deserializeBinaryFromReader
          );
          break;
        case 11:
          const messageInitializer11 = new shared002.Media();
          _reader.readMessage(
            messageInitializer11,
            shared002.Media.deserializeBinaryFromReader
          );
          (_instance.medias = _instance.medias || []).push(
            messageInitializer11
          );
          break;
        case 16:
          _instance.reactions = new shared003.Reactions();
          _reader.readMessage(
            _instance.reactions,
            shared003.Reactions.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.averageSpeed = _reader.readDouble();
          break;
        case 17:
          _instance.totalStats = new shared001.IntervalStats();
          _reader.readMessage(
            _instance.totalStats,
            shared001.IntervalStats.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Summary.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Summary, _writer: BinaryWriter) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
    if (_instance.alias) {
      _writer.writeString(2, _instance.alias);
    }
    if (_instance.firstName) {
      _writer.writeString(3, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(4, _instance.lastName);
    }
    if (_instance.title) {
      _writer.writeString(5, _instance.title);
    }
    if (_instance.noteHtml !== undefined && _instance.noteHtml !== null) {
      _writer.writeString(6, _instance.noteHtml);
    }
    if (_instance.activityType) {
      _writer.writeEnum(7, _instance.activityType);
    }
    if (_instance.mapMedia) {
      _writer.writeMessage(
        10,
        _instance.mapMedia as any,
        MapMedia.serializeBinaryToWriter
      );
    }
    if (_instance.medias && _instance.medias.length) {
      _writer.writeRepeatedMessage(
        11,
        _instance.medias as any,
        shared002.Media.serializeBinaryToWriter
      );
    }
    if (_instance.reactions) {
      _writer.writeMessage(
        16,
        _instance.reactions as any,
        shared003.Reactions.serializeBinaryToWriter
      );
    }
    if (
      _instance.averageSpeed !== undefined &&
      _instance.averageSpeed !== null
    ) {
      _writer.writeDouble(13, _instance.averageSpeed);
    }
    if (_instance.totalStats) {
      _writer.writeMessage(
        17,
        _instance.totalStats as any,
        shared001.IntervalStats.serializeBinaryToWriter
      );
    }
  }

  private _activityId: string;
  private _alias: string;
  private _firstName: string;
  private _lastName: string;
  private _title: string;
  private _noteHtml?: string;
  private _activityType: shared001.ActivityType;
  private _mapMedia?: MapMedia;
  private _medias?: shared002.Media[];
  private _reactions?: shared003.Reactions;
  private _averageSpeed?: number;
  private _totalStats?: shared001.IntervalStats;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Summary to deeply clone from
   */
  constructor(_value?: RecursivePartial<Summary.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    this.alias = _value.alias;
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    this.title = _value.title;
    this.noteHtml = _value.noteHtml;
    this.activityType = _value.activityType;
    this.mapMedia = _value.mapMedia ? new MapMedia(_value.mapMedia) : undefined;
    this.medias = (_value.medias || []).map(m => new shared002.Media(m));
    this.reactions = _value.reactions
      ? new shared003.Reactions(_value.reactions)
      : undefined;
    this.averageSpeed = _value.averageSpeed;
    this.totalStats = _value.totalStats
      ? new shared001.IntervalStats(_value.totalStats)
      : undefined;
    Summary.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get noteHtml(): string | undefined {
    return this._noteHtml;
  }
  set noteHtml(value?: string) {
    this._noteHtml = value;
  }
  get activityType(): shared001.ActivityType {
    return this._activityType;
  }
  set activityType(value: shared001.ActivityType) {
    this._activityType = value;
  }
  get mapMedia(): MapMedia | undefined {
    return this._mapMedia;
  }
  set mapMedia(value: MapMedia | undefined) {
    this._mapMedia = value;
  }
  get medias(): shared002.Media[] | undefined {
    return this._medias;
  }
  set medias(value: shared002.Media[] | undefined) {
    this._medias = value;
  }
  get reactions(): shared003.Reactions | undefined {
    return this._reactions;
  }
  set reactions(value: shared003.Reactions | undefined) {
    this._reactions = value;
  }
  get averageSpeed(): number | undefined {
    return this._averageSpeed;
  }
  set averageSpeed(value?: number) {
    this._averageSpeed = value;
  }
  get totalStats(): shared001.IntervalStats | undefined {
    return this._totalStats;
  }
  set totalStats(value: shared001.IntervalStats | undefined) {
    this._totalStats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Summary.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Summary.AsObject {
    return {
      activityId: this.activityId,
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      noteHtml: this.noteHtml,
      activityType: this.activityType,
      mapMedia: this.mapMedia ? this.mapMedia.toObject() : undefined,
      medias: (this.medias || []).map(m => m.toObject()),
      reactions: this.reactions ? this.reactions.toObject() : undefined,
      averageSpeed: this.averageSpeed,
      totalStats: this.totalStats ? this.totalStats.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Summary.AsProtobufJSON {
    return {
      activityId: this.activityId,
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      noteHtml:
        this.noteHtml === null || this.noteHtml === undefined
          ? null
          : this.noteHtml,
      activityType:
        shared001.ActivityType[
          this.activityType === null || this.activityType === undefined
            ? 0
            : this.activityType
        ],
      mapMedia: this.mapMedia ? this.mapMedia.toProtobufJSON(options) : null,
      medias: (this.medias || []).map(m => m.toProtobufJSON(options)),
      reactions: this.reactions ? this.reactions.toProtobufJSON(options) : null,
      averageSpeed:
        this.averageSpeed === null || this.averageSpeed === undefined
          ? null
          : this.averageSpeed,
      totalStats: this.totalStats
        ? this.totalStats.toProtobufJSON(options)
        : null
    };
  }
}
export module Summary {
  /**
   * Standard JavaScript object representation for Summary
   */
  export interface AsObject {
    activityId: string;
    alias: string;
    firstName: string;
    lastName: string;
    title: string;
    noteHtml?: string;
    activityType: shared001.ActivityType;
    mapMedia?: MapMedia.AsObject;
    medias?: shared002.Media.AsObject[];
    reactions?: shared003.Reactions.AsObject;
    averageSpeed?: number;
    totalStats?: shared001.IntervalStats.AsObject;
  }

  /**
   * Protobuf JSON representation for Summary
   */
  export interface AsProtobufJSON {
    activityId: string;
    alias: string;
    firstName: string;
    lastName: string;
    title: string;
    noteHtml: string | null;
    activityType: string;
    mapMedia: MapMedia.AsProtobufJSON | null;
    medias: shared002.Media.AsProtobufJSON[] | null;
    reactions: shared003.Reactions.AsProtobufJSON | null;
    averageSpeed: number | null;
    totalStats: shared001.IntervalStats.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.activity.MapMedia
 */
export class MapMedia implements GrpcMessage {
  static id = 'api.activity.MapMedia';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MapMedia();
    MapMedia.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MapMedia) {
    _instance.mediaId = _instance.mediaId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MapMedia,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.mediaId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    MapMedia.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: MapMedia, _writer: BinaryWriter) {
    if (_instance.mediaId) {
      _writer.writeInt64String(1, _instance.mediaId);
    }
  }

  private _mediaId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MapMedia to deeply clone from
   */
  constructor(_value?: RecursivePartial<MapMedia.AsObject>) {
    _value = _value || {};
    this.mediaId = _value.mediaId;
    MapMedia.refineValues(this);
  }
  get mediaId(): string {
    return this._mediaId;
  }
  set mediaId(value: string) {
    this._mediaId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MapMedia.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MapMedia.AsObject {
    return {
      mediaId: this.mediaId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MapMedia.AsProtobufJSON {
    return {
      mediaId: this.mediaId
    };
  }
}
export module MapMedia {
  /**
   * Standard JavaScript object representation for MapMedia
   */
  export interface AsObject {
    mediaId: string;
  }

  /**
   * Protobuf JSON representation for MapMedia
   */
  export interface AsProtobufJSON {
    mediaId: string;
  }
}
