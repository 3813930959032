<mat-form-field
  *ngIf="editMode"
  class="input-field"
  [appearance]="'outline'"
  [ngStyle]="{ width: width ?? undefined }"
>
  <mat-select
    required
    #editSelect
    [(value)]="selectedOptionValue"
    [panelWidth]="''"
    (selectionChange)="onSelectionChanged()"
    (closed)="onSelectClosed()"
  >
    <mat-option *ngFor="let v of optionValues" [value]="v">{{ v }}</mat-option>
  </mat-select>
</mat-form-field>
<div
  *ngIf="!editMode"
  tabindex="0"
  class="view-field"
  [ngStyle]="{ width: width ?? undefined }"
  (click)="onClick()"
  (keydown)="onKeyDown($event)"
  matTooltip="{{ toolTip() }}"
>
  <h3 class="mat-typography">{{ selectedOptionValue }}</h3>
</div>
