import { Injectable } from '@angular/core';

interface ScrollPoint {
  x: number;
  y: number;
}

type SavedState = {
  scrollPosition?: ScrollPoint;

  state?: Map<string, object | string | number>;
};

@Injectable({
  providedIn: 'root',
})
export class StateSaverService {
  private state: Map<string, SavedState> = new Map<string, SavedState>();
  private activityIds: string[] = [];

  set(name: string, state: SavedState) {
    this.state.set(name, state);
  }

  get(name: string): SavedState {
    let state = this.state.get(name);
    if (!state) {
      state = {
        scrollPosition: { x: 0, y: 0 },
      };
    }
    return state;
  }

  set activityIdsInView(activityIds: string[]) {
    this.activityIds = activityIds;
  }

  get activityIdsInView(): string[] {
    return this.activityIds;
  }
}
