import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { HeartRateZoneStats } from 'generated/src/main/proto/shared/activity-shared.pb';
import { FormatService } from 'src/app/services/format.service';

/** Shows HR zones chart. */
@Component({
  selector: 'app-hr-zones-chart',
  templateUrl: './hr-zones-chart.component.html',
  styleUrl: './hr-zones-chart.component.scss',
})
export class HrZonesChartComponent implements AfterViewInit, OnChanges {
  @Input()
  hrZones?: { [prop: number]: HeartRateZoneStats };

  hrZonesChartOptions: EChartsOption = {};
  private readonly zoneBarColors = [
    '#8aacff',
    '#626fe6',
    '#6d42c7',
    'orange',
    '#e85b48',
  ];

  isExpanded = false;

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    if (this.hrZones) {
      this.hrZonesChartOptions = this.buildHrZonesChartOptions(this.hrZones);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hrZones'] && this.hrZones) {
      this.hrZonesChartOptions = this.buildHrZonesChartOptions(this.hrZones);
    }
  }

  onToggleBarKeyDown(e: KeyboardEvent): void {
    if (e.key == ' ') {
      this.onToggleBarClick();
    }
  }

  onToggleBarClick(): void {
    this.isExpanded = !this.isExpanded;
  }

  onHrZonesInfoKeyDown(e: KeyboardEvent): void {
    if (e.key == ' ') {
      this.onHrZonesInfoClick();
    }
  }

  onHrZonesInfoClick(): void {
    this.router.navigate(['docs/metrics/hr-zones']);
  }

  private buildHrZonesChartOptions(hrZoneStats: {
    [prop: number]: HeartRateZoneStats;
  }): EChartsOption {
    const zones: { value: number; name: string; color: string }[] = [];
    let totalSeconds = 0;
    for (let index = 1; index <= 5; index++) {
      const stats = hrZoneStats[index];
      const seconds = stats ? parseInt(stats.secondsInZone) : 0;
      zones.push({
        value: seconds,
        name: `Zone ${index}`,
        color: this.zoneBarColors[index - 1],
      });
      totalSeconds += seconds;
    }
    return {
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: { show: false },
      grid: {
        containLabel: true,
        left: 7,
        top: 7,
        right: 7,
        bottom: 7,
      },
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: [
        {
          type: 'category',
          data: zones.map((z) => z.name),
          show: true,
          position: 'left',
          axisLine: {
            show: false,
          },
          z: 3,
          axisTick: {
            show: false,
          },
          axisLabel: {
            inside: true,
            show: true,
            color: 'black',
          },
          nameLocation: 'start',
        },
        {
          type: 'category',
          data: zones,
          position: 'right',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            inside: false,
            show: true,
            // eslint-disable-next-line
            formatter: (seconds: any) => {
              let percent =
                totalSeconds > 0 ? (100 * seconds) / totalSeconds : 0;
              percent = Math.round(percent * 10) / 10;
              const value = FormatService.formatDurationSeconds(seconds).text;
              return `${value} (${percent}%)`;
            },
          },
        },
      ],
      series: [
        {
          type: 'bar',
          data: zones,
          label: {
            show: false,
          },
          itemStyle: {
            borderRadius: 4,
            // eslint-disable-next-line
            color: (params: any) => {
              return params.data.color;
            },
          },
        },
      ],
    };
  }
}
