export const environment = {
  config: {
    configUrl: 'https://storage.googleapis.com/fb-webapp-jestle-prod/app-config.json'
  },
  fitbit: {
    authorizeUrl: 'https://www.fitbit.com/oauth2/authorize'
  },
  garmin: {
    oauthConfirmUrl: 'https://connect.garmin.com/oauthConfirm'
  },
  production: true,
};
