import { Injectable } from '@angular/core';
import {
  Config,
  GetConfigRequest,
  GetConfigResponse,
  UpdateConfigRequest,
  UpdateConfigResponse,
} from 'generated/src/main/proto/api/config-service.pb';
import { ConfigServiceClient } from 'generated/src/main/proto/api/config-service.pbsc';
import {
  ApiConfig,
  UserRestrictions,
} from 'generated/src/main/proto/shared/config-shared.pb';
import { StatusCode } from 'grpc-web';
import { catchError, lastValueFrom, of, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

/** Admin service for accessing api config. */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly configId = 1;

  constructor(
    private configServiceClient: ConfigServiceClient,
    private userService: UserService,
    private bannerService: BannerService,
  ) {}

  async getConfig(): Promise<Config> {
    const request = new GetConfigRequest({
      configId: this.configId.toString(),
    });
    return lastValueFrom(
      this.configServiceClient
        .getConfig(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            // Not found is ok for new config.
            if (e.statusCode == StatusCode.NOT_FOUND) {
              return of(
                new Config({
                  configId: this.configId.toString(),
                  apiConfig: new ApiConfig({
                    userRestrictions: new UserRestrictions({
                      maxPublicUsers: '0',
                    }),
                  }),
                }),
              );
            }
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => {
      if (r instanceof GetConfigResponse) {
        return r.config!;
      } else {
        return r;
      }
    });
  }

  async updateConfig(config: Config): Promise<boolean> {
    const request = new UpdateConfigRequest({
      config: config,
    });
    return lastValueFrom(
      this.configServiceClient
        .updateConfig(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    ).then((r) => r instanceof UpdateConfigResponse);
  }
}
