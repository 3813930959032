import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['./edit-text.component.scss'],
})
export class EditTextComponent {
  editMode = false;

  @Input()
  public readOnly = false;

  @Input()
  public tooltipName = '';

  @Input()
  public value = '';
  @Output()
  public valueChange = new EventEmitter();
  private initialValue?: string;

  @Output()
  public editDone = new EventEmitter();

  @ViewChild('edit')
  edit!: ElementRef<HTMLInputElement>;

  toolTip(): string {
    return this.readOnly
      ? this.tooltipName
      : 'Click to edit ' + this.tooltipName;
  }

  onClick() {
    if (!this.readOnly) {
      this.flipEditMode();
    }
  }

  onViewKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.flipEditMode();
    }
  }

  onEditComplete() {
    if (this.value) {
      this.valueChange.emit(this.value);
      this.editDone.emit();
      this.flipEditMode();
    } else {
      // Do not allow exiting until a value is entered.
      this.edit.nativeElement.focus();
    }
  }

  onInputAfterContentInit() {
    setTimeout(() => {
      this.edit.nativeElement.focus();
    }, 100);
  }

  onInputKeyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      // Enter is the same as focus loss (aka blur).
      this.onEditComplete();
      e.stopPropagation();
    } else if (e.key == 'Escape') {
      // Escape restores initial value before edit.
      if (this.initialValue) {
        this.value = this.initialValue;
      }
      this.flipEditMode();
      e.stopPropagation();
    } else {
      // Store initial value.
      if (!this.initialValue) {
        this.initialValue = this.value;
      }
    }
  }

  private flipEditMode() {
    this.editMode = !this.editMode;
  }
}
