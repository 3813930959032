<div #viewport (scroll)="onScroll()" class="viewport">
  <ng-container #itemContainer></ng-container>
</div>

<!--
  The template is used for adding div elements before and after the items. Having div
  elements tied to topPaddingPixels, bottomPaddingPixels breaks scrolling on mobile browser.
  Possibly, because Angular adds elements and updates their properties in different rendering
  passes.
 -->
<ng-template #divPadding let-height="height">
  <div [ngStyle]="{ height: height, width: '100%' }"></div>
</ng-template>
