/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as shared000 from '../../../../src/main/proto/shared/media-shared.pb';
/**
 * Message implementation for api.media.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.media.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.mediaId = _instance.mediaId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.mediaId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.mediaId) {
      _writer.writeInt64String(1, _instance.mediaId);
    }
  }

  private _mediaId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.mediaId = _value.mediaId;
    GetRequest.refineValues(this);
  }
  get mediaId(): string {
    return this._mediaId;
  }
  set mediaId(value: string) {
    this._mediaId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      mediaId: this.mediaId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      mediaId: this.mediaId
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    mediaId: string;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    mediaId: string;
  }
}

/**
 * Message implementation for api.media.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.media.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.mediaBytes = _instance.mediaBytes || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.mediaBytes = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.mediaBytes && _instance.mediaBytes.length) {
      _writer.writeBytes(2, _instance.mediaBytes);
    }
  }

  private _mediaBytes: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.mediaBytes = _value.mediaBytes;
    GetResponse.refineValues(this);
  }
  get mediaBytes(): Uint8Array {
    return this._mediaBytes;
  }
  set mediaBytes(value: Uint8Array) {
    this._mediaBytes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      mediaBytes: this.mediaBytes
        ? this.mediaBytes.subarray(0)
        : new Uint8Array()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      mediaBytes: this.mediaBytes ? uint8ArrayToBase64(this.mediaBytes) : ''
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    mediaBytes: Uint8Array;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    mediaBytes: string;
  }
}

/**
 * Message implementation for api.media.UploadRequest
 */
export class UploadRequest implements GrpcMessage {
  static id = 'api.media.UploadRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadRequest();
    UploadRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadRequest) {
    _instance.mediaBytes = _instance.mediaBytes || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.mediaBytes = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.mediaBytes && _instance.mediaBytes.length) {
      _writer.writeBytes(1, _instance.mediaBytes);
    }
  }

  private _mediaBytes: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadRequest.AsObject>) {
    _value = _value || {};
    this.mediaBytes = _value.mediaBytes;
    UploadRequest.refineValues(this);
  }
  get mediaBytes(): Uint8Array {
    return this._mediaBytes;
  }
  set mediaBytes(value: Uint8Array) {
    this._mediaBytes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadRequest.AsObject {
    return {
      mediaBytes: this.mediaBytes
        ? this.mediaBytes.subarray(0)
        : new Uint8Array()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadRequest.AsProtobufJSON {
    return {
      mediaBytes: this.mediaBytes ? uint8ArrayToBase64(this.mediaBytes) : ''
    };
  }
}
export module UploadRequest {
  /**
   * Standard JavaScript object representation for UploadRequest
   */
  export interface AsObject {
    mediaBytes: Uint8Array;
  }

  /**
   * Protobuf JSON representation for UploadRequest
   */
  export interface AsProtobufJSON {
    mediaBytes: string;
  }
}

/**
 * Message implementation for api.media.UploadResponse
 */
export class UploadResponse implements GrpcMessage {
  static id = 'api.media.UploadResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadResponse();
    UploadResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadResponse) {
    _instance.smallSizeId = _instance.smallSizeId || '0';
    _instance.fullSizeId = _instance.fullSizeId || '0';
    _instance.contentType = _instance.contentType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.smallSizeId = _reader.readInt64String();
          break;
        case 2:
          _instance.fullSizeId = _reader.readInt64String();
          break;
        case 3:
          _instance.contentType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.smallSizeId) {
      _writer.writeInt64String(1, _instance.smallSizeId);
    }
    if (_instance.fullSizeId) {
      _writer.writeInt64String(2, _instance.fullSizeId);
    }
    if (_instance.contentType) {
      _writer.writeEnum(3, _instance.contentType);
    }
  }

  private _smallSizeId: string;
  private _fullSizeId: string;
  private _contentType: shared000.MediaContentType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadResponse.AsObject>) {
    _value = _value || {};
    this.smallSizeId = _value.smallSizeId;
    this.fullSizeId = _value.fullSizeId;
    this.contentType = _value.contentType;
    UploadResponse.refineValues(this);
  }
  get smallSizeId(): string {
    return this._smallSizeId;
  }
  set smallSizeId(value: string) {
    this._smallSizeId = value;
  }
  get fullSizeId(): string {
    return this._fullSizeId;
  }
  set fullSizeId(value: string) {
    this._fullSizeId = value;
  }
  get contentType(): shared000.MediaContentType {
    return this._contentType;
  }
  set contentType(value: shared000.MediaContentType) {
    this._contentType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadResponse.AsObject {
    return {
      smallSizeId: this.smallSizeId,
      fullSizeId: this.fullSizeId,
      contentType: this.contentType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadResponse.AsProtobufJSON {
    return {
      smallSizeId: this.smallSizeId,
      fullSizeId: this.fullSizeId,
      contentType:
        shared000.MediaContentType[
          this.contentType === null || this.contentType === undefined
            ? 0
            : this.contentType
        ]
    };
  }
}
export module UploadResponse {
  /**
   * Standard JavaScript object representation for UploadResponse
   */
  export interface AsObject {
    smallSizeId: string;
    fullSizeId: string;
    contentType: shared000.MediaContentType;
  }

  /**
   * Protobuf JSON representation for UploadResponse
   */
  export interface AsProtobufJSON {
    smallSizeId: string;
    fullSizeId: string;
    contentType: string;
  }
}
