import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IssueContext } from 'generated/src/main/proto/api/issue-service.pb';
import { IssueService } from 'src/app/services/issues/issue.service';
import { StateSaverService } from 'src/app/services/state-saver/state-saver.service';
import { IssueReport, IssueReportDialogComponent } from './issue-report.dialog';

@Component({
  selector: 'app-issue-report',
  templateUrl: './issue-report.component.html',
  styleUrls: ['./issue-report.component.scss'],
})
export class IssueReportComponent {
  constructor(
    private issueService: IssueService,
    private stateSaverService: StateSaverService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  onReportProblemClick() {
    const dialogRef = this.dialog.open(IssueReportDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: IssueReport) => {
      const url = this.router.url;
      const context = new IssueContext({ url: url });

      // Get activity ids from activities view, put the one in URL in the first place.
      let activityIds = this.stateSaverService.activityIdsInView;
      const index = activityIds.findIndex((id) => url.indexOf('/' + id) >= 0);
      if (index >= 0) {
        const t = activityIds[index];
        activityIds[index] = activityIds[0];
        activityIds[0] = t;
      }
      if (activityIds.length > 1) {
        activityIds = [activityIds[0]].concat(activityIds.slice(1).sort());
      }
      context.activityIds = activityIds;

      // Add the issue, errors are handled in the service.
      this.issueService.add(
        result.issueType,
        result.consentToContact,
        result.title,
        result.description,
        context,
        result.screenshot,
      );
    });
  }
}
