import {
  AfterContentInit,
  Directive,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

// Spy on any element to which it is applied.
@Directive({ selector: '[appSpy]' })
export class SpyDirective implements AfterContentInit, OnInit, OnDestroy {
  @Output()
  spyAfterContentInit = new EventEmitter();

  @Output()
  spyInit = new EventEmitter();

  @Output()
  spyDestroy = new EventEmitter();

  ngAfterContentInit(): void {
    this.spyAfterContentInit.emit();
  }

  ngOnInit() {
    this.spyInit.emit();
  }

  ngOnDestroy() {
    this.spyDestroy.emit();
  }
}
