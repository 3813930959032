/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as shared000 from '../../../../src/main/proto/shared/reaction.pb';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as shared002 from '../../../../src/main/proto/shared/document-shared.pb';
/**
 * Message implementation for api.document.GetMetadataRequest
 */
export class GetMetadataRequest implements GrpcMessage {
  static id = 'api.document.GetMetadataRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMetadataRequest();
    GetMetadataRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMetadataRequest) {
    _instance.path = _instance.path || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMetadataRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.path = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMetadataRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMetadataRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.path) {
      _writer.writeString(1, _instance.path);
    }
  }

  private _path: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMetadataRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMetadataRequest.AsObject>) {
    _value = _value || {};
    this.path = _value.path;
    GetMetadataRequest.refineValues(this);
  }
  get path(): string {
    return this._path;
  }
  set path(value: string) {
    this._path = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMetadataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMetadataRequest.AsObject {
    return {
      path: this.path
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMetadataRequest.AsProtobufJSON {
    return {
      path: this.path
    };
  }
}
export module GetMetadataRequest {
  /**
   * Standard JavaScript object representation for GetMetadataRequest
   */
  export interface AsObject {
    path: string;
  }

  /**
   * Protobuf JSON representation for GetMetadataRequest
   */
  export interface AsProtobufJSON {
    path: string;
  }
}

/**
 * Message implementation for api.document.GetMetadataResponse
 */
export class GetMetadataResponse implements GrpcMessage {
  static id = 'api.document.GetMetadataResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMetadataResponse();
    GetMetadataResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMetadataResponse) {
    _instance.metadata = _instance.metadata || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMetadataResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.metadata = new shared002.DocumentMetadata();
          _reader.readMessage(
            _instance.metadata,
            shared002.DocumentMetadata.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMetadataResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMetadataResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.metadata) {
      _writer.writeMessage(
        1,
        _instance.metadata as any,
        shared002.DocumentMetadata.serializeBinaryToWriter
      );
    }
  }

  private _metadata?: shared002.DocumentMetadata;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMetadataResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMetadataResponse.AsObject>) {
    _value = _value || {};
    this.metadata = _value.metadata
      ? new shared002.DocumentMetadata(_value.metadata)
      : undefined;
    GetMetadataResponse.refineValues(this);
  }
  get metadata(): shared002.DocumentMetadata | undefined {
    return this._metadata;
  }
  set metadata(value: shared002.DocumentMetadata | undefined) {
    this._metadata = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMetadataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMetadataResponse.AsObject {
    return {
      metadata: this.metadata ? this.metadata.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMetadataResponse.AsProtobufJSON {
    return {
      metadata: this.metadata ? this.metadata.toProtobufJSON(options) : null
    };
  }
}
export module GetMetadataResponse {
  /**
   * Standard JavaScript object representation for GetMetadataResponse
   */
  export interface AsObject {
    metadata?: shared002.DocumentMetadata.AsObject;
  }

  /**
   * Protobuf JSON representation for GetMetadataResponse
   */
  export interface AsProtobufJSON {
    metadata: shared002.DocumentMetadata.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.document.SetReactionRequest
 */
export class SetReactionRequest implements GrpcMessage {
  static id = 'api.document.SetReactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetReactionRequest();
    SetReactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetReactionRequest) {
    _instance.path = _instance.path || '';
    _instance.reactionType = _instance.reactionType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetReactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.path = _reader.readString();
          break;
        case 2:
          _instance.reactionType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    SetReactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetReactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.path) {
      _writer.writeString(1, _instance.path);
    }
    if (_instance.reactionType) {
      _writer.writeEnum(2, _instance.reactionType);
    }
  }

  private _path: string;
  private _reactionType: shared000.ReactionType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetReactionRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetReactionRequest.AsObject>) {
    _value = _value || {};
    this.path = _value.path;
    this.reactionType = _value.reactionType;
    SetReactionRequest.refineValues(this);
  }
  get path(): string {
    return this._path;
  }
  set path(value: string) {
    this._path = value;
  }
  get reactionType(): shared000.ReactionType {
    return this._reactionType;
  }
  set reactionType(value: shared000.ReactionType) {
    this._reactionType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetReactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetReactionRequest.AsObject {
    return {
      path: this.path,
      reactionType: this.reactionType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetReactionRequest.AsProtobufJSON {
    return {
      path: this.path,
      reactionType:
        shared000.ReactionType[
          this.reactionType === null || this.reactionType === undefined
            ? 0
            : this.reactionType
        ]
    };
  }
}
export module SetReactionRequest {
  /**
   * Standard JavaScript object representation for SetReactionRequest
   */
  export interface AsObject {
    path: string;
    reactionType: shared000.ReactionType;
  }

  /**
   * Protobuf JSON representation for SetReactionRequest
   */
  export interface AsProtobufJSON {
    path: string;
    reactionType: string;
  }
}

/**
 * Message implementation for api.document.SetReactionResponse
 */
export class SetReactionResponse implements GrpcMessage {
  static id = 'api.document.SetReactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetReactionResponse();
    SetReactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetReactionResponse) {
    _instance.reactions = _instance.reactions || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetReactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.reactions = new shared000.Reactions();
          _reader.readMessage(
            _instance.reactions,
            shared000.Reactions.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SetReactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetReactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.reactions) {
      _writer.writeMessage(
        1,
        _instance.reactions as any,
        shared000.Reactions.serializeBinaryToWriter
      );
    }
  }

  private _reactions?: shared000.Reactions;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetReactionResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetReactionResponse.AsObject>) {
    _value = _value || {};
    this.reactions = _value.reactions
      ? new shared000.Reactions(_value.reactions)
      : undefined;
    SetReactionResponse.refineValues(this);
  }
  get reactions(): shared000.Reactions | undefined {
    return this._reactions;
  }
  set reactions(value: shared000.Reactions | undefined) {
    this._reactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetReactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetReactionResponse.AsObject {
    return {
      reactions: this.reactions ? this.reactions.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetReactionResponse.AsProtobufJSON {
    return {
      reactions: this.reactions ? this.reactions.toProtobufJSON(options) : null
    };
  }
}
export module SetReactionResponse {
  /**
   * Standard JavaScript object representation for SetReactionResponse
   */
  export interface AsObject {
    reactions?: shared000.Reactions.AsObject;
  }

  /**
   * Protobuf JSON representation for SetReactionResponse
   */
  export interface AsProtobufJSON {
    reactions: shared000.Reactions.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.document.UpdateCommentRequest
 */
export class UpdateCommentRequest implements GrpcMessage {
  static id = 'api.document.UpdateCommentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCommentRequest();
    UpdateCommentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCommentRequest) {
    _instance.path = _instance.path || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCommentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.path = _reader.readString();
          break;
        case 2:
          _instance.deleteIdPlusOne = _reader.readInt32();
          break;
        case 3:
          _instance.commentToAdd = new CommentToAdd();
          _reader.readMessage(
            _instance.commentToAdd,
            CommentToAdd.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.reactionToAdd = new ReactionToAdd();
          _reader.readMessage(
            _instance.reactionToAdd,
            ReactionToAdd.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCommentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCommentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.path) {
      _writer.writeString(1, _instance.path);
    }
    if (_instance.deleteIdPlusOne || _instance.deleteIdPlusOne === 0) {
      _writer.writeInt32(2, _instance.deleteIdPlusOne);
    }
    if (_instance.commentToAdd) {
      _writer.writeMessage(
        3,
        _instance.commentToAdd as any,
        CommentToAdd.serializeBinaryToWriter
      );
    }
    if (_instance.reactionToAdd) {
      _writer.writeMessage(
        4,
        _instance.reactionToAdd as any,
        ReactionToAdd.serializeBinaryToWriter
      );
    }
  }

  private _path: string;
  private _deleteIdPlusOne: number;
  private _commentToAdd?: CommentToAdd;
  private _reactionToAdd?: ReactionToAdd;

  private _change: UpdateCommentRequest.ChangeCase =
    UpdateCommentRequest.ChangeCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCommentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCommentRequest.AsObject>) {
    _value = _value || {};
    this.path = _value.path;
    this.deleteIdPlusOne = _value.deleteIdPlusOne;
    this.commentToAdd = _value.commentToAdd
      ? new CommentToAdd(_value.commentToAdd)
      : undefined;
    this.reactionToAdd = _value.reactionToAdd
      ? new ReactionToAdd(_value.reactionToAdd)
      : undefined;
    UpdateCommentRequest.refineValues(this);
  }
  get path(): string {
    return this._path;
  }
  set path(value: string) {
    this._path = value;
  }
  get deleteIdPlusOne(): number {
    return this._deleteIdPlusOne;
  }
  set deleteIdPlusOne(value: number) {
    if (value !== undefined && value !== null) {
      this._commentToAdd = this._reactionToAdd = undefined;
      this._change = UpdateCommentRequest.ChangeCase.deleteIdPlusOne;
    }
    this._deleteIdPlusOne = value;
  }
  get commentToAdd(): CommentToAdd | undefined {
    return this._commentToAdd;
  }
  set commentToAdd(value: CommentToAdd | undefined) {
    if (value !== undefined && value !== null) {
      this._deleteIdPlusOne = this._reactionToAdd = undefined;
      this._change = UpdateCommentRequest.ChangeCase.commentToAdd;
    }
    this._commentToAdd = value;
  }
  get reactionToAdd(): ReactionToAdd | undefined {
    return this._reactionToAdd;
  }
  set reactionToAdd(value: ReactionToAdd | undefined) {
    if (value !== undefined && value !== null) {
      this._deleteIdPlusOne = this._commentToAdd = undefined;
      this._change = UpdateCommentRequest.ChangeCase.reactionToAdd;
    }
    this._reactionToAdd = value;
  }
  get change() {
    return this._change;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCommentRequest.AsObject {
    return {
      path: this.path,
      deleteIdPlusOne: this.deleteIdPlusOne,
      commentToAdd: this.commentToAdd
        ? this.commentToAdd.toObject()
        : undefined,
      reactionToAdd: this.reactionToAdd
        ? this.reactionToAdd.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCommentRequest.AsProtobufJSON {
    return {
      path: this.path,
      deleteIdPlusOne:
        this.deleteIdPlusOne === null || this.deleteIdPlusOne === undefined
          ? null
          : this.deleteIdPlusOne,
      commentToAdd: this.commentToAdd
        ? this.commentToAdd.toProtobufJSON(options)
        : null,
      reactionToAdd: this.reactionToAdd
        ? this.reactionToAdd.toProtobufJSON(options)
        : null
    };
  }
}
export module UpdateCommentRequest {
  /**
   * Standard JavaScript object representation for UpdateCommentRequest
   */
  export interface AsObject {
    path: string;
    deleteIdPlusOne: number;
    commentToAdd?: CommentToAdd.AsObject;
    reactionToAdd?: ReactionToAdd.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateCommentRequest
   */
  export interface AsProtobufJSON {
    path: string;
    deleteIdPlusOne: number | null;
    commentToAdd: CommentToAdd.AsProtobufJSON | null;
    reactionToAdd: ReactionToAdd.AsProtobufJSON | null;
  }
  export enum ChangeCase {
    none = 0,
    deleteIdPlusOne = 1,
    commentToAdd = 2,
    reactionToAdd = 3
  }
}

/**
 * Message implementation for api.document.CommentToAdd
 */
export class CommentToAdd implements GrpcMessage {
  static id = 'api.document.CommentToAdd';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommentToAdd();
    CommentToAdd.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommentToAdd) {
    _instance.text = _instance.text || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommentToAdd,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.replyToId = _reader.readInt32();
          break;
        case 2:
          _instance.text = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CommentToAdd.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommentToAdd,
    _writer: BinaryWriter
  ) {
    if (_instance.replyToId !== undefined && _instance.replyToId !== null) {
      _writer.writeInt32(1, _instance.replyToId);
    }
    if (_instance.text) {
      _writer.writeString(2, _instance.text);
    }
  }

  private _replyToId?: number;
  private _text: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommentToAdd to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommentToAdd.AsObject>) {
    _value = _value || {};
    this.replyToId = _value.replyToId;
    this.text = _value.text;
    CommentToAdd.refineValues(this);
  }
  get replyToId(): number | undefined {
    return this._replyToId;
  }
  set replyToId(value?: number) {
    this._replyToId = value;
  }
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommentToAdd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommentToAdd.AsObject {
    return {
      replyToId: this.replyToId,
      text: this.text
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommentToAdd.AsProtobufJSON {
    return {
      replyToId:
        this.replyToId === null || this.replyToId === undefined
          ? null
          : this.replyToId,
      text: this.text
    };
  }
}
export module CommentToAdd {
  /**
   * Standard JavaScript object representation for CommentToAdd
   */
  export interface AsObject {
    replyToId?: number;
    text: string;
  }

  /**
   * Protobuf JSON representation for CommentToAdd
   */
  export interface AsProtobufJSON {
    replyToId: number | null;
    text: string;
  }
}

/**
 * Message implementation for api.document.ReactionToAdd
 */
export class ReactionToAdd implements GrpcMessage {
  static id = 'api.document.ReactionToAdd';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReactionToAdd();
    ReactionToAdd.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReactionToAdd) {
    _instance.commentId = _instance.commentId || 0;
    _instance.reactionType = _instance.reactionType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReactionToAdd,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.commentId = _reader.readInt32();
          break;
        case 2:
          _instance.reactionType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ReactionToAdd.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReactionToAdd,
    _writer: BinaryWriter
  ) {
    if (_instance.commentId) {
      _writer.writeInt32(1, _instance.commentId);
    }
    if (_instance.reactionType) {
      _writer.writeEnum(2, _instance.reactionType);
    }
  }

  private _commentId: number;
  private _reactionType: shared000.ReactionType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReactionToAdd to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReactionToAdd.AsObject>) {
    _value = _value || {};
    this.commentId = _value.commentId;
    this.reactionType = _value.reactionType;
    ReactionToAdd.refineValues(this);
  }
  get commentId(): number {
    return this._commentId;
  }
  set commentId(value: number) {
    this._commentId = value;
  }
  get reactionType(): shared000.ReactionType {
    return this._reactionType;
  }
  set reactionType(value: shared000.ReactionType) {
    this._reactionType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReactionToAdd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReactionToAdd.AsObject {
    return {
      commentId: this.commentId,
      reactionType: this.reactionType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReactionToAdd.AsProtobufJSON {
    return {
      commentId: this.commentId,
      reactionType:
        shared000.ReactionType[
          this.reactionType === null || this.reactionType === undefined
            ? 0
            : this.reactionType
        ]
    };
  }
}
export module ReactionToAdd {
  /**
   * Standard JavaScript object representation for ReactionToAdd
   */
  export interface AsObject {
    commentId: number;
    reactionType: shared000.ReactionType;
  }

  /**
   * Protobuf JSON representation for ReactionToAdd
   */
  export interface AsProtobufJSON {
    commentId: number;
    reactionType: string;
  }
}

/**
 * Message implementation for api.document.UpdateCommentResponse
 */
export class UpdateCommentResponse implements GrpcMessage {
  static id = 'api.document.UpdateCommentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCommentResponse();
    UpdateCommentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCommentResponse) {
    _instance.reactions = _instance.reactions || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCommentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.reactions = new shared000.Reactions();
          _reader.readMessage(
            _instance.reactions,
            shared000.Reactions.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCommentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCommentResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.reactions) {
      _writer.writeMessage(
        1,
        _instance.reactions as any,
        shared000.Reactions.serializeBinaryToWriter
      );
    }
  }

  private _reactions?: shared000.Reactions;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCommentResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCommentResponse.AsObject>) {
    _value = _value || {};
    this.reactions = _value.reactions
      ? new shared000.Reactions(_value.reactions)
      : undefined;
    UpdateCommentResponse.refineValues(this);
  }
  get reactions(): shared000.Reactions | undefined {
    return this._reactions;
  }
  set reactions(value: shared000.Reactions | undefined) {
    this._reactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCommentResponse.AsObject {
    return {
      reactions: this.reactions ? this.reactions.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCommentResponse.AsProtobufJSON {
    return {
      reactions: this.reactions ? this.reactions.toProtobufJSON(options) : null
    };
  }
}
export module UpdateCommentResponse {
  /**
   * Standard JavaScript object representation for UpdateCommentResponse
   */
  export interface AsObject {
    reactions?: shared000.Reactions.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateCommentResponse
   */
  export interface AsProtobufJSON {
    reactions: shared000.Reactions.AsProtobufJSON | null;
  }
}
