<div *ngIf="!redirect" class="fitbit">
  <div>
    <button mat-raised-button class="button" [disabled]="logInDisabled" (click)="onFitbitLogin()"
      matTooltip="Click to start syncing Fitbit activities">Fitbit Login</button>
    <button mat-raised-button color="accent" class="button" [disabled]="logOutDisabled" (click)="onFitbitLogout()"
      matTooltip="Click to stop syncing Fitbit activities">Log
      out</button>
  </div>
  <div *ngIf="fitbitUserId" class="text">User Id: {{ fitbitUserId }}</div>
  <div class="text" [ngStyle]="{ 'color': loginStatusDisplay === 'Logged In' ? 'green' : 'orange' }">{{
    loginStatusDisplay }}
  </div>
  <br />
  <span class="fitbit-small-print">"Fitbit is a registered trademark or trademark of Fitbit, LLC in the
    United States and certain other countries.
    A list of Fitbit logos can be found at www.fitbit.com/legal/trademark-list.
    The Jestle application is designed for use with the Fitbit platform.
    This application is not authored by Fitbit, and Fitbit does not service or warrant the functionality of
    this application."</span>
</div>
