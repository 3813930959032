/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './event-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import { GRPC_EVENT_SERVICE_CLIENT_SETTINGS } from './event-service.pbconf';
/**
 * Service client implementation for api.event.EventService
 */
@Injectable({ providedIn: 'any' })
export class EventServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.event.EventService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetResponse>>
     */
    get: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.event.EventService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetResponse
      });
    },
    /**
     * Unary call: /api.event.EventService/Add
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddResponse>>
     */
    add: (
      requestData: thisProto.AddRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.event.EventService/Add',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddRequest,
        responseClass: thisProto.AddResponse
      });
    },
    /**
     * Unary call: /api.event.EventService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.event.EventService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse
      });
    },
    /**
     * Unary call: /api.event.EventService/Delete
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteResponse>>
     */
    delete: (
      requestData: thisProto.DeleteRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DeleteResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.event.EventService/Delete',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteRequest,
        responseClass: thisProto.DeleteResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_EVENT_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.event.EventService',
      settings
    );
  }

  /**
   * Unary call @/api.event.EventService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetResponse>
   */
  get(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.event.EventService/Add
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddResponse>
   */
  add(
    requestData: thisProto.AddRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddResponse> {
    return this.$raw
      .add(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.event.EventService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.event.EventService/Delete
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteResponse>
   */
  delete(
    requestData: thisProto.DeleteRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteResponse> {
    return this.$raw
      .delete(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
