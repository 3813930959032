<!-- Bar to click on to expand/collapse the zones -->
<div class="click-bar" tabindex="0" (click)="onToggleBarClick()" (keydown)="onToggleBarKeyDown($event)"
  matTooltip="Click to show/hide HR zones" role="button">
  <mat-icon class="click-bar-icon">favorite</mat-icon>
  <span class="material-symbols-outlined expand-icon">{{ isExpanded ? 'expand_less' : 'expand_more' }}</span>
</div>

<!-- HR zones chart -->
<div class="zones-chart-container" *ngIf="isExpanded">
  <div class="zones-chart-title">
    <span>Time in HR Zones</span>
    <span class="material-symbols-outlined info-icon" role="button" tabindex="0"
      (keydown)="onHrZonesInfoKeyDown($event)" (click)="onHrZonesInfoClick()"
      matTooltip="Click to learn more about HR zones">
      info
    </span>
  </div>
  <div echarts class="zones-chart" [options]="hrZonesChartOptions" matTooltip="Time in HR zones">
  </div>
</div>
