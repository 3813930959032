import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FitbitLoginModule } from 'src/app/common/fitibit-login/fitbit-login.module';
import { GarminLoginModule } from '../common/garmin-login/garmin-login.module';
import { FitbitRedirectComponent } from './fitbit-redirect/fitbit-redirect.component';
import { GarminRedirectComponent } from './garmin-redirect/garmin-redirect.component';

@NgModule({
  declarations: [FitbitRedirectComponent, GarminRedirectComponent],
  exports: [FitbitRedirectComponent, GarminRedirectComponent],
  imports: [CommonModule, FitbitLoginModule, GarminLoginModule],
})
export class RedirectsModule {}
