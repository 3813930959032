/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum ActivityType {
  ACTIVITY_TYPE_UNSPECIFIED = 0,
  ACTIVITY_TYPE_WORKOUT = 1,
  ACTIVITY_TYPE_WALK = 2,
  ACTIVITY_TYPE_RUN = 3,
  ACTIVITY_TYPE_ALPINE_SKI = 4,
  ACTIVITY_TYPE_CROSS_COUNTRY_SKI = 5,
  ACTIVITY_TYPE_ROAD_BIKE = 6,
  ACTIVITY_TYPE_MOUNTAIN_BIKE = 7,
  ACTIVITY_TYPE_POOL_SWIM = 8,
  ACTIVITY_TYPE_OPEN_SWIM = 9,
  ACTIVITY_TYPE_YOGA = 10
}
export enum StatsGranularity {
  STATS_GRANULARITY_UNSPECIFIED = 0,
  STATS_GRANULARITY_WEEK = 1,
  STATS_GRANULARITY_MONTH = 2,
  STATS_GRANULARITY_YEAR = 3
}
/**
 * Message implementation for shared.ActivityTypeMap
 */
export class ActivityTypeMap implements GrpcMessage {
  static id = 'shared.ActivityTypeMap';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityTypeMap();
    ActivityTypeMap.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityTypeMap) {
    _instance.map = _instance.map || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityTypeMap,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            ActivityTypeMap.MapEntry.deserializeBinaryFromReader
          );
          _instance.map = _instance.map || {};
          _instance.map[msg_1.key] = msg_1.value;
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityTypeMap.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityTypeMap,
    _writer: BinaryWriter
  ) {
    if (!!_instance.map) {
      const keys_1 = Object.keys(_instance.map as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map(key => ({ key: key, value: (_instance.map as any)[key] }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          ActivityTypeMap.MapEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _map: { [prop: string]: ActivityType };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityTypeMap to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityTypeMap.AsObject>) {
    _value = _value || {};
    (this.map = _value!.map
      ? Object.keys(_value!.map).reduce(
          (r, k) => ({ ...r, [k]: _value!.map![k] }),
          {}
        )
      : {}),
      ActivityTypeMap.refineValues(this);
  }
  get map(): { [prop: string]: ActivityType } {
    return this._map;
  }
  set map(value: { [prop: string]: ActivityType }) {
    this._map = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityTypeMap.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityTypeMap.AsObject {
    return {
      map: this.map
        ? Object.keys(this.map).reduce(
            (r, k) => ({ ...r, [k]: this.map![k] }),
            {}
          )
        : {}
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityTypeMap.AsProtobufJSON {
    return {
      map: this.map
        ? Object.keys(this.map).reduce(
            (r, k) => ({ ...r, [k]: this.map![k] }),
            {}
          )
        : {}
    };
  }
}
export module ActivityTypeMap {
  /**
   * Standard JavaScript object representation for ActivityTypeMap
   */
  export interface AsObject {
    map: { [prop: string]: ActivityType };
  }

  /**
   * Protobuf JSON representation for ActivityTypeMap
   */
  export interface AsProtobufJSON {
    map: { [prop: string]: ActivityType };
  }

  /**
   * Message implementation for shared.ActivityTypeMap.MapEntry
   */
  export class MapEntry implements GrpcMessage {
    static id = 'shared.ActivityTypeMap.MapEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new MapEntry();
      MapEntry.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: MapEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: MapEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readEnum();
            break;
          default:
            _reader.skipField();
        }
      }

      MapEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: MapEntry, _writer: BinaryWriter) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeEnum(2, _instance.value);
      }
    }

    private _key: string;
    private _value: ActivityType;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of MapEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<MapEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      MapEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): ActivityType {
      return this._value;
    }
    set value(value: ActivityType) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      MapEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): MapEntry.AsObject {
      return {
        key: this.key,
        value: this.value
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): MapEntry.AsProtobufJSON {
      return {
        key: this.key,
        value:
          ActivityType[
            this.value === null || this.value === undefined ? 0 : this.value
          ]
      };
    }
  }
  export module MapEntry {
    /**
     * Standard JavaScript object representation for MapEntry
     */
    export interface AsObject {
      key: string;
      value: ActivityType;
    }

    /**
     * Protobuf JSON representation for MapEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for shared.Gear
 */
export class Gear implements GrpcMessage {
  static id = 'shared.Gear';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Gear();
    Gear.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Gear) {
    _instance.deviceProductName = _instance.deviceProductName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Gear, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.deviceProductName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Gear.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Gear, _writer: BinaryWriter) {
    if (_instance.deviceProductName) {
      _writer.writeString(1, _instance.deviceProductName);
    }
  }

  private _deviceProductName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Gear to deeply clone from
   */
  constructor(_value?: RecursivePartial<Gear.AsObject>) {
    _value = _value || {};
    this.deviceProductName = _value.deviceProductName;
    Gear.refineValues(this);
  }
  get deviceProductName(): string {
    return this._deviceProductName;
  }
  set deviceProductName(value: string) {
    this._deviceProductName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Gear.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Gear.AsObject {
    return {
      deviceProductName: this.deviceProductName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Gear.AsProtobufJSON {
    return {
      deviceProductName: this.deviceProductName
    };
  }
}
export module Gear {
  /**
   * Standard JavaScript object representation for Gear
   */
  export interface AsObject {
    deviceProductName: string;
  }

  /**
   * Protobuf JSON representation for Gear
   */
  export interface AsProtobufJSON {
    deviceProductName: string;
  }
}

/**
 * Message implementation for shared.Track
 */
export class Track implements GrpcMessage {
  static id = 'shared.Track';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Track();
    Track.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Track) {
    _instance.points = _instance.points || [];
    _instance.noLocation = _instance.noLocation || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Track, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new TrackPoint();
          _reader.readMessage(
            messageInitializer1,
            TrackPoint.deserializeBinaryFromReader
          );
          (_instance.points = _instance.points || []).push(messageInitializer1);
          break;
        case 2:
          _instance.noLocation = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    Track.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Track, _writer: BinaryWriter) {
    if (_instance.points && _instance.points.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.points as any,
        TrackPoint.serializeBinaryToWriter
      );
    }
    if (_instance.noLocation) {
      _writer.writeBool(2, _instance.noLocation);
    }
  }

  private _points?: TrackPoint[];
  private _noLocation: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Track to deeply clone from
   */
  constructor(_value?: RecursivePartial<Track.AsObject>) {
    _value = _value || {};
    this.points = (_value.points || []).map(m => new TrackPoint(m));
    this.noLocation = _value.noLocation;
    Track.refineValues(this);
  }
  get points(): TrackPoint[] | undefined {
    return this._points;
  }
  set points(value: TrackPoint[] | undefined) {
    this._points = value;
  }
  get noLocation(): boolean {
    return this._noLocation;
  }
  set noLocation(value: boolean) {
    this._noLocation = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Track.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Track.AsObject {
    return {
      points: (this.points || []).map(m => m.toObject()),
      noLocation: this.noLocation
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Track.AsProtobufJSON {
    return {
      points: (this.points || []).map(m => m.toProtobufJSON(options)),
      noLocation: this.noLocation
    };
  }
}
export module Track {
  /**
   * Standard JavaScript object representation for Track
   */
  export interface AsObject {
    points?: TrackPoint.AsObject[];
    noLocation: boolean;
  }

  /**
   * Protobuf JSON representation for Track
   */
  export interface AsProtobufJSON {
    points: TrackPoint.AsProtobufJSON[] | null;
    noLocation: boolean;
  }
}

/**
 * Message implementation for shared.TrackPoint
 */
export class TrackPoint implements GrpcMessage {
  static id = 'shared.TrackPoint';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TrackPoint();
    TrackPoint.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TrackPoint) {
    _instance.latLongAlt = _instance.latLongAlt || undefined;
    _instance.time = _instance.time || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TrackPoint,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.latLongAlt = new LatLongAlt();
          _reader.readMessage(
            _instance.latLongAlt,
            LatLongAlt.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.temperature = _reader.readInt32();
          break;
        case 4:
          _instance.distance = _reader.readFloat();
          break;
        case 5:
          _instance.speed = _reader.readFloat();
          break;
        case 6:
          _instance.heartRate = _reader.readInt32();
          break;
        case 7:
          _instance.calories = _reader.readInt32();
          break;
        case 8:
          _instance.grade = _reader.readFloat();
          break;
        case 9:
          _instance.cadence = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    TrackPoint.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TrackPoint, _writer: BinaryWriter) {
    if (_instance.latLongAlt) {
      _writer.writeMessage(
        1,
        _instance.latLongAlt as any,
        LatLongAlt.serializeBinaryToWriter
      );
    }
    if (_instance.time) {
      _writer.writeMessage(
        2,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.temperature !== undefined && _instance.temperature !== null) {
      _writer.writeInt32(3, _instance.temperature);
    }
    if (_instance.distance !== undefined && _instance.distance !== null) {
      _writer.writeFloat(4, _instance.distance);
    }
    if (_instance.speed !== undefined && _instance.speed !== null) {
      _writer.writeFloat(5, _instance.speed);
    }
    if (_instance.heartRate !== undefined && _instance.heartRate !== null) {
      _writer.writeInt32(6, _instance.heartRate);
    }
    if (_instance.calories !== undefined && _instance.calories !== null) {
      _writer.writeInt32(7, _instance.calories);
    }
    if (_instance.grade !== undefined && _instance.grade !== null) {
      _writer.writeFloat(8, _instance.grade);
    }
    if (_instance.cadence !== undefined && _instance.cadence !== null) {
      _writer.writeInt32(9, _instance.cadence);
    }
  }

  private _latLongAlt?: LatLongAlt;
  private _time?: googleProtobuf000.Timestamp;
  private _temperature?: number;
  private _distance?: number;
  private _speed?: number;
  private _heartRate?: number;
  private _calories?: number;
  private _grade?: number;
  private _cadence?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TrackPoint to deeply clone from
   */
  constructor(_value?: RecursivePartial<TrackPoint.AsObject>) {
    _value = _value || {};
    this.latLongAlt = _value.latLongAlt
      ? new LatLongAlt(_value.latLongAlt)
      : undefined;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    this.temperature = _value.temperature;
    this.distance = _value.distance;
    this.speed = _value.speed;
    this.heartRate = _value.heartRate;
    this.calories = _value.calories;
    this.grade = _value.grade;
    this.cadence = _value.cadence;
    TrackPoint.refineValues(this);
  }
  get latLongAlt(): LatLongAlt | undefined {
    return this._latLongAlt;
  }
  set latLongAlt(value: LatLongAlt | undefined) {
    this._latLongAlt = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }
  get temperature(): number | undefined {
    return this._temperature;
  }
  set temperature(value?: number) {
    this._temperature = value;
  }
  get distance(): number | undefined {
    return this._distance;
  }
  set distance(value?: number) {
    this._distance = value;
  }
  get speed(): number | undefined {
    return this._speed;
  }
  set speed(value?: number) {
    this._speed = value;
  }
  get heartRate(): number | undefined {
    return this._heartRate;
  }
  set heartRate(value?: number) {
    this._heartRate = value;
  }
  get calories(): number | undefined {
    return this._calories;
  }
  set calories(value?: number) {
    this._calories = value;
  }
  get grade(): number | undefined {
    return this._grade;
  }
  set grade(value?: number) {
    this._grade = value;
  }
  get cadence(): number | undefined {
    return this._cadence;
  }
  set cadence(value?: number) {
    this._cadence = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TrackPoint.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TrackPoint.AsObject {
    return {
      latLongAlt: this.latLongAlt ? this.latLongAlt.toObject() : undefined,
      time: this.time ? this.time.toObject() : undefined,
      temperature: this.temperature,
      distance: this.distance,
      speed: this.speed,
      heartRate: this.heartRate,
      calories: this.calories,
      grade: this.grade,
      cadence: this.cadence
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TrackPoint.AsProtobufJSON {
    return {
      latLongAlt: this.latLongAlt
        ? this.latLongAlt.toProtobufJSON(options)
        : null,
      time: this.time ? this.time.toProtobufJSON(options) : null,
      temperature:
        this.temperature === null || this.temperature === undefined
          ? null
          : this.temperature,
      distance:
        this.distance === null || this.distance === undefined
          ? null
          : this.distance,
      speed:
        this.speed === null || this.speed === undefined ? null : this.speed,
      heartRate:
        this.heartRate === null || this.heartRate === undefined
          ? null
          : this.heartRate,
      calories:
        this.calories === null || this.calories === undefined
          ? null
          : this.calories,
      grade:
        this.grade === null || this.grade === undefined ? null : this.grade,
      cadence:
        this.cadence === null || this.cadence === undefined
          ? null
          : this.cadence
    };
  }
}
export module TrackPoint {
  /**
   * Standard JavaScript object representation for TrackPoint
   */
  export interface AsObject {
    latLongAlt?: LatLongAlt.AsObject;
    time?: googleProtobuf000.Timestamp.AsObject;
    temperature?: number;
    distance?: number;
    speed?: number;
    heartRate?: number;
    calories?: number;
    grade?: number;
    cadence?: number;
  }

  /**
   * Protobuf JSON representation for TrackPoint
   */
  export interface AsProtobufJSON {
    latLongAlt: LatLongAlt.AsProtobufJSON | null;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    temperature: number | null;
    distance: number | null;
    speed: number | null;
    heartRate: number | null;
    calories: number | null;
    grade: number | null;
    cadence: number | null;
  }
}

/**
 * Message implementation for shared.LatLongAlt
 */
export class LatLongAlt implements GrpcMessage {
  static id = 'shared.LatLongAlt';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LatLongAlt();
    LatLongAlt.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LatLongAlt) {
    _instance.latitude = _instance.latitude || 0;
    _instance.longitude = _instance.longitude || 0;
    _instance.altitude = _instance.altitude || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LatLongAlt,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.latitude = _reader.readDouble();
          break;
        case 2:
          _instance.longitude = _reader.readDouble();
          break;
        case 3:
          _instance.altitude = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    LatLongAlt.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: LatLongAlt, _writer: BinaryWriter) {
    if (_instance.latitude) {
      _writer.writeDouble(1, _instance.latitude);
    }
    if (_instance.longitude) {
      _writer.writeDouble(2, _instance.longitude);
    }
    if (_instance.altitude) {
      _writer.writeDouble(3, _instance.altitude);
    }
  }

  private _latitude: number;
  private _longitude: number;
  private _altitude: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LatLongAlt to deeply clone from
   */
  constructor(_value?: RecursivePartial<LatLongAlt.AsObject>) {
    _value = _value || {};
    this.latitude = _value.latitude;
    this.longitude = _value.longitude;
    this.altitude = _value.altitude;
    LatLongAlt.refineValues(this);
  }
  get latitude(): number {
    return this._latitude;
  }
  set latitude(value: number) {
    this._latitude = value;
  }
  get longitude(): number {
    return this._longitude;
  }
  set longitude(value: number) {
    this._longitude = value;
  }
  get altitude(): number {
    return this._altitude;
  }
  set altitude(value: number) {
    this._altitude = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LatLongAlt.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LatLongAlt.AsObject {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      altitude: this.altitude
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LatLongAlt.AsProtobufJSON {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      altitude: this.altitude
    };
  }
}
export module LatLongAlt {
  /**
   * Standard JavaScript object representation for LatLongAlt
   */
  export interface AsObject {
    latitude: number;
    longitude: number;
    altitude: number;
  }

  /**
   * Protobuf JSON representation for LatLongAlt
   */
  export interface AsProtobufJSON {
    latitude: number;
    longitude: number;
    altitude: number;
  }
}

/**
 * Message implementation for shared.Lengths
 */
export class Lengths implements GrpcMessage {
  static id = 'shared.Lengths';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Lengths();
    Lengths.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Lengths) {
    _instance.lengths = _instance.lengths || [];
    _instance.activeTimeSeconds = _instance.activeTimeSeconds || 0;
    _instance.idleTimeSeconds = _instance.idleTimeSeconds || 0;
    _instance.poolLength = _instance.poolLength || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Lengths,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new Length();
          _reader.readMessage(
            messageInitializer1,
            Length.deserializeBinaryFromReader
          );
          (_instance.lengths = _instance.lengths || []).push(
            messageInitializer1
          );
          break;
        case 2:
          _instance.activeTimeSeconds = _reader.readFloat();
          break;
        case 3:
          _instance.idleTimeSeconds = _reader.readFloat();
          break;
        case 4:
          _instance.poolLength = _reader.readFloat();
          break;
        default:
          _reader.skipField();
      }
    }

    Lengths.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Lengths, _writer: BinaryWriter) {
    if (_instance.lengths && _instance.lengths.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.lengths as any,
        Length.serializeBinaryToWriter
      );
    }
    if (_instance.activeTimeSeconds) {
      _writer.writeFloat(2, _instance.activeTimeSeconds);
    }
    if (_instance.idleTimeSeconds) {
      _writer.writeFloat(3, _instance.idleTimeSeconds);
    }
    if (_instance.poolLength) {
      _writer.writeFloat(4, _instance.poolLength);
    }
  }

  private _lengths?: Length[];
  private _activeTimeSeconds: number;
  private _idleTimeSeconds: number;
  private _poolLength: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Lengths to deeply clone from
   */
  constructor(_value?: RecursivePartial<Lengths.AsObject>) {
    _value = _value || {};
    this.lengths = (_value.lengths || []).map(m => new Length(m));
    this.activeTimeSeconds = _value.activeTimeSeconds;
    this.idleTimeSeconds = _value.idleTimeSeconds;
    this.poolLength = _value.poolLength;
    Lengths.refineValues(this);
  }
  get lengths(): Length[] | undefined {
    return this._lengths;
  }
  set lengths(value: Length[] | undefined) {
    this._lengths = value;
  }
  get activeTimeSeconds(): number {
    return this._activeTimeSeconds;
  }
  set activeTimeSeconds(value: number) {
    this._activeTimeSeconds = value;
  }
  get idleTimeSeconds(): number {
    return this._idleTimeSeconds;
  }
  set idleTimeSeconds(value: number) {
    this._idleTimeSeconds = value;
  }
  get poolLength(): number {
    return this._poolLength;
  }
  set poolLength(value: number) {
    this._poolLength = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Lengths.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Lengths.AsObject {
    return {
      lengths: (this.lengths || []).map(m => m.toObject()),
      activeTimeSeconds: this.activeTimeSeconds,
      idleTimeSeconds: this.idleTimeSeconds,
      poolLength: this.poolLength
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Lengths.AsProtobufJSON {
    return {
      lengths: (this.lengths || []).map(m => m.toProtobufJSON(options)),
      activeTimeSeconds: this.activeTimeSeconds,
      idleTimeSeconds: this.idleTimeSeconds,
      poolLength: this.poolLength
    };
  }
}
export module Lengths {
  /**
   * Standard JavaScript object representation for Lengths
   */
  export interface AsObject {
    lengths?: Length.AsObject[];
    activeTimeSeconds: number;
    idleTimeSeconds: number;
    poolLength: number;
  }

  /**
   * Protobuf JSON representation for Lengths
   */
  export interface AsProtobufJSON {
    lengths: Length.AsProtobufJSON[] | null;
    activeTimeSeconds: number;
    idleTimeSeconds: number;
    poolLength: number;
  }
}

/**
 * Message implementation for shared.Length
 */
export class Length implements GrpcMessage {
  static id = 'shared.Length';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Length();
    Length.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Length) {
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Length, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.calories = _reader.readInt32();
          break;
        case 4:
          _instance.swimStrokeType = _reader.readEnum();
          break;
        case 5:
          _instance.strokes = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    Length.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Length, _writer: BinaryWriter) {
    if (_instance.startTime) {
      _writer.writeMessage(
        1,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        2,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.calories !== undefined && _instance.calories !== null) {
      _writer.writeInt32(3, _instance.calories);
    }
    if (
      _instance.swimStrokeType !== undefined &&
      _instance.swimStrokeType !== null
    ) {
      _writer.writeEnum(4, _instance.swimStrokeType);
    }
    if (_instance.strokes !== undefined && _instance.strokes !== null) {
      _writer.writeInt32(5, _instance.strokes);
    }
  }

  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;
  private _calories?: number;
  private _swimStrokeType?: Length.SwimStrokeType;
  private _strokes?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Length to deeply clone from
   */
  constructor(_value?: RecursivePartial<Length.AsObject>) {
    _value = _value || {};
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    this.calories = _value.calories;
    this.swimStrokeType = _value.swimStrokeType;
    this.strokes = _value.strokes;
    Length.refineValues(this);
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }
  get calories(): number | undefined {
    return this._calories;
  }
  set calories(value?: number) {
    this._calories = value;
  }
  get swimStrokeType(): Length.SwimStrokeType | undefined {
    return this._swimStrokeType;
  }
  set swimStrokeType(value?: Length.SwimStrokeType) {
    this._swimStrokeType = value;
  }
  get strokes(): number | undefined {
    return this._strokes;
  }
  set strokes(value?: number) {
    this._strokes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Length.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Length.AsObject {
    return {
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
      calories: this.calories,
      swimStrokeType: this.swimStrokeType,
      strokes: this.strokes
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Length.AsProtobufJSON {
    return {
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
      calories:
        this.calories === null || this.calories === undefined
          ? null
          : this.calories,
      swimStrokeType:
        this.swimStrokeType === undefined
          ? null
          : Length.SwimStrokeType[
              this.swimStrokeType === null || this.swimStrokeType === undefined
                ? 0
                : this.swimStrokeType
            ],
      strokes:
        this.strokes === null || this.strokes === undefined
          ? null
          : this.strokes
    };
  }
}
export module Length {
  /**
   * Standard JavaScript object representation for Length
   */
  export interface AsObject {
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
    calories?: number;
    swimStrokeType?: Length.SwimStrokeType;
    strokes?: number;
  }

  /**
   * Protobuf JSON representation for Length
   */
  export interface AsProtobufJSON {
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    calories: number | null;
    swimStrokeType: string | null;
    strokes: number | null;
  }
  export enum SwimStrokeType {
    SWIM_STROKE_TYPE_UNSPECIFIED = 0,
    SWIM_STROKE_TYPE_FREE_STYLE = 1,
    SWIM_STROKE_TYPE_BACKSTROKE = 2,
    SWIM_STROKE_TYPE_BREASTSTROKE = 3,
    SWIM_STROKE_TYPE_BUTTERFLY = 4,
    SWIM_STROKE_TYPE_DRILL = 5,
    SWIM_STROKE_TYPE_INDIVIDUAL_MEDLEY = 6
  }
}

/**
 * Message implementation for shared.StatsRequestTimeInterval
 */
export class StatsRequestTimeInterval implements GrpcMessage {
  static id = 'shared.StatsRequestTimeInterval';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StatsRequestTimeInterval();
    StatsRequestTimeInterval.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StatsRequestTimeInterval) {
    _instance.granularity = _instance.granularity || 0;
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StatsRequestTimeInterval,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.granularity = _reader.readEnum();
          break;
        case 2:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    StatsRequestTimeInterval.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StatsRequestTimeInterval,
    _writer: BinaryWriter
  ) {
    if (_instance.granularity) {
      _writer.writeEnum(1, _instance.granularity);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        2,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        3,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _granularity: StatsGranularity;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StatsRequestTimeInterval to deeply clone from
   */
  constructor(_value?: RecursivePartial<StatsRequestTimeInterval.AsObject>) {
    _value = _value || {};
    this.granularity = _value.granularity;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    StatsRequestTimeInterval.refineValues(this);
  }
  get granularity(): StatsGranularity {
    return this._granularity;
  }
  set granularity(value: StatsGranularity) {
    this._granularity = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StatsRequestTimeInterval.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StatsRequestTimeInterval.AsObject {
    return {
      granularity: this.granularity,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StatsRequestTimeInterval.AsProtobufJSON {
    return {
      granularity:
        StatsGranularity[
          this.granularity === null || this.granularity === undefined
            ? 0
            : this.granularity
        ],
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null
    };
  }
}
export module StatsRequestTimeInterval {
  /**
   * Standard JavaScript object representation for StatsRequestTimeInterval
   */
  export interface AsObject {
    granularity: StatsGranularity;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for StatsRequestTimeInterval
   */
  export interface AsProtobufJSON {
    granularity: string;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.StatsByActivityType
 */
export class StatsByActivityType implements GrpcMessage {
  static id = 'shared.StatsByActivityType';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StatsByActivityType();
    StatsByActivityType.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StatsByActivityType) {
    _instance.activityType = _instance.activityType || 0;
    _instance.stats = _instance.stats || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StatsByActivityType,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityType = _reader.readEnum();
          break;
        case 2:
          _instance.stats = new IntervalStats();
          _reader.readMessage(
            _instance.stats,
            IntervalStats.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    StatsByActivityType.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StatsByActivityType,
    _writer: BinaryWriter
  ) {
    if (_instance.activityType) {
      _writer.writeEnum(1, _instance.activityType);
    }
    if (_instance.stats) {
      _writer.writeMessage(
        2,
        _instance.stats as any,
        IntervalStats.serializeBinaryToWriter
      );
    }
  }

  private _activityType: ActivityType;
  private _stats?: IntervalStats;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StatsByActivityType to deeply clone from
   */
  constructor(_value?: RecursivePartial<StatsByActivityType.AsObject>) {
    _value = _value || {};
    this.activityType = _value.activityType;
    this.stats = _value.stats ? new IntervalStats(_value.stats) : undefined;
    StatsByActivityType.refineValues(this);
  }
  get activityType(): ActivityType {
    return this._activityType;
  }
  set activityType(value: ActivityType) {
    this._activityType = value;
  }
  get stats(): IntervalStats | undefined {
    return this._stats;
  }
  set stats(value: IntervalStats | undefined) {
    this._stats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StatsByActivityType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StatsByActivityType.AsObject {
    return {
      activityType: this.activityType,
      stats: this.stats ? this.stats.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StatsByActivityType.AsProtobufJSON {
    return {
      activityType:
        ActivityType[
          this.activityType === null || this.activityType === undefined
            ? 0
            : this.activityType
        ],
      stats: this.stats ? this.stats.toProtobufJSON(options) : null
    };
  }
}
export module StatsByActivityType {
  /**
   * Standard JavaScript object representation for StatsByActivityType
   */
  export interface AsObject {
    activityType: ActivityType;
    stats?: IntervalStats.AsObject;
  }

  /**
   * Protobuf JSON representation for StatsByActivityType
   */
  export interface AsProtobufJSON {
    activityType: string;
    stats: IntervalStats.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.ActivityStats
 */
export class ActivityStats implements GrpcMessage {
  static id = 'shared.ActivityStats';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityStats();
    ActivityStats.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityStats) {
    _instance.totalStats = _instance.totalStats || undefined;
    _instance.mileStats = _instance.mileStats || [];
    _instance.kmStats = _instance.kmStats || [];
    _instance.hourStats = _instance.hourStats || [];
    _instance.dayStats = _instance.dayStats || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityStats,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.totalStats = new IntervalStats();
          _reader.readMessage(
            _instance.totalStats,
            IntervalStats.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new IntervalStats();
          _reader.readMessage(
            messageInitializer2,
            IntervalStats.deserializeBinaryFromReader
          );
          (_instance.mileStats = _instance.mileStats || []).push(
            messageInitializer2
          );
          break;
        case 3:
          const messageInitializer3 = new IntervalStats();
          _reader.readMessage(
            messageInitializer3,
            IntervalStats.deserializeBinaryFromReader
          );
          (_instance.kmStats = _instance.kmStats || []).push(
            messageInitializer3
          );
          break;
        case 4:
          const messageInitializer4 = new IntervalStats();
          _reader.readMessage(
            messageInitializer4,
            IntervalStats.deserializeBinaryFromReader
          );
          (_instance.hourStats = _instance.hourStats || []).push(
            messageInitializer4
          );
          break;
        case 5:
          const messageInitializer5 = new IntervalStats();
          _reader.readMessage(
            messageInitializer5,
            IntervalStats.deserializeBinaryFromReader
          );
          (_instance.dayStats = _instance.dayStats || []).push(
            messageInitializer5
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityStats.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityStats,
    _writer: BinaryWriter
  ) {
    if (_instance.totalStats) {
      _writer.writeMessage(
        1,
        _instance.totalStats as any,
        IntervalStats.serializeBinaryToWriter
      );
    }
    if (_instance.mileStats && _instance.mileStats.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.mileStats as any,
        IntervalStats.serializeBinaryToWriter
      );
    }
    if (_instance.kmStats && _instance.kmStats.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.kmStats as any,
        IntervalStats.serializeBinaryToWriter
      );
    }
    if (_instance.hourStats && _instance.hourStats.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.hourStats as any,
        IntervalStats.serializeBinaryToWriter
      );
    }
    if (_instance.dayStats && _instance.dayStats.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.dayStats as any,
        IntervalStats.serializeBinaryToWriter
      );
    }
  }

  private _totalStats?: IntervalStats;
  private _mileStats?: IntervalStats[];
  private _kmStats?: IntervalStats[];
  private _hourStats?: IntervalStats[];
  private _dayStats?: IntervalStats[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityStats to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityStats.AsObject>) {
    _value = _value || {};
    this.totalStats = _value.totalStats
      ? new IntervalStats(_value.totalStats)
      : undefined;
    this.mileStats = (_value.mileStats || []).map(m => new IntervalStats(m));
    this.kmStats = (_value.kmStats || []).map(m => new IntervalStats(m));
    this.hourStats = (_value.hourStats || []).map(m => new IntervalStats(m));
    this.dayStats = (_value.dayStats || []).map(m => new IntervalStats(m));
    ActivityStats.refineValues(this);
  }
  get totalStats(): IntervalStats | undefined {
    return this._totalStats;
  }
  set totalStats(value: IntervalStats | undefined) {
    this._totalStats = value;
  }
  get mileStats(): IntervalStats[] | undefined {
    return this._mileStats;
  }
  set mileStats(value: IntervalStats[] | undefined) {
    this._mileStats = value;
  }
  get kmStats(): IntervalStats[] | undefined {
    return this._kmStats;
  }
  set kmStats(value: IntervalStats[] | undefined) {
    this._kmStats = value;
  }
  get hourStats(): IntervalStats[] | undefined {
    return this._hourStats;
  }
  set hourStats(value: IntervalStats[] | undefined) {
    this._hourStats = value;
  }
  get dayStats(): IntervalStats[] | undefined {
    return this._dayStats;
  }
  set dayStats(value: IntervalStats[] | undefined) {
    this._dayStats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityStats.AsObject {
    return {
      totalStats: this.totalStats ? this.totalStats.toObject() : undefined,
      mileStats: (this.mileStats || []).map(m => m.toObject()),
      kmStats: (this.kmStats || []).map(m => m.toObject()),
      hourStats: (this.hourStats || []).map(m => m.toObject()),
      dayStats: (this.dayStats || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityStats.AsProtobufJSON {
    return {
      totalStats: this.totalStats
        ? this.totalStats.toProtobufJSON(options)
        : null,
      mileStats: (this.mileStats || []).map(m => m.toProtobufJSON(options)),
      kmStats: (this.kmStats || []).map(m => m.toProtobufJSON(options)),
      hourStats: (this.hourStats || []).map(m => m.toProtobufJSON(options)),
      dayStats: (this.dayStats || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ActivityStats {
  /**
   * Standard JavaScript object representation for ActivityStats
   */
  export interface AsObject {
    totalStats?: IntervalStats.AsObject;
    mileStats?: IntervalStats.AsObject[];
    kmStats?: IntervalStats.AsObject[];
    hourStats?: IntervalStats.AsObject[];
    dayStats?: IntervalStats.AsObject[];
  }

  /**
   * Protobuf JSON representation for ActivityStats
   */
  export interface AsProtobufJSON {
    totalStats: IntervalStats.AsProtobufJSON | null;
    mileStats: IntervalStats.AsProtobufJSON[] | null;
    kmStats: IntervalStats.AsProtobufJSON[] | null;
    hourStats: IntervalStats.AsProtobufJSON[] | null;
    dayStats: IntervalStats.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for shared.IntervalStats
 */
export class IntervalStats implements GrpcMessage {
  static id = 'shared.IntervalStats';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IntervalStats();
    IntervalStats.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IntervalStats) {
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
    _instance.activityIds = _instance.activityIds || [];
    _instance.distance = _instance.distance || 0;
    _instance.elevationGain = _instance.elevationGain || 0;
    _instance.elevationDrop = _instance.elevationDrop || 0;
    _instance.movingTimeSeconds = _instance.movingTimeSeconds || 0;
    _instance.idleTimeSeconds = _instance.idleTimeSeconds || 0;
    _instance.calories = _instance.calories || 0;
    _instance.strokes = _instance.strokes || 0;
    _instance.milePace = _instance.milePace || 0;
    _instance.kmPace = _instance.kmPace || 0;
    _instance.heartRateZoneStats = _instance.heartRateZoneStats || {};
    _instance.speedStats = _instance.speedStats || undefined;
    _instance.altitudeStats = _instance.altitudeStats || undefined;
    _instance.milePaceStats = _instance.milePaceStats || undefined;
    _instance.kmPaceStats = _instance.kmPaceStats || undefined;
    _instance.heartRateStats = _instance.heartRateStats || undefined;
    _instance.gradeStats = _instance.gradeStats || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IntervalStats,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 14:
          (_instance.activityIds = _instance.activityIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        case 3:
          _instance.distance = _reader.readDouble();
          break;
        case 4:
          _instance.elevationGain = _reader.readDouble();
          break;
        case 5:
          _instance.elevationDrop = _reader.readDouble();
          break;
        case 6:
          _instance.movingTimeSeconds = _reader.readDouble();
          break;
        case 15:
          _instance.idleTimeSeconds = _reader.readDouble();
          break;
        case 7:
          _instance.calories = _reader.readInt32();
          break;
        case 16:
          _instance.strokes = _reader.readInt32();
          break;
        case 17:
          _instance.milePace = _reader.readDouble();
          break;
        case 18:
          _instance.kmPace = _reader.readDouble();
          break;
        case 19:
          const msg_19 = {} as any;
          _reader.readMessage(
            msg_19,
            IntervalStats.HeartRateZoneStatsEntry.deserializeBinaryFromReader
          );
          _instance.heartRateZoneStats = _instance.heartRateZoneStats || {};
          _instance.heartRateZoneStats[Number(msg_19.key)] = msg_19.value;
          break;
        case 8:
          _instance.speedStats = new Stats();
          _reader.readMessage(
            _instance.speedStats,
            Stats.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.altitudeStats = new Stats();
          _reader.readMessage(
            _instance.altitudeStats,
            Stats.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.milePaceStats = new Stats();
          _reader.readMessage(
            _instance.milePaceStats,
            Stats.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.kmPaceStats = new Stats();
          _reader.readMessage(
            _instance.kmPaceStats,
            Stats.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.heartRateStats = new Stats();
          _reader.readMessage(
            _instance.heartRateStats,
            Stats.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.gradeStats = new Stats();
          _reader.readMessage(
            _instance.gradeStats,
            Stats.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IntervalStats.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IntervalStats,
    _writer: BinaryWriter
  ) {
    if (_instance.startTime) {
      _writer.writeMessage(
        1,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        2,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.activityIds && _instance.activityIds.length) {
      _writer.writePackedInt64String(14, _instance.activityIds);
    }
    if (_instance.distance) {
      _writer.writeDouble(3, _instance.distance);
    }
    if (_instance.elevationGain) {
      _writer.writeDouble(4, _instance.elevationGain);
    }
    if (_instance.elevationDrop) {
      _writer.writeDouble(5, _instance.elevationDrop);
    }
    if (_instance.movingTimeSeconds) {
      _writer.writeDouble(6, _instance.movingTimeSeconds);
    }
    if (_instance.idleTimeSeconds) {
      _writer.writeDouble(15, _instance.idleTimeSeconds);
    }
    if (_instance.calories) {
      _writer.writeInt32(7, _instance.calories);
    }
    if (_instance.strokes) {
      _writer.writeInt32(16, _instance.strokes);
    }
    if (_instance.milePace) {
      _writer.writeDouble(17, _instance.milePace);
    }
    if (_instance.kmPace) {
      _writer.writeDouble(18, _instance.kmPace);
    }
    if (!!_instance.heartRateZoneStats) {
      const keys_19 = Object.keys(_instance.heartRateZoneStats as any);

      if (keys_19.length) {
        const repeated_19 = keys_19
          .map(key => ({
            key: Number(key),
            value: (_instance.heartRateZoneStats as any)[key]
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          19,
          repeated_19,
          IntervalStats.HeartRateZoneStatsEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.speedStats) {
      _writer.writeMessage(
        8,
        _instance.speedStats as any,
        Stats.serializeBinaryToWriter
      );
    }
    if (_instance.altitudeStats) {
      _writer.writeMessage(
        9,
        _instance.altitudeStats as any,
        Stats.serializeBinaryToWriter
      );
    }
    if (_instance.milePaceStats) {
      _writer.writeMessage(
        10,
        _instance.milePaceStats as any,
        Stats.serializeBinaryToWriter
      );
    }
    if (_instance.kmPaceStats) {
      _writer.writeMessage(
        11,
        _instance.kmPaceStats as any,
        Stats.serializeBinaryToWriter
      );
    }
    if (_instance.heartRateStats) {
      _writer.writeMessage(
        12,
        _instance.heartRateStats as any,
        Stats.serializeBinaryToWriter
      );
    }
    if (_instance.gradeStats) {
      _writer.writeMessage(
        13,
        _instance.gradeStats as any,
        Stats.serializeBinaryToWriter
      );
    }
  }

  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;
  private _activityIds: string[];
  private _distance: number;
  private _elevationGain: number;
  private _elevationDrop: number;
  private _movingTimeSeconds: number;
  private _idleTimeSeconds: number;
  private _calories: number;
  private _strokes: number;
  private _milePace: number;
  private _kmPace: number;
  private _heartRateZoneStats: { [prop: number]: HeartRateZoneStats };
  private _speedStats?: Stats;
  private _altitudeStats?: Stats;
  private _milePaceStats?: Stats;
  private _kmPaceStats?: Stats;
  private _heartRateStats?: Stats;
  private _gradeStats?: Stats;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IntervalStats to deeply clone from
   */
  constructor(_value?: RecursivePartial<IntervalStats.AsObject>) {
    _value = _value || {};
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    this.activityIds = (_value.activityIds || []).slice();
    this.distance = _value.distance;
    this.elevationGain = _value.elevationGain;
    this.elevationDrop = _value.elevationDrop;
    this.movingTimeSeconds = _value.movingTimeSeconds;
    this.idleTimeSeconds = _value.idleTimeSeconds;
    this.calories = _value.calories;
    this.strokes = _value.strokes;
    this.milePace = _value.milePace;
    this.kmPace = _value.kmPace;
    (this.heartRateZoneStats = _value!.heartRateZoneStats
      ? Object.keys(_value!.heartRateZoneStats).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.heartRateZoneStats![k]
              ? new HeartRateZoneStats(_value!.heartRateZoneStats![k])
              : undefined
          }),
          {}
        )
      : {}),
      (this.speedStats = _value.speedStats
        ? new Stats(_value.speedStats)
        : undefined);
    this.altitudeStats = _value.altitudeStats
      ? new Stats(_value.altitudeStats)
      : undefined;
    this.milePaceStats = _value.milePaceStats
      ? new Stats(_value.milePaceStats)
      : undefined;
    this.kmPaceStats = _value.kmPaceStats
      ? new Stats(_value.kmPaceStats)
      : undefined;
    this.heartRateStats = _value.heartRateStats
      ? new Stats(_value.heartRateStats)
      : undefined;
    this.gradeStats = _value.gradeStats
      ? new Stats(_value.gradeStats)
      : undefined;
    IntervalStats.refineValues(this);
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }
  get activityIds(): string[] {
    return this._activityIds;
  }
  set activityIds(value: string[]) {
    this._activityIds = value;
  }
  get distance(): number {
    return this._distance;
  }
  set distance(value: number) {
    this._distance = value;
  }
  get elevationGain(): number {
    return this._elevationGain;
  }
  set elevationGain(value: number) {
    this._elevationGain = value;
  }
  get elevationDrop(): number {
    return this._elevationDrop;
  }
  set elevationDrop(value: number) {
    this._elevationDrop = value;
  }
  get movingTimeSeconds(): number {
    return this._movingTimeSeconds;
  }
  set movingTimeSeconds(value: number) {
    this._movingTimeSeconds = value;
  }
  get idleTimeSeconds(): number {
    return this._idleTimeSeconds;
  }
  set idleTimeSeconds(value: number) {
    this._idleTimeSeconds = value;
  }
  get calories(): number {
    return this._calories;
  }
  set calories(value: number) {
    this._calories = value;
  }
  get strokes(): number {
    return this._strokes;
  }
  set strokes(value: number) {
    this._strokes = value;
  }
  get milePace(): number {
    return this._milePace;
  }
  set milePace(value: number) {
    this._milePace = value;
  }
  get kmPace(): number {
    return this._kmPace;
  }
  set kmPace(value: number) {
    this._kmPace = value;
  }
  get heartRateZoneStats(): { [prop: number]: HeartRateZoneStats } {
    return this._heartRateZoneStats;
  }
  set heartRateZoneStats(value: { [prop: number]: HeartRateZoneStats }) {
    this._heartRateZoneStats = value;
  }
  get speedStats(): Stats | undefined {
    return this._speedStats;
  }
  set speedStats(value: Stats | undefined) {
    this._speedStats = value;
  }
  get altitudeStats(): Stats | undefined {
    return this._altitudeStats;
  }
  set altitudeStats(value: Stats | undefined) {
    this._altitudeStats = value;
  }
  get milePaceStats(): Stats | undefined {
    return this._milePaceStats;
  }
  set milePaceStats(value: Stats | undefined) {
    this._milePaceStats = value;
  }
  get kmPaceStats(): Stats | undefined {
    return this._kmPaceStats;
  }
  set kmPaceStats(value: Stats | undefined) {
    this._kmPaceStats = value;
  }
  get heartRateStats(): Stats | undefined {
    return this._heartRateStats;
  }
  set heartRateStats(value: Stats | undefined) {
    this._heartRateStats = value;
  }
  get gradeStats(): Stats | undefined {
    return this._gradeStats;
  }
  set gradeStats(value: Stats | undefined) {
    this._gradeStats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IntervalStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IntervalStats.AsObject {
    return {
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
      activityIds: (this.activityIds || []).slice(),
      distance: this.distance,
      elevationGain: this.elevationGain,
      elevationDrop: this.elevationDrop,
      movingTimeSeconds: this.movingTimeSeconds,
      idleTimeSeconds: this.idleTimeSeconds,
      calories: this.calories,
      strokes: this.strokes,
      milePace: this.milePace,
      kmPace: this.kmPace,
      heartRateZoneStats: this.heartRateZoneStats
        ? Object.keys(this.heartRateZoneStats).reduce(
            (r, k) => ({
              ...r,
              [k]: this.heartRateZoneStats![k]
                ? this.heartRateZoneStats![k].toObject()
                : undefined
            }),
            {}
          )
        : {},
      speedStats: this.speedStats ? this.speedStats.toObject() : undefined,
      altitudeStats: this.altitudeStats
        ? this.altitudeStats.toObject()
        : undefined,
      milePaceStats: this.milePaceStats
        ? this.milePaceStats.toObject()
        : undefined,
      kmPaceStats: this.kmPaceStats ? this.kmPaceStats.toObject() : undefined,
      heartRateStats: this.heartRateStats
        ? this.heartRateStats.toObject()
        : undefined,
      gradeStats: this.gradeStats ? this.gradeStats.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IntervalStats.AsProtobufJSON {
    return {
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
      activityIds: (this.activityIds || []).slice(),
      distance: this.distance,
      elevationGain: this.elevationGain,
      elevationDrop: this.elevationDrop,
      movingTimeSeconds: this.movingTimeSeconds,
      idleTimeSeconds: this.idleTimeSeconds,
      calories: this.calories,
      strokes: this.strokes,
      milePace: this.milePace,
      kmPace: this.kmPace,
      heartRateZoneStats: this.heartRateZoneStats
        ? Object.keys(this.heartRateZoneStats).reduce(
            (r, k) => ({
              ...r,
              [k]: this.heartRateZoneStats![k]
                ? this.heartRateZoneStats![k].toJSON()
                : null
            }),
            {}
          )
        : {},
      speedStats: this.speedStats
        ? this.speedStats.toProtobufJSON(options)
        : null,
      altitudeStats: this.altitudeStats
        ? this.altitudeStats.toProtobufJSON(options)
        : null,
      milePaceStats: this.milePaceStats
        ? this.milePaceStats.toProtobufJSON(options)
        : null,
      kmPaceStats: this.kmPaceStats
        ? this.kmPaceStats.toProtobufJSON(options)
        : null,
      heartRateStats: this.heartRateStats
        ? this.heartRateStats.toProtobufJSON(options)
        : null,
      gradeStats: this.gradeStats
        ? this.gradeStats.toProtobufJSON(options)
        : null
    };
  }
}
export module IntervalStats {
  /**
   * Standard JavaScript object representation for IntervalStats
   */
  export interface AsObject {
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
    activityIds: string[];
    distance: number;
    elevationGain: number;
    elevationDrop: number;
    movingTimeSeconds: number;
    idleTimeSeconds: number;
    calories: number;
    strokes: number;
    milePace: number;
    kmPace: number;
    heartRateZoneStats: { [prop: number]: HeartRateZoneStats };
    speedStats?: Stats.AsObject;
    altitudeStats?: Stats.AsObject;
    milePaceStats?: Stats.AsObject;
    kmPaceStats?: Stats.AsObject;
    heartRateStats?: Stats.AsObject;
    gradeStats?: Stats.AsObject;
  }

  /**
   * Protobuf JSON representation for IntervalStats
   */
  export interface AsProtobufJSON {
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    activityIds: string[];
    distance: number;
    elevationGain: number;
    elevationDrop: number;
    movingTimeSeconds: number;
    idleTimeSeconds: number;
    calories: number;
    strokes: number;
    milePace: number;
    kmPace: number;
    heartRateZoneStats: { [prop: number]: HeartRateZoneStats };
    speedStats: Stats.AsProtobufJSON | null;
    altitudeStats: Stats.AsProtobufJSON | null;
    milePaceStats: Stats.AsProtobufJSON | null;
    kmPaceStats: Stats.AsProtobufJSON | null;
    heartRateStats: Stats.AsProtobufJSON | null;
    gradeStats: Stats.AsProtobufJSON | null;
  }

  /**
   * Message implementation for shared.IntervalStats.HeartRateZoneStatsEntry
   */
  export class HeartRateZoneStatsEntry implements GrpcMessage {
    static id = 'shared.IntervalStats.HeartRateZoneStatsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new HeartRateZoneStatsEntry();
      HeartRateZoneStatsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: HeartRateZoneStatsEntry) {
      _instance.key = _instance.key || 0;
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: HeartRateZoneStatsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readInt32();
            break;
          case 2:
            _instance.value = new HeartRateZoneStats();
            _reader.readMessage(
              _instance.value,
              HeartRateZoneStats.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      HeartRateZoneStatsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: HeartRateZoneStatsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeInt32(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          HeartRateZoneStats.serializeBinaryToWriter
        );
      }
    }

    private _key: number;
    private _value?: HeartRateZoneStats;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of HeartRateZoneStatsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<HeartRateZoneStatsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value
        ? new HeartRateZoneStats(_value.value)
        : undefined;
      HeartRateZoneStatsEntry.refineValues(this);
    }
    get key(): number {
      return this._key;
    }
    set key(value: number) {
      this._key = value;
    }
    get value(): HeartRateZoneStats | undefined {
      return this._value;
    }
    set value(value: HeartRateZoneStats | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      HeartRateZoneStatsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): HeartRateZoneStatsEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): HeartRateZoneStatsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null
      };
    }
  }
  export module HeartRateZoneStatsEntry {
    /**
     * Standard JavaScript object representation for HeartRateZoneStatsEntry
     */
    export interface AsObject {
      key: number;
      value?: HeartRateZoneStats.AsObject;
    }

    /**
     * Protobuf JSON representation for HeartRateZoneStatsEntry
     */
    export interface AsProtobufJSON {
      key: number;
      value: HeartRateZoneStats.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for shared.HeartRateZoneStats
 */
export class HeartRateZoneStats implements GrpcMessage {
  static id = 'shared.HeartRateZoneStats';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new HeartRateZoneStats();
    HeartRateZoneStats.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: HeartRateZoneStats) {
    _instance.secondsInZone = _instance.secondsInZone || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: HeartRateZoneStats,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.secondsInZone = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    HeartRateZoneStats.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: HeartRateZoneStats,
    _writer: BinaryWriter
  ) {
    if (_instance.secondsInZone) {
      _writer.writeInt64String(1, _instance.secondsInZone);
    }
  }

  private _secondsInZone: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of HeartRateZoneStats to deeply clone from
   */
  constructor(_value?: RecursivePartial<HeartRateZoneStats.AsObject>) {
    _value = _value || {};
    this.secondsInZone = _value.secondsInZone;
    HeartRateZoneStats.refineValues(this);
  }
  get secondsInZone(): string {
    return this._secondsInZone;
  }
  set secondsInZone(value: string) {
    this._secondsInZone = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    HeartRateZoneStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): HeartRateZoneStats.AsObject {
    return {
      secondsInZone: this.secondsInZone
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): HeartRateZoneStats.AsProtobufJSON {
    return {
      secondsInZone: this.secondsInZone
    };
  }
}
export module HeartRateZoneStats {
  /**
   * Standard JavaScript object representation for HeartRateZoneStats
   */
  export interface AsObject {
    secondsInZone: string;
  }

  /**
   * Protobuf JSON representation for HeartRateZoneStats
   */
  export interface AsProtobufJSON {
    secondsInZone: string;
  }
}

/**
 * Message implementation for shared.Stats
 */
export class Stats implements GrpcMessage {
  static id = 'shared.Stats';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Stats();
    Stats.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Stats) {
    _instance.mean = _instance.mean || 0;
    _instance.min = _instance.min || 0;
    _instance.max = _instance.max || 0;
    _instance.percentiles = _instance.percentiles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Stats, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.mean = _reader.readDouble();
          break;
        case 2:
          _instance.min = _reader.readDouble();
          break;
        case 3:
          _instance.max = _reader.readDouble();
          break;
        case 4:
          const messageInitializer4 = new Percentile();
          _reader.readMessage(
            messageInitializer4,
            Percentile.deserializeBinaryFromReader
          );
          (_instance.percentiles = _instance.percentiles || []).push(
            messageInitializer4
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Stats.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Stats, _writer: BinaryWriter) {
    if (_instance.mean) {
      _writer.writeDouble(1, _instance.mean);
    }
    if (_instance.min) {
      _writer.writeDouble(2, _instance.min);
    }
    if (_instance.max) {
      _writer.writeDouble(3, _instance.max);
    }
    if (_instance.percentiles && _instance.percentiles.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.percentiles as any,
        Percentile.serializeBinaryToWriter
      );
    }
  }

  private _mean: number;
  private _min: number;
  private _max: number;
  private _percentiles?: Percentile[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Stats to deeply clone from
   */
  constructor(_value?: RecursivePartial<Stats.AsObject>) {
    _value = _value || {};
    this.mean = _value.mean;
    this.min = _value.min;
    this.max = _value.max;
    this.percentiles = (_value.percentiles || []).map(m => new Percentile(m));
    Stats.refineValues(this);
  }
  get mean(): number {
    return this._mean;
  }
  set mean(value: number) {
    this._mean = value;
  }
  get min(): number {
    return this._min;
  }
  set min(value: number) {
    this._min = value;
  }
  get max(): number {
    return this._max;
  }
  set max(value: number) {
    this._max = value;
  }
  get percentiles(): Percentile[] | undefined {
    return this._percentiles;
  }
  set percentiles(value: Percentile[] | undefined) {
    this._percentiles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Stats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Stats.AsObject {
    return {
      mean: this.mean,
      min: this.min,
      max: this.max,
      percentiles: (this.percentiles || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Stats.AsProtobufJSON {
    return {
      mean: this.mean,
      min: this.min,
      max: this.max,
      percentiles: (this.percentiles || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module Stats {
  /**
   * Standard JavaScript object representation for Stats
   */
  export interface AsObject {
    mean: number;
    min: number;
    max: number;
    percentiles?: Percentile.AsObject[];
  }

  /**
   * Protobuf JSON representation for Stats
   */
  export interface AsProtobufJSON {
    mean: number;
    min: number;
    max: number;
    percentiles: Percentile.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for shared.Percentile
 */
export class Percentile implements GrpcMessage {
  static id = 'shared.Percentile';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Percentile();
    Percentile.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Percentile) {
    _instance.percent = _instance.percent || 0;
    _instance.value = _instance.value || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Percentile,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.percent = _reader.readInt32();
          break;
        case 2:
          _instance.value = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    Percentile.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Percentile, _writer: BinaryWriter) {
    if (_instance.percent) {
      _writer.writeInt32(1, _instance.percent);
    }
    if (_instance.value) {
      _writer.writeDouble(2, _instance.value);
    }
  }

  private _percent: number;
  private _value: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Percentile to deeply clone from
   */
  constructor(_value?: RecursivePartial<Percentile.AsObject>) {
    _value = _value || {};
    this.percent = _value.percent;
    this.value = _value.value;
    Percentile.refineValues(this);
  }
  get percent(): number {
    return this._percent;
  }
  set percent(value: number) {
    this._percent = value;
  }
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Percentile.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Percentile.AsObject {
    return {
      percent: this.percent,
      value: this.value
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Percentile.AsProtobufJSON {
    return {
      percent: this.percent,
      value: this.value
    };
  }
}
export module Percentile {
  /**
   * Standard JavaScript object representation for Percentile
   */
  export interface AsObject {
    percent: number;
    value: number;
  }

  /**
   * Protobuf JSON representation for Percentile
   */
  export interface AsProtobufJSON {
    percent: number;
    value: number;
  }
}
