/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum IssueType {
  ISSUE_TYPE_UNSPECIFIED = 0,
  ISSUE_TYPE_MISCONDUCT = 1,
  ISSUE_TYPE_BUG = 2,
  ISSUE_TYPE_FEATURE_REQUEST = 3
}
export enum IssueState {
  ISSUE_STATE_UNSPECIFIED = 0,
  ISSUE_STATE_CREATED = 1,
  ISSUE_STATE_IN_PROGRESS = 2,
  ISSUE_STATE_RESOLVED = 3
}
/**
 * Message implementation for shared.IssueActivityContext
 */
export class IssueActivityContext implements GrpcMessage {
  static id = 'shared.IssueActivityContext';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IssueActivityContext();
    IssueActivityContext.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IssueActivityContext) {
    _instance.activityId = _instance.activityId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IssueActivityContext,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    IssueActivityContext.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IssueActivityContext,
    _writer: BinaryWriter
  ) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
  }

  private _activityId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IssueActivityContext to deeply clone from
   */
  constructor(_value?: RecursivePartial<IssueActivityContext.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    IssueActivityContext.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IssueActivityContext.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IssueActivityContext.AsObject {
    return {
      activityId: this.activityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IssueActivityContext.AsProtobufJSON {
    return {
      activityId: this.activityId
    };
  }
}
export module IssueActivityContext {
  /**
   * Standard JavaScript object representation for IssueActivityContext
   */
  export interface AsObject {
    activityId: string;
  }

  /**
   * Protobuf JSON representation for IssueActivityContext
   */
  export interface AsProtobufJSON {
    activityId: string;
  }
}

/**
 * Message implementation for shared.IssueComment
 */
export class IssueComment implements GrpcMessage {
  static id = 'shared.IssueComment';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IssueComment();
    IssueComment.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IssueComment) {
    _instance.userAlias = _instance.userAlias || '';
    _instance.comment = _instance.comment || '';
    _instance.time = _instance.time || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IssueComment,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userAlias = _reader.readString();
          break;
        case 2:
          _instance.comment = _reader.readString();
          break;
        case 3:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IssueComment.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IssueComment,
    _writer: BinaryWriter
  ) {
    if (_instance.userAlias) {
      _writer.writeString(1, _instance.userAlias);
    }
    if (_instance.comment) {
      _writer.writeString(2, _instance.comment);
    }
    if (_instance.time) {
      _writer.writeMessage(
        3,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _userAlias: string;
  private _comment: string;
  private _time?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IssueComment to deeply clone from
   */
  constructor(_value?: RecursivePartial<IssueComment.AsObject>) {
    _value = _value || {};
    this.userAlias = _value.userAlias;
    this.comment = _value.comment;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    IssueComment.refineValues(this);
  }
  get userAlias(): string {
    return this._userAlias;
  }
  set userAlias(value: string) {
    this._userAlias = value;
  }
  get comment(): string {
    return this._comment;
  }
  set comment(value: string) {
    this._comment = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IssueComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IssueComment.AsObject {
    return {
      userAlias: this.userAlias,
      comment: this.comment,
      time: this.time ? this.time.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IssueComment.AsProtobufJSON {
    return {
      userAlias: this.userAlias,
      comment: this.comment,
      time: this.time ? this.time.toProtobufJSON(options) : null
    };
  }
}
export module IssueComment {
  /**
   * Standard JavaScript object representation for IssueComment
   */
  export interface AsObject {
    userAlias: string;
    comment: string;
    time?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for IssueComment
   */
  export interface AsProtobufJSON {
    userAlias: string;
    comment: string;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.IssueResolution
 */
export class IssueResolution implements GrpcMessage {
  static id = 'shared.IssueResolution';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IssueResolution();
    IssueResolution.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IssueResolution) {
    _instance.type = _instance.type || 0;
    _instance.comment = _instance.comment || '';
    _instance.removedContents = _instance.removedContents || undefined;
    _instance.time = _instance.time || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IssueResolution,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.comment = _reader.readString();
          break;
        case 3:
          _instance.removedContents = new RemovedContent();
          _reader.readMessage(
            _instance.removedContents,
            RemovedContent.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IssueResolution.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IssueResolution,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.comment) {
      _writer.writeString(2, _instance.comment);
    }
    if (_instance.removedContents) {
      _writer.writeMessage(
        3,
        _instance.removedContents as any,
        RemovedContent.serializeBinaryToWriter
      );
    }
    if (_instance.time) {
      _writer.writeMessage(
        4,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _type: IssueResolution.IssueResolutionType;
  private _comment: string;
  private _removedContents?: RemovedContent;
  private _time?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IssueResolution to deeply clone from
   */
  constructor(_value?: RecursivePartial<IssueResolution.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.comment = _value.comment;
    this.removedContents = _value.removedContents
      ? new RemovedContent(_value.removedContents)
      : undefined;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    IssueResolution.refineValues(this);
  }
  get type(): IssueResolution.IssueResolutionType {
    return this._type;
  }
  set type(value: IssueResolution.IssueResolutionType) {
    this._type = value;
  }
  get comment(): string {
    return this._comment;
  }
  set comment(value: string) {
    this._comment = value;
  }
  get removedContents(): RemovedContent | undefined {
    return this._removedContents;
  }
  set removedContents(value: RemovedContent | undefined) {
    this._removedContents = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IssueResolution.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IssueResolution.AsObject {
    return {
      type: this.type,
      comment: this.comment,
      removedContents: this.removedContents
        ? this.removedContents.toObject()
        : undefined,
      time: this.time ? this.time.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IssueResolution.AsProtobufJSON {
    return {
      type:
        IssueResolution.IssueResolutionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      comment: this.comment,
      removedContents: this.removedContents
        ? this.removedContents.toProtobufJSON(options)
        : null,
      time: this.time ? this.time.toProtobufJSON(options) : null
    };
  }
}
export module IssueResolution {
  /**
   * Standard JavaScript object representation for IssueResolution
   */
  export interface AsObject {
    type: IssueResolution.IssueResolutionType;
    comment: string;
    removedContents?: RemovedContent.AsObject;
    time?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for IssueResolution
   */
  export interface AsProtobufJSON {
    type: string;
    comment: string;
    removedContents: RemovedContent.AsProtobufJSON | null;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
  export enum IssueResolutionType {
    ISSUE_RESOLUTION_TYPE_UNSPECIFIED = 0,
    ISSUE_RESOLUTION_TYPE_NOT_ISSUE = 1,
    ISSUE_RESOLUTION_TYPE_CONTENT_REMOVED = 2,
    ISSUE_RESOLUTION_TYPE_ACTIVITY_REMOVED = 3,
    ISSUE_RESOLUTION_TYPE_COMMENT_REMOVED = 4,
    ISSUE_RESOLUTION_TYPE_USER_REMOVED = 5,
    ISSUE_RESOLUTION_TYPE_BUG_FIXED = 6,
    ISSUE_RESOLUTION_TYPE_FEATURE_REQUEST_REJECTED = 7,
    ISSUE_RESOLUTION_TYPE_FEATURE_REQUEST_ACCEPTED = 8
  }
}

/**
 * Message implementation for shared.RemovedContent
 */
export class RemovedContent implements GrpcMessage {
  static id = 'shared.RemovedContent';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemovedContent();
    RemovedContent.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemovedContent) {
    _instance.removedComments = _instance.removedComments || [];
    _instance.removedMediaIds = _instance.removedMediaIds || [];
    _instance.removedActivityIds = _instance.removedActivityIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemovedContent,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.removedComments = _instance.removedComments || []).push(
            _reader.readString()
          );
          break;
        case 2:
          (_instance.removedMediaIds = _instance.removedMediaIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        case 3:
          (_instance.removedActivityIds =
            _instance.removedActivityIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RemovedContent.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemovedContent,
    _writer: BinaryWriter
  ) {
    if (_instance.removedComments && _instance.removedComments.length) {
      _writer.writeRepeatedString(1, _instance.removedComments);
    }
    if (_instance.removedMediaIds && _instance.removedMediaIds.length) {
      _writer.writePackedInt64String(2, _instance.removedMediaIds);
    }
    if (_instance.removedActivityIds && _instance.removedActivityIds.length) {
      _writer.writePackedInt64String(3, _instance.removedActivityIds);
    }
  }

  private _removedComments: string[];
  private _removedMediaIds: string[];
  private _removedActivityIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemovedContent to deeply clone from
   */
  constructor(_value?: RecursivePartial<RemovedContent.AsObject>) {
    _value = _value || {};
    this.removedComments = (_value.removedComments || []).slice();
    this.removedMediaIds = (_value.removedMediaIds || []).slice();
    this.removedActivityIds = (_value.removedActivityIds || []).slice();
    RemovedContent.refineValues(this);
  }
  get removedComments(): string[] {
    return this._removedComments;
  }
  set removedComments(value: string[]) {
    this._removedComments = value;
  }
  get removedMediaIds(): string[] {
    return this._removedMediaIds;
  }
  set removedMediaIds(value: string[]) {
    this._removedMediaIds = value;
  }
  get removedActivityIds(): string[] {
    return this._removedActivityIds;
  }
  set removedActivityIds(value: string[]) {
    this._removedActivityIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemovedContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemovedContent.AsObject {
    return {
      removedComments: (this.removedComments || []).slice(),
      removedMediaIds: (this.removedMediaIds || []).slice(),
      removedActivityIds: (this.removedActivityIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemovedContent.AsProtobufJSON {
    return {
      removedComments: (this.removedComments || []).slice(),
      removedMediaIds: (this.removedMediaIds || []).slice(),
      removedActivityIds: (this.removedActivityIds || []).slice()
    };
  }
}
export module RemovedContent {
  /**
   * Standard JavaScript object representation for RemovedContent
   */
  export interface AsObject {
    removedComments: string[];
    removedMediaIds: string[];
    removedActivityIds: string[];
  }

  /**
   * Protobuf JSON representation for RemovedContent
   */
  export interface AsProtobufJSON {
    removedComments: string[];
    removedMediaIds: string[];
    removedActivityIds: string[];
  }
}
