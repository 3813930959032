import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivityDetailComponent } from './activity-detail/activity-detail.component';
import { CalComponent } from './calendar/cal.component';
import { DocsComponent } from './docs/docs.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PeopleComponent } from './people/people.component';
import { ProfileComponent } from './profile/profile.component';
import { ReactionDetailsComponent } from './reaction-details/reaction-details.component';
import { FitbitRedirectComponent } from './redirects/fitbit-redirect/fitbit-redirect.component';
import { GarminRedirectComponent } from './redirects/garmin-redirect/garmin-redirect.component';
import { StatsComponent } from './stats/stats.component';

const routes: Routes = [
  {
    path: 'activities/:alias',
    component: ActivitiesComponent,
    title: 'Jestle - Activities',
    children: [],
  },
  {
    path: 'activities',
    component: ActivitiesComponent,
    title: 'Jestle - Activities',
    children: [],
  },
  {
    path: 'activity/:id',
    component: ActivityDetailComponent,
    title: 'Jestle - Activity',
  },
  {
    path: 'calendar/:alias',
    component: CalComponent,
    title: 'Jestle - Calendar',
    children: [],
  },
  {
    path: 'calendar',
    component: CalComponent,
    title: 'Jestle - Calendar',
    children: [],
  },
  {
    matcher: (url) => {
      if (url.length >= 1 && url[0].path == 'docs') {
        return {
          consumed: url,
        };
      }
      return null;
    },
    component: DocsComponent,
    title: 'Jestle - Docs',
    children: [],
  },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Jestle - Home',
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [],
  },
  {
    path: 'people',
    component: PeopleComponent,
    title: 'Jestle - People',
  },
  {
    path: 'profile',
    component: ProfileComponent,
    title: 'Jestle - Profile',
    children: [],
  },
  {
    path: 'reactions/:id',
    component: ReactionDetailsComponent,
    title: 'Jestle - Activity Reactions',
  },
  {
    path: 'stats/:alias',
    component: StatsComponent,
    title: 'Jestle - Stats',
  },
  {
    path: 'redirects/fitbit',
    component: FitbitRedirectComponent,
    title: 'Jestle - Fitbit Redirect',
  },
  {
    path: 'redirects/garmin',
    component: GarminRedirectComponent,
    title: 'Jestle - Garmin Redirect',
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: 'Jestle',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
