/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './notification-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/pubsub/notification.pb';
import * as shared002 from '../../../../src/main/proto/shared/notification-shared.pb';
import { GRPC_NOTIFICATION_SERVICE_CLIENT_SETTINGS } from './notification-service.pbconf';
/**
 * Service client implementation for api.notification.NotificationService
 */
@Injectable({ providedIn: 'any' })
export class NotificationServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.notification.NotificationService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetResponse>>
     */
    get: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.notification.NotificationService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetResponse
      });
    },
    /**
     * Unary call: /api.notification.NotificationService/SetSeen
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SetSeenResponse>>
     */
    setSeen: (
      requestData: thisProto.SetSeenRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SetSeenResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.notification.NotificationService/SetSeen',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetSeenRequest,
        responseClass: thisProto.SetSeenResponse
      });
    },
    /**
     * Unary call: /api.notification.NotificationService/GetControls
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetControlsResponse>>
     */
    getControls: (
      requestData: thisProto.GetControlsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetControlsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.notification.NotificationService/GetControls',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetControlsRequest,
        responseClass: thisProto.GetControlsResponse
      });
    },
    /**
     * Unary call: /api.notification.NotificationService/UpdateControls
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateControlsResponse>>
     */
    updateControls: (
      requestData: thisProto.UpdateControlsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateControlsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.notification.NotificationService/UpdateControls',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateControlsRequest,
        responseClass: thisProto.UpdateControlsResponse
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_NOTIFICATION_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.notification.NotificationService',
      settings
    );
  }

  /**
   * Unary call @/api.notification.NotificationService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetResponse>
   */
  get(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.notification.NotificationService/SetSeen
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SetSeenResponse>
   */
  setSeen(
    requestData: thisProto.SetSeenRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SetSeenResponse> {
    return this.$raw
      .setSeen(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.notification.NotificationService/GetControls
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetControlsResponse>
   */
  getControls(
    requestData: thisProto.GetControlsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetControlsResponse> {
    return this.$raw
      .getControls(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.notification.NotificationService/UpdateControls
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateControlsResponse>
   */
  updateControls(
    requestData: thisProto.UpdateControlsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateControlsResponse> {
    return this.$raw
      .updateControls(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
