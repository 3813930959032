/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum FollowStatus {
  FOLLOW_STATUS_UNSPECIFIED = 0,
  FOLLOW_STATUS_ALREADY_FOLLOWING = 1,
  FOLLOW_STATUS_REQUESTED = 2,
  FOLLOW_STATUS_REQUEST_REJECTED = 3
}
/**
 * Message implementation for api.follow.FindToFollowRequest
 */
export class FindToFollowRequest implements GrpcMessage {
  static id = 'api.follow.FindToFollowRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FindToFollowRequest();
    FindToFollowRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FindToFollowRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startIndex = _instance.startIndex || 0;
    _instance.count = _instance.count || 0;
    _instance.queryText = _instance.queryText || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FindToFollowRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startIndex = _reader.readInt32();
          break;
        case 3:
          _instance.count = _reader.readInt32();
          break;
        case 4:
          _instance.queryText = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    FindToFollowRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FindToFollowRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startIndex) {
      _writer.writeInt32(2, _instance.startIndex);
    }
    if (_instance.count) {
      _writer.writeInt32(3, _instance.count);
    }
    if (_instance.queryText) {
      _writer.writeString(4, _instance.queryText);
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startIndex: number;
  private _count: number;
  private _queryText: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FindToFollowRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<FindToFollowRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startIndex = _value.startIndex;
    this.count = _value.count;
    this.queryText = _value.queryText;
    FindToFollowRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startIndex(): number {
    return this._startIndex;
  }
  set startIndex(value: number) {
    this._startIndex = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }
  get queryText(): string {
    return this._queryText;
  }
  set queryText(value: string) {
    this._queryText = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FindToFollowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FindToFollowRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startIndex: this.startIndex,
      count: this.count,
      queryText: this.queryText
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FindToFollowRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startIndex: this.startIndex,
      count: this.count,
      queryText: this.queryText
    };
  }
}
export module FindToFollowRequest {
  /**
   * Standard JavaScript object representation for FindToFollowRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startIndex: number;
    count: number;
    queryText: string;
  }

  /**
   * Protobuf JSON representation for FindToFollowRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startIndex: number;
    count: number;
    queryText: string;
  }
}

/**
 * Message implementation for api.follow.FindToFollowResponse
 */
export class FindToFollowResponse implements GrpcMessage {
  static id = 'api.follow.FindToFollowResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FindToFollowResponse();
    FindToFollowResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FindToFollowResponse) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.candidates = _instance.candidates || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FindToFollowResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new ToFollowCandidate();
          _reader.readMessage(
            messageInitializer2,
            ToFollowCandidate.deserializeBinaryFromReader
          );
          (_instance.candidates = _instance.candidates || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FindToFollowResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FindToFollowResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.candidates && _instance.candidates.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.candidates as any,
        ToFollowCandidate.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _candidates?: ToFollowCandidate[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FindToFollowResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<FindToFollowResponse.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.candidates = (_value.candidates || []).map(
      m => new ToFollowCandidate(m)
    );
    FindToFollowResponse.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get candidates(): ToFollowCandidate[] | undefined {
    return this._candidates;
  }
  set candidates(value: ToFollowCandidate[] | undefined) {
    this._candidates = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FindToFollowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FindToFollowResponse.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      candidates: (this.candidates || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FindToFollowResponse.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      candidates: (this.candidates || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module FindToFollowResponse {
  /**
   * Standard JavaScript object representation for FindToFollowResponse
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    candidates?: ToFollowCandidate.AsObject[];
  }

  /**
   * Protobuf JSON representation for FindToFollowResponse
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    candidates: ToFollowCandidate.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.follow.ToFollowCandidate
 */
export class ToFollowCandidate implements GrpcMessage {
  static id = 'api.follow.ToFollowCandidate';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ToFollowCandidate();
    ToFollowCandidate.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ToFollowCandidate) {
    _instance.alias = _instance.alias || '';
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
    _instance.firstLocationName = _instance.firstLocationName || '';
    _instance.followStatus = _instance.followStatus || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ToFollowCandidate,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.firstName = _reader.readString();
          break;
        case 3:
          _instance.lastName = _reader.readString();
          break;
        case 4:
          _instance.firstLocationName = _reader.readString();
          break;
        case 5:
          _instance.followStatus = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ToFollowCandidate.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ToFollowCandidate,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.firstName) {
      _writer.writeString(2, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(3, _instance.lastName);
    }
    if (_instance.firstLocationName) {
      _writer.writeString(4, _instance.firstLocationName);
    }
    if (_instance.followStatus) {
      _writer.writeEnum(5, _instance.followStatus);
    }
  }

  private _alias: string;
  private _firstName: string;
  private _lastName: string;
  private _firstLocationName: string;
  private _followStatus: FollowStatus;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ToFollowCandidate to deeply clone from
   */
  constructor(_value?: RecursivePartial<ToFollowCandidate.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    this.firstLocationName = _value.firstLocationName;
    this.followStatus = _value.followStatus;
    ToFollowCandidate.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }
  get firstLocationName(): string {
    return this._firstLocationName;
  }
  set firstLocationName(value: string) {
    this._firstLocationName = value;
  }
  get followStatus(): FollowStatus {
    return this._followStatus;
  }
  set followStatus(value: FollowStatus) {
    this._followStatus = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ToFollowCandidate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ToFollowCandidate.AsObject {
    return {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName,
      firstLocationName: this.firstLocationName,
      followStatus: this.followStatus
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ToFollowCandidate.AsProtobufJSON {
    return {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName,
      firstLocationName: this.firstLocationName,
      followStatus:
        FollowStatus[
          this.followStatus === null || this.followStatus === undefined
            ? 0
            : this.followStatus
        ]
    };
  }
}
export module ToFollowCandidate {
  /**
   * Standard JavaScript object representation for ToFollowCandidate
   */
  export interface AsObject {
    alias: string;
    firstName: string;
    lastName: string;
    firstLocationName: string;
    followStatus: FollowStatus;
  }

  /**
   * Protobuf JSON representation for ToFollowCandidate
   */
  export interface AsProtobufJSON {
    alias: string;
    firstName: string;
    lastName: string;
    firstLocationName: string;
    followStatus: string;
  }
}

/**
 * Message implementation for api.follow.RequestToFollowRequest
 */
export class RequestToFollowRequest implements GrpcMessage {
  static id = 'api.follow.RequestToFollowRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestToFollowRequest();
    RequestToFollowRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestToFollowRequest) {
    _instance.alias = _instance.alias || '';
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestToFollowRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RequestToFollowRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestToFollowRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _alias: string;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestToFollowRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestToFollowRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.message = _value.message;
    RequestToFollowRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestToFollowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestToFollowRequest.AsObject {
    return {
      alias: this.alias,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestToFollowRequest.AsProtobufJSON {
    return {
      alias: this.alias,
      message: this.message
    };
  }
}
export module RequestToFollowRequest {
  /**
   * Standard JavaScript object representation for RequestToFollowRequest
   */
  export interface AsObject {
    alias: string;
    message: string;
  }

  /**
   * Protobuf JSON representation for RequestToFollowRequest
   */
  export interface AsProtobufJSON {
    alias: string;
    message: string;
  }
}

/**
 * Message implementation for api.follow.RequestToFollowResponse
 */
export class RequestToFollowResponse implements GrpcMessage {
  static id = 'api.follow.RequestToFollowResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestToFollowResponse();
    RequestToFollowResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestToFollowResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestToFollowResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    RequestToFollowResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestToFollowResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestToFollowResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestToFollowResponse.AsObject>) {
    _value = _value || {};
    RequestToFollowResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestToFollowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestToFollowResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestToFollowResponse.AsProtobufJSON {
    return {};
  }
}
export module RequestToFollowResponse {
  /**
   * Standard JavaScript object representation for RequestToFollowResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for RequestToFollowResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.follow.GetFollowRequestsRequest
 */
export class GetFollowRequestsRequest implements GrpcMessage {
  static id = 'api.follow.GetFollowRequestsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFollowRequestsRequest();
    GetFollowRequestsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFollowRequestsRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startIndex = _instance.startIndex || 0;
    _instance.count = _instance.count || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFollowRequestsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startIndex = _reader.readInt32();
          break;
        case 3:
          _instance.count = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetFollowRequestsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFollowRequestsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startIndex) {
      _writer.writeInt32(2, _instance.startIndex);
    }
    if (_instance.count) {
      _writer.writeInt32(3, _instance.count);
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startIndex: number;
  private _count: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFollowRequestsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFollowRequestsRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startIndex = _value.startIndex;
    this.count = _value.count;
    GetFollowRequestsRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startIndex(): number {
    return this._startIndex;
  }
  set startIndex(value: number) {
    this._startIndex = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFollowRequestsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFollowRequestsRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startIndex: this.startIndex,
      count: this.count
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFollowRequestsRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startIndex: this.startIndex,
      count: this.count
    };
  }
}
export module GetFollowRequestsRequest {
  /**
   * Standard JavaScript object representation for GetFollowRequestsRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startIndex: number;
    count: number;
  }

  /**
   * Protobuf JSON representation for GetFollowRequestsRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startIndex: number;
    count: number;
  }
}

/**
 * Message implementation for api.follow.GetFollowRequestsResponse
 */
export class GetFollowRequestsResponse implements GrpcMessage {
  static id = 'api.follow.GetFollowRequestsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFollowRequestsResponse();
    GetFollowRequestsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFollowRequestsResponse) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.requests = _instance.requests || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFollowRequestsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new FollowRequest();
          _reader.readMessage(
            messageInitializer2,
            FollowRequest.deserializeBinaryFromReader
          );
          (_instance.requests = _instance.requests || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetFollowRequestsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFollowRequestsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.requests && _instance.requests.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.requests as any,
        FollowRequest.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _requests?: FollowRequest[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFollowRequestsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFollowRequestsResponse.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.requests = (_value.requests || []).map(m => new FollowRequest(m));
    GetFollowRequestsResponse.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get requests(): FollowRequest[] | undefined {
    return this._requests;
  }
  set requests(value: FollowRequest[] | undefined) {
    this._requests = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFollowRequestsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFollowRequestsResponse.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      requests: (this.requests || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFollowRequestsResponse.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      requests: (this.requests || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetFollowRequestsResponse {
  /**
   * Standard JavaScript object representation for GetFollowRequestsResponse
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    requests?: FollowRequest.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetFollowRequestsResponse
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    requests: FollowRequest.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.follow.FollowRequest
 */
export class FollowRequest implements GrpcMessage {
  static id = 'api.follow.FollowRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FollowRequest();
    FollowRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FollowRequest) {
    _instance.alias = _instance.alias || '';
    _instance.message = _instance.message || '';
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
    _instance.requestTime = _instance.requestTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FollowRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.firstName = _reader.readString();
          break;
        case 4:
          _instance.lastName = _reader.readString();
          break;
        case 5:
          _instance.requestTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.requestTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FollowRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FollowRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.firstName) {
      _writer.writeString(3, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(4, _instance.lastName);
    }
    if (_instance.requestTime) {
      _writer.writeMessage(
        5,
        _instance.requestTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _alias: string;
  private _message: string;
  private _firstName: string;
  private _lastName: string;
  private _requestTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FollowRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<FollowRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.message = _value.message;
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    this.requestTime = _value.requestTime
      ? new googleProtobuf000.Timestamp(_value.requestTime)
      : undefined;
    FollowRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }
  get requestTime(): googleProtobuf000.Timestamp | undefined {
    return this._requestTime;
  }
  set requestTime(value: googleProtobuf000.Timestamp | undefined) {
    this._requestTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FollowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FollowRequest.AsObject {
    return {
      alias: this.alias,
      message: this.message,
      firstName: this.firstName,
      lastName: this.lastName,
      requestTime: this.requestTime ? this.requestTime.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FollowRequest.AsProtobufJSON {
    return {
      alias: this.alias,
      message: this.message,
      firstName: this.firstName,
      lastName: this.lastName,
      requestTime: this.requestTime
        ? this.requestTime.toProtobufJSON(options)
        : null
    };
  }
}
export module FollowRequest {
  /**
   * Standard JavaScript object representation for FollowRequest
   */
  export interface AsObject {
    alias: string;
    message: string;
    firstName: string;
    lastName: string;
    requestTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for FollowRequest
   */
  export interface AsProtobufJSON {
    alias: string;
    message: string;
    firstName: string;
    lastName: string;
    requestTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.follow.GetFollowersRequest
 */
export class GetFollowersRequest implements GrpcMessage {
  static id = 'api.follow.GetFollowersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFollowersRequest();
    GetFollowersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFollowersRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startIndex = _instance.startIndex || 0;
    _instance.count = _instance.count || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFollowersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startIndex = _reader.readInt32();
          break;
        case 3:
          _instance.count = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetFollowersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFollowersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startIndex) {
      _writer.writeInt32(2, _instance.startIndex);
    }
    if (_instance.count) {
      _writer.writeInt32(3, _instance.count);
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startIndex: number;
  private _count: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFollowersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFollowersRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startIndex = _value.startIndex;
    this.count = _value.count;
    GetFollowersRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startIndex(): number {
    return this._startIndex;
  }
  set startIndex(value: number) {
    this._startIndex = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFollowersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFollowersRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startIndex: this.startIndex,
      count: this.count
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFollowersRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startIndex: this.startIndex,
      count: this.count
    };
  }
}
export module GetFollowersRequest {
  /**
   * Standard JavaScript object representation for GetFollowersRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startIndex: number;
    count: number;
  }

  /**
   * Protobuf JSON representation for GetFollowersRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startIndex: number;
    count: number;
  }
}

/**
 * Message implementation for api.follow.GetFollowersResponse
 */
export class GetFollowersResponse implements GrpcMessage {
  static id = 'api.follow.GetFollowersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFollowersResponse();
    GetFollowersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFollowersResponse) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.followers = _instance.followers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFollowersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new Follower();
          _reader.readMessage(
            messageInitializer2,
            Follower.deserializeBinaryFromReader
          );
          (_instance.followers = _instance.followers || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetFollowersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFollowersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.followers && _instance.followers.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.followers as any,
        Follower.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _followers?: Follower[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFollowersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFollowersResponse.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.followers = (_value.followers || []).map(m => new Follower(m));
    GetFollowersResponse.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get followers(): Follower[] | undefined {
    return this._followers;
  }
  set followers(value: Follower[] | undefined) {
    this._followers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFollowersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFollowersResponse.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      followers: (this.followers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFollowersResponse.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      followers: (this.followers || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetFollowersResponse {
  /**
   * Standard JavaScript object representation for GetFollowersResponse
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    followers?: Follower.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetFollowersResponse
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    followers: Follower.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.follow.GetFollowingRequest
 */
export class GetFollowingRequest implements GrpcMessage {
  static id = 'api.follow.GetFollowingRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFollowingRequest();
    GetFollowingRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFollowingRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startIndex = _instance.startIndex || 0;
    _instance.count = _instance.count || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFollowingRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startIndex = _reader.readInt32();
          break;
        case 3:
          _instance.count = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetFollowingRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFollowingRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startIndex) {
      _writer.writeInt32(2, _instance.startIndex);
    }
    if (_instance.count) {
      _writer.writeInt32(3, _instance.count);
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startIndex: number;
  private _count: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFollowingRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFollowingRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startIndex = _value.startIndex;
    this.count = _value.count;
    GetFollowingRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startIndex(): number {
    return this._startIndex;
  }
  set startIndex(value: number) {
    this._startIndex = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFollowingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFollowingRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startIndex: this.startIndex,
      count: this.count
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFollowingRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startIndex: this.startIndex,
      count: this.count
    };
  }
}
export module GetFollowingRequest {
  /**
   * Standard JavaScript object representation for GetFollowingRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startIndex: number;
    count: number;
  }

  /**
   * Protobuf JSON representation for GetFollowingRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startIndex: number;
    count: number;
  }
}

/**
 * Message implementation for api.follow.GetFollowingResponse
 */
export class GetFollowingResponse implements GrpcMessage {
  static id = 'api.follow.GetFollowingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFollowingResponse();
    GetFollowingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFollowingResponse) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.followers = _instance.followers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFollowingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new Follower();
          _reader.readMessage(
            messageInitializer2,
            Follower.deserializeBinaryFromReader
          );
          (_instance.followers = _instance.followers || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetFollowingResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFollowingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.followers && _instance.followers.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.followers as any,
        Follower.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _followers?: Follower[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFollowingResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFollowingResponse.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.followers = (_value.followers || []).map(m => new Follower(m));
    GetFollowingResponse.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get followers(): Follower[] | undefined {
    return this._followers;
  }
  set followers(value: Follower[] | undefined) {
    this._followers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFollowingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFollowingResponse.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      followers: (this.followers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFollowingResponse.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      followers: (this.followers || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetFollowingResponse {
  /**
   * Standard JavaScript object representation for GetFollowingResponse
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    followers?: Follower.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetFollowingResponse
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    followers: Follower.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.follow.Follower
 */
export class Follower implements GrpcMessage {
  static id = 'api.follow.Follower';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Follower();
    Follower.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Follower) {
    _instance.alias = _instance.alias || '';
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Follower,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.firstName = _reader.readString();
          break;
        case 3:
          _instance.lastName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Follower.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Follower, _writer: BinaryWriter) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.firstName) {
      _writer.writeString(2, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(3, _instance.lastName);
    }
  }

  private _alias: string;
  private _firstName: string;
  private _lastName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Follower to deeply clone from
   */
  constructor(_value?: RecursivePartial<Follower.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    Follower.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Follower.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Follower.AsObject {
    return {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Follower.AsProtobufJSON {
    return {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }
}
export module Follower {
  /**
   * Standard JavaScript object representation for Follower
   */
  export interface AsObject {
    alias: string;
    firstName: string;
    lastName: string;
  }

  /**
   * Protobuf JSON representation for Follower
   */
  export interface AsProtobufJSON {
    alias: string;
    firstName: string;
    lastName: string;
  }
}

/**
 * Message implementation for api.follow.RemoveFollowerRequest
 */
export class RemoveFollowerRequest implements GrpcMessage {
  static id = 'api.follow.RemoveFollowerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveFollowerRequest();
    RemoveFollowerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveFollowerRequest) {
    _instance.alias = _instance.alias || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveFollowerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RemoveFollowerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveFollowerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
  }

  private _alias: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveFollowerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RemoveFollowerRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    RemoveFollowerRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveFollowerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveFollowerRequest.AsObject {
    return {
      alias: this.alias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveFollowerRequest.AsProtobufJSON {
    return {
      alias: this.alias
    };
  }
}
export module RemoveFollowerRequest {
  /**
   * Standard JavaScript object representation for RemoveFollowerRequest
   */
  export interface AsObject {
    alias: string;
  }

  /**
   * Protobuf JSON representation for RemoveFollowerRequest
   */
  export interface AsProtobufJSON {
    alias: string;
  }
}

/**
 * Message implementation for api.follow.RemoveFollowerResponse
 */
export class RemoveFollowerResponse implements GrpcMessage {
  static id = 'api.follow.RemoveFollowerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveFollowerResponse();
    RemoveFollowerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveFollowerResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveFollowerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    RemoveFollowerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveFollowerResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveFollowerResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RemoveFollowerResponse.AsObject>) {
    _value = _value || {};
    RemoveFollowerResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveFollowerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveFollowerResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveFollowerResponse.AsProtobufJSON {
    return {};
  }
}
export module RemoveFollowerResponse {
  /**
   * Standard JavaScript object representation for RemoveFollowerResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for RemoveFollowerResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.follow.UnfollowRequest
 */
export class UnfollowRequest implements GrpcMessage {
  static id = 'api.follow.UnfollowRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnfollowRequest();
    UnfollowRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnfollowRequest) {
    _instance.alias = _instance.alias || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnfollowRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnfollowRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnfollowRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
  }

  private _alias: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnfollowRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnfollowRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    UnfollowRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnfollowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnfollowRequest.AsObject {
    return {
      alias: this.alias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnfollowRequest.AsProtobufJSON {
    return {
      alias: this.alias
    };
  }
}
export module UnfollowRequest {
  /**
   * Standard JavaScript object representation for UnfollowRequest
   */
  export interface AsObject {
    alias: string;
  }

  /**
   * Protobuf JSON representation for UnfollowRequest
   */
  export interface AsProtobufJSON {
    alias: string;
  }
}

/**
 * Message implementation for api.follow.UnfollowResponse
 */
export class UnfollowResponse implements GrpcMessage {
  static id = 'api.follow.UnfollowResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnfollowResponse();
    UnfollowResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnfollowResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnfollowResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UnfollowResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnfollowResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnfollowResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnfollowResponse.AsObject>) {
    _value = _value || {};
    UnfollowResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnfollowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnfollowResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnfollowResponse.AsProtobufJSON {
    return {};
  }
}
export module UnfollowResponse {
  /**
   * Standard JavaScript object representation for UnfollowResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UnfollowResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.follow.ResolveFollowRequestRequest
 */
export class ResolveFollowRequestRequest implements GrpcMessage {
  static id = 'api.follow.ResolveFollowRequestRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ResolveFollowRequestRequest();
    ResolveFollowRequestRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ResolveFollowRequestRequest) {
    _instance.followerAlias = _instance.followerAlias || '';
    _instance.approved = _instance.approved || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ResolveFollowRequestRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.followerAlias = _reader.readString();
          break;
        case 2:
          _instance.approved = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ResolveFollowRequestRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ResolveFollowRequestRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.followerAlias) {
      _writer.writeString(1, _instance.followerAlias);
    }
    if (_instance.approved) {
      _writer.writeBool(2, _instance.approved);
    }
  }

  private _followerAlias: string;
  private _approved: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ResolveFollowRequestRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ResolveFollowRequestRequest.AsObject>) {
    _value = _value || {};
    this.followerAlias = _value.followerAlias;
    this.approved = _value.approved;
    ResolveFollowRequestRequest.refineValues(this);
  }
  get followerAlias(): string {
    return this._followerAlias;
  }
  set followerAlias(value: string) {
    this._followerAlias = value;
  }
  get approved(): boolean {
    return this._approved;
  }
  set approved(value: boolean) {
    this._approved = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ResolveFollowRequestRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ResolveFollowRequestRequest.AsObject {
    return {
      followerAlias: this.followerAlias,
      approved: this.approved
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ResolveFollowRequestRequest.AsProtobufJSON {
    return {
      followerAlias: this.followerAlias,
      approved: this.approved
    };
  }
}
export module ResolveFollowRequestRequest {
  /**
   * Standard JavaScript object representation for ResolveFollowRequestRequest
   */
  export interface AsObject {
    followerAlias: string;
    approved: boolean;
  }

  /**
   * Protobuf JSON representation for ResolveFollowRequestRequest
   */
  export interface AsProtobufJSON {
    followerAlias: string;
    approved: boolean;
  }
}

/**
 * Message implementation for api.follow.ResolveFollowRequestResponse
 */
export class ResolveFollowRequestResponse implements GrpcMessage {
  static id = 'api.follow.ResolveFollowRequestResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ResolveFollowRequestResponse();
    ResolveFollowRequestResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ResolveFollowRequestResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ResolveFollowRequestResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    ResolveFollowRequestResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ResolveFollowRequestResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ResolveFollowRequestResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ResolveFollowRequestResponse.AsObject>
  ) {
    _value = _value || {};
    ResolveFollowRequestResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ResolveFollowRequestResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ResolveFollowRequestResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ResolveFollowRequestResponse.AsProtobufJSON {
    return {};
  }
}
export module ResolveFollowRequestResponse {
  /**
   * Standard JavaScript object representation for ResolveFollowRequestResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for ResolveFollowRequestResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.follow.GetStatsRequest
 */
export class GetStatsRequest implements GrpcMessage {
  static id = 'api.follow.GetStatsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetStatsRequest();
    GetStatsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetStatsRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetStatsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetStatsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetStatsRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetStatsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetStatsRequest.AsObject>) {
    _value = _value || {};
    GetStatsRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetStatsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetStatsRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetStatsRequest.AsProtobufJSON {
    return {};
  }
}
export module GetStatsRequest {
  /**
   * Standard JavaScript object representation for GetStatsRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetStatsRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.follow.GetStatsResponse
 */
export class GetStatsResponse implements GrpcMessage {
  static id = 'api.follow.GetStatsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetStatsResponse();
    GetStatsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetStatsResponse) {
    _instance.followersCount = _instance.followersCount || 0;
    _instance.followingCount = _instance.followingCount || 0;
    _instance.followRequestCount = _instance.followRequestCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetStatsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.followersCount = _reader.readInt32();
          break;
        case 2:
          _instance.followingCount = _reader.readInt32();
          break;
        case 3:
          _instance.followRequestCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetStatsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetStatsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.followersCount) {
      _writer.writeInt32(1, _instance.followersCount);
    }
    if (_instance.followingCount) {
      _writer.writeInt32(2, _instance.followingCount);
    }
    if (_instance.followRequestCount) {
      _writer.writeInt32(3, _instance.followRequestCount);
    }
  }

  private _followersCount: number;
  private _followingCount: number;
  private _followRequestCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetStatsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetStatsResponse.AsObject>) {
    _value = _value || {};
    this.followersCount = _value.followersCount;
    this.followingCount = _value.followingCount;
    this.followRequestCount = _value.followRequestCount;
    GetStatsResponse.refineValues(this);
  }
  get followersCount(): number {
    return this._followersCount;
  }
  set followersCount(value: number) {
    this._followersCount = value;
  }
  get followingCount(): number {
    return this._followingCount;
  }
  set followingCount(value: number) {
    this._followingCount = value;
  }
  get followRequestCount(): number {
    return this._followRequestCount;
  }
  set followRequestCount(value: number) {
    this._followRequestCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetStatsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetStatsResponse.AsObject {
    return {
      followersCount: this.followersCount,
      followingCount: this.followingCount,
      followRequestCount: this.followRequestCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetStatsResponse.AsProtobufJSON {
    return {
      followersCount: this.followersCount,
      followingCount: this.followingCount,
      followRequestCount: this.followRequestCount
    };
  }
}
export module GetStatsResponse {
  /**
   * Standard JavaScript object representation for GetStatsResponse
   */
  export interface AsObject {
    followersCount: number;
    followingCount: number;
    followRequestCount: number;
  }

  /**
   * Protobuf JSON representation for GetStatsResponse
   */
  export interface AsProtobufJSON {
    followersCount: number;
    followingCount: number;
    followRequestCount: number;
  }
}
