import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GarminLoginStatus,
  GetLoginStatusResponse,
} from 'generated/src/main/proto/api/garmin-service.pb';
import {
  BannerMessage,
  BannerService,
} from 'src/app/services/banner/banner.service';
import { GarminService } from 'src/app/services/garmin/garmin.service';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'app-garmin-login',
  templateUrl: './garmin-login.component.html',
  styleUrls: ['./garmin-login.component.scss'],
})
export class GarminLoginComponent implements OnInit {
  // Whether the component is part of redirect or not.
  @Input()
  redirect = false;

  loginStatusDisplay = '';
  private loginStatus = GarminLoginStatus.GARMIN_LOGIN_STATUS_UNSPECIFIED;

  constructor(
    private garminService: GarminService,
    private activatedRoute: ActivatedRoute,
    private bannerService: BannerService,
    private router: Router,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.redirect) {
      const oauthToken =
        this.activatedRoute.snapshot.queryParamMap.get('oauth_token');
      const oauthVerifier =
        this.activatedRoute.snapshot.queryParamMap.get('oauth_verifier');
      if (!oauthToken || !oauthVerifier) {
        return;
      }
      this.garminService.completeLogin(oauthToken, oauthVerifier).then(() => {
        this.bannerService.add(new BannerMessage('Logged-in to Garmin'));
        this.router.navigate(['/profile']);
      });
    } else {
      this.garminService
        .getLoginStatus()
        .then((r) => this.processLoginStatusResponse(r));
    }
  }

  onLogin() {
    this.garminService.startLogin().then((r) => {
      const url = new URL(environment.garmin.oauthConfirmUrl);
      url.searchParams.append('oauth_token', r.oauthToken);
      window.location.href = url.href;
    });
  }

  get logInDisabled(): boolean {
    return this.loginStatus == GarminLoginStatus.GARMIN_LOGIN_STATUS_LOGGED_IN;
  }

  get logOutDisabled(): boolean {
    return (
      this.loginStatus == GarminLoginStatus.GARMIN_LOGIN_STATUS_NO_RECORD ||
      this.loginStatus == GarminLoginStatus.GARMIN_LOGIN_STATUS_UNSPECIFIED
    );
  }

  onLogout() {
    ConfirmationComponent.openDialog(
      this.matDialog,
      'Log out will remove connection to Fitbit. Confirm?',
      (result) => {
        if (result) {
          this.garminService.logOut().then((r) => {
            this.loginStatusDisplay = 'Not logged in';
            this.loginStatus = r.loginStatus;
          });
        }
      },
    );
  }

  private processLoginStatusResponse(r: GetLoginStatusResponse) {
    switch (r.loginStatus) {
      case GarminLoginStatus.GARMIN_LOGIN_STATUS_LOGGED_IN:
        this.loginStatusDisplay = 'Logged In';
        break;
      case GarminLoginStatus.GARMIN_LOGIN_STATUS_NO_TOKEN:
        this.loginStatusDisplay = 'Invalid token';
        break;
      default:
      case GarminLoginStatus.GARMIN_LOGIN_STATUS_NO_RECORD:
        this.loginStatusDisplay = 'Not logged in';
        break;
    }
    this.loginStatus = r.loginStatus;
  }
}
