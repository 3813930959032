import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { CookiesModule } from './cookies/cookies.module';
import { LegalComponent } from './legal.component';
import { PrivacyModule } from './privacy/privacy.module';
import { TermsModule } from './terms/terms.module';

@NgModule({
  declarations: [LegalComponent],
  exports: [LegalComponent],
  imports: [
    CommonModule,
    CookiesModule,
    MatTabsModule,
    PrivacyModule,
    TermsModule,
    RouterModule,
  ],
})
export class LegalModule {}
