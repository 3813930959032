import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { EventEditorComponent } from './event-editor.component';

@Directive({
  selector: '[appCalEndTimeValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EndTimeValidatorDirective,
      multi: true,
    },
  ],
})
export class EndTimeValidatorDirective implements Validator {
  @Input('appCalEndTimeValidatorDialog')
  dialog!: EventEditorComponent;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.dialog.validateEndTime(control.value)
      ? null
      : { error: 'Invalid end time' };
  }
}
