import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { LoggedInUser, UserService } from '../services/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  user?: LoggedInUser;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.userService.user.subscribe((user) => {
      this.user = user;
    });
  }

  showUserDetails(): void {
    this.router.navigate(['profile', /* update */ true]);
  }
}
