import { Injectable } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  AddRequest,
  AddResponse,
  GetRequest,
  GetResponse,
  GetSummariesRequest,
  GetSummariesResponse,
  IssueContext,
  UpdateRequest,
  UpdateResponse,
} from 'generated/src/main/proto/api/issue-service.pb';
import { IssueServiceClient } from 'generated/src/main/proto/api/issue-service.pbsc';
import {
  IssueResolution,
  IssueState,
  IssueType,
} from 'generated/src/main/proto/shared/issue-shared.pb';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { AppConfigProvider } from 'src/environments/app-config';
import { getSessionId } from '../api.auth';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

/** Provides access to issues, users enter issues, reviewers respond to them.  */
@Injectable({
  providedIn: 'root',
})
export class IssueService {
  constructor(
    private issueServiceClient: IssueServiceClient,
    private userService: UserService,
    private bannerService: BannerService,
  ) {}

  /** Loads one issue with all data. */
  async get(id: string): Promise<GetResponse> {
    const request = new GetRequest({
      issueId: id,
    });
    return lastValueFrom(
      this.issueServiceClient
        .get(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Loads a page of issues. */
  async getSummaries(
    startIndex: number,
    count: number,
    readTime: Timestamp | undefined,
  ): Promise<GetSummariesResponse> {
    const request = new GetSummariesRequest({
      startIndex: startIndex,
      count: count,
    });
    if (readTime) {
      request.readTime = readTime;
    }
    return lastValueFrom(
      this.issueServiceClient
        .getSummaries(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Updates an issue. */
  async update(
    issueId: string,
    state: IssueState,
    type: IssueType,
    comment: string,
    resolution: IssueResolution | undefined,
  ): Promise<UpdateResponse> {
    const request = new UpdateRequest({
      issueId: issueId,
      state: state,
      type: type,
      comment: comment,
    });
    if (resolution) {
      request.resolution = resolution;
    }
    return lastValueFrom(
      this.issueServiceClient
        .update(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  /** Adds issue to database. */
  async add(
    type: IssueType,
    consentToContact: boolean,
    title: string,
    description: string,
    context: IssueContext,
    screenshot: Uint8Array | undefined,
  ): Promise<AddResponse> {
    const request = new AddRequest({
      type: type,
      consentToContactUser: consentToContact,
      title: title,
      description: description,
      context: context,
    });
    if (screenshot) {
      request.screenshot = screenshot;
    }
    return lastValueFrom(
      this.issueServiceClient
        .add(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  static getScreenshotImageSrc(blobId: string): string {
    let url =
      AppConfigProvider.config.apiServerHost +
      '/rest/issue-screenshots?id=' +
      blobId;
    if (window.location.hostname.startsWith('localhost')) {
      url += '&session=' + getSessionId();
    }
    return url;
  }
}
