import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ScreenTrackingService,
  UserTrackingService,
  getAnalytics,
  provideAnalytics,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrpcCoreModule } from '@ngx-grpc/core';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxCaptureModule } from 'ngx-capture';
import { AppConfigProvider } from 'src/environments/app-config';
import { ActivitiesModule } from './activities/activities.module';
import { ActivityDetailModule } from './activity-detail/activity-detail.module';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { AdminModule } from './admin/admin.module';
import { RolesModule } from './admin/roles/roles.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerModule } from './banner/banner.module';
import { CalModule } from './calendar/cal.module';
import { ConfirmationModule } from './common/confirmation/confirmation.module';
import { IssueReportModule } from './common/issue-report/issue-report.module';
import { ProfileImageModule } from './common/profile-image/profile-image.module';
import { ReactionsModule } from './common/reactions/reactions.modue';
import { ScrollModule } from './common/scroll/scroll.module';
import { DocsModule } from './docs/docs.module';
import { HomeModule } from './home/home.module';
import { LegalRoutingModule } from './legal/legal-routing.module';
import { LegalModule } from './legal/legal.module';
import { LoginModule } from './login/login.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotificationsModule } from './notifications/notifications.module';
import { PeopleModule } from './people/people.module';
import { ProfileModule } from './profile/profile.module';
import { ReactionDetailsModule } from './reaction-details/reaction-details.module';
import { RedirectsModule } from './redirects/redirects.module';
import { ApiModule } from './services/api.module';
import { StatsModule } from './stats/stats.module';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    // Children routing modules must be imported before the main routing module.
    AdminRoutingModule,
    LegalRoutingModule,
    AppRoutingModule,

    // All the rest of the modules.
    ActivitiesModule,
    ActivityDetailModule,
    AdminModule,
    AngularSvgIconModule.forRoot(),
    ApiModule,
    BannerModule,
    BrowserModule,
    BrowserAnimationsModule,
    CalModule,
    ConfirmationModule,
    DocsModule,
    GoogleSigninButtonModule,
    GrpcCoreModule.forRoot(),
    GrpcWebClientModule.forRoot(),
    HomeModule,
    HttpClientModule,
    IssueReportModule,
    LegalModule,
    LoginModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatGridListModule,
    MatMenuModule,
    MatPseudoCheckboxModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    NgxCaptureModule,
    NotificationsModule,
    ProfileImageModule,
    RedirectsModule,
    RolesModule,
    ScrollingModule,
    ScrollModule,
    PeopleModule,
    ProfileModule,
    provideFirebaseApp(() => initializeApp(AppConfigProvider.config)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => getStorage()),
    ReactionDetailsModule,
    ReactionsModule,
    SocialLoginModule,
    StatsModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: () => {
        return {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                AppConfigProvider.config.googleAuth2ClientId,
                {
                  oneTapEnabled: false,
                },
              ),
            },
          ],
          // eslint-disable-next-line
          onError: (err: any) => {
            console.error(err);
          },
        } as SocialAuthServiceConfig;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
