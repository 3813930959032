import { Component } from '@angular/core';
import { FormatService } from '../services/format.service';

type NavigationLink = {
  label: string;
  route: string;
};

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent {
  private static readonly NAV_LINKS: NavigationLink[] = [
    {
      label: 'Privacy',
      route: '/legal/privacy-policy',
    },
    {
      label: 'Cookies',
      route: '/legal/cookies-policy',
    },
    {
      label: 'Terms of Service',
      route: '/legal/terms-of-service',
    },
  ];

  width: number;
  navLinks = LegalComponent.NAV_LINKS;

  constructor(formatService: FormatService) {
    this.width = formatService.viewWidth;
  }
}
