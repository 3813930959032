<mat-tab-group class="header">
  <mat-tab label="{{ followersHeader }}">
    <mat-table
      class="results-table"
      [ngStyle]="{ width: innerWidth + 'px' }"
      [dataSource]="followerDataSource"
    >
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let follower">{{ formatName(follower) }}</td>
      </ng-container>

      <!-- Alias -->
      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef>Alias</th>
        <td mat-cell *matCellDef="let follower">{{ follower.alias }}</td>
      </ng-container>

      <!-- Remove button -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>Remove</th>
        <td mat-cell *matCellDef="let follower">
          <button
            mat-raised-button
            color="accent"
            class="delete button"
            matTooltip="Remove follower"
            (click)="removeFollower(follower.alias)"
          >
            <mat-icon class="material-symbols-outlined">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="followerDisplayColumns"></tr> -->
      <tr cdk-row *matRowDef="let row; columns: followerDisplayColumns"></tr>
    </mat-table>

    <!-- Paginator -->
    <mat-paginator
      #followerPaginator
      [pageSizeOptions]="[10, 20, 50]"
      aria-label="Select page"
      matTooltip="Select page"
    >
    </mat-paginator>
  </mat-tab>
  <mat-tab label="{{ followingHeader }}">
    <mat-table
      class="results-table"
      [ngStyle]="{ width: innerWidth + 'px' }"
      [dataSource]="followingDataSource"
    >
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let follower">{{ formatName(follower) }}</td>
      </ng-container>

      <!-- Alias -->
      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef>Alias</th>
        <td mat-cell *matCellDef="let follower">{{ follower.alias }}</td>
      </ng-container>

      <!-- Unfollow button -->
      <ng-container matColumnDef="unfollow">
        <th mat-header-cell *matHeaderCellDef>Unfollow</th>
        <td mat-cell *matCellDef="let follower">
          <button
            mat-raised-button
            class="button"
            color="accent"
            matTooltip="Stop following"
            (click)="unfollow(follower.alias)"
          >
            Unfollow
          </button>
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="followingDisplayColumns"></tr> -->
      <tr cdk-row *matRowDef="let row; columns: followingDisplayColumns"></tr>
    </mat-table>

    <!-- Paginator -->
    <mat-paginator
      #followingPaginator
      [pageSizeOptions]="[10, 20, 50]"
      aria-label="Select page"
      matTooltip="Select page"
    >
    </mat-paginator>
  </mat-tab>

  <mat-tab label="{{ requestsHeader }}">
    <mat-table
      class="results-table"
      [ngStyle]="{ width: innerWidth + 'px' }"
      [dataSource]="followRequestDataSource"
    >
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let request">{{ formatName(request) }}</td>
      </ng-container>

      <!-- Alias -->
      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef>Alias</th>
        <td mat-cell *matCellDef="let request">{{ request.alias }}</td>
      </ng-container>

      <!-- Message -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let request">{{ request.message }}</td>
      </ng-container>

      <!-- Date -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let request">
          {{ formatDate(request.requestTime) }}
        </td>
      </ng-container>

      <!-- Accept/reject -->
      <ng-container matColumnDef="decision">
        <th mat-header-cell *matHeaderCellDef>Decision</th>
        <td mat-cell *matCellDef="let request">
          <button
            mat-raised-button
            class="button"
            matTooltip="Reject request"
            (click)="rejectRequest(request.alias)"
          >
            <!-- <mat-icon>person_remove</mat-icon> -->
            Reject
          </button>
          <button
            mat-raised-button
            class="button"
            color="accent"
            matTooltip="Accept request"
            (click)="acceptRequest(request.alias)"
          >
            <!-- <mat-icon class="material-symbols-outlined">person_add</mat-icon> -->
            Accept
          </button>
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="followRequestDisplayColumns"></tr> -->
      <tr
        cdk-row
        *matRowDef="let row; columns: followRequestDisplayColumns"
      ></tr>
    </mat-table>

    <!-- Paginator -->
    <mat-paginator
      #requestsPaginator
      [pageSizeOptions]="[10, 20, 50]"
      aria-label="Select page"
      matTooltip="Select page"
    >
    </mat-paginator>
  </mat-tab>

  <mat-tab label="Search">
    <!-- Search controls -->
    <div class="search">
      <div class="search-input-container">
        <input
          #searchInput
          class="search-input"
          matInput
          [(ngModel)]="searchQuery"
          placeholder="@alias or full/last name"
          [ngModelOptions]="{ standalone: true }"
          (keydown)="onSearchInputKeyDown($event)"
        />
        <div class="search-input-clear-container">
          <mat-icon
            class="search-input-clear"
            [ngStyle]="{ display: searchInput.value ? 'inherit' : 'none' }"
            matTooltip="Clear query"
            (click)="onSearchInputClearClick()"
            >close</mat-icon
          >
        </div>
        <mat-icon
          class="search-input-search"
          matTooltip="Search by @alias, last name, first + last names or everyone near your location(s)."
          (click)="search()"
          >search</mat-icon
        >
      </div>
    </div>

    <mat-table
      class="results-table"
      [ngStyle]="{ width: innerWidth + 'px' }"
      [dataSource]="peopleDataSource"
    >
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell class="search-header-cell" *matHeaderCellDef>
          Name
        </th>
        <td mat-cell class="search-cell" *matCellDef="let candidate">
          {{ formatName(candidate) }}
        </td>
      </ng-container>

      <!-- Alias -->
      <ng-container matColumnDef="alias">
        <th mat-header-cell class="search-header-cell" *matHeaderCellDef>
          Alias
        </th>
        <td mat-cell class="search-cell" *matCellDef="let candidate">
          {{ candidate.alias }}
        </td>
      </ng-container>

      <!-- Location -->
      <ng-container matColumnDef="location">
        <th mat-header-cell class="search-header-cell" *matHeaderCellDef>
          Location
        </th>
        <td mat-cell class="search-cell" *matCellDef="let candidate">
          {{ candidate.firstLocationName }}
        </td>
      </ng-container>

      <!-- Follow button -->
      <ng-container matColumnDef="follow">
        <th mat-header-cell class="search-header-cell" *matHeaderCellDef>
          Follow
        </th>
        <td mat-cell class="search-cell" *matCellDef="let candidate">
          <button
            mat-raised-button
            class="button"
            color="accent"
            matTooltip="Follow this person"
            [disabled]="followButtonDisabled(candidate.followStatus)"
            (click)="follow(candidate.alias)"
          >
            {{ followButtonText(candidate.followStatus) }}
          </button>
          <mat-icon
            *ngIf="wasRejected(candidate.followStatus)"
            class="was-rejected-icon"
            matTooltip="Was rejected before"
            >redo</mat-icon
          >
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="followRequestDisplayColumns"></tr> -->
      <tr cdk-row *matRowDef="let row; columns: searchDisplayColumns"></tr>
    </mat-table>
    <!-- Paginator -->
    <mat-paginator
      #peoplePaginator
      [pageSizeOptions]="[10, 20, 50]"
      aria-label="Select page"
      matTooltip="Select page"
    >
    </mat-paginator>
  </mat-tab>
</mat-tab-group>
