import { Component } from '@angular/core';
import {
  BannerMessage,
  BannerService,
} from '../services/banner/banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  messageQueue: Array<BannerMessage> = [];

  constructor(bannerService: BannerService) {
    bannerService.message.subscribe((m) => {
      if (m.message != null) {
        const message = m.message.length == 0 ? 'Internal error' : m.message;
        m = { message: message, timeout: m.timeout };
        this.messageQueue.push(m);
        setTimeout(() => this.remove(m), m.timeout);
      }
    });
  }

  removeItem(index: number): void {
    this.messageQueue.splice(index, 1);
  }

  private remove(m: BannerMessage): void {
    const index = this.messageQueue.indexOf(m);
    if (index >= 0) {
      this.messageQueue.splice(index, 1);
    }
  }
}
