import { Summary } from 'generated/src/main/proto/api/activity-service.pb';
import {
  ReactionType,
  Reactions,
} from 'generated/src/main/proto/shared/reaction.pb';
import { ActivitiesService } from 'src/app/services/activities/activities.service';
import {
  BannerMessage,
  BannerService,
} from 'src/app/services/banner/banner.service';
import { ReactionUpdater } from './reactions.component';

/** Updates reactions for activities. */
export class ActivityReactionUpdater implements ReactionUpdater {
  constructor(
    private activitiesService: ActivitiesService,
    private bannerService: BannerService,
    private summary: Summary,
    private userAlias: string,
  ) {}

  getUserAlias(): string {
    return this.userAlias;
  }

  isUserAllowedToReact(): boolean {
    return this.summary.alias != this.userAlias;
  }

  async setReaction(reactionType: ReactionType): Promise<Reactions> {
    return this.activitiesService
      .setReaction(this.summary.activityId, reactionType)
      .then((r) => {
        this.summary.reactions = new Reactions(r.reactions);
        return this.summary.reactions;
      })
      .catch((reason) => {
        this.bannerService.add(
          new BannerMessage('Failed to set reaction: ' + reason),
        );
        throw Error('Failed to set reaction');
      });
  }

  getReactions(): Reactions | undefined {
    return this.summary.reactions;
  }

  async addComment(text: string): Promise<Reactions> {
    return this.activitiesService
      .updateComment(this.summary.activityId, text, undefined, undefined)
      .then((r) => {
        this.summary!.reactions = r.reactions;
        return r.reactions!;
      })
      .catch((reason) => {
        this.bannerService.add(
          new BannerMessage('Failed to add/delete comment: ' + reason),
        );
        throw Error('Failed to add comment');
      });
  }
}
