import { Injectable } from '@angular/core';
import {
  CommentToAdd,
  GetMetadataRequest,
  GetMetadataResponse,
  ReactionToAdd,
  SetReactionRequest,
  SetReactionResponse,
  UpdateCommentRequest,
  UpdateCommentResponse,
} from 'generated/src/main/proto/api/document-service.pb';
import { DocumentServiceClient } from 'generated/src/main/proto/api/document-service.pbsc';
import { ReactionType } from 'generated/src/main/proto/shared/reaction.pb';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

/** Provides access to the document metadata (comments, reactions). */
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private documentServiceClient: DocumentServiceClient,
    private bannerService: BannerService,
    private userService: UserService,
  ) {}

  async getMetadata(path: string): Promise<GetMetadataResponse> {
    return lastValueFrom(
      this.documentServiceClient
        .getMetadta(
          new GetMetadataRequest({ path: path }),
          this.userService.userTokenMetadata,
        )
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async setReaction(
    path: string,
    reactionType: ReactionType,
  ): Promise<SetReactionResponse> {
    console.log('Set reaction: ' + reactionType);

    const request = new SetReactionRequest({
      path: path,
      reactionType: reactionType,
    });
    return lastValueFrom(
      this.documentServiceClient
        .setReaction(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async updateComment(
    path: string,
    text: string,
    replytoId: number | undefined,
    deleteId: number | undefined,
  ): Promise<UpdateCommentResponse> {
    const request = new UpdateCommentRequest({
      path: path,
    });
    if (deleteId != undefined) {
      // Workaround for proto oneof not distinguishing 0 from absent value.
      request.deleteIdPlusOne = deleteId + 1;
    } else {
      request.commentToAdd = new CommentToAdd({
        text: text,
        replyToId: replytoId,
      });
    }
    return lastValueFrom(
      this.documentServiceClient
        .updateComment(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }

  async updateCommentReaction(
    path: string,
    commentId: number,
    reactionType: ReactionType,
  ): Promise<UpdateCommentResponse> {
    const request = new UpdateCommentRequest({
      path: path,
      reactionToAdd: new ReactionToAdd({
        commentId: commentId,
        reactionType: reactionType,
      }),
    });
    return lastValueFrom(
      this.documentServiceClient
        .updateComment(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
