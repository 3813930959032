/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for shared.ApiConfig
 */
export class ApiConfig implements GrpcMessage {
  static id = 'shared.ApiConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiConfig();
    ApiConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiConfig) {
    _instance.userRestrictions = _instance.userRestrictions || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userRestrictions = new UserRestrictions();
          _reader.readMessage(
            _instance.userRestrictions,
            UserRestrictions.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApiConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ApiConfig, _writer: BinaryWriter) {
    if (_instance.userRestrictions) {
      _writer.writeMessage(
        1,
        _instance.userRestrictions as any,
        UserRestrictions.serializeBinaryToWriter
      );
    }
  }

  private _userRestrictions?: UserRestrictions;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiConfig.AsObject>) {
    _value = _value || {};
    this.userRestrictions = _value.userRestrictions
      ? new UserRestrictions(_value.userRestrictions)
      : undefined;
    ApiConfig.refineValues(this);
  }
  get userRestrictions(): UserRestrictions | undefined {
    return this._userRestrictions;
  }
  set userRestrictions(value: UserRestrictions | undefined) {
    this._userRestrictions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiConfig.AsObject {
    return {
      userRestrictions: this.userRestrictions
        ? this.userRestrictions.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiConfig.AsProtobufJSON {
    return {
      userRestrictions: this.userRestrictions
        ? this.userRestrictions.toProtobufJSON(options)
        : null
    };
  }
}
export module ApiConfig {
  /**
   * Standard JavaScript object representation for ApiConfig
   */
  export interface AsObject {
    userRestrictions?: UserRestrictions.AsObject;
  }

  /**
   * Protobuf JSON representation for ApiConfig
   */
  export interface AsProtobufJSON {
    userRestrictions: UserRestrictions.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.UserRestrictions
 */
export class UserRestrictions implements GrpcMessage {
  static id = 'shared.UserRestrictions';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserRestrictions();
    UserRestrictions.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserRestrictions) {
    _instance.allowedDomains = _instance.allowedDomains || [];
    _instance.allowedEmails = _instance.allowedEmails || [];
    _instance.maxPublicUsers = _instance.maxPublicUsers || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserRestrictions,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.allowedDomains = _instance.allowedDomains || []).push(
            _reader.readString()
          );
          break;
        case 2:
          (_instance.allowedEmails = _instance.allowedEmails || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.maxPublicUsers = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    UserRestrictions.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserRestrictions,
    _writer: BinaryWriter
  ) {
    if (_instance.allowedDomains && _instance.allowedDomains.length) {
      _writer.writeRepeatedString(1, _instance.allowedDomains);
    }
    if (_instance.allowedEmails && _instance.allowedEmails.length) {
      _writer.writeRepeatedString(2, _instance.allowedEmails);
    }
    if (_instance.maxPublicUsers) {
      _writer.writeInt64String(3, _instance.maxPublicUsers);
    }
  }

  private _allowedDomains: string[];
  private _allowedEmails: string[];
  private _maxPublicUsers: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserRestrictions to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserRestrictions.AsObject>) {
    _value = _value || {};
    this.allowedDomains = (_value.allowedDomains || []).slice();
    this.allowedEmails = (_value.allowedEmails || []).slice();
    this.maxPublicUsers = _value.maxPublicUsers;
    UserRestrictions.refineValues(this);
  }
  get allowedDomains(): string[] {
    return this._allowedDomains;
  }
  set allowedDomains(value: string[]) {
    this._allowedDomains = value;
  }
  get allowedEmails(): string[] {
    return this._allowedEmails;
  }
  set allowedEmails(value: string[]) {
    this._allowedEmails = value;
  }
  get maxPublicUsers(): string {
    return this._maxPublicUsers;
  }
  set maxPublicUsers(value: string) {
    this._maxPublicUsers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserRestrictions.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserRestrictions.AsObject {
    return {
      allowedDomains: (this.allowedDomains || []).slice(),
      allowedEmails: (this.allowedEmails || []).slice(),
      maxPublicUsers: this.maxPublicUsers
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserRestrictions.AsProtobufJSON {
    return {
      allowedDomains: (this.allowedDomains || []).slice(),
      allowedEmails: (this.allowedEmails || []).slice(),
      maxPublicUsers: this.maxPublicUsers
    };
  }
}
export module UserRestrictions {
  /**
   * Standard JavaScript object representation for UserRestrictions
   */
  export interface AsObject {
    allowedDomains: string[];
    allowedEmails: string[];
    maxPublicUsers: string;
  }

  /**
   * Protobuf JSON representation for UserRestrictions
   */
  export interface AsProtobufJSON {
    allowedDomains: string[];
    allowedEmails: string[];
    maxPublicUsers: string;
  }
}
