<div class="policy-text" [ngStyle]="{ 'max-height': maxHeight }">
  <h1>Cookies Policy</h1>
  <p>
    This website uses cookies to provide you with a better
    experience. By using this website, you consent to the use of
    cookies in accordance with this Cookies Policy.
  </p>

  <h2>What are Cookies?</h2>
  <p>
    Cookies are small pieces of data stored on your device (computer
    or mobile device) by your browser when you visit a website. They
    are widely used to remember you and your preferences, either for
    a single visit (through a "session cookie") or for multiple
    repeat visits (using a "persistent cookie").
  </p>

  <h2>Types of Cookies We Use</h2>
  <p>We use the following types of cookies:</p>
  <ul>
    <li>
      <strong>Persistent Cookies:</strong> These cookies remain on
      your device for a specified period or until you delete them.
      They help us recognize you when you return to our website.
    </li>
  </ul>

  <h2>Managing Cookies</h2>
  <p>
    You can manage cookies preferences through your browser
    settings or <b>by clicking 'Cookies' in the menu</b>. Most browsers
    allow you to block or delete cookies. However, please note that
    blocking or deleting cookies will impact your experience on our website,
    including the login functionality and display of images and videos.
  </p>

  <p>This Cookies Policy was last updated on {{ lastUpdateDate }}.</p>
</div>
