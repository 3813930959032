<div id="{{ id }}" #contents class="contents" [ngStyle]="{ height: height + 'px', width: width + 'px' }">
  <!-- Navigate with arrows. -->
  <div *ngIf="!scrollable" class="container">
    <!-- Full screen button -->
    <button *ngIf="isFullScreenEnabled && itemsInternal && itemsInternal.length > 0" class="full-screen-button"
      matTooltip="Toggle full screen" (click)="onFullScreen()">
      <mat-icon class="button-icon">{{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>

    <!-- Custom item -->
    <ng-container *ngTemplateOutlet="
      itemTemplate!;
      context: { index: currentIndex, data: itemTemplateData }
    "></ng-container>

    <!-- Image -->
    <img *ngIf="imageSrc" src="{{ imageSrc }}" [ngStyle]="imageStyle" alt="Gallery image" />

    <!-- Video -->
    <app-video *ngIf="videoSrc" [sourceUrl]="videoSrc!" [width]="width" [height]="height" [isFullScreen]="isFullScreen">
    </app-video>

    <!-- Counter -->
    <span *ngIf="itemsInternal && itemsInternal.length > 1" class="counter">{{
      currentIndex + 1 + "/" + itemsInternal.length
      }}</span>

    <!-- Navigation buttons -->
    <button *ngIf="itemsInternal && itemsInternal.length > 1" class="button previous" matTooltip="Previous"
      (click)="onPrevious($event)">
      <mat-icon class="button-icon">navigate_before</mat-icon>
    </button>
    <button *ngIf="itemsInternal && itemsInternal.length > 1" class="button next" matTooltip="Next"
      (click)="onNext($event)">
      <mat-icon class="button-icon">navigate_next</mat-icon>
    </button>
  </div>

  <!-- Navigate with scroll/pan. -->
  <div *ngIf="scrollable" class="scrollable container" #scrollDiv
    [ngStyle]="{ cursor: itemsInternal!.length > 1 ? 'grabbing' : 'unset'}" (mousedown)="onMouseDown($event)"
    (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)" (scroll)="onScroll()">
    <div *ngFor="let item of itemsInternal" class="image-container">
      <!-- Image -->
      <img *ngIf="item.imageSrc" src="{{ getItemImageSrc(item) }}" [ngStyle]="imageStyle" draggable="false"
        alt="Gallery image" />

      <!-- Video -->
      <app-video *ngIf="getItemVideoSrc(item)" [sourceUrl]="getItemVideoSrc(item)!" [width]="width" [height]="height"
        [isFullScreen]="isFullScreen">
      </app-video>
    </div>

    <!-- Counter -->
    <span *ngIf="itemsInternal && itemsInternal.length > 1" class="counter">{{
      computedCurrentIndex + 1 + "/" + itemsInternal.length
      }}</span>
  </div>
</div>
