import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationModule } from '../confirmation/confirmation.module';
import { GarminLoginComponent } from './garmin-login.component';

@NgModule({
  declarations: [GarminLoginComponent],
  exports: [GarminLoginComponent],
  imports: [
    CommonModule,
    ConfirmationModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
  ],
})
export class GarminLoginModule {}
