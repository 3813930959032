/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/shared/issue-shared.pb';
/**
 * Message implementation for api.issue.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.issue.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.issueId = _instance.issueId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.issueId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.issueId) {
      _writer.writeInt64String(1, _instance.issueId);
    }
  }

  private _issueId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.issueId = _value.issueId;
    GetRequest.refineValues(this);
  }
  get issueId(): string {
    return this._issueId;
  }
  set issueId(value: string) {
    this._issueId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      issueId: this.issueId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      issueId: this.issueId
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    issueId: string;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    issueId: string;
  }
}

/**
 * Message implementation for api.issue.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.issue.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.issue = _instance.issue || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.issue = new Issue();
          _reader.readMessage(
            _instance.issue,
            Issue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.issue) {
      _writer.writeMessage(
        1,
        _instance.issue as any,
        Issue.serializeBinaryToWriter
      );
    }
  }

  private _issue?: Issue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.issue = _value.issue ? new Issue(_value.issue) : undefined;
    GetResponse.refineValues(this);
  }
  get issue(): Issue | undefined {
    return this._issue;
  }
  set issue(value: Issue | undefined) {
    this._issue = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      issue: this.issue ? this.issue.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      issue: this.issue ? this.issue.toProtobufJSON(options) : null
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    issue?: Issue.AsObject;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    issue: Issue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.issue.GetSummariesRequest
 */
export class GetSummariesRequest implements GrpcMessage {
  static id = 'api.issue.GetSummariesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSummariesRequest();
    GetSummariesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSummariesRequest) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.startIndex = _instance.startIndex || 0;
    _instance.count = _instance.count || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSummariesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.startIndex = _reader.readInt32();
          break;
        case 3:
          _instance.count = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetSummariesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSummariesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.startIndex) {
      _writer.writeInt32(2, _instance.startIndex);
    }
    if (_instance.count) {
      _writer.writeInt32(3, _instance.count);
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _startIndex: number;
  private _count: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSummariesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSummariesRequest.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.startIndex = _value.startIndex;
    this.count = _value.count;
    GetSummariesRequest.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get startIndex(): number {
    return this._startIndex;
  }
  set startIndex(value: number) {
    this._startIndex = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSummariesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSummariesRequest.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      startIndex: this.startIndex,
      count: this.count
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSummariesRequest.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      startIndex: this.startIndex,
      count: this.count
    };
  }
}
export module GetSummariesRequest {
  /**
   * Standard JavaScript object representation for GetSummariesRequest
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    startIndex: number;
    count: number;
  }

  /**
   * Protobuf JSON representation for GetSummariesRequest
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    startIndex: number;
    count: number;
  }
}

/**
 * Message implementation for api.issue.GetSummariesResponse
 */
export class GetSummariesResponse implements GrpcMessage {
  static id = 'api.issue.GetSummariesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSummariesResponse();
    GetSummariesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSummariesResponse) {
    _instance.readTime = _instance.readTime || undefined;
    _instance.issues = _instance.issues || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSummariesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.readTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.readTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new IssueSummary();
          _reader.readMessage(
            messageInitializer2,
            IssueSummary.deserializeBinaryFromReader
          );
          (_instance.issues = _instance.issues || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    GetSummariesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSummariesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.readTime) {
      _writer.writeMessage(
        1,
        _instance.readTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.issues && _instance.issues.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.issues as any,
        IssueSummary.serializeBinaryToWriter
      );
    }
  }

  private _readTime?: googleProtobuf000.Timestamp;
  private _issues?: IssueSummary[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSummariesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSummariesResponse.AsObject>) {
    _value = _value || {};
    this.readTime = _value.readTime
      ? new googleProtobuf000.Timestamp(_value.readTime)
      : undefined;
    this.issues = (_value.issues || []).map(m => new IssueSummary(m));
    GetSummariesResponse.refineValues(this);
  }
  get readTime(): googleProtobuf000.Timestamp | undefined {
    return this._readTime;
  }
  set readTime(value: googleProtobuf000.Timestamp | undefined) {
    this._readTime = value;
  }
  get issues(): IssueSummary[] | undefined {
    return this._issues;
  }
  set issues(value: IssueSummary[] | undefined) {
    this._issues = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSummariesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSummariesResponse.AsObject {
    return {
      readTime: this.readTime ? this.readTime.toObject() : undefined,
      issues: (this.issues || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSummariesResponse.AsProtobufJSON {
    return {
      readTime: this.readTime ? this.readTime.toProtobufJSON(options) : null,
      issues: (this.issues || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetSummariesResponse {
  /**
   * Standard JavaScript object representation for GetSummariesResponse
   */
  export interface AsObject {
    readTime?: googleProtobuf000.Timestamp.AsObject;
    issues?: IssueSummary.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetSummariesResponse
   */
  export interface AsProtobufJSON {
    readTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    issues: IssueSummary.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.issue.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.issue.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.issueId = _instance.issueId || '0';
    _instance.state = _instance.state || 0;
    _instance.type = _instance.type || 0;
    _instance.comment = _instance.comment || '';
    _instance.resolution = _instance.resolution || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.issueId = _reader.readInt64String();
          break;
        case 2:
          _instance.state = _reader.readEnum();
          break;
        case 3:
          _instance.type = _reader.readEnum();
          break;
        case 4:
          _instance.comment = _reader.readString();
          break;
        case 5:
          _instance.resolution = new shared001.IssueResolution();
          _reader.readMessage(
            _instance.resolution,
            shared001.IssueResolution.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.issueId) {
      _writer.writeInt64String(1, _instance.issueId);
    }
    if (_instance.state) {
      _writer.writeEnum(2, _instance.state);
    }
    if (_instance.type) {
      _writer.writeEnum(3, _instance.type);
    }
    if (_instance.comment) {
      _writer.writeString(4, _instance.comment);
    }
    if (_instance.resolution) {
      _writer.writeMessage(
        5,
        _instance.resolution as any,
        shared001.IssueResolution.serializeBinaryToWriter
      );
    }
  }

  private _issueId: string;
  private _state: shared001.IssueState;
  private _type: shared001.IssueType;
  private _comment: string;
  private _resolution?: shared001.IssueResolution;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.issueId = _value.issueId;
    this.state = _value.state;
    this.type = _value.type;
    this.comment = _value.comment;
    this.resolution = _value.resolution
      ? new shared001.IssueResolution(_value.resolution)
      : undefined;
    UpdateRequest.refineValues(this);
  }
  get issueId(): string {
    return this._issueId;
  }
  set issueId(value: string) {
    this._issueId = value;
  }
  get state(): shared001.IssueState {
    return this._state;
  }
  set state(value: shared001.IssueState) {
    this._state = value;
  }
  get type(): shared001.IssueType {
    return this._type;
  }
  set type(value: shared001.IssueType) {
    this._type = value;
  }
  get comment(): string {
    return this._comment;
  }
  set comment(value: string) {
    this._comment = value;
  }
  get resolution(): shared001.IssueResolution | undefined {
    return this._resolution;
  }
  set resolution(value: shared001.IssueResolution | undefined) {
    this._resolution = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      issueId: this.issueId,
      state: this.state,
      type: this.type,
      comment: this.comment,
      resolution: this.resolution ? this.resolution.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      issueId: this.issueId,
      state:
        shared001.IssueState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      type:
        shared001.IssueType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      comment: this.comment,
      resolution: this.resolution
        ? this.resolution.toProtobufJSON(options)
        : null
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    issueId: string;
    state: shared001.IssueState;
    type: shared001.IssueType;
    comment: string;
    resolution?: shared001.IssueResolution.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    issueId: string;
    state: string;
    type: string;
    comment: string;
    resolution: shared001.IssueResolution.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.issue.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.issue.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    UpdateResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.issue.AddRequest
 */
export class AddRequest implements GrpcMessage {
  static id = 'api.issue.AddRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddRequest();
    AddRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddRequest) {
    _instance.type = _instance.type || 0;
    _instance.consentToContactUser = _instance.consentToContactUser || false;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.context = _instance.context || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.consentToContactUser = _reader.readBool();
          break;
        case 3:
          _instance.title = _reader.readString();
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          _instance.context = new IssueContext();
          _reader.readMessage(
            _instance.context,
            IssueContext.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.screenshot = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    AddRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: AddRequest, _writer: BinaryWriter) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.consentToContactUser) {
      _writer.writeBool(2, _instance.consentToContactUser);
    }
    if (_instance.title) {
      _writer.writeString(3, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.context) {
      _writer.writeMessage(
        5,
        _instance.context as any,
        IssueContext.serializeBinaryToWriter
      );
    }
    if (_instance.screenshot !== undefined && _instance.screenshot !== null) {
      _writer.writeBytes(6, _instance.screenshot);
    }
  }

  private _type: shared001.IssueType;
  private _consentToContactUser: boolean;
  private _title: string;
  private _description: string;
  private _context?: IssueContext;
  private _screenshot?: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddRequest.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.consentToContactUser = _value.consentToContactUser;
    this.title = _value.title;
    this.description = _value.description;
    this.context = _value.context
      ? new IssueContext(_value.context)
      : undefined;
    this.screenshot = _value.screenshot;
    AddRequest.refineValues(this);
  }
  get type(): shared001.IssueType {
    return this._type;
  }
  set type(value: shared001.IssueType) {
    this._type = value;
  }
  get consentToContactUser(): boolean {
    return this._consentToContactUser;
  }
  set consentToContactUser(value: boolean) {
    this._consentToContactUser = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get context(): IssueContext | undefined {
    return this._context;
  }
  set context(value: IssueContext | undefined) {
    this._context = value;
  }
  get screenshot(): Uint8Array | undefined {
    return this._screenshot;
  }
  set screenshot(value?: Uint8Array) {
    this._screenshot = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddRequest.AsObject {
    return {
      type: this.type,
      consentToContactUser: this.consentToContactUser,
      title: this.title,
      description: this.description,
      context: this.context ? this.context.toObject() : undefined,
      screenshot: this.screenshot ? this.screenshot.subarray(0) : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddRequest.AsProtobufJSON {
    return {
      type:
        shared001.IssueType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      consentToContactUser: this.consentToContactUser,
      title: this.title,
      description: this.description,
      context: this.context ? this.context.toProtobufJSON(options) : null,
      screenshot: this.screenshot ? uint8ArrayToBase64(this.screenshot) : null
    };
  }
}
export module AddRequest {
  /**
   * Standard JavaScript object representation for AddRequest
   */
  export interface AsObject {
    type: shared001.IssueType;
    consentToContactUser: boolean;
    title: string;
    description: string;
    context?: IssueContext.AsObject;
    screenshot?: Uint8Array;
  }

  /**
   * Protobuf JSON representation for AddRequest
   */
  export interface AsProtobufJSON {
    type: string;
    consentToContactUser: boolean;
    title: string;
    description: string;
    context: IssueContext.AsProtobufJSON | null;
    screenshot: string | null;
  }
}

/**
 * Message implementation for api.issue.AddResponse
 */
export class AddResponse implements GrpcMessage {
  static id = 'api.issue.AddResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddResponse();
    AddResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddResponse) {
    _instance.issueId = _instance.issueId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.issueId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    AddResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.issueId) {
      _writer.writeInt64String(1, _instance.issueId);
    }
  }

  private _issueId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddResponse.AsObject>) {
    _value = _value || {};
    this.issueId = _value.issueId;
    AddResponse.refineValues(this);
  }
  get issueId(): string {
    return this._issueId;
  }
  set issueId(value: string) {
    this._issueId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddResponse.AsObject {
    return {
      issueId: this.issueId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddResponse.AsProtobufJSON {
    return {
      issueId: this.issueId
    };
  }
}
export module AddResponse {
  /**
   * Standard JavaScript object representation for AddResponse
   */
  export interface AsObject {
    issueId: string;
  }

  /**
   * Protobuf JSON representation for AddResponse
   */
  export interface AsProtobufJSON {
    issueId: string;
  }
}

/**
 * Message implementation for api.issue.Issue
 */
export class Issue implements GrpcMessage {
  static id = 'api.issue.Issue';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Issue();
    Issue.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Issue) {
    _instance.summary = _instance.summary || undefined;
    _instance.context = _instance.context || undefined;

    _instance.activityContexts = _instance.activityContexts || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Issue, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.summary = new IssueSummary();
          _reader.readMessage(
            _instance.summary,
            IssueSummary.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.context = new IssueContext();
          _reader.readMessage(
            _instance.context,
            IssueContext.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.screenshotBlobId = _reader.readString();
          break;
        case 4:
          const messageInitializer4 = new ActivityIdContext();
          _reader.readMessage(
            messageInitializer4,
            ActivityIdContext.deserializeBinaryFromReader
          );
          (_instance.activityContexts = _instance.activityContexts || []).push(
            messageInitializer4
          );
          break;
        case 5:
          _instance.reporterEmail = _reader.readString();
          break;
        case 6:
          _instance.reporterAlias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Issue.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Issue, _writer: BinaryWriter) {
    if (_instance.summary) {
      _writer.writeMessage(
        1,
        _instance.summary as any,
        IssueSummary.serializeBinaryToWriter
      );
    }
    if (_instance.context) {
      _writer.writeMessage(
        2,
        _instance.context as any,
        IssueContext.serializeBinaryToWriter
      );
    }
    if (
      _instance.screenshotBlobId !== undefined &&
      _instance.screenshotBlobId !== null
    ) {
      _writer.writeString(3, _instance.screenshotBlobId);
    }
    if (_instance.activityContexts && _instance.activityContexts.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.activityContexts as any,
        ActivityIdContext.serializeBinaryToWriter
      );
    }
    if (
      _instance.reporterEmail !== undefined &&
      _instance.reporterEmail !== null
    ) {
      _writer.writeString(5, _instance.reporterEmail);
    }
    if (
      _instance.reporterAlias !== undefined &&
      _instance.reporterAlias !== null
    ) {
      _writer.writeString(6, _instance.reporterAlias);
    }
  }

  private _summary?: IssueSummary;
  private _context?: IssueContext;
  private _screenshotBlobId?: string;
  private _activityContexts?: ActivityIdContext[];
  private _reporterEmail?: string;
  private _reporterAlias?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Issue to deeply clone from
   */
  constructor(_value?: RecursivePartial<Issue.AsObject>) {
    _value = _value || {};
    this.summary = _value.summary
      ? new IssueSummary(_value.summary)
      : undefined;
    this.context = _value.context
      ? new IssueContext(_value.context)
      : undefined;
    this.screenshotBlobId = _value.screenshotBlobId;
    this.activityContexts = (_value.activityContexts || []).map(
      m => new ActivityIdContext(m)
    );
    this.reporterEmail = _value.reporterEmail;
    this.reporterAlias = _value.reporterAlias;
    Issue.refineValues(this);
  }
  get summary(): IssueSummary | undefined {
    return this._summary;
  }
  set summary(value: IssueSummary | undefined) {
    this._summary = value;
  }
  get context(): IssueContext | undefined {
    return this._context;
  }
  set context(value: IssueContext | undefined) {
    this._context = value;
  }
  get screenshotBlobId(): string | undefined {
    return this._screenshotBlobId;
  }
  set screenshotBlobId(value?: string) {
    this._screenshotBlobId = value;
  }
  get activityContexts(): ActivityIdContext[] | undefined {
    return this._activityContexts;
  }
  set activityContexts(value: ActivityIdContext[] | undefined) {
    this._activityContexts = value;
  }
  get reporterEmail(): string | undefined {
    return this._reporterEmail;
  }
  set reporterEmail(value?: string) {
    this._reporterEmail = value;
  }
  get reporterAlias(): string | undefined {
    return this._reporterAlias;
  }
  set reporterAlias(value?: string) {
    this._reporterAlias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Issue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Issue.AsObject {
    return {
      summary: this.summary ? this.summary.toObject() : undefined,
      context: this.context ? this.context.toObject() : undefined,
      screenshotBlobId: this.screenshotBlobId,
      activityContexts: (this.activityContexts || []).map(m => m.toObject()),
      reporterEmail: this.reporterEmail,
      reporterAlias: this.reporterAlias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Issue.AsProtobufJSON {
    return {
      summary: this.summary ? this.summary.toProtobufJSON(options) : null,
      context: this.context ? this.context.toProtobufJSON(options) : null,
      screenshotBlobId:
        this.screenshotBlobId === null || this.screenshotBlobId === undefined
          ? null
          : this.screenshotBlobId,
      activityContexts: (this.activityContexts || []).map(m =>
        m.toProtobufJSON(options)
      ),
      reporterEmail:
        this.reporterEmail === null || this.reporterEmail === undefined
          ? null
          : this.reporterEmail,
      reporterAlias:
        this.reporterAlias === null || this.reporterAlias === undefined
          ? null
          : this.reporterAlias
    };
  }
}
export module Issue {
  /**
   * Standard JavaScript object representation for Issue
   */
  export interface AsObject {
    summary?: IssueSummary.AsObject;
    context?: IssueContext.AsObject;
    screenshotBlobId?: string;
    activityContexts?: ActivityIdContext.AsObject[];
    reporterEmail?: string;
    reporterAlias?: string;
  }

  /**
   * Protobuf JSON representation for Issue
   */
  export interface AsProtobufJSON {
    summary: IssueSummary.AsProtobufJSON | null;
    context: IssueContext.AsProtobufJSON | null;
    screenshotBlobId: string | null;
    activityContexts: ActivityIdContext.AsProtobufJSON[] | null;
    reporterEmail: string | null;
    reporterAlias: string | null;
  }
}

/**
 * Message implementation for api.issue.IssueSummary
 */
export class IssueSummary implements GrpcMessage {
  static id = 'api.issue.IssueSummary';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IssueSummary();
    IssueSummary.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IssueSummary) {
    _instance.issueId = _instance.issueId || '0';
    _instance.userId = _instance.userId || '0';
    _instance.type = _instance.type || 0;
    _instance.createdTime = _instance.createdTime || undefined;
    _instance.lastUpdateTime = _instance.lastUpdateTime || undefined;
    _instance.consentToContactUser = _instance.consentToContactUser || false;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.state = _instance.state || 0;
    _instance.comments = _instance.comments || [];
    _instance.resolution = _instance.resolution || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IssueSummary,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.issueId = _reader.readInt64String();
          break;
        case 2:
          _instance.userId = _reader.readInt64String();
          break;
        case 3:
          _instance.type = _reader.readEnum();
          break;
        case 4:
          _instance.createdTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.lastUpdateTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdateTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.consentToContactUser = _reader.readBool();
          break;
        case 7:
          _instance.title = _reader.readString();
          break;
        case 8:
          _instance.description = _reader.readString();
          break;
        case 9:
          _instance.state = _reader.readEnum();
          break;
        case 10:
          const messageInitializer10 = new shared001.IssueComment();
          _reader.readMessage(
            messageInitializer10,
            shared001.IssueComment.deserializeBinaryFromReader
          );
          (_instance.comments = _instance.comments || []).push(
            messageInitializer10
          );
          break;
        case 11:
          _instance.resolution = new shared001.IssueResolution();
          _reader.readMessage(
            _instance.resolution,
            shared001.IssueResolution.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IssueSummary.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IssueSummary,
    _writer: BinaryWriter
  ) {
    if (_instance.issueId) {
      _writer.writeInt64String(1, _instance.issueId);
    }
    if (_instance.userId) {
      _writer.writeInt64String(2, _instance.userId);
    }
    if (_instance.type) {
      _writer.writeEnum(3, _instance.type);
    }
    if (_instance.createdTime) {
      _writer.writeMessage(
        4,
        _instance.createdTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastUpdateTime) {
      _writer.writeMessage(
        5,
        _instance.lastUpdateTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.consentToContactUser) {
      _writer.writeBool(6, _instance.consentToContactUser);
    }
    if (_instance.title) {
      _writer.writeString(7, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(8, _instance.description);
    }
    if (_instance.state) {
      _writer.writeEnum(9, _instance.state);
    }
    if (_instance.comments && _instance.comments.length) {
      _writer.writeRepeatedMessage(
        10,
        _instance.comments as any,
        shared001.IssueComment.serializeBinaryToWriter
      );
    }
    if (_instance.resolution) {
      _writer.writeMessage(
        11,
        _instance.resolution as any,
        shared001.IssueResolution.serializeBinaryToWriter
      );
    }
  }

  private _issueId: string;
  private _userId: string;
  private _type: shared001.IssueType;
  private _createdTime?: googleProtobuf000.Timestamp;
  private _lastUpdateTime?: googleProtobuf000.Timestamp;
  private _consentToContactUser: boolean;
  private _title: string;
  private _description: string;
  private _state: shared001.IssueState;
  private _comments?: shared001.IssueComment[];
  private _resolution?: shared001.IssueResolution;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IssueSummary to deeply clone from
   */
  constructor(_value?: RecursivePartial<IssueSummary.AsObject>) {
    _value = _value || {};
    this.issueId = _value.issueId;
    this.userId = _value.userId;
    this.type = _value.type;
    this.createdTime = _value.createdTime
      ? new googleProtobuf000.Timestamp(_value.createdTime)
      : undefined;
    this.lastUpdateTime = _value.lastUpdateTime
      ? new googleProtobuf000.Timestamp(_value.lastUpdateTime)
      : undefined;
    this.consentToContactUser = _value.consentToContactUser;
    this.title = _value.title;
    this.description = _value.description;
    this.state = _value.state;
    this.comments = (_value.comments || []).map(
      m => new shared001.IssueComment(m)
    );
    this.resolution = _value.resolution
      ? new shared001.IssueResolution(_value.resolution)
      : undefined;
    IssueSummary.refineValues(this);
  }
  get issueId(): string {
    return this._issueId;
  }
  set issueId(value: string) {
    this._issueId = value;
  }
  get userId(): string {
    return this._userId;
  }
  set userId(value: string) {
    this._userId = value;
  }
  get type(): shared001.IssueType {
    return this._type;
  }
  set type(value: shared001.IssueType) {
    this._type = value;
  }
  get createdTime(): googleProtobuf000.Timestamp | undefined {
    return this._createdTime;
  }
  set createdTime(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTime = value;
  }
  get lastUpdateTime(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdateTime;
  }
  set lastUpdateTime(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdateTime = value;
  }
  get consentToContactUser(): boolean {
    return this._consentToContactUser;
  }
  set consentToContactUser(value: boolean) {
    this._consentToContactUser = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get state(): shared001.IssueState {
    return this._state;
  }
  set state(value: shared001.IssueState) {
    this._state = value;
  }
  get comments(): shared001.IssueComment[] | undefined {
    return this._comments;
  }
  set comments(value: shared001.IssueComment[] | undefined) {
    this._comments = value;
  }
  get resolution(): shared001.IssueResolution | undefined {
    return this._resolution;
  }
  set resolution(value: shared001.IssueResolution | undefined) {
    this._resolution = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IssueSummary.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IssueSummary.AsObject {
    return {
      issueId: this.issueId,
      userId: this.userId,
      type: this.type,
      createdTime: this.createdTime ? this.createdTime.toObject() : undefined,
      lastUpdateTime: this.lastUpdateTime
        ? this.lastUpdateTime.toObject()
        : undefined,
      consentToContactUser: this.consentToContactUser,
      title: this.title,
      description: this.description,
      state: this.state,
      comments: (this.comments || []).map(m => m.toObject()),
      resolution: this.resolution ? this.resolution.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IssueSummary.AsProtobufJSON {
    return {
      issueId: this.issueId,
      userId: this.userId,
      type:
        shared001.IssueType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      createdTime: this.createdTime
        ? this.createdTime.toProtobufJSON(options)
        : null,
      lastUpdateTime: this.lastUpdateTime
        ? this.lastUpdateTime.toProtobufJSON(options)
        : null,
      consentToContactUser: this.consentToContactUser,
      title: this.title,
      description: this.description,
      state:
        shared001.IssueState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      comments: (this.comments || []).map(m => m.toProtobufJSON(options)),
      resolution: this.resolution
        ? this.resolution.toProtobufJSON(options)
        : null
    };
  }
}
export module IssueSummary {
  /**
   * Standard JavaScript object representation for IssueSummary
   */
  export interface AsObject {
    issueId: string;
    userId: string;
    type: shared001.IssueType;
    createdTime?: googleProtobuf000.Timestamp.AsObject;
    lastUpdateTime?: googleProtobuf000.Timestamp.AsObject;
    consentToContactUser: boolean;
    title: string;
    description: string;
    state: shared001.IssueState;
    comments?: shared001.IssueComment.AsObject[];
    resolution?: shared001.IssueResolution.AsObject;
  }

  /**
   * Protobuf JSON representation for IssueSummary
   */
  export interface AsProtobufJSON {
    issueId: string;
    userId: string;
    type: string;
    createdTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    lastUpdateTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    consentToContactUser: boolean;
    title: string;
    description: string;
    state: string;
    comments: shared001.IssueComment.AsProtobufJSON[] | null;
    resolution: shared001.IssueResolution.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.issue.IssueContext
 */
export class IssueContext implements GrpcMessage {
  static id = 'api.issue.IssueContext';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IssueContext();
    IssueContext.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IssueContext) {
    _instance.url = _instance.url || '';

    _instance.activityIds = _instance.activityIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IssueContext,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.url = _reader.readString();
          break;
        case 3:
          _instance.userAgent = _reader.readString();
          break;
        case 4:
          (_instance.activityIds = _instance.activityIds || []).push(
            ...(_reader.readPackedInt64String() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IssueContext.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IssueContext,
    _writer: BinaryWriter
  ) {
    if (_instance.url) {
      _writer.writeString(1, _instance.url);
    }
    if (_instance.userAgent !== undefined && _instance.userAgent !== null) {
      _writer.writeString(3, _instance.userAgent);
    }
    if (_instance.activityIds && _instance.activityIds.length) {
      _writer.writePackedInt64String(4, _instance.activityIds);
    }
  }

  private _url: string;
  private _userAgent?: string;
  private _activityIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IssueContext to deeply clone from
   */
  constructor(_value?: RecursivePartial<IssueContext.AsObject>) {
    _value = _value || {};
    this.url = _value.url;
    this.userAgent = _value.userAgent;
    this.activityIds = (_value.activityIds || []).slice();
    IssueContext.refineValues(this);
  }
  get url(): string {
    return this._url;
  }
  set url(value: string) {
    this._url = value;
  }
  get userAgent(): string | undefined {
    return this._userAgent;
  }
  set userAgent(value?: string) {
    this._userAgent = value;
  }
  get activityIds(): string[] {
    return this._activityIds;
  }
  set activityIds(value: string[]) {
    this._activityIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IssueContext.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IssueContext.AsObject {
    return {
      url: this.url,
      userAgent: this.userAgent,
      activityIds: (this.activityIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IssueContext.AsProtobufJSON {
    return {
      url: this.url,
      userAgent:
        this.userAgent === null || this.userAgent === undefined
          ? null
          : this.userAgent,
      activityIds: (this.activityIds || []).slice()
    };
  }
}
export module IssueContext {
  /**
   * Standard JavaScript object representation for IssueContext
   */
  export interface AsObject {
    url: string;
    userAgent?: string;
    activityIds: string[];
  }

  /**
   * Protobuf JSON representation for IssueContext
   */
  export interface AsProtobufJSON {
    url: string;
    userAgent: string | null;
    activityIds: string[];
  }
}

/**
 * Message implementation for api.issue.ActivityIdContext
 */
export class ActivityIdContext implements GrpcMessage {
  static id = 'api.issue.ActivityIdContext';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityIdContext();
    ActivityIdContext.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityIdContext) {
    _instance.activityId = _instance.activityId || '0';
    _instance.alias = _instance.alias || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityIdContext,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.activityId = _reader.readInt64String();
          break;
        case 2:
          _instance.alias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityIdContext.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityIdContext,
    _writer: BinaryWriter
  ) {
    if (_instance.activityId) {
      _writer.writeInt64String(1, _instance.activityId);
    }
    if (_instance.alias) {
      _writer.writeString(2, _instance.alias);
    }
  }

  private _activityId: string;
  private _alias: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityIdContext to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityIdContext.AsObject>) {
    _value = _value || {};
    this.activityId = _value.activityId;
    this.alias = _value.alias;
    ActivityIdContext.refineValues(this);
  }
  get activityId(): string {
    return this._activityId;
  }
  set activityId(value: string) {
    this._activityId = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityIdContext.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityIdContext.AsObject {
    return {
      activityId: this.activityId,
      alias: this.alias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityIdContext.AsProtobufJSON {
    return {
      activityId: this.activityId,
      alias: this.alias
    };
  }
}
export module ActivityIdContext {
  /**
   * Standard JavaScript object representation for ActivityIdContext
   */
  export interface AsObject {
    activityId: string;
    alias: string;
  }

  /**
   * Protobuf JSON representation for ActivityIdContext
   */
  export interface AsProtobufJSON {
    activityId: string;
    alias: string;
  }
}
