/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './garmin-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import { GRPC_GARMIN_SERVICE_CLIENT_SETTINGS } from './garmin-service.pbconf';
/**
 * Service client implementation for api.garmin.GarminService
 */
@Injectable({ providedIn: 'any' })
export class GarminServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.garmin.GarminService/StartLogin
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.StartLogInResponse>>
     */
    startLogin: (
      requestData: thisProto.StartLogInRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.StartLogInResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.garmin.GarminService/StartLogin',
        requestData,
        requestMetadata,
        requestClass: thisProto.StartLogInRequest,
        responseClass: thisProto.StartLogInResponse
      });
    },
    /**
     * Unary call: /api.garmin.GarminService/CompleteLogin
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CompleteLogInResponse>>
     */
    completeLogin: (
      requestData: thisProto.CompleteLogInRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CompleteLogInResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.garmin.GarminService/CompleteLogin',
        requestData,
        requestMetadata,
        requestClass: thisProto.CompleteLogInRequest,
        responseClass: thisProto.CompleteLogInResponse
      });
    },
    /**
     * Unary call: /api.garmin.GarminService/GetLoginStatus
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetLoginStatusResponse>>
     */
    getLoginStatus: (
      requestData: thisProto.GetLoginStatusRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetLoginStatusResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.garmin.GarminService/GetLoginStatus',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetLoginStatusRequest,
        responseClass: thisProto.GetLoginStatusResponse
      });
    },
    /**
     * Unary call: /api.garmin.GarminService/LogOut
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.LogOutResponse>>
     */
    logOut: (
      requestData: thisProto.LogOutRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.LogOutResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.garmin.GarminService/LogOut',
        requestData,
        requestMetadata,
        requestClass: thisProto.LogOutRequest,
        responseClass: thisProto.LogOutResponse
      });
    },
    /**
     * Unary call: /api.garmin.GarminService/GetBackfillStatus
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetBackfillStatusResponse>>
     */
    getBackfillStatus: (
      requestData: thisProto.GetBackfillStatusRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetBackfillStatusResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.garmin.GarminService/GetBackfillStatus',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBackfillStatusRequest,
        responseClass: thisProto.GetBackfillStatusResponse
      });
    },
    /**
     * Unary call: /api.garmin.GarminService/RequestBackfill
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RequestBackfillResponse>>
     */
    requestBackfill: (
      requestData: thisProto.RequestBackfillRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RequestBackfillResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.garmin.GarminService/RequestBackfill',
        requestData,
        requestMetadata,
        requestClass: thisProto.RequestBackfillRequest,
        responseClass: thisProto.RequestBackfillResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_GARMIN_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.garmin.GarminService',
      settings
    );
  }

  /**
   * Unary call @/api.garmin.GarminService/StartLogin
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.StartLogInResponse>
   */
  startLogin(
    requestData: thisProto.StartLogInRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.StartLogInResponse> {
    return this.$raw
      .startLogin(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.garmin.GarminService/CompleteLogin
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CompleteLogInResponse>
   */
  completeLogin(
    requestData: thisProto.CompleteLogInRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CompleteLogInResponse> {
    return this.$raw
      .completeLogin(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.garmin.GarminService/GetLoginStatus
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetLoginStatusResponse>
   */
  getLoginStatus(
    requestData: thisProto.GetLoginStatusRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetLoginStatusResponse> {
    return this.$raw
      .getLoginStatus(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.garmin.GarminService/LogOut
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.LogOutResponse>
   */
  logOut(
    requestData: thisProto.LogOutRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.LogOutResponse> {
    return this.$raw
      .logOut(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.garmin.GarminService/GetBackfillStatus
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetBackfillStatusResponse>
   */
  getBackfillStatus(
    requestData: thisProto.GetBackfillStatusRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetBackfillStatusResponse> {
    return this.$raw
      .getBackfillStatus(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.garmin.GarminService/RequestBackfill
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RequestBackfillResponse>
   */
  requestBackfill(
    requestData: thisProto.RequestBackfillRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RequestBackfillResponse> {
    return this.$raw
      .requestBackfill(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
