import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CookiesComponent } from './cookies/cookies.component';
import { LegalComponent } from './legal.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
  {
    path: 'legal',
    component: LegalComponent,
    title: 'Jestle - Legal',
    children: [
      {
        path: 'privacy-policy',
        component: PrivacyComponent,
        title: 'Jestle - Legal - Privacy',
      },
      {
        path: 'cookies-policy',
        component: CookiesComponent,
        title: 'Jestle - Legal - Cookies',
      },
      {
        path: 'terms-of-service',
        component: TermsComponent,
        title: 'Jestle - Legal - Terms',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LegalRoutingModule {}
