<div class="contents" [ngStyle]="{ width: dialogWidth + 'px' }">
  <div mat-dialog-title>{{ readOnly ? "View" : "Edit" }}</div>
  <div mat-dialog-content>
    <!-- User details -->
    <div *ngIf="!data!.ownedEvent" class="name-container">
      <b>Created By: </b
      ><span class="name">{{
        data?.eventUserFirstName + " " + data?.eventUserLastName
      }}</span>
      <span class="alias">{{ " (@" + data?.eventUserAlias + ")" }}</span>
    </div>

    <!-- Title -->
    <mat-form-field
      class="field"
      [floatLabel]="'always'"
      [ngStyle]="{ width: titleWidth + 'px' }"
    >
      <mat-label>Title</mat-label>
      <input matInput required [(ngModel)]="title" readonly="{{ readOnly }}" />
    </mat-form-field>

    <!-- Description -->
    <app-edit-rtc
      class="field"
      [readOnly]="readOnly"
      [(textHtml)]="descriptionHtml"
      [width]="descriptionWidth"
    ></app-edit-rtc>

    <div>
      <!-- Start date -->
      <mat-form-field class="field date" [floatLabel]="'always'">
        <mat-label>Start Date</mat-label>
        <input
          matInput
          required
          [matDatepicker]="startDatePicker"
          readonly
          [(ngModel)]="startDate"
          (ngModelChange)="onStartDateChange()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
          [disabled]="readOnly"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker [disabled]="false"></mat-datepicker>
      </mat-form-field>

      <!-- Start time -->
      <mat-form-field class="field time" [floatLabel]="'always'">
        <mat-label>Start Time</mat-label>
        <input
          matInput
          required
          [ngxTimepicker]="startTimePicker"
          [disableClick]="true"
          readonly
          [(ngModel)]="startTime"
          [disabled]="allDay"
          (ngModelChange)="onStartTimeChange()"
        />
        <ngx-material-timepicker-toggle
          matSuffix
          [for]="startTimePicker"
          [disabled]="readOnly"
        ></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #startTimePicker></ngx-material-timepicker>
      </mat-form-field>
    </div>

    <div>
      <!-- End date -->
      <mat-form-field class="field date" [floatLabel]="'always'">
        <mat-label>End Date</mat-label>
        <input
          matInput
          required
          [matDatepicker]="endDatePicker"
          readonly
          [(ngModel)]="endDate"
          [min]="startDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
          [disabled]="readOnly"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker [disabled]="false"></mat-datepicker>
      </mat-form-field>

      <!-- End time -->
      <mat-form-field class="field time" [floatLabel]="'always'">
        <mat-label>End Time</mat-label>
        <input
          matInput
          required
          appCalEndTimeValidator
          [appCalEndTimeValidatorDialog]="this"
          [ngxTimepicker]="endTimePicker"
          [disableClick]="true"
          readonly
          [(ngModel)]="endTime"
          [disabled]="allDay"
        />
        <ngx-material-timepicker-toggle
          matSuffix
          [for]="endTimePicker"
          [disabled]="readOnly"
        ></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #endTimePicker></ngx-material-timepicker>
      </mat-form-field>
    </div>

    <!-- All day -->
    <mat-checkbox class="field" [(ngModel)]="allDay" [disabled]="readOnly"
      >All day?</mat-checkbox
    >
  </div>
  <div *ngIf="!readOnly" mat-dialog-actions>
    <button
      mat-raised-button
      class="cancel"
      (click)="dialogRef.close(undefined)"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="accent"
      class="save"
      [disabled]="isSaveDisabled"
      (click)="save()"
    >
      Save
    </button>
  </div>
  <div *ngIf="readOnly" mat-dialog-actions>
    <button mat-raised-button class="save" (click)="dialogRef.close(undefined)">
      Cancel
    </button>
  </div>
</div>
