import { Component } from '@angular/core';
import { AppConfigProvider } from 'src/environments/app-config';
import { DeviceDetectorService } from '../services/device-detector.service';
import { FormatService } from '../services/format.service';
import { HOME_CONTENTS } from './home.content';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  isMobile: boolean;
  viewWidth: number;
  baseImageUrl: string;
  contents = HOME_CONTENTS;

  constructor(
    deviceDetectorService: DeviceDetectorService,
    formatService: FormatService,
  ) {
    this.isMobile = deviceDetectorService.isMobile();

    const margin = 7;
    const viewWidth = this.isMobile
      ? formatService.viewWidth
      : Math.round(0.8 * window.innerWidth);
    this.viewWidth = viewWidth - 2 * margin;
    this.baseImageUrl = AppConfigProvider.config.contentBucketUrl + '/content/';
  }
}
