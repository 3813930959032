import { formatDate, formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { ActivityType } from 'generated/src/main/proto/shared/activity-shared.pb';
import { Sex } from 'generated/src/main/proto/shared/user-shared.pb';
import BiDirectionalMap from 'ts-bidirectional-map';
import { SeriesType } from '../common/charts/types';
import { DeviceDetectorService } from './device-detector.service';
import { UnitPreference, UserService } from './user/user.service';

export type OptionalNumber = number | '-';

export enum Unit {
  Count = '',
  Meter = 'm',
  Kilometer = 'km',
  Foot = 'ft',
  Yard = 'yd',
  Mile = 'mi',
  MeterPerSecond = 'm/s',
  KilometersPerHour = 'km/h',
  FeetPerSecond = 'ft/s',
  MilesPerHour = 'mph',
  Seconds = 's',
  Milliseconds = 'ms',
  Date = 'date',
  BeatsPerMinute = 'bpm',
  Calories = 'c',
  MinutesPerMile = 'min/mi',
  MinutesPerKilometer = 'min/km',
  Hours = 'hrs',
  Percent = '%',
}

export type Measurement = {
  unit: Unit;
  value: number;
  title: string;
  shortTitle: string;
  text: string;
  description: string;
};

enum UnitConstants {
  MetersPerFoot = 0.3048,
  MetersPerYard = 3 * MetersPerFoot,
  MetersPerMile = 1609.34,
  SecondsPerHour = 3600,
  MillisInDay = 24 * 3600 * 1000,
  KilogramsPerPound = 0.453592,
}

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  private static readonly _ACTIVITY_TYPE_MAP: BiDirectionalMap<
    ActivityType,
    string
  > = FormatService.initActivityTypeMap();

  private static readonly _SLOW_SPEED_ACTIVTY_TYPES = new Set([
    ActivityType.ACTIVITY_TYPE_OPEN_SWIM,
    ActivityType.ACTIVITY_TYPE_POOL_SWIM,
  ]);
  private unitPreference!: UnitPreference;

  readonly viewWidth: number;
  readonly scrollBarWidth: number;

  constructor(
    userService: UserService,
    @Inject(LOCALE_ID) private locale: string,
    deviceDetectorService: DeviceDetectorService,
  ) {
    this.viewWidth = deviceDetectorService.isMobile() ? window.innerWidth : 700;
    userService.user.subscribe(
      (user) =>
        (this.unitPreference = user?.unitPreference ?? UnitPreference.Imperial),
    );

    // Measure scroll bar.
    const scrollDiv = document.createElement('div');
    scrollDiv.style.overflowY = 'scroll';
    document.body.appendChild(scrollDiv);
    this.scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
  }

  getUnitPreference(): UnitPreference {
    return this.unitPreference;
  }

  /** Returns {@class Date} format. */
  getDateDisplayFormat(): string {
    const s = new Date(1111, 8, 11).toLocaleDateString();
    return s.replace('1111', 'yyyy').replace('9', 'MM').replace('11', 'dd');
  }

  formatDateForInput(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', this.locale);
  }

  parseDateFromInput(dateString: string): Date {
    const parts = dateString.split('-');
    return new Date(
      parseInt(parts[0]),
      parseInt(parts[1]) - 1,
      parseInt(parts[2]),
    );
  }

  formatDate(timestamp: Timestamp): string {
    if (!timestamp) {
      return '';
    }
    return formatDate(timestamp.toDate(), 'short', this.locale);
  }

  formatJsDate(date: Date): string {
    if (!date) {
      return '';
    }
    return formatDate(date, 'short', this.locale);
  }

  formatDateSinceTodayWithSuffix(timestamp: Timestamp): string {
    let text = this.formatDateSinceToday(timestamp);
    if (text != 'today') {
      text += ' ago';
    }
    return text;
  }

  formatDateSinceToday(timestamp: Timestamp): string {
    const now = new Date();
    const then = timestamp.toDate();

    let days = Math.floor(
      (now.getTime() - then.getTime()) / UnitConstants.MillisInDay,
    );

    if (days == 0) {
      if (now.getDay() == then.getDay()) {
        return 'today';
      } else {
        days += 1;
      }
    }
    if (days >= 100) {
      return '100d+';
    }

    return `${days}d`;
  }

  formatMonth(date: Date): string {
    if (date.getMonth() > 0) {
      return formatDate(date, 'MMM', this.locale);
    } else {
      return formatDate(date, 'MMM yy`', this.locale);
    }
  }

  formatWeek(date: Date): string {
    if (date.getMonth() > 0) {
      return formatDate(date, "MMM, W'w'", this.locale);
    } else {
      return formatDate(date, "MMM, W'w' yy`", this.locale);
    }
  }

  formatNumber(value: number): string {
    return formatNumber(value, this.locale);
  }

  formatHeartRate(value: number): Measurement {
    value = Math.round(value);
    return {
      value: value,
      title: 'Heart Rate',
      shortTitle: 'HR',
      text: formatNumber(value, this.locale) + ' ' + Unit.BeatsPerMinute,
      unit: Unit.BeatsPerMinute,
      description: 'Heart rate in bpm.',
    };
  }

  convertHeartRates(values: OptionalNumber[]): OptionalNumber[] {
    return values.map((v) => {
      if (v == '-') {
        return v;
      }
      return Math.round(v);
    });
  }

  get heartRateUnit(): Unit {
    return Unit.BeatsPerMinute;
  }

  formatGrade(value: number): Measurement {
    value = Math.round(10 * value) / 10;
    return {
      value: value,
      title: 'Grade',
      shortTitle: 'Grade',
      text: formatNumber(value, this.locale) + Unit.Percent,
      unit: Unit.Percent,
      description: 'Slope grade.',
    };
  }

  formatPercent(title: string, value: number): Measurement {
    value = Math.round(10 * value) / 10;
    return {
      value: value,
      title: title,
      shortTitle: title,
      text: formatNumber(value, this.locale) + Unit.Percent,
      unit: Unit.Percent,
      description: title,
    };
  }

  convertMovingTime(values: number[]): number[] {
    return values.map((v) =>
      FormatService.round(v / UnitConstants.SecondsPerHour, 1),
    );
  }

  get movingTimeUnit(): Unit {
    return Unit.Hours;
  }

  formatCalories(value: number): Measurement {
    value = Math.round(value);
    return {
      value: value,
      title: 'Calories',
      shortTitle: 'Cal.',
      text: formatNumber(value, this.locale),
      unit: Unit.Calories,
      description: 'Calories used.',
    };
  }

  convertCalories(values: number[]): number[] {
    return values.map((v) => Math.round(v));
  }

  get caloriesUnit(): Unit {
    return Unit.Calories;
  }

  formatDistanceByActivityType(
    value: number,
    activityType: ActivityType,
  ): Measurement {
    if (activityType == ActivityType.ACTIVITY_TYPE_POOL_SWIM) {
      return this.formatPoolSwimDistance(value);
    } else {
      return this.formatDistance(value);
    }
  }

  formatDistance(value: number): Measurement {
    let unit: Unit;
    let digits: number;
    if (this.unitPreference == UnitPreference.Metric) {
      if (value < 1000) {
        unit = Unit.Meter;
        digits = 0;
      } else {
        value /= 1000;
        unit = Unit.Kilometer;
        digits = 1;
      }
    } else {
      const valueMiles = value / UnitConstants.MetersPerMile;
      if (valueMiles < 0.2) {
        value = value / UnitConstants.MetersPerFoot;
        unit = Unit.Foot;
        digits = 0;
      } else {
        value = valueMiles;
        unit = Unit.Mile;
        digits = 1;
      }
    }
    return {
      value: value,
      unit: unit,
      title: 'Distance',
      shortTitle: 'Distance',
      text:
        formatNumber(value, this.locale, `0.${digits}-${digits}`) + ' ' + unit,
      description: 'Distance from start of the activity.',
    };
  }

  formatPoolSwimDistance(value: number): Measurement {
    let unit: Unit;
    let digits: number;

    // Check what pool length is better aligned with the distance.
    const meters = value;
    const roundedMeters = Math.round(meters / 25) * 25;
    const yards = value / UnitConstants.MetersPerYard;
    const roundedYards = Math.round(value / 25) * 25;
    if (
      Math.abs(meters - roundedMeters) / meters <
      Math.abs(yards - roundedYards) / yards
    ) {
      unit = Unit.Meter;
      digits = 0;
      value = roundedMeters;
    } else {
      unit = Unit.Yard;
      digits = 0;
      value = roundedYards;
    }

    return {
      value: value,
      unit: unit,
      title: 'Distance',
      shortTitle: 'Distance',
      text:
        formatNumber(value, this.locale, `0.${digits}-${digits}`) + ' ' + unit,
      description: 'Distance from start.',
    };
  }

  convertDistances(values: number[]): number[] {
    if (this.unitPreference == UnitPreference.Metric) {
      return values.map((v) => FormatService.round(v / 1000, 1));
    } else {
      return values.map((v) =>
        FormatService.round(v / UnitConstants.MetersPerMile, 1),
      );
    }
  }

  convertDistanceToMeters(value: number, mileOrKm: boolean): number {
    if (mileOrKm) {
      return FormatService.round(value * UnitConstants.MetersPerMile, 1);
    } else {
      return FormatService.round(value * 1000, 1);
    }
  }

  get distanceUnit(): Unit {
    return this.unitPreference == UnitPreference.Metric
      ? Unit.Kilometer
      : Unit.Mile;
  }

  formatSpeed(
    activityType: ActivityType | undefined,
    value: number,
  ): Measurement {
    if (!activityType) {
      throw 'Activity type must be defined.';
    }
    let unit: Unit;
    let digits: number;
    if (this.unitPreference == UnitPreference.Metric) {
      if (FormatService._SLOW_SPEED_ACTIVTY_TYPES.has(activityType)) {
        unit = Unit.MeterPerSecond;
        digits = 0;
      } else {
        value *= UnitConstants.SecondsPerHour / 1000;
        unit = Unit.KilometersPerHour;
        digits = 1;
      }
    } else {
      if (FormatService._SLOW_SPEED_ACTIVTY_TYPES.has(activityType)) {
        value = value / UnitConstants.MetersPerFoot;
        unit = Unit.FeetPerSecond;
        digits = 0;
      } else {
        value =
          (value / UnitConstants.MetersPerMile) * UnitConstants.SecondsPerHour;
        unit = Unit.MilesPerHour;
        digits = 1;
      }
    }
    return {
      value: value,
      unit: unit,
      title: 'Speed',
      shortTitle: 'Speed',
      text:
        formatNumber(value, this.locale, `0.${digits}-${digits}`) + ' ' + unit,
      description: 'Speed.',
    };
  }

  convertSpeeds(values: OptionalNumber[]): OptionalNumber[] {
    if (this.unitPreference == UnitPreference.Metric) {
      return values.map((v) => {
        if (v == '-') {
          return v;
        }
        return FormatService.round(
          (UnitConstants.SecondsPerHour * v) / 1000,
          1,
        );
      });
    } else {
      return values.map((v) => {
        if (v == '-') {
          return v;
        }
        return FormatService.round(
          (UnitConstants.SecondsPerHour * v) / UnitConstants.MetersPerMile,
          1,
        );
      });
    }
  }

  get speedUnit(): Unit {
    return this.unitPreference == UnitPreference.Metric
      ? Unit.KilometersPerHour
      : Unit.MilesPerHour;
  }

  formatAltitude(value: number): Measurement {
    let unit: Unit;
    let digits: number;
    if (this.unitPreference == UnitPreference.Metric) {
      unit = Unit.Meter;
      digits = 0;
    } else {
      unit = Unit.Foot;
      value = Math.round(value / UnitConstants.MetersPerFoot);
      digits = 0;
    }
    return {
      value: value,
      unit: unit,
      title: 'Altitude',
      shortTitle: 'Alt.',
      text:
        formatNumber(value, this.locale, `0.${digits}-${digits}`) + ' ' + unit,
      description: 'Altitude above sea level.',
    };
  }

  formatElevation(value: number): Measurement {
    let unit: Unit;
    let digits: number;
    if (this.unitPreference == UnitPreference.Metric) {
      unit = Unit.Meter;
      digits = 0;
    } else {
      unit = Unit.Foot;
      value = Math.round(value / UnitConstants.MetersPerFoot);
      digits = 0;
    }
    return {
      value: value,
      unit: unit,
      title: value > 0 ? 'Elevation gain' : 'Elevation drop',
      shortTitle: value > 0 ? 'Elev gain' : 'Elev drop',
      text:
        formatNumber(value, this.locale, `0.${digits}-${digits}`) + ' ' + unit,
      description: value > 0 ? 'Elevation gain.' : 'Elevation drop.',
    };
  }

  convertElevations(values: number[]): number[] {
    if (this.unitPreference == UnitPreference.Metric) {
      return values.map((v) => Math.round(v));
    } else {
      return values.map((v) => Math.round(v / UnitConstants.MetersPerFoot));
    }
  }

  convertElevationBack(value: number): number {
    if (this.unitPreference == UnitPreference.Metric) {
      return value;
    } else {
      return Math.round(value * UnitConstants.MetersPerFoot);
    }
  }

  get elevationUnit(): Unit {
    return this.unitPreference == UnitPreference.Metric
      ? Unit.Meter
      : Unit.Foot;
  }

  formatPace(value: number, milesOrKm: boolean): Measurement {
    let unit: Unit;
    if (milesOrKm) {
      unit = Unit.MinutesPerMile;
    } else {
      unit = Unit.MinutesPerKilometer;
    }
    const minutes = Math.trunc(value);
    const seconds = Math.trunc(60 * (value - minutes));
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = seconds.toString().padStart(2, '0');
    return {
      value: value,
      unit: unit,
      title: milesOrKm ? 'Mile pace' : 'Km pace',
      shortTitle: milesOrKm ? 'Mile pace' : 'Km pace',
      text: `${minutesStr}:${secondsStr} ${unit}`,
      description: milesOrKm ? 'Minutes per mile.' : 'Minutes per km.',
    };
  }

  convertPaces(values: OptionalNumber[]): OptionalNumber[] {
    return values.map((v) => {
      if (v == '-') {
        return v;
      }
      return FormatService.round(v, 1);
    });
  }

  getPaceUnit(mileOrKm: boolean): Unit {
    return mileOrKm ? Unit.MinutesPerMile : Unit.MinutesPerKilometer;
  }

  formatDateToMeasurement(date?: Date): Measurement {
    if (!date) {
      return {
        value: 0,
        unit: Unit.Date,
        title: 'unknown',
        shortTitle: 'unknown',
        text: 'unknown',
        description: 'undefined',
      };
    }
    return {
      value: date.getTime(),
      unit: Unit.Date,
      title: 'Date',
      shortTitle: 'Date',
      text: formatDate(date, 'short', this.locale),
      description: 'Date.',
    };
  }

  formatDateOnlyToMeasurement(date?: Date): Measurement {
    if (!date) {
      return {
        value: 0,
        unit: Unit.Date,
        title: 'unknown',
        shortTitle: 'unknown',
        text: 'unknown',
        description: 'undefined',
      };
    }
    return {
      value: date.getTime(),
      unit: Unit.Date,
      title: 'Date',
      shortTitle: 'Date',
      text: formatDate(date, 'shortDate', this.locale),
      description: 'Date.',
    };
  }

  static formatDuration(
    startTime: Timestamp | undefined,
    endTime: Timestamp | undefined,
  ): Measurement {
    if (!startTime || !endTime) {
      throw 'Timestamps must be set.';
    }
    const millis = endTime.toDate().getTime() - startTime.toDate().getTime();
    return this.formatDurationSeconds(millis / 1000);
  }

  static formatDurationSeconds(seconds: number): Measurement {
    seconds = Math.round(seconds);
    const minutes = seconds / 60;
    const haveHours = minutes > 60;
    const haveDays = minutes > 24 * 60;

    const minutesRounded = haveHours
      ? Math.round(minutes)
      : Math.trunc(minutes);
    const hours = Math.trunc(minutesRounded / 60);
    const daysStr = Math.trunc(hours / 24).toString();
    const hoursStr = (hours % 24).toString();
    const secondsStr = (seconds % 60).toString().padStart(2, '0');
    const minutesStr = (minutesRounded % 60).toString().padStart(2, '0');
    let text: string;
    if (haveDays) {
      text = `${daysStr}d ${hoursStr}h ${minutesStr}m`;
    } else if (haveHours) {
      text = `${hoursStr}h ${minutesStr}m`;
    } else {
      text = `${minutesStr}m ${secondsStr}s`;
    }
    return {
      value: seconds,
      unit: Unit.Seconds,
      title: 'Duration',
      shortTitle: 'Duration',
      text: text,
      description: 'Duration of the activity.',
    };
  }

  static activityTypes(): Array<number> {
    const values: Array<number> = [];
    this._ACTIVITY_TYPE_MAP.ForwardMap.forEach((_, k) => values.push(k));
    return values;
  }

  static toDisplay(activityType: ActivityType): string {
    let result = FormatService._ACTIVITY_TYPE_MAP.ForwardMap.get(activityType);
    if (!result) {
      result = FormatService._ACTIVITY_TYPE_MAP.ForwardMap.get(
        ActivityType.ACTIVITY_TYPE_WORKOUT,
      );
    }
    return result!;
  }

  static toApi(activityType: string): ActivityType {
    let result = FormatService._ACTIVITY_TYPE_MAP.ReverseMap.get(activityType);
    if (!result) {
      result = ActivityType.ACTIVITY_TYPE_WORKOUT;
    }
    return result;
  }

  static sexToDisplay(value: Sex | undefined): string {
    if (!value) {
      return '';
    }
    const v = Sex[value].toString().substring('SEX_'.length);
    return v[0] + v.substring(1).toLowerCase();
  }

  static sexToApi(value: string): Sex {
    return Sex[('SEX_' + value.toUpperCase()) as keyof typeof Sex];
  }

  static initSexValues(): Array<string> {
    const result: Array<string> = [];
    for (let v in Sex) {
      if (v.startsWith('SEX_') && !v.endsWith('_UNSPECIFIED')) {
        v = v.substring('SEX_'.length);
        v = v[0] + v.substring(1).toLowerCase();
        result.push(v);
      }
    }
    return result;
  }

  static convertWeightToPounds(weightInKilograms: number): number {
    return FormatService.round(
      weightInKilograms / UnitConstants.KilogramsPerPound,
      1,
    );
  }

  static convertWeightToKilograms(weightInPounds: number): number {
    return FormatService.round(
      weightInPounds * UnitConstants.KilogramsPerPound,
      2,
    );
  }

  getUnit(seriesType: SeriesType): Unit {
    switch (seriesType) {
      case SeriesType.NumActivities:
        return Unit.Count;
      case SeriesType.Distance:
        return this.distanceUnit;
      case SeriesType.ElevationGain:
        return this.elevationUnit;
      case SeriesType.ElevationDrop:
        return this.elevationUnit;
      case SeriesType.Calories:
        return this.caloriesUnit;
      case SeriesType.AverageSpeed:
        return this.speedUnit;
      case SeriesType.MedianSpeed:
        return this.speedUnit;
      case SeriesType.MilePace:
        return this.getPaceUnit(true);
      case SeriesType.KilometerPace:
        return this.getPaceUnit(false);
      case SeriesType.AverageHeartRate:
        return this.heartRateUnit;
      case SeriesType.MedianHeartRate:
        return this.heartRateUnit;
      case SeriesType.Elevation:
        return this.elevationUnit;
      case SeriesType.HeartRate:
        return this.heartRateUnit;
      case SeriesType.Speed:
        return this.speedUnit;
      case SeriesType.MovingTime:
        return this.movingTimeUnit;
      case SeriesType.Grade:
        return Unit.Percent;
      case SeriesType.TimeInZone1:
        return this.movingTimeUnit;
      case SeriesType.TimeInZone2:
        return this.movingTimeUnit;
      case SeriesType.TimeInZone3:
        return this.movingTimeUnit;
      case SeriesType.TimeInZone4:
        return this.movingTimeUnit;
      case SeriesType.TimeInZone5:
        return this.movingTimeUnit;
      case SeriesType.PercentTimeInZone1:
        return Unit.Percent;
      case SeriesType.PercentTimeInZone2:
        return Unit.Percent;
      case SeriesType.PercentTimeInZone3:
        return Unit.Percent;
      case SeriesType.PercentTimeInZone4:
        return Unit.Percent;
      case SeriesType.PercentTimeInZone5:
        return Unit.Percent;
    }
  }

  private static initActivityTypeMap(): BiDirectionalMap<ActivityType, string> {
    const valueToIndex: { [key: number]: number } = {};
    const values = Object.values(ActivityType);
    const keys: Array<number> = [];
    for (let i = 0; i < values.length; i++) {
      const v = values[i];
      const n = Number(v);
      if (!isNaN(n) && n != 0) {
        valueToIndex[n] = i;
        keys.push(n);
      }
    }
    const map = new BiDirectionalMap<ActivityType, string>();
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const index = valueToIndex[key];
      const upperSnake = Object.keys(ActivityType)[index].substring(
        'ACTIVITY_TYPE_'.length,
      );
      const text = upperSnake
        .split('_')
        .map((s) => s[0] + s.substring(1).toLowerCase())
        .join(' ');
      map.set(key, text);
    }
    return map;
  }

  private static round(value: number, places: number): number {
    if (places < 0 || places > 5) {
      throw 'Unexpected places: ' + places.toString();
    }
    const pow = 10 ** places;
    return parseFloat((Math.round(value * pow) / pow).toFixed(places));
  }
}
