import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CookiesComponent } from './cookies.component';

@NgModule({
  declarations: [CookiesComponent],
  exports: [CookiesComponent],
  imports: [CommonModule],
})
export class CookiesModule {}
