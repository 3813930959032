/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum ReactionType {
  REACTION_TYPE_UNSPECIFIED = 0,
  REACTION_TYPE_LIKE = 1,
  REACTION_TYPE_HEART = 2,
  REACTION_TYPE_SATISFIED = 3,
  REACTION_TYPE_DISSATISFIED = 4
}
/**
 * Message implementation for shared.Reactions
 */
export class Reactions implements GrpcMessage {
  static id = 'shared.Reactions';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Reactions();
    Reactions.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Reactions) {
    _instance.reactionsByAlias = _instance.reactionsByAlias || {};
    _instance.commentsContainer = _instance.commentsContainer || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Reactions,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            Reactions.ReactionsByAliasEntry.deserializeBinaryFromReader
          );
          _instance.reactionsByAlias = _instance.reactionsByAlias || {};
          _instance.reactionsByAlias[msg_1.key] = msg_1.value;
          break;
        case 2:
          _instance.commentsContainer = new CommentsContainer();
          _reader.readMessage(
            _instance.commentsContainer,
            CommentsContainer.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Reactions.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Reactions, _writer: BinaryWriter) {
    if (!!_instance.reactionsByAlias) {
      const keys_1 = Object.keys(_instance.reactionsByAlias as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map(key => ({
            key: key,
            value: (_instance.reactionsByAlias as any)[key]
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          Reactions.ReactionsByAliasEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.commentsContainer) {
      _writer.writeMessage(
        2,
        _instance.commentsContainer as any,
        CommentsContainer.serializeBinaryToWriter
      );
    }
  }

  private _reactionsByAlias: { [prop: string]: Reaction };
  private _commentsContainer?: CommentsContainer;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Reactions to deeply clone from
   */
  constructor(_value?: RecursivePartial<Reactions.AsObject>) {
    _value = _value || {};
    (this.reactionsByAlias = _value!.reactionsByAlias
      ? Object.keys(_value!.reactionsByAlias).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.reactionsByAlias![k]
              ? new Reaction(_value!.reactionsByAlias![k])
              : undefined
          }),
          {}
        )
      : {}),
      (this.commentsContainer = _value.commentsContainer
        ? new CommentsContainer(_value.commentsContainer)
        : undefined);
    Reactions.refineValues(this);
  }
  get reactionsByAlias(): { [prop: string]: Reaction } {
    return this._reactionsByAlias;
  }
  set reactionsByAlias(value: { [prop: string]: Reaction }) {
    this._reactionsByAlias = value;
  }
  get commentsContainer(): CommentsContainer | undefined {
    return this._commentsContainer;
  }
  set commentsContainer(value: CommentsContainer | undefined) {
    this._commentsContainer = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Reactions.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Reactions.AsObject {
    return {
      reactionsByAlias: this.reactionsByAlias
        ? Object.keys(this.reactionsByAlias).reduce(
            (r, k) => ({
              ...r,
              [k]: this.reactionsByAlias![k]
                ? this.reactionsByAlias![k].toObject()
                : undefined
            }),
            {}
          )
        : {},
      commentsContainer: this.commentsContainer
        ? this.commentsContainer.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Reactions.AsProtobufJSON {
    return {
      reactionsByAlias: this.reactionsByAlias
        ? Object.keys(this.reactionsByAlias).reduce(
            (r, k) => ({
              ...r,
              [k]: this.reactionsByAlias![k]
                ? this.reactionsByAlias![k].toJSON()
                : null
            }),
            {}
          )
        : {},
      commentsContainer: this.commentsContainer
        ? this.commentsContainer.toProtobufJSON(options)
        : null
    };
  }
}
export module Reactions {
  /**
   * Standard JavaScript object representation for Reactions
   */
  export interface AsObject {
    reactionsByAlias: { [prop: string]: Reaction };
    commentsContainer?: CommentsContainer.AsObject;
  }

  /**
   * Protobuf JSON representation for Reactions
   */
  export interface AsProtobufJSON {
    reactionsByAlias: { [prop: string]: Reaction };
    commentsContainer: CommentsContainer.AsProtobufJSON | null;
  }

  /**
   * Message implementation for shared.Reactions.ReactionsByAliasEntry
   */
  export class ReactionsByAliasEntry implements GrpcMessage {
    static id = 'shared.Reactions.ReactionsByAliasEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ReactionsByAliasEntry();
      ReactionsByAliasEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ReactionsByAliasEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ReactionsByAliasEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new Reaction();
            _reader.readMessage(
              _instance.value,
              Reaction.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      ReactionsByAliasEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ReactionsByAliasEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          Reaction.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: Reaction;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ReactionsByAliasEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ReactionsByAliasEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value ? new Reaction(_value.value) : undefined;
      ReactionsByAliasEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): Reaction | undefined {
      return this._value;
    }
    set value(value: Reaction | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ReactionsByAliasEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ReactionsByAliasEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ReactionsByAliasEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null
      };
    }
  }
  export module ReactionsByAliasEntry {
    /**
     * Standard JavaScript object representation for ReactionsByAliasEntry
     */
    export interface AsObject {
      key: string;
      value?: Reaction.AsObject;
    }

    /**
     * Protobuf JSON representation for ReactionsByAliasEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: Reaction.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for shared.Reaction
 */
export class Reaction implements GrpcMessage {
  static id = 'shared.Reaction';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Reaction();
    Reaction.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Reaction) {
    _instance.reactionType = _instance.reactionType || 0;
    _instance.createdTime = _instance.createdTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Reaction,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.reactionType = _reader.readEnum();
          break;
        case 2:
          _instance.createdTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Reaction.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Reaction, _writer: BinaryWriter) {
    if (_instance.reactionType) {
      _writer.writeEnum(1, _instance.reactionType);
    }
    if (_instance.createdTime) {
      _writer.writeMessage(
        2,
        _instance.createdTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _reactionType: ReactionType;
  private _createdTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Reaction to deeply clone from
   */
  constructor(_value?: RecursivePartial<Reaction.AsObject>) {
    _value = _value || {};
    this.reactionType = _value.reactionType;
    this.createdTime = _value.createdTime
      ? new googleProtobuf000.Timestamp(_value.createdTime)
      : undefined;
    Reaction.refineValues(this);
  }
  get reactionType(): ReactionType {
    return this._reactionType;
  }
  set reactionType(value: ReactionType) {
    this._reactionType = value;
  }
  get createdTime(): googleProtobuf000.Timestamp | undefined {
    return this._createdTime;
  }
  set createdTime(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Reaction.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Reaction.AsObject {
    return {
      reactionType: this.reactionType,
      createdTime: this.createdTime ? this.createdTime.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Reaction.AsProtobufJSON {
    return {
      reactionType:
        ReactionType[
          this.reactionType === null || this.reactionType === undefined
            ? 0
            : this.reactionType
        ],
      createdTime: this.createdTime
        ? this.createdTime.toProtobufJSON(options)
        : null
    };
  }
}
export module Reaction {
  /**
   * Standard JavaScript object representation for Reaction
   */
  export interface AsObject {
    reactionType: ReactionType;
    createdTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for Reaction
   */
  export interface AsProtobufJSON {
    reactionType: string;
    createdTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for shared.CommentsContainer
 */
export class CommentsContainer implements GrpcMessage {
  static id = 'shared.CommentsContainer';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommentsContainer();
    CommentsContainer.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommentsContainer) {
    _instance.nextId = _instance.nextId || 0;
    _instance.comments = _instance.comments || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommentsContainer,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.nextId = _reader.readInt32();
          break;
        case 2:
          const messageInitializer2 = new Comment();
          _reader.readMessage(
            messageInitializer2,
            Comment.deserializeBinaryFromReader
          );
          (_instance.comments = _instance.comments || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CommentsContainer.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommentsContainer,
    _writer: BinaryWriter
  ) {
    if (_instance.nextId) {
      _writer.writeInt32(1, _instance.nextId);
    }
    if (_instance.comments && _instance.comments.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.comments as any,
        Comment.serializeBinaryToWriter
      );
    }
  }

  private _nextId: number;
  private _comments?: Comment[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommentsContainer to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommentsContainer.AsObject>) {
    _value = _value || {};
    this.nextId = _value.nextId;
    this.comments = (_value.comments || []).map(m => new Comment(m));
    CommentsContainer.refineValues(this);
  }
  get nextId(): number {
    return this._nextId;
  }
  set nextId(value: number) {
    this._nextId = value;
  }
  get comments(): Comment[] | undefined {
    return this._comments;
  }
  set comments(value: Comment[] | undefined) {
    this._comments = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommentsContainer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommentsContainer.AsObject {
    return {
      nextId: this.nextId,
      comments: (this.comments || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommentsContainer.AsProtobufJSON {
    return {
      nextId: this.nextId,
      comments: (this.comments || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module CommentsContainer {
  /**
   * Standard JavaScript object representation for CommentsContainer
   */
  export interface AsObject {
    nextId: number;
    comments?: Comment.AsObject[];
  }

  /**
   * Protobuf JSON representation for CommentsContainer
   */
  export interface AsProtobufJSON {
    nextId: number;
    comments: Comment.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for shared.Comment
 */
export class Comment implements GrpcMessage {
  static id = 'shared.Comment';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Comment();
    Comment.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Comment) {
    _instance.id = _instance.id || 0;

    _instance.text = _instance.text || '';
    _instance.alias = _instance.alias || '';
    _instance.createdTime = _instance.createdTime || undefined;
    _instance.reactionsByAlias = _instance.reactionsByAlias || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Comment,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.replyToId = _reader.readInt32();
          break;
        case 3:
          _instance.text = _reader.readString();
          break;
        case 4:
          _instance.alias = _reader.readString();
          break;
        case 5:
          _instance.createdTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          const msg_6 = {} as any;
          _reader.readMessage(
            msg_6,
            Comment.ReactionsByAliasEntry.deserializeBinaryFromReader
          );
          _instance.reactionsByAlias = _instance.reactionsByAlias || {};
          _instance.reactionsByAlias[msg_6.key] = msg_6.value;
          break;
        default:
          _reader.skipField();
      }
    }

    Comment.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Comment, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.replyToId !== undefined && _instance.replyToId !== null) {
      _writer.writeInt32(2, _instance.replyToId);
    }
    if (_instance.text) {
      _writer.writeString(3, _instance.text);
    }
    if (_instance.alias) {
      _writer.writeString(4, _instance.alias);
    }
    if (_instance.createdTime) {
      _writer.writeMessage(
        5,
        _instance.createdTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (!!_instance.reactionsByAlias) {
      const keys_6 = Object.keys(_instance.reactionsByAlias as any);

      if (keys_6.length) {
        const repeated_6 = keys_6
          .map(key => ({
            key: key,
            value: (_instance.reactionsByAlias as any)[key]
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          6,
          repeated_6,
          Comment.ReactionsByAliasEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _id: number;
  private _replyToId?: number;
  private _text: string;
  private _alias: string;
  private _createdTime?: googleProtobuf000.Timestamp;
  private _reactionsByAlias: { [prop: string]: Reaction };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Comment to deeply clone from
   */
  constructor(_value?: RecursivePartial<Comment.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.replyToId = _value.replyToId;
    this.text = _value.text;
    this.alias = _value.alias;
    this.createdTime = _value.createdTime
      ? new googleProtobuf000.Timestamp(_value.createdTime)
      : undefined;
    (this.reactionsByAlias = _value!.reactionsByAlias
      ? Object.keys(_value!.reactionsByAlias).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.reactionsByAlias![k]
              ? new Reaction(_value!.reactionsByAlias![k])
              : undefined
          }),
          {}
        )
      : {}),
      Comment.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get replyToId(): number | undefined {
    return this._replyToId;
  }
  set replyToId(value?: number) {
    this._replyToId = value;
  }
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get createdTime(): googleProtobuf000.Timestamp | undefined {
    return this._createdTime;
  }
  set createdTime(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTime = value;
  }
  get reactionsByAlias(): { [prop: string]: Reaction } {
    return this._reactionsByAlias;
  }
  set reactionsByAlias(value: { [prop: string]: Reaction }) {
    this._reactionsByAlias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Comment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Comment.AsObject {
    return {
      id: this.id,
      replyToId: this.replyToId,
      text: this.text,
      alias: this.alias,
      createdTime: this.createdTime ? this.createdTime.toObject() : undefined,
      reactionsByAlias: this.reactionsByAlias
        ? Object.keys(this.reactionsByAlias).reduce(
            (r, k) => ({
              ...r,
              [k]: this.reactionsByAlias![k]
                ? this.reactionsByAlias![k].toObject()
                : undefined
            }),
            {}
          )
        : {}
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Comment.AsProtobufJSON {
    return {
      id: this.id,
      replyToId:
        this.replyToId === null || this.replyToId === undefined
          ? null
          : this.replyToId,
      text: this.text,
      alias: this.alias,
      createdTime: this.createdTime
        ? this.createdTime.toProtobufJSON(options)
        : null,
      reactionsByAlias: this.reactionsByAlias
        ? Object.keys(this.reactionsByAlias).reduce(
            (r, k) => ({
              ...r,
              [k]: this.reactionsByAlias![k]
                ? this.reactionsByAlias![k].toJSON()
                : null
            }),
            {}
          )
        : {}
    };
  }
}
export module Comment {
  /**
   * Standard JavaScript object representation for Comment
   */
  export interface AsObject {
    id: number;
    replyToId?: number;
    text: string;
    alias: string;
    createdTime?: googleProtobuf000.Timestamp.AsObject;
    reactionsByAlias: { [prop: string]: Reaction };
  }

  /**
   * Protobuf JSON representation for Comment
   */
  export interface AsProtobufJSON {
    id: number;
    replyToId: number | null;
    text: string;
    alias: string;
    createdTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    reactionsByAlias: { [prop: string]: Reaction };
  }

  /**
   * Message implementation for shared.Comment.ReactionsByAliasEntry
   */
  export class ReactionsByAliasEntry implements GrpcMessage {
    static id = 'shared.Comment.ReactionsByAliasEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ReactionsByAliasEntry();
      ReactionsByAliasEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ReactionsByAliasEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ReactionsByAliasEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new Reaction();
            _reader.readMessage(
              _instance.value,
              Reaction.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      ReactionsByAliasEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ReactionsByAliasEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          Reaction.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: Reaction;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ReactionsByAliasEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ReactionsByAliasEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value ? new Reaction(_value.value) : undefined;
      ReactionsByAliasEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): Reaction | undefined {
      return this._value;
    }
    set value(value: Reaction | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ReactionsByAliasEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ReactionsByAliasEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ReactionsByAliasEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null
      };
    }
  }
  export module ReactionsByAliasEntry {
    /**
     * Standard JavaScript object representation for ReactionsByAliasEntry
     */
    export interface AsObject {
      key: string;
      value?: Reaction.AsObject;
    }

    /**
     * Protobuf JSON representation for ReactionsByAliasEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: Reaction.AsProtobufJSON | null;
    }
  }
}
