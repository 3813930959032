/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as shared001 from '../../../../src/main/proto/shared/media-shared.pb';
import * as shared002 from '../../../../src/main/proto/shared/user-shared.pb';
/**
 * Message implementation for api.user.CheckUserRestrictionsRequest
 */
export class CheckUserRestrictionsRequest implements GrpcMessage {
  static id = 'api.user.CheckUserRestrictionsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CheckUserRestrictionsRequest();
    CheckUserRestrictionsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CheckUserRestrictionsRequest) {
    _instance.email = _instance.email || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CheckUserRestrictionsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.email = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CheckUserRestrictionsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CheckUserRestrictionsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.email) {
      _writer.writeString(1, _instance.email);
    }
  }

  private _email: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CheckUserRestrictionsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CheckUserRestrictionsRequest.AsObject>
  ) {
    _value = _value || {};
    this.email = _value.email;
    CheckUserRestrictionsRequest.refineValues(this);
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CheckUserRestrictionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CheckUserRestrictionsRequest.AsObject {
    return {
      email: this.email
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CheckUserRestrictionsRequest.AsProtobufJSON {
    return {
      email: this.email
    };
  }
}
export module CheckUserRestrictionsRequest {
  /**
   * Standard JavaScript object representation for CheckUserRestrictionsRequest
   */
  export interface AsObject {
    email: string;
  }

  /**
   * Protobuf JSON representation for CheckUserRestrictionsRequest
   */
  export interface AsProtobufJSON {
    email: string;
  }
}

/**
 * Message implementation for api.user.CheckUserRestrictionsResponse
 */
export class CheckUserRestrictionsResponse implements GrpcMessage {
  static id = 'api.user.CheckUserRestrictionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CheckUserRestrictionsResponse();
    CheckUserRestrictionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CheckUserRestrictionsResponse) {
    _instance.isDenied = _instance.isDenied || false;
    _instance.reason = _instance.reason || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CheckUserRestrictionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.isDenied = _reader.readBool();
          break;
        case 2:
          _instance.reason = new CheckUserRestrictionsResponse.DeniedReason();
          _reader.readMessage(
            _instance.reason,
            CheckUserRestrictionsResponse.DeniedReason
              .deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CheckUserRestrictionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CheckUserRestrictionsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.isDenied) {
      _writer.writeBool(1, _instance.isDenied);
    }
    if (_instance.reason) {
      _writer.writeMessage(
        2,
        _instance.reason as any,
        CheckUserRestrictionsResponse.DeniedReason.serializeBinaryToWriter
      );
    }
  }

  private _isDenied: boolean;
  private _reason?: CheckUserRestrictionsResponse.DeniedReason;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CheckUserRestrictionsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CheckUserRestrictionsResponse.AsObject>
  ) {
    _value = _value || {};
    this.isDenied = _value.isDenied;
    this.reason = _value.reason
      ? new CheckUserRestrictionsResponse.DeniedReason(_value.reason)
      : undefined;
    CheckUserRestrictionsResponse.refineValues(this);
  }
  get isDenied(): boolean {
    return this._isDenied;
  }
  set isDenied(value: boolean) {
    this._isDenied = value;
  }
  get reason(): CheckUserRestrictionsResponse.DeniedReason | undefined {
    return this._reason;
  }
  set reason(value: CheckUserRestrictionsResponse.DeniedReason | undefined) {
    this._reason = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CheckUserRestrictionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CheckUserRestrictionsResponse.AsObject {
    return {
      isDenied: this.isDenied,
      reason: this.reason ? this.reason.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CheckUserRestrictionsResponse.AsProtobufJSON {
    return {
      isDenied: this.isDenied,
      reason: this.reason ? this.reason.toProtobufJSON(options) : null
    };
  }
}
export module CheckUserRestrictionsResponse {
  /**
   * Standard JavaScript object representation for CheckUserRestrictionsResponse
   */
  export interface AsObject {
    isDenied: boolean;
    reason?: CheckUserRestrictionsResponse.DeniedReason.AsObject;
  }

  /**
   * Protobuf JSON representation for CheckUserRestrictionsResponse
   */
  export interface AsProtobufJSON {
    isDenied: boolean;
    reason: CheckUserRestrictionsResponse.DeniedReason.AsProtobufJSON | null;
  }
  export enum DeniedReasonCode {
    DENIED_REASON_CODE_UNSPECIFIED = 0,
    DENIED_REASON_CODE_EXCEEDED_MAX_USERS = 1
  }
  /**
   * Message implementation for api.user.CheckUserRestrictionsResponse.DeniedReason
   */
  export class DeniedReason implements GrpcMessage {
    static id = 'api.user.CheckUserRestrictionsResponse.DeniedReason';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new DeniedReason();
      DeniedReason.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: DeniedReason) {
      _instance.code = _instance.code || 0;
      _instance.text = _instance.text || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: DeniedReason,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.code = _reader.readEnum();
            break;
          case 2:
            _instance.text = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      DeniedReason.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: DeniedReason,
      _writer: BinaryWriter
    ) {
      if (_instance.code) {
        _writer.writeEnum(1, _instance.code);
      }
      if (_instance.text) {
        _writer.writeString(2, _instance.text);
      }
    }

    private _code: CheckUserRestrictionsResponse.DeniedReasonCode;
    private _text: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of DeniedReason to deeply clone from
     */
    constructor(_value?: RecursivePartial<DeniedReason.AsObject>) {
      _value = _value || {};
      this.code = _value.code;
      this.text = _value.text;
      DeniedReason.refineValues(this);
    }
    get code(): CheckUserRestrictionsResponse.DeniedReasonCode {
      return this._code;
    }
    set code(value: CheckUserRestrictionsResponse.DeniedReasonCode) {
      this._code = value;
    }
    get text(): string {
      return this._text;
    }
    set text(value: string) {
      this._text = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      DeniedReason.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): DeniedReason.AsObject {
      return {
        code: this.code,
        text: this.text
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): DeniedReason.AsProtobufJSON {
      return {
        code:
          CheckUserRestrictionsResponse.DeniedReasonCode[
            this.code === null || this.code === undefined ? 0 : this.code
          ],
        text: this.text
      };
    }
  }
  export module DeniedReason {
    /**
     * Standard JavaScript object representation for DeniedReason
     */
    export interface AsObject {
      code: CheckUserRestrictionsResponse.DeniedReasonCode;
      text: string;
    }

    /**
     * Protobuf JSON representation for DeniedReason
     */
    export interface AsProtobufJSON {
      code: string;
      text: string;
    }
  }
}

/**
 * Message implementation for api.user.LogInRequest
 */
export class LogInRequest implements GrpcMessage {
  static id = 'api.user.LogInRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogInRequest();
    LogInRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogInRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogInRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LogInRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogInRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogInRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogInRequest.AsObject>) {
    _value = _value || {};
    LogInRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogInRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogInRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogInRequest.AsProtobufJSON {
    return {};
  }
}
export module LogInRequest {
  /**
   * Standard JavaScript object representation for LogInRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LogInRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.LogInResponse
 */
export class LogInResponse implements GrpcMessage {
  static id = 'api.user.LogInResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogInResponse();
    LogInResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogInResponse) {
    _instance.email = _instance.email || '';
    _instance.alias = _instance.alias || '';
    _instance.session = _instance.session || undefined;
    _instance.roles = _instance.roles || [];
    _instance.details = _instance.details || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogInResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.email = _reader.readString();
          break;
        case 2:
          _instance.alias = _reader.readString();
          break;
        case 3:
          _instance.session = new Session();
          _reader.readMessage(
            _instance.session,
            Session.deserializeBinaryFromReader
          );
          break;
        case 4:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        case 5:
          _instance.details = new UserPrivateDetails();
          _reader.readMessage(
            _instance.details,
            UserPrivateDetails.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LogInResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogInResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.email) {
      _writer.writeString(1, _instance.email);
    }
    if (_instance.alias) {
      _writer.writeString(2, _instance.alias);
    }
    if (_instance.session) {
      _writer.writeMessage(
        3,
        _instance.session as any,
        Session.serializeBinaryToWriter
      );
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(4, _instance.roles);
    }
    if (_instance.details) {
      _writer.writeMessage(
        5,
        _instance.details as any,
        UserPrivateDetails.serializeBinaryToWriter
      );
    }
  }

  private _email: string;
  private _alias: string;
  private _session?: Session;
  private _roles: string[];
  private _details?: UserPrivateDetails;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogInResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogInResponse.AsObject>) {
    _value = _value || {};
    this.email = _value.email;
    this.alias = _value.alias;
    this.session = _value.session ? new Session(_value.session) : undefined;
    this.roles = (_value.roles || []).slice();
    this.details = _value.details
      ? new UserPrivateDetails(_value.details)
      : undefined;
    LogInResponse.refineValues(this);
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get session(): Session | undefined {
    return this._session;
  }
  set session(value: Session | undefined) {
    this._session = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }
  get details(): UserPrivateDetails | undefined {
    return this._details;
  }
  set details(value: UserPrivateDetails | undefined) {
    this._details = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogInResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogInResponse.AsObject {
    return {
      email: this.email,
      alias: this.alias,
      session: this.session ? this.session.toObject() : undefined,
      roles: (this.roles || []).slice(),
      details: this.details ? this.details.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogInResponse.AsProtobufJSON {
    return {
      email: this.email,
      alias: this.alias,
      session: this.session ? this.session.toProtobufJSON(options) : null,
      roles: (this.roles || []).slice(),
      details: this.details ? this.details.toProtobufJSON(options) : null
    };
  }
}
export module LogInResponse {
  /**
   * Standard JavaScript object representation for LogInResponse
   */
  export interface AsObject {
    email: string;
    alias: string;
    session?: Session.AsObject;
    roles: string[];
    details?: UserPrivateDetails.AsObject;
  }

  /**
   * Protobuf JSON representation for LogInResponse
   */
  export interface AsProtobufJSON {
    email: string;
    alias: string;
    session: Session.AsProtobufJSON | null;
    roles: string[];
    details: UserPrivateDetails.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.LogOutRequest
 */
export class LogOutRequest implements GrpcMessage {
  static id = 'api.user.LogOutRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogOutRequest();
    LogOutRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogOutRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogOutRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LogOutRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogOutRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogOutRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogOutRequest.AsObject>) {
    _value = _value || {};
    LogOutRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogOutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogOutRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogOutRequest.AsProtobufJSON {
    return {};
  }
}
export module LogOutRequest {
  /**
   * Standard JavaScript object representation for LogOutRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LogOutRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.LogOutResponse
 */
export class LogOutResponse implements GrpcMessage {
  static id = 'api.user.LogOutResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogOutResponse();
    LogOutResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogOutResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogOutResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LogOutResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogOutResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogOutResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogOutResponse.AsObject>) {
    _value = _value || {};
    LogOutResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogOutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogOutResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogOutResponse.AsProtobufJSON {
    return {};
  }
}
export module LogOutResponse {
  /**
   * Standard JavaScript object representation for LogOutResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LogOutResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.UpdateMessagingTokenRequest
 */
export class UpdateMessagingTokenRequest implements GrpcMessage {
  static id = 'api.user.UpdateMessagingTokenRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateMessagingTokenRequest();
    UpdateMessagingTokenRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateMessagingTokenRequest) {
    _instance.token = _instance.token || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateMessagingTokenRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.token = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateMessagingTokenRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateMessagingTokenRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.token) {
      _writer.writeString(1, _instance.token);
    }
  }

  private _token: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateMessagingTokenRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateMessagingTokenRequest.AsObject>) {
    _value = _value || {};
    this.token = _value.token;
    UpdateMessagingTokenRequest.refineValues(this);
  }
  get token(): string {
    return this._token;
  }
  set token(value: string) {
    this._token = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateMessagingTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateMessagingTokenRequest.AsObject {
    return {
      token: this.token
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateMessagingTokenRequest.AsProtobufJSON {
    return {
      token: this.token
    };
  }
}
export module UpdateMessagingTokenRequest {
  /**
   * Standard JavaScript object representation for UpdateMessagingTokenRequest
   */
  export interface AsObject {
    token: string;
  }

  /**
   * Protobuf JSON representation for UpdateMessagingTokenRequest
   */
  export interface AsProtobufJSON {
    token: string;
  }
}

/**
 * Message implementation for api.user.UpdateMessagingTokenResponse
 */
export class UpdateMessagingTokenResponse implements GrpcMessage {
  static id = 'api.user.UpdateMessagingTokenResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateMessagingTokenResponse();
    UpdateMessagingTokenResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateMessagingTokenResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateMessagingTokenResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateMessagingTokenResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateMessagingTokenResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateMessagingTokenResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UpdateMessagingTokenResponse.AsObject>
  ) {
    _value = _value || {};
    UpdateMessagingTokenResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateMessagingTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateMessagingTokenResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateMessagingTokenResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateMessagingTokenResponse {
  /**
   * Standard JavaScript object representation for UpdateMessagingTokenResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateMessagingTokenResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.AliasExistsRequest
 */
export class AliasExistsRequest implements GrpcMessage {
  static id = 'api.user.AliasExistsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AliasExistsRequest();
    AliasExistsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AliasExistsRequest) {
    _instance.alias = _instance.alias || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AliasExistsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AliasExistsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AliasExistsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
  }

  private _alias: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AliasExistsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AliasExistsRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    AliasExistsRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AliasExistsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AliasExistsRequest.AsObject {
    return {
      alias: this.alias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AliasExistsRequest.AsProtobufJSON {
    return {
      alias: this.alias
    };
  }
}
export module AliasExistsRequest {
  /**
   * Standard JavaScript object representation for AliasExistsRequest
   */
  export interface AsObject {
    alias: string;
  }

  /**
   * Protobuf JSON representation for AliasExistsRequest
   */
  export interface AsProtobufJSON {
    alias: string;
  }
}

/**
 * Message implementation for api.user.AliasExistsResponse
 */
export class AliasExistsResponse implements GrpcMessage {
  static id = 'api.user.AliasExistsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AliasExistsResponse();
    AliasExistsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AliasExistsResponse) {
    _instance.exists = _instance.exists || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AliasExistsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.exists = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    AliasExistsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AliasExistsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.exists) {
      _writer.writeBool(1, _instance.exists);
    }
  }

  private _exists: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AliasExistsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AliasExistsResponse.AsObject>) {
    _value = _value || {};
    this.exists = _value.exists;
    AliasExistsResponse.refineValues(this);
  }
  get exists(): boolean {
    return this._exists;
  }
  set exists(value: boolean) {
    this._exists = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AliasExistsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AliasExistsResponse.AsObject {
    return {
      exists: this.exists
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AliasExistsResponse.AsProtobufJSON {
    return {
      exists: this.exists
    };
  }
}
export module AliasExistsResponse {
  /**
   * Standard JavaScript object representation for AliasExistsResponse
   */
  export interface AsObject {
    exists: boolean;
  }

  /**
   * Protobuf JSON representation for AliasExistsResponse
   */
  export interface AsProtobufJSON {
    exists: boolean;
  }
}

/**
 * Message implementation for api.user.SignUpRequest
 */
export class SignUpRequest implements GrpcMessage {
  static id = 'api.user.SignUpRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SignUpRequest();
    SignUpRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SignUpRequest) {
    _instance.alias = _instance.alias || '';
    _instance.details = _instance.details || undefined;
    _instance.messagingToken = _instance.messagingToken || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SignUpRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.details = new UserPrivateDetails();
          _reader.readMessage(
            _instance.details,
            UserPrivateDetails.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.messagingToken = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SignUpRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SignUpRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.details) {
      _writer.writeMessage(
        2,
        _instance.details as any,
        UserPrivateDetails.serializeBinaryToWriter
      );
    }
    if (_instance.messagingToken) {
      _writer.writeString(3, _instance.messagingToken);
    }
  }

  private _alias: string;
  private _details?: UserPrivateDetails;
  private _messagingToken: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SignUpRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SignUpRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.details = _value.details
      ? new UserPrivateDetails(_value.details)
      : undefined;
    this.messagingToken = _value.messagingToken;
    SignUpRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get details(): UserPrivateDetails | undefined {
    return this._details;
  }
  set details(value: UserPrivateDetails | undefined) {
    this._details = value;
  }
  get messagingToken(): string {
    return this._messagingToken;
  }
  set messagingToken(value: string) {
    this._messagingToken = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SignUpRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SignUpRequest.AsObject {
    return {
      alias: this.alias,
      details: this.details ? this.details.toObject() : undefined,
      messagingToken: this.messagingToken
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SignUpRequest.AsProtobufJSON {
    return {
      alias: this.alias,
      details: this.details ? this.details.toProtobufJSON(options) : null,
      messagingToken: this.messagingToken
    };
  }
}
export module SignUpRequest {
  /**
   * Standard JavaScript object representation for SignUpRequest
   */
  export interface AsObject {
    alias: string;
    details?: UserPrivateDetails.AsObject;
    messagingToken: string;
  }

  /**
   * Protobuf JSON representation for SignUpRequest
   */
  export interface AsProtobufJSON {
    alias: string;
    details: UserPrivateDetails.AsProtobufJSON | null;
    messagingToken: string;
  }
}

/**
 * Message implementation for api.user.SignUpResponse
 */
export class SignUpResponse implements GrpcMessage {
  static id = 'api.user.SignUpResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SignUpResponse();
    SignUpResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SignUpResponse) {
    _instance.session = _instance.session || undefined;
    _instance.roles = _instance.roles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SignUpResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.session = new Session();
          _reader.readMessage(
            _instance.session,
            Session.deserializeBinaryFromReader
          );
          break;
        case 2:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        default:
          _reader.skipField();
      }
    }

    SignUpResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SignUpResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.session) {
      _writer.writeMessage(
        1,
        _instance.session as any,
        Session.serializeBinaryToWriter
      );
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(2, _instance.roles);
    }
  }

  private _session?: Session;
  private _roles: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SignUpResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SignUpResponse.AsObject>) {
    _value = _value || {};
    this.session = _value.session ? new Session(_value.session) : undefined;
    this.roles = (_value.roles || []).slice();
    SignUpResponse.refineValues(this);
  }
  get session(): Session | undefined {
    return this._session;
  }
  set session(value: Session | undefined) {
    this._session = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SignUpResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SignUpResponse.AsObject {
    return {
      session: this.session ? this.session.toObject() : undefined,
      roles: (this.roles || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SignUpResponse.AsProtobufJSON {
    return {
      session: this.session ? this.session.toProtobufJSON(options) : null,
      roles: (this.roles || []).slice()
    };
  }
}
export module SignUpResponse {
  /**
   * Standard JavaScript object representation for SignUpResponse
   */
  export interface AsObject {
    session?: Session.AsObject;
    roles: string[];
  }

  /**
   * Protobuf JSON representation for SignUpResponse
   */
  export interface AsProtobufJSON {
    session: Session.AsProtobufJSON | null;
    roles: string[];
  }
}

/**
 * Message implementation for api.user.GetDefaultHeartRateLimitsRequest
 */
export class GetDefaultHeartRateLimitsRequest implements GrpcMessage {
  static id = 'api.user.GetDefaultHeartRateLimitsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDefaultHeartRateLimitsRequest();
    GetDefaultHeartRateLimitsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDefaultHeartRateLimitsRequest) {
    _instance.dateOfBirth = _instance.dateOfBirth || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDefaultHeartRateLimitsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.dateOfBirth = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.dateOfBirth,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDefaultHeartRateLimitsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDefaultHeartRateLimitsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.dateOfBirth) {
      _writer.writeMessage(
        1,
        _instance.dateOfBirth as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _dateOfBirth?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDefaultHeartRateLimitsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDefaultHeartRateLimitsRequest.AsObject>
  ) {
    _value = _value || {};
    this.dateOfBirth = _value.dateOfBirth
      ? new googleProtobuf000.Timestamp(_value.dateOfBirth)
      : undefined;
    GetDefaultHeartRateLimitsRequest.refineValues(this);
  }
  get dateOfBirth(): googleProtobuf000.Timestamp | undefined {
    return this._dateOfBirth;
  }
  set dateOfBirth(value: googleProtobuf000.Timestamp | undefined) {
    this._dateOfBirth = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDefaultHeartRateLimitsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDefaultHeartRateLimitsRequest.AsObject {
    return {
      dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDefaultHeartRateLimitsRequest.AsProtobufJSON {
    return {
      dateOfBirth: this.dateOfBirth
        ? this.dateOfBirth.toProtobufJSON(options)
        : null
    };
  }
}
export module GetDefaultHeartRateLimitsRequest {
  /**
   * Standard JavaScript object representation for GetDefaultHeartRateLimitsRequest
   */
  export interface AsObject {
    dateOfBirth?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetDefaultHeartRateLimitsRequest
   */
  export interface AsProtobufJSON {
    dateOfBirth: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.GetDefaultHeartRateLimitsResponse
 */
export class GetDefaultHeartRateLimitsResponse implements GrpcMessage {
  static id = 'api.user.GetDefaultHeartRateLimitsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDefaultHeartRateLimitsResponse();
    GetDefaultHeartRateLimitsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDefaultHeartRateLimitsResponse) {
    _instance.heartRateLimits = _instance.heartRateLimits || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDefaultHeartRateLimitsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.heartRateLimits = new shared002.HeartRateLimits();
          _reader.readMessage(
            _instance.heartRateLimits,
            shared002.HeartRateLimits.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDefaultHeartRateLimitsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDefaultHeartRateLimitsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.heartRateLimits) {
      _writer.writeMessage(
        1,
        _instance.heartRateLimits as any,
        shared002.HeartRateLimits.serializeBinaryToWriter
      );
    }
  }

  private _heartRateLimits?: shared002.HeartRateLimits;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDefaultHeartRateLimitsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDefaultHeartRateLimitsResponse.AsObject>
  ) {
    _value = _value || {};
    this.heartRateLimits = _value.heartRateLimits
      ? new shared002.HeartRateLimits(_value.heartRateLimits)
      : undefined;
    GetDefaultHeartRateLimitsResponse.refineValues(this);
  }
  get heartRateLimits(): shared002.HeartRateLimits | undefined {
    return this._heartRateLimits;
  }
  set heartRateLimits(value: shared002.HeartRateLimits | undefined) {
    this._heartRateLimits = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDefaultHeartRateLimitsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDefaultHeartRateLimitsResponse.AsObject {
    return {
      heartRateLimits: this.heartRateLimits
        ? this.heartRateLimits.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDefaultHeartRateLimitsResponse.AsProtobufJSON {
    return {
      heartRateLimits: this.heartRateLimits
        ? this.heartRateLimits.toProtobufJSON(options)
        : null
    };
  }
}
export module GetDefaultHeartRateLimitsResponse {
  /**
   * Standard JavaScript object representation for GetDefaultHeartRateLimitsResponse
   */
  export interface AsObject {
    heartRateLimits?: shared002.HeartRateLimits.AsObject;
  }

  /**
   * Protobuf JSON representation for GetDefaultHeartRateLimitsResponse
   */
  export interface AsProtobufJSON {
    heartRateLimits: shared002.HeartRateLimits.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.Session
 */
export class Session implements GrpcMessage {
  static id = 'api.user.Session';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Session();
    Session.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Session) {
    _instance.sessionId = _instance.sessionId || '';
    _instance.expirationTime = _instance.expirationTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Session,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.sessionId = _reader.readString();
          break;
        case 2:
          _instance.expirationTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.expirationTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Session.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Session, _writer: BinaryWriter) {
    if (_instance.sessionId) {
      _writer.writeString(1, _instance.sessionId);
    }
    if (_instance.expirationTime) {
      _writer.writeMessage(
        2,
        _instance.expirationTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _sessionId: string;
  private _expirationTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Session to deeply clone from
   */
  constructor(_value?: RecursivePartial<Session.AsObject>) {
    _value = _value || {};
    this.sessionId = _value.sessionId;
    this.expirationTime = _value.expirationTime
      ? new googleProtobuf000.Timestamp(_value.expirationTime)
      : undefined;
    Session.refineValues(this);
  }
  get sessionId(): string {
    return this._sessionId;
  }
  set sessionId(value: string) {
    this._sessionId = value;
  }
  get expirationTime(): googleProtobuf000.Timestamp | undefined {
    return this._expirationTime;
  }
  set expirationTime(value: googleProtobuf000.Timestamp | undefined) {
    this._expirationTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Session.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Session.AsObject {
    return {
      sessionId: this.sessionId,
      expirationTime: this.expirationTime
        ? this.expirationTime.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Session.AsProtobufJSON {
    return {
      sessionId: this.sessionId,
      expirationTime: this.expirationTime
        ? this.expirationTime.toProtobufJSON(options)
        : null
    };
  }
}
export module Session {
  /**
   * Standard JavaScript object representation for Session
   */
  export interface AsObject {
    sessionId: string;
    expirationTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for Session
   */
  export interface AsProtobufJSON {
    sessionId: string;
    expirationTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.user.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.details = _instance.details || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.details = new UserPrivateDetails();
          _reader.readMessage(
            _instance.details,
            UserPrivateDetails.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.details) {
      _writer.writeMessage(
        1,
        _instance.details as any,
        UserPrivateDetails.serializeBinaryToWriter
      );
    }
  }

  private _details?: UserPrivateDetails;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.details = _value.details
      ? new UserPrivateDetails(_value.details)
      : undefined;
    UpdateRequest.refineValues(this);
  }
  get details(): UserPrivateDetails | undefined {
    return this._details;
  }
  set details(value: UserPrivateDetails | undefined) {
    this._details = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      details: this.details ? this.details.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      details: this.details ? this.details.toProtobufJSON(options) : null
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    details?: UserPrivateDetails.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    details: UserPrivateDetails.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.user.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    UpdateResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.UpdateCookiesConsentRequest
 */
export class UpdateCookiesConsentRequest implements GrpcMessage {
  static id = 'api.user.UpdateCookiesConsentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCookiesConsentRequest();
    UpdateCookiesConsentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCookiesConsentRequest) {
    _instance.consentedCookiesVersion = _instance.consentedCookiesVersion || '';
    _instance.acceptedCookiesCategories =
      _instance.acceptedCookiesCategories || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCookiesConsentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.consentedCookiesVersion = _reader.readString();
          break;
        case 2:
          (_instance.acceptedCookiesCategories =
            _instance.acceptedCookiesCategories || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCookiesConsentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCookiesConsentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.consentedCookiesVersion) {
      _writer.writeString(1, _instance.consentedCookiesVersion);
    }
    if (
      _instance.acceptedCookiesCategories &&
      _instance.acceptedCookiesCategories.length
    ) {
      _writer.writeRepeatedString(2, _instance.acceptedCookiesCategories);
    }
  }

  private _consentedCookiesVersion: string;
  private _acceptedCookiesCategories: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCookiesConsentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCookiesConsentRequest.AsObject>) {
    _value = _value || {};
    this.consentedCookiesVersion = _value.consentedCookiesVersion;
    this.acceptedCookiesCategories = (
      _value.acceptedCookiesCategories || []
    ).slice();
    UpdateCookiesConsentRequest.refineValues(this);
  }
  get consentedCookiesVersion(): string {
    return this._consentedCookiesVersion;
  }
  set consentedCookiesVersion(value: string) {
    this._consentedCookiesVersion = value;
  }
  get acceptedCookiesCategories(): string[] {
    return this._acceptedCookiesCategories;
  }
  set acceptedCookiesCategories(value: string[]) {
    this._acceptedCookiesCategories = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCookiesConsentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCookiesConsentRequest.AsObject {
    return {
      consentedCookiesVersion: this.consentedCookiesVersion,
      acceptedCookiesCategories: (this.acceptedCookiesCategories || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCookiesConsentRequest.AsProtobufJSON {
    return {
      consentedCookiesVersion: this.consentedCookiesVersion,
      acceptedCookiesCategories: (this.acceptedCookiesCategories || []).slice()
    };
  }
}
export module UpdateCookiesConsentRequest {
  /**
   * Standard JavaScript object representation for UpdateCookiesConsentRequest
   */
  export interface AsObject {
    consentedCookiesVersion: string;
    acceptedCookiesCategories: string[];
  }

  /**
   * Protobuf JSON representation for UpdateCookiesConsentRequest
   */
  export interface AsProtobufJSON {
    consentedCookiesVersion: string;
    acceptedCookiesCategories: string[];
  }
}

/**
 * Message implementation for api.user.UpdateCookiesConsentResponse
 */
export class UpdateCookiesConsentResponse implements GrpcMessage {
  static id = 'api.user.UpdateCookiesConsentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCookiesConsentResponse();
    UpdateCookiesConsentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCookiesConsentResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCookiesConsentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateCookiesConsentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCookiesConsentResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCookiesConsentResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UpdateCookiesConsentResponse.AsObject>
  ) {
    _value = _value || {};
    UpdateCookiesConsentResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCookiesConsentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCookiesConsentResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCookiesConsentResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateCookiesConsentResponse {
  /**
   * Standard JavaScript object representation for UpdateCookiesConsentResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateCookiesConsentResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.SignOutRequest
 */
export class SignOutRequest implements GrpcMessage {
  static id = 'api.user.SignOutRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SignOutRequest();
    SignOutRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SignOutRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SignOutRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    SignOutRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SignOutRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SignOutRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SignOutRequest.AsObject>) {
    _value = _value || {};
    SignOutRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SignOutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SignOutRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SignOutRequest.AsProtobufJSON {
    return {};
  }
}
export module SignOutRequest {
  /**
   * Standard JavaScript object representation for SignOutRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for SignOutRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.SignOutResponse
 */
export class SignOutResponse implements GrpcMessage {
  static id = 'api.user.SignOutResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SignOutResponse();
    SignOutResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SignOutResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SignOutResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    SignOutResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SignOutResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SignOutResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SignOutResponse.AsObject>) {
    _value = _value || {};
    SignOutResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SignOutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SignOutResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SignOutResponse.AsProtobufJSON {
    return {};
  }
}
export module SignOutResponse {
  /**
   * Standard JavaScript object representation for SignOutResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for SignOutResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.DeleteRequest
 */
export class DeleteRequest implements GrpcMessage {
  static id = 'api.user.DeleteRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteRequest();
    DeleteRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteRequest) {
    _instance.alias = _instance.alias || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
  }

  private _alias: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    DeleteRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteRequest.AsObject {
    return {
      alias: this.alias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteRequest.AsProtobufJSON {
    return {
      alias: this.alias
    };
  }
}
export module DeleteRequest {
  /**
   * Standard JavaScript object representation for DeleteRequest
   */
  export interface AsObject {
    alias: string;
  }

  /**
   * Protobuf JSON representation for DeleteRequest
   */
  export interface AsProtobufJSON {
    alias: string;
  }
}

/**
 * Message implementation for api.user.DeleteResponse
 */
export class DeleteResponse implements GrpcMessage {
  static id = 'api.user.DeleteResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteResponse();
    DeleteResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    DeleteResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteResponse.AsObject>) {
    _value = _value || {};
    DeleteResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteResponse.AsProtobufJSON {
    return {};
  }
}
export module DeleteResponse {
  /**
   * Standard JavaScript object representation for DeleteResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for DeleteResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.GetUserDetailsRequest
 */
export class GetUserDetailsRequest implements GrpcMessage {
  static id = 'api.user.GetUserDetailsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUserDetailsRequest();
    GetUserDetailsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUserDetailsRequest) {
    _instance.aliases = _instance.aliases || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUserDetailsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.aliases = _instance.aliases || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetUserDetailsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUserDetailsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.aliases && _instance.aliases.length) {
      _writer.writeRepeatedString(1, _instance.aliases);
    }
  }

  private _aliases: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUserDetailsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUserDetailsRequest.AsObject>) {
    _value = _value || {};
    this.aliases = (_value.aliases || []).slice();
    GetUserDetailsRequest.refineValues(this);
  }
  get aliases(): string[] {
    return this._aliases;
  }
  set aliases(value: string[]) {
    this._aliases = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUserDetailsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUserDetailsRequest.AsObject {
    return {
      aliases: (this.aliases || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUserDetailsRequest.AsProtobufJSON {
    return {
      aliases: (this.aliases || []).slice()
    };
  }
}
export module GetUserDetailsRequest {
  /**
   * Standard JavaScript object representation for GetUserDetailsRequest
   */
  export interface AsObject {
    aliases: string[];
  }

  /**
   * Protobuf JSON representation for GetUserDetailsRequest
   */
  export interface AsProtobufJSON {
    aliases: string[];
  }
}

/**
 * Message implementation for api.user.GetUserDetailsResponse
 */
export class GetUserDetailsResponse implements GrpcMessage {
  static id = 'api.user.GetUserDetailsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUserDetailsResponse();
    GetUserDetailsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUserDetailsResponse) {
    _instance.users = _instance.users || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUserDetailsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new UserPublicDetails();
          _reader.readMessage(
            messageInitializer1,
            UserPublicDetails.deserializeBinaryFromReader
          );
          (_instance.users = _instance.users || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetUserDetailsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUserDetailsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.users && _instance.users.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.users as any,
        UserPublicDetails.serializeBinaryToWriter
      );
    }
  }

  private _users?: UserPublicDetails[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUserDetailsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUserDetailsResponse.AsObject>) {
    _value = _value || {};
    this.users = (_value.users || []).map(m => new UserPublicDetails(m));
    GetUserDetailsResponse.refineValues(this);
  }
  get users(): UserPublicDetails[] | undefined {
    return this._users;
  }
  set users(value: UserPublicDetails[] | undefined) {
    this._users = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUserDetailsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUserDetailsResponse.AsObject {
    return {
      users: (this.users || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUserDetailsResponse.AsProtobufJSON {
    return {
      users: (this.users || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetUserDetailsResponse {
  /**
   * Standard JavaScript object representation for GetUserDetailsResponse
   */
  export interface AsObject {
    users?: UserPublicDetails.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetUserDetailsResponse
   */
  export interface AsProtobufJSON {
    users: UserPublicDetails.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.user.UserPrivateDetails
 */
export class UserPrivateDetails implements GrpcMessage {
  static id = 'api.user.UserPrivateDetails';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserPrivateDetails();
    UserPrivateDetails.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserPrivateDetails) {
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
    _instance.unitType = _instance.unitType || 0;
    _instance.timeZone = _instance.timeZone || '';
    _instance.consentedPrivacyVersion = _instance.consentedPrivacyVersion || '';
    _instance.consentedCookiesVersion = _instance.consentedCookiesVersion || '';
    _instance.acceptedCookiesCategories =
      _instance.acceptedCookiesCategories || [];
    _instance.photoMedia = _instance.photoMedia || undefined;
    _instance.dateOfBirth = _instance.dateOfBirth || undefined;

    _instance.locations = _instance.locations || [];
    _instance.privacyControls = _instance.privacyControls || undefined;
    _instance.heartRateLimits = _instance.heartRateLimits || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserPrivateDetails,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.firstName = _reader.readString();
          break;
        case 2:
          _instance.lastName = _reader.readString();
          break;
        case 3:
          _instance.unitType = _reader.readEnum();
          break;
        case 4:
          _instance.timeZone = _reader.readString();
          break;
        case 5:
          _instance.consentedPrivacyVersion = _reader.readString();
          break;
        case 6:
          _instance.consentedCookiesVersion = _reader.readString();
          break;
        case 7:
          (_instance.acceptedCookiesCategories =
            _instance.acceptedCookiesCategories || []).push(
            _reader.readString()
          );
          break;
        case 8:
          _instance.photoMedia = new shared001.Media();
          _reader.readMessage(
            _instance.photoMedia,
            shared001.Media.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.dateOfBirth = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.dateOfBirth,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.sex = _reader.readEnum();
          break;
        case 11:
          _instance.weightInKilograms = _reader.readFloat();
          break;
        case 12:
          const messageInitializer12 = new shared002.UserLocation();
          _reader.readMessage(
            messageInitializer12,
            shared002.UserLocation.deserializeBinaryFromReader
          );
          (_instance.locations = _instance.locations || []).push(
            messageInitializer12
          );
          break;
        case 13:
          _instance.privacyControls = new UserPrivacyControls();
          _reader.readMessage(
            _instance.privacyControls,
            UserPrivacyControls.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.heartRateLimits = new shared002.UserHeartRateLimits();
          _reader.readMessage(
            _instance.heartRateLimits,
            shared002.UserHeartRateLimits.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserPrivateDetails.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserPrivateDetails,
    _writer: BinaryWriter
  ) {
    if (_instance.firstName) {
      _writer.writeString(1, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(2, _instance.lastName);
    }
    if (_instance.unitType) {
      _writer.writeEnum(3, _instance.unitType);
    }
    if (_instance.timeZone) {
      _writer.writeString(4, _instance.timeZone);
    }
    if (_instance.consentedPrivacyVersion) {
      _writer.writeString(5, _instance.consentedPrivacyVersion);
    }
    if (_instance.consentedCookiesVersion) {
      _writer.writeString(6, _instance.consentedCookiesVersion);
    }
    if (
      _instance.acceptedCookiesCategories &&
      _instance.acceptedCookiesCategories.length
    ) {
      _writer.writeRepeatedString(7, _instance.acceptedCookiesCategories);
    }
    if (_instance.photoMedia) {
      _writer.writeMessage(
        8,
        _instance.photoMedia as any,
        shared001.Media.serializeBinaryToWriter
      );
    }
    if (_instance.dateOfBirth) {
      _writer.writeMessage(
        9,
        _instance.dateOfBirth as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.sex !== undefined && _instance.sex !== null) {
      _writer.writeEnum(10, _instance.sex);
    }
    if (
      _instance.weightInKilograms !== undefined &&
      _instance.weightInKilograms !== null
    ) {
      _writer.writeFloat(11, _instance.weightInKilograms);
    }
    if (_instance.locations && _instance.locations.length) {
      _writer.writeRepeatedMessage(
        12,
        _instance.locations as any,
        shared002.UserLocation.serializeBinaryToWriter
      );
    }
    if (_instance.privacyControls) {
      _writer.writeMessage(
        13,
        _instance.privacyControls as any,
        UserPrivacyControls.serializeBinaryToWriter
      );
    }
    if (_instance.heartRateLimits) {
      _writer.writeMessage(
        14,
        _instance.heartRateLimits as any,
        shared002.UserHeartRateLimits.serializeBinaryToWriter
      );
    }
  }

  private _firstName: string;
  private _lastName: string;
  private _unitType: shared002.UnitType;
  private _timeZone: string;
  private _consentedPrivacyVersion: string;
  private _consentedCookiesVersion: string;
  private _acceptedCookiesCategories: string[];
  private _photoMedia?: shared001.Media;
  private _dateOfBirth?: googleProtobuf000.Timestamp;
  private _sex?: shared002.Sex;
  private _weightInKilograms?: number;
  private _locations?: shared002.UserLocation[];
  private _privacyControls?: UserPrivacyControls;
  private _heartRateLimits?: shared002.UserHeartRateLimits;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserPrivateDetails to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserPrivateDetails.AsObject>) {
    _value = _value || {};
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    this.unitType = _value.unitType;
    this.timeZone = _value.timeZone;
    this.consentedPrivacyVersion = _value.consentedPrivacyVersion;
    this.consentedCookiesVersion = _value.consentedCookiesVersion;
    this.acceptedCookiesCategories = (
      _value.acceptedCookiesCategories || []
    ).slice();
    this.photoMedia = _value.photoMedia
      ? new shared001.Media(_value.photoMedia)
      : undefined;
    this.dateOfBirth = _value.dateOfBirth
      ? new googleProtobuf000.Timestamp(_value.dateOfBirth)
      : undefined;
    this.sex = _value.sex;
    this.weightInKilograms = _value.weightInKilograms;
    this.locations = (_value.locations || []).map(
      m => new shared002.UserLocation(m)
    );
    this.privacyControls = _value.privacyControls
      ? new UserPrivacyControls(_value.privacyControls)
      : undefined;
    this.heartRateLimits = _value.heartRateLimits
      ? new shared002.UserHeartRateLimits(_value.heartRateLimits)
      : undefined;
    UserPrivateDetails.refineValues(this);
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }
  get unitType(): shared002.UnitType {
    return this._unitType;
  }
  set unitType(value: shared002.UnitType) {
    this._unitType = value;
  }
  get timeZone(): string {
    return this._timeZone;
  }
  set timeZone(value: string) {
    this._timeZone = value;
  }
  get consentedPrivacyVersion(): string {
    return this._consentedPrivacyVersion;
  }
  set consentedPrivacyVersion(value: string) {
    this._consentedPrivacyVersion = value;
  }
  get consentedCookiesVersion(): string {
    return this._consentedCookiesVersion;
  }
  set consentedCookiesVersion(value: string) {
    this._consentedCookiesVersion = value;
  }
  get acceptedCookiesCategories(): string[] {
    return this._acceptedCookiesCategories;
  }
  set acceptedCookiesCategories(value: string[]) {
    this._acceptedCookiesCategories = value;
  }
  get photoMedia(): shared001.Media | undefined {
    return this._photoMedia;
  }
  set photoMedia(value: shared001.Media | undefined) {
    this._photoMedia = value;
  }
  get dateOfBirth(): googleProtobuf000.Timestamp | undefined {
    return this._dateOfBirth;
  }
  set dateOfBirth(value: googleProtobuf000.Timestamp | undefined) {
    this._dateOfBirth = value;
  }
  get sex(): shared002.Sex | undefined {
    return this._sex;
  }
  set sex(value?: shared002.Sex) {
    this._sex = value;
  }
  get weightInKilograms(): number | undefined {
    return this._weightInKilograms;
  }
  set weightInKilograms(value?: number) {
    this._weightInKilograms = value;
  }
  get locations(): shared002.UserLocation[] | undefined {
    return this._locations;
  }
  set locations(value: shared002.UserLocation[] | undefined) {
    this._locations = value;
  }
  get privacyControls(): UserPrivacyControls | undefined {
    return this._privacyControls;
  }
  set privacyControls(value: UserPrivacyControls | undefined) {
    this._privacyControls = value;
  }
  get heartRateLimits(): shared002.UserHeartRateLimits | undefined {
    return this._heartRateLimits;
  }
  set heartRateLimits(value: shared002.UserHeartRateLimits | undefined) {
    this._heartRateLimits = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserPrivateDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserPrivateDetails.AsObject {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      unitType: this.unitType,
      timeZone: this.timeZone,
      consentedPrivacyVersion: this.consentedPrivacyVersion,
      consentedCookiesVersion: this.consentedCookiesVersion,
      acceptedCookiesCategories: (this.acceptedCookiesCategories || []).slice(),
      photoMedia: this.photoMedia ? this.photoMedia.toObject() : undefined,
      dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toObject() : undefined,
      sex: this.sex,
      weightInKilograms: this.weightInKilograms,
      locations: (this.locations || []).map(m => m.toObject()),
      privacyControls: this.privacyControls
        ? this.privacyControls.toObject()
        : undefined,
      heartRateLimits: this.heartRateLimits
        ? this.heartRateLimits.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserPrivateDetails.AsProtobufJSON {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      unitType:
        shared002.UnitType[
          this.unitType === null || this.unitType === undefined
            ? 0
            : this.unitType
        ],
      timeZone: this.timeZone,
      consentedPrivacyVersion: this.consentedPrivacyVersion,
      consentedCookiesVersion: this.consentedCookiesVersion,
      acceptedCookiesCategories: (this.acceptedCookiesCategories || []).slice(),
      photoMedia: this.photoMedia
        ? this.photoMedia.toProtobufJSON(options)
        : null,
      dateOfBirth: this.dateOfBirth
        ? this.dateOfBirth.toProtobufJSON(options)
        : null,
      sex:
        this.sex === undefined
          ? null
          : shared002.Sex[
              this.sex === null || this.sex === undefined ? 0 : this.sex
            ],
      weightInKilograms:
        this.weightInKilograms === null || this.weightInKilograms === undefined
          ? null
          : this.weightInKilograms,
      locations: (this.locations || []).map(m => m.toProtobufJSON(options)),
      privacyControls: this.privacyControls
        ? this.privacyControls.toProtobufJSON(options)
        : null,
      heartRateLimits: this.heartRateLimits
        ? this.heartRateLimits.toProtobufJSON(options)
        : null
    };
  }
}
export module UserPrivateDetails {
  /**
   * Standard JavaScript object representation for UserPrivateDetails
   */
  export interface AsObject {
    firstName: string;
    lastName: string;
    unitType: shared002.UnitType;
    timeZone: string;
    consentedPrivacyVersion: string;
    consentedCookiesVersion: string;
    acceptedCookiesCategories: string[];
    photoMedia?: shared001.Media.AsObject;
    dateOfBirth?: googleProtobuf000.Timestamp.AsObject;
    sex?: shared002.Sex;
    weightInKilograms?: number;
    locations?: shared002.UserLocation.AsObject[];
    privacyControls?: UserPrivacyControls.AsObject;
    heartRateLimits?: shared002.UserHeartRateLimits.AsObject;
  }

  /**
   * Protobuf JSON representation for UserPrivateDetails
   */
  export interface AsProtobufJSON {
    firstName: string;
    lastName: string;
    unitType: string;
    timeZone: string;
    consentedPrivacyVersion: string;
    consentedCookiesVersion: string;
    acceptedCookiesCategories: string[];
    photoMedia: shared001.Media.AsProtobufJSON | null;
    dateOfBirth: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    sex: string | null;
    weightInKilograms: number | null;
    locations: shared002.UserLocation.AsProtobufJSON[] | null;
    privacyControls: UserPrivacyControls.AsProtobufJSON | null;
    heartRateLimits: shared002.UserHeartRateLimits.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.GetAvailableRolesRequest
 */
export class GetAvailableRolesRequest implements GrpcMessage {
  static id = 'api.user.GetAvailableRolesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAvailableRolesRequest();
    GetAvailableRolesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAvailableRolesRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAvailableRolesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    GetAvailableRolesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAvailableRolesRequest,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAvailableRolesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAvailableRolesRequest.AsObject>) {
    _value = _value || {};
    GetAvailableRolesRequest.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAvailableRolesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAvailableRolesRequest.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAvailableRolesRequest.AsProtobufJSON {
    return {};
  }
}
export module GetAvailableRolesRequest {
  /**
   * Standard JavaScript object representation for GetAvailableRolesRequest
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for GetAvailableRolesRequest
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.GetAvailableRolesResponse
 */
export class GetAvailableRolesResponse implements GrpcMessage {
  static id = 'api.user.GetAvailableRolesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAvailableRolesResponse();
    GetAvailableRolesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAvailableRolesResponse) {
    _instance.roles = _instance.roles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAvailableRolesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new UserRole();
          _reader.readMessage(
            messageInitializer1,
            UserRole.deserializeBinaryFromReader
          );
          (_instance.roles = _instance.roles || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetAvailableRolesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAvailableRolesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.roles as any,
        UserRole.serializeBinaryToWriter
      );
    }
  }

  private _roles?: UserRole[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAvailableRolesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAvailableRolesResponse.AsObject>) {
    _value = _value || {};
    this.roles = (_value.roles || []).map(m => new UserRole(m));
    GetAvailableRolesResponse.refineValues(this);
  }
  get roles(): UserRole[] | undefined {
    return this._roles;
  }
  set roles(value: UserRole[] | undefined) {
    this._roles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAvailableRolesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAvailableRolesResponse.AsObject {
    return {
      roles: (this.roles || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAvailableRolesResponse.AsProtobufJSON {
    return {
      roles: (this.roles || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetAvailableRolesResponse {
  /**
   * Standard JavaScript object representation for GetAvailableRolesResponse
   */
  export interface AsObject {
    roles?: UserRole.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetAvailableRolesResponse
   */
  export interface AsProtobufJSON {
    roles: UserRole.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.user.GetUserRolesRequest
 */
export class GetUserRolesRequest implements GrpcMessage {
  static id = 'api.user.GetUserRolesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUserRolesRequest();
    GetUserRolesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUserRolesRequest) {
    _instance.alias = _instance.alias || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUserRolesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetUserRolesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUserRolesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
  }

  private _alias: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUserRolesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUserRolesRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    GetUserRolesRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUserRolesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUserRolesRequest.AsObject {
    return {
      alias: this.alias
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUserRolesRequest.AsProtobufJSON {
    return {
      alias: this.alias
    };
  }
}
export module GetUserRolesRequest {
  /**
   * Standard JavaScript object representation for GetUserRolesRequest
   */
  export interface AsObject {
    alias: string;
  }

  /**
   * Protobuf JSON representation for GetUserRolesRequest
   */
  export interface AsProtobufJSON {
    alias: string;
  }
}

/**
 * Message implementation for api.user.GetUserRolesResponse
 */
export class GetUserRolesResponse implements GrpcMessage {
  static id = 'api.user.GetUserRolesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUserRolesResponse();
    GetUserRolesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUserRolesResponse) {
    _instance.roles = _instance.roles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUserRolesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new UserRole();
          _reader.readMessage(
            messageInitializer1,
            UserRole.deserializeBinaryFromReader
          );
          (_instance.roles = _instance.roles || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetUserRolesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUserRolesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.roles as any,
        UserRole.serializeBinaryToWriter
      );
    }
  }

  private _roles?: UserRole[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUserRolesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUserRolesResponse.AsObject>) {
    _value = _value || {};
    this.roles = (_value.roles || []).map(m => new UserRole(m));
    GetUserRolesResponse.refineValues(this);
  }
  get roles(): UserRole[] | undefined {
    return this._roles;
  }
  set roles(value: UserRole[] | undefined) {
    this._roles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUserRolesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUserRolesResponse.AsObject {
    return {
      roles: (this.roles || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUserRolesResponse.AsProtobufJSON {
    return {
      roles: (this.roles || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetUserRolesResponse {
  /**
   * Standard JavaScript object representation for GetUserRolesResponse
   */
  export interface AsObject {
    roles?: UserRole.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetUserRolesResponse
   */
  export interface AsProtobufJSON {
    roles: UserRole.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.user.UserRole
 */
export class UserRole implements GrpcMessage {
  static id = 'api.user.UserRole';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserRole();
    UserRole.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserRole) {
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserRole,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserRole.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UserRole, _writer: BinaryWriter) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
  }

  private _name: string;
  private _description: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserRole to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserRole.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.description = _value.description;
    UserRole.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserRole.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserRole.AsObject {
    return {
      name: this.name,
      description: this.description
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserRole.AsProtobufJSON {
    return {
      name: this.name,
      description: this.description
    };
  }
}
export module UserRole {
  /**
   * Standard JavaScript object representation for UserRole
   */
  export interface AsObject {
    name: string;
    description: string;
  }

  /**
   * Protobuf JSON representation for UserRole
   */
  export interface AsProtobufJSON {
    name: string;
    description: string;
  }
}

/**
 * Message implementation for api.user.UpdateUserRolesRequest
 */
export class UpdateUserRolesRequest implements GrpcMessage {
  static id = 'api.user.UpdateUserRolesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateUserRolesRequest();
    UpdateUserRolesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateUserRolesRequest) {
    _instance.alias = _instance.alias || '';
    _instance.roles = _instance.roles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateUserRolesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateUserRolesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateUserRolesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(2, _instance.roles);
    }
  }

  private _alias: string;
  private _roles: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateUserRolesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateUserRolesRequest.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.roles = (_value.roles || []).slice();
    UpdateUserRolesRequest.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateUserRolesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateUserRolesRequest.AsObject {
    return {
      alias: this.alias,
      roles: (this.roles || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateUserRolesRequest.AsProtobufJSON {
    return {
      alias: this.alias,
      roles: (this.roles || []).slice()
    };
  }
}
export module UpdateUserRolesRequest {
  /**
   * Standard JavaScript object representation for UpdateUserRolesRequest
   */
  export interface AsObject {
    alias: string;
    roles: string[];
  }

  /**
   * Protobuf JSON representation for UpdateUserRolesRequest
   */
  export interface AsProtobufJSON {
    alias: string;
    roles: string[];
  }
}

/**
 * Message implementation for api.user.UpdateUserRolesResponse
 */
export class UpdateUserRolesResponse implements GrpcMessage {
  static id = 'api.user.UpdateUserRolesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateUserRolesResponse();
    UpdateUserRolesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateUserRolesResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateUserRolesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    UpdateUserRolesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateUserRolesResponse,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateUserRolesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateUserRolesResponse.AsObject>) {
    _value = _value || {};
    UpdateUserRolesResponse.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateUserRolesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateUserRolesResponse.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateUserRolesResponse.AsProtobufJSON {
    return {};
  }
}
export module UpdateUserRolesResponse {
  /**
   * Standard JavaScript object representation for UpdateUserRolesResponse
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for UpdateUserRolesResponse
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for api.user.AutoSuggestUsersRequest
 */
export class AutoSuggestUsersRequest implements GrpcMessage {
  static id = 'api.user.AutoSuggestUsersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AutoSuggestUsersRequest();
    AutoSuggestUsersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AutoSuggestUsersRequest) {
    _instance.queryPrefix = _instance.queryPrefix || '';
    _instance.maxResults = _instance.maxResults || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AutoSuggestUsersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.queryPrefix = _reader.readString();
          break;
        case 2:
          _instance.maxResults = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AutoSuggestUsersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AutoSuggestUsersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.queryPrefix) {
      _writer.writeString(1, _instance.queryPrefix);
    }
    if (_instance.maxResults) {
      _writer.writeInt32(2, _instance.maxResults);
    }
  }

  private _queryPrefix: string;
  private _maxResults: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AutoSuggestUsersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AutoSuggestUsersRequest.AsObject>) {
    _value = _value || {};
    this.queryPrefix = _value.queryPrefix;
    this.maxResults = _value.maxResults;
    AutoSuggestUsersRequest.refineValues(this);
  }
  get queryPrefix(): string {
    return this._queryPrefix;
  }
  set queryPrefix(value: string) {
    this._queryPrefix = value;
  }
  get maxResults(): number {
    return this._maxResults;
  }
  set maxResults(value: number) {
    this._maxResults = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AutoSuggestUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AutoSuggestUsersRequest.AsObject {
    return {
      queryPrefix: this.queryPrefix,
      maxResults: this.maxResults
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AutoSuggestUsersRequest.AsProtobufJSON {
    return {
      queryPrefix: this.queryPrefix,
      maxResults: this.maxResults
    };
  }
}
export module AutoSuggestUsersRequest {
  /**
   * Standard JavaScript object representation for AutoSuggestUsersRequest
   */
  export interface AsObject {
    queryPrefix: string;
    maxResults: number;
  }

  /**
   * Protobuf JSON representation for AutoSuggestUsersRequest
   */
  export interface AsProtobufJSON {
    queryPrefix: string;
    maxResults: number;
  }
}

/**
 * Message implementation for api.user.AutoSuggestUsersResponse
 */
export class AutoSuggestUsersResponse implements GrpcMessage {
  static id = 'api.user.AutoSuggestUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AutoSuggestUsersResponse();
    AutoSuggestUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AutoSuggestUsersResponse) {
    _instance.userDetails = _instance.userDetails || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AutoSuggestUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new UserPublicDetails();
          _reader.readMessage(
            messageInitializer1,
            UserPublicDetails.deserializeBinaryFromReader
          );
          (_instance.userDetails = _instance.userDetails || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AutoSuggestUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AutoSuggestUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.userDetails && _instance.userDetails.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.userDetails as any,
        UserPublicDetails.serializeBinaryToWriter
      );
    }
  }

  private _userDetails?: UserPublicDetails[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AutoSuggestUsersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AutoSuggestUsersResponse.AsObject>) {
    _value = _value || {};
    this.userDetails = (_value.userDetails || []).map(
      m => new UserPublicDetails(m)
    );
    AutoSuggestUsersResponse.refineValues(this);
  }
  get userDetails(): UserPublicDetails[] | undefined {
    return this._userDetails;
  }
  set userDetails(value: UserPublicDetails[] | undefined) {
    this._userDetails = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AutoSuggestUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AutoSuggestUsersResponse.AsObject {
    return {
      userDetails: (this.userDetails || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AutoSuggestUsersResponse.AsProtobufJSON {
    return {
      userDetails: (this.userDetails || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module AutoSuggestUsersResponse {
  /**
   * Standard JavaScript object representation for AutoSuggestUsersResponse
   */
  export interface AsObject {
    userDetails?: UserPublicDetails.AsObject[];
  }

  /**
   * Protobuf JSON representation for AutoSuggestUsersResponse
   */
  export interface AsProtobufJSON {
    userDetails: UserPublicDetails.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.user.UserPublicDetails
 */
export class UserPublicDetails implements GrpcMessage {
  static id = 'api.user.UserPublicDetails';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserPublicDetails();
    UserPublicDetails.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserPublicDetails) {
    _instance.alias = _instance.alias || '';
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserPublicDetails,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.alias = _reader.readString();
          break;
        case 2:
          _instance.firstName = _reader.readString();
          break;
        case 3:
          _instance.lastName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserPublicDetails.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserPublicDetails,
    _writer: BinaryWriter
  ) {
    if (_instance.alias) {
      _writer.writeString(1, _instance.alias);
    }
    if (_instance.firstName) {
      _writer.writeString(2, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(3, _instance.lastName);
    }
  }

  private _alias: string;
  private _firstName: string;
  private _lastName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserPublicDetails to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserPublicDetails.AsObject>) {
    _value = _value || {};
    this.alias = _value.alias;
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    UserPublicDetails.refineValues(this);
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserPublicDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserPublicDetails.AsObject {
    return {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserPublicDetails.AsProtobufJSON {
    return {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }
}
export module UserPublicDetails {
  /**
   * Standard JavaScript object representation for UserPublicDetails
   */
  export interface AsObject {
    alias: string;
    firstName: string;
    lastName: string;
  }

  /**
   * Protobuf JSON representation for UserPublicDetails
   */
  export interface AsProtobufJSON {
    alias: string;
    firstName: string;
    lastName: string;
  }
}

/**
 * Message implementation for api.user.UserPrivacyControls
 */
export class UserPrivacyControls implements GrpcMessage {
  static id = 'api.user.UserPrivacyControls';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserPrivacyControls();
    UserPrivacyControls.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserPrivacyControls) {
    _instance.locationAcl = _instance.locationAcl || undefined;
    _instance.healthMetricsAcl = _instance.healthMetricsAcl || undefined;
    _instance.calendarAcl = _instance.calendarAcl || undefined;
    _instance.activitiesAcl = _instance.activitiesAcl || undefined;
    _instance.autoApproveFollowRequests =
      _instance.autoApproveFollowRequests || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserPrivacyControls,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.locationAcl = new shared002.UserPrivateDataAccessList();
          _reader.readMessage(
            _instance.locationAcl,
            shared002.UserPrivateDataAccessList.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.healthMetricsAcl = new shared002.UserPrivateDataAccessList();
          _reader.readMessage(
            _instance.healthMetricsAcl,
            shared002.UserPrivateDataAccessList.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.calendarAcl = new shared002.UserPrivateDataAccessList();
          _reader.readMessage(
            _instance.calendarAcl,
            shared002.UserPrivateDataAccessList.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.activitiesAcl = new shared002.UserPrivateDataAccessList();
          _reader.readMessage(
            _instance.activitiesAcl,
            shared002.UserPrivateDataAccessList.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.autoApproveFollowRequests = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UserPrivacyControls.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserPrivacyControls,
    _writer: BinaryWriter
  ) {
    if (_instance.locationAcl) {
      _writer.writeMessage(
        1,
        _instance.locationAcl as any,
        shared002.UserPrivateDataAccessList.serializeBinaryToWriter
      );
    }
    if (_instance.healthMetricsAcl) {
      _writer.writeMessage(
        2,
        _instance.healthMetricsAcl as any,
        shared002.UserPrivateDataAccessList.serializeBinaryToWriter
      );
    }
    if (_instance.calendarAcl) {
      _writer.writeMessage(
        3,
        _instance.calendarAcl as any,
        shared002.UserPrivateDataAccessList.serializeBinaryToWriter
      );
    }
    if (_instance.activitiesAcl) {
      _writer.writeMessage(
        4,
        _instance.activitiesAcl as any,
        shared002.UserPrivateDataAccessList.serializeBinaryToWriter
      );
    }
    if (_instance.autoApproveFollowRequests) {
      _writer.writeBool(5, _instance.autoApproveFollowRequests);
    }
  }

  private _locationAcl?: shared002.UserPrivateDataAccessList;
  private _healthMetricsAcl?: shared002.UserPrivateDataAccessList;
  private _calendarAcl?: shared002.UserPrivateDataAccessList;
  private _activitiesAcl?: shared002.UserPrivateDataAccessList;
  private _autoApproveFollowRequests: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserPrivacyControls to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserPrivacyControls.AsObject>) {
    _value = _value || {};
    this.locationAcl = _value.locationAcl
      ? new shared002.UserPrivateDataAccessList(_value.locationAcl)
      : undefined;
    this.healthMetricsAcl = _value.healthMetricsAcl
      ? new shared002.UserPrivateDataAccessList(_value.healthMetricsAcl)
      : undefined;
    this.calendarAcl = _value.calendarAcl
      ? new shared002.UserPrivateDataAccessList(_value.calendarAcl)
      : undefined;
    this.activitiesAcl = _value.activitiesAcl
      ? new shared002.UserPrivateDataAccessList(_value.activitiesAcl)
      : undefined;
    this.autoApproveFollowRequests = _value.autoApproveFollowRequests;
    UserPrivacyControls.refineValues(this);
  }
  get locationAcl(): shared002.UserPrivateDataAccessList | undefined {
    return this._locationAcl;
  }
  set locationAcl(value: shared002.UserPrivateDataAccessList | undefined) {
    this._locationAcl = value;
  }
  get healthMetricsAcl(): shared002.UserPrivateDataAccessList | undefined {
    return this._healthMetricsAcl;
  }
  set healthMetricsAcl(value: shared002.UserPrivateDataAccessList | undefined) {
    this._healthMetricsAcl = value;
  }
  get calendarAcl(): shared002.UserPrivateDataAccessList | undefined {
    return this._calendarAcl;
  }
  set calendarAcl(value: shared002.UserPrivateDataAccessList | undefined) {
    this._calendarAcl = value;
  }
  get activitiesAcl(): shared002.UserPrivateDataAccessList | undefined {
    return this._activitiesAcl;
  }
  set activitiesAcl(value: shared002.UserPrivateDataAccessList | undefined) {
    this._activitiesAcl = value;
  }
  get autoApproveFollowRequests(): boolean {
    return this._autoApproveFollowRequests;
  }
  set autoApproveFollowRequests(value: boolean) {
    this._autoApproveFollowRequests = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserPrivacyControls.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserPrivacyControls.AsObject {
    return {
      locationAcl: this.locationAcl ? this.locationAcl.toObject() : undefined,
      healthMetricsAcl: this.healthMetricsAcl
        ? this.healthMetricsAcl.toObject()
        : undefined,
      calendarAcl: this.calendarAcl ? this.calendarAcl.toObject() : undefined,
      activitiesAcl: this.activitiesAcl
        ? this.activitiesAcl.toObject()
        : undefined,
      autoApproveFollowRequests: this.autoApproveFollowRequests
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserPrivacyControls.AsProtobufJSON {
    return {
      locationAcl: this.locationAcl
        ? this.locationAcl.toProtobufJSON(options)
        : null,
      healthMetricsAcl: this.healthMetricsAcl
        ? this.healthMetricsAcl.toProtobufJSON(options)
        : null,
      calendarAcl: this.calendarAcl
        ? this.calendarAcl.toProtobufJSON(options)
        : null,
      activitiesAcl: this.activitiesAcl
        ? this.activitiesAcl.toProtobufJSON(options)
        : null,
      autoApproveFollowRequests: this.autoApproveFollowRequests
    };
  }
}
export module UserPrivacyControls {
  /**
   * Standard JavaScript object representation for UserPrivacyControls
   */
  export interface AsObject {
    locationAcl?: shared002.UserPrivateDataAccessList.AsObject;
    healthMetricsAcl?: shared002.UserPrivateDataAccessList.AsObject;
    calendarAcl?: shared002.UserPrivateDataAccessList.AsObject;
    activitiesAcl?: shared002.UserPrivateDataAccessList.AsObject;
    autoApproveFollowRequests: boolean;
  }

  /**
   * Protobuf JSON representation for UserPrivacyControls
   */
  export interface AsProtobufJSON {
    locationAcl: shared002.UserPrivateDataAccessList.AsProtobufJSON | null;
    healthMetricsAcl: shared002.UserPrivateDataAccessList.AsProtobufJSON | null;
    calendarAcl: shared002.UserPrivateDataAccessList.AsProtobufJSON | null;
    activitiesAcl: shared002.UserPrivateDataAccessList.AsProtobufJSON | null;
    autoApproveFollowRequests: boolean;
  }
}
