<div *ngIf="!redirect" class="garmin">
  <div>
    <button mat-raised-button class="button" [disabled]="logInDisabled" (click)="onLogin()"
      matTooltip="Click to start syncing Garmin activities">Garmin Login</button>
    <button mat-raised-button color="accent" class="button" [disabled]="logOutDisabled" (click)="onLogout()"
      matTooltip="Click to stop syncing Garmin activities">Log
      out</button>
  </div>
  <div class="text" [ngStyle]="{ 'color': loginStatusDisplay === 'Logged In' ? 'green' : 'orange' }">{{
    loginStatusDisplay }}
  </div>
  <br />
</div>
