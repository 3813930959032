import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { EditOptionModule } from '../common/edit-option/edit-option.module';
import { EditRtcModule } from '../common/edit-rtc/edit-rtc.module';
import { EditTextModule } from '../common/edit-text/edit-text.module';
import { GalleryModule } from '../common/gallery/gallery.module';
import { HrZonesChartModule } from '../common/hr-zones-chart/hr-zones-chart.module';
import { MeasurementWidgetModule } from '../common/measurement-widget/measurement-widget.module';
import { ProgressSpinnerModule } from '../common/progress-spinner/progress-spinner.module';
import { ReactionsModule } from '../common/reactions/reactions.modue';
import { ReactionDetailsModule } from '../reaction-details/reaction-details.module';
import { ActivityDetailComponent } from './activity-detail.component';

@NgModule({
  declarations: [ActivityDetailComponent],
  imports: [
    CdkAccordionModule,
    CdkTableModule,
    CommonModule,
    EditOptionModule,
    EditTextModule,
    EditRtcModule,
    FormsModule,
    HrZonesChartModule,
    GalleryModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MeasurementWidgetModule,
    NgxEchartsModule,
    ProgressSpinnerModule,
    ReactionDetailsModule,
    ReactionsModule,
  ],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') }),
    },
  ],
  exports: [ActivityDetailComponent],
})
export class ActivityDetailModule {}
