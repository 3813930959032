import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SocialLoginModule } from '@abacritt/angularx-social-login';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { FitbitLoginModule } from '../common/fitibit-login/fitbit-login.module';
import { GarminLoginModule } from '../common/garmin-login/garmin-login.module';
import { NotificationControlModule } from '../common/notification-control/notification-control.module';
import { PrivacyControlModule } from '../common/privacy-control/privacy-control.module';
import { DirectivesModule } from '../directives/directives.module';
import { CookiesModule } from '../legal/cookies/cookies.module';
import { PrivacyModule } from '../legal/privacy/privacy.module';
import { AliasValidatorDirective } from './alias.validator.directive';
import { LoginComponent } from './login.component';

@NgModule({
  declarations: [AliasValidatorDirective, LoginComponent],
  imports: [
    CdkAccordionModule,
    CdkTableModule,
    CookiesModule,
    CommonModule,
    DirectivesModule,
    FitbitLoginModule,
    GarminLoginModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    NotificationControlModule,
    PrivacyControlModule,
    PrivacyModule,
    SocialLoginModule,
  ],
  exports: [LoginComponent],
})
export class LoginModule {}
