<mat-drawer-container class="container" autosize>
  <mat-drawer #drawer class="sidenav" mode="side" [opened]="true">
    <app-docs-toc [item]="tableOfContents" [activePath]="activePath"></app-docs-toc>
  </mat-drawer>

  <div class="contents">
    <div class="contents-header">
      <button mat-icon-button matTooltip="Toggle table of contents" (click)="drawer.toggle()"><mat-icon>{{ drawer.opened
          ?
          'keyboard_double_arrow_left' :
          'keyboard_double_arrow_right'
          }}</mat-icon></button>
      <div class="flex-expand"></div>
      <div *ngIf="lastUpdated" class="last-updated">
        <span>{{ 'Updated: ' + lastUpdated }}</span>
      </div>
    </div>
    <markdown *ngIf="contentsSrc" mermaid [src]="contentsSrc"></markdown>
  </div>

  <app-reactions [readOnly]="!user?.alias" [hideComment]="true" [updater]="reactionUpdater"></app-reactions>

  <!-- Existing comments -->
  <div *ngIf="commentsSource">
    <div *ngFor="
              let comment of commentsSource.allComments;
              let commentIndex = index
            " class="comment">
      <app-profile-image [alias]="comment.alias"></app-profile-image>
      <div class="comment-contents">
        <div class="comment-header">
          <span class="comment-name">{{ comment.name }}</span>
          <div class="comment-time" matTooltip="{{ comment.timeTooltip }}">
            {{ comment.timeText }}
          </div>
          <div class="comment-name-container">
            <button class="comment-delete-button" mat-icon-button matTooltip="Delete comment"
              (click)="onDeleteComment(comment.id)">
              <mat-icon class="material-symbols-outlined comment-delete-icon">delete</mat-icon>
            </button>
          </div>
        </div>
        <div>
          <div class="comment-text">
            {{ comment.text }}
          </div>
        </div>
        <!-- Comment reaction -->
        <app-comment-reaction [reactionsByAlias]="comment.reactionsByAlias" [commentCreatorAlias]="comment.alias"
          (reactionChanged)="onCommentReactionChanged(comment.id, $event)"></app-comment-reaction>
      </div>
    </div>

    <!-- New comment -->
    <div *ngIf="user?.alias" class="comment">
      <app-profile-image [alias]="user!.alias!"></app-profile-image>
      <div class="comment-contents">
        <div class="comment-header">
          <div class="comment-name">
            {{ user!.details.firstName + " " + user!.details.lastName }}
          </div>
          <div class="comment-time" matTooltip="Remaining chars">
            {{ currentCommentRemainingChars + " chars" }}
          </div>
        </div>
        <textarea class="comment-text" placeholder="...reply" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5" [(ngModel)]="currentComment" maxlength="256" [ngModelOptions]="{ standalone: true }"
          (keydown)="onCommentKeyDown($event)"></textarea>
      </div>
    </div>
  </div>

</mat-drawer-container>
