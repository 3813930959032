import { Subject } from 'rxjs';

const CookieScriptCategories = [
  'strict',
  'performance',
  'targeting',
  'functionality',
  'unclassified',
] as const;
type CookieScriptCategory = (typeof CookieScriptCategories)[number];

type CookieScriptAcceptEvent = {
  detail: {
    categories: CookieScriptCategory[];
  };
};

// Global instance of CookieScript. This is set when
// the CookieScript <script> element is loaded in index.html.
declare let CookieScript: {
  instance: {
    show: () => void;
    hide: () => void;
    currentState: () => {
      action: 'accept' | 'reject';

      // This is not set when 'accept all' is clicked.
      categories: CookieScriptCategory[];
    };
    onAccept: (e: CookieScriptAcceptEvent) => void;
    onAcceptAll: () => void;
    onReject: () => void;
  };
};

// Observable for notifying subscribers in changes to cookie consent.
const cookiePolicyAcceptSubject = new Subject<readonly string[]>();
export const cookiePolicyAcceptObservable =
  cookiePolicyAcceptSubject.asObservable();

if (typeof CookieScript !== 'undefined') {
  CookieScript.instance.onAccept = (e: CookieScriptAcceptEvent) => {
    cookiePolicyAcceptSubject.next(e.detail.categories);
  };

  CookieScript.instance.onAcceptAll = () => {
    cookiePolicyAcceptSubject.next(CookieScriptCategories);
  };

  CookieScript.instance.onReject = () => {
    cookiePolicyAcceptSubject.next([]);
  };
}

export function isCookieBannerDisplayed(): boolean {
  const element = document.getElementById('cookiescript_injected_wrapper');
  if (element) {
    return window.getComputedStyle(element).display !== 'none';
  }
  return false;
}

export function toggleCookieBanner(): void {
  if (isCookieBannerDisplayed()) {
    CookieScript.instance.hide();
  } else {
    CookieScript.instance.show();
  }
}

export function showCookieBanner(): void {
  CookieScript.instance.show();
}
