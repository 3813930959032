import { Component, Input } from '@angular/core';
import { MediaService } from 'src/app/services/media-service/media.service';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent {
  @Input()
  alias!: string;

  @Input()
  imageUrl = '';

  getProfileImageSrc(): string {
    if (this.imageUrl.length > 0) {
      return this.imageUrl;
    } else {
      return MediaService.getProfileImageSrc(this.alias);
    }
  }

  getProfileImageAltText(): string {
    return 'Profile image of ' + this.alias;
  }

  onImageLoad(
    image: HTMLImageElement,
    icon: HTMLDivElement,
    error: boolean,
  ): void {
    if (error) {
      image.style.display = 'none';
      icon.style.display = 'block';
    } else {
      image.style.display = 'block';
      icon.style.display = 'none';
    }
  }
}
