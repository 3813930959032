import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DirectivesModule } from '../../directives/directives.module';
import { EditTextComponent } from './edit-text.component';

@NgModule({
  declarations: [EditTextComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
  ],
  exports: [EditTextComponent],
})
export class EditTextModule {}
