<div class="container">
  <!-- Summary of reactions -->
  <div class="reactions-container" tabindex="0" (click)="onClickReactionSummary($event)"
    (keydown)="onReactionSummaryKeyDown($event)">
    <span *ngIf="!hasReactions">No reactions</span>
    <!-- Counts of reactions -->
    <div class="icon-count" *ngFor="let icon of reactionIconNameAndColors">
      <div class="icon-container">
        <mat-icon class="material-symbols-outlined foreground-icon" [ngStyle]="{ 'z-index': 1 }">{{ icon.name
          }}</mat-icon>
        <!-- Background color -->
        <mat-icon class="background-icon" [ngStyle]="{ color: icon.color }">{{
          icon.backgroundName
          }}</mat-icon>
      </div>
      <span class="count">{{ icon.count }}</span>
    </div>

    <!-- Counts of comments -->
    <div class="icon-count">
      <mat-icon class="material-symbols-outlined foreground-icon">comment</mat-icon>
      <span class="count">{{
        reactions?.commentsContainer?.comments?.length ?? 0
        }}</span>
    </div>
  </div>

  <!-- Reaction buttons -->
  <div *ngIf="showReactionButtons" class="reaction-button-container-relative">
    <div #reactionButtonsContainer id="reactionButtonsContainer" class="reaction-button-container"
      [ngStyle]="{ 'z-index': 3 }" (mouseover)="onMouseOverReactionButtons($event)"
      (focus)="onReactionButtonsFocus($event)" (mouseout)="onMouseOutReactionButtons($event)"
      (blur)="onBlurReactionButtons($event)">
      <button mat-icon-button *ngFor="let icon of allReactionIconNameAndColors" matTooltip="{{ icon.toolTip }}"
        [ngStyle]="{ 'z-index': 5 }" (click)="onAddReactionClick(icon.reactionType, $event)">
        <div class="icon-container">
          <mat-icon class="material-symbols-outlined foreground-icon" [ngStyle]="{ 'z-index': 5 }">{{ icon.name
            }}</mat-icon>
          <!-- Background color -->
          <mat-icon class="background-icon" [ngStyle]="{ color: icon.color, 'z-index': 4 }">{{ icon.backgroundName
            }}</mat-icon>
        </div>
      </button>
    </div>
  </div>

  <!-- Action buttons -->
  <div *ngIf="!readOnly" class="button-container">
    <style>
      .material-symbols-outlined {
        font-variation-settings:
          "FILL" 0,
          "wght" 100,
          "GRAD" 0,
          "opsz" 48;
      }
    </style>
    <!-- Reaction button -->
    <button #reactionButton id="reactionButton" mat-raised-button class="button" (click)="onAddLikeClick($event)"
      (mouseover)="onMouseOverLike($event)" (focus)="onFocusLike($event)" (mouseout)="onMouseOutLike($event)"
      (blur)="onBlurLike($event)">
      <div class="icon-container">
        <mat-icon class="material-symbols-outlined foreground-button-icon" [ngStyle]="{ 'z-index': 1 }">{{
          getReactionIconName(false) }}</mat-icon>
        <!-- Background color -->
        <mat-icon *ngIf="hasUserReaction" class="background-button-icon" [ngStyle]="{ color: reactionColorName }">{{
          getReactionIconName(true) }}</mat-icon>
      </div>
    </button>

    <!-- New comment -->
    <textarea *ngIf="!hideComment" class="comment-input" placeholder="{{
        reactions?.commentsContainer?.comments?.slice(-1)?.[0]?.text ??
          '...comment'
      }}" matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" [(ngModel)]="currentComment"
      maxlength="256" [ngModelOptions]="{ standalone: true }" (keydown)="onCommentKeyDown($event)"></textarea>
  </div>
</div>
