import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
} from 'angular-calendar';
import { MonthViewDay, WeekDay } from 'calendar-utils';
import { Event } from 'generated/src/main/proto/api/event-service.pb';
import { Subject } from 'rxjs';
import { Roles } from '../admin/roles';
import { ConfirmationComponent } from '../common/confirmation/confirmation.component';
import { UserFilter } from '../common/user-filter/user-filter';
import { EventService } from '../services/event/event.service';
import { UserService } from '../services/user/user.service';
import {
  EventEditorComponent,
  EventEditorData,
} from './event-editor/event-editor.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './cal.component.html',
  styleUrls: ['./cal.component.scss'],
})
export class CalComponent implements OnInit {
  view = CalendarView.Month;
  viewDate: Date = new Date();
  private viewDateForData = this.getViewDateForData();

  // Edit actions for editable events.
  private editActions: CalendarEventAction[] = [
    {
      label: 'edit',
      cssClass: 'edit-event',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.editEvent(event);
      },
    },
    {
      label: 'delete',
      cssClass: 'delete-event',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.deleteEvent(event);
      },
    },
  ];

  // View action for non-editable events.
  private viewActions: CalendarEventAction[] = [
    {
      label: 'view',
      cssClass: 'view-event',
      a11yLabel: 'View',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.viewEvent(event);
      },
    },
  ];

  events: CalendarEvent[] = [];
  refresh = new Subject<void>();

  userFilter = new UserFilter();
  private readTime: Timestamp | undefined;

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.userFilter = UserFilter.fromAlias(
        this.userService.snapshotUser!.alias!,
        params.get('alias') as string,
      );
    });
  }

  get calendarView(): typeof CalendarView {
    return CalendarView;
  }

  onMonthDayClicked(day: MonthViewDay): void {
    this.viewDate = day.date;
  }

  onWeekDayHeaderClicked(day: WeekDay) {
    this.viewDate = day.date;
  }

  isViewDate(day: WeekDay): boolean {
    return day.date.getTime() == this.viewDate.getTime();
  }

  deleteEvent(calEvent: CalendarEvent): void {
    ConfirmationComponent.openDialog(
      this.dialog,
      `Delete '${calEvent.title}'?`,
      (result) => {
        if (result) {
          const event: Event = calEvent.meta!;
          this.eventService.delete(event.eventId).then(() => {
            this.events = this.events.filter((e) => e.id != calEvent.id);
            this.refresh.next();
          });
        }
      },
    );
  }

  onAddEventClick() {
    const data: EventEditorData = {
      title: '',
      descriptionHtml: '',
      start: this.viewDate,
      end: this.viewDate,
      allDay: false,
    };
    const dialogRef = this.dialog.open(EventEditorComponent, {
      data: data,
      maxWidth: '100vw',
    });
    dialogRef.afterClosed().subscribe((result: EventEditorData | undefined) => {
      if (result) {
        const event: Event = new Event({
          title: result.title,
          // This is only needed for convertAlias below,
          // not used by the backend.
          alias: this.userService.snapshotUser?.alias,
          startTime: Timestamp.fromDate(result.start),
          endTime: Timestamp.fromDate(result.end),
          descriptionHtml: result.descriptionHtml,
          allDay: result.allDay,
        });
        this.eventService.add(event).then((r) => {
          event.eventId = r.eventId;
          this.events.push(this.convertEvents([event])[0]);
          this.refresh.next();
        });
      }
    });
  }

  editEvent(calEvent: CalendarEvent): void {
    const event: Event = calEvent.meta;
    const data: EventEditorData = {
      readOnly: false,
      eventUserAlias: event.alias,

      title: calEvent.title,
      descriptionHtml: event.descriptionHtml ?? '',
      start: calEvent.start,
      end: calEvent.end!,
      allDay: calEvent.allDay ?? false,
    };
    this.addUserDetails(data).then((updatedData) => {
      const dialogRef = this.dialog.open(EventEditorComponent, {
        data: updatedData,
        maxWidth: '100vw',
      });
      dialogRef
        .afterClosed()
        .subscribe((result: EventEditorData | undefined) => {
          if (!result) {
            return;
          }
          const event: Event = calEvent.meta!;
          event.title = result.title;
          event.descriptionHtml = result.descriptionHtml;
          event.startTime = Timestamp.fromDate(result.start);
          event.endTime = Timestamp.fromDate(result.end);
          event.allDay = result.allDay;
          this.eventService.update(event).then(() => {
            calEvent.title = result.title;
            calEvent.start = result.start;
            calEvent.end = result.end;
            calEvent.allDay = result.allDay;
          });
        });
    });
  }

  viewEvent(calEvent: CalendarEvent): void {
    const event: Event = calEvent.meta;
    const data: EventEditorData = {
      readOnly: true,
      eventUserAlias: event.alias,

      title: calEvent.title,
      descriptionHtml: event.descriptionHtml ?? '',
      start: calEvent.start,
      end: calEvent.end!,
      allDay: calEvent.allDay ?? false,
    };
    this.addUserDetails(data).then((updatedData) => {
      this.dialog.open(EventEditorComponent, {
        data: updatedData,
        maxWidth: '100vw',
      });
    });
  }

  onUserFilterChange(userFilter: UserFilter) {
    if (this.userService.snapshotUser) {
      userFilter.updateLocation(
        this.location,
        '/calendar',
        this.userService.snapshotUser.alias!,
      );
    }
    this.userFilter = userFilter;
    this.loadEvents();
  }

  onViewDateChange() {
    const newViewDataForData = this.getViewDateForData();
    if (newViewDataForData.getTime() != this.viewDateForData.getTime()) {
      this.viewDateForData = newViewDataForData;
      this.loadEvents();
    }
  }

  private async addUserDetails(
    data: EventEditorData,
  ): Promise<EventEditorData> {
    const user = this.userService.snapshotUser!;
    if (data.eventUserAlias == user.alias!) {
      data.eventUserFirstName = user.details.firstName;
      data.eventUserLastName = user.details.lastName;
      data.ownedEvent = true;
      return Promise.resolve(data);
    } else {
      return this.userService
        .getUserDetails([data.eventUserAlias!])
        .then((r) => {
          data.eventUserFirstName = r[0].firstName;
          data.eventUserLastName = r[0].lastName;
          data.ownedEvent = false;
          return data;
        });
    }
  }

  private loadEvents() {
    const year = this.viewDate.getFullYear();
    const month = this.viewDate.getMonth();
    const startDate = CalComponent.addMonths(year, month, -1);
    const endDate = CalComponent.addMonths(year, month, 1);
    this.eventService
      .get(
        this.userFilter.loggedInUser,
        startDate,
        endDate,
        this.userFilter.followingAlias,
        this.readTime,
      )
      .then((r) => {
        // TODO: this may raise warnings from Angular.
        this.events = this.convertEvents(r.events!);
        this.refresh.next();
      });
  }

  private convertEvents(events: Event[]): CalendarEvent[] {
    return events.map((e) => {
      const user = this.userService.snapshotUser!;
      const alias = user.alias;
      const readOnly =
        alias != e.alias && user.roles!.indexOf(Roles.IssueReviewer) < 0;
      return {
        id: e.eventId,
        start: e.startTime!.toDate(),
        end: e.endTime!.toDate(),
        title: e.title,
        actions: readOnly ? this.viewActions : this.editActions,
        allDay: e.allDay,
        draggable: false,
        meta: e,
      };
    });
  }

  private getViewDateForData(): Date {
    return new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
  }

  private static addMonths(
    year: number,
    month: number,
    monthsToAdd: number,
  ): Date {
    monthsToAdd = Math.round(monthsToAdd);
    month += monthsToAdd;
    if (month >= 12) {
      year += Math.trunc(month / 12);
      month = month % 12;
    } else if (month < 0) {
      year += Math.trunc((month - 12) / 12);
      month = month % 12;
    }
    return new Date(year, month, 1);
  }
}
