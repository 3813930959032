<div class="tool-bar">
  <!-- User filtering menu -->
  <app-user-filter [(userFilter)]="userFilter" (userFilterChange)="onUserFilterChange($event)" matTooltip="User filter"
    [stateName]="'calendarUserFilter'"></app-user-filter>

  <!-- Calendar view options -->
  <mat-select class="view-select" [(ngModel)]="view" matTooltip="Calendar view">
    <mat-option *ngFor="
        let option of [calendarView.Month, calendarView.Week, calendarView.Day]
      " [value]="option">{{ option[0].toUpperCase() + option.substring(1) }}</mat-option>
  </mat-select>

  <!-- Selected date -->
  <mat-form-field class="selected-date">
    <input matTooltip="Selected date" matInput [matDatepicker]="viewDatePicker" readonly [(ngModel)]="viewDate"
      (ngModelChange)="onViewDateChange()" />
    <mat-datepicker-toggle matSuffix [for]="viewDatePicker" [disabled]="false"></mat-datepicker-toggle>
    <mat-datepicker #viewDatePicker [disabled]="false"></mat-datepicker>
  </mat-form-field>

  <!-- Add event -->
  <button mat-icon-button class="add-event-button" matTooltip="Add event" (click)="onAddEventClick()">
    <mat-icon class="add-event-icon">add</mat-icon>
  </button>
</div>

<div class="contents">
  <div [ngSwitch]="view">
    <!-- Month view -->
    <mwl-calendar-month-view *ngSwitchCase="calendarView.Month" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" [activeDayIsOpen]="true" (dayClicked)="onMonthDayClicked($event.day)">
    </mwl-calendar-month-view>

    <!-- Week view -->
    <mwl-calendar-week-view *ngSwitchCase="calendarView.Week" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" [headerTemplate]="weekTemplate">
    </mwl-calendar-week-view>
    <!-- Week view header template for current day styling, copied from:
      https://github.com/mattlewis92/angular-calendar/blob/663bb5d64b6cc474ba4e44f050b7420ce6afd875/projects/angular-calendar/src/modules/week/calendar-week-view/calendar-week-view-header/calendar-week-view-header.component.ts
    -->
    <ng-template #weekTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked"
      let-eventDropped="eventDropped" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate" let-dragEnter="dragEnter">
      <div class="cal-day-headers" role="row">
        <div class="cal-header" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          [class.cal-past]="day.isPast" [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend" [class.cal-week-open]="isViewDate(day)"
          (mwlClick)="onWeekDayHeaderClicked(day)" mwlDroppable dragOverClass="cal-drag-over" (drop)="
            eventDropped.emit({
              event: $event.dropData.event,
              newStart: day.date
            })
          " (dragEnter)="dragEnter.emit({ date: day.date })" tabindex="0" role="columnheader">
          <b>{{ day.date | calendarDate: "weekViewColumnHeader" : locale }}</b><br />
          <span>{{
            day.date | calendarDate: "weekViewColumnSubHeader" : locale
            }}</span>
        </div>
      </div>
    </ng-template>

    <!-- Day view -->
    <mwl-calendar-day-view *ngSwitchCase="calendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh">
    </mwl-calendar-day-view>
  </div>
</div>
