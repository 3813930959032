<div class="container">
  <div class="alias-container">
    <!-- Alias -->
    <mat-form-field class="field" [floatLabel]="'always'">
      <mat-label>User Alias</mat-label>
      <input matInput [(ngModel)]="alias" (keydown)="onAliasKeyDown($event)" />
    </mat-form-field>

    <!-- Search button -->
    <button
      mat-icon-button
      (click)="getUserRoles()"
      matTooltip="Search for user by alias"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <!-- Role to add -->
  <mat-form-field class="field" [floatLabel]="'always'">
    <mat-label>Role to add</mat-label>
    <mat-select
      required
      [(value)]="roleToAdd"
      (selectionChange)="onRoleToAddChanged()"
    >
      <mat-option *ngFor="let v of availableToAdd" [value]="v">{{
        v
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-table [dataSource]="roles" class="roles-table">
    <!-- Name Column -->
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell class="roles-header-cell" *cdkHeaderCellDef>
        Role Name
      </th>
      <td cdk-cell *cdkCellDef="let role">
        <div class="roles-cell">{{ role.name }}</div>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container cdkColumnDef="description">
      <th cdk-header-cell class="roles-header-cell" *cdkHeaderCellDef>
        Description
      </th>
      <td cdk-cell class="roles-cell" *cdkCellDef="let role">
        {{ role.description }}
      </td>
    </ng-container>

    <!-- Delete -->
    <ng-container cdkColumnDef="delete">
      <th cdk-header-cell class="roles-header-cell" *cdkHeaderCellDef>
        Delete
      </th>
      <td cdk-cell class="roles-cell" *cdkCellDef="let role">
        <button mat-icon-button (click)="deleteRole(role.name)">
          <mat-icon
            class="material-symbols-outlined"
            matTooltip="{{ 'Delete \'' + role.name + '\'' }}"
            >delete</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="roleColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: roleColumns"></tr>
  </mat-table>

  <div>
    <!-- Save button -->
    <button
      mat-icon-button
      class="save-button"
      (click)="save()"
      [disabled]="isSaveDisabled()"
      matTooltip="Save the changes"
    >
      <mat-icon>save</mat-icon>
    </button>
  </div>
</div>
